import React from 'react';
import { Outlet, Navigate, useParams, ScrollRestoration, useLoaderData } from 'react-router-dom';
import { Message, ScrollTop } from './components/PrimereactComponents';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import MunicipalityFooter from './components/MunicipalityFooter';

export const AppLoader = async ({ params }) => {
    const responses = await Promise.all([
        axios.get(`${params.municipalitaId}/${params.bodyType}/${params.zastupitelstvoId}/generalData`),
        axios.get(`municipality/${params.municipalitaId}`),
        axios.get(`${params.municipalitaId}/${params.bodyType}/councils`),
        axios.get('appInfo')
    ]);
    return responses;
};

function App() {
    const { municipalitaId, bodyType, zastupitelstvoId } = useParams();

    const { ready } = useTranslation();

    const response = useLoaderData();
    const basicData = response[0].data;
    const municipality = response[1].data;
    const councils = response[2].data;
    const appInfo = response[3].data;

    var root = document.querySelector(':root');

    if (municipality.barva !== null) {
        //root.style.setProperty('--primary-color', municipality.barva);
    }

    if (basicData !== undefined && ready) {
        if (JSON.stringify(basicData) !== '{"error":"neexistujici ID"}') {
            return (
                <>
                    <div>
                        {
                            basicData.nejnovejsi_zasedani.cislo === undefined && (
                                <Message severity="warn"
                                        text="Data tohoto kraje nejsou úplná a nezahrnují informace o konkrétních zasedání a hlasováních.
                                        Důvodem je buď jejich nedostupnost, nebo nevyhovující formát zdrojových dat komplikující jejich další zpracování." />
                            )
                        }
                        <div id='body' className='body--visualization'>
                            <ScrollRestoration />
                            <Outlet />
                        </div>
                        <MunicipalityFooter
                            latest_meeting_number={basicData.nejnovejsi_zasedani.cislo}
                            latest_meeting_date={basicData['nejnovejsi_zasedani']['datum']}
                        ></MunicipalityFooter>
                    </div>
                    <ScrollTop />
                </>
            );
        } else {
            return <Navigate to='/' />;
        }
    }
}

export default App;
