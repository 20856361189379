import PopupBodyChart from "../../../../components/map/popup/PopupBodyChart";
import PopupFooter from "../../../../components/map/popup/PopupFooter";
import PopupHeader from "../../../../components/map/popup/PopupHeader";
import data from "../data";
import geo from "../geo";
import meta from "../meta";

/**
 * Income Per Citizen statistics.
 * 
 * @author Adam Janosik, Kristyna Zaklova
 */

const incomePerCitizen23 = {
    label: "Rok 2023",
    data: data,
    categories: meta.prijemNaObyvatele.values,
    getValue: meta.prijemNaObyvatele.getValue,
    formatValue: meta.prijemNaObyvatele.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle příjmů na obyvatele jednotlivých krajů (rok 2023)",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Příjmy na obyvatele jednotlivých krajů se v roce <span
                                                className="emp"><strong>2023</strong></span> pohybovaly od <span
                                                className="emp"><strong>33,939</strong> Kč</span> do <span
                                                className="emp"><strong>101,666</strong> Kč</span>.
                                                Nejvyšší poměr zaznamenala <span className="emp"><strong>Praha</strong></span> a
                                                nejnižší <span className="emp"><strong>Moravskoslezský kraj</strong></span>.
                                            </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.prijemNaObyvatele.values,
                                            getValue: meta.prijemNaObyvatele.getValue,
                                            formatValue: meta.prijemNaObyvatele.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů (rok 2023)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2023"
                                        },
                                        {
                                            ...meta.csuPeople,
                                            label: "ČSÚ - obyvatelstvo"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}


const incomePerCitizen22 = {
    label: "Rok 2022",
    data: data,
    categories: meta.prijemNaObyvatele22.values,
    getValue: meta.prijemNaObyvatele22.getValue,
    formatValue: meta.prijemNaObyvatele22.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle příjmů na obyvatele jednotlivých krajů (rok 2022)",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Příjmy na obyvatele jednotlivých krajů se v roce <span
                                                className="emp"><strong>2022</strong></span> pohybovaly od <span
                                                className="emp"><strong>29,017</strong> Kč</span> do <span
                                                className="emp"><strong>88,657</strong> Kč</span>.
                                                Nejvyšší poměr zaznamenala <span className="emp"><strong>Praha</strong></span> a
                                                nejnižší <span className="emp"><strong>Jihomoravský kraj</strong></span>.
                                            </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.prijemNaObyvatele22.values,
                                            getValue: meta.prijemNaObyvatele22.getValue,
                                            formatValue: meta.prijemNaObyvatele22.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů (rok 2022)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2022"
                                        },
                                        {
                                            ...meta.csuPeople,
                                            label: "ČSÚ - obyvatelstvo"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const incomePerCitizen21 = {
    label: "Rok 2021",
    data: data,
    categories: meta.prijemNaObyvatele21.values,
    getValue: meta.prijemNaObyvatele21.getValue,
    formatValue: meta.prijemNaObyvatele21.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle příjmů na obyvatele jednotlivých krajů (rok 2021)",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Příjmy na obyvatele jednotlivých krajů se v roce <span
                                                className="emp"><strong>2021</strong></span> pohybovaly od <span
                                                className="emp"><strong>28,022</strong> Kč</span> do <span
                                                className="emp"><strong>82,826</strong> Kč</span>.
                                                Nejvyšší poměr zaznamenala <span className="emp"><strong>Praha</strong></span> a
                                                nejnižší <span className="emp"><strong>Jihomoravský kraj</strong></span>.
                                            </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.prijemNaObyvatele21.values,
                                            getValue: meta.prijemNaObyvatele21.getValue,
                                            formatValue: meta.prijemNaObyvatele21.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů (rok 2021)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2021"
                                        },
                                        {
                                            ...meta.csuPeople,
                                            label: "ČSÚ - obyvatelstvo"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const incomePerCitizen20 = {
    label: "Rok 2020",
    data: data,
    categories: meta.prijemNaObyvatele20.values,
    getValue: meta.prijemNaObyvatele20.getValue,
    formatValue: meta.prijemNaObyvatele20.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle příjmů na obyvatele jednotlivých krajů (rok 2020)",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Příjmy na obyvatele jednotlivých krajů se v roce <span
                                                className="emp"><strong>2020</strong></span> pohybovaly od <span
                                                className="emp"><strong>24,318</strong> Kč</span> do <span
                                                className="emp"><strong>72,611</strong> Kč</span>.
                                                Nejvyšší poměr zaznamenala <span className="emp"><strong>Praha</strong></span> a
                                                nejnižší <span className="emp"><strong>Moravskoslezský kraj</strong></span>.
                                            </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.prijemNaObyvatele20.values,
                                            getValue: meta.prijemNaObyvatele20.getValue,
                                            formatValue: meta.prijemNaObyvatele20.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů (rok 2020)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2020"
                                        },
                                        {
                                            ...meta.csuPeople,
                                            label: "ČSÚ - obyvatelstvo"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const incomePerCitizenAvg = {
    label: "Průměr 2020-2023",
    data: data,
    categories: meta.prijemNaObyvateleAvg.values,
    getValue: meta.prijemNaObyvateleAvg.getValue,
    formatValue: meta.prijemNaObyvateleAvg.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle průměrných příjmů na obyvatele jednotlivých krajů v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Napříč lety <span className="emp"><strong>2020-2023</strong></span> v průměru nejvíce financí na obyvatele získala <span className="emp"><strong>Praha</strong></span>, nejméně pak <span className="emp"><strong>Moravskoslezský kraj</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.prijemNaObyvateleAvg.values,
                                            getValue: meta.prijemNaObyvateleAvg.getValue,
                                            formatValue: meta.prijemNaObyvateleAvg.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ - hospodaření (2023)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2023"
                                        },
                                        {
                                            label: "ČSÚ - hospodaření (2022)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2022"
                                        },
                                        {
                                            label: "ČSÚ - hospodaření (2021)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2021"
                                        },
                                        {
                                            label: "ČSÚ - hospodaření (2020)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2020"
                                        },
                                        {
                                            ...meta.csuPeople,
                                            label: "ČSÚ - obyvatelstvo"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const incomePerCitizenSum = {
    label: "Suma za období 2020-2023",
    data: data,
    categories: meta.prijemNaObyvateleSum.values,
    getValue: meta.prijemNaObyvateleSum.getValue,
    formatValue: meta.prijemNaObyvateleSum.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle sumy příjmů na obyvatele jednotlivých krajů v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Suma příjmů jednotlivých krajů na obyvatele v letech <span className="emp"><strong>2020-2023</strong></span> značí součet všech příjmů na obyvatele krajů v daném období. Nejvyšší příjmy na obyvatele zaznamenala <span className="emp"><strong>Praha</strong></span>, nejnižší pak <span className="emp"><strong>Moravskoslezský kraj</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.prijemNaObyvateleSum.values,
                                            getValue: meta.prijemNaObyvateleSum.getValue,
                                            formatValue: meta.prijemNaObyvateleSum.formatValue
                                        }
                                    }
                                },
                                {
                                    title: "Vývoj příjmů krajů na obyvatele v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                                
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "line",
                                        config: {
                                            geo: geo,
                                            datasets: [
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.prijemNaObyvatele20.values,
                                                    getValue: meta.prijemNaObyvatele20.getValue,
                                                    formatValue: meta.prijemNaObyvatele20.formatValue,
                                                    label: meta.prijemNaObyvatele20.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.prijemNaObyvatele21.values,
                                                    getValue: meta.prijemNaObyvatele21.getValue,
                                                    formatValue: meta.prijemNaObyvatele21.formatValue,
                                                    label: meta.prijemNaObyvatele21.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.prijemNaObyvatele22.values,
                                                    getValue: meta.prijemNaObyvatele22.getValue,
                                                    formatValue: meta.prijemNaObyvatele22.formatValue,
                                                    label: meta.prijemNaObyvatele22.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.prijemNaObyvatele.values,
                                                    getValue: meta.prijemNaObyvatele.getValue,
                                                    formatValue: meta.prijemNaObyvatele.formatValue,
                                                    label: meta.prijemNaObyvatele.label
                                                },
                                            ]
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ (2023)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2023"
                                        },
                                        {
                                            label: "ČSÚ (2022)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2022"
                                        },
                                        {
                                            label: "ČSÚ (2021)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2021"
                                        },
                                        {
                                            label: "ČSÚ (2020)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2020"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}









const genericPopup = {
    header: {
        component: PopupHeader,
        props: {
            data: data
        }
    },
    body: {
        component: PopupBodyChart,
        props: {
            data: data,
            stats: [
                {
                    title: "Srovnání dle průměrných příjmů na obyvatele jednotlivých krajů v období 2020-2023",
                    content: () => {
                        return (
                            <>
                            </>
                        )
                    },
                    chart: {
                        type: "bar",
                        config: {
                            geo: geo,
                            data: data,
                            categories: meta.prijemNaObyvateleAvg.values,
                            getValue: meta.prijemNaObyvateleAvg.getValue,
                            formatValue: meta.prijemNaObyvateleAvg.formatValue
                        }
                    }
                },
                {
                    title: "Vývoj příjmů krajů na obyvatele v období 2020-2023",
                    content: () => {
                        return (
                            <>
                                
                            </>
                        )
                    },
                    chart: {
                        type: "line",
                        config: {
                            geo: geo,
                            datasets: [
                                {
                                    geo: geo,
                                    data: data,
                                    categories: meta.prijemNaObyvatele20.values,
                                    getValue: meta.prijemNaObyvatele20.getValue,
                                    formatValue: meta.prijemNaObyvatele20.formatValue,
                                    label: meta.prijemNaObyvatele20.label
                                },
                                {
                                    geo: geo,
                                    data: data,
                                    categories: meta.prijemNaObyvatele21.values,
                                    getValue: meta.prijemNaObyvatele21.getValue,
                                    formatValue: meta.prijemNaObyvatele21.formatValue,
                                    label: meta.prijemNaObyvatele21.label
                                },
                                {
                                    geo: geo,
                                    data: data,
                                    categories: meta.prijemNaObyvatele22.values,
                                    getValue: meta.prijemNaObyvatele22.getValue,
                                    formatValue: meta.prijemNaObyvatele22.formatValue,
                                    label: meta.prijemNaObyvatele22.label
                                },
                                {
                                    geo: geo,
                                    data: data,
                                    categories: meta.prijemNaObyvatele.values,
                                    getValue: meta.prijemNaObyvatele.getValue,
                                    formatValue: meta.prijemNaObyvatele.formatValue,
                                    label: meta.prijemNaObyvatele.label
                                },
                            ]
                        }
                    }
                }
            ]
        }
    },
    footer: {
        component: PopupFooter,
        props: {
            data: data,
            settings: {
                left: {
                    title: {
                        label: "Zdroje:",
                        icon: "pi pi-fw pi-globe"
                    },
                    links: [
                        {
                            label: "ČSÚ - hospodaření (2023)",
                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2023"
                        },
                        {
                            label: "ČSÚ - hospodaření (2022)",
                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2022"
                        },
                        {
                            label: "ČSÚ - hospodaření (2021)",
                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2021"
                        },
                        {
                            label: "ČSÚ - hospodaření (2020)",
                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2020"
                        },
                        {
                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ obyvatelstvo",
                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt-parametry&z=T&f=TABULKA&katalog=30845&sp=A&skupId=3809&pvo=DEMZU01a&u=v81__VUZEMI__100__3018&evo=v450_%21_IK-DEM-R-2030-2000-sestup_1&str=v191"
                        }
                    ]
                },
                right: {
                    title: undefined,
                    links: [
                        meta.route
                    ]
                }
            }
        }
    },
    style: {
        width: '80vw',
        height: '100vh',
        maxWidth: '1200px',
    }
}

const incomePerCitizen = {
    title: 'Příjmy kraje na obyvatele',
    content: () => {
        return (
            <>
                Přepočítání příjmů krajů na obyvatele umožňuje objektivní <span className="emp">porovnání finanční kapacity a&nbsp;zdrojů</span> jednotlivých krajů bez ohledu na jejich velikost. 
                Tento ukazatel poskytuje lepší přehled o&nbsp;tom, kolik finančních prostředků je k&nbsp;dispozici na osobu v&nbsp;každém regionu, a&nbsp;pomáhá identifikovat rozdíly v&nbsp;úrovni financování veřejných služeb a&nbsp;projektů. 
                Díky tomu lze lépe pochopit, jak efektivně kraje spravují své rozpočty, a&nbsp;umožňuje spravedlivější srovnání mezi regiony s&nbsp;různými velikostmi populace a&nbsp;ekonomickými podmínkami.
                Z&nbsp;dat je patrné, že <span className="emp">Praha</span> si drží před ostatními kraji významný náskok.
            </>
        )
    },
    chart: {
        id: "mapa-prijem-na-obyvatele",
        type: "regional-filtered-choropleth",
        config: {
            label: "Celkový příjem krajů",
            geo: geo,
            datasets: [
                incomePerCitizenSum, incomePerCitizenAvg, incomePerCitizen23, incomePerCitizen22, incomePerCitizen21, incomePerCitizen20
            ]

        }
    },
    footer: {
        left: {
            title: {
                label: "Zdroj:",
                icon: "pi pi-fw pi-globe"
            },
            links: [
                {
                    label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů",
                    value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt-parametry&z=T&f=TABULKA&katalog=33877&pvo=FIN01&sp=A&c=v3%7E8__RP2023&str=v58"
                },
                {
                    label: "ČSÚ –⁠⁠⁠⁠⁠⁠ obyvatelstvo",
                    value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt-parametry&z=T&f=TABULKA&katalog=30845&sp=A&skupId=3809&pvo=DEMZU01a&u=v81__VUZEMI__100__3018&evo=v450_%21_IK-DEM-R-2030-2000-sestup_1&str=v191"
                }
            ]
        },
        right: {
            title: undefined,
            links: [
                meta.route
            ]
        },
        popup: {
            component: genericPopup
        }
    }
}
export default incomePerCitizen;