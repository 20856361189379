import React, { useEffect, useState } from 'react';
import { BaseButton, ButtonAdd, ButtonClose } from '../../../components/Buttons';
import { ModalDialog } from '../../../components/ModalDialog';
import { useFieldArray, useForm } from 'react-hook-form';
import { Messages } from 'primereact/messages';
import { PrimeIcons } from 'primereact/api';
import { MeetingEditSelectedMembersTable } from './MeetingEditSelectedMembersTable';
import { AdditionalMemberTable } from '../../../components/AdditionalMemberTable';
import { DochazkaMoznost } from '../../../constants/enums';

export const MeetingEditAdditionalMembersDialog = ({
    members,
    politicalEntities,
    loading,
    isVisible,
    onHide,
    onSubmit,
    msgs
}) => {
    const [processing, setProcessing] = useState(false);

    const [selectedMembers, setSelectedMembers] = useState([]);

    const [selecting, setSelecting] = useState(true);

    const formId = 'add-meeting-members-dialog';

    const defaultValues = {
        members: []
    };

    const {
        control,
        formState: { errors, isSubmitting },
        handleSubmit
    } = useForm({
        disabled: processing,
        defaultValues: defaultValues
    });

    const { fields, append, remove, replace } = useFieldArray({ control, name: 'members' });

    useEffect(() => {
        setProcessing(isSubmitting);
    }, [isSubmitting]);

    useEffect(() => {
        setSelecting(true);
        setSelectedMembers([]);
        replace([]);
    }, [isVisible, members, replace]);

    const onClickNext = () => {
        if (!selectedMembers.length) {
            return;
        }

        setSelecting(false);
    };

    const onClickBack = () => {
        setSelecting(true);
    };

    const onSelectionChange = (e) => {
        // remove unselected members from form
        const removeIdxs = [];
        fields.forEach((item, index) => {
            if (!e.value.find((row) => row.id === item.member_id)) {
                removeIdxs.push(index);
            }
        });
        remove(removeIdxs);

        // add selected members to form
        e.value.forEach((row) => {
            if (!fields.find((field) => field.member_id === row.id)) {
                append({
                    member_id: row.id,
                    political_entity: null,
                    attendance: DochazkaMoznost.PRITOMNOST,
                    first_name: row.first_name,
                    last_name: row.last_name
                });
            }
        });

        // update table selection
        setSelectedMembers(e.value);
    };

    const onRemoveMember = (item, index) => {
        remove(index);
        setSelectedMembers(selectedMembers.filter((member) => member.id !== item.member_id));
    };

    return (
        <ModalDialog
            visible={isVisible}
            header='Zvolit další členy zasedání'
            style={{ minWidth: 600 }}
            footer={
                <>
                    <ButtonClose label='Zavřít' onClick={onHide} />
                    {selecting ? (
                        <BaseButton
                            label='Pokračovat'
                            icon={PrimeIcons.ARROW_RIGHT}
                            type='button'
                            onClick={onClickNext}
                            disabled={!selectedMembers.length}
                        />
                    ) : (
                        <>
                            <BaseButton
                                label='Zpět'
                                icon={PrimeIcons.ARROW_LEFT}
                                type='button'
                                onClick={onClickBack}
                                disabled={processing}
                            />
                            <ButtonAdd
                                label='Přidat členy'
                                type='submit'
                                form={formId}
                                disabled={processing || !selectedMembers.length}
                            />
                        </>
                    )}
                </>
            }
            onHide={onHide}
        >
            <Messages ref={msgs} className='flex-center' />

            {selecting ? (
                <AdditionalMemberTable
                    members={members}
                    loading={loading}
                    selectedMembers={selectedMembers}
                    onSelectionChange={onSelectionChange}
                />
            ) : (
                <form onSubmit={handleSubmit(onSubmit)} id={formId}>
                    <MeetingEditSelectedMembersTable
                        control={control}
                        errors={errors}
                        fields={fields}
                        politicalEntities={politicalEntities}
                        loading={loading}
                        disabled={processing}
                        onRemoveMember={onRemoveMember}
                    />
                </form>
            )}
        </ModalDialog>
    );
};
