import React from 'react';
import { Column } from 'primereact/column';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonDelete, ButtonEdit } from '../../../components/Buttons';
import { PaginatedDataTable } from '../../../components/PaginatedDataTable';
import { formatMemberName } from '../../../../utils/texts';

export const MemberTable = ({ members, loading, onClickDelete, disabled }) => {
    const { municipalityId } = useParams();

    const navigate = useNavigate();

    const aliasesTemplate = (rowData) => {
        if (!rowData.aliases) {
            return '-';
        }

        return rowData.aliases.map((alias) => formatMemberName(alias.first_name, alias.last_name)).join(', ');
    };

    const sexTemplate = (rowData) => {
        const icon = rowData.sex ? 'bi bi-gender-female' : 'bi bi-gender-male';
        const title = rowData ? 'Žena' : 'Muž';
        return <i className={icon} title={title} />;
    };

    const buttonsTemplate = (rowData) => {
        return (
            <div className='table-buttons'>
                <ButtonEdit
                    title='Upravit člena/členku'
                    rounded
                    disabled={disabled}
                    onClick={() => navigate(`/admin/municipality/${municipalityId}/clenove/${rowData.id}`)}
                />
                <ButtonDelete
                    title='Smazat člena/členku'
                    rounded
                    disabled={disabled}
                    onClick={() => onClickDelete(rowData)}
                />
            </div>
        );
    };

    return (
        <PaginatedDataTable
            value={members}
            loading={loading}
            removableSort
            sortField='order'
            sortOrder={1}
            breakpoint='900px'
            emptyMessage='Municipalita nemá definovány žádné členy'
        >
            <Column field='id' header='ID' sortable />
            <Column field='order' header='Pořadí' sortable />
            <Column field='first_name' header='Jméno' sortable />
            <Column field='last_name' header='Příjmení' sortable />
            <Column field='sex' header='Pohlaví' body={sexTemplate} align='center' sortable />
            <Column header='Aliasy' body={aliasesTemplate} />
            <Column body={buttonsTemplate} align='right' />
        </PaginatedDataTable>
    );
};
