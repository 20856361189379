import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import React from 'react';
import { Controller } from 'react-hook-form';

/**
 * @param {object} params
 * @param {import('react-hook-form').UseFormRegister<any>} params.control
 * @param {string} params.name
 * @param {import('react-hook-form').RegisterOptions} params.rules
 * @param {boolean} params.disabled
 * @param {string} params.placeholder
 * @param {import('primereact/dropdown').DropdownProps} params.inputProps
 * @param {(e: import('primereact/dropdown').DropdownChangeParams, field: import('react-hook-form').ControllerRenderProps) => void} params.onChange
 */
export const RHFDropdown = ({ control, name, rules, disabled, placeholder, inputProps, onChange }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            disabled={disabled}
            render={({ field, fieldState }) => (
                <Dropdown
                    id={field.name}
                    {...field}
                    ref={undefined}
                    focusInputRef={field.ref}
                    className={classNames({ 'p-invalid': fieldState.invalid })}
                    placeholder={placeholder}
                    showClear={true}
                    {...inputProps}
                    {...(onChange && { onChange: (e) => onChange(e, field) })}
                />
            )}
        />
    );
};
