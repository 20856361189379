import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';
import React from 'react';
import { Controller } from 'react-hook-form';

/**
 * @param {object} params
 * @param {import('react-hook-form').UseFormRegister<any>} params.control
 * @param {string} params.name
 * @param {import('react-hook-form').RegisterOptions} params.rules
 * @param {boolean} params.disabled
 * @param {string} params.placeholder
 * @param {import('primereact/inputnumber').InputNumberProps} params.inputProps
 */
export const RHFInputNumber = ({ control, name, rules, disabled, placeholder, inputProps }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            disabled={disabled}
            render={({ field, fieldState }) => (
                <InputNumber
                    id={field.name}
                    {...field}
                    ref={undefined}
                    inputRef={field.ref}
                    onChange={(e) => field.onChange(e.value)}
                    className={classNames({ 'p-invalid': fieldState.invalid })}
                    placeholder={placeholder}
                    mode='decimal'
                    showButtons={true}
                    {...inputProps}
                />
            )}
        />
    );
};
