import React from 'react';
import { InputError } from '../components/InputError';

export const getFormErrorMessage = (errors, name) => {
    return errors[name] && <InputError message={errors[name].message} />;
};

export const getFormArrayErrorMessage = (errors, name, index, subname) => {
    return errors[name]?.[index]?.[subname] && <InputError message={errors[name][index][subname].message} />;
};

/**
 * @param {number} max
 * @returns {string}
 */
export const maxLengthMessage = (max) => `Maximální povolená délka je ${max} znaků`;
