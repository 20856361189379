import APP from "../config";
import { dev } from "../municipality/dev";
import { kraje } from "../municipality/kraje";
import { mesta } from "../municipality/mesta";

export const getData = (response) => {
    switch(APP.subdomain) {
        case "dev":
            return dev;
        case "kraje":
            return kraje;
        case "mesta":
            return mesta;
        case "most":
            return mesta.most.organy
        case "brno":
            return mesta.brno.organy
        case "praha":
            return mesta.praha.organy
        default:
            return {};
    }
}

const data = (response) => getData(response);
export default data;