import React from 'react';
import { DataTable, Column } from '../../components/PrimereactComponents';

export const AdditionalMemberTable = ({ members, loading, selectedMembers, onSelectionChange, emptyMessage }) => {
    return (
        <DataTable
            value={members}
            loading={loading}
            removableSort
            sortField='last_name'
            sortOrder={1}
            breakpoint='500px'
            emptyMessage={emptyMessage ?? 'Pro zvolené datum nejsou dostupní žádní další členové'}
            size='small'
            selection={selectedMembers}
            onSelectionChange={onSelectionChange}
            selectionMode='checkbox'
        >
            <Column selectionMode='multiple' headerStyle={{ width: '3em' }} align='center' />
            <Column field='id' header='ID' align='center' />
            <Column field='first_name' header='Jméno' sortable />
            <Column field='last_name' header='Příjmení' sortable />
        </DataTable>
    );
};
