import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { ButtonAdd } from '../../../components/Buttons';
import { FormContainer, FormInput, FormRow, Label } from '../../../components/form';
import { LineDivider } from '../../../components/LineDivider';
import { getFormErrorMessage, maxLengthMessage } from '../../../utils/errors';
import { RHFInputText, RHFMultiSelect, RHFDropdown, RHFInputNumber, RHFYearCalendar } from '../../../components/rhf';
import { IconTooltip } from '../../../components/IconTooltip';
import { ZASTUPITELSTVO } from '../../../constants/field_lengths';
import { TermMemberTable } from './TermMemberTable';
import { MAX_TERM_LENGTH } from '../../../constants/constants';
import { AdditionalTermMemberDialog } from './AdditionalTermMemberDialog';
import { formatMemberName } from '../../../../utils/texts';

export const TermAddForm = ({ members, politicalEntities, onSubmit }) => {
    const [processing, setProcessing] = useState(false);

    const [addedMemberIds, setAddedMemberIds] = useState(new Set());

    const [isVisibleAddMembersDialog, setIsVisibleAddMembersDialog] = useState(false);

    const filteredMembers = useMemo(
        () => members.filter((member) => !addedMemberIds.has(member.id)),
        [members, addedMemberIds]
    );

    const defaultValues = {
        year_from: null,
        year_to: null,
        order: null,
        leader: null,
        leader_title: '',
        coalition: [],
        members: []
    };

    const {
        control,
        formState: { errors, isSubmitting },
        handleSubmit,
        getValues,
        setValue
    } = useForm({
        disabled: processing,
        defaultValues: defaultValues
    });

    const { fields, append, remove } = useFieldArray({ control, name: 'members' });

    useEffect(() => {
        setProcessing(isSubmitting);
    }, [isSubmitting]);

    const leaderOptions = useMemo(() => {
        return fields.map((item) => ({
            value: item.member_id,
            label: formatMemberName(item.first_name, item.last_name)
        }));
    }, [fields]);

    const handleAddMembers = (selectedMembers) => {
        const newAddedMembers = [];
        const newAddedMemberIds = new Set(addedMemberIds);
        selectedMembers.forEach((member) => {
            newAddedMembers.push({
                member_id: member.id,
                first_name: member.first_name,
                last_name: member.last_name
            });
            newAddedMemberIds.add(member.id);
        });
        setAddedMemberIds(newAddedMemberIds);
        newAddedMembers.forEach((member) => append(member));
        hideAddMembersDialog();
    };

    const handleRemoveMember = (item, index) => {
        const leader = getValues('leader');
        if (leader === item.member_id) {
            setValue('leader', null);
        }

        remove(index);
        const newAddedMemberIds = new Set(addedMemberIds);
        newAddedMemberIds.delete(item.member_id);
        setAddedMemberIds(newAddedMemberIds);
    };

    const showAddMembersDialog = () => {
        setIsVisibleAddMembersDialog(true);
    };

    const hideAddMembersDialog = () => {
        setIsVisibleAddMembersDialog(false);
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormContainer>
                    <FormRow>
                        <FormInput>
                            <Label htmlFor='year_from' text='Začátek' required />
                            <RHFYearCalendar
                                control={control}
                                name='year_from'
                                rules={{ required: 'Zadejte začátek funkčního období' }}
                                placeholder='Rok od'
                            />
                            {getFormErrorMessage(errors, 'year_from')}
                        </FormInput>
                        <FormInput>
                            <Label htmlFor='year_to' text='Konec' required />
                            <RHFYearCalendar
                                control={control}
                                name='year_to'
                                rules={{
                                    required: 'Zadejte konec funkčního období',
                                    validate: {
                                        order: (value, formValues) => {
                                            const start = formValues.year_from;
                                            const end = value;
                                            if (!start || !end) return;
                                            if (start.getFullYear() > end.getFullYear()) {
                                                return 'Konec funkčního období musí být později než začátek';
                                            }
                                        },
                                        duration: (value, formValues) => {
                                            const start = formValues.year_from;
                                            const end = value;
                                            if (!start || !end) return;
                                            const termLength = end.getFullYear() - start.getFullYear();
                                            if (termLength > MAX_TERM_LENGTH) {
                                                return `Funkční období může trvat maximálně ${MAX_TERM_LENGTH} roky`;
                                            }
                                        }
                                    }
                                }}
                                placeholder='Rok do'
                            />
                            {getFormErrorMessage(errors, 'year_to')}
                        </FormInput>
                        <FormInput>
                            <Label
                                htmlFor='order'
                                text='Pořadí'
                                warning='Pořadové číslo zasedání nelze po vytvoření funkčního období změnit. Pokud nebude
                                    zadáno, automaticky se použije hodnota následující po nejvyšším aktuálním pořadí.'
                            />
                            <RHFInputNumber
                                control={control}
                                name='order'
                                inputProps={{
                                    min: 1
                                }}
                            />
                        </FormInput>
                    </FormRow>
                    <FormRow>
                        <FormInput>
                            <Label htmlFor='coalition' text='Koalice' />
                            <RHFMultiSelect
                                control={control}
                                name='coalition'
                                placeholder='Koaliční subjekty'
                                inputProps={{
                                    options: politicalEntities,
                                    optionLabel: 'abbreviation',
                                    optionValue: 'id'
                                }}
                            />
                        </FormInput>
                        <FormInput>
                            <Label htmlFor='leader' text='Lídr' />
                            <RHFDropdown
                                control={control}
                                name='leader'
                                placeholder='Zvolte lídra'
                                inputProps={{
                                    options: leaderOptions,
                                    emptyMessage: 'Nejsou vybráni žádní členové pro funkční období',
                                    filter: true
                                }}
                            />
                        </FormInput>
                        <FormInput width='lg'>
                            <Label htmlFor='leader_title' text='Název funkce lídra' required />
                            <RHFInputText
                                control={control}
                                name='leader_title'
                                rules={{
                                    required: 'Vyplňte toto pole',
                                    maxLength: {
                                        value: ZASTUPITELSTVO.LEADER_FUNKCE,
                                        message: maxLengthMessage(ZASTUPITELSTVO.LEADER_FUNKCE)
                                    }
                                }}
                                placeholder='Funkce lídra'
                            />
                            {getFormErrorMessage(errors, 'leader_title')}
                        </FormInput>
                    </FormRow>
                    <LineDivider />
                    <FormRow type='heading'>
                        Členové pro funkční období
                        <IconTooltip>
                            Členové, kteří byli aktivní na začátku funkčního období. Další mohou být průběžně přidáváni
                            s jednotlivými zasedáními.
                        </IconTooltip>
                    </FormRow>
                    <FormRow>
                        <span className='form-input__info-text'>
                            Celkem zvoleno členů: <strong>{fields.length}</strong>
                        </span>
                        <ButtonAdd
                            type='button'
                            label='Přidat členy'
                            icon='bi bi-person-plus'
                            onClick={showAddMembersDialog}
                            disabled={processing}
                            loading={processing}
                        />
                    </FormRow>
                    <FormRow>
                        <TermMemberTable
                            control={control}
                            errors={errors}
                            fields={fields}
                            getValues={getValues}
                            politicalEntities={politicalEntities}
                            disabled={processing}
                            onRemoveMember={handleRemoveMember}
                        />
                    </FormRow>
                    <LineDivider />
                    <FormRow type='button'>
                        <ButtonAdd
                            type='submit'
                            label='Přidat funkční období'
                            disabled={processing}
                            loading={processing}
                        />
                    </FormRow>
                </FormContainer>
            </form>

            <AdditionalTermMemberDialog
                members={filteredMembers}
                isVisible={isVisibleAddMembersDialog}
                onHide={hideAddMembersDialog}
                onConfirm={handleAddMembers}
            />
        </>
    );
};
