import React from "react";

import { Badge } from 'primereact/badge';
import { Tooltip } from 'primereact/tooltip';

import SimpleMeter from "../SimpleMeter";

import "./PopupBodyBasicStats.css"

function PopupBodyBasicStats({
    event,
    props = {
        data: {},
        settings: {}
    }
}) {
    
    const rank = (attr) => {
        const currentVal = props.data[event.region][attr];
        let ranking = 1;
        
        for (var region in props.data) {
            if(region !== event.region) {
                if(props.data[region][attr] > currentVal) {
                    ranking++;
                }
            }
        }

        return ranking;
    }
    
    const maxValue = (attr) => {
        const currentVal = props.data[event.region][attr];
        let max = currentVal;
        
        for (var region in props.data) {
            if(props.data[region][attr] > max) {
                max = props.data[region][attr]
            }
        }

        return max;
    }
    
    const share = (attr) => {
        return props.data[event.region][attr]/maxValue(attr);
    }
    
    return (
        <>
            <Tooltip target=".popup-badge" content={"Pořadí v ČR"} />
            <Tooltip target=".popup-body-meter" content={"Porovnání s prvním"} />
            <div className="popup-body">
                {props && props.data && props.settings && 
                    <>
                        <div className="popup-body-left">
                            <div className="popup-body-description">
                                {props.data[event.region].popis}
                            </div>
                            <div className="popup-body-attributes">
                                <table>
                                    <colgroup>
                                        <col className="popup-body-attribute-dt"></col>
                                        <col className="popup-body-attribute-dd"></col>
                                        <col className="popup-body-attribute-rank"></col>
                                        <col className="popup-body-attribute-meter"></col>
                                    </colgroup>
                                    <tbody>
                                        {
                                            // row of the table for every value
                                            props.settings.values?.map((valCfg, index) => {
                                                return (<tr key={index}>
                                                    <th>
                                                    {
                                                        // value label
                                                        valCfg.meta.label 
                                                    }
                                                    </th>
                                                    <td>
                                                    {
                                                        // value
                                                        valCfg.meta.formatValue
                                                            ? valCfg.meta.formatValue(props.data[event.region][valCfg.meta.id])
                                                            : props.data[event.region][valCfg.meta.id]
                                                    }
                                                    </td>
                                                    <td className="popup-body-rank">
                                                    {
                                                        // rank of the value (compared to other values)
                                                        valCfg.rank && <Badge
                                                            className="popup-badge"
                                                            value={rank(valCfg.meta.id)}
                                                            style={{ opacity: .6 }}>
                                                        </Badge>
                                                    }
                                                    </td>
                                                    <td className="popup-body-meter">
                                                    {
                                                        // meter of the value (compared to other values)
                                                        valCfg.meter && <SimpleMeter
                                                            val={share(valCfg.meta.id)}
                                                            meterStyle={{"width": "100%"}}>
                                                        </SimpleMeter>
                                                    }
                                                    </td>
                                                </tr>)
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="popup-body-right">
                            <img src={props.data[event.region][props.settings.img.id]} alt={props.settings.img.label}/>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default PopupBodyBasicStats;