import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import LoadingPage from '../../components/LoadingPage';
import { useTranslation } from 'react-i18next';
import UnityLineChart from '../../components/UnityLineChart';
import UnityAverageChart from '../../components/UnityAverageChart'
import {Tooltip, Card} from "../../components/PrimereactComponents";
import { redirectErrorPage } from '../../utils/errors';

function PartiesUnity() {
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [options, setOptions] = useState(undefined);
  const { municipalitaId, bodyType, zastupitelstvoId } = useParams();
  const {t} = useTranslation();
  
  const fetchData = async () => {
    try {
      const responses = await axios.all([
        axios.get(`${municipalitaId}/${bodyType}/${zastupitelstvoId}/PartiesUnity`),
        axios.get(`FE/${municipalitaId}/${bodyType}/${zastupitelstvoId}/dropdownSubjectOptions`)
      ]);
      
      const data = responses[0].data
      setData(data);
      

      setOptions(responses[1].data);
    } catch (error) {
        setError(error);
    } finally{
        setLoading(false);
    }
  };

  useEffect(() => {
        fetchData();
  }, []);


  if (loading) {return <LoadingPage />};
    
  if (error) { return redirectErrorPage(error)};
  if(data !== undefined && options !== undefined) {

    return (
      <Card>
        <div className="parties-unity">
          <div className="item1">
            <h2>{t('parties-voting-unity', {ns: 'translation'})}<i
            className='custom-target-icon3 pi bi-info-circle text-icon__right parties__chart-icon'
            data-pr-tooltip={t("unity-tooltip", {ns: "analyses"})}
            data-pr-position='bottom'
            ></i>
            </h2>
            <Tooltip target='.custom-target-icon3'/>
            <UnityLineChart data={data} options={options}/>
          </div>
          <div className="item2">
            <h2>{t("parties-voting-avg-unity")}</h2>
            <UnityAverageChart data={data}/>
          </div>
        </div>
      </Card>
    );} 
    else{
      return (<LoadingPage/>)
    }
}


export default PartiesUnity;