import { Messages } from 'primereact/messages';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingPage from '../../../components/LoadingPage';
import { getErrorMessage } from '../../../utils/errors';
import { ErrorMessage } from '../../components/ErrorMessage';
import authAxios from '../../lib/authAxios';
import { formatDate } from '../../utils/format';
import { showErrorMessage } from '../../utils/messages';
import { scrollTop } from '../../utils/scroll';
import { MeetingAddForm } from './components/MeetingAddForm';
import { MeetingBreadCrumb } from './components/MeetingBreadCrumb';

export default function MeetingAdd() {
    const { municipalityId, termId } = useParams();
    const bodyType = 'zastupitelstvo';

    const navigate = useNavigate();

    const msgs = useRef(null);

    const [loading, setLoading] = useState(true);
    const [initError, setInitError] = useState(null);
    const [politicalEntities, setPoliticalEntities] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await authAxios.get(`admin/${municipalityId}/${bodyType}/political-entity`);
                setPoliticalEntities(response.data);
            } catch (error) {
                const errorMessage = getErrorMessage(error);
                setInitError(errorMessage ?? 'Nepodařilo se načíst politické subjekty');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [municipalityId]);

    const onSubmit = async (data) => {
        const requestData = {
            date: formatDate(data.date),
            number: data.number,
            recording_url: data.recording_url,
            members: [],
            members_add: [],
            members_remove: []
        };
        data.members.forEach((member) => {
            if (member.delete) {
                requestData.members_remove.push(member.member_id);
                return;
            }

            const memberData = {
                id: member.member_id,
                political_entity: member.political_entity,
                attendance: member.attendance
            };

            if (member.add) {
                requestData.members_add.push(memberData);
            } else {
                requestData.members.push(memberData);
            }
        });
        try {
            const response = await authAxios.post(
                `admin/${municipalityId}/${bodyType}/term/${termId}/meeting`,
                requestData
            );
            navigate(
                `/admin/municipality/${municipalityId}/funkcni-obdobi/${termId}/zasedani/${response.data.id}/hlasovani`
            );
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se přidat nové zasedání', { replace: true });
            scrollTop();
        }
    };

    const onMemberFetchError = (errorMessage) => {
        showErrorMessage(msgs, errorMessage, { replace: true });
    };

    return loading ? (
        <LoadingPage />
    ) : (
        <>
            <MeetingBreadCrumb
                extraItems={[
                    {
                        label: 'Přidat',
                        url: `/admin/municipality/${municipalityId}/funkcni-obdobi/${termId}/zasedani/new`
                    }
                ]}
            />
            <h1>Přidat zasedání</h1>

            {initError ? (
                <ErrorMessage prefix='Nelze použít formulář' text={initError} />
            ) : (
                <>
                    <Messages ref={msgs} className='page__messages' />
                    <MeetingAddForm
                        politicalEntities={politicalEntities}
                        onSubmit={onSubmit}
                        onMemberFetchError={onMemberFetchError}
                    />
                </>
            )}
        </>
    );
}
