import React, { useState, useEffect, useCallback } from 'react';
import LoadingPage from '../components/LoadingPage';
import axios from 'axios';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { PoliticalBodyCard } from '../components/PoliticalBodyCard';



export default function Organy() {
    const { municipalitaId } = useParams();

    const [municipality, setMunicipality] = useState(null);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    const redirectToBody = useCallback(
        (bodyType, replace) => {
            const fetchLatestCouncilId = async (bodyType) => {
                const response = await axios.get(`${municipalitaId}/${bodyType}/councils`);
                const councilsData = response.data;

                const latest = councilsData.reduce((maxPoradi, council) => {
                    return Math.max(maxPoradi, council.poradi);
                }, 0);

                return latest;
            };

            const redirectHomepage = async () => {
                try {
                    const latest = await fetchLatestCouncilId(bodyType);
                    navigate(`/${municipalitaId}/${bodyType}/${latest}/`, { replace: !!replace });
                } catch (error) {
                    setError(error);
                }
            };

            redirectHomepage();
        },
        [municipalitaId, navigate]
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`municipality/${municipalitaId}`);
                const municipalityData = response.data;
                if (Object.keys(municipalityData.organy).length === 1) {
                    redirectToBody(Object.keys(municipalityData.organy)[0], true);
                }
                setMunicipality(municipalityData);
            } catch (error) {
                setError(error);
            }
        };

        fetchData();
    }, [municipalitaId, redirectToBody]);

    if (municipality && Object.keys(municipality.organy).length > 1) {
        return (
            <div className='municipality2'>
                <div className='municipality-wrapper2'>
                    <span>
                        <h1 className='municipality-title2'>
                            {municipality.typ_nazev} {municipality.nazev}
                        </h1>
                        <div className='municipality-cards-grid2'>
                            {Object.keys(municipality.organy)
                                .reverse() // hack to get 'zastupitelstvo' first
                                .map((bodyType) => (
                                    <PoliticalBodyCard
                                        key={`${municipality.name}-${bodyType}`}
                                        municipality={municipality}
                                        bodyType={bodyType}
                                        onClick={() => redirectToBody(bodyType)}
                                    />
                                ))}
                        </div>
                    </span>
                </div>
            </div>
        );
    } else if (error) {
        return <Navigate to='/' />;
    } else {
        return <LoadingPage />;
    }
}
