import SubjectList from "../../../components/map/SubjectsList";

/**
 * Definitions of metadata for each data attribute.
 * 
 * @author Jiri Hynek
 */

const greens = ["#bdf5bd", "#90ee90", "#38e138", "#1ec71e", "#00a900"]
const shades = ["#bdb8c6", "#898099", "#6f667f", "#564f63", "#3e3947"]
const blues = ["#90e0ef", "#48cae4", "#00b4d8", "#0096c7"]
const reds = ["#ffb3c0", "#ff8096", "#ff4d6c", "#ff1a42", "#c71f2f"]

const populaceCategories = {
    0: {
        name: "<0.6 mil.",
        description: "<0.6 mil. obyvatel",
        color: greens[0],
        equals: (value) => value < 600_000
    },
    1: {
        name: "0.6-0.8 mil.",
        description: "0.6-0.8 mil. obyvatel",
        color: greens[1],
        equals: (value) => value >= 600_000 && value < 800_000
    },
    2: {
        name: "0.8-1 mil.",
        description: "0.8-1 mil. obyvatel",
        color: greens[2],
        equals: (value) => value >= 800_000 && value < 1_000_000
    },
    3: {
        name: "1-1.2 mil.",
        description: "1-1.2 mil. obyvatel",
        color: greens[3],
        equals: (value) => value >= 1_000_000 && value < 1_200_000
    },
    4: {
        name: ">1.2 mil.",
        description: ">1.2 mil. obyvatel",
        color: greens[4],
        equals: (value) => value >= 1_200_000
    }
}

const saldoCategories = {
    0: {
        name: "<0 Kč",
        description: "<0 Kč",
        color: reds[0],
        equals: (value) => value < 0
    },
    1: {
        name: "0-1 mil. Kč",
        description: "0-1 mil. Kč",
        color: greens[0],
        equals: (value) => value >= 0 && value < 1000000
    },
    2: {
        name: "1-8 mil. Kč",
        description: "1-8 mil. Kč",
        color: greens[1],
        equals: (value) => value >= 1000000 && value < 8000000
    },
    3: {
        name: ">8 mil. Kč",
        description: ">8 mil. Kč",
        color: greens[2],
        equals: (value) => value >= 8000000
    }
}



const outcomePerCitizenCategories = {
    0: {
        name: "<30 Kč",
        description: "<30 Kč",
        color: reds[0],
        equals: (value) => value < 30.000
    },
    1: {
        name: "30-40 Kč",
        description: "30-40 Kč",
        color: reds[1],
        equals: (value) => value >= 30.000 && value < 40.000
    },
    2: {
        name: ">40 Kč",
        description: ">40 Kč",
        color: reds,
        equals: (value) => value >= 40.000
    }
}


const nezamestnanostCategories = {
    0: {
        name: "<2.0 %",
        description: "<2.0 %",
        color: reds[0],
        equals: (value) => value < 2.0
    },
    1: {
        name: "2.0-2.5 %",
        description: "2.0-2.5 %",
        color: reds[1],
        equals: (value) => value >= 2.0 && value < 2.5
    },
    2: {
        name: "2.5-3.0 %",
        description: "2.5-3.0 %",
        color: reds[2],
        equals: (value) => value >= 2.5 && value < 3
    },
    3: {
        name: "3.0-3.5 %",
        description: "3.0-3.5 %",
        color: reds[3],
        equals: (value) => value >= 3.0 && value < 3.5
    },
    4: {
        name: ">3.5 %",
        description: ">3.5 %",
        color: reds[4],
        equals: (value) => value >= 3.5
    }
}

const vydajeKrajuCategories = {
    0: {
        name: "<20 mil. Kč",
        description: "<20 mil. Kč",
        color: reds[0],
        equals: (value) => value < 20000000
    },
    1: {
        name: "20-35 mil. Kč",
        description: "20-35 mil. Kč",
        color: reds[1],
        equals: (value) => value >= 20000000 && value < 35000000
    },
    2: {
        name: "35-50 mil. Kč",
        description: "35-50 mil. Kč",
        color: reds[2],
        equals: (value) => value >= 35000000 && value < 50000000
    },
    3: {
        name: ">50 mil. Kč",
        description: ">50 mil. Kč",
        color: reds[3],
        equals: (value) => value >= 50000000
    }
}

const crimesCategories = {
    0: {
        name: "<5 tis.",
        description: "<5 tis. trestných činů",
        color: shades[0],
        equals: (value) => value < 5000
    },
    1: {
        name: "5-10 tis.",
        description: "5-10 tis. trestných činů",
        color: shades[1],
        equals: (value) => value >= 5000 && value < 10000
    },
    2: {
        name: "10-20 tis.",
        description: "10-20 tis. trestných činů",
        color: shades[2],
        equals: (value) => value >= 10000 && value < 20000
    },
    3: {
        name: "20-30 tis.",
        description: "20-30 tis. trestných činů",
        color: shades[3],
        equals: (value) => value >= 20000 && value < 30000
    },
    4: {
        name: ">30 tis.",
        description: ">30 tis. trestných činů",
        color: shades[4],
        equals: (value) => value >= 30000
    }
}

const trestneCinynaObyvateleCategories = {
    0: {
        name: "<0.01",
        description: "<0.01 trestných činů na obyvatele",
        color: shades[0],
        equals: (value) => value < 0.01
    },
    1: {
        name: "0.01-0.015",
        description: "0.01-0.015 trestných činů na obyvatele",
        color: shades[1],
        equals: (value) => value >= 0.01 && value < 0.015
    },
    2: {
        name: "0.015-0.02",
        description: "0.015-0.02 trestných činů na obyvatele",
        color: shades[2],
        equals: (value) => value >= 0.015 && value < 0.02
    },
    3: {
        name: ">0.02",
        description: ">0.02 trestných činů na obyvatele",
        color: shades[3],
        equals: (value) => value >= 0.02
    }
}

const meta = {
    nazev: {
        id: "nazev",
        label: "Název kraje",
    },
    popis: {
        id: "popis",
        label: "Představení kraje",
    },
    sidlo: {
        id: "sidlo",
        label: "Sídlo"
    },
    rz: {
        id: "rz",
        label: "Registrační značka"
    },
    rozloha: {
        id: "rozloha",
        label: "Rozloha",
        formatValue: (value) => `${formatInt(value)} km²`
    },
    pocetObyvatel: {
        id: "pocetObyvatel",
        label: "Počet obyvatel",
        formatValue: (value) => `${formatInt(value)} obyvatel`
    },
    hustotaZalidneni: {
        id: "hustotaZalidneni",
        label: "Hustota zalidnění"
    },
    nejvyssiBodNazev: {
        id: "nejvyssiBodNazev",
        label: "Nejvyšší bod"
    },
    nejvyssiBodVyska: {
        id: "nejvyssiBodVyska",
        label: "Výška nejvyššího bodu"
    },
    pocetOkresu: {
        id: "pocetOkresu",
        label: "Okresů"
    },
    pocetORP: {
        id: "pocetORP",
        label: "Obcí s rozšířenou působností"
    },
    pocetPOU: {
        id: "pocetPOU",
        label: "Obcí s pověřeným obecným účadem"
    },
    pocetObci: {
        id: "pocetObci",
        label: "Obcí",
    },
    pocetMest: {
        id: "pocetMest",
        label: "Měst"
    },
    pocetMestysu: {
        id: "pocetMestysu",
        label: "Městysů"
    },
    route: {
        id: "route",
        label: "Hlasování zastupitelstva",
        icon: "pi-fw pi-check-square"
    },
    csuSource: {
        id: "csuSource",
        label: "Zdroj ČSÚ",
    },
    csuSalary: {
        id: "csuSalary",
        label: "Zdroj ČSÚ"
    },
    csuPeople: {
        id: "csuPeople",
        label: "Zdroj ČSÚ"
    },
    url: {
        id: "url",
        label: "Webové stránky"
    },
    urlHlasovaciData: {
        id: "urlHlasovaciData",
        label: "Zdroj hlasovacích dat"
    },
    CoA: {
        id: "CoA",
        label: "Erb"
    },
    CoALicense: {
        id: "CoALicense",
        label: "Erb (licence)"
    },
    wiki: {
        id: "wiki",
        label: "Wikipedia"
    },
    transparentnost: {
        name: "Transparentnost",
        description: "Způsob zveřejňování záznámů hlasování",
        getValue: (data, region) => data[region]?.transparentnost,
        formatValue: (value, categories) => value ? (categories[value]?.name ?? "Nedostupná data") : "Nedostupná data",
        values: {
            serialized: {
                name: "serializovaná",
                description: "Data jsou v serializované podobě (JSON, XML)",
                color: "#008e00"
            },
            HTML: {
                name: "HTML stránka",
                description: "Data jsou ve formě HTML stránek",
                color: "#00c600"
            },
            PDF: {
                name: "PDF (textové)",
                description: "Data jsou ve formě PDF dokumentů",
                color: "#c6c600"
            },
            scan: {
                name: "skenované",
                description: "Data jsou skenované dokumenty",
                color: "#ffa500"
            },
            video: {
                name: "video",
                description: "Data jsou ve video formátu",
                color: "#b37300"
            },
            sums: {
                name: "neúplná (sumy)",
                description: "Data jsou uvedena pouze v podobě sum jednotlivých hlasovacích možností",
                color: "darkgray"
            },
            /*part: {
                name: "neúplná",
                description: "Data jsou neúplná",
                color: "darkgray"
            },
            NA: {
                name: "nedostupná",
                description: "Data nejsou dostupná",
                color: "gray"
            }*/
        }
    },
    dostupnost: {
        name: "Dostupnost dat",
        description: "Dostupnost dat pro zpracování",
        getValue: (data, region) => data[region]?.dostupnost,
        formatValue: (value, categories) => value ? categories[value].name : "Nedostupná data",
        values: {
            true: {
                name: "dostupná data",
                description: "Data jsou dostupná",
                color: "lightgreen"
            },
            false: {
                name: "nedostupná data",
                description: "Data nebyla dostupná v přijatelném formátu a nebylo možné je zpracovat",
                color: "lightgray"
            }
        }
    },
    pocetSubjektuKraj: {
        derived: true,
        getValue: (data, region, party) => data[region].subjekty[party]?.pocetZastupitelu ? data[region].subjekty[party]?.pocetZastupitelu : undefined,
        formatValue: (value, categories) => value ? `${(value)}` + " mandátů" : "Žádný mandát",
    },
    pocetZastupitelu: {
        derived: true,
        getValue: (data, region) => data[region].pocetZastupitelu ? data[region].pocetZastupitelu : undefined,
        formatValue: (value, categories) => value ? `${(value)}` : "Nedostatečná data",
        values: {
            0: {
                name: "45",
                description: "45 zastupitelů",
                color: "#90ee90",
                equals: (value) => value === 45
            },
            1: {
                name: "55",
                description: "55 zastupitelů",
                color: "#38e138",
                equals: (value) => value === 55
            },
            2: {
                name: "65",
                description: "65 zastupitelů",
                color: "#1ec71e",
                equals: (value) => value === 65
            }
        }
    },
    pohlaviLidr: {
        derived: true,
        getValue: (data, region) => [data[region].lidrPohlavi, data[region].lidrJmeno, data[region].lidrPrijmeni],
        formatValue: (value, categories) => value[1] + " " + value[2],
        values: {
            1: {
                name: "žena",
                description: "žena",
                color: "#ffb3c0",
                equals: (value) => value[0] === 1
            },
            2: {
                name: "muž",
                description: "muž",
                color: "#90e0ef",
                equals: (value) => value[0] === 0
            }
        }
    },
    podilMuzu: {
        derived: true,
        getValue: (data, region) => data[region].celkovyPocetZastupitelu ? data[region]?.pocetMuzu / data[region]?.celkovyPocetZastupitelu : undefined,
        formatValue: (value, categories) => value ? `${(value * 100).toFixed(2)} %` : "Nedostatečná data",
        values: {
            1: {
                name: "<70 %",
                description: "<70 % mužů",
                color: blues[0],
                equals: (value) => value < 0.7
            },
            2: {
                name: "70-75 %",
                description: "70-75 % mužů",
                color: blues[1],
                equals: (value) => value >= 0.7 && value < 0.75
            },
            3: {
                name: "75-80 %",
                description: "75-80 % mužů",
                color: blues[2],
                equals: (value) => value >= 0.75 && value <= 0.8
            },
            4: {
                name: ">80 %",
                description: ">80 % mužů",
                color: blues[3],
                equals: (value) => value > 0.8
            }
        }
    },
    podilZen: {
        derived: true,
        getValue: (data, region) => data[region].celkovyPocetZastupitelu ? data[region]?.pocetZen / data[region]?.celkovyPocetZastupitelu : undefined,
        formatValue: (value, categories) => value ? `${(value * 100).toFixed(2)} %` : "Nedostatečná data",
        values: {
            0: {
                name: "<20 %",
                description: "<20 % žen",
                color: reds[0],
                equals: (value) => value < 0.2
            },
            1: {
                name: "20-25 %",
                description: "20-25 % žen",
                color: reds[1],
                equals: (value) => value >= 0.2 && value < 0.25
            },
            2: {
                name: "25-30 %",
                description: "25-30 % žen",
                color: reds[2],
                equals: (value) => value >= 0.25 && value < 0.3
            },
            3: {
                name: ">30 %",
                description: ">30 % žen",
                color: reds[3],
                equals: (value) => value >= 0.3
            }
        }
    },
    pocetSubjektu: {
        derived: true,
        getValue: (data, region) => data[region].pocetSubjektu ? data[region]?.pocetSubjektu : undefined,
        formatValue: (value, categories, data, region) => value ? (
            <div>
                <div><b>{value}</b> subjektů</div><br />
                { //TODO error fix with bar chart
                    <SubjectList
                        subjects={data[region].subjekty}
                        formatLine={(subjects, subjectId) => `${subjects[subjectId].pocetZastupitelu} zastupitelů`}>
                    </SubjectList>
                }
            </div>
        ) : "Nedostatečná data",
        values: {
            0: {
                name: "≤6",
                description: "≤6",
                color: "#bdf5bd",
                equals: (value) => value <= 6
            },
            1: {
                name: "7",
                description: "7",
                color: "#90ee90",
                equals: (value) => value === 7
            },
            2: {
                name: "8",
                description: "8",
                color: "#64e864",
                equals: (value) => value === 8
            },
            3: {
                name: "≥9",
                description: "≥9",
                color: "#38e138",
                equals: (value) => value >= 9
            }
        }
    },
    pocetZasedani: {
        derived: true,
        getValue: (data, region) => data[region].pocetZasedani ? data[region].pocetZasedani : undefined,
        formatValue: (value, categories) => value ? `${(value)}` + ` zasedání` : "Chybějící data",
        values: {
            0: {
                name: "<20",
                description: "<20 zasedání",
                color: "#bdf5bd",
                equals: (value) => value < 20
            },
            1: {
                name: "20-25",
                description: "20-25 zasedání",
                color: "#90ee90",
                equals: (value) => value >= 20 && value < 25
            },
            2: {
                name: "25-30",
                description: "25-30 zasedání",
                color: "#64e764",
                equals: (value) => value >= 25 && value < 30
            },
            3: {
                name: "30-35",
                description: "30-35 zasedání",
                color: "#38e138",
                equals: (value) => value >= 30 && value < 35
            },
            4: {
                name: ">35",
                description: ">35 zasedání",
                color: "#1ec71e",
                equals: (value) => value >= 35
            }
        }
    },
    pocetHlasovani: {
        derived: true,
        getValue: (data, region) => data[region].pocetHlasovani ? data[region].pocetHlasovani : undefined,
        formatValue: (value) => value ? `${(value)}` + ` hlasování` : "Chybějící data",
        values: {
            0: {
                name: "<500",
                description: "<500 hlasování",
                color: "#bdf5bd",
                equals: (value) => value < 500
            },
            1: {
                name: "500-1 000",
                description: "500-1 000 hlasování",
                color: "#90ee90",
                equals: (value) => value >= 500 && value < 1000
            },
            2: {
                name: "1 000-1 500",
                description: "1 000-1 500 hlasování",
                color: "#64e764",
                equals: (value) => value >= 1000 && value < 1500
            },
            3: {
                name: ">1 500",
                description: ">1 500 hlasování",
                color: "#38e138",
                equals: (value) => value >= 1500
            }
        }
    },
    pocetHlasovaniPrijata: {
        label: "Přijatá hlasování",
        derived: true,
        getValue: (data, region) => data[region].pocetHlasovaniPrijata ? data[region].pocetHlasovaniPrijata : undefined,
        formatValue: (value) => value ? `${(value)}` : "Chybějící data",
        values: {
            0: {
                name: "<500",
                description: "<500 hlasování",
                color: "#bdf5bd",
                equals: (value) => value < 500
            },
            1: {
                name: "500-750",
                description: "500-750 hlasování",
                color: "#90ee90",
                equals: (value) => value >= 500 && value < 750
            },
            2: {
                name: "750-1000",
                description: "750-1 000 hlasování",
                color: "#64e764",
                equals: (value) => value >= 750 && value < 1000
            },
            3: {
                name: "1 000-1 250",
                description: "1 000-1 250 hlasování",
                color: "#38e138",
                equals: (value) => value >= 1000 && value < 1250
            },
            4: {
                name: ">1 250",
                description: ">1 250 hlasování",
                color: "#1ec71e",
                equals: (value) => value >= 1250
            }
        }
    },
    pocetHlasovaniNeprijata: {
        label: "Nepřijatá hlasování",
        derived: true,
        getValue: (data, region) => data[region].pocetHlasovaniNeprijata ? data[region].pocetHlasovaniNeprijata : undefined,
        formatValue: (value) => value ? `${(value)}` : "Chybějící data",
        values: {
            0: {
                name: "<20",
                description: "<20 hlasování",
                color: "#ee919a",
                equals: (value) => value < 20
            },
            1: {
                name: "20-40",
                description: "20-40 hlasování",
                color: "#e76471",
                equals: (value) => value >= 20 && value < 40
            },
            2: {
                name: "40-60",
                description: "40-60 hlasování",
                color: "#e03848",
                equals: (value) => value >= 40 && value < 60
            },
            3: {
                name: ">60",
                description: ">60 hlasování",
                color: "#c71f2f",
                equals: (value) => value >= 60
            }
        }
    },
    hlasovaniPomer: {
        derived: true,
        getValue: (data, region) => data[region].pocetHlasovaniPrijata && data[region].pocetHlasovani && data[region].pocetHlasovaniNeprijata ? data[region].pocetHlasovaniPrijata / data[region].pocetHlasovani : undefined,
        formatValue: (value) => value ? `${((value) * 100).toFixed(2)}` + ` %` : "Chybějící data",
        values: {
            0: {
                name: "<96 %",
                description: "<96 %",
                color: greens[1],
                equals: (value) => value < 0.96
            },
            1: {
                name: "96-97 %",
                description: "96-97 %",
                color: greens[2],
                equals: (value) => value >= 0.96 && value < 0.97
            },
            2: {
                name: "97-98 %",
                description: "97-98 %",
                color: greens[3],
                equals: (value) => value >= 0.97 && value < 0.98
            },
            3: {
                name: ">98 %",
                description: ">98 %",
                color: greens[4],
                equals: (value) => value >= 0.98
            }
        }
    },
    pocetHlasovaniNaZasedani: {
        derived: true,
        getValue: (data, region) => data[region].pocetHlasovani && data[region].pocetZasedani ? data[region].pocetHlasovani / data[region].pocetZasedani : undefined,
        formatValue: (value) => value ? `${(value).toFixed(0)} hlasování` : "Chybějící data",
        values: {
            0: {
                name: "<25",
                description: "<25 hlasování",
                color: "#bdf5bd",
                equals: (value) => value < 25
            },
            1: {
                name: "25-40",
                description: "25-40 hlasování",
                color: "#90ee90",
                equals: (value) => value >= 25 && value < 40
            },
            2: {
                name: "40-50",
                description: "40-50 hlasování",
                color: "#64e764",
                equals: (value) => value >= 40 && value < 50
            },
            3: {
                name: "50-100",
                description: "50-100 hlasování",
                color: "#38e138",
                equals: (value) => value >= 50 && value < 100
            },
            4: {
                name: ">100",
                description: ">100 hlasování",
                color: "#1ec71e",
                equals: (value) => value >= 100
            }
        }
    },
    prumernaHrubaMzda23: {
        derived: true,
        label: "2023",
        getValue: (data, region) => data[region].prumernaHrubaMzda23 ? data[region].prumernaHrubaMzda23 : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "<39 tis. Kč",
                description: "<39 tis. Kč",
                color: "#bdf5bd",
                equals: (value) => value < 39000
            },
            1: {
                name: "39-40 tis. Kč",
                description: "39-40 tis. Kč",
                color: "#90ee90",
                equals: (value) => value >= 39000 && value < 40000
            },
            2: {
                name: "40-45 tis. Kč",
                description: "40-45 tis. Kč",
                color: "#38e138",
                equals: (value) => value >= 40000 && value < 45000
            },
            3: {
                name: ">45 tis. Kč",
                description: ">45 tis. Kč",
                color: "#1ec71e",
                equals: (value) => value >= 45000
            }
        }
    },
    prumernaHrubaMzda22: {
        derived: true,
        label: "2022",
        getValue: (data, region) => data[region].prumernaHrubaMzda22 ? data[region].prumernaHrubaMzda22 : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "<33 tis. Kč",
                description: "<33 tis. Kč",
                color: "#bdf5bd",
                equals: (value) => value < 33000
            },
            1: {
                name: "33-35 tis. Kč",
                description: "33-35 tis. Kč",
                color: "#90ee90",
                equals: (value) => value >= 33000 && value < 35000
            },
            2: {
                name: "35-40 tis. Kč",
                description: "35-40 tis. Kč",
                color: "#38e138",
                equals: (value) => value >= 35000 && value < 40000
            },
            3: {
                name: ">45 tis. Kč",
                description: ">45 tis. Kč",
                color: "#1ec71e",
                equals: (value) => value >= 40000
            }
        }
    },
    prumernaHrubaMzda21: {
        derived: true,
        label: "2021",
        getValue: (data, region) => data[region].prumernaHrubaMzda21 ? data[region].prumernaHrubaMzda21 : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "<32.5 tis. Kč",
                description: "<32.5 tis. Kč",
                color: "#bdf5bd",
                equals: (value) => value < 32500
            },
            1: {
                name: "32.5-35 tis. Kč",
                description: "32.5-35 tis. Kč",
                color: "#90ee90",
                equals: (value) => value >= 32500 && value < 35000
            },
            2: {
                name: "35-40 tis. Kč",
                description: "35-40 tis. Kč",
                color: "#38e138",
                equals: (value) => value >= 35000 && value < 40000
            },
            3: {
                name: ">45 tis. Kč",
                description: ">45 tis. Kč",
                color: "#1ec71e",
                equals: (value) => value >= 40000
            }
        }
    },
    prumernaHrubaMzda20: {
        derived: true,
        label: "2020",
        getValue: (data, region) => data[region].prumernaHrubaMzda20 ? data[region].prumernaHrubaMzda20 : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "<30 tis. Kč",
                description: "<30 tis. Kč",
                color: "#bdf5bd",
                equals: (value) => value < 30000
            },
            1: {
                name: "30-32.5 tis. Kč",
                description: "30-32.5 tis. Kč",
                color: "#90ee90",
                equals: (value) => value >= 30000 && value < 32500
            },
            2: {
                name: "32.5-40 tis. Kč",
                description: "32.5-40 tis. Kč",
                color: "#38e138",
                equals: (value) => value >= 32500 && value < 40000
            },
            3: {
                name: ">45 tis. Kč",
                description: ">45 tis. Kč",
                color: "#1ec71e",
                equals: (value) => value >= 40000
            }
        }
    },
    prumernaHrubaMzdaVyvoj: {
        derived: true,
        getValue: (data, region) => data[region].prumernaHrubaMzda20 && data[region].prumernaHrubaMzda23 ? data[region].prumernaHrubaMzda23 - data[region].prumernaHrubaMzda20 : undefined,
        formatValue: (value) => value ? `${((value).toFixed(2).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' '))}` + ` Kč` : "Chybějící data",
        values: {
            1: {
                name: "<5 500 Kč",
                description: "meziroční vzrůst o <5 500 Kč",
                color: "#90ee90",
                equals: (value) => value < 5500
            },
            2: {
                name: "5 500-6 500 Kč",
                description: "meziroční vzrůst o 5 500-6 500 Kč",
                color: "#38e138",
                equals: (value) => value >= 5500 && value < 6500
            },
            3: {
                name: ">6 500 Kč",
                description: "meziroční vzrůst o >6 500 Kč",
                color: "#179b17",
                equals: (value) => value >= 6500
            }
        }
    },
    nezamestnanost: {
        derived: true,
        label: "2023",
        getValue: (data, region) => data[region].nezamestnanost ? data[region].nezamestnanost : undefined,
        formatValue: (value) => value ? `${(value)}` + ` %` : "Chybějící data",
        values: nezamestnanostCategories
    },
    nezamestnanost22: {
        derived: true,
        label: "2022",
        getValue: (data, region) => data[region].nezamestnanost22 ? data[region].nezamestnanost22 : undefined,
        formatValue: (value) => value ? `${(value)}` + ` %` : "Chybějící data",
        values: nezamestnanostCategories
    },
    nezamestnanost21: {
        derived: true,
        label: "2021",
        getValue: (data, region) => data[region].nezamestnanost21 ? data[region].nezamestnanost21 : undefined,
        formatValue: (value) => value ? `${(value)}` + ` %` : "Chybějící data",
        values: nezamestnanostCategories
    },
    nezamestnanost20: {
        derived: true,
        label: "2020",
        getValue: (data, region) => data[region].nezamestnanost20 ? data[region].nezamestnanost20 : undefined,
        formatValue: (value) => value ? `${(value)}` + ` %` : "Chybějící data",
        values: nezamestnanostCategories
    },
    nezamestnanostVyvoj: {
        derived: true,
        getValue: (data, region) => data[region].nezamestnanost && data[region].nezamestnanost20 ? data[region].nezamestnanost - data[region].nezamestnanost20 : undefined,
        formatValue: (value) => `${(value).toFixed(2)}`,
        values: {
            0: {
                name: "<0 %",
                description: "meziroční pokles",
                color: "#bdf5bd",
                equals: (value) => value < 0
            },
            1: {
                name: "0-0.5 %",
                description: "meziroční vzrůst o 0-0.5 %",
                color: "#f5bdc2",
                equals: (value) => value >= 0 && value < 0.5
            },
            2: {
                name: ">0.5 %",
                description: "meziroční vzrůst o >0.5 %",
                color: "#ee919a",
                equals: (value) => value >= 0.5
            },
        }
    },
    prijmyKraju: {
        derived: true,
        label: "2023",
        getValue: (data, region) => data[region].prijem ? data[region].prijem : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "<20 mil. Kč",
                description: "<20 mil. Kč",
                color: "#bdf5bd",
                equals: (value) => value < 20000000
            },
            1: {
                name: "20-35 mil. Kč",
                description: "20-35 mil. Kč",
                color: "#90ee90",
                equals: (value) => value >= 20000000 && value < 35000000
            },
            2: {
                name: "35-50 mil. Kč",
                description: "35-50 mil. Kč",
                color: "#38e138",
                equals: (value) => value >= 35000000 && value < 50000000
            },
            3: {
                name: "50-100 mil. Kč",
                description: "50-100 mil. Kč",
                color: "#38e138",
                equals: (value) => value >= 50000000 && value < 100000000
            },
            4: {
                name: ">100 mil. Kč",
                description: ">100 mil. Kč",
                color: "#1ec71e",
                equals: (value) => value >= 100000000
            }
        }
    },
    prijmyKraju22: {
        derived: true,
        label: "2022",
        getValue: (data, region) => data[region].prijem22 ? data[region].prijem22 : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "<20 mil. Kč",
                description: "<20 mil. Kč",
                color: "#bdf5bd",
                equals: (value) => value < 20000000
            },
            1: {
                name: "20-35 mil. Kč",
                description: "20-35 mil. Kč",
                color: "#90ee90",
                equals: (value) => value >= 20000000 && value < 35000000
            },
            2: {
                name: "35-50 mil. Kč",
                description: "35-50 mil. Kč",
                color: "#38e138",
                equals: (value) => value >= 35000000 && value < 50000000
            },
            3: {
                name: ">50 mil. Kč",
                description: ">50 mil. Kč",
                color: "#1ec71e",
                equals: (value) => value >= 50000000
            }
        }
    },
    prijmyKraju21: {
        derived: true,
        label: "2021",
        getValue: (data, region) => data[region].prijem21 ? data[region].prijem21 : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "<20 mil. Kč",
                description: "<20 mil. Kč",
                color: "#bdf5bd",
                equals: (value) => value < 20000000
            },
            1: {
                name: "20-35 mil. Kč",
                description: "20-35 mil. Kč",
                color: "#90ee90",
                equals: (value) => value >= 20000000 && value < 35000000
            },
            2: {
                name: "35-50 mil. Kč",
                description: "35-50 mil. Kč",
                color: "#38e138",
                equals: (value) => value >= 35000000 && value < 50000000
            },
            3: {
                name: "50-100 mil. Kč",
                description: "50-100 mil. Kč",
                color: "#38e138",
                equals: (value) => value >= 50000000 && value < 100000000
            },
            4: {
                name: ">50 mil. Kč",
                description: ">50 mil. Kč",
                color: "#1ec71e",
                equals: (value) => value >= 50000000
            }
        }
    },
    prijmyKraju20: {
        derived: true,
        label: "2020",
        getValue: (data, region) => data[region].prijem20 ? data[region].prijem20 : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "<20 mil. Kč",
                description: "<20 mil. Kč",
                color: "#bdf5bd",
                equals: (value) => value < 20000000
            },
            1: {
                name: "20-35 mil. Kč",
                description: "20-35 mil. Kč",
                color: "#90ee90",
                equals: (value) => value >= 20000000 && value < 35000000
            },
            2: {
                name: "35-50 mil. Kč",
                description: "35-50 mil. Kč",
                color: "#38e138",
                equals: (value) => value >= 35000000 && value < 50000000
            },
            3: {
                name: ">50 mil. Kč",
                description: ">50 mil. Kč",
                color: "#1ec71e",
                equals: (value) => value >= 50000000
            }
        }
    },
    prijmyKrajuSum: {
        derived: true,
        getValue: (data, region) => data[region].prijem && data[region].prijem22 && data[region].prijem21 && data[region].prijem20 ? (data[region].prijem + data[region].prijem22 + data[region].prijem21 + data[region].prijem20) : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "<50 mil. Kč",
                description: "celkový příjem menší než 50 mil. Kč",
                color: greens[0],
                equals: (value) => value < 50_000_000
            },
            1: {
                name: "50-100 mil. Kč",
                description: "celkový příjem mezi 50-100 mil. Kč",
                color: greens[1],
                equals: (value) => value >= 50_000_000 && value < 100_000_000
            },
            2: {
                name: "100-200 mil. Kč",
                description: "celkový příjem mezi 100-200 mil. Kč",
                color: greens[2],
                equals: (value) => value >= 100_000_000 && value < 200_000000
            },
            3: {
                name: ">200 mil. Kč",
                description: "celkový příjem větší než 200 mil. Kč",
                color: greens[3],
                equals: (value) => value >= 200_000_000
            }
        }
    },
    prijmyKrajuAvg: {
        derived: true,
        getValue: (data, region) => data[region].prijem && data[region].prijem22 && data[region].prijem21 && data[region].prijem20 ? (data[region].prijem + data[region].prijem22 + data[region].prijem21 + data[region].prijem20) / 4 : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "<20 mil. Kč",
                description: "<20 mil. Kč",
                color: "#bdf5bd",
                equals: (value) => value < 20000000
            },
            1: {
                name: "20-35 mil. Kč",
                description: "20-35 mil. Kč",
                color: "#90ee90",
                equals: (value) => value >= 20000000 && value < 35000000
            },
            2: {
                name: "35-50 mil. Kč",
                description: "35-50 mil. Kč",
                color: "#38e138",
                equals: (value) => value >= 35000000 && value < 50000000
            },
            3: {
                name: ">50 mil. Kč",
                description: ">50 mil. Kč",
                color: "#1ec71e",
                equals: (value) => value >= 50000000
            }
        }
    },
    vydajeKraju: {
        derived: true,
        label: "2023",
        getValue: (data, region) => data[region].vydaje ? data[region].vydaje : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: vydajeKrajuCategories
    },
    vydajeKraju22: {
        derived: true,
        label: "2022",
        getValue: (data, region) => data[region].vydaje22 ? data[region].vydaje22 : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: vydajeKrajuCategories
    },
    vydajeKraju21: {
        derived: true,
        label: "2021",
        getValue: (data, region) => data[region].vydaje21 ? data[region].vydaje21 : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: vydajeKrajuCategories
    },
    vydajeKraju20: {
        derived: true,
        label: "2020",
        getValue: (data, region) => data[region].vydaje20 ? data[region].vydaje20 : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: vydajeKrajuCategories
    },
    vydajeKrajuAvg: {
        derived: true,
        getValue: (data, region) => data[region].vydaje && data[region].vydaje22 && data[region].vydaje21 && data[region].vydaje20 ? (data[region].vydaje + data[region].vydaje22 + data[region].vydaje21 + data[region].vydaje20) / 4 : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: vydajeKrajuCategories
    },
    vydajeKrajuSum: {
        derived: true,
        getValue: (data, region) => data[region].vydaje && data[region].vydaje22 && data[region].vydaje21 && data[region].vydaje20 ? (data[region].vydaje + data[region].vydaje22 + data[region].vydaje21 + data[region].vydaje20) : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "<50 mil. Kč",
                description: "celkové výdaje menší než 50 mil. Kč",
                color: reds[0],
                equals: (value) => value < 50_000_000
            },
            1: {
                name: "50-100 mil. Kč",
                description: "celkové výdaje mezi 50-100 mil. Kč",
                color: reds[1],
                equals: (value) => value >= 50_000_000 && value < 100_000_000
            },
            2: {
                name: "100-200 mil. Kč",
                description: "celkové výdaje mezi 100-200 mil. Kč",
                color: reds[2],
                equals: (value) => value >= 100_000_000 && value < 200_000000
            },
            3: {
                name: ">200 mil. Kč",
                description: "celkové výdaje větší než 200 mil. Kč",
                color: reds[3],
                equals: (value) => value >= 200_000_000
            }
        }
    },
    saldoKraju: {
        derived: true,
        label: "2023",
        getValue: (data, region) => data[region].vydaje && data[region].prijem ? data[region].prijem - data[region].vydaje : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: saldoCategories
    },
    saldoKraju22: {
        derived: true,
        label: "2022",
        getValue: (data, region) => data[region].vydaje22 && data[region].prijem22 ? data[region].prijem22 - data[region].vydaje22 : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: saldoCategories
    },
    saldoKraju21: {
        derived: true,
        label: "2021",
        getValue: (data, region) => data[region].vydaje21 && data[region].prijem21 ? data[region].prijem21 - data[region].vydaje21 : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: {
            1: {
                name: "0-1 mil. Kč",
                description: "0-1 mil. Kč",
                color: greens[0],
                equals: (value) => value >= 0 && value < 1000000
            },
            2: {
                name: "1-8 mil. Kč",
                description: "1-8 mil. Kč",
                color: greens[1],
                equals: (value) => value >= 1000000 && value < 8000000
            },
            3: {
                name: ">8 mil. Kč",
                description: ">8 mil. Kč",
                color: greens[2],
                equals: (value) => value >= 8000000
            }
        }
    },
    saldoKraju20: {
        derived: true,
        label: "2020",
        getValue: (data, region) => data[region].vydaje20 && data[region].prijem20 ? data[region].prijem20 - data[region].vydaje20 : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "< -1 mil. Kč",
                description: "< -1 mil. Kč",
                color: reds[1],
                equals: (value) => value < -1_000_000
            },
            1: {
                name: "<0 Kč",
                description: "<0 Kč",
                color: reds[0],
                equals: (value) => value >= -1_000_000 && value < 0
            },
            2: {
                name: "0-1 mil. Kč",
                description: "0-1 mil. Kč",
                color: greens[0],
                equals: (value) => value >= 0 && value < 1000000
            },
            3: {
                name: ">1 mil. Kč",
                description: ">1 mil. Kč",
                color: greens[1],
                equals: (value) => value >= 1000000
            },
        }
    },
    saldoKrajuAvg: {
        derived: true,
        getValue: (data, region) => data[region].vydaje20 && data[region].prijem20 && data[region].vydaje21 && data[region].prijem21 && data[region].vydaje22 && data[region].prijem22 && data[region].vydaje && data[region].prijem ? ((data[region].prijem20 - data[region].vydaje20) + (data[region].prijem21 - data[region].vydaje21) + (data[region].prijem22 - data[region].vydaje22) + (data[region].prijem - data[region].vydaje)) / 4 : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: saldoCategories
    },
    saldoKrajuSum: {
        derived: true,
        getValue: (data, region) => data[region].vydaje20 && data[region].prijem20 && data[region].vydaje21 && data[region].prijem21 && data[region].vydaje22 && data[region].prijem22 && data[region].vydaje && data[region].prijem ? ((data[region].prijem20 - data[region].vydaje20) + (data[region].prijem21 - data[region].vydaje21) + (data[region].prijem22 - data[region].vydaje22) + (data[region].prijem - data[region].vydaje)) : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: saldoCategories
    },
    prijemNaObyvatele: {
        derived: true,
        label: "2023",
        getValue: (data, region) => data[region].pocetObyvatel23 && data[region].prijem ? (data[region].prijem / data[region].pocetObyvatel23) : undefined,
        formatValue: (value) => value ? `${(value).toFixed(2)}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "<35 Kč",
                description: "<35 Kč",
                color: "#bdf5bd",
                equals: (value) => value < 35.00
            },
            1: {
                name: "35-40 Kč",
                description: "35-40 Kč",
                color: "#90ee90",
                equals: (value) => value >= 35.00 && value < 40.00
            },
            2: {
                name: "40-50 Kč",
                description: "40-50 Kč",
                color: "#38e138",
                equals: (value) => value >= 40.00 && value < 50.00
            },
            3: {
                name: ">50 Kč",
                description: ">50 Kč",
                color: "#1ec71e",
                equals: (value) => value >= 50.00
            }
        }
    },
    prijemNaObyvatele22: {
        derived: true,
        label: "2022",
        getValue: (data, region) => data[region].pocetObyvatel22 && data[region].prijem22 ? (data[region].prijem22 / data[region].pocetObyvatel22) : undefined,
        formatValue: (value) => value ? `${(value).toFixed(2)}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "<35 Kč",
                description: "<35 Kč",
                color: "#bdf5bd",
                equals: (value) => value < 35.00
            },
            1: {
                name: "35-40 Kč",
                description: "35-40 Kč",
                color: "#90ee90",
                equals: (value) => value >= 35.00 && value < 40.00
            },
            2: {
                name: ">40 Kč",
                description: ">40 Kč",
                color: "#38e138",
                equals: (value) => value >= 40.00
            }
        }
    },
    prijemNaObyvatele21: {
        derived: true,
        label: "2021",
        getValue: (data, region) => data[region].pocetObyvatel21 && data[region].prijem21 ? (data[region].prijem21 / data[region].pocetObyvatel21) : undefined,
        formatValue: (value) => value ? `${(value).toFixed(2)}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "<35 Kč",
                description: "<35 Kč",
                color: "#bdf5bd",
                equals: (value) => value < 35.00
            },
            1: {
                name: "35-40 Kč",
                description: "35-40 Kč",
                color: "#90ee90",
                equals: (value) => value >= 35.00 && value < 40.00
            },
            2: {
                name: ">40 Kč",
                description: ">40 Kč",
                color: "#38e138",
                equals: (value) => value >= 40.00
            }
        }
    },
    prijemNaObyvatele20: {
        derived: true,
        label: "2020",
        getValue: (data, region) => data[region].pocetObyvatel20 && data[region].prijem20 ? (data[region].prijem20 / data[region].pocetObyvatel20) : undefined,
        formatValue: (value) => value ? `${(value).toFixed(2)}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "<30 Kč",
                description: "<30 Kč",
                color: "#bdf5bd",
                equals: (value) => value < 30.00
            },
            1: {
                name: "30-33 Kč",
                description: "30-33 Kč",
                color: "#90ee90",
                equals: (value) => value >= 30.00 && value < 33.00
            },
            2: {
                name: "33-40 Kč",
                description: "33-40 Kč",
                color: "#38e138",
                equals: (value) => value >= 33.00 && value < 40.00
            },
            3: {
                name: ">40 Kč",
                description: ">40 Kč",
                color: "#1ec71e",
                equals: (value) => value >= 40.00 
            }
        }
    },
    prijemNaObyvateleAvg: {
        derived: true,
        getValue: (data, region) => data[region].pocetObyvatel23 && data[region].prijem && data[region].pocetObyvatel22 && data[region].prijem22 && data[region].pocetObyvatel21 && data[region].prijem21 && data[region].pocetObyvatel20 && data[region].prijem20 ? ((data[region].prijem / data[region].pocetObyvatel23) + (data[region].prijem22 / data[region].pocetObyvatel22) + (data[region].prijem21 / data[region].pocetObyvatel21) + (data[region].prijem20 / data[region].pocetObyvatel20)) / 4 : undefined,
        formatValue: (value) => value ? `${(value).toFixed(2)}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "<35 Kč",
                description: "<35 Kč",
                color: "#bdf5bd",
                equals: (value) => value < 35.00
            },
            1: {
                name: "35-40 Kč",
                description: "35-40 Kč",
                color: "#90ee90",
                equals: (value) => value >= 35.00 && value < 40.00
            },
            2: {
                name: ">40 Kč",
                description: ">40 Kč",
                color: "#38e138",
                equals: (value) => value >= 40.00
            }
        }
    },
    prijemNaObyvateleSum: {
        derived: true,
        getValue: (data, region) => data[region].pocetObyvatel23 && data[region].prijem && data[region].pocetObyvatel22 && data[region].prijem22 && data[region].pocetObyvatel21 && data[region].prijem21 && data[region].pocetObyvatel20 && data[region].prijem20 ? ((data[region].prijem / data[region].pocetObyvatel23) + (data[region].prijem22 / data[region].pocetObyvatel22) + (data[region].prijem21 / data[region].pocetObyvatel21) + (data[region].prijem20 / data[region].pocetObyvatel20)) : undefined,
        formatValue: (value) => value ? `${(value).toFixed(2)}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "<125 Kč",
                description: "<125 Kč",
                color: greens[0],
                equals: (value) => value < 125
            },
            1: {
                name: "125-150 Kč",
                description: "125-150 Kč",
                color: greens[1],
                equals: (value) => value >= 125 && value < 150
            },
            2: {
                name: ">150 Kč",
                description: ">150 Kč",
                color: greens[2],
                equals: (value) => value >= 150
            },
        }
    },
    vydajeNaObyvatele23: {
        derived: true,
        label: "2023",
        getValue: (data, region) => data[region].pocetObyvatel23 && data[region].vydaje ? (data[region].vydaje / data[region].pocetObyvatel23) : undefined,
        formatValue: (value) => value ? `${(value).toFixed(2)}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "<35 Kč",
                description: "<35 Kč",
                color: "#bdf5bd",
                equals: (value) => value < 35.000
            },
            1: {
                name: "35-40 Kč",
                description: "35-40 Kč",
                color: "#90ee90",
                equals: (value) => value >= 35.000 && value < 40.000
            },
            2: {
                name: "40-50 Kč",
                description: "40-50 Kč",
                color: "#38e138",
                equals: (value) => value >= 40.000 && value < 50.000
            },
            3: {
                name: ">50 Kč",
                description: ">50 Kč",
                color: "#1ec71e",
                equals: (value) => value >= 50.000
            }
        }
    },
    vydajeNaObyvatele22: {
        derived: true,
        label: "2022",
        getValue: (data, region) => data[region].pocetObyvatel22 && data[region].vydaje22 ? (data[region].vydaje22 / data[region].pocetObyvatel22) : undefined,
        formatValue: (value) => value ? `${(value).toFixed(2)}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "<35 Kč",
                description: "<35 Kč",
                color: "#bdf5bd",
                equals: (value) => value < 35.000
            },
            1: {
                name: "35-40 Kč",
                description: "35-40 Kč",
                color: "#90ee90",
                equals: (value) => value >= 35.000 && value < 40.000
            },
            2: {
                name: "40-50 Kč",
                description: "40-50 Kč",
                color: "#38e138",
                equals: (value) => value >= 40.000 && value < 50.000
            },
            3: {
                name: ">50 Kč",
                description: ">50 Kč",
                color: "#1ec71e",
                equals: (value) => value >= 50.000
            }
        }
    },
    vydajeNaObyvatele21: {
        derived: true,
        label: "2021",
        getValue: (data, region) => data[region].pocetObyvatel21 && data[region].vydaje21 ? (data[region].vydaje21 / data[region].pocetObyvatel21) : undefined,
        formatValue: (value) => value ? `${(value).toFixed(2)}` + ` Kč` : "Chybějící data",
        values: outcomePerCitizenCategories
    },
    vydajeNaObyvatele20: {
        derived: true,
        label: "2020",
        getValue: (data, region) => data[region].pocetObyvatel20 && data[region].vydaje20 ? (data[region].vydaje20 / data[region].pocetObyvatel20) : undefined,
        formatValue: (value) => value ? `${(value).toFixed(2)}` + ` Kč` : "Chybějící data",
        values: outcomePerCitizenCategories
    },
    vydajeNaObyvateleAvg: {
        derived: true,
        getValue: (data, region) => data[region].pocetObyvatel23 && data[region].vydaje && data[region].pocetObyvatel22 && data[region].vydaje22 && data[region].pocetObyvatel21 && data[region].vydaje21 && data[region].pocetObyvatel20 && data[region].vydaje20 ? ((data[region].vydaje / data[region].pocetObyvatel23) + (data[region].vydaje22 / data[region].pocetObyvatel22) + (data[region].vydaje21 / data[region].pocetObyvatel21) + (data[region].vydaje20 / data[region].pocetObyvatel20)) / 4 : undefined,
        formatValue: (value) => value ? `${(value).toFixed(2)}` + ` Kč` : "Chybějící data",
        values: outcomePerCitizenCategories
    },
    vydajeNaObyvateleSum: {
        derived: true,
        getValue: (data, region) => data[region].pocetObyvatel23 && data[region].vydaje && data[region].pocetObyvatel22 && data[region].vydaje22 && data[region].pocetObyvatel21 && data[region].vydaje21 && data[region].pocetObyvatel20 && data[region].vydaje20 ? ((data[region].vydaje / data[region].pocetObyvatel23) + (data[region].vydaje22 / data[region].pocetObyvatel22) + (data[region].vydaje21 / data[region].pocetObyvatel21) + (data[region].vydaje20 / data[region].pocetObyvatel20)) : undefined,
        formatValue: (value) => value ? `${(value).toFixed(2)}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "<125 Kč",
                description: "<125 Kč",
                color: reds[0],
                equals: (value) => value < 125
            },
            1: {
                name: "125-150 Kč",
                description: "125-150 Kč",
                color: reds[1],
                equals: (value) => value >= 125 && value < 150
            },
            2: {
                name: ">150 Kč",
                description: ">150 Kč",
                color: reds[2],
                equals: (value) => value >= 150
            },
        }
    },
    trestneCinyDetail:{
        formatValue: (value) => value ? `${(value)}` : "Chybějící data",
    },
    trestneCinyNaObyvatele23: {
        derived: true,
        label: "2023",
        getValue: (data, region) => data[region].pocetObyvatel23 && data[region].trestneCiny23 ? data[region].trestneCiny23 /  data[region].pocetObyvatel23 : undefined,
        formatValue: (value) => value ? `${(value).toFixed(5)}` : "Chybějící data",
        values: trestneCinynaObyvateleCategories
    },
    trestneCinyNaObyvatele22: {
        derived: true,
        label: "2022",
        getValue: (data, region) => data[region].pocetObyvatel22 && data[region].trestneCiny22 ? (data[region].trestneCiny22 / data[region].pocetObyvatel22) : undefined,
        formatValue: (value) => value ? `${(value).toFixed(5)}` : "Chybějící data",
        values: trestneCinynaObyvateleCategories
    },
    trestneCinyNaObyvatele21: {
        derived: true,
        label: "2021",
        getValue: (data, region) => data[region].pocetObyvatel21 && data[region].trestneCiny21 ? (data[region].trestneCiny21 / data[region].pocetObyvatel21) : undefined,
        formatValue: (value) => value ? `${(value).toFixed(5)}` : "Chybějící data",
        values: trestneCinynaObyvateleCategories
    },
    trestneCinyNaObyvatele20: {
        derived: true,
        label: "2020",
        getValue: (data, region) => data[region].pocetObyvatel20 && data[region].trestneCiny20 ? (data[region].trestneCiny20 / data[region].pocetObyvatel20) : undefined,
        formatValue: (value) => value ? `${(value).toFixed(5)}` : "Chybějící data",
        values: trestneCinynaObyvateleCategories
    },
    trestneCinyNaObyvateleVyvoj: {
        derived: true,
        getValue: (data, region) => data[region].pocetObyvatel23 && data[region].trestneCiny23 && data[region].pocetObyvatel20 && data[region].trestneCiny20 ? (data[region].trestneCiny23 / data[region].pocetObyvatel23) - (data[region].trestneCiny20 / data[region].pocetObyvatel20) : undefined,
        formatValue: (value) => value ? `${(value).toFixed(5)}` : "Chybějící data",
        values: {
            0: {
                name: "<0.001",
                description: "meziroční vzrůst o <0.001",
                color: shades[0],
                equals: (value) => value < 0.001
            },
            1: {
                name: "0.001-0.0015",
                description: "meziroční vzrůst o 0.001-0.0015",
                color: shades[1],
                equals: (value) => value >= 0.001 && value < 0.0015
            },
            2: {
                name: "0.015-0.002",
                description: "meziroční vzrůst o 0.015-0.002",
                color: shades[2],
                equals: (value) => value >= 0.0015 && value < 0.002
            },
            3: {
                name: ">0.002",
                description: "meziroční vzrůst o >0.002",
                color: shades[3],
                equals: (value) => value >= 0.002
            }
        }
    },
    trestneCinyNaObyvateleSuma: {
        derived: true,
        getValue: (data, region) => data[region].pocetObyvatel23 && data[region].trestneCiny23 &&
                                    data[region].pocetObyvatel22 && data[region].trestneCiny22 &&
                                    data[region].pocetObyvatel21 && data[region].trestneCiny21 &&  
                                    data[region].pocetObyvatel20 && data[region].trestneCiny20 ? (
                                        data[region].trestneCiny23 / data[region].pocetObyvatel23 +
                                        data[region].trestneCiny22 / data[region].pocetObyvatel22 +
                                        data[region].trestneCiny21 / data[region].pocetObyvatel21 +
                                        data[region].trestneCiny20 / data[region].pocetObyvatel20
                                    ) : 0
                                    ,
        formatValue: (value) => value ? `${(value).toFixed(5)}` : "Chybějící data",
        values: {
            0: {
                name: "<0,05",
                description: "<0,05 trestných činů na obyvatele",
                color: shades[0],
                equals: (value) => value < 0.05
            },
            1: {
                name: "0,05 - 0,1",
                description: "0.05-0.1 trestných činů na obyvatele",
                color: shades[1],
                equals: (value) => value >= 0.05 && value < 0.1
            },
            3: {
                name: ">0,1",
                description: ">0,1 trestných činů na obyvatele",
                color: shades[3],
                equals: (value) => value >= 0.1
            }
        }
    },
    trestneCiny23: {
        label: "2023",
        derived: true,
        getValue: (data, region) => data[region].trestneCiny23 ? data[region].trestneCiny23 : undefined,
        formatValue: (value) => value ? `${(value)}` : "Chybějící data",
        values: crimesCategories
    },
    trestneCiny22: {
        label: "2022",
        derived: true,
        getValue: (data, region) =>   data[region].trestneCiny22 ? (data[region].trestneCiny22 ) : undefined,
        formatValue: (value) => value ? `${(value)}` : "Chybějící data",
        values: crimesCategories
    },
    trestneCiny21: {
        label: "2021",
        derived: true,
        getValue: (data, region) =>  data[region].trestneCiny21 ? (data[region].trestneCiny21 ) : undefined,
        formatValue: (value) => value ? `${(value)}` : "Chybějící data",
        values: crimesCategories
    },
    trestneCiny20: {
        label: "2020",
        derived: true,
        getValue: (data, region) =>  data[region].trestneCiny20 ? (data[region].trestneCiny20 ) : undefined,
        formatValue: (value) => value ? `${(value)}` : "Chybějící data",
        values: crimesCategories
    },
    trestneCinyVyvoj: {
        derived: true,
        getValue: (data, region) => data[region].trestneCiny23 && data[region].trestneCiny20 ? data[region].trestneCiny23 - data[region].trestneCiny20 : undefined,
        formatValue: (value) => value ? `${(value)}` : "Chybějící data",
        values: {
            0: {
                name: "<500 ",
                description: "meziroční vzrůst o <500 trestných činů",
                color: shades[0],
                equals: (value) => value < 500
            },
            1: {
                name: "500-1 tis.",
                description: "meziroční vzrůst o 500-1 tis. trestných činů",
                color: shades[1],
                equals: (value) => value >= 500 && value < 1000
            },
            2: {
                name: "1-2 tis.",
                description: "meziroční vzrůst o 1-2 tis. trestných činů",
                color: shades[2],
                equals: (value) => value >= 1000 && value < 2000
            },
            3: {
                name: "2-3 tis.",
                description: "meziroční vzrůst o 2-3 tis. trestných činů",
                color: shades[3],
                equals: (value) => value >= 2000 && value < 3000
            },
            4: {
                name: ">3 tis.",
                description: ">3 tis. trestných činů",
                color: shades[4],
                equals: (value) => value >= 3000
            }
        }
    },
    trestneCinySuma: {
        derived: true,
        getValue: (data, region) => data[region].trestneCiny20 && data[region].trestneCiny21 && data[region].trestneCiny22 && data[region].trestneCiny23 &&
            data[region].trestneCiny20 + data[region].trestneCiny21 + data[region].trestneCiny22 + data[region].trestneCiny23,
        formatValue: (value) => value ? `${(value)}` : "Chybějící data",
        values: {
            0: {
                name: "< 20 tis.",
                description: "< 20 tis. trestných činů",
                color: shades[0],
                equals: (value) => value < 20000
            },
            1: {
                name: "20-50 tis.",
                description: "20-50 tis. trestných činů",
                color: shades[1],
                equals: (value) => value >= 20000 && value < 50000
            },
            2: {
                name: "50-100 tis.",
                description: "50-100 tis. trestných činů",
                color: shades[2],
                equals: (value) => value >= 50000 && value < 100000
            },
            3: {
                name: ">100 tis.",
                description: ">100 tis. trestných činů",
                color: shades[4],
                equals: (value) => value >= 100000
            }
        }
    },
    populace23: {
        derived: true,
        label: "2023",
        getValue: (data, region) => data[region].pocetObyvatel23 ? data[region].pocetObyvatel23 : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` obyvatel` : "Chybějící data",
        values: populaceCategories
    },
    populace22: {
        derived: true,
        label: "2022",
        getValue: (data, region) => data[region].pocetObyvatel22 ? data[region].pocetObyvatel22 : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` obyvatel` : "Chybějící data",
        values: populaceCategories
    },
    populace21: {
        derived: true,
        label: "2021",
        getValue: (data, region) => data[region].pocetObyvatel21 ? data[region].pocetObyvatel21 : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` obyvatel` : "Chybějící data",
        values: populaceCategories
    },
    populace20: {
        derived: true,
        label: "2020",
        getValue: (data, region) => data[region].pocetObyvatel20 ? data[region].pocetObyvatel20 : undefined,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` obyvatel` : "Chybějící data",
        values: populaceCategories
    },
    populaceVyvoj: {
        derived: true,
        getValue: (data, region) => data[region].pocetObyvatel23 && data[region].pocetObyvatel20 ? data[region].pocetObyvatel23 - data[region].pocetObyvatel20 : undefined,
        formatValue: (value) => value ? `${(value)}` + ` obyvatel` : "Chybějící data",
        values: {
            0: {
                name: "<0",
                description: "pokles obyvatelstva",
                color: reds[0],
                equals: (value) => value < 0
            },
            1: {
                name: "0-10 000",
                description: "nárůst o 0-10 000 obyvatel",
                color: greens[0],
                equals: (value) => value >= 0 && value < 10000
            },
            2: {
                name: "10 000-25 000",
                description: "nárůst o 10 000-25 000 obyvatel",
                color: greens[1],
                equals: (value) => value >= 10000 && value < 25000
            },
            3: {
                name: ">25 000",
                description: "nárůst o více než 25 000 obyvatel",
                color: greens[2],
                equals: (value) => value >= 25000
            },
        }
    },

    platUvolneny: {
        derived: true,
        getValue: (data, region) => region === "A" ? 114_791 : data[region].pocetObyvatel < 500_000 ? 104_356 : data[region].pocetObyvatel > 500_000 && data[region].pocetObyvatel < 1_000_000 ? 107_820 : 111_327,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "104 356 Kč",
                description: "104 356 Kč",
                color: "#bdf5bd",
                equals: (value) => value === 104356
            },
            1: {
                name: "107 820 Kč",
                description: "107 820 Kč",
                color: "#90ee90",
                equals: (value) => value === 107820
            },
            2: {
                name: "111 327 Kč",
                description: "111 327 Kč",
                color: "#38e138",
                equals: (value) => value === 111327
            },
            3: {
                name: "114 791 Kč",
                description: "114 791 Kč",
                color: "#1ec71e",
                equals: (value) => value === 114791
            }
        }
    },
    platNeuvolneny: {
        derived: true,
        getValue: (data, region) => region === "A" ? 8450 : data[region].pocetObyvatel < 500_000 ? 7690 : data[region].pocetObyvatel > 500_000 && data[region].pocetObyvatel < 1_000_000 ? 7943 : 8197,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "7 690 Kč",
                description: "7 690 Kč",
                color: "#bdf5bd",
                equals: (value) => value === 7690
            },
            1: {
                name: "7 943 Kč",
                description: "7 943 Kč",
                color: "#90ee90",
                equals: (value) => value === 7943
            },
            2: {
                name: "8 197 Kč",
                description: "8 197 Kč",
                color: "#38e138",
                equals: (value) => value === 8197
            },
            3: {
                name: "8 450 Kč",
                description: "8 450 Kč",
                color: "#1ec71e",
                equals: (value) => value === 8450
            }
        }
    },
    platUvolneny23: {
        derived: true,
        getValue: (data, region) => region === "A" ? 106_041 : data[region].pocetObyvatel23 < 500_000 ? 96_401 : data[region].pocetObyvatel23 > 500_000 && data[region].pocetObyvatel23 < 1_000_000 ? 99_615 : 102_827,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "96 401 Kč",
                description: "96 401 Kč",
                color: "#bdf5bd",
                equals: (value) => value === 96401
            },
            1: {
                name: "99 615 Kč",
                description: "99 615 Kč",
                color: "#90ee90",
                equals: (value) => value === 99615
            },
            2: {
                name: "102 827 Kč",
                description: "102 827 Kč",
                color: "#38e138",
                equals: (value) => value === 102827
            },
            3: {
                name: "106 041 Kč",
                description: "106 041 Kč",
                color: "#1ec71e",
                equals: (value) => value === 106041
            }
        }
    },
    platNeuvolneny23: {
        derived: true,
        getValue: (data, region) => region === "A" ? 7797 : data[region].pocetObyvatel23 < 500_000 ? 7088 : data[region].pocetObyvatel23 > 500_000 && data[region].pocetObyvatel23 < 1_000_000 ? 7324 : 7561,
        formatValue: (value) => value ? `${(value).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')}` + ` Kč` : "Chybějící data",
        values: {
            0: {
                name: "7 088 Kč",
                description: "7 088 Kč",
                color: "#bdf5bd",
                equals: (value) => value === 7088
            },
            1: {
                name: "7 324 Kč",
                description: "7 324 Kč",
                color: "#90ee90",
                equals: (value) => value === 7324
            },
            2: {
                name: "7 561 Kč",
                description: "7 561 Kč",
                color: "#38e138",
                equals: (value) => value === 7561
            },
            3: {
                name: "7 797 Kč",
                description: "7 797 Kč",
                color: "#1ec71e",
                equals: (value) => value === 7797
            }
        }
    },
}
export default meta;

/**
 * Help method which formats integer values to human readable format.
 * 
 * @param {*} x 
 * @returns 
 */
const formatInt = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

/**
 * Help method which formats float values to human readable format.
 */
/*const formatFloat = (x) => {
    var parts = x.toString().split(",");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(",");
}*/