import axios from 'axios';
import { PrimeIcons } from 'primereact/api';
import { Messages } from 'primereact/messages';
import React, { useEffect, useRef, useState } from 'react';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card } from '../../components/PrimereactComponents';
import { getErrorMessage } from '../../utils/errors';
import { FormContainer, FormInput, FormRow, Label } from '../components/form';
import { LoginNavMenu } from '../components/LoginNavMenu';
import { RHFInputText, RHFPassword } from '../components/rhf';
import { getFormErrorMessage } from '../utils/errors';
import { showErrorMessage } from '../utils/messages';

export default function Login() {
    const isAuthenticated = useIsAuthenticated();
    const signIn = useSignIn();

    const msgs = useRef(null);

    const navigate = useNavigate();

    const [processing, setProcessing] = useState(false);

    const {
        control,
        formState: { errors },
        handleSubmit,
        resetField
    } = useForm({
        disabled: processing,
        defaultValues: {
            login: '',
            password: ''
        }
    });

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/admin/municipality', { replace: true });
        }
    }, [isAuthenticated, navigate]);

    const onSubmit = async (data) => {
        setProcessing(true);
        const requestData = {
            login: data.login,
            password: data.password
        };
        try {
            const response = await axios.post('login', requestData);
            signIn({
                auth: {
                    token: response.data.token,
                    type: 'Bearer'
                },
                refresh: response.data.refresh
            });
            navigate('/admin/municipality');
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se přihlásit', { replace: true });
            if (error?.response?.status === 401) {
                resetField('password');
            }
        } finally {
            setProcessing(false);
        }
    };

    return (
        <>
            <LoginNavMenu />

            <div id='body' className='body--admin'>
                <Messages ref={msgs} className='page__messages' />

                <Card className='admin-card'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormContainer type='card'>
                            <FormRow type='heading'>Přihlášení</FormRow>
                            <FormRow>
                                <FormInput>
                                    <Label htmlFor='login' text='Login' required />
                                    <RHFInputText
                                        control={control}
                                        name='login'
                                        rules={{ required: 'Zadejte login' }}
                                        placeholder='Login'
                                    />
                                    {getFormErrorMessage(errors, 'login')}
                                </FormInput>
                            </FormRow>
                            <FormRow>
                                <FormInput>
                                    <Label htmlFor='password' text='Heslo' required />
                                    <RHFPassword
                                        control={control}
                                        name='password'
                                        rules={{ required: 'Zadejte heslo' }}
                                        placeholder='Heslo'
                                    />
                                    {getFormErrorMessage(errors, 'password')}
                                </FormInput>
                            </FormRow>
                            <FormRow type='button'>
                                <Button
                                    type='submit'
                                    label='Přihlásit'
                                    icon={PrimeIcons.USER}
                                    disabled={processing}
                                    loading={processing}
                                />
                            </FormRow>
                            <FormRow type='footer'>
                                Nemáte účet? <Link to='/admin/register'>Registrujte se</Link>.
                            </FormRow>
                        </FormContainer>
                    </form>
                </Card>
            </div>
        </>
    );
}
