import React, { useState, useEffect } from 'react';
import PollsTable from '../../components/PollsTable';
import { useParams } from 'react-router-dom';
import { useSearchParams, useLoaderData } from 'react-router-dom';
import SummaryVotesGraph from '../../components/SummaryVotesGraph';
import { useTranslation } from 'react-i18next';
import {
    Dropdown,
    RadioButton,
    Checkbox,
    InputText,
    Card,
    Button,
    Tooltip
} from '../../components/PrimereactComponents';

function Votings() {
    let [searchParams] = useSearchParams();

    const { t } = useTranslation(['votings', 'translation']);

    const { municipalitaId, bodyType, zastupitelstvoId } = useParams();

    const [members, setMemberOptions] = useState(undefined);
    const [voteResults, setVoteOptions] = useState(undefined);
    const [sessions, setSessionOptions] = useState(undefined);
    const [sessionResults, setSessionResults] = useState(undefined);
    const [parties, setSubjectOptions] = useState(undefined);
    const [votesData, setVotesData] = useState(undefined);
    const [sessionResultValue, setSessionResultValue] = useState(null);
    const [voteResultValue, setVoteResultValue] = useState(null);
    const [memberValue, setMemberValue] = useState(null);
    const [sessionValue, setSessionValue] = useState(null);
    const [partyValue, setPartyValue] = useState(null);
    const [checked, setChecked] = useState(null);
    const [checkedProc, setCheckedProc] = useState(null);
    const [checkedPublicVotings, setCheckedPublicVotings] = useState(null);
    const [input, setInput] = useState('');
    const [view, setView] = useState(null);
    const [member, setMember] = useState(null);
    const [party, setParty] = useState(null);
    const [session, setSession] = useState(null);
    const [sessionResult, setSessionResult] = useState(null);
    const [voteResult, setVoteResult] = useState(null);

    const isFilterSet = () => {
        return (view !== null || checked !== null || checkedProc !== null || checkedPublicVotings)
    }

    const response = useLoaderData();

    useEffect(() => {
        setMemberOptions(response[0].data);
        setVoteOptions(response[1].data);
        setSessionOptions(response[2].data);
        setSessionResults(response[3].data);
        setSubjectOptions(response[4].data);
        setVotesData(response[5].data);

        if (searchParams.get('subjekt')) {
            setPartyValue(searchParams.get('subjekt'));
        }
        if (searchParams.get('clen')) {
            setMemberValue(searchParams.get('clen'));
        }
        if (searchParams.get('hlasovaci_moznost')) {
            setVoteResultValue(searchParams.get('hlasovaci_moznost'));
        }
        if (searchParams.get('vysledek_hlasovani')) {
            setSessionResultValue(searchParams.get('vysledek_hlasovani'));
        }
        if (searchParams.get('zasedani')) {
            setSessionValue(searchParams.get('zasedani'));
        }
        if (searchParams.get('pouze_platna')) {
            setChecked(searchParams.get('pouze_platna') === 'true');
        }
        if (searchParams.get('neproceduralni')) {
            setCheckedProc(searchParams.get('neproceduralni') === 'true');
        }
        if (searchParams.get('verejna')) {
            setCheckedPublicVotings(searchParams.get('verejna') === 'true');
        }
        if (searchParams.get('text')) {
            setInput(searchParams.get('text'));
        }
        if (searchParams.get('pohled')) {
            setView(searchParams.get('pohled'));
        }

        if (sessionResultValue !== null && sessionResults !== undefined) {
            setSessionResult(
                sessionResults.filter((sessionResult) => sessionResult.code === parseInt(sessionResultValue))[0]
            );
        }
        if (voteResultValue !== null && voteResults !== undefined) {
            setVoteResult(voteResults.filter((voteResult) => voteResult.code === parseInt(voteResultValue))[0]);
        }
        if (memberValue !== null && members !== undefined) {
            setMember(members.filter((member) => member.code === parseInt(memberValue))[0]);
        }
        if (partyValue !== null && parties !== undefined) {
            setParty(parties.filter((party) => party.code === parseInt(partyValue))[0]);
        }
        if (sessionValue !== null && sessions !== undefined) {
            setSession(sessions.filter((session) => session.code === parseInt(sessionValue))[0]);
        }
    }, [
        memberValue,
        members,
        parties,
        partyValue,
        response,
        searchParams,
        sessionResultValue,
        sessionResults,
        sessionValue,
        sessions,
        voteResultValue,
        voteResults
    ]);

    const clearSearchParams = () => {
        const url = new URL(window.location);
        const params = new URLSearchParams(url.search);
        params.delete('text');
        params.delete('vysledek_hlasovani');
        params.delete('hlasovaci_moznost');
        params.delete('pouze_platna');
        params.delete('neproceduralni');
        params.delete('verejna');
        params.delete('clen');
        params.delete('subjekt');
        params.delete('zasedani');
        params.delete('pohled');

        setInput('');
        setSessionResult(null);
        setVoteResult(null);
        setChecked(null);
        setCheckedProc(null);
        setCheckedPublicVotings(null);
        setMember(null);
        setParty(null);
        setSession(null);
        setView(null);

        url.search = '';
        window.history.pushState({}, '', url);
    };

    const setParams = (key, value) => {
        const url = new URL(window.location);
        if (key === 'pohled') {
            // uklid v URL
            url.searchParams.delete('text');
            url.searchParams.delete('vysledek_hlasovani');
            url.searchParams.delete('hlasovaci_moznost');
            url.searchParams.delete('pouze_platna');
            url.searchParams.delete('neproceduralni');
            url.searchParams.delete('verejna');
            url.searchParams.delete('clen');
            url.searchParams.delete('subjekt');
            url.searchParams.delete('zasedani');

            // uklid vnitrnich stavu
            setInput('');
            setSessionResult(null);
            setVoteResult(null);
            setChecked(null);
            setCheckedProc(null);
            setCheckedPublicVotings(null);
            setMember(null);
            setParty(null);
            setSession(null);
        }
        url.searchParams.set(key, value);
        window.history.pushState({}, '', url);
    };

    const onInputChange = (e) => {
        setInput(e.target.value);
        if (e.target.value !== '') {
            // vse ok
            setParams('text', e.target.value);
        } else {
            // zruseni filtru
            const url = new URL(window.location);
            url.searchParams.delete('text');
            window.history.pushState({}, '', url);
        }
    };

    const onViewChange = (e) => {
        setView(e.value);
        setParams('pohled', e.value);
    };

    const onValidChange = (e) => {
        setChecked(e.checked);
        if (e.checked === true) {
            setParams('pouze_platna', e.checked);
        } else {
            // zruseni filtru
            const url = new URL(window.location);
            url.searchParams.delete('pouze_platna');
            window.history.pushState({}, '', url);
        }
    };

    const onProcChange = (e) => {
        setCheckedProc(e.checked);
        if (e.checked === true) {
            setParams('neproceduralni', e.checked);
        } else {
            // zruseni filtru
            const url = new URL(window.location);
            url.searchParams.delete('neproceduralni');
            window.history.pushState({}, '', url);
        }
    };

    const onCheckedPublicVotingsChange = (e) => {
        setCheckedPublicVotings(e.checked);
        if (e.checked === true) {
            setParams('verejna', e.checked);
        } else {
            // zruseni filtru
            const url = new URL(window.location);
            url.searchParams.delete('verejna');
            window.history.pushState({}, '', url);
        }
    };

    const onSessionResultChange = (e) => {
        if (!e.value) {
            const url = new URL(window.location);
            url.searchParams.delete('vysledek_hlasovani');
            window.history.pushState({}, '', url);
            setSessionResult(null);
        } else {
            setParams('vysledek_hlasovani', e.value.code);
            setSessionResult(e.value);
        }
    };

    const onVoteResultChange = (e) => {
        setVoteResult(e.value);
        setParams('hlasovaci_moznost', e.value.code);
    };

    const onPartyChange = (e) => {
        setParty(e.value);
        setParams('subjekt', e.value.code);
    };

    const onMemberChange = (e) => {
        setMember(e.value);
        if (e.value !== undefined) {
            // vse ok
            setParams('clen', e.value.code);
        } else {
            // zruseni filtru
            setVoteResult(null);
            setChecked(null);
            setCheckedProc(null);

            const url = new URL(window.location);
            url.searchParams.delete('clen');
            url.searchParams.delete('hlasovaci_moznost');
            url.searchParams.delete('pouze_platna');
            url.searchParams.delete('neproceduralni');
            window.history.pushState({}, '', url);
        }
    };

    const onSessionChange = (e) => {
        setSession(e.value);
        if (e.value !== undefined) {
            // vse ok
            setParams('zasedani', e.value.code);
        } else {
            // zruseni filtru
            setVoteResult(null);
            setChecked(null);
            setCheckedProc(null);

            const url = new URL(window.location);
            url.searchParams.delete('zasedani');
            url.searchParams.delete('vysledek_hlasovani');
            url.searchParams.delete('pouze_platna');
            url.searchParams.delete('neproceduralni');
            window.history.pushState({}, '', url);
        }
    };

    if (votesData !== undefined) {
        const summary_stats = [
            {
                text: t('votings', { ns: 'translation' }),
                value: votesData.pocet_hlasovani,
                icon: 'pi bi-stack'
            },
            {
                text: t('approved-proposals', { ns: 'translation' }),
                value: votesData.pocet_prijatych,
                icon: 'pi bi-check-square'
            },
            {
                text: t('rejected-proposals', { ns: 'translation' }),
                value: votesData.pocet_neprijatych,
                icon: 'pi bi-x-square'
            },
            {
                text: t('invalid-votings', { ns: 'translation' }),
                value: votesData.pocet_neplatnych,
                icon: 'pi bi-question-square'
            }
        ];

        return (
            <div className='container'>
                {/*<h1 className="page-title">{t("Votings", {ns: "translation"})}</h1>*/}
                <div className='votings-grid'>
                    <Card title={t('votings-summary', { ns: 'translation' })} className='summary-card'>
                        {summary_stats.map((stat, index) => (
                            <div key={index}>
                                <i className={`pi ${stat.icon} text__primary summary-icon`}> </i>
                                <div className='summary-value text__primary'>{stat.value}</div>
                                <p className='summary-text'>{stat.text}</p>
                            </div>
                        ))}
                    </Card>
                    <Card
                        className='chart-card'
                        title={
                            <span>
                                {t('parties-votings', { ns: 'translation' })}
                                <Tooltip target='.custom-target-icon5' />
                                <i
                                    className='custom-target-icon5 pi bi-info-circle text__secondary text-icon__right'
                                    data-pr-tooltip={t('chart-tooltip')}
                                    data-pr-position='right'
                                ></i>
                            </span>
                        }
                    >
                        <SummaryVotesGraph />
                    </Card>
                    <Card title={t('votings-overview', { ns: 'translation' })} className='table-card'>
                        <div className='grid'>
                            <div className='item-options'>
                                <div className='grid'>
                                    <div className='item-dropdown'>
                                        <h3>{t('search-by')}</h3>
                                        <div>
                                            <div className='table-radiobutton'>
                                                <RadioButton
                                                    inputId='view1'
                                                    name='view'
                                                    value='zasedani'
                                                    onChange={onViewChange}
                                                    checked={view === 'zasedani'}
                                                />
                                                <label htmlFor='view1'> {t('Meetings', { ns: 'translation' })} </label>
                                            </div>
                                            {view === 'zasedani' ? (
                                                <div className='table-options'>
                                                    <Dropdown
                                                        value={session}
                                                        options={sessions}
                                                        onChange={onSessionChange}
                                                        optionLabel='name'
                                                        filter
                                                        showClear
                                                        filterBy='name'
                                                        placeholder={t('select-meeting', { ns: 'translation' })}
                                                    />
                                                    <Dropdown
                                                        value={sessionResult}
                                                        options={sessionResults}
                                                        onChange={onSessionResultChange}
                                                        optionLabel='name'
                                                        showClear
                                                        placeholder={t('select-option', { ns: 'translation' })}
                                                    />
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div>
                                            <div className='table-radiobutton'>
                                                <RadioButton
                                                    inputId='view2'
                                                    name='view'
                                                    value='subjekt'
                                                    onChange={onViewChange}
                                                    checked={view === 'subjekt'}
                                                />
                                                <label htmlFor='view2'> {t('Party', { ns: 'translation' })} </label>
                                            </div>
                                            {view === 'subjekt' ? (
                                                <div className='table-options'>
                                                    <Dropdown
                                                        value={party}
                                                        options={parties}
                                                        onChange={onPartyChange}
                                                        optionLabel='name'
                                                        placeholder={t('select-party', { ns: 'translation' })}
                                                    />
                                                    <Dropdown
                                                        value={voteResult}
                                                        options={voteResults}
                                                        onChange={onVoteResultChange}
                                                        optionLabel='name'
                                                        placeholder={t('select-option', { ns: 'translation' })}
                                                    />
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div>
                                            <div className='table-radiobutton'>
                                                <RadioButton
                                                    inputId='view3'
                                                    name='view'
                                                    value='clen'
                                                    onChange={onViewChange}
                                                    checked={view === 'clen'}
                                                />
                                                <label htmlFor='view3'> {t('Member', { ns: 'translation' })}</label>
                                            </div>
                                            {view === 'clen' ? (
                                                <div className='table-options'>
                                                    <Dropdown
                                                        value={member}
                                                        options={members}
                                                        onChange={onMemberChange}
                                                        optionLabel='name'
                                                        filter
                                                        showClear
                                                        filterBy='name'
                                                        placeholder={t('select-member', { ns: 'translation' })}
                                                    />
                                                    <Dropdown
                                                        value={voteResult}
                                                        options={voteResults}
                                                        onChange={onVoteResultChange}
                                                        optionLabel='name'
                                                        placeholder={t('select-option', { ns: 'translation' })}
                                                    />
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div>
                                            <div className='table-radiobutton'>
                                                <RadioButton
                                                    inputId='view4'
                                                    name='view'
                                                    value='text'
                                                    onChange={onViewChange}
                                                    checked={view === 'text'}
                                                />
                                                <label htmlFor='view4'> {t('search-by-text')} </label>
                                            </div>
                                            {view === 'text' ? (
                                                <div className='table-options'>
                                                    <InputText
                                                        value={input || ''}
                                                        onChange={onInputChange}
                                                        placeholder={t('type-searched-text')}
                                                    />
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                    <div className='item-filter'>
                                        <h3>{t('filter')}</h3>
                                        <div className='table-radiobutton'>
                                            <Checkbox
                                                inputId='validVotings'
                                                checked={checked}
                                                onChange={onValidChange}
                                            />
                                            <label htmlFor='validVotings'> {t('valid-votings-only')}</label>
                                        </div>
                                        <div className='table-radiobutton'>
                                            <Checkbox
                                                inputId='nonProceduralVotings'
                                                checked={checkedProc}
                                                onChange={onProcChange}
                                            />
                                            <label htmlFor='nonProceduralVotings'>
                                                {' '}
                                                {t('non-procedural-votings-only')}{' '}
                                            </label>
                                        </div>
                                        <div className='table-radiobutton'>
                                            <Checkbox
                                                inputId='publicVotings'
                                                checked={checkedPublicVotings}
                                                onChange={onCheckedPublicVotingsChange}
                                            />
                                            <label htmlFor='publicVotings'>
                                                {' '}
                                                {t('public-votings-only')}{' '}
                                            </label>
                                        </div>
                                        {
                                            isFilterSet() && (
                                                <Button
                                                    label={t('cancel')}
                                                    className='p-button-text '
                                                    icon='bi bi-trash'
                                                    iconPos='right'
                                                    onClick={clearSearchParams}
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='item-table'>
                                <PollsTable
                                    municipalitaId={municipalitaId}
                                    bodyType={bodyType}
                                    text={input}
                                    checked={checked}
                                    checkedProc={checkedProc}
                                    checkedPublicVotings={checkedPublicVotings}
                                    view={view}
                                    zastupitelstvoId={zastupitelstvoId}
                                    sessionResult={sessionResult}
                                    voteResult={voteResult}
                                    member={member}
                                    party={party}
                                    session={session}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        );
    }
}

export default Votings;
