import PopupBodyChart from "../../../../components/map/popup/PopupBodyChart";
import PopupFooter from "../../../../components/map/popup/PopupFooter";
import PopupHeader from "../../../../components/map/popup/PopupHeader";
import data from "../data";
import geo from "../geo";
import meta from "../meta";

/**
 * crimes statistics.
 * 
 * @author Adam Janosik, Kristyna Zaklova
 */
const crimeCategories = ["Obecná kriminalita",	"Hospodářská kriminalita",	"Loupeže",	"Vloupání do bytů a rodinných domů",	"Znásilnění",	"Vraždy", "Ostatní kriminalita"]
const colors = ["#4e79a7", "#f28e2b", "#e15759", "#76b7b2", "#59a14f", "#edc948", "#b07aa1"];




const crimes23 = {
    label: "Rok 2023",
    data: data,
    categories: meta.trestneCiny23.values,
    getValue: meta.trestneCiny23.getValue,
    formatValue: meta.trestneCiny23.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle počtu trestných činů v jednotlivých krajích (rok 2023)",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Počet trestných činů se v jednotlivých krajích v roce <span
                                                className="emp"><strong>2023</strong></span> pohyboval od <span
                                                className="emp"><strong>41&nbsp;070</strong></span> do <span
                                                className="emp"><strong>4985</strong></span>.
                                                Nejhorší bilanci měla <span className="emp"><strong>Praha</strong></span> a
                                                nejlepší <span className="emp"><strong>Pradubický kraj</strong></span>.
                                            </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "stacked-bar",
                                        config: {
                                            geo: geo,
                                            datasets: [
                                                {
                                                    data: data,
                                                    getValue: (data, region) => data[region].trestneCinyDetail23 ? data[region].trestneCinyDetail23[0] : undefined,
                                                    formatValue: meta.trestneCinyDetail.formatValue,
                                                    backgroundColor: colors[0],
                                                    label: crimeCategories[0]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => data[region].trestneCinyDetail23 ? data[region].trestneCinyDetail23[1] : undefined,
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[1],
                                                    label: crimeCategories[1]
                                                },
                                                {
                                                    data: data,
                                                    getValue: (data, region) => data[region].trestneCinyDetail23 ? data[region].trestneCinyDetail23[2] : undefined,
                                                    formatValue: meta.trestneCinyDetail.formatValue,
                                                    backgroundColor: colors[2],
                                                    label: crimeCategories[2]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => data[region].trestneCinyDetail23 ? data[region].trestneCinyDetail23[3] : undefined,
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[3],
                                                    label: crimeCategories[3]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => data[region].trestneCinyDetail23 ? data[region].trestneCinyDetail23[4] : undefined,
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[4],
                                                    label: crimeCategories[4]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => data[region].trestneCinyDetail23 ? data[region].trestneCinyDetail23[5] : undefined,
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[5],
                                                    label: crimeCategories[5]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => data[region].trestneCinyDetail23 ? data[region].trestneCiny23 - sumArray(data[region].trestneCinyDetail23)  : undefined,
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[6],
                                                    label: crimeCategories[6]
                                                }
                                            ],
                                            formatValue: meta.pocetHlasovaniPrijata.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroj:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ kriminalita (rok 2023)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2023"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const crimes22 = {
    label: "Rok 2022",
    data: data,
    categories: meta.trestneCiny22.values,
    getValue: meta.trestneCiny22.getValue,
    formatValue: meta.trestneCiny22.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle počtu trestných činů v jednotlivých krajích (rok 2022)",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Počet trestných činů se v jednotlivých krajích v roce <span
                                                className="emp"><strong>2022</strong></span> pohyboval od <span
                                                className="emp"><strong>40&nbsp;704</strong></span> do <span
                                                className="emp"><strong>4939</strong></span>.
                                                Nejhorší bilanci měla <span className="emp"><strong>Praha</strong></span> a
                                                nejlepší <span className="emp"><strong>Karlovarský kraj</strong></span>.
                                            </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "stacked-bar",
                                        config: {
                                            geo: geo,
                                            datasets: [
                                                {
                                                    data: data,
                                                    getValue: (data, region) => data[region].trestneCinyDetail22 ? data[region].trestneCinyDetail22[0] : undefined,
                                                    formatValue: meta.trestneCinyDetail.formatValue,
                                                    backgroundColor: colors[0],
                                                    label: crimeCategories[0]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => data[region].trestneCinyDetail22 ? data[region].trestneCinyDetail22[1] : undefined,
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[1],
                                                    label: crimeCategories[1]
                                                },
                                                {
                                                    data: data,
                                                    getValue: (data, region) => data[region].trestneCinyDetail22 ? data[region].trestneCinyDetail22[2] : undefined,
                                                    formatValue: meta.trestneCinyDetail.formatValue,
                                                    backgroundColor: colors[2],
                                                    label: crimeCategories[2]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => data[region].trestneCinyDetail22 ? data[region].trestneCinyDetail22[3] : undefined,
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[3],
                                                    label: crimeCategories[3]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => data[region].trestneCinyDetail22 ? data[region].trestneCinyDetail22[4] : undefined,
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[4],
                                                    label: crimeCategories[4]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => data[region].trestneCinyDetail22 ? data[region].trestneCinyDetail22[5] : undefined,
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[5],
                                                    label: crimeCategories[5]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => data[region].trestneCinyDetail22 ? data[region].trestneCiny22 - sumArray(data[region].trestneCinyDetail22)  : undefined,
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[6],
                                                    label: crimeCategories[6]
                                                }
                                            ],
                                            formatValue: meta.pocetHlasovaniPrijata.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroj:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ kriminalita (rok 2022)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2022"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const crimes21 = {
    label: "Rok 2021",
    data: data,
    categories: meta.trestneCiny21.values,
    getValue: meta.trestneCiny21.getValue,
    formatValue: meta.trestneCiny21.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle počtu trestných činů v jednotlivých krajích (rok 2021)",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Počet trestných činů se v jednotlivých krajích v roce <span
                                                className="emp"><strong>2021</strong></span> pohyboval od <span
                                                className="emp"><strong>35&nbsp;136</strong></span> do <span
                                                className="emp"><strong>3754</strong></span>.
                                                Nejhorší bilanci měla <span className="emp"><strong>Praha</strong></span> a
                                                nejlepší <span className="emp"><strong>Karlovarský kraj</strong></span>.
                                            </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "stacked-bar",
                                        config: {
                                            geo: geo,
                                            datasets: [
                                                {
                                                    data: data,
                                                    getValue: (data, region) => data[region].trestneCinyDetail21 ? data[region].trestneCinyDetail21[0] : undefined,
                                                    formatValue: meta.trestneCinyDetail.formatValue,
                                                    backgroundColor: colors[0],
                                                    label: crimeCategories[0]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => data[region].trestneCinyDetail21 ? data[region].trestneCinyDetail21[1] : undefined,
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[1],
                                                    label: crimeCategories[1]
                                                },
                                                {
                                                    data: data,
                                                    getValue: (data, region) => data[region].trestneCinyDetail21 ? data[region].trestneCinyDetail21[2] : undefined,
                                                    formatValue: meta.trestneCinyDetail.formatValue,
                                                    backgroundColor: colors[2],
                                                    label: crimeCategories[2]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => data[region].trestneCinyDetail21 ? data[region].trestneCinyDetail21[3] : undefined,
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[3],
                                                    label: crimeCategories[3]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => data[region].trestneCinyDetail21 ? data[region].trestneCinyDetail21[4] : undefined,
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[4],
                                                    label: crimeCategories[4]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => data[region].trestneCinyDetail21 ? data[region].trestneCinyDetail21[5] : undefined,
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[5],
                                                    label: crimeCategories[5]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => data[region].trestneCinyDetail21 ? data[region].trestneCiny21 - sumArray(data[region].trestneCinyDetail21)  : undefined,
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[6],
                                                    label: crimeCategories[6]
                                                }
                                            ],
                                            formatValue: meta.pocetHlasovaniPrijata.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroj:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ kriminalita (rok 2021)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2021"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const crimes20 = {
    label: "Rok 2020",
    data: data,
    categories: meta.trestneCiny20.values,
    getValue: meta.trestneCiny20.getValue,
    formatValue: meta.trestneCiny20.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle počtu trestných činů v jednotlivých krajích (rok 2020)",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Počet trestných činů se v jednotlivých krajích v roce <span
                                                className="emp"><strong>2020</strong></span> pohyboval od <span
                                                className="emp"><strong>38&nbsp;059</strong></span> do <span
                                                className="emp"><strong>4422</strong></span>.
                                                Nejhorší bilanci měla <span className="emp"><strong>Praha</strong></span> a
                                                nejlepší <span className="emp"><strong>Karlovarský kraj</strong></span>.
                                            </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "stacked-bar",
                                        config: {
                                            geo: geo,
                                            datasets: [
                                                {
                                                    data: data,
                                                    getValue: (data, region) => data[region].trestneCinyDetail20 ? data[region].trestneCinyDetail20[0] : undefined,
                                                    formatValue: meta.trestneCinyDetail.formatValue,
                                                    backgroundColor: colors[0],
                                                    label: crimeCategories[0]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => data[region].trestneCinyDetail20 ? data[region].trestneCinyDetail20[1] : undefined,
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[1],
                                                    label: crimeCategories[1]
                                                },
                                                {
                                                    data: data,
                                                    getValue: (data, region) => data[region].trestneCinyDetail20 ? data[region].trestneCinyDetail20[2] : undefined,
                                                    formatValue: meta.trestneCinyDetail.formatValue,
                                                    backgroundColor: colors[2],
                                                    label: crimeCategories[2]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => data[region].trestneCinyDetail20 ? data[region].trestneCinyDetail20[3] : undefined,
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[3],
                                                    label: crimeCategories[3]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => data[region].trestneCinyDetail20 ? data[region].trestneCinyDetail20[4] : undefined,
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[4],
                                                    label: crimeCategories[4]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => data[region].trestneCinyDetail20 ? data[region].trestneCinyDetail20[5] : undefined,
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[5],
                                                    label: crimeCategories[5]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => data[region].trestneCinyDetail20 ? data[region].trestneCiny20 - sumArray(data[region].trestneCinyDetail20)  : undefined,
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[6],
                                                    label: crimeCategories[6]
                                                }
                                            ],
                                            formatValue: meta.pocetHlasovaniPrijata.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroj:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ kriminalita (rok 2020)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2020"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const crimesInc = {
    label: "2020 vs. 2023",
    data: data,
    categories: meta.trestneCinyVyvoj.values,
    getValue: meta.trestneCinyVyvoj.getValue,
    formatValue: meta.trestneCinyVyvoj.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle vývoje počtu trestných činů v jednotlivých krajích v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Počet trestných činů v letech <span className="emp"><strong>2020–2023</strong></span> ve všech krajích vzrostl, a to o <span className="emp"><strong>207</strong></span> až <span className="emp"><strong>3&nbsp;011</strong></span>. Největší nárůst byl v <span className="emp"><strong>Praze</strong></span> a nejmenší v <span className="emp"><strong>Pardubickém kraji</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.trestneCinyVyvoj.values,
                                            getValue: meta.trestneCinyVyvoj.getValue,
                                            formatValue: meta.trestneCinyVyvoj.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ (2023)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2023"
                                        },
                                        {
                                            label: "ČSÚ (2022)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2022"
                                        },
                                        {
                                            label: "ČSÚ (2021)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2021"
                                        },
                                        {
                                            label: "ČSÚ (2020)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2020"
                                        },
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const crimesSum = {
    label: "Suma 2020-2023",
    data: data,
    categories: meta.trestneCinySuma.values,
    getValue: meta.trestneCinySuma.getValue,
    formatValue: meta.trestneCinySuma.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle sumy počtu trestných činů v jednotlivých krajích v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Počet trestných činů se v jednotlivých krajích za <span
                                                className="emp"><strong>celé období</strong></span> pohyboval od <span
                                                className="emp"><strong>154&nbsp;969</strong></span> do <span
                                                className="emp"><strong>18&nbsp;156</strong></span>.
                                                Nejhorší bilanci měla <span className="emp"><strong>Praha</strong></span> a
                                                nejlepší <span className="emp"><strong>Karlovarský kraj</strong></span>.
                                            </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "stacked-bar",
                                        config: {
                                            geo: geo,
                                            datasets: [
                                                {
                                                    data: data,
                                                    getValue: (data, region) => sumIndex(0,data[region].trestneCinyDetail20,data[region].trestneCinyDetail21,data[region].trestneCinyDetail22,data[region].trestneCinyDetail23),
                                                    formatValue: meta.trestneCinyDetail.formatValue,
                                                    backgroundColor: colors[0],
                                                    label: crimeCategories[0]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => sumIndex(1,data[region].trestneCinyDetail20,data[region].trestneCinyDetail21,data[region].trestneCinyDetail22,data[region].trestneCinyDetail23),
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[1],
                                                    label: crimeCategories[1]
                                                },
                                                {
                                                    data: data,
                                                    getValue: (data, region) => sumIndex(2,data[region].trestneCinyDetail20,data[region].trestneCinyDetail21,data[region].trestneCinyDetail22,data[region].trestneCinyDetail23),
                                                    formatValue: meta.trestneCinyDetail.formatValue,
                                                    backgroundColor: colors[2],
                                                    label: crimeCategories[2]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => sumIndex(3,data[region].trestneCinyDetail20,data[region].trestneCinyDetail21,data[region].trestneCinyDetail22,data[region].trestneCinyDetail23),
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[3],
                                                    label: crimeCategories[3]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => sumIndex(4,data[region].trestneCinyDetail20,data[region].trestneCinyDetail21,data[region].trestneCinyDetail22,data[region].trestneCinyDetail23),
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[4],
                                                    label: crimeCategories[4]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => sumIndex(5,data[region].trestneCinyDetail20,data[region].trestneCinyDetail21,data[region].trestneCinyDetail22,data[region].trestneCinyDetail23),
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[5],
                                                    label: crimeCategories[5]
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: (data, region) => data[region].trestneCinyDetail22 ? (data[region].trestneCiny20 - sumArray(data[region].trestneCinyDetail20)) + (data[region].trestneCiny21 - sumArray(data[region].trestneCinyDetail21)) + (data[region].trestneCiny22 - sumArray(data[region].trestneCinyDetail22)) + (data[region].trestneCiny23 - sumArray(data[region].trestneCinyDetail23)) : undefined,
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: colors[6],
                                                    label: crimeCategories[6]
                                                }
                                            ],
                                            formatValue: meta.pocetHlasovaniPrijata.formatValue
                                        }
                                    }
                                },
                                {
                                    title: "Vývoj trestných činů v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                                Lze vidět mírně vzrůstající trend ve všech krajích s propadem v období onemocnění Covid-19.
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "line",
                                        config: {
                                            geo: geo,
                                            datasets: [
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.trestneCiny20.values,
                                                    getValue: meta.trestneCiny20.getValue,
                                                    formatValue: meta.trestneCiny20.formatValue,
                                                    label: meta.trestneCiny20.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.trestneCiny21.values,
                                                    getValue: meta.trestneCiny21.getValue,
                                                    formatValue: meta.trestneCiny21.formatValue,
                                                    label: meta.trestneCiny21.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.trestneCiny22.values,
                                                    getValue: meta.trestneCiny22.getValue,
                                                    formatValue: meta.trestneCiny22.formatValue,
                                                    label: meta.trestneCiny22.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.trestneCiny23.values,
                                                    getValue: meta.trestneCiny23.getValue,
                                                    formatValue: meta.trestneCiny23.formatValue,
                                                    label: meta.trestneCiny23.label
                                                },
                                            ]
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ (2023)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2023"
                                        },
                                        {
                                            label: "ČSÚ (2022)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2022"
                                        },
                                        {
                                            label: "ČSÚ (2021)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2021"
                                        },
                                        {
                                            label: "ČSÚ (2020)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2020"
                                        },
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}


const crimes = {
    title: 'Počet trestných činů',
    widget: {
        type: "column-group",
        config: {
            widgets: [
                {
                    type: "custom",
                    config: {
                        content: (
                            <p>
                                <span className="emp">Počet <strong>trestných činů</strong></span> a&nbsp;jejich závažnost se může lišit v&nbsp;závislosti na specifikách jednotlivých krajů, jako jsou hustota osídlení, sociální situace nebo míra nezaměstnanosti. 
                                Větší městské oblasti mají zpravidla vyšší počet trestných činů, zatímco méně zalidněné regiony vykazují nižší kriminalitu. 
                                Sledování a&nbsp;analýza trestných činů umožňuje lepší cílení preventivních opatření a&nbsp;zajištění větší bezpečnosti pro obyvatele.
                            </p>
                        )
                    }
                },
                {
                    type: "regional-filtered-choropleth",
                    config: {
                        id: "mapa-trestne-ciny",
                        label: "Počet trestných činů",
                        geo: geo,
                        datasets: [
                            crimesSum, crimes23, crimes22, crimes21, crimes20, crimesInc
                        ]
                    }
                },
                {
                    type: "custom",
                    config: {
                        content: (
                            <p>
                                <span className="emp">Sledované <strong>delikty</strong></span> jsou členěny do následujích kategorií: obecná kriminalita (krádeže, loupeže, fyzická napadení, podvody, vandalismus apod.), hospodářská kriminalita (nelegální aktivity v oblasti firemních financí, účetnictví apod.), loupeže, vloupání do bytů a&nbsp;rodinných domů, znásilnění a&nbsp;vraždy.
                            </p>
                        )
                    }
                }
            ]
        }
    },
    footer: {
        left: {
            title: {
                label: "Zdroj:",
                icon: "pi pi-fw pi-globe"
            },
            links: [
                {
                    label: "ČSÚ –⁠⁠⁠⁠⁠⁠ kriminalita",
                    value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt-parametry&z=T&f=TABULKA&katalog=31008&sp=A&pvo=KRI06&c=v3~8__RP2020&evo=v257_%21_VUZEMI97-100mv_1&str=v132"
                }
            ]
        },
        right: {
            title: undefined,
            links: [
                meta.route
            ]
        },
        popup: {
            component: crimesSum.map.defaults.polygon.popup
        }
    }
}
export default crimes;


function sumIndex(index, arr1, arr2, arr3, arr4) {
    return arr1[index] + arr2[index] + arr3[index] + arr4[index]
}



function sumArray(arr) {
    return arr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}