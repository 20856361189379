import React from "react";

import { Tooltip } from "primereact/tooltip";

import "./RegionalMapLegend.css"

function RegionalMapLegend({
    id = undefined,
    categories = {},
    onMouseEnter = undefined,
    onMouseLeave = undefined,
    onClick = undefined,
    filteredColor = "#f0f0f0"
}) {

    const [ disabledCategories, setDisabledCategories ] = React.useState([]);

    /**
     * Help method which updates the list of disabled categories
     * and invokes onClick callback.
     * 
     * @param {*} cat 
     */
    const _onClick = (cat) => {
        let _disabledCategories;
        
        if(disabledCategories.includes(cat)) {
            setDisabledCategories(_disabledCategories = disabledCategories.filter(c => c !== cat));
        } else {
            setDisabledCategories(_disabledCategories = [...disabledCategories, cat]);
        }
        //cat.classList.toggle("disabled");

        onClick && onClick(_disabledCategories);
    }

    return (
        <>
            <div className="legend">
                {
                    categories && Object.keys(categories).map(cat => 
                        <div key={cat}>
                            <Tooltip target={`#legend-popup-${id}-${cat}`} content={categories[cat].description} />
                            <div
                                id={`legend-popup-${id}-${cat}`}
                                className={"legend-category " + (disabledCategories.includes(cat) ? "disabled" : "")}
                                onMouseEnter={onMouseEnter ? () => onMouseEnter(cat) : undefined}
                                onMouseLeave={onMouseLeave ? () => onMouseLeave(cat) : undefined}
                                onClick={() => _onClick(cat)}
                            >
                                <div className="legend-color" style={{ 
                                    backgroundColor: disabledCategories.includes(cat) ? filteredColor : categories[cat].color
                                }}></div>
                                <div className="legend-label">{categories[cat].name}</div>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default RegionalMapLegend;