import { Messages } from 'primereact/messages';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProgressSpinner } from '../../../components/PrimereactComponents';
import { getErrorMessage } from '../../../utils/errors';
import { ButtonAdd } from '../../components/Buttons';
import { useUserPermissions } from '../../hooks/useUserPermissions';
import authAxios from '../../lib/authAxios';
import { Permission, UserRole } from '../../constants/enums';
import { hasPermission, hasRole } from '../../utils/auth';
import { showErrorMessage, showSuccessMessage } from '../../utils/messages';
import { AddRequestDialog } from './components/AddRequestDialog';
import { ConfirmDeleteMunicipalityDialog } from './components/ConfirmDeleteMunicipalityDialog';
import { MunicipalityTable } from './components/MunicipalityTable';
import { MunicipalityBreadCrumb } from '../../components/MunicipalityBreadCrumb';

export default function Municipalities() {
    const { municipalityId } = useParams();

    const { user } = useUserPermissions();

    const [municipalities, setMunicipalities] = useState(null);
    const [loading, setLoading] = useState(true);

    const [selectedMunicipality, setSelectedMunicipality] = useState(null);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [processingDelete, setProcessingDelete] = useState(false);

    const [showAddRequestDialog, setShowAddRequestDialog] = useState(false);
    const [processingAddRequest, setprocessingAddRequest] = useState(false);
    const refAddRequestDialogMsgs = useRef(null);
    const refAddRequestDialogForm = useRef(null);

    const navigate = useNavigate();

    const msgs = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await authAxios.get('admin/municipality');
                setMunicipalities(response.data);
            } catch (error) {
                const errorMessage = getErrorMessage(error);
                showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se načíst municipality');
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const confirmDelete = (rowData) => {
        setSelectedMunicipality(rowData);
        setShowDeleteDialog(true);
    };

    const hideDeleteDialog = () => {
        setShowDeleteDialog(false);
        setSelectedMunicipality(null);
    };

    const handleDeleteMunicipality = async () => {
        hideDeleteDialog();
        if (selectedMunicipality) {
            setProcessingDelete(true);
            try {
                await authAxios.delete(`admin/municipality/${selectedMunicipality.text_id}`);
                setMunicipalities(municipalities.filter((m) => m.text_id !== selectedMunicipality.text_id));
                showSuccessMessage(
                    msgs,
                    `Municipalita ${selectedMunicipality.name} (${selectedMunicipality.text_id}) byla úspěšně smazána`,
                    { replace: true }
                );
            } catch (error) {
                const errorMessage = getErrorMessage(error);
                showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se smazat municipalitu', { replace: true });
            } finally {
                setProcessingDelete(false);
            }
        }
    };

    const handleClickAddRequest = (rowData) => {
        setSelectedMunicipality(rowData);
        setShowAddRequestDialog(true);
    };

    const hideAddRequestDialog = () => {
        setShowAddRequestDialog(false);
        setSelectedMunicipality(null);
    };

    const handleAddRequest = async (data) => {
        setprocessingAddRequest(true);
        const requestData = {
            municipality_text_id: selectedMunicipality.text_id,
            text: data.text
        };
        try {
            await authAxios.post('admin/request/manage-municipality', requestData);
            showSuccessMessage(
                msgs,
                `Žádost o správu municipality ${selectedMunicipality.name} (${selectedMunicipality.text_id}) byla úspěšně podána`
            );
            hideAddRequestDialog();
            refAddRequestDialogForm?.current?.reset();
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(
                refAddRequestDialogMsgs,
                errorMessage ?? 'Nepodařilo se podat žádost o správu municipality',
                { replace: true }
            );
        } finally {
            setprocessingAddRequest(false);
        }
    };

    const handleRowClick = (event) => {
        if (hasPermission(user, Permission.MANAGE_MUNICIPALITY, event.data.text_id)) {
            navigate(`/admin/municipality/${event.data.text_id}/funkcni-obdobi`);
        }
    };

    return (
        <>
            {municipalityId && <MunicipalityBreadCrumb />}
            <h1>Municipality</h1>

            <div className='page__buttons'>
                {hasRole(user, UserRole.ADMIN) && (
                    <ButtonAdd
                        label='Přidat municipalitu'
                        disabled={processingDelete || processingAddRequest}
                        onClick={() => navigate('/admin/municipality/new')}
                    />
                )}
                {hasRole(user, UserRole.USER) && (
                    <ButtonAdd
                        label='Přidat municipalitu'
                        tooltip='Požádat o přidání nové municipality'
                        disabled={processingDelete || processingAddRequest}
                        onClick={() => navigate('/admin/nova-municipalita')}
                    />
                )}
                {(processingDelete || processingAddRequest) && <ProgressSpinner />}
            </div>

            <Messages ref={msgs} className='page__messages' />

            <MunicipalityTable
                municipalities={municipalities}
                loading={loading}
                user={user}
                onRowClick={handleRowClick}
                onClickDelete={confirmDelete}
                onClickAddRequest={handleClickAddRequest}
                disabled={processingDelete || processingAddRequest}
            />

            <ConfirmDeleteMunicipalityDialog
                isVisible={showDeleteDialog}
                onHide={hideDeleteDialog}
                handleDeleteMunicipality={handleDeleteMunicipality}
                municipality={selectedMunicipality}
            />
            <AddRequestDialog
                isVisible={showAddRequestDialog}
                onHide={hideAddRequestDialog}
                onSubmit={handleAddRequest}
                municipality={selectedMunicipality}
                msgs={refAddRequestDialogMsgs}
                ref={refAddRequestDialogForm}
            />
        </>
    );
}
