import axios from 'axios';
import home from './config/home/home';
import stats from './config/stats/stats';
import datasets from './config/datasets/datasets';
import partners from './config/partners/partners';
import about from './config/about/about';
import { API_BASE_URL } from './config/api';

axios.defaults.baseURL = API_BASE_URL;

/**
 * Home page loader
 * 
 * @param {*} param0 
 * @returns 
 */
export const HomeLoader = async({ params }) => {
    const responses =
        await Promise.all([
            axios.get(`municipalities`),
            axios.get(`appInfo`),
        ]);
    return home(responses);
}

/**
 * Stats loader
 * 
 * @param {*} param0 
 * @returns 
 */
export const StatsLoader = async({ params }) => {
    return stats(undefined);
}

export const DatasetLoader = async({ params }) => {
    return datasets(undefined);
}

export const PartnersLoader = async({ params }) => {
    return partners(undefined);
}

export const AboutLoader = async({ params }) => {
    return about(undefined);
}

export const MunicipalityHelmetLoader = async({ params }) => {
    const response = await axios.get(`municipality/${params.municipalitaId}`);
    return response.data;
}

export const Home2Loader = async({ params }) => {
    const responses =
        await Promise.all([
            axios.get(`${params.municipalitaId}/${params.bodyType}/${params.zastupitelstvoId}/generalData`),
            axios.get(`municipality/${params.municipalitaId}`),
            axios.get(`${params.municipalitaId}/${params.bodyType}/${params.zastupitelstvoId}/partiesData`),
            axios.get(`${params.municipalitaId}/${params.bodyType}/${params.zastupitelstvoId}/votesData`)
        ]);
    return responses;
}

export const VotingsLoader = async({ params }) => {
    const responses = await Promise.all([
        axios.get(`FE/${params.municipalitaId}/${params.bodyType}/${params.zastupitelstvoId}/dropdownMemberOptions`),
        axios.get(`FE/${params.municipalitaId}/${params.bodyType}/dropdownVoteOptions`),
        axios.get(`FE/${params.municipalitaId}/${params.bodyType}/${params.zastupitelstvoId}/dropdownSessionOptions`),
        axios.get(`FE/${params.municipalitaId}/${params.bodyType}/dropdownSessionResults`),
        axios.get(`FE/${params.municipalitaId}/${params.bodyType}/${params.zastupitelstvoId}/dropdownSubjectOptions`),
        axios.get(`${params.municipalitaId}/${params.bodyType}/${params.zastupitelstvoId}/votesData`),
    ]);
    return responses;
}

export const VotingDetailLoader = async({ params }) => { const response = await axios.get(`${params.municipalitaId}/${params.bodyType}/${params.zastupitelstvoId}/vote/${params.hlasovaniId}`); return response.data };

export const CouncilCompositionLoader = async({ params }) => {
    const responses =
        await Promise.all([
            axios.get(`${params.municipalitaId}/${params.bodyType}/${params.zastupitelstvoId}/generalData`),
            axios.get(`${params.municipalitaId}/${params.bodyType}/${params.zastupitelstvoId}/partiesData`),
            axios.get(`${params.municipalitaId}/${params.bodyType}/${params.zastupitelstvoId}/partiesStats`),
            axios.get(`municipality/${params.municipalitaId}`),
            axios.get(`/FE/${params.municipalitaId}/${params.bodyType}/${params.zastupitelstvoId}/dropdownSubjectOptions`),
            axios.get(`/FE/${params.municipalitaId}/${params.bodyType}/${params.zastupitelstvoId}/dropdownMemberOptions`)
        ]);
    return responses;
}

export const AboutMunicipalityLoader = async({ params }) => { const response = await axios.get(`municipality/${params.municipalitaId}`); return response.data };

export const MeetingsLoader = async({ params }) => {
    const responses =
        await Promise.all([
            axios.get(`${params.municipalitaId}/${params.bodyType}/${params.zastupitelstvoId}/sessionsData`),
            axios.get(`municipality/${params.municipalitaId}`),
            axios.get(`FE/${params.municipalitaId}/${params.bodyType}/${params.zastupitelstvoId}/dropdownYearOptions`)
        ]);
    return responses;
}

export const MeetingDetailLoader = async({ params }) => {
    const responses =
        await Promise.all([
            axios.get(`${params.municipalitaId}/${params.bodyType}/${params.zastupitelstvoId}/session/${parseInt(params.zasedaniId)}`),
            axios.get(`${params.municipalitaId}/${params.bodyType}/${params.zastupitelstvoId}/sessionAttendance/${parseInt(params.zasedaniId)}`)
        ]);
    return responses;
}

export const PartyLoader = async({ params }) => { const response = await axios.get(`${params.municipalitaId}/${params.bodyType}/${params.zastupitelstvoId}/party/${params.subjektId}`); return response.data };

export const MemberLoader = async({ params }) => { return await axios.get(`${params.municipalitaId}/${params.bodyType}/${params.zastupitelstvoId}/member/${params.zastupitelId}`) };