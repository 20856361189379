import { useState, useEffect } from 'react';
import { Dropdown } from './PrimereactComponents';
import { useTranslation } from 'react-i18next';

function LanguageSelection() {
    const LOCAL_STORAGE_KEY = 'lang';
    const { i18n } = useTranslation();
    const [showLanguageSelection] = useState(process.env.REACT_APP_ENABLE_I18N === 'true' ? true : false);
    const [selectedLanguage, setSelectedLanguage] = useState(() => {
        const storedLang = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
        return {
            name: storedLang.name,
            code: storedLang.code
        };
    });

    //TODO přidat a načítat z DB
    const languages = [
        { name: 'CZ', code: 'cs' },
        { name: 'EN', code: 'en' }
    ];

    useEffect(() => {
        i18n.changeLanguage(selectedLanguage.code);
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(selectedLanguage));
        document.documentElement.setAttribute('lang', selectedLanguage.code);
    }, [selectedLanguage]);

    return (
        showLanguageSelection && (
            <Dropdown
                id='lngSelect'
                value={selectedLanguage}
                onChange={(e) => setSelectedLanguage(e.value)}
                options={languages}
                optionLabel='name'
                className='lng-selection'
            />
        )
    );
}

export default LanguageSelection;
