import { mesta } from "./mesta"

export const dev = {
    decin: {
        id: 4,
        text_id: "decin",
        typ_nazev: "Statutární město",
        rada: false,
        nazev: "Děčín",
        nazev_genitiv: "Děčína",
        logo: "flask/municipality/decin/logo",
        barva: "#006FB7",
        url: "https://www.mmdecin.cz/",
        deploy: {
            local: {
                subdomain: "dev",
                path: "decin"
            }
        },
        email: "",
        partner: false,
        uplnyDataset: true,
        organy: {
            zastupitelstvo: {
                nazev_aplikace: "Vizualizace hlasování Zastupitelstva města Děčín",
                nazev: "Zastupitelstvo města Děčín",
                nazev_genitiv: "Zastupitelstva města Děčín",
                misto: null,
                pocet_zastupitelu: 27,
                popis_aplikace: "Přehledné vizualizace z 9. Zastupitelstva města Děčín pro širokou veřejnost."
            }
        }
    },
    hk: {
        id: 6,
        text_id: "hk",
        typ_nazev: "Statutární město",
        rada: false,
        nazev: "Hradec Králové",
        nazev_genitiv: "Hradce Králové",
        logo: "flask/municipality/hk/logo",
        deploy: {
            local: {
                subdomain: "dev",
                path: "hk"
            }
        },
        barva: "#0083CE",
        url: "https://www.hradeckralove.org/",
        email: "",
        partner: false,
        uplnyDataset: true,
        organy: {
            zastupitelstvo: {
                nazev_aplikace: "Vizualizace hlasování Zastupitelstva města Hradce Králové",
                nazev: "Zastupitelstvo města Hradec Králové",
                nazev_genitiv: "Zastupitelstva města Hradec Králové",
                misto: null,
                pocet_zastupitelu: 37,
                popis_aplikace: "Přehledné vizualizace z 9. Zastupitelstva města Hradec Králové pro širokou veřejnost."
            }
        }
    },
    karvina: {
        id: 5,
        text_id: "karvina",
        typ_nazev: "Statutární město",
        rada: false,
        nazev: "Karviná",
        nazev_genitiv: "Karviné",
        logo: "flask/municipality/karvina/logo",
        barva: "#C81428",
        url: "https://www.karvina.cz/",
        deploy: {
            local: {
                subdomain: "dev",
                path: "karvina"
            }
        },
        email: "",
        partner: false,
        uplnyDataset: true,
        organy: {
            zastupitelstvo: {
                nazev_aplikace: "Vizualizace hlasování Zastupitelstva města Karviná",
                nazev: "Zastupitelstvo hlavního města Karviná",
                nazev_genitiv: "Zastupitelstva hlavního města Karviná",
                misto: null,
                pocet_zastupitelu: 65,
                popis_aplikace: "Přehledné vizualizace z 9. Zastupitelstva hlavního města Karviná pro širokou veřejnost."
            }
        }
    }
}

export const devList = [
    dev.decin,
    dev.hk,
    dev.karvina,
    mesta.praha
]