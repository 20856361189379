import { Messages } from 'primereact/messages';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getErrorMessage } from '../../../utils/errors';
import { ButtonAdd } from '../../components/Buttons';
import authAxios from '../../lib/authAxios';
import { showErrorMessage, showSuccessMessage } from '../../utils/messages';
import { ConfirmDeleteVoteDialog } from './components/ConfirmDeleteVoteDialog';
import { VoteTable } from './components/VoteTable';
import { VoteBreadCrumb } from './components/VoteBreadCrumb';

export default function Votes() {
    const { municipalityId, termId, meetingId } = useParams();
    const bodyType = 'zastupitelstvo';

    const [votes, setVotes] = useState(null);
    const [loading, setLoading] = useState(true);

    const [selectedVote, setSelectedVote] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [processingDelete, setProcessingDelete] = useState(false);

    const msgs = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await authAxios.get(`admin/${municipalityId}/${bodyType}/meeting/${meetingId}/vote`);
                setVotes(response.data);
            } catch (error) {
                const errorMessage = getErrorMessage(error);
                showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se načíst hlasování');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [meetingId, municipalityId]);

    const confirmDelete = (rowData) => {
        setSelectedVote(rowData);
        setShowDeleteDialog(true);
    };

    const hideDeleteDialog = () => {
        setShowDeleteDialog(false);
        setSelectedVote(null);
    };

    const handleDeleteVote = async () => {
        hideDeleteDialog();
        if (selectedVote) {
            setProcessingDelete(true);
            try {
                await authAxios.delete(`admin/${municipalityId}/${bodyType}/vote/${selectedVote.id}`);
                setVotes(votes.filter((v) => v.id !== selectedVote.id));
                showSuccessMessage(msgs, `Hlasování č. ${selectedVote.number} bylo úspěšně smazáno`);
            } catch (error) {
                const errorMessage = getErrorMessage(error);
                showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se smazat hlasování', { replace: true });
            } finally {
                setProcessingDelete(false);
            }
        }
    };

    return (
        <>
            <VoteBreadCrumb />
            <h1>Hlasování</h1>

            <div className='page__buttons'>
                <ButtonAdd
                    label='Přidat hlasování'
                    disabled={processingDelete}
                    onClick={() => {
                        navigate(
                            `/admin/municipality/${municipalityId}/funkcni-obdobi/${termId}/zasedani/${meetingId}/hlasovani/new`
                        );
                    }}
                />
                {processingDelete && <ProgressSpinner />}
            </div>

            <Messages ref={msgs} className='page__messages' />

            <VoteTable votes={votes} loading={loading} onClickDelete={confirmDelete} disabled={processingDelete} />

            <ConfirmDeleteVoteDialog
                visible={showDeleteDialog}
                onHide={hideDeleteDialog}
                handleDeleteVote={handleDeleteVote}
                vote={selectedVote}
            />
        </>
    );
}
