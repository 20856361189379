import React from "react";
import { Divider } from "./PrimereactComponents";
import { useTranslation } from "react-i18next";

import "./MunicipalityFooter.css";

function MunicipalityFooter({ latest_meeting_date, latest_meeting_number }) {
  const year = new Date().getFullYear();
  const { t } = useTranslation();
  return (
    latest_meeting_number &&
    <div className="municipality-footer">
      <p className="municipality-footer-text">
        {
          t("footer-text", { num: latest_meeting_number, date: latest_meeting_date })
        }
      </p>
    </div>
  );
}

export default MunicipalityFooter;