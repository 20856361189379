import React from 'react';
import { ButtonClose, ButtonDelete } from '../../../components/Buttons';
import { ModalDialog } from '../../../components/ModalDialog';

export const ConfirmDeleteAliasDialog = ({ isVisible, onHide, onConfirm, alias }) => {
    return (
        <ModalDialog
            visible={isVisible}
            header='Smazat alias'
            style={{ width: 300 }}
            footer={
                <>
                    <ButtonClose label='Ne' onClick={onHide} />
                    <ButtonDelete label='Ano' onClick={onConfirm} />
                </>
            }
            onHide={onHide}
        >
            {alias && (
                <p className='dialog-text'>
                    <span>
                        Opravdu chcete smazat alias{' '}
                        <b>
                            {alias.first_name} {alias.last_name}
                        </b>
                        ?
                    </span>
                </p>
            )}
        </ModalDialog>
    );
};
