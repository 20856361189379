import PopupBodyChart from "../../../../components/map/popup/PopupBodyChart";
import PopupFooter from "../../../../components/map/popup/PopupFooter";
import PopupHeader from "../../../../components/map/popup/PopupHeader";
import data from "../data";
import geo from "../geo";
import meta from "../meta";

/**
 * population statistics.
 * 
 * @author Petr John
 */
const population23 = {
    label: "Rok 2023",
    data: data,
    categories: meta.populace23.values,
    getValue: meta.populace23.getValue,
    formatValue: meta.populace23.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle počtu obyvatel jednotlivých krajů (rok 2023)",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Počet obyvatel jednotlivých krajů se v roce <span className="emp"><strong>2023</strong></span> pohyboval od <span className="emp"><strong>295&nbsp;077</strong></span> do <span className="emp"><strong>1&nbsp;455&nbsp;940</strong> obyvatel</span>. Největší počet obyvatel byl v <span className="emp"><strong>Sředočeském kraji</strong></span> a nejmenší v <span className="emp"><strong>Karlovarském kraji</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.populace23.values,
                                            getValue: meta.populace23.getValue,
                                            formatValue: meta.populace23.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            ...meta.csuPeople,
                                            label: "ČSÚ - obyvatelstvo"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}


const population22 = {
    label: "Rok 2022",
    data: data,
    categories: meta.populace22.values,
    getValue: meta.populace22.getValue,
    formatValue: meta.populace22.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle počtu obyvatel jednotlivých krajů (rok 2022)",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Počet obyvatel jednotlivých krajů se v roce <span
                                                    className="emp"><strong>2022</strong></span> pohyboval od <span
                                                    className="emp"><strong>293&nbsp;595</strong></span> do <span
                                                    className="emp"><strong>1&nbsp;439&nbsp;391</strong> obyvatel</span>.
                                                    Největší počet obyvatel byl v <span className="emp"><strong>Sředočeském kraji</strong></span> a
                                                    nejmenší v <span className="emp"><strong>Karlovarském kraji</strong></span>.
                                                </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.populace22.values,
                                            getValue: meta.populace22.getValue,
                                            formatValue: meta.populace22.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            ...meta.csuPeople,
                                            label: "ČSÚ - obyvatelstvo"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}


const population21 = {
    label: "Rok 2021",
    data: data,
    categories: meta.populace21.values,
    getValue: meta.populace21.getValue,
    formatValue: meta.populace21.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle počtu obyvatel jednotlivých krajů (rok 2021)",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Počet obyvatel jednotlivých krajů se v roce <span
                                                    className="emp"><strong>2021</strong></span> pohyboval od <span
                                                    className="emp"><strong>283&nbsp;210</strong></span> do <span
                                                    className="emp"><strong>1&nbsp;386&nbsp;824</strong> obyvatel</span>.
                                                    Největší počet obyvatel byl v <span className="emp"><strong>Sředočeském kraji</strong></span> a
                                                    nejmenší v <span className="emp"><strong>Karlovarském kraji</strong></span>.
                                                </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.populace21.values,
                                            getValue: meta.populace21.getValue,
                                            formatValue: meta.populace21.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            ...meta.csuPeople,
                                            label: "ČSÚ - obyvatelstvo"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const population20 = {
    label: "Rok 2020",
    data: data,
    categories: meta.populace20.values,
    getValue: meta.populace20.getValue,
    formatValue: meta.populace20.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle počtu obyvatel jednotlivých krajů (rok 2020)",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Počet obyvatel jednotlivých krajů se v roce <span
                                                    className="emp"><strong>2020</strong></span> pohyboval od <span
                                                    className="emp"><strong>293&nbsp;311</strong></span> do <span
                                                    className="emp"><strong>1&nbsp;397&nbsp;997</strong> obyvatel</span>.
                                                    Největší počet obyvatel byl v <span className="emp"><strong>Sředočeském kraji</strong></span> a
                                                    nejmenší v <span className="emp"><strong>Karlovarském kraji</strong></span>.
                                                </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.populace20.values,
                                            getValue: meta.populace20.getValue,
                                            formatValue: meta.populace20.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            ...meta.csuPeople,
                                            label: "ČSÚ - obyvatelstvo"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}


const populationInc = {
    label: "Rozdíl mezi lety 2023 a 2020",
    data: data,
    categories: meta.populaceVyvoj.values,
    getValue: meta.populaceVyvoj.getValue,
    formatValue: meta.populaceVyvoj.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle vývoje obyvatelstva v jednotlivých krajích v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Počet obyvatel v letech <span className="emp"><strong>2020-2023</strong></span> ve většině krajů vzrostl (kladné hodnoty), a ve&nbsp;2 krajích se naopak snížil (záporné hodnoty). Nejvíce obyvatel přibylo ve&nbsp;<span className="emp"><strong>Středočeském kraji</strong></span> a&nbsp;naopak nejvyšší úbytek zaznamenal <span className="emp"><strong>Ústecký kraj</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.populaceVyvoj.values,
                                            getValue: meta.populaceVyvoj.getValue,
                                            formatValue: meta.populaceVyvoj.formatValue
                                        }
                                    }
                                },
                                {
                                    title: "Vývoj počtů obyvatel v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                                
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "line",
                                        config: {
                                            geo: geo,
                                            datasets: [
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.populace20.values,
                                                    getValue: meta.populace20.getValue,
                                                    formatValue: meta.populace20.formatValue,
                                                    label: meta.populace20.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.populace21.values,
                                                    getValue: meta.populace21.getValue,
                                                    formatValue: meta.populace21.formatValue,
                                                    label: meta.populace21.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.populace22.values,
                                                    getValue: meta.populace22.getValue,
                                                    formatValue: meta.populace22.formatValue,
                                                    label: meta.populace22.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.populace23.values,
                                                    getValue: meta.populace23.getValue,
                                                    formatValue: meta.populace23.formatValue,
                                                    label: meta.populace23.label
                                                },
                                            ]
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            ...meta.csuPeople,
                                            label: "ČSÚ - obyvatelstvo"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const genericPopup = {
    header: {
        component: PopupHeader,
        props: {
            data: data
        }
    },
    body: {
        component: PopupBodyChart,
        props: {
            data: data,
            stats: [
                {
                    title: "Srovnání dle počtu obyvatel jednotlivých krajů (rok 2023)",
                    content: () => {
                        return (
                            <>
                                <p>Počet obyvatel jednotlivých krajů se v roce <span className="emp"><strong>2023</strong></span> pohyboval od <span className="emp"><strong>295&nbsp;077</strong></span> do <span className="emp"><strong>1&nbsp;455&nbsp;940</strong> obyvatel</span>. Největší počet obyvatel byl v <span className="emp"><strong>Sředočeském kraji</strong></span> a nejmenší v <span className="emp"><strong>Karlovarském kraji</strong></span>.</p>
                            </>
                        )
                    },
                    chart: {
                        type: "bar",
                        config: {
                            geo: geo,
                            data: data,
                            categories: meta.populace23.values,
                            getValue: meta.populace23.getValue,
                            formatValue: meta.populace23.formatValue
                        }
                    }
                }
            ]
        }
    },
    footer: {
        component: PopupFooter,
        props: {
            data: data,
            settings: {
                left: {
                    title: {
                        label: "Zdroj:",
                        icon: "pi pi-fw pi-globe"
                    },
                    links: [
                        {
                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ obyvatelstvo",
                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt-parametry&z=T&f=TABULKA&katalog=30845&sp=A&skupId=3809&pvo=DEMZU01a&u=v81__VUZEMI__100__3018&evo=v450_%21_IK-DEM-R-2030-2000-sestup_1&str=v191"
                        }
                    ]
                },
                right: {
                    title: undefined,
                    links: [
                        meta.route
                    ]
                }
            }
        }
    },
    style: {
        width: '80vw',
        height: '100vh',
        maxWidth: '1200px',
    }
}

const populationStats = {
    title: 'Počet obyvatel krajů',
    widget: {
        type: "column-group",
        config: {
            widgets: [
                {
                    type: "custom",
                    config: {
                        content: (
                            <p>
                                <span className="emp">Počet obyvatel</span> má významný vliv na fungování krajských zastupitelstev i&nbsp;na jejich hospodaření a&nbsp;rozpočet. 
                                Kraje s vyšším počtem obyvatel zpravidla spravují větší rozpočty, protože potřebují zajišťovat širší spektrum veřejných služeb pro větší množství lidí. 
                                Větší populace také znamená vyšší příjmy z&nbsp;daní, ale současně i&nbsp;vyšší výdaje na infrastrukturu a&nbsp;sociální programy. 
                                Počet obyvatel dále ovlivňuje i&nbsp;složení zastupitelstva a&nbsp;výši odměn členů zastupitelstva.
                            </p>
                        )
                    }
                },
                {
                    type: "regional-filtered-choropleth",
                    config: {
                        id: "mapa-pocet-obyvatel",
                        label: "Počet obyvatel",
                        geo: geo,
                        datasets: [
                            populationInc, population23, population22, population21, population20
                        ]
                    }
                },
                {
                    type: "custom",
                    config: {
                        content: (
                            <p>
                                V&nbsp;letech <span className="emp"><strong>2020–⁠⁠⁠⁠⁠⁠2023</strong></span> lze pozorovat pouze mírné výkyvy v&nbsp;počtu obyvatel, přičemž nejvíce lidnatým krajem je <span className="emp">kraj Středočeský</span> a&nbsp;nejméně obyvatel žije v&nbsp;<span className="emp">kraji Karlovarském</span>.
                            </p>
                        )
                    }
                }
            ]
        }
    },
    footer: {
        left: {
            title: {
                label: "Zdroj:",
                icon: "pi pi-fw pi-globe"
            },
            links: [
                {
                    label: "ČSÚ –⁠⁠⁠⁠⁠⁠ obyvatelstvo",
                    value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt-parametry&z=T&f=TABULKA&katalog=30845&sp=A&skupId=3809&pvo=DEMZU01a&u=v81__VUZEMI__100__3018&evo=v450_%21_IK-DEM-R-2030-2000-sestup_1&str=v191"
                }
            ]
        },
        right: {
            title: undefined,
            links: [
                meta.route
            ]
        },
        popup: {
            component: populationInc.map.defaults.polygon.popup
        }
    }
}
export default populationStats;
