import React from "react";

import "./SubjectList.css"

function SubjectList({
    subjects = {},
    formatLine = (subjects, subjectId) => `${subjects[subjectId]}`,
}) {

    return (
        <>
            <div>
                {
                    subjects && Object.keys(subjects).map((subject, index) => {
                        return (
                            <div key={index} className="subject-list">
                                <span className="subject-list-marker" style={{ backgroundColor: subjects[subject].color ?? "lightgray" }}></span>
                                <b>{subject}</b>: {formatLine(subjects, subject)}
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default SubjectList;