import React from "react";

import { Chart } from 'primereact/chart';

import "./RegionalBarChart.css"

const defaultConfig = {
    sort: true,
    filterUndefined: false,
    elementStyle: {
        width: "100%",
        height: "100%",
        position: "relative",
    },
    style: {
        backgroundColor: "lightgray",
        borderColor: "lightgray",
        textColor: "black",
        disabledColor: "lightgray",
        emphasizeBackgroundColor: "yellow",
        emphasizeBorderColor: "yellow",
        emphasizeTextColor: "#b2b300",
        columnHeight: 30,
    },
    chartjs: undefined
}

const chartjsStructure = {
    type: 'bar',
    data: {
        labels: [],
        datasets: [{
            axis: 'y',
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1,
            barPercentage: 0.9,
            //barThickness: 20,
        }]
    },
    options: {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    color: []
                    /*callback: function(value, index, ticks) {
                        return value*100 + "%";
                    }*/
                },
                grid: {
                    display: false
                }
            },
            x: {
                ticks: {
                    /*callback: function(value, index, ticks) {
                        return value*100 + "%";
                    }*/
                }
            }
        },
        plugins: {
            legend: {
                display: false
            },
        }
    }
}

function RegionalBarChart({
    geo = {},
    data = {}, // the structure of the region values { regionId: value }
    categories = {},
    getValue = (data, region) => data[region],
    formatValue = (value) => value,
    config = defaultConfig,
    selection = undefined
}) {

    // initialize the chartjs structure
    const chartjs = JSON.parse(JSON.stringify(chartjsStructure));

    const swap = (i, j) => {

        // data
        let swap = chartjs.data.datasets[0].data[i];
        chartjs.data.datasets[0].data[i] = chartjs.data.datasets[0].data[j];
        chartjs.data.datasets[0].data[j] = swap;

        // labels
        swap = chartjs.data.labels[i];
        chartjs.data.labels[i] = chartjs.data.labels[j];
        chartjs.data.labels[j] = swap;

        // backgroundColor
        swap = chartjs.data.datasets[0].backgroundColor[i];
        chartjs.data.datasets[0].backgroundColor[i] = chartjs.data.datasets[0].backgroundColor[j];
        chartjs.data.datasets[0].backgroundColor[j] = swap;

        // borderColor
        swap = chartjs.data.datasets[0].borderColor[i];
        chartjs.data.datasets[0].borderColor[i] = chartjs.data.datasets[0].borderColor[j];
        chartjs.data.datasets[0].borderColor[j] = swap;

        // ticks color
        swap = chartjs.options.scales.y.ticks.color[i];
        chartjs.options.scales.y.ticks.color[i] = chartjs.options.scales.y.ticks.color[j];
        chartjs.options.scales.y.ticks.color[j] = swap;
    }

    const cmp = (a, b) => {
        if(a === undefined) {
            if(b === undefined) {
                return 0;
            } else {
                return -1;
            }
        } else {
            if(b === undefined) {
                return 1;
            } else {
                return a - b;
            }
        }
    }

    const sortData = () => {
        const values = chartjs.data.datasets[0].data;

        let max;
        for(let i = 0; i < values.length; i++) {
            max = i;
            for(let j = i+1; j < values.length; j++) {
                if(cmp(values[j], values[max]) > 0) {
                    max = j;
                }
            }

            // swap values
            swap(i, max);
        }
    }

    const processRegions = () => {

        // iterate over the regions and for every region get the region and category
        for (let region in data) {
            
            // get the value of the region
            let value = data[region] ? getValue(data, region) : undefined;
            if(!((config.filterUndefined === true || ((config.filterUndefined === undefined && defaultConfig.filterUndefined))) && value === undefined)) {
                // label
                chartjs.data.labels.push(geo[region].nameShort);
                
                // data
                chartjs.data.datasets[0].data.push(value);

                // colors
                if(selection && selection.region === region) {
                    chartjs.data.datasets[0].backgroundColor.push(config.style?.emphasizeBackgroundColorbackgroundColor ?? defaultConfig.style?.emphasizeBackgroundColor);
                    chartjs.data.datasets[0].borderColor.push(config.style?.emphasizeBorderColor ?? defaultConfig.style?.emphasizeBorderColor);
                    chartjs.options.scales.y.ticks.color.push(config.style?.emphasizeTextColor ?? defaultConfig.style?.emphasizeTextColor);
                } else {
                    chartjs.data.datasets[0].backgroundColor.push(config.style?.backgroundColor ?? defaultConfig.style?.backgroundColor);
                    chartjs.data.datasets[0].borderColor.push(config.style?.borderColor ?? defaultConfig.style?.borderColor);
                    if(value === undefined) {
                        chartjs.options.scales.y.ticks.color.push(config.style?.disabledColor ?? defaultConfig.style?.disabledColor);
                    } else {
                        chartjs.options.scales.y.ticks.color.push(config.style?.textColor ?? defaultConfig.style?.textColor);
                    }
                }
            }
        }

        if(config.sort || defaultConfig.sort) {
            sortData();
        }
    }

    const setOptions = () => {
        // merge options with the props
        chartjs.options = {
            ...chartjs.options,
            ...config.chartjs
        }

        chartjs.options.scales.x.ticks.callback = (value, index, ticks) => {
            return value && formatValue && formatValue(value);
        }

        /*chartjs.options.scales.y.ticks.callback = (value, index, ticks) => {
            return geo[chartjs.data.labels[value]].name;
        }*/

        
    }

    setOptions();
    processRegions();

    return (
        <>
            <Chart
                type={chartjs.type}
                data={chartjs.data}
                options={chartjs.options}
                style={{
                    ...config.elementStyle ?? defaultConfig.elementStyle,
                    height: (chartjs.data.labels.length * config.style.columnHeight) + "px"
                }} />
        </>
    )
}
export default RegionalBarChart;