import React, { useEffect, useState } from 'react';
import { ButtonAdd, ButtonClose } from '../../../components/Buttons';
import { ModalDialog } from '../../../components/ModalDialog';
import { AdditionalMemberTable } from '../../../components/AdditionalMemberTable';

export const AdditionalMeetingMemberDialog = ({ members, loading, isVisible, onHide, onConfirm }) => {
    const [selectedMembers, setSelectedMembers] = useState([]);

    useEffect(() => {
        setSelectedMembers([]);
    }, [isVisible, members]);

    const onSelectionChange = (e) => {
        setSelectedMembers(e.value);
    };

    return (
        <ModalDialog
            visible={isVisible}
            header='Zvolit další členy zasedání'
            style={{ width: 500 }}
            footer={
                <>
                    <ButtonClose label='Zavřít' onClick={onHide} />
                    <ButtonAdd
                        label='Přidat členy'
                        type='button'
                        onClick={() => onConfirm(selectedMembers)}
                        disabled={loading}
                    />
                </>
            }
            onHide={onHide}
        >
            <AdditionalMemberTable
                members={members}
                loading={loading}
                selectedMembers={selectedMembers}
                onSelectionChange={onSelectionChange}
            />
        </ModalDialog>
    );
};
