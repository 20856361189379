import React, { useState } from 'react';
import { DataTable, Dropdown } from '../../components/PrimereactComponents';

const rowsPerPageOptions = [5, 10, 15, 25, 100];
const MIN_ROWS = 5;
const DEFAULT_ROWS = 10;

/**
 * @typedef {object} PaginatedDataTableProps
 * @property {React.ReactNode} children
 * @property {number} [initialRows]
 * @property {boolean} [useRowsPerPageDropdown]
 */

/**
 * @param {PaginatedDataTableProps & import('primereact/datatable').DataTableProps} props
 */
export const PaginatedDataTable = ({
    children,
    initialRows = DEFAULT_ROWS,
    useRowsPerPageDropdown = true,
    ...tableProps
}) => {
    const [rows, setRows] = useState(rowsPerPageOptions.includes(initialRows) ? initialRows : DEFAULT_ROWS);

    const onRowsChange = (event) => {
        setRows(event.value);
    };

    const customPaginatorTemplate = {
        layout: `${
            useRowsPerPageDropdown ? 'RowsPerPageDropdown ' : ''
        }CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink`,
        RowsPerPageDropdown: (options) => {
            return (
                <>
                    <span>Počet řádků na stránku:</span>
                    <Dropdown
                        className='pagination-dropdown'
                        value={options.value}
                        options={options.options}
                        onChange={onRowsChange}
                    />
                </>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span className='pagination-numbers'>
                    {options.first} - {options.last} z {options.totalRecords}
                </span>
            );
        }
    };

    return (
        <DataTable
            paginator={tableProps.value?.length > MIN_ROWS}
            paginatorTemplate={customPaginatorTemplate}
            rows={rows}
            rowsPerPageOptions={rowsPerPageOptions}
            {...tableProps}
        >
            {children}
        </DataTable>
    );
};
