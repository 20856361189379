import { devList } from "../../municipality/dev";
import { aboutSection } from "../default/sections/about";
import { datasetsSection } from "../default/sections/datasets";
import { partnersSection } from "../default/sections/partners";

/**
 * Definition of Home page.
 * 
 * @author Jiri Hynek
 */
const home = (response) => {
    return {
        id: "home",
        header: {
            image: {
                large: "loga/title/zastupko/large.svg",
                small: "loga/title/zastupko/small.svg",
            }
        },
        sections: [
            {
                id: "intro",
                noAnchor: true,
                heading: {
                    label: "Testovací verze vizualizace z hlasování městských zastupitelstev",
                },
                content: {
                    type: "widget-group",
                    config: {
                        widgets: [
                            {
                                type: "municipality-card-grid",
                                config: {
                                    items: devList
                                }
                            }
                        ]
                    }
                }
            },
            datasetsSection,
            partnersSection,
            aboutSection
        ]
    }
}
export default home;