import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import LoadingPage from './components/LoadingPage';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'primereact/resources/primereact.min.css'; //core css
import 'primeicons/primeicons.css'; //icons
import 'bootstrap-icons/font/bootstrap-icons.css';
//import 'primeflex/primeflex.css';

import 'primereact/resources/themes/lara-light-indigo/theme.css'; //theme
import './styles/App.css'; // custom styles

import reportWebVitals from './utils/reportWebVitals';

import './utils/i18n';
import routes from './AppRoutes';

const baseName = process.env.REACT_APP_BASENAME;

// Load conditionally to exclude from build (only works if the env variable is set)
if (process.env.REACT_APP_USE_ADMIN === '1') {
    const adminInitApp = require('./admin/init').default;
    adminInitApp(routes);
}

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter(routes, { basename: baseName });

root.render(
    <Suspense fallback={<LoadingPage />}>
        <RouterProvider router={router} fallbackElement={<LoadingPage />} />
    </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
