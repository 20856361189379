import PopupBodyChart from "../../../../components/map/popup/PopupBodyChart";
import PopupFooter from "../../../../components/map/popup/PopupFooter";
import PopupHeader from "../../../../components/map/popup/PopupHeader";
import APP from "../../../config";
import data from "../data";
import geo from "../geo";
import meta from "../meta";

/**
 * Share of subjects statistics.
 * 
 * @author Kristyna Zaklova
 */
const subjectsStats = {
    title: 'Počet politických subjektů',
    content: () => {
        return (
            <>
                <span className="emp"><strong>Počet</strong> politických subjektů</span> v&nbsp;krajích se liší podle regionu a&nbsp;ovlivňuje politickou rozmanitost v&nbsp;zastupitelstvech. 
                V některých krajích soutěží více subjektů, což vede k širšímu zastoupení různých názorů. 
                Více subjektů často znamená složitější povolební vyjednávání a&nbsp;tvorbu koalic, ale také přináší pestřejší diskuzi o&nbsp;regionálních otázkách.
                Nejrozmanitější složení lze vidět ve <span className="emp"><strong>3</strong>&nbsp;krajích</span>, v nichž figuruje celkem <span className="emp"><strong>9</strong>&nbsp;politických subjektů</span>.
                <br></br><br></br>
                Uvedené počty subjektů a&nbsp;mandátů, které se zobrazí po najetí nad příslušný kraj, jsou vztaženy k&nbsp;<span className="emp">nejnovějšímu zasedání</span>, o&nbsp;němž existují záznamy v&nbsp;systému.
                Nezařazení členové nepatří k&nbsp;žádnému politickému subjektu, proto jsou evidováni samostatně, tedy jako sólo "subjekt".
            </>
        )
    },
    chart: {
        id: "mapa-pocet-subjektu",
        type: "regional-choropleth",
        config: {
            geo: geo,
            data: data,
            categories: meta.pocetSubjektu.values,
            getValue: meta.pocetSubjektu.getValue,
            formatValue: meta.pocetSubjektu.formatValue,
            map: {
                defaults: {
                    polygon: {
                        hoverStyle: {
                            fill: "yellow"
                        },
                        popup: {
                            header: {
                                component: PopupHeader,
                                props: {
                                    data: data
                                }
                            },
                            body: {
                                component: PopupBodyChart,
                                props: {
                                    data: data,
                                    stats: [
                                        {
                                            title: "Srovnání dle počtu subjektů v zastupitelstvech jednotlivých krajů v období 2020-2024 (s výjimkou Prahy)",
                                            content: () => {
                                                return (
                                                    <>
                                                        <p>Počet subjektů v zastupitelstvech v&nbsp;jednotlivých krajích se pohybuje od <span className="emp"><strong>5</strong></span> do <span className="emp"><strong>9</strong></span>. Nejvyšší počet subjektů v&nbsp;zastupitelstvech je v&nbsp;<span className="emp"><strong>Ústeckém, Moravskoslezském a&nbsp;Jihočeském kraji</strong></span> a&nbsp;naopak nejnižší v&nbsp;<span className="emp"><strong>kraji Středočeském</strong></span>.</p>
                                                    </>
                                                )
                                            },
                                            chart: {
                                                type: "bar",
                                                config: {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.pocetSubjektu.values,
                                                    getValue: meta.pocetSubjektu.getValue,
                                                    formatValue: (value, categories) => value ? `${(value)}` + ` subjektů` : "Chybějící data",
                                                }
                                            }
                                        }
                                    ]
                                }
                            },
                            footer: {
                                component: PopupFooter,
                                props: {
                                    data: data,
                                    settings: {
                                        left: {
                                            title: {
                                                label: "Zdroje:",
                                                icon: "pi pi-fw pi-globe"
                                            },
                                            links: [
                                                meta.wiki,
                                                {
                                                    ...meta.CoALicense,
                                                    label: "Wikimedia",
                                                },
                                                {
                                                    ...meta.url,
                                                    label: undefined
                                                }
                                            ]
                                        },
                                        right: {
                                            title: undefined,
                                            links: [
                                                meta.route
                                            ]
                                        }
                                    }
                                }
                            },
                            style: {
                                width: '80vw',
                                height: '100vh',
                                maxWidth: '1200px',
                            }
                        }
                    },
                    capital: {
                        style: {
                            display: "none"
                        }
                    }
                }
            },
        }
    },
    footer: {
        left: {
            title: {
                label: "Zdroj:",
                icon: "pi pi-fw pi-globe"
            },
            links: [
                {
                    label: "Datové sady",
                    value: `${APP.protocol}://kraje.${APP.domain}${APP.port && ':' + APP.port}/datasety`
                }
            ]
        },
        right: {
            title: undefined,
            links: [
                meta.route
            ]
        }
    }
}
export default subjectsStats;