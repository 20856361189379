import { useState, useEffect } from 'react';
import authAxios from '../lib/authAxios';

export const useUserPermissions = () => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await authAxios.get('/admin/user-permissions');
                setUser(response.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return { user, loading, error };
};
