import React from "react";

import "./SimpleMeter.css"

function SimpleMeter(
    props = {
        val: 0,
        meterStyle: {},
        progressStyle: {}
    }
) {

    return (
        <>
            <div className="simple-meter" style={props.meterStyle}>
                <div className="simple-meter-val" style={{
                    "width": (props.val*100) + "%"
                }}></div>
            </div>
        </>
    )
}

export default SimpleMeter;