import { Messages } from 'primereact/messages';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonEdit } from '../../../components/Buttons';
import { FormContainer, FormInput, FormRow, Label } from '../../../components/form';
import { getFormErrorMessage } from '../../../utils/errors';
import { RHFDropdown } from '../../../components/rhf';
import { ModalDialog } from '../../../components/ModalDialog';
import { formatMemberName } from '../../../../utils/texts';
import { LineDivider } from '../../../components/LineDivider';
import { AttributeRow } from '../../../components/AttributeRow';

export const ChangeMembershipDialog = ({ isVisible, politicalEntities, selectedMember, onHide, onSubmit, msgs }) => {
    const [processing, setProcessing] = useState(false);

    const formId = 'change-membership-dialog';

    const defaultValues = {
        political_entity: null
    };

    const {
        control,
        formState: { errors, isSubmitting },
        handleSubmit,
        reset
    } = useForm({
        disabled: processing,
        defaultValues: defaultValues
    });

    useEffect(() => {
        setProcessing(isSubmitting);
    }, [isSubmitting]);

    useEffect(() => {
        if (selectedMember) {
            reset({ political_entity: selectedMember.political_entity.id });
        }
    }, [selectedMember, reset]);

    return (
        <ModalDialog
            visible={isVisible}
            header={'Změnit členství v politickém subjektu'}
            style={{ width: 350 }}
            footer={<ButtonEdit label='Změnit' type='submit' form={formId} />}
            onHide={onHide}
        >
            <Messages ref={msgs} className='flex-center' />

            <form onSubmit={handleSubmit(onSubmit)} id={formId}>
                {selectedMember && (
                    <>
                        <div className='dialog-text'>
                            <AttributeRow label='Člen'>
                                {formatMemberName(selectedMember.first_name, selectedMember.last_name)}
                            </AttributeRow>
                        </div>
                        <LineDivider />
                    </>
                )}
                <FormContainer type='dialog'>
                    <FormRow>
                        <FormInput>
                            <Label htmlFor='political_entity' text='Politický subjekt' />
                            <RHFDropdown
                                name={`political_entity`}
                                control={control}
                                rules={{ required: 'Zvolte politický subjekt' }}
                                placeholder='Zvolte politický subjekt'
                                inputProps={{
                                    options: politicalEntities,
                                    optionValue: 'id',
                                    optionLabel: 'abbreviation',
                                    emptyMessage: 'Nejsou zadány žádné politické subjekty',
                                    showClear: false,
                                    panelClassName: 'p-dropdown-panel--zindex-override'
                                }}
                            />
                            {getFormErrorMessage(errors, 'political_entity')}
                        </FormInput>
                    </FormRow>
                </FormContainer>
            </form>
        </ModalDialog>
    );
};
