import {Outlet, useLocation} from 'react-router-dom';
import useLocationChange from '../hooks/useLocationChange';
import APP, { getApp } from '../config/config';
import React, {useEffect, useState} from 'react';
import NavMenu2 from '../components/Menu2';
import ReactGA from "react-ga4";
import MainFooter from '../components/widget/MainFooter';

if (process.env.REACT_APP_GA_TRACKING_ID) {
    ReactGA.initialize([{ trackingId: process.env.REACT_APP_GA_TRACKING_ID }]);
}

const Analytics = () => {
    const location = useLocation();

    useEffect(() => {
        const page = `${window.location.hostname} ${location.pathname}${location.search}`;
        ReactGA.send({
            hitType: 'pageview',
            page: page,
            title: page
        });
    }, [location]);

    return null;
};

const AppLayout = () => {


    React.useEffect(() => {
        const anchor = window.location.hash.slice(1);
        if (anchor) {
            const anchorEl = document.getElementById(anchor);
            if (anchorEl) {
                anchorEl.scrollIntoView();
            }
        }
    }, []);


    const [app, setApp] = useState(APP);

    useLocationChange((location) => { 
        setApp(getApp());
    })

    return (
        <>
            <Analytics />
            <NavMenu2 />
            <Outlet />
            <MainFooter/>
        </>
    );
};

export default AppLayout;
