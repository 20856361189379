import axios from 'axios';
import { Messages } from 'primereact/messages';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingPage from '../../../components/LoadingPage';
import { getErrorMessage } from '../../../utils/errors';
import { ErrorMessage } from '../../components/ErrorMessage';
import { MunicipalityForm } from '../../components/MunicipalityForm';
import authAxios from '../../lib/authAxios';
import { showErrorMessage, showSuccessMessage } from '../../utils/messages';
import { scrollTop } from '../../utils/scroll';
import { useUserPermissions } from '../../hooks/useUserPermissions';
import { hasRole } from '../../utils/auth';
import { UserRole } from '../../constants/enums';

export default function MunicipalityEdit() {
    const { municipalityId } = useParams();

    const { user } = useUserPermissions();

    const msgs = useRef(null);
    const refForm = useRef(null);

    const [loading, setLoading] = useState(true);
    const [initError, setInitError] = useState(null);
    const [municipalityTypeOptions, setMunicipalityTypeOptions] = useState([]);
    const [municipality, setMunicipality] = useState(null);

    const mapMunicipalityData = (municipalityData) => ({
        text_id: municipalityData.text_id,
        municipality_type: municipalityData.typ_id,
        name: municipalityData.nazev,
        name_genitive: municipalityData.nazev_genitiv,
        logo: municipalityData.logo,
        color: municipalityData.barva,
        url: municipalityData.url,
        email: municipalityData.email,
        partner: municipalityData.partner,
        zastupitelstvo_application_name: municipalityData.organy.zastupitelstvo.nazev_aplikace,
        zastupitelstvo_name: municipalityData.organy.zastupitelstvo.nazev,
        zastupitelstvo_name_genitive: municipalityData.organy.zastupitelstvo.nazev_genitiv,
        zastupitelstvo_place: municipalityData.organy.zastupitelstvo.misto,
        zastupitelstvo_member_count: municipalityData.organy.zastupitelstvo.pocet_zastupitelu,
        zastupitelstvo_application_description: municipalityData.organy.zastupitelstvo.popis_aplikace
    });

    const loadMunicipality = useCallback(async () => {
        const response = await axios.get(`municipality/${municipalityId}`);
        setMunicipality(mapMunicipalityData(response.data));
    }, [municipalityId]);

    useEffect(() => {
        const loadMunicipalityTypes = async () => {
            const response = await axios.get('admin/municipality-type');
            setMunicipalityTypeOptions(
                response.data.map((typMunicipality) => ({
                    label: typMunicipality.name,
                    value: typMunicipality.id
                }))
            );
        };

        const fetchData = async () => {
            try {
                await Promise.all([loadMunicipalityTypes(), loadMunicipality()]);
            } catch (error) {
                const errorMessage = getErrorMessage(error);
                setInitError(errorMessage ?? 'Nepodařilo se načíst data zvolené municipality');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [loadMunicipality]);

    const onSubmit = async (data) => {
        const requestData = {
            municipality_type: data.municipality_type,
            name: data.name,
            name_genitive: data.name_genitive,
            color: data.color,
            url: data.url,
            email: data.email ? data.email : null,
            zastupitelstvo_application_name: data.zastupitelstvo_application_name,
            zastupitelstvo_name: data.zastupitelstvo_name,
            zastupitelstvo_name_genitive: data.zastupitelstvo_name_genitive,
            zastupitelstvo_place: data.zastupitelstvo_place,
            zastupitelstvo_member_count: data.zastupitelstvo_member_count,
            zastupitelstvo_application_description: data.zastupitelstvo_application_description
        };
        if (data.partner !== undefined) {
            requestData.partner = data.partner;
        }
        try {
            await authAxios.put(`admin/municipality/${municipalityId}`, requestData);
            showSuccessMessage(msgs, 'Municipalita byla úspěšně upravena', { replace: true });

            if (data.logo) {
                try {
                    const formData = new FormData();
                    formData.append('logo', data.logo);
                    await authAxios.post(`admin/municipality/${municipalityId}/logo`, formData);
                } catch (error) {
                    const errorMessage = getErrorMessage(error);
                    const baseErrorMessage = 'Nepodařilo se uložit logo municipality';
                    showErrorMessage(msgs, errorMessage ? `${baseErrorMessage}: ${errorMessage}` : baseErrorMessage);
                }

                await loadMunicipality();
                refForm?.current?.resetLogo();
            }
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se upravit municipalitu', { replace: true });
        } finally {
            scrollTop();
        }
    };

    return loading ? (
        <LoadingPage />
    ) : initError ? (
        <>
            <h1>Upravit Municipalitu</h1>

            <ErrorMessage prefix='Nelze použít formulář' error={initError} />
        </>
    ) : (
        <>
            <h1>Upravit Municipalitu {`${municipality.name} (${municipality.text_id})`}</h1>

            <Messages ref={msgs} className='page__messages' />

            <MunicipalityForm
                ref={refForm}
                municipalityTypeOptions={municipalityTypeOptions}
                onSubmit={onSubmit}
                editMunicipality={municipality}
                enablePartner={hasRole(user, UserRole.ADMIN)}
            />
        </>
    );
}
