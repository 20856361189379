import React from "react";
//import useDimensions from "../hooks/useDimensions";
import { Chart } from "./PrimereactComponents";

function ChartComponent({ type, data, options, height, width, legendPosition = 'bottom', Xgrid = true, Ygrid = true, xTitle , yTitle, aria }) {
  //const { WindowHeight, WindowWidth } = useDimensions();
  
  const style = getComputedStyle(document.body);

  if(options){
  options.plugins = options.plugins || {};
  options.plugins.legend = options.plugins.legend || {};
  options.plugins.legend.labels = options.plugins.legend.labels || {};
  options.plugins.legend.labels.font = options.plugins.legend.labels.font || {};
  options.scales = options.scales || {};
  options.scales.x = options.scales.x || {};
  options.scales.y = options.scales.y || {};
  options.scales.x.title = options.scales.x.title || {};
  options.scales.y.title = options.scales.y.title || {};
  options.scales.x.grid = options.scales.x.grid || {};
  options.scales.y.grid = options.scales.y.grid || {};
  options.scales.x.ticks = options.scales.x.ticks || {};
  options.scales.y.ticks = options.scales.y.ticks || {};

  var labelBoxHeight = 15;
  var labelBoxWidth = 15;

  options.plugins.tooltip = {
    //backgroundColor: 'white',
    //borderColor: style.getPropertyValue('--background-color'),
    //borderWidth: 2.25,
    //bodyColor: 'white',
    padding: 10,
    //usePointStyle: true,
    boxHeight: labelBoxHeight,
    boxWidth: labelBoxWidth,
    callbacks: {
      labelTextColor: function(context) {
          return 'white';
          //return style.getPropertyValue('--chart-labels-color');
      }
  }
  };

  if(xTitle){
    options.scales.x.title.text = xTitle;
    options.scales.x.title.display = true;
  }

  if(yTitle){
    options.scales.y.title.text = yTitle;
    options.scales.y.title.display = true;
  }

  options.animation = false;
  options.scales.x.grid.display = Xgrid;
  options.scales.y.grid.display = Ygrid;
  
  //options.plugins.legend.labels.usePointStyle = true
  options.plugins.legend.labels.padding = 10;
  options.plugins.legend.labels.boxWidth = labelBoxWidth;
  options.plugins.legend.labels.boxHeight = labelBoxHeight;
  options.plugins.legend.position = legendPosition;
  options.plugins.legend.labels.color = style.getPropertyValue('--chart-labels-color');
  //Pokud se mění velikost fontu legendy, je nutné zkontroloval, že text nikde nepřetéká (chart.js bug)
  //options.plugins.legend.labels.font.size = style.getPropertyValue('--chart-labels-size');
  options.plugins.legend.labels.font.family = style.getPropertyValue('--chart-labels-font');
  options.scales.x.ticks.color = style.getPropertyValue('--chart-ticks-color');
  options.scales.y.ticks.color = style.getPropertyValue('--chart-ticks-color');
  options.scales.x.grid.color = style.getPropertyValue('--chart-grid-color');
  options.scales.y.grid.color = style.getPropertyValue('--chart-grid-color');
  options.plugins.legend.onClick = null;
}

  return (
      <div className="chart-container">
        <Chart
          type={type}
          data={data}
          options={options}
          height={height ? height : ''}
          width={width ? width : ''}
          aria-label={aria}
          />
      </div>
    );
  }

  export default ChartComponent;