import React, { useEffect, useState } from 'react';
import { ButtonAdd, ButtonClose } from '../../../components/Buttons';
import { ModalDialog } from '../../../components/ModalDialog';
import { AdditionalMemberTable } from '../../../components/AdditionalMemberTable';

export const AdditionalTermMemberDialog = ({ members, isVisible, onHide, onConfirm }) => {
    const [selectedMembers, setSelectedMembers] = useState([]);

    useEffect(() => {
        setSelectedMembers([]);
    }, [isVisible, members]);

    const onSelectionChange = (e) => {
        setSelectedMembers(e.value);
    };

    return (
        <ModalDialog
            visible={isVisible}
            header='Zvolte členy pro funkční období'
            style={{ width: 500 }}
            footer={
                <>
                    <ButtonClose label='Zavřít' onClick={onHide} />
                    <ButtonAdd label='Přidat členy' type='button' onClick={() => onConfirm(selectedMembers)} />
                </>
            }
            onHide={onHide}
        >
            <AdditionalMemberTable
                members={members}
                selectedMembers={selectedMembers}
                onSelectionChange={onSelectionChange}
                emptyMessage='Pro zvolenou municipalitu nebyli nalezeni žádní členové'
            />
        </ModalDialog>
    );
};
