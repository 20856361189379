import React, { useState } from 'react';
import { DataTable, Dropdown } from './PrimereactComponents';
import { useTranslation } from 'react-i18next';

const PaginatedDataTable = ({ 
    data, 
    children, 
    rowsPerPageOptions = [5, 10, 15, 25],
    initialRows = 5,
    paginatorTemplate,
    onSelectionChange,
    selection,
    selectionMode,
    initialSortField,
    initialSortOrder,
    emptyMessage,
    header
}) => {
  const [rows, setRows] = useState(initialRows);
  const sortField = initialSortField || null;
  const sortOrder = initialSortOrder || 1;
  const { t } = useTranslation();

  const onRowsChange = (event) => {
    setRows(event.value);
  }

  const customPaginatorTemplate = paginatorTemplate || {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      return (
        <React.Fragment>
          <span>{t("results-per-page")}</span>
          <Dropdown
            className="pagination-dropdown"
            value={options.value}
            options={rowsPerPageOptions}
            onChange={onRowsChange}
          />
        </React.Fragment>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span className="pagination-numbers">
          {options.first} - {options.last} z {options.totalRecords}
        </span>
      );
    }
  };

  return (
    <DataTable 
        value={data}
        paginator
        paginatorTemplate={customPaginatorTemplate}  
        rows={rows} 
        rowsPerPageOptions={rowsPerPageOptions}
        selectionMode="single" 
        selection={selection} 
        onSelectionChange={onSelectionChange} 
        responsiveLayout="stack" 
        breakpoint="768px"
        sortField={sortField}
        sortOrder={sortOrder}
        removableSort
        emptyMessage={emptyMessage}
        header={header}
    >
      {children}
    </DataTable>
  );
};

export default PaginatedDataTable;