import React from 'react';
import { PrimeIcons } from 'primereact/api';
import { Button } from '../../components/PrimereactComponents';
import { classNames } from 'primereact/utils';

const ButtonSeverity = {
    SUCCESS: 'success',
    DANGER: 'danger',
    WARNING: 'warning'
};

/**
 * @param {import('primereact/button').ButtonProps & {severity: 'success'|'danger'|'warning'=, rounded: boolean=, outlined: boolean=}} props
 */
export const BaseButton = ({ severity, rounded, outlined, ...buttonProps }) => {
    const baseClass = buttonProps.className || '';
    return (
        <Button
            {...buttonProps}
            className={classNames(baseClass, {
                'p-button-success': severity === ButtonSeverity.SUCCESS,
                'p-button-danger': severity === ButtonSeverity.DANGER,
                'p-button-warning': severity === ButtonSeverity.WARNING,
                'p-button-rounded': rounded,
                'p-button-outlined': outlined
            })}
        />
    );
};

/**
 * @param {import('primereact/button').ButtonProps} props
 */
export const ButtonAdd = (props) => {
    return <BaseButton severity={ButtonSeverity.SUCCESS} icon={PrimeIcons.PLUS} {...props} />;
};

/**
 * @param {import('primereact/button').ButtonProps} props
 */
export const ButtonYes = (props) => {
    return <BaseButton severity={ButtonSeverity.SUCCESS} icon={PrimeIcons.CHECK} {...props} />;
};

/**
 * @param {import('primereact/button').ButtonProps & {rounded: boolean}} props
 */
export const ButtonDelete = (props) => {
    return <BaseButton severity={ButtonSeverity.DANGER} icon={PrimeIcons.TRASH} {...props} />;
};

/**
 * @param {import('primereact/button').ButtonProps} props
 */
export const ButtonNo = (props) => {
    return <BaseButton severity={ButtonSeverity.DANGER} icon={PrimeIcons.TIMES} {...props} />;
};

/**
 * @param {import('primereact/button').ButtonProps} props
 */
export const ButtonClose = (props) => {
    return <BaseButton icon={PrimeIcons.TIMES} outlined {...props} />;
};

/**
 * @param {import('primereact/button').ButtonProps & {rounded: boolean}} props
 */
export const ButtonEdit = (props) => {
    return <BaseButton severity={ButtonSeverity.WARNING} icon={PrimeIcons.PENCIL} {...props} />;
};

/**
 * @param {import('primereact/button').ButtonProps & {rounded: boolean}} props
 */
export const ButtonDetail = (props) => {
    return <BaseButton icon='bi bi-info-square' {...props} />;
};

/**
 * @param {import('primereact/button').ButtonProps & {rounded: boolean}} props
 */
export const ButtonRevert = (props) => {
    return <BaseButton severity={ButtonSeverity.WARNING} icon={PrimeIcons.REFRESH} {...props} />;
};
