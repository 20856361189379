import { Messages } from 'primereact/messages';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingPage from '../../../components/LoadingPage';
import { getErrorMessage } from '../../../utils/errors';
import { ErrorMessage } from '../../components/ErrorMessage';
import authAxios from '../../lib/authAxios';
import { showErrorMessage, showSuccessMessage } from '../../utils/messages';
import { scrollTop } from '../../utils/scroll';
import { formatMemberName } from '../../../utils/texts';
import { TermEditForm } from './components/TermEditForm';
import { IconTooltip } from '../../components/IconTooltip';
import { StaticTermMemberTable } from './components/StaticTermMemberTable';
import { Divider } from 'primereact/divider';
import { TermBreadCrumb } from './components/TermBreadCrumb';

export default function TermEdit() {
    const { municipalityId, termId } = useParams();
    const bodyType = 'zastupitelstvo';

    const msgs = useRef(null);

    const [loading, setLoading] = useState(true);
    const [initError, setInitError] = useState(null);
    const [term, setTerm] = useState(null);
    const [members, setMembers] = useState([]);
    const [politicalEntities, setPoliticalEntities] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [termResponse, politicalEntitiesResponse, membersResponse] = await Promise.all([
                    authAxios.get(`admin/${municipalityId}/${bodyType}/term/${termId}`),
                    authAxios.get(`admin/${municipalityId}/${bodyType}/political-entity`),
                    authAxios.get(`admin/${municipalityId}/${bodyType}/member`)
                ]);
                setTerm(termResponse.data);
                setPoliticalEntities(politicalEntitiesResponse.data);
                setMembers(
                    membersResponse.data.map((member) => ({
                        id: member.id,
                        label: formatMemberName(member.first_name, member.last_name)
                    }))
                );
            } catch (error) {
                const errorMessage = getErrorMessage(error);
                setInitError(errorMessage ?? 'Nepodařilo se načíst data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [municipalityId, termId]);

    const onSubmit = async (data) => {
        const requestData = {
            year_from: data.year_from.getFullYear(),
            year_to: data.year_to.getFullYear(),
            coalition: data.coalition,
            leader_title: data.leader_title,
            leader: data.leader
        };
        try {
            await authAxios.put(`admin/${municipalityId}/${bodyType}/term/${termId}`, requestData);
            showSuccessMessage(msgs, `Funkční období č. ${termId} bylo úspěšně upraveno`, {
                replace: true
            });
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se upravit funkční období', { replace: true });
            scrollTop();
        }
    };

    return loading ? (
        <LoadingPage />
    ) : (
        <>
            <TermBreadCrumb
                extraItems={[
                    {
                        label: 'Upravit',
                        url: `/admin/municipality/${municipalityId}/funkcni-obdobi/${termId}`
                    }
                ]}
            />
            <h1>Upravit funkční období</h1>

            {initError ? (
                <ErrorMessage prefix='Nelze použít formulář' text={initError} />
            ) : (
                <>
                    <Messages ref={msgs} className='page__messages' />

                    <TermEditForm
                        term={term}
                        members={members}
                        politicalEntities={politicalEntities}
                        onSubmit={onSubmit}
                    />
                    <Divider />
                    <h2>
                        Členové
                        <IconTooltip>
                            Členové, kteří byli aktivní v průběhu funkčního období. Další mohou být přidáni při vkládání
                            jednotlivých zasedání.
                        </IconTooltip>
                    </h2>
                    <StaticTermMemberTable members={term.members} />
                </>
            )}
        </>
    );
}
