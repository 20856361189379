import { RadioButton } from 'primereact/radiobutton';
import { classNames } from 'primereact/utils';
import React from 'react';
import { Controller } from 'react-hook-form';

/**
 * @param {object} params
 * @param {import('react-hook-form').UseFormRegister<any>} params.control
 * @param {string} params.name
 * @param {import('react-hook-form').RegisterOptions} params.rules
 * @param {import('primereact/radiobutton').RadioButtonProps} params.inputProps
 * @param {(e: import('primereact/radiobutton').RadioButtonChangeParams, field: import('react-hook-form').ControllerRenderProps) => void} params.onChange
 */
export const RHFRadioButton = ({ control, name, rules, inputProps, onChange }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => (
                <RadioButton
                    {...field}
                    ref={undefined}
                    inputRef={field.ref}
                    className={classNames({ 'p-invalid': fieldState.invalid })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            field.onChange(inputProps.value);
                        } else if (e.key === ' ') {
                            field.onChange(inputProps.value);
                        }
                    }}
                    {...inputProps}
                    checked={field.value === inputProps.value}
                    {...(onChange && { onChange: (e) => onChange(e, field) })}
                />
            )}
        />
    );
};
