import PopupBodyChart from "../../../../components/map/popup/PopupBodyChart";
import PopupFooter from "../../../../components/map/popup/PopupFooter";
import PopupHeader from "../../../../components/map/popup/PopupHeader";
import data from "../data";
import geo from "../geo";
import meta from "../meta";

/**
 * salary statistics.
 * 
 * @author Adam Janosik, Kristyna Zaklova
 */
const salary23 = {
    label: "Rok 2023",
    data: data,
    categories: meta.prumernaHrubaMzda23.values,
    getValue: meta.prumernaHrubaMzda23.getValue,
    formatValue: meta.prumernaHrubaMzda23.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle průměrné hrubé mzdy obyvatel v jednotlivých krajích (rok 2023)",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Průměrná hrubá mzda obyvatel jednotlivých krajů se v roce <span className="emp"><strong>2023</strong></span> pohybovala od <span className="emp"><strong>35&nbsp;565&nbsp;Kč</strong></span> do <span className="emp"><strong>50&nbsp;318&nbsp;Kč</strong></span>. Největší hrubá mzda obyvatel byla v <span className="emp"><strong>Praze</strong></span> a nejmenší v <span className="emp"><strong>Karlovarském kraji</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.prumernaHrubaMzda23.values,
                                            getValue: meta.prumernaHrubaMzda23.getValue,
                                            formatValue: meta.prumernaHrubaMzda23.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            ...meta.csuSalary,
                                            label: "ČSÚ - mzdy a náklady práce"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}


const salary22 = {
    label: "Rok 2022",
    data: data,
    categories: meta.prumernaHrubaMzda22.values,
    getValue: meta.prumernaHrubaMzda22.getValue,
    formatValue: meta.prumernaHrubaMzda22.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle průměrné hrubé mzdy obyvatel v jednotlivých krajích (rok 2022)",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Průměrná hrubá mzda obyvatel jednotlivých krajů se v roce <span className="emp"><strong>2022</strong></span> pohybovala od <span className="emp"><strong>32&nbsp;910&nbsp;Kč</strong></span> do <span className="emp"><strong>46&nbsp;999&nbsp;Kč</strong></span>. Největší hrubá mzda obyvatel byla v <span className="emp"><strong>Praze</strong></span> a nejmenší v <span className="emp"><strong>Karlovarském kraji</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.prumernaHrubaMzda22.values,
                                            getValue: meta.prumernaHrubaMzda22.getValue,
                                            formatValue: meta.prumernaHrubaMzda22.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            ...meta.csuSalary,
                                            label: "ČSÚ - mzdy a náklady práce"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const salary21 = {
    label: "Rok 2021",
    data: data,
    categories: meta.prumernaHrubaMzda21.values,
    getValue: meta.prumernaHrubaMzda21.getValue,
    formatValue: meta.prumernaHrubaMzda21.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle průměrné hrubé mzdy obyvatel v jednotlivých krajích (rok 2021)",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Průměrná hrubá mzda obyvatel jednotlivých krajů se v roce <span className="emp"><strong>2021</strong></span> pohybovala od <span className="emp"><strong>31&nbsp;844&nbsp;Kč</strong></span> do <span className="emp"><strong>44&nbsp;742&nbsp;Kč</strong></span>. Největší hrubá mzda obyvatel byla v <span className="emp"><strong>Praze</strong></span> a nejmenší v <span className="emp"><strong>Karlovarském kraji</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.prumernaHrubaMzda21.values,
                                            getValue: meta.prumernaHrubaMzda21.getValue,
                                            formatValue: meta.prumernaHrubaMzda21.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            ...meta.csuSalary,
                                            label: "ČSÚ - mzdy a náklady práce"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const salary20 = {
    label: "Rok 2020",
    data: data,
    categories: meta.prumernaHrubaMzda20.values,
    getValue: meta.prumernaHrubaMzda20.getValue,
    formatValue: meta.prumernaHrubaMzda20.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle průměrné hrubé mzdy obyvatel v jednotlivých krajích (rok 2020)",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Průměrná hrubá mzda obyvatel jednotlivých krajů se v roce <span className="emp"><strong>2020</strong></span> pohybovala od <span className="emp"><strong>29&nbsp;983&nbsp;Kč</strong></span> do <span className="emp"><strong>42&nbsp;573&nbsp;Kč</strong></span>. Největší hrubá mzda obyvatel byla v <span className="emp"><strong>Praze</strong></span> a nejmenší v <span className="emp"><strong>Karlovarském kraji</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.prumernaHrubaMzda20.values,
                                            getValue: meta.prumernaHrubaMzda20.getValue,
                                            formatValue: meta.prumernaHrubaMzda20.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            ...meta.csuSalary,
                                            label: "ČSÚ - mzdy a náklady práce"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}


const salaryInc = {
    label: "Rozdíl mezi lety 2023 a 2020",
    data: data,
    categories: meta.prumernaHrubaMzdaVyvoj.values,
    getValue: meta.prumernaHrubaMzdaVyvoj.getValue,
    formatValue: meta.prumernaHrubaMzdaVyvoj.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle vývoje průměrné hrubé mzdy v jednotlivých krajích v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Průměrná hrubá mzda v letech <span className="emp"><strong>2020–2023</strong></span> ve všech krajích vzrostla, a to o <span className="emp"><strong>5&nbsp;419&nbsp;Kč</strong></span> až <span className="emp"><strong>7&nbsp;745&nbsp;Kč</strong></span>. Největší nárůst byl v <span className="emp"><strong>Praze</strong></span> a nejmenší v <span className="emp"><strong>Olomouckém kraji</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.prumernaHrubaMzdaVyvoj.values,
                                            getValue: meta.prumernaHrubaMzdaVyvoj.getValue,
                                            formatValue: meta.prumernaHrubaMzdaVyvoj.formatValue
                                        }
                                    }
                                },
                                {
                                    title: "Vývoj průměrných hrubých mezd v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                                
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "line",
                                        config: {
                                            geo: geo,
                                            datasets: [
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.prumernaHrubaMzda20.values,
                                                    getValue: meta.prumernaHrubaMzda20.getValue,
                                                    formatValue: meta.prumernaHrubaMzda20.formatValue,
                                                    label: meta.prumernaHrubaMzda20.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.prumernaHrubaMzda21.values,
                                                    getValue: meta.prumernaHrubaMzda21.getValue,
                                                    formatValue: meta.prumernaHrubaMzda21.formatValue,
                                                    label: meta.prumernaHrubaMzda21.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.prumernaHrubaMzda22.values,
                                                    getValue: meta.prumernaHrubaMzda22.getValue,
                                                    formatValue: meta.prumernaHrubaMzda22.formatValue,
                                                    label: meta.prumernaHrubaMzda22.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.prumernaHrubaMzda23.values,
                                                    getValue: meta.prumernaHrubaMzda23.getValue,
                                                    formatValue: meta.prumernaHrubaMzda23.formatValue,
                                                    label: meta.prumernaHrubaMzda23.label
                                                }
                                            ]
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            ...meta.csuSalary,
                                            label: "ČSÚ - mzdy a náklady práce"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const genericPopup = {
    header: {
        component: PopupHeader,
        props: {
            data: data
        }
    },
    body: {
        component: PopupBodyChart,
        props: {
            data: data,
            stats: [
                {
                    title: "Srovnání dle vývoje průměrné hrubé mzdy v jednotlivých krajích v období 2020-2023",
                    content: () => {
                        return (
                            <>
                                <p>Průměrná hrubá mzda v letech <span className="emp"><strong>2020–2023</strong></span> ve všech krajích vzrostla, a to o <span className="emp"><strong>5&nbsp;419&nbsp;Kč</strong></span> až <span className="emp"><strong>7&nbsp;745&nbsp;Kč</strong></span>. Největší nárůst byl v <span className="emp"><strong>Praze</strong></span> a nejmenší v <span className="emp"><strong>Olomouckém kraji</strong></span>.</p>
                            </>
                        )
                    },
                    chart: {
                        type: "bar",
                        config: {
                            geo: geo,
                            data: data,
                            categories: meta.prumernaHrubaMzdaVyvoj.values,
                            getValue: meta.prumernaHrubaMzdaVyvoj.getValue,
                            formatValue: meta.prumernaHrubaMzdaVyvoj.formatValue
                        }
                    }
                },
                {
                    title: "Vývoj průměrných hrubých mezd v období 2020-2023",
                    content: () => {
                        return (
                            <>
                                
                            </>
                        )
                    },
                    chart: {
                        type: "line",
                        config: {
                            geo: geo,
                            datasets: [
                                {
                                    geo: geo,
                                    data: data,
                                    categories: meta.prumernaHrubaMzda20.values,
                                    getValue: meta.prumernaHrubaMzda20.getValue,
                                    formatValue: meta.prumernaHrubaMzda20.formatValue,
                                    label: meta.prumernaHrubaMzda20.label
                                },
                                {
                                    geo: geo,
                                    data: data,
                                    categories: meta.prumernaHrubaMzda21.values,
                                    getValue: meta.prumernaHrubaMzda21.getValue,
                                    formatValue: meta.prumernaHrubaMzda21.formatValue,
                                    label: meta.prumernaHrubaMzda21.label
                                },
                                {
                                    geo: geo,
                                    data: data,
                                    categories: meta.prumernaHrubaMzda22.values,
                                    getValue: meta.prumernaHrubaMzda22.getValue,
                                    formatValue: meta.prumernaHrubaMzda22.formatValue,
                                    label: meta.prumernaHrubaMzda22.label
                                },
                                {
                                    geo: geo,
                                    data: data,
                                    categories: meta.prumernaHrubaMzda23.values,
                                    getValue: meta.prumernaHrubaMzda23.getValue,
                                    formatValue: meta.prumernaHrubaMzda23.formatValue,
                                    label: meta.prumernaHrubaMzda23.label
                                }
                            ]
                        }
                    }
                }
            ]
        }
    },
    footer: {
        component: PopupFooter,
        props: {
            data: data,
            settings: {
                left: {
                    title: {
                        label: "Zdroje:",
                        icon: "pi pi-fw pi-globe"
                    },
                    links: [
                        {
                            label: "ČSÚ - mzdy a náklady práce",
                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt-parametry&z=T&f=TABULKA&katalog=30852&sp=A&skupId=855&pvo=MZD01-C&u=v159__VUZEMI__100__3018&evo=v208_%21_MZD-R-ABS-od2011_1&str=v159"
                        }
                    ]
                },
                right: {
                    title: undefined,
                    links: [
                        meta.route
                    ]
                }
            }
        }
    },
    style: {
        width: '80vw',
        height: '100vh',
        maxWidth: '1200px',
    }
}

const salaryStats = {
    title: 'Průměrná hrubá mzda obyvatel',
    widget: {
        type: "column-group",
        config: {
            widgets: [
                {
                    type: "custom",
                    config: {
                        content: (
                            <p>
                                <span className="emp">Průměrná hrubá mzda</span> obyvatel se napříč kraji výrazně liší v&nbsp;závislosti na ekonomické struktuře a dostupnosti pracovních příležitostí v&nbsp;daném regionu. 
                                Nejvyšší mzdy jsou tradičně v regionech, kde je velká koncentrace firem a&nbsp;služeb, zatímco v krajích s menší ekonomickou aktivitou jsou mzdy obecně nižší. 
                                Tyto rozdíly odrážejí ekonomický potenciál jednotlivých regionů a&nbsp;dostupnost kvalifikovaných pracovních míst.
                            </p>
                        )
                    }
                },
                {
                    type: "regional-filtered-choropleth",
                    config: {
                        id: "mapa-prumernaHrubaMzda",
                        label: "Celkový počet hlasování",
                        geo: geo,
                        datasets: [
                            salaryInc, salary23, salary22, salary21, salary20
                        ]
                    }
                },
                {
                    type: "custom",
                    config: {
                        content: (
                            <p>
                                V&nbsp;letech <span className="emp"><strong>2020–⁠⁠⁠⁠⁠⁠2023</strong></span> lze pozorovat, že ve všech krajích došlo k&nbsp;růstu mezd – nejvíce narostla hrubá mzda obyvatel v&nbsp;<span className="emp">Praze</span> a nejméně v&nbsp;<span className="emp">Olomouckém kraji</span>.
                            </p>
                        )
                    }
                }
            ]
        }
    },
    footer: {
        left: {
            title: {
                label: "Zdroj:",
                icon: "pi pi-fw pi-globe"
            },
            links: [
                {
                    label: "ČSÚ - mzdy a náklady práce",
                    value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt-parametry&z=T&f=TABULKA&katalog=30852&sp=A&skupId=855&pvo=MZD01-C&u=v159__VUZEMI__100__3018&evo=v208_%21_MZD-R-ABS-od2011_1&str=v159"
                }
            ]
        },
        right: {
            title: undefined,
            links: [
                meta.route
            ]
        },
        popup: {
            component: genericPopup
        }
    }
}
export default salaryStats;