import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
//import LocalStorageBackend from 'i18next-localstorage-backend';
import ChainedBackend from "i18next-chained-backend";
i18n.use(ChainedBackend).use(initReactI18next).init({
    fallbackLng: 'cs',
    ns: ['translation', 'about', 'analyses', 'meetings', 'votings'],
    debug: false,
    interpolation: {
        escapeValue: false,
    },
    backend: {
        backends: [
           // LocalStorageBackend,
            HttpBackend
        ],
        backendOptions: [{
            //expirationTime: 7 * 24 * 60 * 60 * 1000,
            loadPath: process.env.PUBLIC_URL + '/locales/{{lng}}/{{ns}}.json'
        }, {
            loadPath: process.env.PUBLIC_URL + '/locales/{{lng}}/{{ns}}.json'
        }],
    }
});

i18n.services.formatter.addCached('lowercase', (str, lng, options) => {
    return str.toLowerCase();
});

i18n.services.formatter.addCached('uppercase', (str, lng, options) => {
    return str.toUpperCase();
});

i18n.services.formatter.addCached('capitalize', (str, lng, options) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
});

// Usage: {i18n.format(t("mandate_plural"), "addClasses", "", { classNames: ['text__secondary', 'text__bold'] })}
i18n.services.formatter.addCached('addClasses', (str, lng, options = {}) => {
    const { classNames = [] } = options;
    const classNameString = classNames.join(' ');
  
    return <span className={`${classNameString}`}>{str}</span>;
});

i18n.services.formatter.addCached('semiboldPrimary', (str, lng, options = {}) => {
    return <span className='text__semibold text__primary'>{str}</span>;
});

export default i18n;