import { Messages } from 'primereact/messages';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getErrorMessage } from '../../../utils/errors';
import authAxios from '../../lib/authAxios';
import { showErrorMessage, showSuccessMessage } from '../../utils/messages';
import { ResolveRequestAddDialog } from './components/ResolveRequestAddDialog';
import { ResolvePermissionRequestDialog } from './components/ResolvePermissionRequestDialog';
import { RequestMunicipalityManageTable } from './components/RequestMunicipalityManageTable';
import { RequestMunicipalityAddTable } from './components/RequestMunicipalityAddTable';

export default function PermissionRequests() {
    const [requestsManage, setRequestsManage] = useState(null);
    const [requestsAdd, setRequestsAdd] = useState(null);
    const [loading, setLoading] = useState(true);

    const [selectedRequest, setSelectedRequest] = useState(null);

    const [showResolveRequestDialog, setShowResolveRequestDialog] = useState(false);
    const [processingResolveRequest, setProcessingResolveRequest] = useState(false);
    const refResolvePermissionRequestMsgs = useRef(null);

    const [showResolveRequestAddDialog, setShowResolveRequestAddDialog] = useState(false);
    const [processingResolveRequestAdd, setProcessingResolveRequestAdd] = useState(false);
    const refResolveRequestAddMsgs = useRef(null);

    const msgs = useRef(null);

    const fetchData = useCallback(async () => {
        try {
            const [responseRequestsManage, responseRequestsAdd] = await Promise.all([
                authAxios.get('/admin/request/manage-municipality'),
                authAxios.get('/admin/request/add-municipality')
            ]);
            setRequestsManage(responseRequestsManage.data);
            setRequestsAdd(responseRequestsAdd.data);
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se načíst požadavky', { replace: true });
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleClickResolveRequest = (rowData) => {
        setSelectedRequest(rowData);
        setShowResolveRequestDialog(true);
    };

    const hideResolvePermissionRequestDialog = () => {
        setShowResolveRequestDialog(false);
        setSelectedRequest(null);
    };

    const handleResolvePermissionRequest = async (approved) => {
        if (!selectedRequest || selectedRequest.type !== 'manage') {
            return;
        }
        setProcessingResolveRequest(true);
        const requestData = {
            approved: approved
        };
        try {
            await authAxios.patch(`admin/request/manage-municipality/${selectedRequest.id}`, requestData);
            showSuccessMessage(
                msgs,
                `Žádost o správu municipality ${selectedRequest.municipality.name} (${
                    selectedRequest.municipality.text_id
                }) ${approved ? 'schválena' : 'zamítnuta'}`
            );
            setShowResolveRequestDialog(false);
            fetchData();
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se vyřídit žádost o správu municipality', {
                replace: true
            });
        } finally {
            setProcessingResolveRequest(false);
        }
    };

    const handleClickResolveRequestAdd = (rowData) => {
        setSelectedRequest(rowData);
        setShowResolveRequestAddDialog(true);
    };

    const hideResolveRequestAddDialog = () => {
        setShowResolveRequestAddDialog(false);
        setSelectedRequest(null);
    };

    const handleRejectRequestAdd = async () => {
        if (!selectedRequest || selectedRequest.type !== 'add') {
            return;
        }
        setProcessingResolveRequestAdd(true);
        const requestData = {
            approved: false
        };
        try {
            await authAxios.patch(`admin/request/add-municipality/${selectedRequest.id}`, requestData);
            showSuccessMessage(
                msgs,
                `Žádost o přidání municipality ${selectedRequest.municipality.name} (${selectedRequest.municipality.text_id}) zamítnuta'}`
            );
            hideResolveRequestAddDialog();
            fetchData();
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(
                refResolveRequestAddMsgs,
                errorMessage ?? 'Nepodařilo se vyřídit žádost o přidání municipality',
                { replace: true }
            );
        } finally {
            setProcessingResolveRequestAdd(false);
        }
    };

    return (
        <>
            <h1>Požadavky na správu municipalit</h1>

            <Messages ref={msgs} className='page__messages' />

            <TabView className='tabview--admin'>
                <TabPanel header='Spravovat municipalitu'>
                    <RequestMunicipalityManageTable
                        requests={requestsManage}
                        loading={loading}
                        onClickResolve={handleClickResolveRequest}
                    />
                </TabPanel>
                <TabPanel header='Přidat municipalitu'>
                    <RequestMunicipalityAddTable
                        requests={requestsAdd}
                        loading={loading}
                        onClickResolve={handleClickResolveRequestAdd}
                    />
                </TabPanel>
            </TabView>

            <ResolvePermissionRequestDialog
                isVisible={showResolveRequestDialog}
                processing={processingResolveRequest}
                request={selectedRequest}
                onHide={hideResolvePermissionRequestDialog}
                onApprove={() => handleResolvePermissionRequest(true)}
                onReject={() => handleResolvePermissionRequest(false)}
                msgs={refResolvePermissionRequestMsgs}
            />

            <ResolveRequestAddDialog
                isVisible={showResolveRequestAddDialog}
                processing={processingResolveRequestAdd}
                request={selectedRequest}
                onHide={hideResolveRequestAddDialog}
                onReject={handleRejectRequestAdd}
                msgs={refResolveRequestAddMsgs}
            />
        </>
    );
}
