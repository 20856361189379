import { Messages } from 'primereact/messages';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getErrorMessage } from '../../../utils/errors';
import authAxios from '../../lib/authAxios';
import { showErrorMessage } from '../../utils/messages';
import { TermTable } from './components/TermTable';
import { TermBreadCrumb } from './components/TermBreadCrumb';
import { ButtonAdd } from '../../components/Buttons';

export default function Terms() {
    const navigate = useNavigate();

    const { municipalityId } = useParams();
    const bodyType = 'zastupitelstvo';

    const [terms, setTerms] = useState(null);
    const [loading, setLoading] = useState(true);

    const msgs = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await authAxios.get(`admin/${municipalityId}/${bodyType}/term`);
                setTerms(response.data);
            } catch (error) {
                const errorMessage = getErrorMessage(error);
                showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se načíst funkční období');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [municipalityId]);

    return (
        <>
            <TermBreadCrumb />
            <h1>Funkční období</h1>

            <div className='page__buttons'>
                <ButtonAdd
                    label='Přidat funkční období'
                    onClick={() => navigate(`/admin/municipality/${municipalityId}/funkcni-obdobi/new`)}
                />
            </div>

            <Messages ref={msgs} className='page__messages' />

            <TermTable terms={terms} loading={loading} />
        </>
    );
}
