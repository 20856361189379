import React from "react";
import { ProgressSpinner } from "./PrimereactComponents";

function LoadingPage() {
  return (
    <div className="loading-page">
      <div className="loading-page__content">
        <ProgressSpinner style={{ width: "200px", height: "200px" }} />
        <h3 className="loading-page__text">Probíhá načítání dat...</h3>
      </div>
    </div>
  );
}

export default LoadingPage;
