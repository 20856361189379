import commonStats from "../../stats/kraje/stats/common";
import transparencyStats from "../../stats/kraje/stats/transparency";
import { krajeList } from "../../municipality/kraje";
import { mesta } from "../../municipality/mesta";
import { aboutSection } from "../default/sections/about";
import { datasetsSection } from "../default/sections/datasets";
import { partnersSection } from "../default/sections/partners";

/**
 * Definition of Home page.
 * 
 * @author Jiri Hynek
 */
const home = (response) => {
    return {
        id: "home",
        header: {
            image: {
                large: "loga/title/kraje/large.svg",
                small: "loga/title/kraje/small.svg",
            }
        },
        sections: [
            {
                id: "intro",
                noAnchor: true,
                heading: {
                    label: "Vizualizace z hlasování krajských zastupitelstev",
                },
                content: {
                    type: "widget-group",
                    config: {
                        widgets: [
                            {
                                type: "municipality-card-grid",
                                config: {
                                    items: krajeList
                                }
                            },
                            {
                                type: "custom",
                                config: {
                                    style: {
                                        fontSize: "2rem",
                                        textAlign: "center"
                                    },
                                    content: (
                                        <h3>+</h3>
                                    )
                                }
                            },
                            {
                                type: "municipality-card-grid",
                                config: {
                                    items: [ mesta.praha ]
                                }
                            }
                        ]
                    }
                }
            },
            {
                id: "analyzy",
                heading: {
                    label: "Porovnávejte jednotlivé kraje",
                },
                content: {
                    type: "widget-group",
                    config: {
                        widgets: [
                            {
                                type: "stats-grid",
                                config: {
                                    stats: [
                                        commonStats,
                                        transparencyStats
                                    ]
                                }
                            },
                            {
                                type: "detail-ref",
                                config: {
                                    url: "/analyzy",
                                    label: "Více analýz"
                                }
                            }
                        ]
                    }
                }
            },
            datasetsSection,
            partnersSection,
            aboutSection
        ]
    }
}
export default home;