export const redirectErrorPage = (municipalitaId, councilId, bodyType) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    window.location.href = municipalitaId && councilId && bodyType ? baseUrl + `${municipalitaId}/${bodyType}/${councilId}/chyba` : '/chyba';
};

export const getErrorMessage = (error) => {
    const response = error.response;
    if (response) {
        const data = response.data;
        if (data) {
            return data.error;
        }
    }
};