import React from "react";

import Widget from "./Widget";

import "./ColumnGroup.css"
import WidgetGroup from "./WidgetGroup";

function ColumnGroup({
    widgets = [],
    style = {}
}) {

    return (
        <WidgetGroup
            widgets={widgets}
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                ...style
            }}/>
    )
}
export default ColumnGroup;