import { Messages } from 'primereact/messages';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getErrorMessage } from '../../../utils/errors';
import authAxios from '../../lib/authAxios';
import { ButtonAdd } from '../../components/Buttons';
import { showErrorMessage } from '../../utils/messages';
import { MeetingTable } from './components/MeetingTable';
import { MeetingBreadCrumb } from './components/MeetingBreadCrumb';

export default function Meetings() {
    const { municipalityId, termId } = useParams();
    const bodyType = 'zastupitelstvo';

    const [meetings, setMeetings] = useState(null);
    const [loading, setLoading] = useState(true);

    const msgs = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await authAxios.get(`admin/${municipalityId}/${bodyType}/term/${termId}/meeting`);
                setMeetings(response.data);
            } catch (error) {
                const errorMessage = getErrorMessage(error);
                showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se načíst zasedání');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [municipalityId, termId]);

    return (
        <>
            <MeetingBreadCrumb />
            <h1>Zasedání</h1>

            <div className='page__buttons'>
                <ButtonAdd
                    label='Přidat zasedání'
                    onClick={() => {
                        navigate(`/admin/municipality/${municipalityId}/funkcni-obdobi/${termId}/zasedani/new`);
                    }}
                />
            </div>

            <Messages ref={msgs} className='page__messages' />

            <MeetingTable meetings={meetings} loading={loading} />
        </>
    );
}
