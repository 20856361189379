import React from 'react';
import { Outlet, useLoaderData } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getBodyNameGenitive } from '../utils/texts';
import { logoUrl } from '../utils/logoUrl';

export default function MunicipalityHelmet() {
    const municipality = useLoaderData();

    if (municipality) {
        const organ = municipality.organy['zastupitelstvo'];
        const votingMessage = `hlasování ${getBodyNameGenitive('zastupitelstvo')}`;

        return (
            <>
                <Helmet>
                    <title>{organ.nazev_aplikace}</title>
                    <meta name='description' content={organ.popis_aplikace} />
                    <meta
                        name='keywords'
                        content={`${organ.nazev}, ${municipality.nazev}, zastupitelstvo, ${votingMessage}, otevřená data, vizualizace`}
                    />
                    {municipality.logo && <meta property='og:image' content={logoUrl(municipality.text_id)} />}
                    <meta property='og:title' content={organ.nazev_aplikace} />
                    <meta property='og:description' content={organ.popis_aplikace} />
                </Helmet>
                <Outlet />
            </>
        );
    }
}
