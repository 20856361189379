import { Dropdown } from 'primereact/dropdown';
import { Panel } from 'primereact/panel';
import React, { useMemo } from 'react';
import { formatMemberName } from '../../../../utils/texts';
import { RHFCheckbox, RHFDropdown } from '../../../components/rhf';
import { formatPoliticalEntityName } from '../../../utils/format';

export const EntityVoteCards = ({ members, voteOptions, setValue, control, onVoteOptionChanged, disabled }) => {
    const politicalEntities = useMemo(() => {
        const entityIds = new Set();
        const result = [];

        members.forEach((member) => {
            if (!entityIds.has(member.political_entity.id)) {
                entityIds.add(member.political_entity.id);
                result.push({
                    id: member.political_entity.id,
                    abbreviation: member.political_entity.abbreviation,
                    name: member.political_entity.name
                });
            }
        });

        return result;
    }, [members]);

    return (
        <>
            {politicalEntities.map((entity) => {
                const entityMembers = members.filter((member) => member.political_entity.id === entity.id);

                return (
                    <EntityVoteCard
                        members={entityMembers}
                        politicalEntity={entity}
                        voteOptions={voteOptions}
                        control={control}
                        setValue={setValue}
                        onVoteOptionChanged={onVoteOptionChanged}
                        key={`card-${entity.id}`}
                        disabled={disabled}
                    />
                );
            })}
        </>
    );
};

const EntityVoteCard = ({
    members,
    politicalEntity,
    voteOptions,
    setValue,
    control,
    onVoteOptionChanged,
    disabled
}) => {
    const onEntityVoteOptionChange = (e) => {
        if (!e.value) return;
        members.forEach((member) => {
            setValue(`members[${member.id}].option`, e.value);
        });
        onVoteOptionChanged();
    };

    const header = (
        <>
            <strong>
                <span>{formatPoliticalEntityName(politicalEntity)}</span>
                <span title='Počet členů' className='ml-2'>
                    <i className='bi bi-people-fill' /> {members.length}
                </span>
            </strong>
            <Dropdown
                options={voteOptions}
                optionLabel='name'
                optionValue='code'
                emptyMessage='Nejsou k dispozici žádné hlasovací možnosti'
                placeholder='Hromadná volba'
                tooltip='Hromadně nastavit hlasovací možnost'
                onChange={onEntityVoteOptionChange}
                disabled={disabled}
            />
        </>
    );

    return (
        <Panel toggleable header={header} className='member-vote-panel'>
            {members.map((member) => (
                <div className='member-vote-panel__item' key={`card-${politicalEntity.id}-member-${member.id}`}>
                    <h4>{formatMemberName(member.first_name, member.last_name)}</h4>
                    <div>
                        <RHFDropdown
                            control={control}
                            name={`members[${member.id}].option`}
                            rules={{ required: true }}
                            placeholder='Volba'
                            inputProps={{
                                options: voteOptions,
                                optionLabel: 'name',
                                optionValue: 'code',
                                emptyMessage: 'Nejsou k dispozici žádné hlasovací možnosti',
                                title: 'Hlasovací možnost',
                                showClear: false
                            }}
                            onChange={(e, field) => {
                                field.onChange(e);
                                onVoteOptionChanged();
                            }}
                        />
                        <RHFCheckbox control={control} name={`members[${member.id}].in_person`} />
                        <label htmlFor={`members[${member.id}].in_person`}>
                            <small>Prezenčně</small>
                        </label>
                    </div>
                </div>
            ))}
        </Panel>
    );
};
