import { Column } from 'primereact/column';
import React from 'react';
import { PaginatedDataTable } from '../../../components/PaginatedDataTable';
import { UserRoleChip } from '../../../components/UserRoleChip';

export const UserTable = ({ users, loading }) => {
    const roleTemplate = (rowData) => {
        return <UserRoleChip role={rowData.role} />;
    };

    return (
        <PaginatedDataTable
            value={users}
            loading={loading}
            removableSort
            breakpoint='900px'
            emptyMessage='Nebyli nalezeni žádní uživatelé'
        >
            <Column field='id' header='ID' />
            <Column field='first_name' header='Jméno' sortable />
            <Column field='last_name' header='Příjmení' sortable />
            <Column field='login' header='Login' sortable />
            <Column header='Role' body={roleTemplate} align='center' />
        </PaginatedDataTable>
    );
};
