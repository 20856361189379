import React, { useRef } from 'react';
import { Link, useParams, useNavigate, useLoaderData } from 'react-router-dom';
import { redirectErrorPage } from '../../utils/errors';
import ChartComponent from '../../components/ChartComponent';
import { useTranslation } from 'react-i18next';
import useDimensions from '../../hooks/useDimensions';
import {
    Card,
    Divider,
    Toolbar,
    Tooltip,
    ScrollPanel,
    Button,
    OverlayPanel,
    DataTable,
    Column,
    Chip
} from '../../components/PrimereactComponents';
import { AliasTooltip } from '../../components/AliasTooltip';

function PartyDetail() {
    const { width } = useDimensions();

    const { t } = useTranslation();

    const op = useRef(null);

    const { bodyType, municipalitaId, zastupitelstvoId } = useParams();

    const navigate = useNavigate();
    const partyData = useLoaderData();

    var style = getComputedStyle(document.body);
    const colors = [
        style.getPropertyValue('--clr-1'),
        style.getPropertyValue('--clr-2'),
        style.getPropertyValue('--clr-3'),
        style.getPropertyValue('--clr-4'),
        style.getPropertyValue('--clr-5'),
        style.getPropertyValue('--clr-6'),
        style.getPropertyValue('--clr-7')
    ];

    //TODO: z DB
    const voteOptions = { ano: 1, ne: 2, 'zdržel se': 3, nehlasoval: 4, 'nepřít.': 5, omluven: 6, tajná: 7 };
    const hasLatestMeeting = !!partyData.dochazka.celkem_zasedani;

    const voteColors = {
        ano: colors[0],
        ne: colors[1],
        'zdržel se': colors[2],
        nehlasoval: colors[3],
        'nepřít.': colors[4],
        tajná: colors[5],
        omluven: colors[6]
    };

    if (partyData !== undefined) {
        if (JSON.stringify(partyData) === '{"error":"neexistujici ID"}') {
            redirectErrorPage(municipalitaId, zastupitelstvoId);
        } else {
            let summaryVotesLabels = Object.keys(partyData.celkove_hlasy);
            let summaryVotesValues = Object.values(partyData.celkove_hlasy);

            let summaryVotesData = {
                labels: [''],
                datasets: summaryVotesValues.map((item, index) => ({
                    type: 'bar',
                    label: summaryVotesLabels[index],
                    backgroundColor: voteColors[summaryVotesLabels[index]],
                    data: [item]
                })).sort((a, b) => {
                    return Object.keys(voteOptions).indexOf(a.label) - Object.keys(voteOptions).indexOf(b.label);
                })
            };

            const stackedOptions = {
                indexAxis: 'y',
                maintainAspectRatio: false,
                aspectRatio: 0.8,
                plugins: {
                    tooltips: {
                        mode: 'index',
                        intersect: false
                    },
                    legend: {
                        labels: {
                            generateLabels: (chart) => {
                                const datasets = chart.data.datasets;
                                return datasets.map((data, i) => ({
                                    text: `${data.label} (${data.data[0]})`,
                                    fillStyle: data.backgroundColor,
                                    strokeStyle: 'white',
                                    datasetIndex: 0,
                                    index: i
                                }));
                            }
                        },
                        onHover: function (evt, item, legend) {
                            const tooltip = legend.chart.tooltip;
                            if (tooltip.getActiveElements().length > 0) {
                                tooltip.setActiveElements([], { x: 0, y: 0 });
                            }
                            tooltip.setActiveElements([{ datasetIndex: item.index, index: 0 }], { x: 0, y: 0 });

                            evt.native.target.style.cursor = 'pointer';
                            legend.chart.data.datasets.forEach((i, id) => {
                                if (id !== item.index && i.backgroundColor.length === 7) {
                                    i.backgroundColor += '30';
                                }
                            });
                            legend.chart.update();
                        },
                        onLeave: function (evt, item, legend) {
                            legend.chart.data.datasets.forEach((i, id) => {
                                if (id !== item.index && i.backgroundColor.length > 7) {
                                    i.backgroundColor = i.backgroundColor.slice(0, -2);
                                }
                            });
                            legend.chart.update();
                        }
                    }
                },
                scales: {
                    x: {
                        stacked: true
                    },
                    y: {
                        stacked: true,
                        position: 'right'
                    }
                },
                onHover: function (evt, item) {
                    if (item[0] !== undefined) {
                        evt.native.target.style.cursor = 'pointer';
                    } else {
                        evt.native.target.style.cursor = 'default';
                    }
                },
                onClick: function (evt, item) {
                    if (item[0] !== undefined) {
                        let voteOpt = summaryVotesData.datasets[item[0].datasetIndex].label;
                        // REDIRECT
                        navigate(
                            `/${municipalitaId}/${bodyType}/${zastupitelstvoId}/hlasovani?pohled=subjekt&subjekt=${partyData.strana_id}&hlasovaci_moznost=${voteOptions[voteOpt]}&pouze_platna=true`
                        );
                    }
                }
            };

            const leftContents = (
                <>
                    <div key='zasedani'>
                        {partyData.dochazka.mandat === partyData.dochazka.celkem_zasedani ? (
                            <>
                                <i className='pi pi-book text-icon__left text__primary'></i>
                                {t('active')} <span className='text__primary'>{t('whole')}</span> {t('term')}
                            </>
                        ) : (
                            <>
                                <i className='pi pi-book text-icon__left text__primary'></i>
                                {t('mandate_out_of', {
                                    cnt1: partyData.dochazka.mandat,
                                    cnt2: partyData.dochazka.celkem_zasedani
                                })}
                            </>
                        )}
                    </div>
                    {width > 992 ? <Divider layout='vertical' /> : ''}
                    <div key='hlasovani'>
                        {partyData.pocet_aktivnich > 0 ? (
                            <>
                                <i
                                    className='pi pi-users text-icon__left'
                                    style={{ color: partyData.strana_barva }}
                                ></i>
                                &nbsp;
                                <span style={{ color: partyData.strana_barva }}>
                                    {partyData.pocet_aktivnich}
                                </span>{' '}
                                {t('mandate', { count: partyData.pocet_aktivnich })} (
                                <span className='text__primary'>{partyData.clenove.length}</span>&nbsp;
                                {t('councilMember', { count: partyData.clenove.length })} {t('whole_term')})
                            </>
                        ) : (
                            <>
                                <i className='pi pi-users text-icon__left text__secondary'></i>&nbsp;
                                <span className='text__secondary'>{partyData.pocet_aktivnich}</span>&nbsp;
                                {t('mandate', { count: partyData.pocet_aktivnich })} (
                                <span className='text__primary'>{partyData.clenove.length}</span>&nbsp;
                                {t('councilMember', { count: partyData.clenove.length })} {t('whole_term')})
                            </>
                        )}
                    </div>
                    {width > 992 ? <Divider layout='vertical' /> : ''}
                    {
                        partyData.celkovy_pocet_hlasovani > 0 && (
                            <div key='ucast'>
                                <div>
                                    <span className=' text__primary'>
                                        {Math.round(
                                            (partyData.celkovy_pocet_ucast / partyData.celkovy_pocet_hlasovani) * 100
                                        ) || 0}
                                        %
                                    </span>{' '}
                                    {t('voting-attendance')}
                                    <Tooltip target='.custom-target-icon2' />
                                    <i
                                        className='custom-target-icon2 pi bi-info-circle text__secondary text-icon__right'
                                        data-pr-tooltip={t('tooltip-attendance', {
                                            cnt1: partyData.celkovy_pocet_ucast.toLocaleString('cz'),
                                            cnt2: partyData.celkovy_pocet_hlasovani.toLocaleString('cz')
                                        })}
                                        data-pr-position='bottom'
                                    ></i>
                                </div>
                            </div>
                        )
                    }
                </>
            );

            return (
                <div className='party-detail'>
                    <h1 className='party-detail-title' style={{ color: partyData.strana_barva }}>
                        {partyData.strana_nazev === null
                            ? partyData.strana_zkratka
                            : partyData.strana_nazev + ' (' + partyData.strana_zkratka + ')'}
                        {partyData.pocet_aktivnich === 0 ? (
                            <Chip label={t('inactive_party')} className='bg__secondary text__white' />
                        ) : partyData.koalicni_strana ? (
                            <Chip
                                label={t('coalition_party')}
                                className='text__white'
                                style={{ backgroundColor: partyData.strana_barva }}
                            />
                        ) : (
                            <Chip
                                label={t('opposition_party')}
                                className='text__white'
                                style={{ backgroundColor: partyData.strana_barva }}
                            />
                        )}
                    </h1>
                    <div className={`${hasLatestMeeting && "party-detail-grid"}`}>
                        <Card title={t('basic-info')} className='info-card'>
                            <div className='grid'>
                                {leftContents}
                            </div>
                        </Card>
                        <Card className='members-card' title={t('Members')}>
                            <div>
                                {partyData.pocet_aktivnich > 0 ? (
                                    <>
                                        <p className='text__primary representatives-list-title'>
                                            {t('active-members')}
                                        </p>
                                        <ul className='representatives-list__grid'>
                                            {partyData.clenove.map((member) =>
                                                member.aktivni ? (
                                                    <li
                                                        key={member.id}
                                                        className='representatives-list-cell bullet__primary'
                                                    >
                                                        <Link
                                                            to={`../../zastupitele/${member.id}`}
                                                            key={member.id}
                                                            className='text__black'
                                                        >
                                                            {member.jmeno}
                                                        </Link>
                                                        {member.aliasy && (
                                                            <AliasTooltip
                                                                id={`member-${member.id}-aliases`}
                                                                aliases={member.aliasy}
                                                            />
                                                        )}
                                                    </li>
                                                ) : (
                                                    <></>
                                                )
                                            )}
                                        </ul>
                                    </>
                                ) : (
                                    <></>
                                )}
                                {partyData.pocet_neaktivnich > 0 ? (
                                    <>
                                        <p className='text__secondary representatives-list-title'>
                                            {t('history')}
                                        </p>
                                        <ul className='representatives-list__grid'>
                                            {partyData.clenove.map((member) =>
                                                member.aktivni ? (
                                                    <></>
                                                ) : (
                                                    <li
                                                        key={member.id}
                                                        className='representatives-list-cell bullet__secondary'
                                                    >
                                                        <Link
                                                            className='text__secondary'
                                                            to={`../../zastupitele/${member.id}`}
                                                            key={member.id}
                                                        >
                                                            {member.jmeno}
                                                        </Link>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </Card>
                        {
                            hasLatestMeeting && (
                                <Card
                                    className='party-attendance-card'
                                    title={
                                        <span className='card-title-w-btn'>
                                            <span>
                                                {t('votings-summary')}
                                                <Tooltip target='.custom-target-icon3' />
                                                <i
                                                    className='custom-target-icon3 pi bi-info-circle text__secondary text-icon__right'
                                                    data-pr-tooltip={t('tooltip-chart')}
                                                    data-pr-position='bottom'
                                                ></i>
                                            </span>
                                            <Button
                                                className='table-view-icon'
                                                icon='bi bi-table'
                                                onClick={(e) => op.current.toggle(e)}
                                                alt='Zobrazit tabulku'
                                                aria-label='Detail'
                                            />
                                        </span>
                                    }
                                >
                                    <OverlayPanel ref={op}>
                                        <DataTable
                                            size={width < 576 ? 'small' : ''}
                                            responsiveLayout='true'
                                            value={summaryVotesData.datasets}
                                        >
                                            <Column field='label' header={t('option')} />
                                            <Column field='data' header={t('count')} />
                                        </DataTable>
                                    </OverlayPanel>
                                    <ChartComponent
                                        type='bar'
                                        data={summaryVotesData}
                                        options={stackedOptions}
                                        height='200px'
                                        width='100%'
                                    />
                                </Card>

                            )
                        }
                    </div>
                </div>
            );
        }
    }
}

export default PartyDetail;
