import React from 'react';

export const AdminFooter = () => {
    const year = new Date().getFullYear();

    return (
        <footer className='footer footer--admin'>
            <div className='footer-wrapper'>
                <p className='footer-text'>&copy; {year}</p>
            </div>
        </footer>
    );
};
