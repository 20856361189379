import { Messages } from 'primereact/messages';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingPage from '../../../components/LoadingPage';
import { getErrorMessage } from '../../../utils/errors';
import { ErrorMessage } from '../../components/ErrorMessage';
import { IconTooltip } from '../../components/IconTooltip';
import authAxios from '../../lib/authAxios';
import { formatDate } from '../../utils/format';
import { showErrorMessage, showSuccessMessage } from '../../utils/messages';
import { scrollTop } from '../../utils/scroll';
import { MeetingEditForm } from './components/MeetingEditForm';
import { MeetingEditMemberTable } from './components/MeetingEditMemberTable';
import { MeetingBreadCrumb } from './components/MeetingBreadCrumb';
import { LineDivider } from '../../components/LineDivider';
import { ChangeMembershipDialog } from './components/ChangeMembershipDialog';
import { ConfirmRemoveMemberDialog } from './components/ConfirmRemoveMemberDialog';
import { ButtonAdd } from '../../components/Buttons';
import { MeetingEditAdditionalMembersDialog } from './components/MeetingEditAdditionalMembersDialog';
import { Toast } from 'primereact/toast';

export default function MeetingEdit() {
    const { municipalityId, termId, meetingId } = useParams();
    const bodyType = 'zastupitelstvo';

    const msgs = useRef(null);
    const toast = useRef(null);

    const [loading, setLoading] = useState(true);
    const [initError, setInitError] = useState(false);
    const [meeting, setMeeting] = useState(null);
    const [politicalEntities, setPoliticalEntities] = useState([]);

    const [reloading, setReloading] = useState(false);

    const [selectedMemberRow, setSelectedMemberRow] = useState(null);

    const [isVisibleChangeMembershipDialog, setIsVisibleChangeMembershipDialog] = useState(false);
    const [processingChangeMembership, setProcessingChangeMembership] = useState(false);
    const refChangeMembershipDialogMsgs = useRef(null);

    const [isVisibleConfirmRemoveMemberDialog, setIsVisibleConfirmRemoveMemberDialog] = useState(false);
    const [processingRemoveMember, setProcessingRemoveMember] = useState(false);
    const refConfirmRemoveMemberDialogMsgs = useRef(null);

    const [isVisibleAddMembersDialog, setIsVisibleAddMembersDialog] = useState(false);
    const [processingAddMembers, setProcessingAddMembers] = useState(false);
    const refAddMembersDialogMsgs = useRef(null);

    const [processingChangeAttendance, setProcessingChangeAttendance] = useState(false);

    const disabled = useMemo(
        () =>
            processingChangeMembership || processingRemoveMember || processingAddMembers || processingChangeAttendance,
        [processingChangeMembership, processingRemoveMember, processingAddMembers, processingChangeAttendance]
    );

    const fetchMeeting = useCallback(() => {
        const params = new URLSearchParams({ include_available: true });
        return authAxios.get(`admin/${municipalityId}/${bodyType}/meeting/${meetingId}?${params.toString()}`);
    }, [municipalityId, meetingId]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [meetingResponse, politicalEntitiesResponse] = await Promise.all([
                    fetchMeeting(),
                    authAxios.get(`admin/${municipalityId}/${bodyType}/political-entity`)
                ]);
                setMeeting(meetingResponse.data);
                setPoliticalEntities(politicalEntitiesResponse.data);
            } catch (error) {
                const errorMessage = getErrorMessage(error);
                setInitError(errorMessage ?? 'Nepodařilo se načíst zvolené zasedání');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [municipalityId, fetchMeeting]);

    const reloadMeeting = async () => {
        setReloading(true);
        try {
            const response = await fetchMeeting();
            setMeeting(response.data);
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se načíst nová data zasedání', { replace: true });
            scrollTop();
        } finally {
            setReloading(false);
        }
    };

    const onSubmit = async (data) => {
        const requestData = {
            date: formatDate(data.date),
            number: data.number,
            recording_url: data.recording_url
        };
        try {
            await authAxios.put(`admin/${municipalityId}/${bodyType}/meeting/${meetingId}`, requestData);
            showSuccessMessage(msgs, 'Zasedání bylo úspěšně upraveno');
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se přidat nové zasedání', { replace: true });
            scrollTop();
        } finally {
            scrollTop();
        }
    };

    const showChangeMembershipDialog = (memberRow) => {
        setSelectedMemberRow(memberRow);
        setIsVisibleChangeMembershipDialog(true);
    };

    const hideChangeMembershipDialog = () => {
        setIsVisibleChangeMembershipDialog(false);
        setSelectedMemberRow(null);
    };

    const handleChangeMembership = async (data) => {
        if (!selectedMemberRow) {
            hideChangeMembershipDialog();
            return;
        }

        setProcessingChangeMembership(true);

        const requestData = {
            political_entity_id: data.political_entity
        };
        try {
            const params = new URLSearchParams({ type: 'political_entity' });
            await authAxios.patch(
                `admin/${municipalityId}/${bodyType}/meeting/${meetingId}/member/${
                    selectedMemberRow.id
                }?${params.toString()}`,
                requestData
            );
            showSuccessMessage(msgs, 'Členství bylo úspěšně změněno');
            reloadMeeting();
            hideChangeMembershipDialog();
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(refChangeMembershipDialogMsgs, errorMessage ?? 'Nepodařilo se změnit členství', {
                replace: true
            });
        } finally {
            setProcessingChangeMembership(false);
        }
    };

    const showConfirmRemoveMemberDialog = (memberRow) => {
        setSelectedMemberRow(memberRow);
        setIsVisibleConfirmRemoveMemberDialog(true);
    };

    const hideConfirmRemoveMemberDialog = () => {
        setIsVisibleConfirmRemoveMemberDialog(false);
        setSelectedMemberRow(null);
    };

    const handleRemoveMember = async () => {
        if (!selectedMemberRow) {
            hideConfirmRemoveMemberDialog();
            return;
        }

        setProcessingRemoveMember(true);

        try {
            await authAxios.delete(
                `admin/${municipalityId}/${bodyType}/meeting/${meetingId}/member/${selectedMemberRow.id}`
            );
            showSuccessMessage(
                msgs,
                selectedMemberRow.sex ? 'Členka byla úspěšně odebrána' : 'Člen byl úspěšně odebrán'
            );
            reloadMeeting();
            hideConfirmRemoveMemberDialog();
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(
                refConfirmRemoveMemberDialogMsgs,
                errorMessage ?? `Nepodařilo se odebrat ${selectedMemberRow.sex ? 'členku' : 'člena'}`,
                {
                    replace: true
                }
            );
        } finally {
            setProcessingRemoveMember(false);
        }
    };

    const showAddMembersDialog = () => {
        setIsVisibleAddMembersDialog(true);
    };

    const hideAddMembersDialog = () => {
        setIsVisibleAddMembersDialog(false);
    };

    const handleAddMembers = async (data) => {
        if (!data.members.length) {
            hideAddMembersDialog();
            return;
        }

        const requestData = {
            members: data.members.map((member) => ({
                id: member.member_id,
                political_entity: member.political_entity,
                attendance: member.attendance
            }))
        };

        setProcessingAddMembers(true);

        try {
            await authAxios.post(`admin/${municipalityId}/${bodyType}/meeting/${meetingId}/member`, requestData);
            showSuccessMessage(
                msgs,
                requestData.members.length > 1 ? 'Členové byli úspěšně přidání' : 'Člen byl úspěšně přidán'
            );
            reloadMeeting();
            hideAddMembersDialog();
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(
                refAddMembersDialogMsgs,
                errorMessage ?? `Nepodařilo se přidat ${requestData.members.length > 1 ? 'člena' : 'členy'}`,
                {
                    replace: true
                }
            );
        } finally {
            setProcessingAddMembers(false);
        }
    };

    const handleAttendanceChange = async (e, memberId) => {
        setProcessingChangeAttendance(true);

        const requestData = {
            attendance: e.value
        };
        try {
            const params = new URLSearchParams({ type: 'attendance' });
            await authAxios.patch(
                `admin/${municipalityId}/${bodyType}/meeting/${meetingId}/member/${memberId}?${params.toString()}`,
                requestData
            );
            toast?.current?.replace({
                severity: 'success',
                summary: 'Přítomnost byla úspěšně změněna'
            });
            const newMembers = meeting.members.map((member) => {
                if (member.id === memberId) {
                    return {
                        ...member,
                        attendance: e.value
                    };
                }
                return member;
            });
            setMeeting({
                ...meeting,
                members: newMembers
            });
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            toast?.current?.replace({
                severity: 'error',
                summary: errorMessage ?? 'Nepodařilo se změnit přítomnost'
            });
        } finally {
            setProcessingChangeAttendance(false);
        }
    };

    return loading ? (
        <LoadingPage />
    ) : initError ? (
        <>
            <MeetingBreadCrumb />
            <h1>Upravit zasedání</h1>
            <ErrorMessage text={initError} />
        </>
    ) : (
        <>
            <Toast position='top-center' ref={toast} className='toast-container' />

            <MeetingBreadCrumb
                extraItems={[
                    {
                        label: 'Upravit',
                        url: `/admin/municipality/${municipalityId}/funkcni-obdobi/${termId}/zasedani/${meetingId}`
                    }
                ]}
            />
            <h1>Upravit zasedání č. {meeting.number}</h1>

            <Messages ref={msgs} className='page__messages' />

            <MeetingEditForm onSubmit={onSubmit} meeting={meeting} disabled={disabled} />
            <LineDivider />
            <div className='flex-vertical-center'>
                <h2>
                    Členové zasedání
                    <IconTooltip>
                        <>
                            <p>Členové, kteří měli aktivní členství v době konání zasedání (včetně nepřítomných).</p>
                            <p>
                                Pro řazení podle více sloupců přidržte klávesu <i>Ctrl</i> při výběru dalšího sloupce.
                            </p>
                        </>
                    </IconTooltip>
                    <span title='Počet členů' className='ml-2'>
                        ({meeting.members.length})
                    </span>
                </h2>
                <ButtonAdd
                    label='Přidat členy'
                    className='ml-2'
                    icon='bi bi-person-plus'
                    onClick={showAddMembersDialog}
                    disabled={disabled}
                />
            </div>

            <MeetingEditMemberTable
                members={meeting.members}
                disabled={disabled}
                onClickChangePoliticalEntity={showChangeMembershipDialog}
                onClickRemoveMember={showConfirmRemoveMemberDialog}
                onAttendanceChange={handleAttendanceChange}
            />

            <ChangeMembershipDialog
                isVisible={isVisibleChangeMembershipDialog}
                politicalEntities={politicalEntities}
                selectedMember={selectedMemberRow}
                onHide={hideChangeMembershipDialog}
                onSubmit={handleChangeMembership}
                msgs={refChangeMembershipDialogMsgs}
            />

            <ConfirmRemoveMemberDialog
                isVisible={isVisibleConfirmRemoveMemberDialog}
                selectedMember={selectedMemberRow}
                onHide={hideConfirmRemoveMemberDialog}
                onClickDelete={handleRemoveMember}
                msgs={refConfirmRemoveMemberDialogMsgs}
            />

            <MeetingEditAdditionalMembersDialog
                isVisible={isVisibleAddMembersDialog}
                members={meeting.available_members}
                loading={reloading}
                politicalEntities={politicalEntities}
                onHide={hideAddMembersDialog}
                onSubmit={handleAddMembers}
                msgs={refAddMembersDialogMsgs}
            />
        </>
    );
}
