import React, { useEffect, useState } from 'react';
import createStore from 'react-auth-kit/createStore';
import AuthProvider from 'react-auth-kit/AuthProvider';
import createRefresh from 'react-auth-kit/createRefresh';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import axios from 'axios';
import { Outlet, useNavigate } from 'react-router-dom';
import { AUTH_NAME } from '../constants/auth';
import LoadingPage from '../../components/LoadingPage';

export const AuthProviderOutlet = () => {
    const refresh = createRefresh({
        interval: 15, // minutes
        refreshApiCallback: async (param) => {
            try {
                const response = await axios.post(
                    'refresh',
                    {},
                    {
                        headers: { Authorization: `Bearer ${param.refreshToken}` }
                    }
                );
                return {
                    isSuccess: true,
                    newAuthToken: response.data.token,
                    newAuthTokenType: 'Bearer',
                    newRefreshToken: response.data.refresh
                };
            } catch (error) {
                return {
                    isSuccess: false
                };
            }
        }
    });

    const authStore = createStore({
        authName: AUTH_NAME,
        authType: 'localstorage',
        refresh: refresh
    });

    return (
        <AuthProvider store={authStore}>
            <RefreshTokenOutlet />
        </AuthProvider>
    );
};

const RefreshTokenOutlet = () => {
    const [refreshing, setRefreshing] = useState(true);

    const navigate = useNavigate();

    const signIn = useSignIn();

    useEffect(() => {
        const refresh = async () => {
            const refreshToken = localStorage.getItem(`${AUTH_NAME}_refresh`);
            if (!refreshToken) {
                setRefreshing(false);
                return;
            }

            try {
                const response = await axios.post(
                    'refresh',
                    {},
                    {
                        headers: { Authorization: `Bearer ${refreshToken}` }
                    }
                );

                signIn({
                    auth: {
                        token: response.data.token,
                        type: 'Bearer'
                    },
                    refresh: response.data.refresh
                });
            } catch (error) {
                navigate('/admin/login', { replace: true });
            } finally {
                setRefreshing(false);
            }
        };

        refresh();
    }, [navigate]); // eslint-disable-line react-hooks/exhaustive-deps

    return refreshing ? <LoadingPage /> : <Outlet />;
};
