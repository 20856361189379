import APP from "../../config";
import { krajeList } from "../../municipality/kraje";
import { mestaList } from "../../municipality/mesta";
import { aboutSection } from "../default/sections/about";
import { datasetsSection } from "../default/sections/datasets";
import { partnersSection } from "../default/sections/partners";

const getUrl = (subdomain) => {
    const portSuffix = APP.port ? ":" + APP.port : "";
    return `${APP.protocol}://${subdomain }.${APP.domain}${portSuffix}`;
}

/**
 * Definition of Home page.
 * 
 * @author Jiri Hynek
 */
const home = (response) => {
    return {
        id: "home",
        header: {
            image: {
                large: "loga/title/zastupko/large.svg",
                small: "loga/title/zastupko/small.svg"
            }
        },
        sections: [
            {
                id: "intro",
                noAnchor: true,
                heading: {
                    label: "Vizualizace z hlasování českých zastupitelstev",
                },
                content: {
                    type: "widget-group",
                    config: {
                        widgets: [
                            {
                                type: "custom",
                                config: {
                                    style: {
                                        textAlign: "center"
                                    },
                                    content: (
                                        <a href={getUrl("mesta")}>
                                            <h3>Města <i className="pi pi-arrow-right"/></h3>
                                        </a>
                                    )
                                }
                            },
                            {
                                type: "municipality-card-grid",
                                config: {
                                    items: mestaList
                                }
                            },
                            {
                                type: "custom",
                                config: {
                                    style: {
                                        textAlign: "center"
                                    },
                                    content: (
                                        <a href={getUrl("kraje")}>
                                            <h3>Kraje <i className="pi pi-arrow-right"/></h3>
                                        </a>
                                    )
                                }
                            },
                            {
                                type: "municipality-card-grid",
                                config: {
                                    items: krajeList
                                }
                            }
                        ]
                    }
                }
            },
            datasetsSection,
            partnersSection,
            aboutSection
        ]
    }
}
export default home;