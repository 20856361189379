import React, { useState } from 'react';
import { Link, useParams, useLoaderData } from 'react-router-dom';
import { Toolbar, Dropdown, Card, Divider, Chip } from '../../components/PrimereactComponents';
import useDimensions from '../../hooks/useDimensions';
import { useTranslation } from 'react-i18next';
import { AliasTooltip } from '../../components/AliasTooltip';

function Members() {
    const [selectedParty, setSelectedParty] = useState(undefined);
    const [displayAll, setDisplayAll] = useState(true);
    const { width } = useDimensions();
    const [member, setMember] = useState(null);

    const { t } = useTranslation();

    const { bodyType, zastupitelstvoId } = useParams();

    const response = useLoaderData();

    const basicData = response[0].data;
    const partiesData = response[1].data;
    const partiesStats = response[2].data;
    const municipality = response[3].data;
    const options = response[4].data;
    const memberOptions = response[5].data;

    const onOptionChange = (e) => {
        if (e.value === undefined) {
            setDisplayAll(true);
            setMember(undefined);
            setSelectedParty(undefined);
        } else {
            setDisplayAll(false);
            setMember(undefined);
            setSelectedParty(e.value);
        }
    };

    const onMemberChange = (e) => {
        if (e.value === undefined) {
            setDisplayAll(true);
            setMember(undefined);
            setSelectedParty(undefined);
        } else {
            setDisplayAll(false);
            setSelectedParty(undefined);
            setMember(e.value);
        }
    };

    if (
        basicData !== undefined &&
        partiesData !== undefined &&
        partiesStats !== undefined &&
        zastupitelstvoId !== undefined &&
        municipality !== undefined &&
        options !== null
    ) {
        partiesData.forEach((party) => {
            let active_party = basicData.nejnovejsi_zasedani.mandaty.find((obj) => obj.strana_id === party.strana_id);
            if (active_party) {
                party['aktivni'] = true;
                party['pocet_mandatu'] = active_party.pocet_mandatu;
            } else {
                party['aktivni'] = false;
                party['pocet_mandatu'] = 0;
            }
        });

        const leftContents = (
            <>
                <span className='p-float-label'>
                    <Dropdown
                        showClear
                        inputId='memberSelection'
                        value={member}
                        options={memberOptions}
                        onChange={onMemberChange}
                        optionLabel='name'
                        filter
                        filterBy='name'
                    />
                    <label htmlFor='yearSelection'>{t('select-member', { ns: 'translation' })}</label>
                </span>
                {/* <Divider layout={width > 992 ? 'vertical' : 'horizontal'} /> */}
                <span className='p-float-label'>
                    <Dropdown
                        showClear
                        value={selectedParty}
                        options={options}
                        onChange={onOptionChange}
                        optionLabel='name'
                        inputId='partySelection'
                    />
                    <label htmlFor='yearSelection'>{t('select-party', { ns: 'translation' })}</label>
                </span>
            </>
        );

        const rightContents = (
            <>
                {/**<div class="p-dataview-layout-options p-selectbutton p-buttonset"><button type="button" class="p-button p-button-icon-only"><i class="pi pi-bars"></i><span role="presentation" class="p-ink"></span></button><button type="button" class="p-button p-button-icon-only p-highlight"><i class="pi pi-th-large"></i><span role="presentation" class="p-ink"></span></button></div>*/}
            </>
        );

        const organ = municipality.organy[bodyType];
        const len = basicData.pocet_zastupitelu + basicData.pocet_zastupitelek;
        const lenText =
            len > basicData.nejnovejsi_zasedani.clenu ? ', někteří byli aktivní pouze část tohoto období' : '';

        return (
            <div className='composition'>
                {/**
                <h1>{t('Composition', {ns: "translation", context: bodyType})}
                <i
                    className='custom-target-icon pi bi-info-circle parties__chart-icon'
                    data-pr-tooltip={t('in-term-composition', {ns: "translation", total_mandates: organ.pocet_zastupitelu, parties_count: partiesData.length, members_count: len, type: organ.nazev})}
                    data-pr-position='bottom'
                ></i>
                </h1>
                <Tooltip target='.custom-target-icon'/>
                */}
                <Toolbar left={leftContents} right={rightContents} />
                <div className='members-list'>
                    {partiesData.map((party, i) =>
                        displayAll ||
                        (selectedParty && selectedParty.code === party.strana_id) ||
                        (member && party.clenove.find((clen) => clen.id === member.code)) ? (
                            <div key={i} className='grid-item'>
                                <Card
                                    key={party.strana_id + 'subjekt'}
                                    title={
                                        <Link to={`../subjekty/${party.strana_id}`} className='card-title'>
                                            <span style={{ color: party.strana_barva }}>{party.strana_zkratka}</span>
                                            <Chip
                                                style={
                                                    party.pocet_mandatu > 0
                                                        ? { backgroundColor: party.strana_barva, color: 'white' }
                                                        : {}
                                                }
                                                className={
                                                    party.pocet_mandatu > 0 ? 'm-2' : 'text__white bg__secondary m-2'
                                                }
                                                label={
                                                    party.pocet_mandatu +
                                                    '\u00A0' +
                                                    t('mandate', { count: party.pocet_mandatu })
                                                }
                                                icon='pi pi-users '
                                            />
                                        </Link>
                                    }
                                    footer={
                                        <Link to={`../subjekty/${party.strana_id}`}>
                                            {t('show_party')}
                                            <i className='pi pi-angle-right text-icon__right'></i>
                                        </Link>
                                    }
                                >
                                    <ul className='representatives-list'>
                                        {party.pocet_aktivnich > 0 ? (
                                            <li key='zastupitele' className='representatives-list__item'>
                                                <p key='sekce' className='text__primary representatives-list-title'>
                                                    {t('active-members')}
                                                </p>
                                                <div key='hodnota' className='representatives-list__order'>
                                                    <ul className='representatives-list__grid'>
                                                        {party.clenove.map((clen) =>
                                                            clen.aktivni ? (
                                                                <li
                                                                    key={clen.id}
                                                                    className='representatives-list-cell bullet__primary'
                                                                >
                                                                    <Link
                                                                        className='text__black'
                                                                        to={`${clen.id}`}
                                                                        key={clen.id}
                                                                    >
                                                                        {clen.jmeno}
                                                                    </Link>
                                                                    {clen.aliasy && (
                                                                        <AliasTooltip
                                                                            id={`member-${clen.id}-aliases`}
                                                                            aliases={clen.aliasy}
                                                                        />
                                                                    )}
                                                                </li>
                                                            ) : (
                                                                ''
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                            </li>
                                        ) : (
                                            ''
                                        )}
                                        {party.pocet_aktivnich > 0 && party.pocet_neaktivnich > 0 ? <Divider /> : ''}
                                        {party.pocet_neaktivnich > 0 ? (
                                            <li className='representatives-list__item'>
                                                <p key='sekce' className='text__secondary representatives-list-title'>
                                                    {t('history')}
                                                </p>
                                                <div key='hodnota' className='representatives-list__order'>
                                                    <ul className='representatives-list__grid'>
                                                        {party.clenove.map((clen) =>
                                                            clen.aktivni ? (
                                                                ''
                                                            ) : (
                                                                <li
                                                                    key={clen.id}
                                                                    className='representatives-list-cell bullet__secondary'
                                                                >
                                                                    <Link
                                                                        className='text__secondary'
                                                                        to={`${clen.id}`}
                                                                        key={clen.id}
                                                                    >
                                                                        {clen.jmeno}
                                                                    </Link>
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                            </li>
                                        ) : (
                                            ''
                                        )}
                                    </ul>
                                </Card>
                            </div>
                        ) : (
                            ''
                        )
                    )}
                </div>
            </div>
        );
    }
}

export default Members;
