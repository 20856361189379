export const orderNull = (a, b) => {
    if (!a && !b) {
        return 0;
    } else if (a && !b) {
        return -1;
    } else if (!a && b) {
        return 1;
    }
    return undefined;
};

export const order = (a, b) => {
    if (a > b) {
        return 1;
    } else if (a < b) {
        return -1;
    }
    return 0;
};
