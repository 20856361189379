import React from "react";

import "./CustomWidget.css"

function CustomWidget({
    content = "",
    style = {},
}) {

    return (
        <>
            <div className="custom-widget" style={style}>
                {content}
            </div>
        </>
    )
}

export default CustomWidget;