import React from 'react';
import { useLoaderData } from 'react-router-dom';

import Header from '../components/widget/Header.js';
import Section from '../components/widget/Section.js';

import './Page.css';

/**
 * @author Jiri Hynek
 * 
 * @returns page which is composed of header and sections based on configuration
 */
function Page() {

    // get page configuration with data
    const config = useLoaderData();

    if (config) {
        return (
            <div className='page'>
                {
                    // page header
                    config.header && 
                    <Header
                        id={config.header.id}
                        sublabel={config.header.sublabel}
                        image={config.header.image}
                        style={config.header.style}/>
                }
                {
                    // page sections
                    config.sections && config.sections.map((section, key) => (
                        <Section
                            key={key}
                            id={section.id}
                            noAnchor={section.noAnchor}
                            heading={section.heading}
                            content={section.content}
                            style={section.style}/>
                    ))
                }
            </div>
        );
    }
}

export default Page;
