import { Messages } from 'primereact/messages';
import React from 'react';
import { ButtonClose, ButtonDelete } from '../../../components/Buttons';
import { PrimeIcons } from 'primereact/api';
import { ModalDialog } from '../../../components/ModalDialog';
import { formatMemberName } from '../../../../utils/texts';

export const ConfirmDeleteMemberDialog = ({ member, isVisible, onHide, onClickDelete, msgs }) => {
    return (
        <ModalDialog
            visible={isVisible}
            header='Potvrďte smazání'
            style={{ width: 400 }}
            footer={
                <>
                    <ButtonClose label='Ne' onClick={onHide} />
                    <ButtonDelete label='Smazat' onClick={onClickDelete} />
                </>
            }
            onHide={onHide}
        >
            <Messages ref={msgs} className='flex-center' />
            {member && (
                <>
                    <div className='dialog-text'>
                        <span className='dialog-text__warning'>
                            <i className={PrimeIcons.EXCLAMATION_TRIANGLE} />
                            <span>
                                Opravdu chcete smazat {member.sex ? 'členku' : 'člena'}{' '}
                                <b>{formatMemberName(member.first_name, member.last_name)}</b>?
                            </span>
                        </span>
                    </div>
                </>
            )}
        </ModalDialog>
    );
};
