/**
 * @param {import('react').MutableRefObject<import('primereact/messages').Messages>?} refMessages
 * @param {string} message
 * @param {{ replace: boolean }=} options
 */
export const showSuccessMessage = (refMessages, message, options) => {
    const data = {
        severity: 'success',
        detail: message
    };
    if (options?.replace) {
        refMessages?.current?.replace(data);
    } else {
        refMessages?.current?.show(data);
    }
};

/**
 * @param {import('react').MutableRefObject<import('primereact/messages').Messages>?} refMessages
 * @param {string} message
 * @param {{ replace: boolean }=} options
 */
export const showErrorMessage = (refMessages, message, options) => {
    const data = {
        severity: 'error',
        detail: message,
        sticky: true,
        closable: true
    };
    if (options?.replace) {
        refMessages?.current?.replace(data);
    } else {
        refMessages?.current?.show(data);
    }
};
