import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonEdit } from '../../../components/Buttons';
import { FormContainer, FormInput, FormRow, Label } from '../../../components/form';
import { getFormErrorMessage, maxLengthMessage } from '../../../utils/errors';
import { RHFInputText, RHFMultiSelect, RHFDropdown, RHFInputNumber, RHFYearCalendar } from '../../../components/rhf';
import { ZASTUPITELSTVO } from '../../../constants/field_lengths';
import { MAX_TERM_LENGTH } from '../../../constants/constants';

export const TermEditForm = ({ term, members, politicalEntities, onSubmit }) => {
    const [processing, setProcessing] = useState(false);

    const selectedMembers = useMemo(
        () => members.filter((member) => term.members.find((m) => m.id === member.id)),
        [members, term.members]
    );

    const defaultValues = {
        year_from: new Date(term.year_from, 0, 1),
        year_to: new Date(term.year_to, 0, 1),
        order: term.order,
        leader: term.leader,
        leader_title: term.leader_title,
        coalition: term.coalition
    };

    const {
        control,
        formState: { errors, isSubmitting },
        handleSubmit
    } = useForm({
        disabled: processing,
        defaultValues: defaultValues
    });

    useEffect(() => {
        setProcessing(isSubmitting);
    }, [isSubmitting]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormRow>
                    <FormInput>
                        <Label htmlFor='year_from' text='Začátek' required />
                        <RHFYearCalendar
                            control={control}
                            name='year_from'
                            rules={{ required: 'Zadejte začátek funkčního období' }}
                            placeholder='Rok od'
                        />
                        {getFormErrorMessage(errors, 'year_from')}
                    </FormInput>
                    <FormInput>
                        <Label htmlFor='year_to' text='Konec' required />
                        <RHFYearCalendar
                            control={control}
                            name='year_to'
                            rules={{
                                required: 'Zadejte konec funkčního období',
                                validate: {
                                    order: (value, formValues) => {
                                        const start = formValues.year_from;
                                        const end = value;
                                        if (!start || !end) return;
                                        if (start.getFullYear() > end.getFullYear()) {
                                            return 'Konec funkčního období musí být později než začátek';
                                        }
                                    },
                                    duration: (value, formValues) => {
                                        const start = formValues.year_from;
                                        const end = value;
                                        if (!start || !end) return;
                                        const termLength = end.getFullYear() - start.getFullYear();
                                        if (termLength > MAX_TERM_LENGTH) {
                                            return `Funkční období může trvat maximálně ${MAX_TERM_LENGTH} roky`;
                                        }
                                    }
                                }
                            }}
                            placeholder='Rok do'
                        />
                        {getFormErrorMessage(errors, 'year_to')}
                    </FormInput>
                    <FormInput>
                        <Label htmlFor='order' text='Pořadí' />
                        <RHFInputNumber control={control} name='order' disabled />
                    </FormInput>
                </FormRow>
                <FormRow>
                    <FormInput>
                        <Label htmlFor='coalition' text='Koalice' />
                        <RHFMultiSelect
                            control={control}
                            name='coalition'
                            placeholder='Koaliční subjekty'
                            inputProps={{
                                options: politicalEntities,
                                optionLabel: 'abbreviation',
                                optionValue: 'id'
                            }}
                        />
                    </FormInput>
                    <FormInput>
                        <Label htmlFor='leader' text='Lídr' />
                        <RHFDropdown
                            control={control}
                            name='leader'
                            placeholder='Zvolte lídra'
                            inputProps={{
                                options: selectedMembers,
                                optionValue: 'id',
                                emptyMessage: 'Nejsou vybráni žádní členové pro funkční období',
                                filter: true
                            }}
                        />
                    </FormInput>
                    <FormInput width='lg'>
                        <Label htmlFor='leader_title' text='Název funkce lídra' required />
                        <RHFInputText
                            control={control}
                            name='leader_title'
                            rules={{
                                required: 'Vyplňte toto pole',
                                maxLength: {
                                    value: ZASTUPITELSTVO.LEADER_FUNKCE,
                                    message: maxLengthMessage(ZASTUPITELSTVO.LEADER_FUNKCE)
                                }
                            }}
                            placeholder='Funkce lídra'
                        />
                        {getFormErrorMessage(errors, 'leader_title')}
                    </FormInput>
                </FormRow>
                <FormRow type='button'>
                    <ButtonEdit type='submit' label='Upravit' disabled={processing} loading={processing} />
                </FormRow>
            </FormContainer>
        </form>
    );
};
