import { ColorPicker } from 'primereact/colorpicker';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import React from 'react';
import { ButtonNo, ButtonYes } from '../../../components/Buttons';
import { FormContainer, FormInput, FormRow, Label } from '../../../components/form';
import { LineDivider } from '../../../components/LineDivider';
import { formatUserName } from '../../../utils/format';

export const RequestNewMunicipalityInfoForm = ({ request, onResolve, processing }) => {
    return (
        <FormContainer>
            <FormRow>
                <FormInput readonly>
                    <Label htmlFor='user_name' text='Uživatel' />
                    <InputText
                        id='user_name'
                        disabled
                        defaultValue={formatUserName(
                            request.user.first_name,
                            request.user.last_name,
                            request.user.login
                        )}
                    />
                </FormInput>
                <FormInput readonly>
                    <Label htmlFor='date' text='Datum' />
                    <InputText id='date' disabled defaultValue={request.date} />
                </FormInput>
            </FormRow>
            <FormRow>
                <FormInput width='lg' readonly>
                    <Label htmlFor='text' text='Text žádosti' />
                    <InputTextarea id='text' disabled defaultValue={request.text} autoResize />
                </FormInput>
            </FormRow>
            <LineDivider />
            <FormRow type='heading'>Municipalita</FormRow>
            <FormRow>
                <FormInput readonly>
                    <Label htmlFor='name' text='Název municipality' required />
                    <InputText id='name' disabled defaultValue={request.municipality.name} />
                </FormInput>
                <FormInput readonly>
                    <Label htmlFor='name_genitive' text='Název municipality (2. pád)' required />
                    <InputText id='name_genitive' disabled defaultValue={request.municipality.name_genitive} />
                </FormInput>
                <FormInput readonly>
                    <Label htmlFor='text_id' text='Textové ID' required />
                    <InputText id='text_id' disabled defaultValue={request.municipality.text_id} />
                </FormInput>
                <FormInput readonly>
                    <Label htmlFor='municipality_type' text='Typ municipality' required />
                    <InputText
                        id='municipality_type'
                        disabled
                        defaultValue={request.municipality.municipality_type_name}
                    />
                </FormInput>
            </FormRow>
            <FormRow>
                <FormInput width='lg' readonly>
                    <Label htmlFor='url' text='Webové stránky municipality' required />
                    <InputText id='url' disabled defaultValue={request.municipality.url} />
                </FormInput>
                <FormInput readonly>
                    <Label htmlFor='email' text='Kontaktní e-mail' />
                    <InputText id='email' disabled defaultValue={request.municipality.email} />
                </FormInput>
            </FormRow>
            <FormRow>
                <FormInput readonly>
                    <Label htmlFor='color' text='Barva' required />
                    <div className='color-display'>
                        <span>{request.municipality.color.toUpperCase()}</span>
                        <ColorPicker value={request.municipality.color} disabled />
                    </div>
                </FormInput>
            </FormRow>
            <LineDivider />
            <FormRow type='heading'>Zastupitelstvo</FormRow>
            <FormRow>
                <FormInput width='lg' readonly>
                    <Label htmlFor='zastupitelstvo_name' text='Název' required />
                    <InputText
                        id='zastupitelstvo_name'
                        disabled
                        defaultValue={request.municipality.zastupitelstvo_name}
                    />
                </FormInput>
                <FormInput width='lg' readonly>
                    <Label htmlFor='zastupitelstvo_name_genitive' text='Název (2. pád)' required />
                    <InputText
                        id='zastupitelstvo_name_genitive'
                        disabled
                        defaultValue={request.municipality.zastupitelstvo_name_genitive}
                    />
                </FormInput>
            </FormRow>
            <FormRow>
                <FormInput width='lg' readonly>
                    <Label htmlFor='zastupitelstvo_application_name' text='Název aplikace' required />
                    <InputText
                        id='zastupitelstvo_application_name'
                        disabled
                        defaultValue={request.municipality.zastupitelstvo_application_name}
                    />
                </FormInput>
                <FormInput readonly>
                    <Label htmlFor='zastupitelstvo_member_count' text='Počet zastupitelů' required />
                    <InputText
                        id='zastupitelstvo_member_count'
                        disabled
                        value={request.municipality.zastupitelstvo_member_count}
                    />
                </FormInput>
            </FormRow>
            <FormRow>
                <FormInput width='lg' readonly>
                    <Label htmlFor='zastupitelstvo_application_description' text='Popis aplikace' required />
                    <InputTextarea
                        id='zastupitelstvo_application_description'
                        autoResize
                        disabled
                        defaultValue={request.municipality.zastupitelstvo_application_description}
                    />
                </FormInput>
                <FormInput width='lg' readonly>
                    <Label htmlFor='zastupitelstvo_place' text='Místo konání zasedání' />
                    <InputText
                        id='zastupitelstvo_place'
                        disabled
                        defaultValue={request.municipality.zastupitelstvo_place}
                    />
                </FormInput>
            </FormRow>
            <LineDivider />
            <FormRow type='button'>
                <ButtonNo
                    type='button'
                    label='Zamítnout'
                    onClick={() => onResolve(false)}
                    disabled={processing}
                    loading={processing}
                />
                <ButtonYes
                    type='button'
                    label='Schválit'
                    onClick={() => onResolve(true)}
                    tooltip='Schválením dojde k vytvoření nové municipality a přiřazení práv správce uživateli'
                    disabled={processing}
                    loading={processing}
                />
            </FormRow>
        </FormContainer>
    );
};
