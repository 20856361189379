import { PrimeIcons } from 'primereact/api';
import React from 'react';
import { Column } from '../../../../components/PrimereactComponents';
import { PaginatedDataTable } from '../../../components/PaginatedDataTable';
import { formatUserName } from '../../../utils/format';
import { BaseChip } from '../../../components/Chips';

export const RequestMunicipalityManageTable = ({ requests, loading, onClickResolve }) => {
    const userTemplate = (rowData) => {
        return formatUserName(rowData.user.first_name, rowData.user.last_name, rowData.user.login);
    };

    const statusTemplate = (rowData) => {
        const approved = rowData.approved;

        if (approved === null) {
            return (
                <BaseChip
                    label='Nerozhodnuto'
                    severity='warning'
                    hoverable
                    icon={PrimeIcons.QUESTION_CIRCLE}
                    onClick={() => onClickResolve(rowData)}
                    title='Vyřídit žádost o přidání municipality'
                />
            );
        }

        if (approved) {
            return <BaseChip label='Schváleno' severity='success' outlined icon={PrimeIcons.CHECK} />;
        }

        return <BaseChip label='Zamítnuto' severity='danger' outlined icon={PrimeIcons.TIMES} />;
    };

    return (
        <PaginatedDataTable
            value={requests}
            loading={loading}
            removableSort
            breakpoint='900px'
            emptyMessage='Nebyly nalezeny žádné požadavky na správu municipality'
        >
            <Column field='id' header='ID' />
            <Column field='municipality.name' header='Název municipality' sortable />
            <Column field='municipality.text_id' header='ID municipality' sortable />
            <Column header='Uživatel' body={userTemplate} />
            <Column field='date' header='Datum' />
            <Column header='Stav' body={statusTemplate} align='center' />
        </PaginatedDataTable>
    );
};
