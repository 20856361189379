import { PrimeIcons } from 'primereact/api';
import { Menubar } from 'primereact/menubar';
import { NavLink } from 'react-router-dom';

export const LoginNavMenu = () => {
    return (
        <div className='mainmenu mainmenu--admin'>
            <Menubar
                start={
                    <NavLink to='/' className='p-menuitem-link menu-item'>
                        <span className={`${PrimeIcons.HOME} menu-item__icon`} />
                        <span>Úvod</span>
                    </NavLink>
                }
            />
        </div>
    );
};
