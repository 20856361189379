import React, { useState } from 'react';
import { Link, Navigate, useParams, useLoaderData } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ChartComponent from '../components/ChartComponent';
import {Card } from '../components/PrimereactComponents';
import SvgChart from '../components/SvgChart';

function Home() {
    const { bodyType } = useParams();
    const { t } = useTranslation();

    const response = useLoaderData();
    const basicData= response[0].data;
    const municipality = response[1].data;
    const partiesData  = response[2].data;
    const votesData = response[3].data;
    const hasLatestMeeting = !!basicData.nejnovejsi_zasedani?.id;

    //const { state } = useNavigation();
    
    if (basicData !== undefined && municipality !== undefined && partiesData !== undefined && votesData !== undefined) {
    
        const organ = municipality.organy[bodyType];

        partiesData.forEach((party) => {
            let active_party = basicData.nejnovejsi_zasedani.mandaty.find((obj) => obj.strana_id === party.strana_id);
            if (active_party) {
                party['aktivni'] = true;
                party['pocet_mandatu'] = active_party.pocet_mandatu;
            } else {
                party['aktivni'] = false;
                party['pocet_mandatu'] = 0;
            }
        });

        let coalitionParties = basicData['koalice'].map((party) => {
            return party.strana_zkratka;
        });

        coalitionParties = coalitionParties.join(', ');

        const style = getComputedStyle(document.body);
        const colors = [style.getPropertyValue('--clr-1'), style.getPropertyValue('--clr-2')];

        /** Votes chart */
        const votesChartData = {
                labels: [''],
                datasets: [
                    {
                label: t("approved-proposals"),
                data: [votesData.pocet_prijatych],
                backgroundColor: [
                    colors[0]
                ],
                barPercentage: 0.8
            },{
                label: t("rejected-proposals"),
                data: [votesData.pocet_neprijatych],
                backgroundColor: [
                    colors[1]
                ],
                barPercentage: 0.8
            }]
        }

        var hover_timeout;
        const votesChartOptions = {
            indexAxis: 'y',
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 1.4,
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true,
                }
            },
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend:{
                    labels: {
                        generateLabels: (chart) => {
                            const datasets = chart.data.datasets;
                            return datasets.map((data, i) => ({
                                text: `${data.label} (${data.data[0]})`,
                                fillStyle: data.backgroundColor,
                                strokeStyle: 'white',
                                datasetIndex: 0,
                                index: i,
                            }))
                        }
                    },
                    onHover: function (evt, item, legend) {
                        const tooltip = legend.chart.tooltip;
                        if (tooltip.getActiveElements().length > 0) {
                        tooltip.setActiveElements([], {x: 0, y: 0});
                        }
                        tooltip.setActiveElements([{datasetIndex: item.index, index: 0}], {x: 0, y: 0})

                        evt.native.target.style.cursor = 'pointer';
                        legend.chart.data.datasets.forEach((i, id) => {
                            if (id !== item.index) {
                                i.backgroundColor += '30';
                            }
                        });
                        clearTimeout(hover_timeout);
                        hover_timeout = setTimeout(function () {
                        legend.chart.update();
                        }, 60)
                    },
                    onLeave: function (evt, item, legend) {
                        const tooltip = legend.chart.tooltip;
                        tooltip.setActiveElements([], {x: 0, y: 0});
                        legend.chart.data.datasets.forEach((i, id) => {
                            if (id !== item.index) {
                                i.backgroundColor = i.backgroundColor.slice(0, -2);
                            }
                        });
                            legend.chart.update();
                    },
                },
            },
        };

        const latest_session = basicData.nejnovejsi_zasedani;

    return (
        <>
            <div className="overview">
                {/**<h1>
                    <span className="overview-title">
                    {zastupitelstvoId}. {t(organ.nazev)}{" "}
                    </span>
                    <span className="overview-title__year">({basicData["funkcni_obdobi"]})</span>
                    </h1>*/}
                <div className="overview-grid">
                    <Card
                        className="mandates-card"
                        title={
                            <h1><span className="overview-title">
                                {t(organ.nazev)}{" "}
                            </span><span className="overview-title__year">{basicData["funkcni_obdobi"]}</span>
                            {/**<span className="header">
                                <span>{t("Composition", {context: bodyType})}</span>
                                <div className="buttons-wrapper">
                                <Button className={"p-button-rounded " + (bool ? "" : "p-button-outlined") + " switch-button"} label="1" onClick={() => setBool(true)} aria-label="Mandáty" />
                                <Button className={"p-button-rounded " + (bool ? "p-button-outlined" : "") + " switch-button"} label="2" onClick={() => setBool(false)} aria-label="Zastupitelé" />
                                </div>
                                </span>*/}
                            </h1>
                        }
                        footer={
                            <Link to="subjekty">
                                {t("show_parties")}<i className="pi pi-angle-right text-icon__right"></i>
                            </Link>
                        }
                        >
                        {/**bool ? (
                            <MandatesChart basicData={basicData}/>
                            ) : (
                                <SvgChart basicData={basicData} partiesData={partiesData}/>
                                )*/}
                        <SvgChart basicData={basicData} partiesData={partiesData}/>
                    </Card>
                    <Card
                        className="recent-meetings-card"
                        title={t('latest-meeting')}
                        footer={
                            hasLatestMeeting && <Link to="zasedani">
                                {t("show_meetings")}<i className="pi pi-angle-right text-icon__right"></i>
                            </Link>
                        }
                        >
                        <ul className="list__primary">
                            { hasLatestMeeting ? (
                                <>
                                    <li>
                                        {t("meeting-no")}&nbsp;
                                        <Link to={`zasedani/${latest_session.id}`} className="text__primary">
                                            {latest_session.cislo}
                                        </Link>
                                        {" "}({latest_session.datum})
                                    </li>
                                    <li>
                                        <span className="text__primary">{latest_session?.celkem ? Math.round((latest_session.ucast / latest_session.celkem) * 100) : 0}%</span> {t("attendance-of-members")} ({t("i.e.")}&nbsp;{t("members-out-of", {members_count: latest_session.ucast || 0, members_total: latest_session.celkem || 0})})
                                    </li>
                                </>
                            ) : (<li>{t("no-last-meeting")}</li>)}
                        </ul>

                    </Card>
                    <Card
                        className="members-card"
                        title={t('Members')}
                        footer={
                            <Link to="zastupitele">
                                {t("show_members")}<i className="pi pi-angle-right text-icon__right"></i>
                            </Link>
                        }
                        >
                        <ul className="list__primary">
                            <li>
                                {basicData["leader_funkce"].charAt(0).toUpperCase() + basicData["leader_funkce"].slice(1)}&nbsp;-&nbsp;
                                <span className="text__primary">
                                    {basicData["leader"]}
                                </span>
                            </li>
                            <li>
                                <span className="nowrap"><span className="text__primary"><i className="pi bi-gender-male"></i> {basicData["nejnovejsi_zasedani"]["zastupitele"]}</span> {t("councilMember", { context: "0", count: basicData["nejnovejsi_zasedani"]["zastupitele"] })}</span>                            
                                , <span className="nowrap"><span className="text__primary"><i className="pi bi-gender-female"></i> {basicData["nejnovejsi_zasedani"]["zastupitelky"]}</span> {t("councilMember", { context: "1", count: basicData["nejnovejsi_zasedani"]["zastupitelky"] })}</span>
                            </li>
                        </ul>
                    </Card>
                    { hasLatestMeeting ? <>
                        <div className="votings-card">
                            <Card
                                title={t('Votings')}
                                footer={
                                    hasLatestMeeting ? <Link to="hlasovani">
                                        {t("show_votings")}<i className="pi pi-angle-right text-icon__right"></i>
                                    </Link> : <></>
                                }
                                >
                                <div className="grid">
                                    <div className="item-text">
                                        <div>
                                            <h3 className="text__primary">
                                                <i className="pi bi-stack text-icon__left"></i>
                                                {votesData.pocet_hlasovani}
                                            </h3>
                                            {t('points_discussed')}
                                        </div>
                                        <div>
                                            <h3 className="text__primary">
                                                <i className="pi pi-check-square text-icon__left"></i>
                                                {basicData["pocet_hlasovani_clenu"].toLocaleString("cz")}
                                            </h3>
                                            {t("all-members-votes")}
                                        </div>
                                    </div>
                                    <div className="item-chart">
                                    <ChartComponent type="bar" data={votesChartData} options={votesChartOptions} height="200px"/>

                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="analyses-card">
                            <Card
                                title={t("analyses")}
                                footer={
                                    <Link to="analyzy">
                                        {t("show_analyses")}
                                        <i className="pi pi-angle-right text-icon__right"></i>
                                    </Link>
                                }
                                >
                                <ul className='list__primary'>
                                    <li>
                                        <Link to="analyzy/dochazka">
                                            {t("attendance_analysis")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="analyzy/porovnani_zastupitelu">
                                            {t("councilMember_comparison")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="analyzy/nejednotnost">
                                            {t("parties-voting-unity")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="analyzy/koheze">
                                            {t("voting-unity")}
                                        </Link>
                                    </li>
                                </ul>
                            </Card>
                        </div>
                    </> : <></>}
                </div>
            </div>
        </>
    );
    } else {
        return <Navigate to='/' />
    }
}

export default Home;
