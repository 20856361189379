import { Message } from 'primereact/message';
import React, { useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { getErrorMessage } from '../../../../utils/errors';
import { ButtonAdd } from '../../../components/Buttons';
import { FormContainer, FormInput, FormRow, Label } from '../../../components/form';
import { IconTooltip } from '../../../components/IconTooltip';
import { LineDivider } from '../../../components/LineDivider';
import authAxios from '../../../lib/authAxios';
import { formatDate } from '../../../utils/format';
import { getFormErrorMessage, maxLengthMessage } from '../../../utils/errors';
import { MeetingAddMemberTable } from './MeetingAddMemberTable';
import { RHFDateCalendar, RHFInputText } from '../../../components/rhf';
import { ZASEDANI, ZASEDANI_ZAZNAM } from '../../../constants/field_lengths';
import { AdditionalMeetingMemberDialog } from './AdditionalMeetingMemberDialog';
import { DochazkaMoznost } from '../../../constants/enums';

export const MeetingAddForm = ({ politicalEntities, onSubmit, onMemberFetchError }) => {
    const { municipalityId, termId } = useParams();
    const bodyType = 'zastupitelstvo';

    const [processing, setProcessing] = useState(false);

    const [loadingMembers, setLoadingMembers] = useState(false);
    const [activeMembers, setActiveMembers] = useState([]);
    const [availableMembers, setAvailableMembers] = useState([]);
    const [nextMeeting, setNextMeeting] = useState(null);

    const [addedMemberIds, setAddedMemberIds] = useState(new Set());

    const [isVisibleAddMembersDialog, setIsVisibleAddMembersDialog] = useState(false);

    const filteredAvailableMembers = useMemo(
        () => availableMembers.filter((member) => !addedMemberIds.has(member.id)),
        [availableMembers, addedMemberIds]
    );

    const fetchMembers = (date) => {
        const params = new URLSearchParams({
            type: 'meeting',
            date: formatDate(date),
            term_id: termId
        });
        return authAxios.get(`admin/${municipalityId}/${bodyType}/member?${params.toString()}`);
    };

    const fetchNextMeeting = (date) => {
        const params = new URLSearchParams({
            type: 'next',
            date: formatDate(date)
        });
        return authAxios.get(`admin/${municipalityId}/${bodyType}/term/${termId}/meeting?${params.toString()}`);
    };

    const onMeetingDateChange = async (date) => {
        setLoadingMembers(true);
        try {
            const [memberResponse, nextMeetingResponse] = await Promise.all([
                fetchMembers(date),
                fetchNextMeeting(date)
            ]);
            const activeMembers = memberResponse.data.active.map((member) => ({
                member_id: member.id,
                political_entity: member.political_entity_id ?? null,
                attendance: DochazkaMoznost.PRITOMNOST,
                first_name: member.first_name,
                last_name: member.last_name
            }));
            setActiveMembers(activeMembers);
            replace(activeMembers);

            setAvailableMembers(memberResponse.data.available);

            if (nextMeetingResponse.data) {
                setNextMeeting(nextMeetingResponse.data);
            } else {
                setNextMeeting(null);
            }
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            onMemberFetchError(errorMessage ?? 'Nepodařilo se načíst členy');
        } finally {
            setLoadingMembers(false);
        }
    };

    const defaultValues = {
        date: null,
        number: '',
        recording_url: '',
        members: []
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        getValues
    } = useForm({
        disabled: processing,
        defaultValues: defaultValues
    });

    const { fields, append, remove, replace, update } = useFieldArray({ control, name: 'members' });

    const onSubmitWrapper = async (data) => {
        setProcessing(true);
        await onSubmit(data);
        setProcessing(false);
    };

    const meetingMemberCount = useMemo(
        () => fields.reduce((count, member) => (member.delete ? count : count + 1), 0),
        [fields]
    );

    const showAddMembersDialog = () => {
        setIsVisibleAddMembersDialog(true);
    };

    const hideAddMembersDialog = () => {
        setIsVisibleAddMembersDialog(false);
    };

    const handleAddMembers = (selectedMembers) => {
        const newAddedMembers = [];
        const newAddedMemberIds = new Set(addedMemberIds);
        selectedMembers.forEach((member) => {
            newAddedMembers.push({
                member_id: member.id,
                political_entity: null,
                attendance: DochazkaMoznost.PRITOMNOST,
                first_name: member.first_name,
                last_name: member.last_name,
                add: true
            });
            newAddedMemberIds.add(member.id);
        });
        setAddedMemberIds(newAddedMemberIds);
        newAddedMembers.forEach((member) => append(member));
        hideAddMembersDialog();
    };

    const handleRemoveMember = (item, index) => {
        const selectedActiveMember = activeMembers.find((member) => member.member_id === item.member_id);
        if (selectedActiveMember) {
            update(index, { ...getValues(`members[${index}]`), delete: true });
        } else {
            remove(index);
            const newAddedMemberIds = new Set(addedMemberIds);
            newAddedMemberIds.delete(item.member_id);
            setAddedMemberIds(newAddedMemberIds);
        }
    };

    const handleRevertRemoveMember = (item, index) => {
        update(index, { ...getValues(`members[${index}]`), delete: false });
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmitWrapper)}>
                <FormContainer>
                    {nextMeeting && (
                        <FormRow>
                            <Message
                                text={
                                    <>
                                        Vkládáte zasedání nechronologicky, následuje zasedání č.{' '}
                                        <strong>{nextMeeting.number}</strong>, konané dne {nextMeeting.date}!
                                    </>
                                }
                                severity='warn'
                            />
                        </FormRow>
                    )}
                    <FormRow>
                        <FormInput>
                            <Label
                                htmlFor='date'
                                text='Datum'
                                required
                                warning='Změna data zasedání vyvolá načtení aktivních zastupitelů v daný den a nahradí zvolené!'
                            />
                            <RHFDateCalendar
                                control={control}
                                name='date'
                                rules={{ required: 'Zadejte datum zasedání' }}
                                placeholder='dd.mm.rrrr'
                                inputProps={{
                                    showOnFocus: false
                                }}
                                afterChange={(date) => {
                                    if (date) {
                                        onMeetingDateChange(date);
                                    }
                                }}
                            />
                            {getFormErrorMessage(errors, 'date')}
                        </FormInput>
                        <FormInput>
                            <Label htmlFor='number' text='Číslo zasedání' required />
                            <RHFInputText
                                control={control}
                                name='number'
                                rules={{
                                    required: 'Zadejte číslo zasedání',
                                    maxLength: {
                                        value: ZASEDANI.CISLO,
                                        message: maxLengthMessage(ZASEDANI.CISLO)
                                    }
                                }}
                                placeholder='č.'
                            />
                            {getFormErrorMessage(errors, 'number')}
                        </FormInput>
                        <FormInput width='lg'>
                            <Label htmlFor='recording_url' text='Odkaz na záznam zasedání' />
                            <RHFInputText
                                control={control}
                                name='recording_url'
                                rules={{
                                    maxLength: {
                                        value: ZASEDANI_ZAZNAM.URL,
                                        message: maxLengthMessage(ZASEDANI_ZAZNAM.URL)
                                    }
                                }}
                                placeholder='URL nahrávky zasedání'
                            />
                        </FormInput>
                    </FormRow>
                    <LineDivider />
                    <FormRow type='heading'>
                        Členové zasedání
                        <IconTooltip>
                            <>
                                <p>
                                    Členové, kteří měli aktivní členství v době konání zasedání (včetně nepřítomných).
                                </p>
                                <p>
                                    Přidáním/odebráním člena nebo změnou politického subjektu dojde k odpovídající
                                    úpravě příslušností daného člena při vytvoření zasedání.
                                </p>
                            </>
                        </IconTooltip>
                    </FormRow>
                    <FormRow>
                        <span className='form-input__info-text'>
                            Celkem zvoleno členů: <strong>{meetingMemberCount}</strong>
                        </span>
                        <ButtonAdd
                            type='button'
                            label='Přidat členy'
                            icon='bi bi-person-plus'
                            onClick={showAddMembersDialog}
                            disabled={processing}
                            loading={loadingMembers}
                        />
                    </FormRow>
                    <FormRow>
                        <MeetingAddMemberTable
                            control={control}
                            errors={errors}
                            fields={fields}
                            getValues={getValues}
                            politicalEntities={politicalEntities}
                            loading={loadingMembers}
                            disabled={processing}
                            onRemoveMember={handleRemoveMember}
                            onRevertRemoveMember={handleRevertRemoveMember}
                        />
                    </FormRow>
                    <LineDivider />
                    <FormRow type='button'>
                        <ButtonAdd type='submit' label='Přidat zasedání' disabled={processing} loading={processing} />
                    </FormRow>
                </FormContainer>
            </form>

            <AdditionalMeetingMemberDialog
                members={filteredAvailableMembers}
                loading={loadingMembers}
                isVisible={isVisibleAddMembersDialog}
                onHide={hideAddMembersDialog}
                onConfirm={handleAddMembers}
            />
        </>
    );
};
