import { mestaList } from "../../municipality/mesta";
import { aboutSection } from "../default/sections/about";
import { datasetsSection } from "../default/sections/datasets";
import { partnersSection } from "../default/sections/partners";

/**
 * Definition of Home page.
 * 
 * @author Jiri Hynek
 */
const home = (repsonse) => {
    return {
        id: "home",
        header: {
            image: {
                large: "loga/title/mesta/large.svg",
                small: "loga/title/mesta/small.svg",
            }
        },
        sections: [
            {
                id: "intro",
                noAnchor: true,
                heading: {
                    label: "Vizualizace z hlasování městských zastupitelstev",
                },
                content: {
                    type: "widget-group",
                    config: {
                        widgets: [
                            {
                                type: "municipality-card-grid",
                                config: {
                                    items: mestaList
                                }
                            }
                        ]
                    }
                }
            },
            datasetsSection,
            partnersSection,
            aboutSection
        ]
    }
}
export default home;