import { parseTime } from '@internationalized/date';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useForm, useFormState, useWatch } from 'react-hook-form';
import { ButtonAdd, ButtonEdit } from '../../../components/Buttons';
import { FormContainer, FormInput, FormRow, Label } from '../../../components/form';
import { LineDivider } from '../../../components/LineDivider';
import { HlasovaciMoznost, VysledekHlasovaniMoznost } from '../../../constants/enums';
import { getFormErrorMessage, maxLengthMessage } from '../../../utils/errors';
import { EntityVoteCards } from './EntityVoteCards';
import { MemberVoteTable } from './MemberVoteTable';
import {
    RHFCheckbox,
    RHFDropdown,
    RHFInputNumber,
    RHFInputText,
    RHFInputTextarea,
    RHFTimeInput
} from '../../../components/rhf';
import { HLASOVANI } from '../../../constants/field_lengths';
import { Checkbox } from 'primereact/checkbox';
import { IconTooltip } from '../../../components/IconTooltip';

export const VoteForm = forwardRef(({ members, resultOptions, voteOptions, onSubmit, vote }, ref) => {
    const [processing, setProcessing] = useState(false);
    const [keep, setKeep] = useState(false);

    useImperativeHandle(ref, () => ({
        reset: resetForm
    }));

    const edit = !!vote;

    const defaultMembers = useMemo(
        () => (vote ? vote.member_votes : getDefaultMemberVoteValues(members)),
        [members, vote]
    );

    const defaultValues = useMemo(
        () => ({
            number: '',
            time: null,
            result: null,
            item: '',
            valid: true,
            procedural: false,
            secret: false,
            minutes_url: '',
            sum_yes: 0,
            sum_no: 0,
            sum_abstained: 0,
            sum_did_not_vote: 0,
            sum_absent: 0,
            sum_excused: 0,
            members: defaultMembers
        }),
        [defaultMembers]
    );

    if (edit) {
        defaultValues.number = vote.number;
        defaultValues.time = vote.time ? parseTime(vote.time) : null;
        defaultValues.result = vote.result;
        defaultValues.item = vote.item ?? '';
        defaultValues.valid = vote.valid;
        defaultValues.procedural = vote.procedural;
        defaultValues.secret = vote.secret;
        defaultValues.minutes_url = vote.minutes_url ?? '';
        defaultValues.sum_yes = vote.sum_yes;
        defaultValues.sum_no = vote.sum_no;
        defaultValues.sum_abstained = vote.sum_abstained;
        defaultValues.sum_did_not_vote = vote.sum_did_not_vote;
        defaultValues.sum_absent = vote.sum_absent;
        defaultValues.sum_excused = vote.sum_excused;
    }

    const {
        control,
        formState: { errors, isSubmitting },
        handleSubmit,
        reset,
        setValue,
        getValues
    } = useForm({
        disabled: processing,
        defaultValues: defaultValues
    });

    const secret = useWatch({ control, name: 'secret' });

    useEffect(() => {
        setProcessing(isSubmitting);
    }, [isSubmitting]);

    const resetForm = useCallback(() => {
        if (!keep) {
            reset(defaultValues);
            return;
        }

        const newDefaultMembers = getDefaultMemberVoteValues(members);

        const memberValues = getValues('members');
        Object.entries(memberValues).forEach(([memberId, values]) => {
            if (!values.in_person) {
                newDefaultMembers[memberId].in_person = false;
            }

            if (values.option === HlasovaciMoznost.NEPRITOMEN || values.option === HlasovaciMoznost.OMLUVEN) {
                newDefaultMembers[memberId].option = values.option;
            }
        });

        const newDefaultValues = { ...defaultValues, members: newDefaultMembers };

        reset(newDefaultValues);
    }, [reset, getValues, members, defaultValues, keep]);

    const onVoteOptionChanged = useCallback(() => {
        if (getValues('secret')) {
            return;
        }

        const memberValues = getValues('members');

        const computeSum = (option) => {
            return Object.keys(memberValues).filter((key) => memberValues[key].option === option).length;
        };

        const sumYes = computeSum(HlasovaciMoznost.ANO);
        const sumNo = computeSum(HlasovaciMoznost.NE);
        const sumAbstained = computeSum(HlasovaciMoznost.ZDRZEL_SE);
        const sumDidNotVote = computeSum(HlasovaciMoznost.NEHLASOVAL);
        const sumAbsent = computeSum(HlasovaciMoznost.NEPRITOMEN);
        const sumExcused = computeSum(HlasovaciMoznost.OMLUVEN);

        setValue('sum_yes', sumYes);
        setValue('sum_no', sumNo);
        setValue('sum_abstained', sumAbstained);
        setValue('sum_did_not_vote', sumDidNotVote);
        setValue('sum_absent', sumAbsent);
        setValue('sum_excused', sumExcused);
    }, [getValues, setValue]);

    const setVoteOption = useCallback(
        (option) => {
            members.forEach((member) => {
                const currentOption = getValues(`members[${member.id}].option`);
                if (currentOption !== HlasovaciMoznost.NEPRITOMEN && currentOption !== HlasovaciMoznost.OMLUVEN) {
                    setValue(`members[${member.id}].option`, option);
                }
            });
            onVoteOptionChanged();
        },
        [members, getValues, setValue, onVoteOptionChanged]
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormRow>
                    <FormInput>
                        <Label htmlFor='number' text='Číslo hlasování' required />
                        <RHFInputText
                            control={control}
                            name='number'
                            rules={{
                                required: 'Vyplňte toto pole',
                                maxLength: {
                                    value: HLASOVANI.CISLO,
                                    message: maxLengthMessage(HLASOVANI.CISLO)
                                }
                            }}
                        />
                        {getFormErrorMessage(errors, 'number')}
                    </FormInput>
                    <FormInput>
                        <Label htmlFor='time' text='Čas' />
                        <RHFTimeInput control={control} name='time' inputProps={{ 'aria-label': 'Čas' }} />
                        {getFormErrorMessage(errors, 'time')}
                    </FormInput>
                    <FormInput>
                        <Label
                            htmlFor='result'
                            text='Výsledek'
                            required
                            info='Výběrem se automaticky přednastaví hlasovací možnost ano/ne pro všechny členy.'
                        />
                        <VoteResultDropdown
                            control={control}
                            getValues={getValues}
                            setValue={setValue}
                            resultOptions={resultOptions}
                            members={members}
                            onVoteOptionChanged={onVoteOptionChanged}
                        />
                        {getFormErrorMessage(errors, 'result')}
                    </FormInput>
                    <FormInput type='checkbox'>
                        <Label htmlFor='valid' text='Platné' />
                        <RHFCheckbox control={control} name='valid' />
                    </FormInput>
                    <FormInput type='checkbox'>
                        <Label htmlFor='procedural' text='Procedurální' />
                        <RHFCheckbox control={control} name='procedural' />
                    </FormInput>
                    <FormInput type='checkbox'>
                        <Label
                            htmlFor='secret'
                            text='Tajné'
                            info={
                                <>
                                    <p>
                                        Zaškrtnutím se automaticky nastaví hlasovací možnost <i>tajná</i> pro všechny
                                        členy.
                                    </p>
                                    <p>
                                        Také je umožněno editování sum hlasovacích možností, které je třeba vyplnit
                                        manuálně, pokud jsou známé a nenulové.
                                    </p>
                                </>
                            }
                        />
                        <SecretVoteCheckbox
                            control={control}
                            getValues={getValues}
                            setValue={setValue}
                            members={members}
                            onVoteOptionChanged={onVoteOptionChanged}
                        />
                    </FormInput>
                </FormRow>
                <FormRow>
                    <FormInput width='lg'>
                        <Label htmlFor='item' text='Předmět hlasování' />
                        <RHFInputTextarea
                            control={control}
                            name='item'
                            rules={{
                                maxLength: { value: HLASOVANI.PREDMET, message: maxLengthMessage(HLASOVANI.PREDMET) }
                            }}
                            placeholder='Předmět hlasování'
                        />
                        {getFormErrorMessage(errors, 'item')}
                    </FormInput>
                    <FormInput width='lg'>
                        <Label htmlFor='minutes_url' text='Odkaz na protokol hlasování' />
                        <RHFInputText
                            control={control}
                            name='minutes_url'
                            rules={{
                                maxLength: {
                                    value: HLASOVANI.URL_PROTOKOL,
                                    message: maxLengthMessage(HLASOVANI.URL_PROTOKOL)
                                }
                            }}
                            placeholder='URL protokolu hlasování'
                        />
                        {getFormErrorMessage(errors, 'minutes_url')}
                    </FormInput>
                </FormRow>
                <FormRow type='subheading'>Sumy hlasovacích možností</FormRow>
                <FormRow>
                    <FormInput width='sm'>
                        <Label htmlFor='sum_yes' text='Ano' />
                        <RHFInputNumber
                            control={control}
                            name='sum_yes'
                            disabled={!secret}
                            inputProps={{ min: 0, max: members.length, showButtons: false }}
                        />
                    </FormInput>
                    <FormInput width='sm'>
                        <Label htmlFor='sum_no' text='Ne' />
                        <RHFInputNumber
                            control={control}
                            name='sum_no'
                            disabled={!secret}
                            inputProps={{ min: 0, max: members.length, showButtons: false }}
                        />
                    </FormInput>
                    <FormInput width='sm'>
                        <Label htmlFor='sum_abstained' text='Zdržel se' />
                        <RHFInputNumber
                            control={control}
                            name='sum_abstained'
                            disabled={!secret}
                            inputProps={{ min: 0, max: members.length, showButtons: false }}
                        />
                    </FormInput>
                    <FormInput width='sm'>
                        <Label htmlFor='sum_did_not_vote' text='Nehlasoval' />
                        <RHFInputNumber
                            control={control}
                            name='sum_did_not_vote'
                            disabled={!secret}
                            inputProps={{ min: 0, max: members.length, showButtons: false }}
                        />
                    </FormInput>
                    <FormInput width='sm'>
                        <Label htmlFor='sum_absent' text='Nepřítomen' />
                        <RHFInputNumber
                            control={control}
                            name='sum_absent'
                            disabled={!secret}
                            inputProps={{ min: 0, max: members.length, showButtons: false }}
                        />
                    </FormInput>
                    <FormInput width='sm'>
                        <Label htmlFor='sum_excused' text='Omluven' />
                        <RHFInputNumber
                            control={control}
                            name='sum_excused'
                            disabled={!secret}
                            inputProps={{ min: 0, max: members.length, showButtons: false }}
                        />
                    </FormInput>
                </FormRow>
                <LineDivider />
                <FormRow>
                    <TabView className='tabview--admin'>
                        <TabPanel header='Tabulka' leftIcon={'bi bi-table'}>
                            <MemberVoteTable
                                members={members}
                                voteOptions={voteOptions}
                                control={control}
                                onSetVoteOption={setVoteOption}
                                onVoteOptionChanged={onVoteOptionChanged}
                            />
                        </TabPanel>
                        <TabPanel header='Politické subjekty' leftIcon='bi bi-person-workspace'>
                            <EntityVoteCards
                                members={members}
                                voteOptions={voteOptions}
                                control={control}
                                setValue={setValue}
                                onVoteOptionChanged={onVoteOptionChanged}
                                disabled={processing}
                            />
                        </TabPanel>
                    </TabView>
                </FormRow>
                <LineDivider />
                <FormRow type='button'>
                    {edit ? (
                        <ButtonEdit
                            type='submit'
                            label='Upravit hlasování'
                            disabled={processing}
                            loading={processing}
                        />
                    ) : (
                        <>
                            <ButtonAdd
                                type='submit'
                                label='Přidat hlasování'
                                disabled={processing}
                                loading={processing}
                            />
                            {!edit && (
                                <div className='labeled-checkbox'>
                                    <Checkbox name='keep' checked={keep} onChange={(e) => setKeep(e.checked)} />
                                    <label htmlFor='keep'>
                                        Ponechat nepřítomnosti a prezenci
                                        <IconTooltip>
                                            Mají po uložení hlasování zůstat vyplněné hlasovací možnosti nepřítomnost a
                                            omluven a zvolená prezence?
                                        </IconTooltip>
                                    </label>
                                </div>
                            )}
                        </>
                    )}
                </FormRow>
            </FormContainer>
        </form>
    );
});

const getDefaultMemberVoteValues = (members) => {
    return members.reduce(
        (acc, member) => ({
            ...acc,
            [member.id]: {
                option: null,
                in_person: true
            }
        }),
        {}
    );
};

const VoteResultDropdown = ({ control, getValues, setValue, resultOptions, members, onVoteOptionChanged }) => {
    const { touchedFields } = useFormState({ control });

    const onChange = (e, field) => {
        if (!getValues('secret') && !touchedFields['members']) {
            const option = e.value === VysledekHlasovaniMoznost.PRIJATO ? HlasovaciMoznost.ANO : HlasovaciMoznost.NE;
            members.forEach((member) => {
                const currentOption = getValues(`members[${member.id}].option`);
                if (currentOption !== HlasovaciMoznost.NEPRITOMEN && currentOption !== HlasovaciMoznost.OMLUVEN) {
                    setValue(`members[${member.id}].option`, option);
                }
            });
            onVoteOptionChanged();
        }
        field.onChange(e);
    };

    return (
        <RHFDropdown
            control={control}
            name='result'
            rules={{ required: 'Zvolte výsledek hlasování' }}
            placeholder='Zvolte výsledek'
            inputProps={{
                options: resultOptions,
                optionLabel: 'name',
                optionValue: 'code',
                emptyMessage: 'Nejsou k dispozici žádné možnosti pro výsledek hlasování',
                showClear: false
            }}
            onChange={onChange}
        />
    );
};

const SecretVoteCheckbox = ({ control, getValues, setValue, members, onVoteOptionChanged }) => {
    const { touchedFields } = useFormState({ control });

    const onChange = (e, field) => {
        if (e.checked && !touchedFields['members']) {
            members.forEach((member) => {
                const currentOption = getValues(`members[${member.id}].option`);
                if (currentOption !== HlasovaciMoznost.NEPRITOMEN && currentOption !== HlasovaciMoznost.OMLUVEN) {
                    setValue(`members[${member.id}].option`, HlasovaciMoznost.TAJNA);
                }
            });
            onVoteOptionChanged(); // clear sums if setting options to secret
        }
        field.onChange(e.checked);
        if (!e.checked) {
            onVoteOptionChanged();
        }
    };

    return <RHFCheckbox control={control} name='secret' onChange={onChange} />;
};
