import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { Column, Dropdown, TabPanel, TabView, Divider } from '../../components/PrimereactComponents';
import LoadingPage from '../../components/LoadingPage';
import { redirectErrorPage } from '../../utils/errors';
import ChartComponent from '../../components/ChartComponent';
import { useTranslation } from 'react-i18next';
import useDimensions from '../../hooks/useDimensions';
import PaginatedDataTable from '../../components/PaginatedDataTable';
import { AliasTooltip } from '../../components/AliasTooltip';

function ComparisonDetail(props) {
    const [firstMemberData, setFirstMemberData] = useState(undefined);
    const [secondMemberData, setSecondMemberData] = useState(undefined);
    const [dateFrom, setDateFrom] = useState(undefined);
    const [dateTo, setDateTo] = useState(undefined);
    const [compareData, setCompareData] = useState(undefined);
    const [makingRequest, setMakingRequest] = useState(false);
    const [selectedSession, setSelectedSession] = useState(null);
    const [selectedRow1, setSelectedRow1] = useState(null);
    const [selectedRow2, setSelectedRow2] = useState(null);
    const [loading, setLoading] = useState(true);

    const { zastupitelstvoId, bodyType } = useParams();
    const municipalitaId = props.municipalitaId;

    const prevFirstMemberRef = useRef();
    const prevSecondMemberRef = useRef();

    const { width } = useDimensions();

    const { t } = useTranslation(['analyses', 'translation']);

    useEffect(() => {
        prevFirstMemberRef.current = props.firstMember;
        prevSecondMemberRef.current = props.secondMember;
    }, [props.firstMember, props.secondMember]);

    const prevFirstMember = prevFirstMemberRef.current;
    const prevSecondMember = prevSecondMemberRef.current;

    const onTableOptionChange1 = (e) => {
        setSelectedRow1(e.value);
        const baseUrl = process.env.REACT_APP_BASE_URL;
        let url = baseUrl + `${municipalitaId}/${bodyType}/${zastupitelstvoId}/hlasovani/${e.value.id_hlasovani}`;
        window.open(url, '_blank');
    };

    const onTableOptionChange2 = (e) => {
        setSelectedRow2(e.value);
        const baseUrl = process.env.REACT_APP_BASE_URL;
        let url = baseUrl + `${municipalitaId}/${bodyType}/${zastupitelstvoId}/hlasovani/${e.value.id_hlasovani}`;
        window.open(url, '_blank');
    };

    const onOptionChange = (e) => {
        setSelectedSession(e.value);
    };

    const apiCall = async () => {
        if (props.firstMember !== null && props.secondMember !== null && props.firstMember !== props.secondMember) {
            setCompareData(undefined);
            setFirstMemberData(undefined);
            setSecondMemberData(undefined);
            setDateFrom(undefined);
            setDateTo(undefined);
            setMakingRequest(true);

            try {
                const [firstMemberResponse, secondMemberResponse] = await Promise.all([
                    axios.get(`${municipalitaId}/${bodyType}/${zastupitelstvoId}/member/${props.firstMember.code}`),
                    axios.get(`${municipalitaId}/${bodyType}/${zastupitelstvoId}/member/${props.secondMember.code}`)
                ]);

                setFirstMemberData(firstMemberResponse.data);
                setSecondMemberData(secondMemberResponse.data);
            } catch (error) {
                redirectErrorPage(error);
            } finally {
                setLoading(false);
            }
        }
    };

    const fixDate = (date) => {
        if (!date) return null;
        date = date.split('.');
        return date[2] + '-' + date[1] + '-' + date[0];
    };

    const sndApiCall = () => {
        if (dateFrom !== undefined && dateTo !== undefined) {
            let from = fixDate(dateFrom);
            let to = fixDate(dateTo);

            try {
                axios
                    .get(
                        `${municipalitaId}/${bodyType}/${zastupitelstvoId}/compare?prvni_clen=` +
                            props.firstMember.code +
                            '&druhy_clen=' +
                            props.secondMember.code +
                            '&datum_od=' +
                            from +
                            '&datum_do=' +
                            to
                    )
                    .then((response) => {
                        setCompareData(response.data);
                        setMakingRequest(false);
                    });
            } catch (error) {
                redirectErrorPage(error);
            } finally {
                setLoading(false);
            }
        }
    };

    let chartData = '';
    let labels = '';
    let chartOptions = '';
    if (compareData && compareData.data) {
        const ratios = Object.entries(compareData.data).map(([key, value]) => {
            const neshodaLength = value.neshoda?.length || 0;
            const shodaLength = value.shoda?.length || 0;
            const totalLength = neshodaLength + shodaLength;
            const ratio = totalLength > 0 ? ((shodaLength / totalLength) * 100).toFixed(1) : 0;
            return { key, ratio: ratio };
        });

        const style = getComputedStyle(document.body);
        const colors = [style.getPropertyValue('--clr-1'), style.getPropertyValue('--clr-7')];

        let avg =
            compareData.shoda + compareData.neshoda > 0
                ? ((compareData.shoda / (compareData.shoda + compareData.neshoda)) * 100).toFixed(1)
                : 0;

        labels = ratios.map((ratio) => ratio.key);
        chartData = {
            labels,
            datasets: [
                {
                    label: t('avg-agreement') + ' (%)',
                    type: 'line',
                    data: Array(ratios.length).fill(avg),
                    backgroundColor: colors[1],
                    borderColor: colors[1],
                    pointStyle: 'dash',
                    borderDash: [10, 5]
                },
                {
                    label: t('meetings-agreement') + ' (%)',
                    data: ratios.map((ratio) => ratio.ratio),
                    backgroundColor: colors[0]
                }
            ]
        };

        chartOptions = {
            type: 'bar',
            indexAxis: 'x',
            maintainAspectRatio: false,
            aspectRatio: 0.7,
            responsive: true,
            scales: {
                y: {
                    ticks: {
                        beginAtZero: true,
                        callback: (value, index, values) => {
                            if (chartOptions.indexAxis === 'x') {
                                return value + ' %';
                            }
                            return compareData.zasedani[index].name;
                        }
                    },
                    max: 100
                },
                x: {
                    ticks: {
                        beginAtZero: true,
                        callback: (value, index, values) => {
                            if (chartOptions.indexAxis === 'y') {
                                return value + ' %';
                            }
                            return compareData.zasedani[index].name;
                        }
                    },
                    max: chartOptions.indexAxis === 'y' ? 100 : undefined
                }
            }
        };

        switch (true) {
            case ratios.length > 0 && ratios.length <= 30:
                chartOptions.indexAxis = 'x';
                chartOptions.aspectRatio = 0.7;
                break;
            case ratios.length > 30 && width <= 576:
                chartOptions.indexAxis = 'y';
                chartOptions.aspectRatio = 0.5;
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        (async () => {
            apiCall();
        })();
    }, []);

    useEffect(() => {
        if (firstMemberData !== undefined && secondMemberData !== undefined) {
            if (dateFrom === undefined && dateTo === undefined) {
                const firstMemberFrom = firstMemberData.politicke_strany.at(-1).datum_od;
                const secondMemberFrom = secondMemberData.politicke_strany.at(-1).datum_od;

                const firstMemberTo = firstMemberData.politicke_strany[0].datum_do;
                const secondMemberTo = secondMemberData.politicke_strany[0].datum_do;
                setDateFrom(
                    !firstMemberFrom || firstMemberFrom < secondMemberFrom ? secondMemberFrom : firstMemberFrom
                );
                setDateTo(
                    !secondMemberTo || firstMemberTo > secondMemberTo ? firstMemberTo : secondMemberTo
                );

            } else {
                if (compareData === undefined) {
                    sndApiCall();
                }
            }
        }

        if (props.firstMember !== prevFirstMember) {
            if (props.firstMember === null) {
                setCompareData(undefined);
                setFirstMemberData(undefined);
                setSecondMemberData(undefined);
                setDateFrom(undefined);
                setDateTo(undefined);
            } else {
                apiCall();
            }
        }

        if (props.secondMember !== prevSecondMember) {
            if (props.secondMember === null) {
                setCompareData(undefined);
                setFirstMemberData(undefined);
                setSecondMemberData(undefined);
                setDateFrom(undefined);
                setDateTo(undefined);
            } else {
                apiCall();
            }
        }
    }, [props.firstMember, props.secondMember, firstMemberData, secondMemberData, dateFrom, dateTo, compareData]);

    const resultBodyTemplate = (rowData) => {
        if (rowData.vysledek === 'přijato') {
            return (
                <span>
                    <i className={'bi bi-check-square text-icon__left'}></i>
                    {rowData.predmet_hlasovani}
                </span>
            );
        }
        return (
            <span>
                <i className={'bi bi-x-square text-icon__left'}></i>
                {rowData.predmet_hlasovani}
            </span>
        );
    };

    let data = undefined;

    if (
        firstMemberData !== undefined &&
        props.firstMember !== null &&
        secondMemberData !== undefined &&
        props.secondMember !== null &&
        compareData !== undefined
    ) {
        if (loading) {
            return <LoadingPage />;
        }
        data = [
            {
                code: props.firstMember.code,
                jmeno: firstMemberData.jmeno,
                aliasy: firstMemberData.aliasy,
                aktivni_clen:
                    secondMemberData.aktivni_clen === 1
                        ? t('active', { ns: 'translation' })
                        : t('inactive', { ns: 'translation' }),
                pohlavi: t('member', { ns: 'translation', context: secondMemberData.pohlavi }),
                mandat: firstMemberData.dochazka.mandat,
                celkem_zasedani: firstMemberData.dochazka.celkem_zasedani,
                politicka_strana: firstMemberData.politicke_strany[0].zkratka,
                barva_strany: firstMemberData.politicke_strany[0].strana_barva,
                id_strany: firstMemberData.politicke_strany[0].id,
                pocet_stran: firstMemberData.politicke_strany.length,
                dochazka: firstMemberData.hlasovani.sumarizace.hasOwnProperty('nepřít.')
                    ? Math.round(
                          (firstMemberData.hlasovani.aktivni_platna /
                              (firstMemberData.hlasovani.aktivni_platna +
                                  firstMemberData.hlasovani.sumarizace['nepřít.'])) *
                              100
                      )
                    : Math.round(
                          (firstMemberData.hlasovani.aktivni_platna / firstMemberData.hlasovani.aktivni_platna) * 100
                      )
            },
            {
                code: props.secondMember.code,
                jmeno: secondMemberData.jmeno,
                aliasy: secondMemberData.aliasy,
                aktivni_clen:
                    secondMemberData.aktivni_clen === 1
                        ? t('active', { ns: 'translation' })
                        : t('inactive', { ns: 'translation' }),
                pohlavi: t('member', { ns: 'translation', context: secondMemberData.pohlavi }),
                mandat: secondMemberData.dochazka.mandat,
                celkem_zasedani: secondMemberData.dochazka.celkem_zasedani,
                politicka_strana: secondMemberData.politicke_strany[0].zkratka,
                barva_strany: secondMemberData.politicke_strany[0].strana_barva,
                id_strany: secondMemberData.politicke_strany[0].id,
                pocet_stran: secondMemberData.politicke_strany.length,
                dochazka: secondMemberData.hlasovani.sumarizace.hasOwnProperty('nepřít.')
                    ? Math.round(
                          (secondMemberData.hlasovani.aktivni_platna /
                              (secondMemberData.hlasovani.aktivni_platna +
                                  secondMemberData.hlasovani.sumarizace['nepřít.'])) *
                              100
                      )
                    : Math.round(
                          (secondMemberData.hlasovani.aktivni_platna / secondMemberData.hlasovani.aktivni_platna) * 100
                      )
            }
        ];
    }

    return (
        <div className='comparison-detail'>
            {data !== undefined ? (
                <>
                    <div className='grid'>
                        {data.map((member) => {
                            return (
                                <>
                                    <div className='item-list' key={member.jmeno}>
                                        <h3>
                                            <Link to={`../../zastupitele/${member.code}`} key={member.code}>
                                                {member.jmeno}
                                            </Link>
                                            {member.aliasy && (
                                                <AliasTooltip
                                                    id={`member-${member.code}-aliases`}
                                                    aliases={member.aliasy}
                                                />
                                            )}
                                        </h3>
                                        <ul className='list__unstyled'>
                                            <li className='bullet__primary'>
                                                <span className='text__primary text__bold'>{member.aktivni_clen}</span>{' '}
                                                {member.pohlavi}
                                            </li>
                                            {member.mandat === member.celkem_zasedani ? (
                                                <li className='bullet__primary'>
                                                    {t('mandate', { ns: 'translation' })}{' '}
                                                    <span className='text__primary text__bold'>
                                                        {t('whole', { ns: 'translation' })}
                                                    </span>{' '}
                                                    {t('term', { ns: 'translation' })}
                                                </li>
                                            ) : (
                                                <li className='bullet__primary'>
                                                    {t('mandate_out_of', {
                                                        ns: 'translation',
                                                        cnt1: member.mandat,
                                                        cnt2: member.celkem_zasedani
                                                    })}
                                                </li>
                                            )}
                                            <li className='bullet__primary'>
                                                {t('political-party_one', { ns: 'translation', count: 1 })} -{' '}
                                                <Link
                                                    style={{ color: member.barva_strany }}
                                                    className='text__semibold nowrap'
                                                    to={`../../subjekty/${member.id_strany}`}
                                                    key={member.id_strany}
                                                >
                                                    {member.politicka_strana}
                                                </Link>
                                                {member.pocet_stran > 1
                                                    ? ' (' + t('latest', { ns: 'translation' }) + ')'
                                                    : ''}
                                            </li>
                                            <li className='bullet__primary'>
                                                <span className='text__primary text__bold'>{member.dochazka}%</span>{' '}
                                                {t('voting-attendance', { ns: 'translation' })}
                                            </li>
                                        </ul>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                    <div className='text__center'>
                        <h2 className='text__primary'>
                            <span className='text__xl'>
                                {((compareData.shoda / (compareData.shoda + compareData.neshoda)) * 100).toFixed(1)}%
                            </span>
                            <span className='text__semibold'> {t('voting-agreement', { ns: 'translation' })}</span>
                        </h2>
                        <p>
                            {dateFrom && dateTo ? (
                                <>
                                    {t('mandate-date')} {t('from')} <span className='text__primary'>{dateFrom}</span>{' '}
                                    {t('to')} <span className='text__primary'>{dateTo}</span>.
                                </>
                            ) : dateFrom ? (
                                <>
                                    {t('mandate-date')} {t('from')} <span className='text__primary'>{dateFrom}</span>.
                                </>
                            ) : dateTo ? (
                                <>
                                    {t('mandate-date')} {t('to')} <span className='text__primary'>{dateTo}</span>.
                                </>
                            ) : (
                                <>{t('mandate-entire-term')}</>
                            )}
                        </p>
                    </div>
                    <Divider />
                </>
            ) : (
                ''
            )}
            <div className='voting-comparison'>
                {dateFrom !== undefined &&
                dateTo !== undefined &&
                compareData !== undefined &&
                JSON.stringify(compareData) !== '{"error":"neexistujici ID"}' ? (
                    <div>
                        <h2>{t('comparison-councillors-votings')}</h2>
                        <div className='dropdown-wrapper'>
                            <span className='p-float-label'>
                                <Dropdown
                                    inputId='compareSessionSelection'
                                    value={selectedSession === null ? compareData.zasedani[0] : selectedSession}
                                    options={compareData.zasedani}
                                    onChange={onOptionChange}
                                    optionLabel='name'
                                />
                                <label htmlFor='compareSessionSelection'>
                                    {t('select-meeting', { ns: 'translation' })}
                                </label>
                            </span>
                        </div>
                        <Link
                            className='text__primary text__center'
                            to={`../../zasedani/${
                                selectedSession === null ? compareData.zasedani[0].code : selectedSession.code
                            }`}
                            key={selectedSession === null ? compareData.zasedani[0].code : selectedSession.code}
                        >
                            <h2 className='text__primary'>
                                <span className='font-semibold'>{t('meeting-no', { ns: 'translation' })} </span>
                                {selectedSession === null ? compareData.zasedani[0].name : selectedSession.name}
                            </h2>
                        </Link>
                        <div className='tabview-wrapper'>
                            <TabView>
                                <TabPanel header={t('councillors-agreement')}>
                                    {selectedSession === null ? (
                                        <div>
                                            {compareData.data[compareData.zasedani[0].name]['shoda'].length > 0 ? (
                                                <PaginatedDataTable
                                                    data={compareData.data[compareData.zasedani[0].name]['shoda']}
                                                    selection={selectedRow1}
                                                    onSelectionChange={onTableOptionChange1}
                                                >
                                                    <Column
                                                        field='cislo_hlasovani'
                                                        header={t('voting-number', { ns: 'translation' })}
                                                    ></Column>
                                                    <Column
                                                        className='w80 lh4'
                                                        field='predmet_hlasovani'
                                                        header={t('voting-description', { ns: 'translation' })}
                                                        body={resultBodyTemplate}
                                                    ></Column>
                                                </PaginatedDataTable>
                                            ) : (
                                                t('no-votings-found')
                                            )}
                                        </div>
                                    ) : (
                                        <div>
                                            {compareData.data[selectedSession.name]['shoda'].length > 0 ? (
                                                <PaginatedDataTable
                                                    data={compareData.data[selectedSession.name]['shoda']}
                                                    selection={selectedRow1}
                                                    onSelectionChange={onTableOptionChange1}
                                                >
                                                    <Column
                                                        field='cislo_hlasovani'
                                                        header={t('voting-number', { ns: 'translation' })}
                                                    ></Column>
                                                    <Column
                                                        className='w80 lh4'
                                                        field='predmet_hlasovani'
                                                        header={t('voting-description', { ns: 'translation' })}
                                                        body={resultBodyTemplate}
                                                    ></Column>
                                                </PaginatedDataTable>
                                            ) : (
                                                t('no-votings-found')
                                            )}
                                        </div>
                                    )}
                                </TabPanel>
                                <TabPanel header={t('councillors-disagreement')}>
                                    {selectedSession === null ? (
                                        <div>
                                            {compareData.data[compareData.zasedani[0].name]['neshoda'].length > 0 ? (
                                                <PaginatedDataTable
                                                    data={compareData.data[compareData.zasedani[0].name]['neshoda']}
                                                    selection={selectedRow2}
                                                    onSelectionChange={onTableOptionChange2}
                                                >
                                                    <Column
                                                        field='cislo_hlasovani'
                                                        header={t('voting-number', { ns: 'translation' })}
                                                    ></Column>
                                                    <Column
                                                        className='w80 lh4'
                                                        field='predmet_hlasovani'
                                                        header={t('voting-description', { ns: 'translation' })}
                                                        body={resultBodyTemplate}
                                                    ></Column>
                                                </PaginatedDataTable>
                                            ) : (
                                                t('no-votings-found')
                                            )}
                                        </div>
                                    ) : (
                                        <div>
                                            {compareData.data[selectedSession.name]['neshoda'].length > 0 ? (
                                                <PaginatedDataTable
                                                    data={compareData.data[selectedSession.name]['neshoda']}
                                                    selection={selectedRow2}
                                                    onSelectionChange={onTableOptionChange2}
                                                >
                                                    <Column
                                                        field='cislo_hlasovani'
                                                        header={t('voting-number', { ns: 'translation' })}
                                                    ></Column>
                                                    <Column
                                                        className='w80 lh4'
                                                        field='predmet_hlasovani'
                                                        header={t('voting-description', { ns: 'translation' })}
                                                        body={resultBodyTemplate}
                                                    ></Column>
                                                </PaginatedDataTable>
                                            ) : (
                                                t('no-votings-found')
                                            )}
                                        </div>
                                    )}
                                </TabPanel>
                            </TabView>
                        </div>
                    </div>
                ) : (
                    ''
                )}

                {compareData !== undefined ? (
                    <>
                        <h2>{t('agreement-on-meetings')}</h2>
                        <ChartComponent type='bar' data={chartData} options={chartOptions} />
                    </>
                ) : (
                    ''
                )}
            </div>
            {makingRequest === true ? <LoadingPage /> : ''}
        </div>
    );
}

export default ComparisonDetail;
