import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoadingPage from '../../components/LoadingPage';
import { Link, Navigate, useParams } from 'react-router-dom';
import { ProgressBar, Button, Card, Tooltip, Chip, Checkbox, MultiSelect } from '../../components/PrimereactComponents';
import { Column } from 'primereact/column';
import useDimensions from '../../hooks/useDimensions';
import ChartComponent from '../../components/ChartComponent';
import { useTranslation } from 'react-i18next';
import { redirectErrorPage } from '../../utils/errors';
import PaginatedDataTable from '../../components/PaginatedDataTable';
import { AliasTooltip } from '../../components/AliasTooltip';

function AttendanceAnalysis() {
    const [loading, setLoading] = useState(true);
    const [basicData, setBasicData] = useState(undefined);
    const [generalData, setGeneralData] = useState(undefined);
    const [error, setError] = useState(null);
    const [partiesStats, setPartiesStats] = useState(undefined);
    const { width } = useDimensions();
    const [displayInactive, setDisplayInactive] = useState(false);
    const [selectedParties, setSelectedParties] = useState([]);

    const { t } = useTranslation(['analyses', 'translation']);

    const { municipalitaId, bodyType, zastupitelstvoId } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responses = await Promise.all([
                    axios.get(`${municipalitaId}/${bodyType}/${zastupitelstvoId}/attendanceDashboard`),
                    axios.get(`${municipalitaId}/${bodyType}/${zastupitelstvoId}/generalData`),
                    axios.get(`${municipalitaId}/${bodyType}/${zastupitelstvoId}/partiesStats`)
                ]);

                setBasicData(responses[0].data);
                setGeneralData(responses[1].data);
                setPartiesStats(responses[2].data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const attendanceBodyTemplate = (rowData) => {
        return (
            <div className='flex'>
                <strong className='w40'>{rowData.dochazka}&nbsp;%</strong>
                <div className={width < 768 ? '' : 'w60'} style={{ alignContent: 'center' }}>
                    <ProgressBar
                        value={rowData.dochazka}
                        showValue={false}
                        style={{ height: '6px' }}
                        aria-label={`docházka zastupitele ${rowData.jmeno} ${rowData.prijmeni}`}
                    />
                </div>
            </div>
        );
    };

    if (loading) {
        return <LoadingPage />;
    }

    if (error) {
        return redirectErrorPage(error);
    }

    if (basicData !== undefined && generalData !== undefined && partiesStats !== undefined) {
        let filtered = partiesStats.filter((obj) => basicData.data.some((item) => item.strana_id === obj.strana_id));
        filtered.sort((a, b) => b.celkova_ucast - a.celkova_ucast);

        const customSort = (a, b) => {
            return (
                filtered.findIndex((obj) => obj.strana_id === a.strana_id) -
                filtered.findIndex((obj) => obj.strana_id === b.strana_id)
            );
        };

        const sortedArray = basicData.data.sort(customSort);

        const partyOptions = [
            ...sortedArray.map((party) => ({
                label: party.strana_zkratka,
                value: party.strana_id
            }))
        ];

        const onInactiveChange = () => {
            setDisplayInactive(!displayInactive);
        };

        const filteredMembers =
            selectedParties.length === 0
                ? sortedArray.flatMap((party) =>
                      party.clenove.map((clen) => ({
                          ...clen,
                          strana: party.strana_zkratka,
                          strana_barva: party.strana_barva
                      }))
                  )
                : sortedArray
                      .filter((party) => selectedParties.includes(party.strana_id))
                      .flatMap((party) =>
                          party.clenove.map((clen) => ({
                              ...clen,
                              strana: party.strana_zkratka,
                              strana_barva: party.strana_barva
                          }))
                      );

        const chartData = {
            labels: [''],
            datasets: filtered.map((party) => ({
                label: party.strana_zkratka,
                backgroundColor: party.strana_barva,
                data: [party.celkova_ucast]
            }))
        };

        const chartOptions = {
            indexAxis: 'y',
            plugins: {
                legend: {
                    onHover: function (evt, item, legend) {
                        const tooltip = legend.chart.tooltip;
                        if (tooltip.getActiveElements().length > 0) {
                            tooltip.setActiveElements([], { x: 0, y: 0 });
                        }

                        tooltip.setActiveElements([{ datasetIndex: item.datasetIndex, index: 0 }], { x: 0, y: 0 });

                        evt.native.target.style.cursor = 'pointer';
                        legend.chart.data.datasets.forEach((i, id) => {
                            if (id !== item.datasetIndex) {
                                i.backgroundColor += '30';
                            }
                        });
                        legend.chart.update();
                    },
                    onLeave: function (evt, item, legend) {
                        legend.chart.data.datasets.forEach((i, id) => {
                            if (id !== item.datasetIndex) {
                                i.backgroundColor = i.backgroundColor.slice(0, -2);
                            }
                        });
                        legend.chart.update();
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        beginAtZero: true,
                        callback: (value, index, values) => {
                            return value + ' %';
                        }
                    },
                    max: 100
                }
            },
            maintainAspectRatio: false,
            aspectRatio: width < 768 ? 1.4 : 1,
            onClick: function (evt, item) {
                if (item[0] !== undefined) {
                    setSelectedParties([filtered[item[0].datasetIndex].strana_id]);
                }
            }
        };

        const partyFilterTemplate = (options) => {
            return (
                <>
                    <div>
                        <MultiSelect
                            value={selectedParties}
                            options={partyOptions}
                            onChange={(e) => setSelectedParties(e.value)}
                            placeholder='Zvolte subjekty'
                            display='chip'
                            maxSelectedLabels={1}
                        />
                    </div>
                </>
            );
        };

        const nameFilterTemplate = () => {
            return (
                <>
                    <Checkbox inputId='binary' checked={displayInactive} onChange={onInactiveChange} />
                    <label htmlFor='binary'>&nbsp;{t('display-inactive', { ns: 'translation' })}</label>
                </>
            );
        };

        const partyTemplate = (rowData) => {
            return (
                <Chip
                    style={{ margin: '0', fontWeight: '400', backgroundColor: rowData.strana_barva, color: 'white' }}
                    label={rowData.strana}
                />
            );
        };

        const filterClearTemplate = (options) => {
            return (
                <Button
                    type='button'
                    className='p-button-danger'
                    icon='pi pi-times'
                    onClick={options.filterClearCallback}
                ></Button>
            );
        };

        const filterApplyTemplate = (options) => {
            return (
                <Button
                    className='p-button-success'
                    type='button'
                    icon='pi pi-check'
                    onClick={options.filterApplyCallback}
                ></Button>
            );
        };

        return (
            <Card className='attendance'>
                <div className='attendance-grid'>
                    <div className='members-card'>
                        <h2>
                            {t('councilMember_attendance', { ns: 'translation' })}
                            <i
                                className='custom-target-icon pi bi-info-circle text-icon__right parties__chart-icon'
                                data-pr-tooltip={t('attendance-tooltip')}
                                data-pr-position='bottom'
                            ></i>
                        </h2>
                        <div className='analyses-content'>
                            <Tooltip target='.custom-target-icon' />

                            <PaginatedDataTable
                                initialSortOrder={-1}
                                removableSort
                                initialSortField={'dochazka'}
                                data={filteredMembers.filter((clen) => displayInactive || clen.aktivni === 1)}
                                paginator
                                rows={10}
                            >
                                <Column
                                    className='w30 p-column-filter'
                                    filterElement={partyFilterTemplate}
                                    showAddButton={false}
                                    showFilterMatchModes={false}
                                    showFilterMenuOptions={false}
                                    filterApply={filterApplyTemplate}
                                    filterClear={filterClearTemplate}
                                    filter
                                    field='strana'
                                    body={partyTemplate}
                                    sortable
                                    header='Subjekt'
                                />
                                <Column
                                    className='w35'
                                    field='jmeno'
                                    filter
                                    filterElement={nameFilterTemplate}
                                    showAddButton={false}
                                    showFilterMatchModes={false}
                                    showFilterMenuOptions={false}
                                    filterApply={filterApplyTemplate}
                                    filterClear={filterClearTemplate}
                                    header='Člen/ka'
                                    body={(rowData) => (
                                        <>
                                            <Link
                                                className={`item-representative ${
                                                    rowData.aktivni === 1 ? 'text__primary' : 'text__secondary'
                                                }`}
                                                to={`../../zastupitele/${rowData.id}`}
                                            >
                                                {rowData.jmeno + ' ' + rowData.prijmeni}
                                                {rowData.aktivni === 1 ? '' : ' (neaktivní)'}
                                            </Link>
                                            {rowData.aliasy && (
                                                <AliasTooltip
                                                    id={`member-${rowData.id}-aliases`}
                                                    aliases={rowData.aliasy}
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                <Column
                                    className='w35'
                                    sortable
                                    field='dochazka'
                                    header='Docházka'
                                    body={attendanceBodyTemplate}
                                />
                            </PaginatedDataTable>
                        </div>
                    </div>

                    <div className='parties-card'>
                        <h2>
                            Docházka dle subjektů
                            <i
                                className='custom-target-icon2 pi bi-info-circle text-icon__right parties__chart-icon'
                                data-pr-tooltip={t('parties-attendance-tooltip')}
                                data-pr-position='bottom'
                            ></i>
                        </h2>
                        <Tooltip target='.custom-target-icon2' />
                        <div className='analyses-content'>
                            <ChartComponent type='bar' height='450px' data={chartData} options={chartOptions} />
                        </div>
                    </div>
                </div>
            </Card>
        );
    } else {
        return <Navigate to='/' />;
    }
}

export default AttendanceAnalysis;
