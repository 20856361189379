import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonEdit } from '../../../components/Buttons';
import { FormContainer, FormInput, FormRow, Label } from '../../../components/form';
import { RHFCalendar, RHFInputText } from '../../../components/rhf';
import { getFormErrorMessage, maxLengthMessage } from '../../../utils/errors';
import { ZASEDANI, ZASEDANI_ZAZNAM } from '../../../constants/field_lengths';

export const MeetingEditForm = ({ onSubmit, meeting, disabled }) => {
    const [processing, setProcessing] = useState(false);

    const defaultValues = {
        date: new Date(meeting.date),
        number: meeting.number,
        recording_url: meeting.recording_url ?? ''
    };

    const {
        control,
        formState: { errors, isSubmitting },
        handleSubmit
    } = useForm({
        disabled: processing || disabled,
        defaultValues: defaultValues
    });

    useEffect(() => {
        setProcessing(isSubmitting);
    }, [isSubmitting]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormRow>
                    <FormInput>
                        <Label htmlFor='date' text='Datum' required />
                        <RHFCalendar
                            control={control}
                            name='date'
                            rules={{ required: 'Zadejte datum zasedání' }}
                            placeholder='Datum zasedání'
                            inputProps={{
                                dateFormat: 'd.m.yy'
                            }}
                        />
                        {getFormErrorMessage(errors, 'date')}
                    </FormInput>
                    <FormInput>
                        <Label htmlFor='number' text='Číslo zasedání' required />
                        <RHFInputText
                            control={control}
                            name='number'
                            rules={{
                                required: 'Zadejte číslo zasedání',
                                maxLength: {
                                    value: ZASEDANI.CISLO,
                                    message: maxLengthMessage(ZASEDANI.CISLO)
                                }
                            }}
                            placeholder='č.'
                        />
                        {getFormErrorMessage(errors, 'number')}
                    </FormInput>
                    <FormInput width='lg'>
                        <Label htmlFor='recording_url' text='Odkaz na záznam zasedání' />
                        <RHFInputText
                            control={control}
                            name='recording_url'
                            rules={{
                                maxLength: {
                                    value: ZASEDANI_ZAZNAM.URL,
                                    message: maxLengthMessage(ZASEDANI_ZAZNAM.URL)
                                }
                            }}
                            placeholder='URL nahrávky zasedání'
                        />
                    </FormInput>
                </FormRow>
                <FormRow type='button'>
                    <ButtonEdit
                        type='submit'
                        label='Upravit zasedání'
                        disabled={processing || disabled}
                        loading={processing}
                    />
                </FormRow>
            </FormContainer>
        </form>
    );
};
