import { RadioButton } from 'primereact/radiobutton';
import { classNames } from 'primereact/utils';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ButtonEdit } from '../../../components/Buttons';
import { FormContainer, FormInput, FormRow, Label } from '../../../components/form';
import { getFormErrorMessage, maxLengthMessage } from '../../../utils/errors';
import { RHFInputText } from '../../../components/rhf';
import { UZIVATEL } from '../../../constants/field_lengths';

export const MemberEditForm = ({ member, onSubmit }) => {
    const [processing, setProcessing] = useState(false);

    const defaultValues = {
        first_name: member.first_name,
        last_name: member.last_name,
        sex: member.sex
    };

    const {
        control,
        formState: { errors, isSubmitting },
        handleSubmit
    } = useForm({
        disabled: processing,
        defaultValues: defaultValues
    });

    useEffect(() => {
        setProcessing(isSubmitting);
    }, [isSubmitting]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormRow>
                    <FormInput>
                        <Label htmlFor='first_name' text='Jméno' required />
                        <RHFInputText
                            control={control}
                            name='first_name'
                            rules={{
                                required: 'Vyplňte toto pole',
                                maxLength: {
                                    value: UZIVATEL.JMENO,
                                    message: maxLengthMessage(UZIVATEL.JMENO)
                                }
                            }}
                            placeholder='Jméno'
                        />
                        {getFormErrorMessage(errors, 'first_name')}
                    </FormInput>
                    <FormInput>
                        <Label htmlFor='last_name' text='Příjmení' required />
                        <RHFInputText
                            control={control}
                            name='last_name'
                            rules={{
                                required: 'Vyplňte toto pole',
                                maxLength: {
                                    value: UZIVATEL.PRIJMENI,
                                    message: maxLengthMessage(UZIVATEL.PRIJMENI)
                                }
                            }}
                            placeholder='Příjmení'
                        />
                        {getFormErrorMessage(errors, 'last_name')}
                    </FormInput>
                    <FormInput>
                        <Label htmlFor='sex' text='Pohlaví' required />
                        <Controller
                            name='sex'
                            control={control}
                            rules={{
                                validate: (value) => value !== null || 'Vyberte pohlaví'
                            }}
                            render={({ field, fieldState }) => {
                                const fieldRef = field.ref;
                                field.ref = undefined;
                                return (
                                    <div className='radio-group'>
                                        <div className='radio-group__item'>
                                            <RadioButton
                                                inputId='M'
                                                inputRef={fieldRef}
                                                {...field}
                                                className={classNames({ 'p-invalid': fieldState.invalid })}
                                                value={false}
                                                checked={field.value === false}
                                            />
                                            <label htmlFor='M'>Muž</label>
                                        </div>
                                        <div className='radio-group__item'>
                                            <RadioButton
                                                inputId='F'
                                                {...field}
                                                className={classNames({ 'p-invalid': fieldState.invalid })}
                                                value={true}
                                                checked={field.value === true}
                                            />
                                            <label htmlFor='F'>Žena</label>
                                        </div>
                                    </div>
                                );
                            }}
                        />
                        {getFormErrorMessage('sex')}
                    </FormInput>
                </FormRow>
                <FormRow type='button'>
                    <ButtonEdit type='submit' label='Upravit' disabled={processing} loading={processing} />
                </FormRow>
            </FormContainer>
        </form>
    );
};
