import APP from "../config";
import about_default from "./about/about";

const getAbout = (response) => {
    switch(APP.subdomain) {
        default:
            return about_default(response);
    }
}

const about = (response) => getAbout(response);
export default about;