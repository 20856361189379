import PopupBodyChart from "../../../../components/map/popup/PopupBodyChart";
import PopupFooter from "../../../../components/map/popup/PopupFooter";
import PopupHeader from "../../../../components/map/popup/PopupHeader";
import data from "../data";
import geo from "../geo";
import meta from "../meta";

/**
 * Unemployment statistics.
 * 
 * @author Adam Janosik, Kristyna Zaklova
 */
const sourceLabel = "ČSÚ –⁠⁠⁠⁠⁠⁠ míra nezaměstnanosti";
const sourceValue = "https://vdb.czso.cz/vdbvo2/faces/index.jsf?page=vystup-objekt&z=T&f=TABULKA&katalog=30853&pvo=ZAM06&str=v95#w=";

const unemployment23 = {
    label: "Rok 2023",
    data: data,
    categories: meta.nezamestnanost.values,
    getValue: meta.nezamestnanost.getValue,
    formatValue: meta.nezamestnanost.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle míry nezaměstnanosti obyvatel jednotlivých krajů (rok 2023)",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Míra nezaměstnanosti obyvatel jednotlivých krajů se v roce <span className="emp"><strong>2023</strong></span> pohybovala od <span className="emp"><strong>1,5 %</strong></span> do <span className="emp"><strong>4,3 %</strong></span>. Největší míra nezaměstnanosti obyvatel byla v <span className="emp"><strong>Karlovarském kraji</strong></span> a nejmenší v <span className="emp"><strong>Kraji Vysočina</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.nezamestnanost.values,
                                            getValue: meta.nezamestnanost.getValue,
                                            formatValue: meta.nezamestnanost.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: sourceLabel,
                                            value: sourceValue
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}


const unemployment22 = {
    label: "Rok 2022",
    data: data,
    categories: meta.nezamestnanost22.values,
    getValue: meta.nezamestnanost22.getValue,
    formatValue: meta.nezamestnanost22.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle míry nezaměstnanosti obyvatel jednotlivých krajů (rok 2022)",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Míra nezaměstnanosti obyvatel jednotlivých krajů se v roce <span className="emp"><strong>2022</strong></span> pohybovala od <span className="emp"><strong>1,2 %</strong></span> do <span className="emp"><strong>4,0 %</strong></span>. Největší míra nezaměstnanosti obyvatel byla v <span className="emp"><strong>Karlovarském</strong></span> a <span className="emp"><strong>Moravskoslezském kraji</strong></span> a nejmenší ve <span className="emp"><strong>Středočeském kraji</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.nezamestnanost22.values,
                                            getValue: meta.nezamestnanost22.getValue,
                                            formatValue: meta.nezamestnanost22.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: sourceLabel,
                                            value: sourceValue
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const unemployment21 = {
    label: "Rok 2021",
    data: data,
    categories: meta.nezamestnanost21.values,
    getValue: meta.nezamestnanost21.getValue,
    formatValue: meta.nezamestnanost21.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle míry nezaměstnanosti obyvatel jednotlivých krajů (rok 2021)",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Míra nezaměstnanosti obyvatel jednotlivých krajů se v roce <span className="emp"><strong>2021</strong></span> pohybovala od <span className="emp"><strong>1,8 %</strong></span> do <span className="emp"><strong>5,7 %</strong></span>. Největší míra nezaměstnanosti obyvatel byla v <span className="emp"><strong>Karlovarském kraji</strong></span> a nejmenší v <span className="emp"><strong>Jihočeském</strong></span> a&nbsp;<span className="emp"><strong>Zlínském kraji</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.nezamestnanost21.values,
                                            getValue: meta.nezamestnanost21.getValue,
                                            formatValue: meta.nezamestnanost21.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: sourceLabel,
                                            value: sourceValue
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const unemployment20 = {
    label: "Rok 2020",
    data: data,
    categories: meta.nezamestnanost20.values,
    getValue: meta.nezamestnanost20.getValue,
    formatValue: meta.nezamestnanost20.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle míry nezaměstnanosti obyvatel jednotlivých krajů (rok 2020)",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Míra nezaměstnanosti obyvatel jednotlivých krajů se v roce <span className="emp"><strong>2020</strong></span> pohybovala od <span className="emp"><strong>1,6 %</strong></span> do <span className="emp"><strong>4,7 %</strong></span>. Největší míra nezaměstnanosti obyvatel byla v <span className="emp"><strong>Karlovarském kraji</strong></span> a nejmenší v <span className="emp"><strong>Pardubickém kraji</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.nezamestnanost20.values,
                                            getValue: meta.nezamestnanost20.getValue,
                                            formatValue: meta.nezamestnanost20.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: sourceLabel,
                                            value: sourceValue
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}


const unemploymentInc = {
    label: "Rozdíl mezi lety 2023 a 2020",
    data: data,
    categories: meta.nezamestnanostVyvoj.values,
    getValue: meta.nezamestnanostVyvoj.getValue,
    formatValue: meta.nezamestnanostVyvoj.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle vývoje míry nezaměstnanosti obyvatel v jednotlivých krajích v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Míra nezaměstnanosti obyvatel v letech <span className="emp"><strong>2020-2023</strong></span> v některých krajích vzrostla (kladné hodnoty), v&nbsp;některých krajích se naopak snížila (záporné hodnoty). Největší nárůst nezaměstnanosti byl v&nbsp;<span className="emp"><strong>Královéhradeckém kraji</strong></span> a&nbsp;největší pokles, tj. zlepšení situace, nastal v&nbsp;<span className="emp"><strong>kraji Vysočina</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.nezamestnanostVyvoj.values,
                                            getValue: meta.nezamestnanostVyvoj.getValue,
                                            formatValue: meta.nezamestnanostVyvoj.formatValue
                                        }
                                    }
                                },
                                {
                                    title: "Vývoj obecné míry nezaměstnanosti v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                                
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "line",
                                        config: {
                                            geo: geo,
                                            datasets: [
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.nezamestnanost20.values,
                                                    getValue: meta.nezamestnanost20.getValue,
                                                    formatValue: meta.nezamestnanost20.formatValue,
                                                    label: meta.nezamestnanost20.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.nezamestnanost21.values,
                                                    getValue: meta.nezamestnanost21.getValue,
                                                    formatValue: meta.nezamestnanost21.formatValue,
                                                    label: meta.nezamestnanost21.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.nezamestnanost22.values,
                                                    getValue: meta.nezamestnanost22.getValue,
                                                    formatValue: meta.nezamestnanost22.formatValue,
                                                    label: meta.nezamestnanost22.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.nezamestnanost.values,
                                                    getValue: meta.nezamestnanost.getValue,
                                                    formatValue: meta.nezamestnanost.formatValue,
                                                    label: meta.nezamestnanost.label
                                                }
                                            ]
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: sourceLabel,
                                            value: sourceValue
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}


const unemploymentStats = {
    title: 'Obecná míra nezaměstnanosti obyvatel',
    widget: {
        type: "column-group",
        config: {
            widgets: [
                {
                    type: "custom",
                    config: {
                        content: (
                            <p>
                                <span className="emp">Míra nezaměstnanosti</span> v&nbsp;jednotlivých krajích České republiky se liší v&nbsp;závislosti na ekonomických podmínkách, struktuře průmyslu a&nbsp;dostupnosti pracovních příležitostí v&nbsp;daném regionu. 
                                V&nbsp;některých krajích je míra nezaměstnanosti tradičně nižší díky silné ekonomice a vysoké poptávce po pracovní síle. 
                                Naopak v&nbsp;krajích s&nbsp;vyšší nezaměstnaností mohou hrát roli strukturální problémy, jako je útlum tradičních průmyslových odvětví. 
                                Nezaměstnanost je důležitým ukazatelem regionální prosperity a rozvoje, který ovlivňuje kvalitu života místních obyvatel.
                            </p>
                        )
                    }
                },
                {
                    type: "regional-filtered-choropleth",
                    config: {
                        id: "mapa-nezamestnanost",
                        label: "Celkový počet hlasování",
                        geo: geo,
                        datasets: [
                            unemploymentInc, unemployment23, unemployment22, unemployment21, unemployment20
                        ]
                    }
                },
                {
                    type: "custom",
                    config: {
                        content: (
                            <p>
                                V&nbsp;letech <span className="emp"><strong>2020–⁠⁠⁠⁠⁠⁠2023</strong></span> lze pozorovat, že největší míra nezaměstnanosti obyvatel byla v <span className="emp">Karlovarském kraji</span> a nejmenší v <span className="emp">Jihočeském kraji</span>. V&nbsp;<span className="emp">Praze</span> zůstala situace beze změny.
                            </p>
                        )
                    }
                }
            ]
        }
    },
    footer: {
        left: {
            title: {
                label: "Zdroj:",
                icon: "pi pi-fw pi-globe"
            },
            links: [
                
                {
                    
                    label: sourceLabel,
                    value: sourceValue
                }
            ]
        },
        right: {
            title: undefined,
            links: [
                meta.route
            ]
        },
        popup: {
            component: unemploymentInc.map.defaults.polygon.popup
        }
    }
}
export default unemploymentStats;