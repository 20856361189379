import React, { useEffect, useState } from "react";
import ComparisonDetail from "./ComparisonDetail";
import { useParams } from "react-router-dom";
import axios from "axios";
import { redirectErrorPage } from "../../utils/errors";
import { Message, Divider, Dropdown, Card } from "../../components/PrimereactComponents";
import { useTranslation } from "react-i18next";
import LoadingPage from "../../components/LoadingPage";

const MembersComparison = (props) => {
  const [loading, setLoading] = useState(true);
  const searchParams = new URLSearchParams(window.location.search);
  let firstMemberValue = searchParams.get("prvni_clen") || props.firstMember || null;
  let secondMemberValue = searchParams.get("druhy_clen") || null;
  const [firstMember, setFirstMember] = useState(undefined); 
  const [secondMember, setSecondMember] = useState(undefined); 

  const { municipalitaId, bodyType, zastupitelstvoId } = useParams();
  const {t} = useTranslation(["analyses", "translation"]);

  const [memberOptions, setMemberOptions] = useState(undefined);
  const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await axios.get(`FE/${municipalitaId}/${bodyType}/${zastupitelstvoId}/dropdownMemberOptions`).then((response) => {
                    setMemberOptions(response.data);
                    setFirstMember(
                      firstMemberValue !== null && response.data
                        ? response.data.find(
                            (element) => element.code === parseInt(firstMemberValue),
                          )
                        : null,
                    );
                    
                    setSecondMember(
                      secondMemberValue !== null && response.data
                        ? response.data.find(
                            (element) => element.code === parseInt(secondMemberValue),
                          )
                        : null,
                    );
                    });
            } catch (error) {
                setError(error);
            } finally{
                setLoading(false);
            }
        };
    
        fetchData();

    }, []); 
  
  if (loading) {return <LoadingPage />};
    
  if (error) { return redirectErrorPage(error)};

  const setParams = (key, value) => {
    const url = new URL(window.location);
    url.searchParams.set(key, value);
    window.history.pushState({}, "", url);
  };

  const onFirstMemberChange = (e) => {
    let same = false;
    // prenastaveni na stejne
    if (secondMember === e.value) {
      same = true;
    }

    setFirstMember(e.value);
    if (e.value !== undefined && same === false) {
      // vse ok
      setParams("prvni_clen", e.value.code);
    } else {
      // zruseni filtru
      setFirstMember(null);
      const url = new URL(window.location);
      url.searchParams.delete("prvni_clen");
      window.history.pushState({}, "", url);
    }
  };

  const onSecondMemberChange = (e) => {
    let same = false;
    // prenastaveni na stejne
    if (firstMember === e.value) {
      same = true;
    }

    setSecondMember(e.value);
    if (e.value !== undefined && same === false) {
      // vse ok
      setParams("druhy_clen", e.value.code);
    } else {
      // zruseni filtru
      setSecondMember(null);
      const url = new URL(window.location);
      url.searchParams.delete("druhy_clen");
      window.history.pushState({}, "", url);
    }
  };

  if(memberOptions === undefined){ return <LoadingPage/>}
  return (
    <Card className="representative-comparison">
      <h2>{t('councilMember_comparison', {ns: 'translation'})}</h2>
      <div className="dropdowns-wrapper">
        <span className="p-float-label">
        <Dropdown
          value={firstMember}
          options={memberOptions}
          onChange={onFirstMemberChange}
          optionLabel="name"
          filter
          showClear
          filterBy="name"
          inputId="firstMemberSelection"
        />
        <label htmlFor="firstMemberSelection">{t("select-first-member")}</label>
        </span>
        <span className="p-float-label">
        <Dropdown
          value={secondMember}
          options={memberOptions}
          onChange={onSecondMemberChange}
          optionLabel="name"
          filter
          showClear
          filterBy="name"
          inputId="secondMemberSelection"
        />
        <label htmlFor="secondMemberSelection">{t("select-second-member")}</label>
        </span>
        <Divider/>
      </div>
      {firstMember === null ||
      secondMember === null ||
      firstMember === secondMember ? (
        <div className="message-wrapper">
          <Message severity="info" text={t("select-two-different")} />
        </div>
      ) : (
        ""
      )}

      <ComparisonDetail
        municipalitaId={municipalitaId}
        firstMember={firstMember}
        secondMember={secondMember}
      />
    </Card>
  );
};

export default MembersComparison;
