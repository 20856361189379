import PopupBodyChart from "../../../../components/map/popup/PopupBodyChart";
import PopupFooter from "../../../../components/map/popup/PopupFooter";
import PopupHeader from "../../../../components/map/popup/PopupHeader";
import data from "../data";
import geo from "../geo";
import meta from "../meta";

/**
 * crime per citizen statistics.
 * 
 * @author Adam Janosik, Kristyna Zaklova
 */
const crimePerCitizen23 = {
    label: "Rok 2023",
    data: data,
    categories: meta.trestneCinyNaObyvatele23.values,
    getValue: meta.trestneCinyNaObyvatele23.getValue,
    formatValue: meta.trestneCinyNaObyvatele23.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle počtu trestných činů na obyvatele v jednotlivých krajích (rok 2023)",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Počet trestných činů na obyvatele se v jednotlivých krajích v roce <span
                                                className="emp"><strong>2023</strong></span> pohyboval od <span
                                                className="emp"><strong>0,02966</strong></span> do <span
                                                className="emp"><strong>0,00940</strong></span>.
                                                Nejhorší bilanci měla <span className="emp"><strong>Praha</strong></span> a
                                                nejlepší <span className="emp"><strong>Pardubický kraj</strong></span>.
                                            </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.trestneCinyNaObyvatele23.values,
                                            getValue: meta.trestneCinyNaObyvatele23.getValue,
                                            formatValue: meta.trestneCinyNaObyvatele23.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ kriminalita (rok 2023)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2023"
                                        },
                                        {
                                            ...meta.csuPeople,
                                            label: "ČSÚ - obyvatelstvo"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const crimePerCitizen22 = {
    label: "Rok 2022",
    data: data,
    categories: meta.trestneCinyNaObyvatele22.values,
    getValue: meta.trestneCinyNaObyvatele22.getValue,
    formatValue: meta.trestneCinyNaObyvatele22.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle počtu trestných činů na obyvatele v jednotlivých krajích (rok 2022)",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Počet trestných činů na obyvatele se v jednotlivých krajích v roce <span
                                                className="emp"><strong>2022</strong></span> pohyboval od <span
                                                className="emp"><strong>0,02999</strong></span> do <span
                                                className="emp"><strong>0,00989</strong></span>.
                                                Nejhorší bilanci měla <span className="emp"><strong>Praha</strong></span> a
                                                nejlepší <span className="emp"><strong>Pardubický kraj</strong></span>.
                                            </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.trestneCinyNaObyvatele22.values,
                                            getValue: meta.trestneCinyNaObyvatele22.getValue,
                                            formatValue: meta.trestneCinyNaObyvatele22.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ kriminalita (rok 2022)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2022"
                                        },
                                        {
                                            ...meta.csuPeople,
                                            label: "ČSÚ - obyvatelstvo"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const crimePerCitizen21 = {
    label: "Rok 2021",
    data: data,
    categories: meta.trestneCinyNaObyvatele21.values,
    getValue: meta.trestneCinyNaObyvatele21.getValue,
    formatValue: meta.trestneCinyNaObyvatele21.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle počtu trestných činů na obyvatele v jednotlivých krajích (rok 2021)",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Počet trestných činů na obyvatele se v jednotlivých krajích v roce <span
                                                className="emp"><strong>2021</strong></span> pohyboval od <span
                                                className="emp"><strong>0,02755</strong></span> do <span
                                                className="emp"><strong>0,00842</strong></span>.
                                                Nejhorší bilanci měla <span className="emp"><strong>Praha</strong></span> a
                                                nejlepší <span className="emp"><strong>Zlínský kraj</strong></span>.
                                            </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.trestneCinyNaObyvatele21.values,
                                            getValue: meta.trestneCinyNaObyvatele21.getValue,
                                            formatValue: meta.trestneCinyNaObyvatele21.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ kriminalita (rok 2021)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2021"
                                        },
                                        {
                                            ...meta.csuPeople,
                                            label: "ČSÚ - obyvatelstvo"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const crimePerCitizen20 = {
    label: "Rok 2020",
    data: data,
    categories: meta.trestneCinyNaObyvatele20.values,
    getValue: meta.trestneCinyNaObyvatele20.getValue,
    formatValue: meta.trestneCinyNaObyvatele20.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle počtu trestných činů na obyvatele v jednotlivých krajích (rok 2020)",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Počet trestných činů na obyvatele se v jednotlivých krajích v roce <span
                                                className="emp"><strong>2020</strong></span> pohyboval od <span
                                                className="emp"><strong>0,02851</strong></span> do <span
                                                className="emp"><strong>0,00914</strong></span>.
                                                Nejhorší bilanci měla <span className="emp"><strong>Praha</strong></span> a
                                                nejlepší <span className="emp"><strong>Pardubický kraj</strong></span>.
                                            </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.trestneCinyNaObyvatele20.values,
                                            getValue: meta.trestneCinyNaObyvatele20.getValue,
                                            formatValue: meta.trestneCinyNaObyvatele20.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ kriminalita (rok 2020)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2020"
                                        },
                                        {
                                            ...meta.csuPeople,
                                            label: "ČSÚ - obyvatelstvo"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const crimePerCitizenAvg = {
    label: "2020 vs. 2023",
    data: data,
    categories: meta.trestneCinyNaObyvateleVyvoj.values,
    getValue: meta.trestneCinyNaObyvateleVyvoj.getValue,
    formatValue: meta.trestneCinyNaObyvateleVyvoj.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle vývoje počtu trestných činů na obyvatele v jednotlivých krajích v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>  
                                                <p>Počet trestných činů na obyvatele v letech <span className="emp"><strong>2020–2023</strong></span> ve všech krajích vzrostl, a to o <span className="emp"><strong>0.00026</strong></span> až <span className="emp"><strong>0.00201</strong></span>. Největší nárůst byl v <span className="emp"><strong>Karlovarském kraji</strong></span> a nejmenší v <span className="emp"><strong>Pardubickém kraji</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.trestneCinyNaObyvateleVyvoj.values,
                                            getValue: meta.trestneCinyNaObyvateleVyvoj.getValue,
                                            formatValue: meta.trestneCinyNaObyvateleVyvoj.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ kriminalita (rok 2023)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2023"
                                        },
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ kriminalita (rok 2022)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2022"
                                        },
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ kriminalita (rok 2021)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2021"
                                        },
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ kriminalita (rok 2020)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2020"
                                        },
                                        {
                                            ...meta.csuPeople,
                                            label: "ČSÚ - obyvatelstvo"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const crimePerCitizenSum = {
    label: "Suma 2020 - 2023",
    data: data,
    categories: meta.trestneCinyNaObyvateleSuma.values,
    getValue: meta.trestneCinyNaObyvateleSuma.getValue,
    formatValue: meta.trestneCinyNaObyvateleSuma.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle sumy počtů trestných činů na obyvatele v jednotlivých krajích v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>  
                                            <p>Počet trestných činů na obyvatele se v jednotlivých krajích za <span
                                                className="emp"><strong>celé období</strong></span> pohyboval od <span
                                                className="emp"><strong>0,11570</strong></span> do <span
                                                className="emp"><strong>0,03702</strong></span>.
                                                Nejhorší bilanci měla <span className="emp"><strong>Praha</strong></span> a
                                                nejlepší <span className="emp"><strong>Pardubický kraj</strong></span>.
                                            </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.trestneCinyNaObyvateleSuma.values,
                                            getValue: meta.trestneCinyNaObyvateleSuma.getValue,
                                            formatValue: meta.trestneCinyNaObyvateleSuma.formatValue
                                        }
                                    }
                                },
                                {
                                    title: "Vývoj trestných činů na obyvatele v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                                Lze vidět mírně vzrůstající trend ve všech krajích s propadem v období onemocnění Covid-19.
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "line",
                                        config: {
                                            geo: geo,
                                            datasets: [
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.trestneCinyNaObyvatele20.values,
                                                    getValue: meta.trestneCinyNaObyvatele20.getValue,
                                                    formatValue: meta.trestneCinyNaObyvatele20.formatValue,
                                                    label: meta.trestneCinyNaObyvatele20.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.trestneCinyNaObyvatele21.values,
                                                    getValue: meta.trestneCinyNaObyvatele21.getValue,
                                                    formatValue: meta.trestneCinyNaObyvatele21.formatValue,
                                                    label: meta.trestneCinyNaObyvatele21.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.trestneCinyNaObyvatele22.values,
                                                    getValue: meta.trestneCinyNaObyvatele22.getValue,
                                                    formatValue: meta.trestneCinyNaObyvatele22.formatValue,
                                                    label: meta.trestneCinyNaObyvatele22.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.trestneCinyNaObyvatele23.values,
                                                    getValue: meta.trestneCinyNaObyvatele23.getValue,
                                                    formatValue: meta.trestneCinyNaObyvatele23.formatValue,
                                                    label: meta.trestneCinyNaObyvatele23.label
                                                },
                                            ]
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ kriminalita (rok 2023)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2023"
                                        },
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ kriminalita (rok 2022)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2022"
                                        },
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ kriminalita (rok 2021)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2021"
                                        },
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ kriminalita (rok 2020)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2020"
                                        },
                                        {
                                            ...meta.csuPeople,
                                            label: "ČSÚ - obyvatelstvo"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const genericPopup = {
    header: {
        component: PopupHeader,
        props: {
            data: data
        }
    },
    body: {
        component: PopupBodyChart,
        props: {
            data: data,
            stats: [
                {
                    title: "Srovnání dle vývoje počtu trestných činů na obyvatele v jednotlivých krajích v období 2020-2023",
                    content: () => {
                        return (
                            <>  
                                <p>Počet trestných činů na obyvatele v letech <span className="emp"><strong>2020–2023</strong></span> ve všech krajích vzrostl, a to o <span className="emp"><strong>0.00026</strong></span> až <span className="emp"><strong>0.00201</strong></span>. Největší nárůst byl v <span className="emp"><strong>Karlovarském kraji</strong></span> a nejmenší v <span className="emp"><strong>Pardubickém kraji</strong></span>.</p>
                            </>
                        )
                    },
                    chart: {
                        type: "bar",
                        config: {
                            geo: geo,
                            data: data,
                            categories: meta.trestneCinyNaObyvateleVyvoj.values,
                            getValue: meta.trestneCinyNaObyvateleVyvoj.getValue,
                            formatValue: meta.trestneCinyNaObyvateleVyvoj.formatValue
                        }
                    }
                },
                {
                    title: "Vývoj trestných činů na obyvatele v období 2020-2023",
                    content: () => {
                        return (
                            <>
                                Lze vidět mírně vzrůstající trend ve všech krajích s propadem v období onemocnění Covid-19.
                            </>
                        )
                    },
                    chart: {
                        type: "line",
                        config: {
                            geo: geo,
                            datasets: [
                                {
                                    geo: geo,
                                    data: data,
                                    categories: meta.trestneCinyNaObyvatele20.values,
                                    getValue: meta.trestneCinyNaObyvatele20.getValue,
                                    formatValue: meta.trestneCinyNaObyvatele20.formatValue,
                                    label: meta.trestneCinyNaObyvatele20.label
                                },
                                {
                                    geo: geo,
                                    data: data,
                                    categories: meta.trestneCinyNaObyvatele21.values,
                                    getValue: meta.trestneCinyNaObyvatele21.getValue,
                                    formatValue: meta.trestneCinyNaObyvatele21.formatValue,
                                    label: meta.trestneCinyNaObyvatele21.label
                                },
                                {
                                    geo: geo,
                                    data: data,
                                    categories: meta.trestneCinyNaObyvatele22.values,
                                    getValue: meta.trestneCinyNaObyvatele22.getValue,
                                    formatValue: meta.trestneCinyNaObyvatele22.formatValue,
                                    label: meta.trestneCinyNaObyvatele22.label
                                },
                                {
                                    geo: geo,
                                    data: data,
                                    categories: meta.trestneCinyNaObyvatele23.values,
                                    getValue: meta.trestneCinyNaObyvatele23.getValue,
                                    formatValue: meta.trestneCinyNaObyvatele23.formatValue,
                                    label: meta.trestneCinyNaObyvatele23.label
                                },
                            ]
                        }
                    }
                }
            ]
        }
    },
    footer: {
        component: PopupFooter,
        props: {
            data: data,
            settings: {
                left: {
                    title: {
                        label: "Zdroje:",
                        icon: "pi pi-fw pi-globe"
                    },
                    links: [
                        {
                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ kriminalita (rok 2023)",
                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2023"
                        },
                        {
                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ kriminalita (rok 2022)",
                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2022"
                        },
                        {
                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ kriminalita (rok 2021)",
                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2021"
                        },
                        {
                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ kriminalita (rok 2020)",
                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=KRI06&z=T&f=TABULKA&katalog=31008&str=v132&evo=v257_!_VUZEMI97-100mv_1&c=v3~8__RP2020"
                        },
                        {
                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ obyvatelstvo",
                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt-parametry&z=T&f=TABULKA&katalog=30845&sp=A&skupId=3809&pvo=DEMZU01a&u=v81__VUZEMI__100__3018&evo=v450_%21_IK-DEM-R-2030-2000-sestup_1&str=v191"
                        }
                    ]
                },
                right: {
                    title: undefined,
                    links: [
                        meta.route
                    ]
                }
            }
        }
    },
    style: {
        width: '80vw',
        height: '100vh',
        maxWidth: '1200px',
    }
}

const crimePerCitizen = {
    title: 'Počet trestných činů na obyvatele',
    widget: {
        type: "column-group",
        config: {
            widgets: [
                {
                    type: "custom",
                    config: {
                        content: (
                            <p>
                                Přepočet trestných činů na obyvatele je užitečný, protože poskytuje objektivnější pohled na <span className="emp"><strong>míru kriminality</strong> v&nbsp;různých regionech</span>. 
                                Místo pouhého počtu trestných činů umožňuje srovnání mezi kraji různé velikosti a&nbsp;odhaluje, jak často dochází ke kriminalitě ve vztahu k&nbsp;počtu obyvatel. 
                                Tento ukazatel je klíčový pro lepší pochopení bezpečnostní situace v&nbsp;jednotlivých regionech a může sloužit jako nástroj pro plánování bezpečnostních opatření nebo rozdělování policejních sil.                            </p>
                        )
                    }
                },
                {
                    type: "regional-filtered-choropleth",
                    config: {
                        id: "mapa-trestne-ciny-na-obyvatele",
                        label: "Počet trestných činů na obyvatele krajů",
                        geo: geo,
                        datasets: [
                            crimePerCitizenSum, crimePerCitizen23, crimePerCitizen22, crimePerCitizen21, crimePerCitizen20, crimePerCitizenAvg
                        ]
                    }
                },
                {
                    type: "custom",
                    config: {
                        content: (
                            <p>
                                Ve sledovaném období docházelo k&nbsp;nejvíce trestným činům na obyvatele v&nbsp;<span className="emp">Praze</span>, zatímco jako nejbezpečnější vyšel <span className="emp">kraj Pardubický</span>.
                            </p>
                        )
                    }
                }
            ]
        }
    },
    footer: {
        left: {
            title: {
                label: "Zdroje:",
                icon: "pi pi-fw pi-globe"
            },
            links: [
                {
                    label: "ČSÚ –⁠⁠⁠⁠⁠⁠ kriminalita",
                    value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt-parametry&z=T&f=TABULKA&katalog=31008&sp=A&pvo=KRI06&c=v3~8__RP2020&evo=v257_%21_VUZEMI97-100mv_1&str=v132"
                },
                {
                    label: "ČSÚ –⁠⁠⁠⁠⁠⁠ obyvatelstvo",
                    value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt-parametry&z=T&f=TABULKA&katalog=30845&sp=A&skupId=3809&pvo=DEMZU01a&u=v81__VUZEMI__100__3018&evo=v450_%21_IK-DEM-R-2030-2000-sestup_1&str=v191"
                }
            ]
        },
        right: {
            title: undefined,
            links: [
                meta.route
            ]
        },
        popup: {
            component: genericPopup
        }
    }
}
export default crimePerCitizen;