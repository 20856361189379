import React from "react";

import "./Image.css"

function Image({
    external = false,
    src = "/loga/logo_fit.png",
    alt = "",
    style = {},
    url = undefined
}) {
    
    return (
        <>
            <div className="image">
                {
                    url ? (
                        <a href={url} target="_blank" rel="noreferrer">
                            <img src={external ? src : process.env.PUBLIC_URL + src} alt={alt} style={style}></img>
                        </a>
                    ) : (
                        <img src={external ? src : process.env.PUBLIC_URL + src} alt={alt} style={style}></img>
                    )
                }
            </div>
        </>
    )
}

export default Image;