import { PrimeIcons } from 'primereact/api';
import React from 'react';
import { ButtonClose, ButtonDelete } from '../../../components/Buttons';
import { ModalDialog } from '../../../components/ModalDialog';

export const ConfirmDeleteMunicipalityDialog = ({ isVisible, onHide, handleDeleteMunicipality, municipality }) => {
    return (
        <ModalDialog
            visible={isVisible}
            header={'Potvrďte smazání'}
            style={{ width: 400 }}
            footer={
                <>
                    <ButtonClose label='Ne' onClick={onHide} />
                    <ButtonDelete label='Ano' onClick={handleDeleteMunicipality} />
                </>
            }
            onHide={onHide}
        >
            {municipality && (
                <div className='dialog-text'>
                    <span className='dialog-text__warning'>
                        <i className={PrimeIcons.EXCLAMATION_TRIANGLE} />
                        <span>
                            Opravdu chcete smazat municipalitu <b>{municipality.name}</b> (ID{' '}
                            <b>{municipality.text_id}</b>
                            )?
                        </span>
                    </span>
                    <span>Tato akce je nevratná!</span>
                </div>
            )}
        </ModalDialog>
    );
};
