import APP from "../config";
import { mesta } from "../municipality/mesta";
import data from "../stats/kraje/data";
import datatsets_default from "./default/datasets";
import datatsets_zastupko from "./zastupko/datasets";

const intro = (
    <>
        <p>
            V&nbsp;rámci tohoto projektu byla využita veřejně dostupná data z&nbsp;krajských zastupitelstev, která jsou zveřejňována v souladu se <span className="emp" style={{ color: "var(--primary-color)", fontSize: "larger" }}><strong>zákonem č.&nbsp;106/1999&nbsp;Sb., o&nbsp;svobodném přístupu k&nbsp;informacím</strong></span>, jenž ukládá veřejným institucím povinnost zpřístupňovat informace veřejnosti. 
            Dále tato data nejsou chráněna autorskými právy dle <span className="emp" style={{ color: "var(--primary-color)", fontSize: "larger" }}><strong>zákona č.&nbsp;89/2012&nbsp;Sb., občanského zákoníku</strong></span>, který stanoví, že úřední díla, jako jsou oficiální dokumenty, nepodléhají ochraně autorského práva, a&nbsp;mohou být proto volně využívána pro další zpracování a&nbsp;analýzy.
        </p>
        <p>
            Veškeré zdroje dat byly řádně uvedeny a&nbsp;jsou referovány napříč celou aplikací. Upozorňujeme, že <span className="emp" style={{ color: "var(--primary-color)", fontSize: "larger" }}><strong>nelze zaručit 100&nbsp;% správnost zpracovaných dat</strong></span>, neboť nelze vyloučit chyby předzpracování (např. při strojovém zpracování PDF souborů, ze kterých je možné extrahovat obsah).
            I&nbsp;v&nbsp;případě dostupnosti strojově zpracovatelných dat byly zjištěny problémy, které zahrnovaly např. chybějící hlasovací protokoly, neplatné odkazy na hlasovací protokoly, nebo ponechávání testovacích hlasování mezi validními hlasováními.
            Snažili jsme se o&nbsp;nahlašování těchto problémů, nicméně ne vždy bylo možné je ze strany samospráv rychle vyřešit.
            Naším cílem bylo především zhodnotit kvalitu dostupných dat, náročnost jejich zpracování a&nbsp;získání přehledu o&nbsp;aktuálním stavu.
        </p>
    </>
)

const regions = (
    <p>
        Kromě toho chceme upozornit na skutečnost, že v&nbsp;době rozvoje digitalizace a otevřených dat <span className="emp" style={{ color: "var(--primary-color)", fontSize: "larger" }}><strong>neexistuje ani jedna datová sada</strong></span>, kterou by bylo možné vzít jako celek, transformovat do našeho modelu a&nbsp;bez dalších komplikací data zobrazit v&nbsp;rámci našeho systému.
        Výjimkou je <span className="emp" style={{ color: "var(--primary-color)", fontSize: "larger" }}>datová sada hlavního města Prahy</span>, která však neobsahuje některé informace.
    </p>
)

const conclusion = (
    <p>
        Datové sady jsou k&nbsp;dispozici jako otevřené. Mohou být dále sdíleny a&nbsp;využívány v&nbsp;souladu s&nbsp;platnými zákony. 
        Při použití těchto sad uveďte jak původní zdroj dat, tak projekt Zastupko.
    </p>
)

const brno = (
    <p>
        Datové sady města Brna jsou publikovány pod licencí <span className="emp" style={{ color: "var(--primary-color)", fontSize: "larger" }}>CC BY 4.0</span>. Nově vytvořené datové sady jsou k&nbsp;dispozici jako otevřené. Mohou být dále sdíleny a&nbsp;využívány v&nbsp;souladu s&nbsp;licencí zvolenou městem Brno. 
        Při použití těchto sad uveďte jak původní zdroj dat, tak projekt Zastupko.
    </p>
)

const most = (
    <p>
        Datové sady města Mostu jsou publikovány v rámci Národního katalogu otevřených dat, v němž jsou specifikovány podmínky dalšího užití. Nově vytvořené datové sady jsou k&nbsp;dispozici jako otevřené. Mohou být dále sdíleny a&nbsp;využívány v&nbsp;souladu s&nbsp;podmínkami zvolenými městem Most. 
        Při použití těchto sad uveďte jak původní zdroj dat, tak projekt Zastupko.
    </p>
)

const praha = (
    <p>
        Datové sady hlavního města Prahy jsou publikovány v rámci Národního katalogu otevřených dat, v němž jsou specifikovány podmínky dalšího užití. Nově vytvořená datová sada je k&nbsp;dispozici jako otevřená. Může být dále sdílena a&nbsp;využívána v&nbsp;souladu s&nbsp;podmínkami zvolenými městem Praha. 
        Při použití této sady uveďte jak původní zdroj dat, tak projekt Zastupko.
    </p>
)

const getDatasets = (response) => {
    switch(APP.subdomain) {
        case "brno":
            return datatsets_default(response, {
                data: mesta.brno.organy.zastupitelstvo.varianty,
                licencesContent: brno,
                getLabel: (region) => region.nazev,
                getUrl: (region) => region.urlDataset,
                getSource: (region) => region.urlHlasovaciData,
                lastUpdate: (region) => region.posledniAktualizace,
                isComplete: (region) => region.uplnyDataset,
                getComment: (region) => region.datasetPoznamka
            });
        case "praha":
            return datatsets_default(response, {
                data: mesta.praha.organy.zastupitelstvo.varianty,
                licencesContent: praha,
                getLabel: (region) => region.nazev,
                getUrl: (region) => region.urlDataset,
                getSource: (region) => region.urlHlasovaciData,
                lastUpdate: (region) => region.posledniAktualizace,
                isComplete: (region) => region.uplnyDataset,
                getComment: (region) => region.datasetPoznamka
            });
        case "kraje":
            return datatsets_default(response, {
                data: data,
                licencesContent: (
                    <>
                        {intro} 
                        {regions} 
                        {conclusion}
                    </>
                ),
                getLabel: (region) => region.nazev,
                getUrl: (region) => region.urlDataset,
                getSource: (region) => region.urlHlasovaciData,
                lastUpdate: (region) => region.posledniAktualizace,
                isComplete: (region) => region.uplnyDataset,
                getComment: (region) => region.datasetPoznamka
            });
        case "mesta":
            return datatsets_default(response, {
                data: {
                    ...mesta.brno.organy.zastupitelstvo.varianty,
                    ...mesta.most.organy.zastupitelstvo.varianty,
                    ...mesta.most.organy.rada.varianty,
                    ...mesta.praha.organy.zastupitelstvo.varianty
                },
                licencesContent: (
                    <>
                        {intro}
                        {conclusion}
                    </>
                ),
                getLabel: (region) => region.nazev,
                getUrl: (region) => region.urlDataset,
                getSource: (region) => region.urlHlasovaciData,
                lastUpdate: (region) => region.posledniAktualizace,
                isComplete: (region) => region.uplnyDataset,
                getComment: (region) => region.datasetPoznamka
            });
        case "most":
            return datatsets_default(response, {
                data: {
                    ...mesta.most.organy.zastupitelstvo.varianty,
                    ...mesta.most.organy.rada.varianty,
                },
                licencesContent: most,
                getLabel: (region) => region.nazev,
                getUrl: (region) => region.urlDataset,
                getSource: (region) => region.urlHlasovaciData,
                lastUpdate: (region) => region.posledniAktualizace,
                isComplete: (region) => region.uplnyDataset,
                getComment: (region) => region.datasetPoznamka
            });
        case "":
            return datatsets_zastupko(response, (
                <>
                    {intro}
                    {conclusion}
                </>
            ));

        default:
            return datatsets_default(response, undefined);
    }
}

const datasets = (response) => getDatasets(response);
export default datasets;