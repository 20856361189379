import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { Column, Message } from "./PrimereactComponents";
import PaginatedDataTable from "./PaginatedDataTable";
import LoadingPage from "./LoadingPage";
import { redirectErrorPage } from "../utils/errors";
import { getBodyNameGenitive } from '../utils/texts';
import { useTranslation } from "react-i18next";

function PollsTable(props) {
  const [tableData, setTableData] = useState(undefined);
  const [selectedRow, setSelectedRow] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const [makingRequest, setMakingRequest] = useState(false);
  const [error, setError] = useState(false);
  const [session, setSession] = useState(props.session);
  const [member, setMember] = useState(props.member);

  const prevViewRef = useRef(props.view);
  const prevSessionRef = useRef(props.session);
  const prevPartyRef = useRef(props.party);
  const prevMemberRef = useRef(props.member);
  const prevSessionResultRef = useRef(props.sessionResult);
  const prevVoteResultRef = useRef(props.voteResult);
  const prevCheckedRef = useRef(props.checked);
  const prevCheckedProcRef = useRef(props.checkedProc);
  const prevCheckedPublicVotingsRef = useRef(props.checkedPublicVotings);
  const prevTextRef = useRef(props.text);

  const {t} = useTranslation(['translation', 'votings']);

  useEffect(() => {
    setSession(props.session);
    setMember(props.member);
  }, [props.session, props.member]);

  useEffect(() => {
    prevMemberRef.current = member;
    prevSessionRef.current = session;
    prevViewRef.current = props.view;
    prevPartyRef.current = props.party;
    prevSessionResultRef.current = props.sessionResult;
    prevVoteResultRef.current = props.voteResult;
    prevCheckedRef.current = props.checked;
    prevCheckedProcRef.current = props.checkedProc;
    prevCheckedPublicVotingsRef.current = props.checkedPublicVotings;
    prevTextRef.current = props.text;
  }, [
    props.view,
    session,
    props.party,
    member,
    props.sessionResult,
    props.voteResult,
    props.checked,
    props.checkedProc,
    props.checkedPublicVotings,
    props.text,
  ]);

  const prevView = prevViewRef.current;
  const prevSession = prevSessionRef.current;
  const prevParty = prevPartyRef.current;
  const prevMember = prevMemberRef.current;
  const prevSessionResult = prevSessionResultRef.current;
  const prevVoteResult = prevVoteResultRef.current;
  const prevChecked = prevCheckedRef.current;
  const prevCheckedProc = prevCheckedProcRef.current;
  const prevCheckedPublicVotings = prevCheckedPublicVotingsRef.current;
  const prevText = prevTextRef.current;

  const onOptionChange = (e) => {
    setSelectedRow(e.value);
    const baseUrl = process.env.REACT_APP_BASE_URL;
	  const url = baseUrl + `${props.municipalitaId}/${props.bodyType}/${props.zastupitelstvoId}/hlasovani/${e.value.id_hlasovani}`;
    window.open(url, "_blank");
  };

  

  const apiCallWaiting = () => {
    // zde bude api call
    if (props.view === "text" && props.text !== "") {
      apiCallGeneral("pohled=" + props.view + "&text=" + props.text);
      setMakingRequest(false);
    }
    setWaiting(false);
  };

  const apiCallGeneral = (url) => {
    setTableData(undefined);
    setMakingRequest(true);

    try {
      axios
        .get(
            `${props.municipalitaId}/${props.bodyType}/${props.zastupitelstvoId}/votes?` +
            url,
        )
        .then((response) => {
          if (props.view === "subjekt" && props.party !== null) {
            setTableData(response.data.data);
            setMakingRequest(false);
          } else {
            setTableData(response.data);
            setMakingRequest(false);
          }
        });
    } catch (error) {
      setError(error);
      redirectErrorPage(error);
    }
  };

  const sessionRequest = () => {
    let url = "pohled=" + props.view + "&zasedani=" + session.code;
    if (props.checked === true) {
      url += "&pouze_platna=true";
    }
    if (props.checkedProc === true) {
      url += "&neproceduralni=true";
    }
    if (props.checkedPublicVotings) {
      url += "&verejna=true";
    }
    if (props.sessionResult !== null) {
      url += "&vysledek_hlasovani=" + props.sessionResult.code;
    }
    apiCallGeneral(url);
  };

  const partyRequest = () => {
    let url = "pohled=" + props.view + "&subjekt=" + props.party.code;
    if (props.checked === true) {
      url += "&pouze_platna=true";
    }
    if (props.checkedProc === true) {
      url += "&neproceduralni=true";
    }
    if (props.voteResult !== null) {
      url += "&hlasovaci_moznost=" + props.voteResult.code;
    }
    apiCallGeneral(url);
  };

  const memberRequest = () => {
    let url = "pohled=" + props.view + "&clen=" + member.code;
    if (props.checked === true) {
      url += "&pouze_platna=true";
    }
    if (props.checkedProc === true) {
      url += "&neproceduralni=true";
    }
    if (props.voteResult !== null) {
      url += "&hlasovaci_moznost=" + props.voteResult.code;
    }
    apiCallGeneral(url);
  };

  const chooseRequest = useCallback(() => {
    // pohled ZASEDANI
    if (props.view === "zasedani" && session !== null) {
      sessionRequest();
    }

    // pohled SUBJEKT
    if (props.view === "subjekt" && props.party !== null) {
      partyRequest();
    }

    // pohled CLEN
    if (props.view === "clen" && member !== null) {
      memberRequest();
    }

    // pohled HLEDANY TEXT
    if (props.view === "text" && props.text !== "") {
      apiCallGeneral("pohled=" + props.view + "&text=" + props.text);
    }
  }, []);

  useEffect(() => {
    (async () => {
      chooseRequest();
    })();
  }, [chooseRequest]);

  useEffect(() => {
    window.onpopstate = (e) => {
      setTableData(undefined);
      chooseRequest();
    };

    // zmenil se pohled => nutno smazat nactena data
    if (props.view !== prevView) {
      setTableData(undefined);
      setMakingRequest(false);
    }

    // zmenilo se cislo zasedani
    if (session !== prevSession) {
      // unset cisla zasedani => nutno smazat nactena data
      if (session === undefined) {
        setSession(null);
        setTableData(undefined);
      }
      // pohled ZASEDANI
      if (props.view === "zasedani" && session) {
        sessionRequest();
      }
    }

    // zmenilo se id subjektu
    if (props.party !== prevParty) {
      // pohled SUBJEKT
      if (props.view === "subjekt" && props.party) {
        partyRequest();
      }
    }

    // zmenilo se id clena
    if (member !== prevMember) {
      // unset id clena => nutno smazat nactena data
      if (member === undefined) {
        setMember(null);
        setTableData(undefined);
      }
      // pohled CLEN
      if (props.view === "clen" && member) {
        memberRequest();
      }
    }

    // zmenil se vysledek hlasovani
    if (props.sessionResult !== prevSessionResult) {
      // pohled ZASEDANI
      if (props.view === "zasedani" && session) {
        sessionRequest();
      }
      // pohled SUBJEKT
      if (props.view === "subjekt" && props.party) {
        partyRequest();
      }
    }

    // zmenil se vysledek hlasovani clena
    if (props.voteResult !== prevVoteResult) {
      // pohled SUBJEKT
      if (props.view === "subjekt" && props.party) {
        partyRequest();
      }
      // pohled CLEN
      if (props.view === "clen" && member) {
        memberRequest();
      }
    }

    // zmenil se checkbox validni hladovani
    if (props.checked !== prevChecked) {
      // pohled ZASEDANI
      if (props.view === "zasedani" && session) {
        sessionRequest();
      }
      // pohled SUBJEKT
      if (props.view === "subjekt" && props.party) {
        partyRequest();
      }
      // pohled CLEN
      if (props.view === "clen" && member) {
        memberRequest();
      }
    }

    // zmenil se checkbox neproceduralni hladovani
    if (props.checkedProc !== prevCheckedProc) {
      // pohled ZASEDANI
      if (props.view === "zasedani" && session) {
        sessionRequest();
      }
      // pohled SUBJEKT
      if (props.view === "subjekt" && props.party) {
        partyRequest();
      }
      // pohled CLEN
      if (props.view === "clen" && member) {
        memberRequest();
      }
    }

    // the checkbox public votings has changed
    if (props.checkedPublicVotings !== prevCheckedPublicVotings) {
      // pohled ZASEDANI
      if (props.view === "zasedani" && session) {
        sessionRequest();
      }
      // pohled SUBJEKT
      if (props.view === "subjekt" && props.party) {
        partyRequest();
      }
      // pohled CLEN
      if (props.view === "clen" && member) {
        memberRequest();
      }
    }

    // zmenil se text => nutnost cekat
    if (props.text !== prevText) {
      if (props.view === "text" && waiting === false) {
        setTableData(undefined);
        setMakingRequest(true);
        setTimeout(() => {
          apiCallWaiting();
        }, 3000);
      }
      if (props.view === "text") {
        setWaiting(true);
        // nove prazdny retezec
        if (props.text === "") {
          setMakingRequest(false);
        }
      }
    }
  }, [
    props.view,
    session,
    props.party,
    member,
    props.sessionResult,
    props.voteResult,
    props.checked,
    props.checkedProc,
    props.checkedPublicVotings,
    props.text,
  ]);

  const resultBodyTemplate = (rowData) => {
    if (rowData.vysledek === "přijato") {
      return (
          <i className={"w100 text__center bi bi-check-square polls-table__icon-accepted"}></i>
      );
    }
    return (
        <i className={"w100 text__center bi bi-x-square polls-table__icon-rejected"}></i>
    );
  };

  const descriptionBodyTemplate = (rowData) => {
    return (
        <div className="description-cell">{rowData.predmet_hlasovani}</div>
    );
  };  

  var columns = [
    <Column className="w10" field="cislo_hlasovani" header={t("voting-number")}></Column>,
    <Column
      className="w90 lh4"
      field="predmet_hlasovani"
      header={t("voting-description")}
      body={descriptionBodyTemplate}
    ></Column>,<Column className="w5" sortable field="vysledek" header={t("result")} body={resultBodyTemplate}></Column>];
    
  var message = '';
  if(tableData !== undefined && tableData.length > 0){ 
    switch (props.view) {
      case "zasedani":
        if(session !== null && session !== undefined){
          columns = [
            <Column className="w10" field="cislo_hlasovani" header={t("voting-number")}></Column>,
            <Column
              className="w90 lh4"
              field="predmet_hlasovani"
              header={t("voting-description")}
              body={descriptionBodyTemplate}
            ></Column>,<Column className="w5" sortable field="vysledek" header={t("result")} body={resultBodyTemplate}></Column>]
          ;} else {
            message = <Message severity="info" text={t("msg-3", {ns: "votings"})} />
          }
        break;
        case "subjekt":
          if(props.party !== null && props.party !== undefined){
          columns = [
            <Column
              className="w10"
              field="cislo_zasedani"
              header={t("session-number", {ns: "votings"})}
              sortable
            ></Column>,
            <Column className="w10" field="cislo_hlasovani" header={t("voting-number")}></Column>,
            <Column
              className="w90 lh4 truncate-overflow"
              field="predmet_hlasovani"
              body={descriptionBodyTemplate}
              header={t("voting-description")}
            ></Column>,<Column className="w5" sortable field="vysledek" header={t("result")} body={resultBodyTemplate}></Column>
            ]}
            else{
              message = <Message severity="info" text={t("msg-4", {ns: "votings"})} />
            }
          break;
        case "clen":
          if(member !== null && member !== undefined){ columns = [
            <Column
              field="cislo_zasedani"
              className="w10"
              header={t("session-number", {ns: "votings"})}
              sortable
            ></Column>,
            <Column field="cislo_hlasovani" header={t("voting-number")}></Column>,
            <Column
              className="w90 lh4 truncate-overflow"
              field="predmet_hlasovani"
              body={descriptionBodyTemplate}
              header={t("voting-description")}
            ></Column>,<Column className="w5" sortable field="vysledek" header={t("result")} body={resultBodyTemplate}></Column>
            ]}
          else{
            message = <Message severity="info" text={t("msg-5", {ns: "votings", what: getBodyNameGenitive(props.bodyType)})} />
          }
          break;
        case "text":
          if (props.text !== null && props.text !== undefined && props.text.length > 0) {
            columns = [
            <Column
              field="cislo_zasedani"
              className="w10"
              header={t("session-number", {ns: "votings"})}
              sortable
            ></Column>,
            <Column className="w10" field="cislo_hlasovani" header={t("voting-number")}></Column>,
            <Column
              className="w70 lh4 truncate-overflow"
              field="predmet_hlasovani"
              body={descriptionBodyTemplate}
              header={t("voting-description")}
            ></Column>,<Column className="w5" sortable field="vysledek" header={t("result")} body={resultBodyTemplate}></Column>,
          ]
          ;} else{
            message = <Message severity="info" text={t("msg-6", {ns: "votings"})} />
          }
          break;
        default:
          message = <Message severity="info" text={t("msg-1", {ns: "votings"})} />
          break;
   }
  } else {
    message = <Message severity="info" text={t("msg-2", {ns: "votings"})} />
  }

  return (
    <>
      {makingRequest === true ? <LoadingPage /> : ""}
      {columns && (
          <PaginatedDataTable
            data={tableData}
            selection={selectedRow}
            onSelectionChange={onOptionChange}
            emptyMessage={message}
          >
            {columns.map((column) => (
              column
            ))  
            }
          </PaginatedDataTable>
      )}
    </>
  );
}

export default PollsTable;
