import React from "react";

import "./Header.css"

function Header({
    id=undefined,
    sublabel = undefined,
    image = {
        large: undefined,
        small: undefined,
    },
    style = {},
}) {

    /*const getSublabel = () => {
        return sublabel;
    }*/

    return (
        <>
            <header className='header' id={id} style={style}>
                { image?.large && <img className="header-logo-large" src={ process.env.PUBLIC_URL + image.large } alt="Zastupko"></img> }
                { image?.small && <img className="header-logo-small" src={ process.env.PUBLIC_URL + image.small } alt="Zastupko"></img> }
            </header>
        </>
    )
}

export default Header;