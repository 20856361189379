import React from "react";

import "./DetailRef.css"

function DetailRef({
    url = "#",
    label = "Dozvědět se více",
}) {

    return (
        <>
            
            {
                // section detail url
                <a href={url} className="detail-ref">
                    <span>{label}</span>
                    <i className="pi pi-angle-right"></i>
                </a>
            }
        </>
    )
}

export default DetailRef;