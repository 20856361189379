import PopupBodyChart from "../../../../components/map/popup/PopupBodyChart";
import PopupFooter from "../../../../components/map/popup/PopupFooter";
import PopupHeader from "../../../../components/map/popup/PopupHeader";
import APP from "../../../config";
import data from "../data";
import geo from "../geo";
import meta from "../meta";

/**
 * Share of leaders statistics.
 * 
 * @author Adam Janosik, Kristyna Zaklova
 */

const leaderGenderStats = {
    title: 'Lídři krajů',
    content: () => {
        return (
            <>
                V čele krajů stojí <span className="emp">hejtmani (ev. hejtmanky)</span>, kteří jsou nejvyššími představiteli krajské samosprávy. 
                Jsou voleni krajským zastupitelstvem a&nbsp;jejich hlavní funkcí je řídit krajskou radu, koordinovat činnost zastupitelstva a&nbsp;reprezentovat kraj navenek. 
                Hejtmani nesou odpovědnost za rozpočet kraje, jeho hospodaření a&nbsp;za zajištění veřejných služeb. 
                <br></br><br></br>
                Zastoupení mužů a&nbsp;žen v&nbsp;čele krajů, vykazuje tradičně nerovnováhu ve prospěch mužů. 
                Funkční období <span className="emp"><strong>2020-2024</strong></span> tuto skutečnost potvrzuje, pouze <span className="emp"><strong>1</strong>&nbsp;žena</span> vykonává funkci hejtmanky.
            </>
        )
    },
    chart: {
        id: "mapa-pohlavi-lidr",
        type: "regional-choropleth",
        config: {
            geo: geo,
            data: data,
            categories: meta.pohlaviLidr.values,
            getValue: meta.pohlaviLidr.getValue,
            formatValue: meta.pohlaviLidr.formatValue,
            map: {
                defaults: {
                    polygon: {
                        hoverStyle: {
                            fill: "yellow"
                        },
                    },
                    capital: {
                        style: {
                            display: "none"
                        }
                    }
                }
            },
        }
    },
    footer: {
        left: {
            title: {
                label: "Zdroj:",
                icon: "pi pi-fw pi-globe"
            },
            links: [
                {
                    label: "Datové sady",
                    value: `${APP.protocol}://kraje.${APP.domain}${APP.port && ':' + APP.port}/datasety`
                }
            ]
        },
        right: {
            title: undefined,
            links: [
                meta.route
            ]
        }
    }
}
export default leaderGenderStats;