import { FileUpload } from 'primereact/fileupload';
import { Image } from 'primereact/image';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { logoUrl } from '../../utils/logoUrl';
import { getFormErrorMessage, maxLengthMessage } from '../utils/errors';
import { ButtonAdd, ButtonEdit } from './Buttons';
import { FormContainer, FormInput, FormRow, Label } from './form';
import { LineDivider } from './LineDivider';
import { RHFInputText, RHFDropdown, RHFInputNumber, RHFInputTextarea, RHFSketchPicker, RHFCheckbox } from './rhf';
import { DEFAULT_COLOR } from '../constants/constants';
import { MUNICIPALITA, ORGAN, ZADOST_MUNICIPALITA_NOVA } from '../constants/field_lengths';

export const MunicipalityForm = forwardRef(
    ({ municipalityTypeOptions, onSubmit, editMunicipality, request, enablePartner }, ref) => {
        const [processing, setProcessing] = useState(false);

        useImperativeHandle(ref, () => ({
            resetLogo() {
                refFileInput?.current?.clear();
                setValue('logo', null);
            }
        }));

        const refFileInput = useRef(null);

        const MIN_POCET_ZASTUPITELU = 5;
        const MAX_POCET_ZASTUPITELU = 70;
        const accept = ['image/png', 'image/jpeg', 'image/svg+xml'];
        const MAX_FILE_SIZE = 1000 * 1000;

        const defaultValues = {
            text_id: '',
            municipality_type: null,
            name: '',
            name_genitive: '',
            color: DEFAULT_COLOR,
            url: '',
            email: '',
            zastupitelstvo_application_name: '',
            zastupitelstvo_name: '',
            zastupitelstvo_name_genitive: '',
            zastupitelstvo_place: '',
            zastupitelstvo_member_count: MIN_POCET_ZASTUPITELU,
            zastupitelstvo_application_description: ''
        };

        const edit = !!editMunicipality;
        const hasLogo = edit && editMunicipality.logo;

        if (!request) {
            defaultValues.partner = false;
        }

        if (edit) {
            Object.keys(defaultValues).forEach((key) => {
                if (editMunicipality[key]) {
                    defaultValues[key] = editMunicipality[key];
                }
            });
        }

        if (request) {
            defaultValues.text = '';
        }

        const {
            control,
            formState: { errors, isSubmitting },
            handleSubmit,
            setValue
        } = useForm({
            disabled: processing,
            defaultValues: defaultValues
        });

        useEffect(() => {
            setProcessing(isSubmitting);
        }, [isSubmitting]);

        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormContainer>
                    {request && (
                        <>
                            <FormRow>
                                <FormInput width='lg'>
                                    <Label htmlFor='text' text='Text žádosti' />
                                    <RHFInputTextarea
                                        control={control}
                                        name='text'
                                        rules={{
                                            maxLength: {
                                                value: ZADOST_MUNICIPALITA_NOVA.TEXT,
                                                message: maxLengthMessage(ZADOST_MUNICIPALITA_NOVA.TEXT)
                                            }
                                        }}
                                        placeholder='...'
                                    />
                                    {getFormErrorMessage(errors, 'text')}
                                </FormInput>
                            </FormRow>
                            <LineDivider />
                            <FormRow type='heading'>Municipalita</FormRow>
                        </>
                    )}
                    <FormRow>
                        <FormInput>
                            <Label htmlFor='name' text='Název municipality' required />
                            <RHFInputText
                                control={control}
                                name='name'
                                rules={{
                                    required: 'Vyplňte toto pole',
                                    maxLength: {
                                        value: MUNICIPALITA.NAZEV,
                                        message: maxLengthMessage(MUNICIPALITA.NAZEV)
                                    }
                                }}
                                placeholder='např. Brno'
                            />
                            {getFormErrorMessage(errors, 'name')}
                        </FormInput>
                        <FormInput>
                            <Label htmlFor='name_genitive' text='Název municipality (2. pád)' required />
                            <RHFInputText
                                control={control}
                                name='name_genitive'
                                rules={{
                                    required: 'Vyplňte toto pole',
                                    maxLength: {
                                        value: MUNICIPALITA.NAZEV_GENITIV,
                                        message: maxLengthMessage(MUNICIPALITA.NAZEV_GENITIV)
                                    }
                                }}
                                placeholder='např. Brna'
                            />
                            {getFormErrorMessage(errors, 'name_genitive')}
                        </FormInput>
                        <FormInput>
                            <Label htmlFor='text_id' text='Textové ID' required />
                            <RHFInputText
                                control={control}
                                name='text_id'
                                rules={{
                                    required: 'Vyplňte toto pole',
                                    pattern: {
                                        value: /^[a-zA-Z\-_]+$/,
                                        message: 'ID může obsahovat pouze malá a velká písmena, spojovníky a podtržítka'
                                    },
                                    maxLength: {
                                        value: MUNICIPALITA.TEXT_ID,
                                        message: maxLengthMessage(MUNICIPALITA.TEXT_ID)
                                    }
                                }}
                                disabled={edit}
                                placeholder='např. brno'
                                inputProps={{
                                    title: 'Může obsahovat pouze malá a velká písmena, spojovníky a podtržítka'
                                }}
                            />
                            {getFormErrorMessage(errors, 'text_id')}
                        </FormInput>
                        <FormInput>
                            <Label htmlFor='municipality_type' text='Typ municipality' required />
                            <RHFDropdown
                                name='municipality_type'
                                control={control}
                                rules={{ required: 'Vyplňte toto pole' }}
                                placeholder='Zvolte typ municipality'
                                inputProps={{ options: municipalityTypeOptions, showClear: false }}
                            />
                            {getFormErrorMessage(errors, 'municipality_type')}
                        </FormInput>
                    </FormRow>
                    <FormRow>
                        <FormInput width='lg'>
                            <Label htmlFor='url' text='Webové stránky municipality' required />
                            <RHFInputText
                                control={control}
                                name='url'
                                rules={{
                                    required: 'Vyplňte toto pole',
                                    maxLength: { value: MUNICIPALITA.URL, message: maxLengthMessage(MUNICIPALITA.URL) }
                                }}
                                placeholder='např. https://www.brno.cz/'
                            />
                            {getFormErrorMessage(errors, 'url')}
                        </FormInput>
                        <FormInput>
                            <Label htmlFor='email' text='Kontaktní e-mail' />
                            <RHFInputText
                                control={control}
                                name='email'
                                rules={{
                                    pattern: {
                                        value: /^\S+@\S+\.\S+$/,
                                        message: 'Zadejte e-mailovou adresu ve správném formátu.'
                                    },
                                    maxLength: {
                                        value: MUNICIPALITA.EMAIL,
                                        message: maxLengthMessage(MUNICIPALITA.EMAIL)
                                    }
                                }}
                                placeholder='email@domena.cz'
                            />
                            {getFormErrorMessage(errors, 'email')}
                        </FormInput>
                        {!request && (
                            <FormInput type='checkbox'>
                                <Label htmlFor='partner' text='Partner' />
                                <RHFCheckbox control={control} name='partner' disabled={edit && !enablePartner} />
                            </FormInput>
                        )}
                    </FormRow>
                    <FormRow>
                        <FormInput>
                            <Label htmlFor='color' text='Barva' required />
                            <RHFSketchPicker control={control} name='color' rules={{ required: 'Vyplňte toto pole' }} />
                            {getFormErrorMessage(errors, 'color')}
                        </FormInput>
                        {!request && (
                            <FormInput width='lg'>
                                <Label htmlFor='logo' text='Logo' />
                                <Controller
                                    name='logo'
                                    control={control}
                                    render={({ field }) => (
                                        <FileUpload
                                            name={field.name}
                                            ref={refFileInput}
                                            accept={accept.join(',')}
                                            maxFileSize={MAX_FILE_SIZE}
                                            url=''
                                            uploadHandler={() => {}}
                                            customUpload
                                            headerTemplate={(options) => {
                                                const { className, chooseButton, cancelButton } = options;

                                                return (
                                                    <div className={className}>
                                                        {chooseButton}
                                                        {cancelButton}
                                                    </div>
                                                );
                                            }}
                                            className='image-input'
                                            emptyTemplate={
                                                <>
                                                    {hasLogo && (
                                                        <Image
                                                            src={logoUrl(
                                                                editMunicipality.text_id,
                                                                editMunicipality.logo
                                                            )}
                                                            alt='Logo'
                                                        />
                                                    )}
                                                    <div>
                                                        <i className='bi bi-upload'></i>{' '}
                                                        <span>
                                                            {hasLogo
                                                                ? 'Přetáhněte obrázek s logem pro nahrazení současného.'
                                                                : 'Přetáhněte obrázek s logem.'}
                                                        </span>
                                                    </div>
                                                </>
                                            }
                                            onBeforeDrop={(event) => {
                                                const type = event.dataTransfer.files[0].type;
                                                if (!accept.includes(type)) {
                                                    return false;
                                                }
                                            }}
                                            onSelect={(event) => field.onChange(event.files[0])}
                                            onRemove={() => field.onChange(null)}
                                            onClear={() => field.onChange(null)}
                                            invalidFileSizeMessageSummary='{0}: Soubor je příliš velký, '
                                            invalidFileSizeMessageDetail='maximální velikost je {0}.'
                                            chooseLabel='Vyberte soubor'
                                            cancelLabel='Zrušit'
                                        />
                                    )}
                                />
                            </FormInput>
                        )}
                    </FormRow>
                    <LineDivider />
                    <FormRow type='heading'>Zastupitelstvo</FormRow>
                    <FormRow>
                        <FormInput width='lg'>
                            <Label htmlFor='zastupitelstvo_name' text='Název' required />
                            <RHFInputText
                                control={control}
                                name='zastupitelstvo_name'
                                rules={{
                                    required: 'Vyplňte toto pole',
                                    maxLength: {
                                        value: ORGAN.NAZEV,
                                        message: maxLengthMessage(ORGAN.NAZEV)
                                    }
                                }}
                                placeholder='např. Zastupitelstvo města Brna'
                            />
                            {getFormErrorMessage(errors, 'zastupitelstvo_name')}
                        </FormInput>
                        <FormInput width='lg'>
                            <Label htmlFor='zastupitelstvo_name_genitive' text='Název (2. pád)' required />
                            <RHFInputText
                                control={control}
                                name='zastupitelstvo_name_genitive'
                                rules={{
                                    required: 'Vyplňte toto pole',
                                    maxLength: {
                                        value: ORGAN.NAZEV_GENITIV,
                                        message: maxLengthMessage(ORGAN.NAZEV_GENITIV)
                                    }
                                }}
                                placeholder='např. Zastupitelstva města Brna'
                            />
                            {getFormErrorMessage(errors, 'zastupitelstvo_name_genitive')}
                        </FormInput>
                    </FormRow>
                    <FormRow>
                        <FormInput width='lg'>
                            <Label htmlFor='zastupitelstvo_application_name' text='Název aplikace' required />
                            <RHFInputText
                                control={control}
                                name='zastupitelstvo_application_name'
                                rules={{
                                    required: 'Vyplňte toto pole',
                                    maxLength: {
                                        value: ORGAN.NAZEV_APLIKACE,
                                        message: maxLengthMessage(ORGAN.NAZEV_APLIKACE)
                                    }
                                }}
                                placeholder='např. Vizualizace hlasování Zastupitelstva města Brna'
                            />
                            {getFormErrorMessage(errors, 'zastupitelstvo_application_name')}
                        </FormInput>
                        <FormInput>
                            <Label htmlFor='zastupitelstvo_member_count' text='Počet zastupitelů' required />
                            <RHFInputNumber
                                control={control}
                                name='zastupitelstvo_member_count'
                                rules={{
                                    required: 'Vyplňte toto pole',
                                    min: {
                                        value: MIN_POCET_ZASTUPITELU,
                                        message: `Minimální počet zastupitelů je ${MIN_POCET_ZASTUPITELU}`
                                    },
                                    max: {
                                        value: MAX_POCET_ZASTUPITELU,
                                        message: `Maximální počet zastupitelů je ${MAX_POCET_ZASTUPITELU}`
                                    }
                                }}
                            />
                            {getFormErrorMessage(errors, 'zastupitelstvo_member_count')}
                        </FormInput>
                    </FormRow>
                    <FormRow>
                        <FormInput width='lg'>
                            <Label htmlFor='zastupitelstvo_application_description' text='Popis aplikace' required />
                            <RHFInputTextarea
                                control={control}
                                name='zastupitelstvo_application_description'
                                rules={{
                                    required: 'Vyplňte toto pole',
                                    maxLength: {
                                        value: ORGAN.POPIS_APLIKACE,
                                        message: maxLengthMessage(ORGAN.POPIS_APLIKACE)
                                    }
                                }}
                                placeholder='např. Přehledné vizualizace z 8. a 9. Zastupitelstva města Brna pro širokou veřejnost.'
                            />
                            {getFormErrorMessage(errors, 'zastupitelstvo_application_description')}
                        </FormInput>
                        <FormInput width='lg'>
                            <Label htmlFor='zastupitelstvo_place' text='Místo konání zasedání' />
                            <RHFInputText
                                control={control}
                                name='zastupitelstvo_place'
                                rules={{
                                    maxLength: {
                                        value: ORGAN.MISTO,
                                        message: maxLengthMessage(ORGAN.MISTO)
                                    }
                                }}
                                placeholder='např. v zasedací síni Nové radnice'
                            />
                            {getFormErrorMessage(errors, 'zastupitelstvo_place')}
                        </FormInput>
                    </FormRow>
                    <LineDivider />
                    <FormRow type='button'>
                        {edit ? (
                            <ButtonEdit type='submit' label='Upravit' disabled={processing} loading={processing} />
                        ) : (
                            <ButtonAdd
                                type='submit'
                                label={request ? 'Podat žádost' : 'Vytvořit municipalitu'}
                                disabled={processing}
                                loading={processing}
                            />
                        )}
                    </FormRow>
                </FormContainer>
            </form>
        );
    }
);
