import PopupBodyChart from "../../../../components/map/popup/PopupBodyChart";
import PopupFooter from "../../../../components/map/popup/PopupFooter";
import PopupHeader from "../../../../components/map/popup/PopupHeader";
import data from "../data";
import geo from "../geo";
import meta from "../meta";

/**
 * saldo statistics.
 * 
 * @author Adam Janosik, Kristyna Zaklova
 */
const saldo23 = {
    label: "Rok 2023",
    data: data,
    categories: meta.saldoKraju.values,
    getValue: meta.saldoKraju.getValue,
    formatValue: meta.saldoKraju.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle salda jednotlivých krajů (rok 2023)",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Saldo příjmů a výdajů jednotlivých krajů se v roce <span
                                                className="emp"><strong>2023</strong></span> pohybovalo od <span
                                                className="emp"><strong>-704&nbsp;517</strong> Kč</span> do <span
                                                className="emp"><strong>29&nbsp;269&nbsp;053</strong> Kč</span>.
                                                Nejhorší bilanci měl <span className="emp"><strong>Karlovarský kraj</strong></span> a
                                                nejlepší <span className="emp"><strong>Praha</strong></span>.
                                            </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.saldoKraju.values,
                                            getValue: meta.saldoKraju.getValue,
                                            formatValue: meta.saldoKraju.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroj:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů (rok 2023)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2023"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}


const saldo22 = {
    label: "Rok 2022",
    data: data,
    categories: meta.saldoKraju22.values,
    getValue: meta.saldoKraju22.getValue,
    formatValue: meta.saldoKraju22.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle salda jednotlivých krajů (rok 2022)",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Saldo příjmů a výdajů jednotlivých krajů se v roce <span
                                                className="emp"><strong>2022</strong></span> pohybovalo od <span
                                                className="emp"><strong>-1&nbsp;030&nbsp;532</strong> Kč</span> do <span
                                                className="emp"><strong>15&nbsp;513&nbsp;008</strong> Kč</span>.
                                                Nejhorší bilanci měl <span className="emp"><strong>kraj Vysočina</strong></span> a
                                                nejlepší <span className="emp"><strong>Praha</strong></span>.
                                            </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.saldoKraju22.values,
                                            getValue: meta.saldoKraju22.getValue,
                                            formatValue: meta.saldoKraju22.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroj:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů (rok 2022)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2022"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const saldo21 = {
    label: "Rok 2021",
    data: data,
    categories: meta.saldoKraju21.values,
    getValue: meta.saldoKraju21.getValue,
    formatValue: meta.saldoKraju21.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle salda jednotlivých krajů (rok 2021)",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Saldo příjmů a výdajů jednotlivých krajů se v roce <span
                                                className="emp"><strong>2021</strong></span> pohybovalo od <span
                                                className="emp"><strong>183&nbsp;172</strong> Kč</span> do <span
                                                className="emp"><strong>15&nbsp;987&nbsp;052</strong> Kč</span>.
                                                Nejhorší bilanci měl <span className="emp"><strong>Pardubický kraj</strong></span> a
                                                nejlepší <span className="emp"><strong>Praha</strong></span>.
                                            </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.saldoKraju21.values,
                                            getValue: meta.saldoKraju21.getValue,
                                            formatValue: meta.saldoKraju21.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroj:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů (rok 2021)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2021"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const saldo20 = {
    label: "Rok 2020",
    data: data,
    categories: meta.saldoKraju20.values,
    getValue: meta.saldoKraju20.getValue,
    formatValue: meta.saldoKraju20.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle salda jednotlivých krajů (rok 2020)",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Saldo příjmů a výdajů jednotlivých krajů se v roce <span
                                                className="emp"><strong>2020</strong></span> pohybovalo od <span
                                                className="emp"><strong>-1&nbsp;103&nbsp;912</strong> Kč</span> do <span
                                                className="emp"><strong>8&nbsp;906&nbsp;470</strong> Kč</span>.
                                                Nejhorší bilanci měl <span className="emp"><strong>Královéhradecký kraj</strong></span> a
                                                nejlepší <span className="emp"><strong>Praha</strong></span>.
                                            </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.saldoKraju20.values,
                                            getValue: meta.saldoKraju20.getValue,
                                            formatValue: meta.saldoKraju20.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroj:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů (rok 2020)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2020"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}


const saldoSum = {
    label: "Suma za období 2020-2023",
    data: data,
    categories: meta.saldoKrajuSum.values,
    getValue: meta.saldoKrajuSum.getValue,
    formatValue: meta.saldoKrajuSum.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle sumy salda příjmů a výdajů jednotlivých krajů v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Suma salda jednotlivých krajů v letech <span className="emp"><strong>2020-2023</strong></span> značí součet všech bilancí příjmů a výdajů krajů v daném období. Nejlepší bilanci zaznamenala<span className="emp"><strong>Praha</strong></span>, nejhorší pak <span className="emp"><strong>Ústecký kraj</strong></span>. Dalším krajem s negativním saldem je <span className="emp"><strong>Pardubický kraj</strong></span></p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.saldoKrajuSum.values,
                                            getValue: meta.saldoKrajuSum.getValue,
                                            formatValue: meta.saldoKrajuSum.formatValue
                                        }
                                    }
                                },
                                {
                                    title: "Vývoj salda příjmů a výdajů krajů v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                                
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "line",
                                        config: {
                                            geo: geo,
                                            datasets: [
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.saldoKraju20.values,
                                                    getValue: meta.saldoKraju20.getValue,
                                                    formatValue: meta.saldoKraju20.formatValue,
                                                    label: meta.saldoKraju20.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.saldoKraju21.values,
                                                    getValue: meta.saldoKraju21.getValue,
                                                    formatValue: meta.saldoKraju21.formatValue,
                                                    label: meta.saldoKraju21.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.saldoKraju22.values,
                                                    getValue: meta.saldoKraju22.getValue,
                                                    formatValue: meta.saldoKraju22.formatValue,
                                                    label: meta.saldoKraju22.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.saldoKraju.values,
                                                    getValue: meta.saldoKraju.getValue,
                                                    formatValue: meta.saldoKraju.formatValue,
                                                    label: meta.saldoKraju.label
                                                },
                                            ]
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ (2023)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2023"
                                        },
                                        {
                                            label: "ČSÚ (2022)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2022"
                                        },
                                        {
                                            label: "ČSÚ (2021)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2021"
                                        },
                                        {
                                            label: "ČSÚ (2020)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2020"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}





const saldoAvg = {
    label: "Průměr 2020-2023",
    data: data,
    categories: meta.saldoKrajuAvg.values,
    getValue: meta.saldoKrajuAvg.getValue,
    formatValue: meta.saldoKrajuAvg.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle průměrného salda jednotlivých krajů v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Napříč lety <span className="emp"><strong>2020-2023</strong></span> měla v průměru nejlepší bilanci <span className="emp"><strong>Praha</strong></span>, nejhorší pak <span className="emp"><strong>Ústecký kraj</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.saldoKrajuAvg.values,
                                            getValue: meta.saldoKrajuAvg.getValue,
                                            formatValue: meta.saldoKrajuAvg.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ (2023)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2023"
                                        },
                                        {
                                            label: "ČSÚ (2022)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2022"
                                        },
                                        {
                                            label: "ČSÚ (2021)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2021"
                                        },
                                        {
                                            label: "ČSÚ (2020)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2020"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}


const saldoStats = {
    title: 'Saldo příjmů a výdajů',
    widget: {
        type: "column-group",
        config: {
            widgets: [
                {
                    type: "custom",
                    config: {
                        content: (
                            <p>
                                Saldo příjmů a&nbsp;výdajů krajů je <span className="emp"><strong>rozdíl</strong> mezi celkovými příjmy a&nbsp;celkovými výdaji</span> kraje. 
                                Pokud jsou příjmy vyšší než výdaje, je saldo kladné, což naznačuje přebytek v&nbsp;rozpočtu. 
                                Naopak, pokud jsou výdaje vyšší než příjmy, je saldo záporné, což znamená deficit. 
                                Toto saldo ukazuje, zda kraj hospodaří v&nbsp;rovnováze, nebo zda je potřeba provést úpravy v&nbsp;hospodaření, například snížit výdaje nebo zvýšit příjmy, aby se zajistila finanční stabilita.
                            </p>
                        )
                    }
                },
                {
                    type: "regional-filtered-choropleth",
                    config: {
                        id: "mapa-saldo",
                        label: "Celkové saldo krajů",
                        geo: geo,
                        datasets: [
                            saldoSum, saldoAvg, saldo23, saldo22, saldo21, saldo20
                        ]
                    }
                },
                {
                    type: "custom",
                    config: {
                        content: (
                            <p>
                                V&nbsp;letech <span className="emp"><strong>2020–⁠⁠⁠⁠⁠⁠2023</strong></span> lze pozorovat významné přebytky v&nbsp;rozpočtu hlavního města <span className="emp">Prahy</span> a&nbsp;naopak každý rok některé z&nbsp;krajů končí v&nbsp;deficitu.
                            </p>
                        )
                    }
                }
            ]
        }
    },
    footer: {
        left: {
            title: {
                label: "Zdroj:",
                icon: "pi pi-fw pi-globe"
            },
            links: [
                {
                    label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů",
                    value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt-parametry&z=T&f=TABULKA&katalog=33877&pvo=FIN01&sp=A&c=v3%7E8__RP2023&str=v58"
                }
            ]
        },
        right: {
            title: undefined,
            links: [
                meta.route
            ]
        },
        popup: {
            component: saldoSum.map.defaults.polygon.popup
        }
    }
}
export default saldoStats;