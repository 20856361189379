import APP from '../config';
import stats_kraje from './kraje/stats';
import stats_zastupko from './zastupko/stats';

/**
 * Default stats based on environment variable.
 *
 * In the future it is possible to add more stats based on the environment variable (e.g., cities).
 *
 * Also, the stats can be loaded dynamically from a database.
 *
 * @author Jiri Hynek
 */
const getStats = (response) => {
    switch(APP.subdomain) {
        case 'kraje':
            return stats_kraje(response);
        case '':
            return stats_zastupko(response);
        default:
            return undefined;
    }
}

const stats = (response) => getStats(response);
export default stats;
