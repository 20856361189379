import { Messages } from 'primereact/messages';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import authAxios from '../../../lib/authAxios';
import { showErrorMessage, showSuccessMessage } from '../../../utils/messages';
import { ButtonAdd, ButtonDelete } from '../../../components/Buttons';
import { getErrorMessage } from '../../../../utils/errors';
import { Column } from '../../../../components/PrimereactComponents';
import { AliasAddDialog } from './AliasAddDialog';
import { ConfirmDeleteAliasDialog } from './ConfirmDeleteAliasDialog';
import { formatDate } from '../../../utils/format';
import { PaginatedDataTable } from '../../../components/PaginatedDataTable';
import { BaseChip } from '../../../components/Chips';

export const AliasTable = ({ member }) => {
    const { municipalityId, memberId } = useParams();
    const bodyType = 'zastupitelstvo';

    const [aliases, setAliases] = useState(null);
    const [loading, setLoading] = useState(true);

    const [showAddDialog, setShowAddDialog] = useState(false);
    const [processingAdd, setProcessingAdd] = useState(false);
    const refDialogAddMsgs = useRef(null);
    const refDialogAddForm = useRef(null);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [processingDelete, setProcessingDelete] = useState(false);
    const [selectedDeleteRow, setSelectedDeleteRow] = useState(null);

    const msgs = useRef(null);

    const fetchData = useCallback(async () => {
        try {
            const response = await authAxios.get(`admin/${municipalityId}/${bodyType}/member/${memberId}/alias`);
            setAliases(response.data);
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se načíst aliasy', { replace: true });
        } finally {
            setLoading(false);
        }
    }, [municipalityId, memberId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const hideAddDialog = () => {
        setShowAddDialog(false);
    };

    const handleAdd = async (data) => {
        setProcessingAdd(true);
        const requestData = {
            first_name: data.first_name,
            last_name: data.last_name,
            date_from: formatDate(data.date_from),
            date_to: formatDate(data.date_to)
        };
        try {
            await authAxios.post(`admin/${municipalityId}/${bodyType}/member/${memberId}/alias`, requestData);
            refDialogAddForm?.current?.reset();
            hideAddDialog();
            showSuccessMessage(msgs, 'Alias byl úspěšně vytvořen');
            fetchData();
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(refDialogAddMsgs, errorMessage ?? 'Nepodařilo se vytvořit alias', { replace: true });
        } finally {
            setProcessingAdd(false);
        }
    };

    const confirmDelete = (rowData) => {
        setSelectedDeleteRow(rowData);
        setShowDeleteDialog(true);
    };

    const hideDeleteDialog = () => {
        setSelectedDeleteRow(null);
        setShowDeleteDialog(false);
    };

    const handleDelete = async () => {
        const alias = selectedDeleteRow;
        hideDeleteDialog();
        if (!alias) {
            return;
        }

        setProcessingDelete(true);
        try {
            await authAxios.delete(`admin/${municipalityId}/${bodyType}/alias/${alias.id}`);
            setAliases(aliases.filter((a) => a.id !== alias.id));
            showSuccessMessage(msgs, `Alias ${alias.first_name} ${alias.last_name} byl úspěšně smazán`);
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se smazat alias', { replace: true });
        } finally {
            setProcessingDelete(false);
        }
    };

    const dateFromTemplate = (rowData) => {
        if (rowData.date_from) {
            return rowData.date_from;
        }

        return <BaseChip label='bez omezení' severity='success' outlined />;
    };

    const dateToTemplate = (rowData) => {
        if (rowData.date_to) {
            return rowData.date_to;
        }

        return <BaseChip label='bez omezení' severity='success' outlined />;
    };

    const buttonsTemplate = (rowData) => {
        return (
            <ButtonDelete
                title='Smazat alias'
                rounded
                disabled={processingAdd || processingDelete}
                onClick={() => confirmDelete(rowData)}
            />
        );
    };

    return (
        <>
            <h2>Aliasy</h2>

            <div className='page__buttons'>
                <ButtonAdd
                    label='Přidat'
                    disabled={processingAdd || processingDelete}
                    onClick={() => setShowAddDialog(true)}
                />
                {(processingAdd || processingDelete) && <ProgressSpinner />}
            </div>

            <Messages ref={msgs} className='page__messages' />

            <PaginatedDataTable
                value={aliases}
                loading={loading}
                useRowsPerPageDropdown={false}
                initialRows={5}
                breakpoint='900px'
                emptyMessage='Nemá definovány žádné aliasy'
            >
                <Column field='first_name' header='Jméno' />
                <Column field='last_name' header='Příjmení' />
                <Column header='Od' body={dateFromTemplate} />
                <Column header='Do' body={dateToTemplate} />
                <Column body={buttonsTemplate} align='right' />
            </PaginatedDataTable>

            <AliasAddDialog
                isVisible={showAddDialog}
                member={member}
                onHide={hideAddDialog}
                onSubmit={handleAdd}
                msgs={refDialogAddMsgs}
                ref={refDialogAddForm}
            />

            <ConfirmDeleteAliasDialog
                isVisible={showDeleteDialog}
                onHide={hideDeleteDialog}
                onConfirm={handleDelete}
                alias={selectedDeleteRow}
            />
        </>
    );
};
