import axios from 'axios';
import { AUTH_NAME } from '../constants/auth';
import { ErrorCode } from '../constants/enums';

const authAxios = axios.create();

authAxios.interceptors.request.use((config) => {
    const token = localStorage.getItem(AUTH_NAME);
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
});

authAxios.interceptors.response.use(undefined, (error) => {
    if (error.response?.status === 401) {
        const errorCode = error.response?.data?.error_code;
        if (errorCode === ErrorCode.TOKEN_EXPIRED || errorCode === ErrorCode.TOKEN_INVALID) {
            localStorage.removeItem(AUTH_NAME); // force logout if token is invalid
            window.location.assign('/admin/login'); // force reload and Auth Kit state update
            return;
        }
    }
    return Promise.reject(error);
});

export default authAxios;
