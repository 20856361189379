import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import React from 'react';
import { FormContainer, FormInput, FormRow, Label } from '../../../components/form';
import { LineDivider } from '../../../components/LineDivider';
import { formatUserName } from '../../../utils/format';

export const RequestInfoForm = ({ request }) => {
    return (
        <FormContainer type='dialog'>
            <FormRow>
                <FormInput readonly>
                    <Label htmlFor='municipalityName' text='Název municipality' />
                    <InputText id='municipalityName' disabled defaultValue={request.municipality.name} />
                </FormInput>
                <FormInput readonly>
                    <Label htmlFor='municipalityTextId' text='ID municipality' />
                    <InputText id='municipalityTextId' disabled defaultValue={request.municipality.text_id} />
                </FormInput>
            </FormRow>
            <FormRow>
                <FormInput readonly>
                    <Label htmlFor='user' text='Uživatel' />
                    <InputText
                        id='user'
                        disabled
                        defaultValue={formatUserName(
                            request.user.first_name,
                            request.user.last_name,
                            request.user.login
                        )}
                    />
                </FormInput>
            </FormRow>
            <LineDivider />
            <FormRow>
                <FormInput readonly>
                    <Label htmlFor='text' text='Text žádosti' />
                    <InputTextarea id='text' disabled defaultValue={request.text} autoResize />
                </FormInput>
            </FormRow>
        </FormContainer>
    );
};
