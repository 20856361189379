import React from 'react';
import ChartComponent from './ChartComponent';
import useDimensions from '../hooks/useDimensions';
//import { useTranslation } from 'react-i18next';

function UnityAverageChart({data}) {
    const { height, width } = useDimensions();

    //const {t} = useTranslation();
    data.datasets.forEach(dataset => {
        dataset.data = dataset.data.filter(item => item !== '-');
    });

    var datasets = data.datasets.map(dataset => ({
        label: dataset.label,
        data: [(dataset.data.reduce((a, b) => a + b, 0) / dataset.data.length).toFixed(1)],
        backgroundColor: dataset.backgroundColor[0]
      }))

      datasets.sort((a, b) => b.data - a.data);
  
      const chartData = {
        labels: [""],
        datasets: datasets
      };
  
      const chartOptions = {
        indexAxis: "y",
        plugins: {
          tooltips: {
              mode: 'index',
              intersect: false
          },
          legend:{
              position: 'bottom',
              onHover: function (evt, item, legend) {
                const tooltip = legend.chart.tooltip;
                    if (tooltip.getActiveElements().length > 0) {
                        tooltip.setActiveElements([], { x: 0, y: 0 });
                    }
                    tooltip.setActiveElements([{ datasetIndex: item.datasetIndex, index: 0 }], { x: 0, y: 0 })
                  evt.native.target.style.cursor = 'pointer';
                  legend.chart.data.datasets.forEach((i, id) => {
                      if (id !== item.datasetIndex) {
                          i.backgroundColor += '30';
                      }
                  });
                      legend.chart.update();
              },
              onLeave: function (evt, item, legend) {
                  legend.chart.data.datasets.forEach((i, id) => {
                      if (id !== item.datasetIndex) {
                          i.backgroundColor = i.backgroundColor.slice(0, -2);
                      }
                  });
                      legend.chart.update();
              },
          },
      },
        scales: {
          x: {
              ticks: {
                  beginAtZero: true,
                  callback: (value, index, values) => {
                      return value + " %";
                  },
              },
              //max: 100,
          },
        },
        maintainAspectRatio: false,
        aspectRatio: (width < 768 ? 1.4 : 1)
      };


    return (
        <ChartComponent type='bar' height="450px" data={chartData} options={chartOptions}/>
    );
}
export default UnityAverageChart;