import React from "react";

import { Chart } from 'primereact/chart';

import "./RegionalStackedBarChart.css"

const defaultConfig = {
    sort: true,
    filterUndefined: false,
    elementStyle: {
        width: "100%",
        height: "100%",
        position: "relative",
    },
    style: {
        backgroundColor: "lightgray",
        borderColor: "lightgray",
        textColor: "black",
        disabledColor: "lightgray",
        emphasizeBackgroundColor: "yellow",
        emphasizeBorderColor: "yellow",
        emphasizeTextColor: "#b2b300",
        columnHeight: 30,
    },
    chartjs: undefined
}

const datasetTemplate = {
    axis: 'y',
    data: [],
    backgroundColor: [],
    borderColor: [],
    borderWidth: 2,
    barPercentage: 0.9,
    //barThickness: 20,
}

const chartjsStructure = {
    type: 'bar',
    data: {
        labels: [],
        datasets: []
    },
    options: {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    color: []
                    /*callback: function(value, index, ticks) {
                        return value*100 + "%";
                    }*/
                },
                grid: {
                    display: false
                },
                stacked: true
            },
            x: {
                ticks: {
                    /*callback: function(value, index, ticks) {
                        return value*100 + "%";
                    }*/
                },
                stacked: true
            },
        },
        plugins: {
            /*legend: {
                display: false
            },*/
        }
    }
}

function RegionalStackedBarChart({
    geo = {},
    datasets = [],
    /*data = {}, // the structure of the region values { regionId: value }
    categories = {},
    getValue = (data, region) => data[region],*/
    formatValue = (value) => value,
    config = defaultConfig,
    selection = undefined
}) {

    // initialize the chartjs structure
    const chartjs = JSON.parse(JSON.stringify(chartjsStructure));
    for(let i = 0; i < datasets.length; i++) {
        chartjs.data.datasets.push(JSON.parse(JSON.stringify(datasetTemplate)));
    }

    const swap = (i, j) => {
        let swap;
        for(let k = 0; k < chartjs.data.datasets.length; k++) {
            // data
            swap = chartjs.data.datasets[k].data[i];
            chartjs.data.datasets[k].data[i] = chartjs.data.datasets[k].data[j];
            chartjs.data.datasets[k].data[j] = swap;

            // backgroundColor
            swap = chartjs.data.datasets[k].backgroundColor[i];
            chartjs.data.datasets[k].backgroundColor[i] = chartjs.data.datasets[k].backgroundColor[j];
            chartjs.data.datasets[k].backgroundColor[j] = swap;

            // borderColor
            swap = chartjs.data.datasets[k].borderColor[i];
            chartjs.data.datasets[k].borderColor[i] = chartjs.data.datasets[k].borderColor[j];
            chartjs.data.datasets[k].borderColor[j] = swap;
        }

        // labels
        swap = chartjs.data.labels[i];
        chartjs.data.labels[i] = chartjs.data.labels[j];
        chartjs.data.labels[j] = swap;

        // ticks color
        swap = chartjs.options.scales.y.ticks.color[i];
        chartjs.options.scales.y.ticks.color[i] = chartjs.options.scales.y.ticks.color[j];
        chartjs.options.scales.y.ticks.color[j] = swap;
    }

    const cmp = (a, b) => {
        if(a === undefined) {
            if(b === undefined) {
                return 0;
            } else {
                return -1;
            }
        } else {
            if(b === undefined) {
                return 1;
            } else {
                return a - b;
            }
        }
    }

    const sumArray = (datasets) => {
        let sumArray = [];
        for(let i = 0; i < datasets[0].data.length; i++) {
            let sum = 0;
            for(let j = 0; j < datasets.length; j++) {
                sum += datasets[j].data[i] ?? 0;
            }
            sumArray.push(sum);
        }
        return sumArray;
    }

    const sortData = () => {
        const values = sumArray(chartjs.data.datasets);

        let max;
        let tmp;
        for(let i = 0; i < values.length; i++) {
            max = i;
            for(let j = i+1; j < values.length; j++) {
                if(cmp(values[j], values[max]) > 0) {
                    max = j;
                }
            }

            // swap values
            swap(i, max);

            // swap values in the sum array
            tmp = values[i];
            values[i] = values[max];
            values[max] = tmp;
        }
    }

    const processDataset = (dataset, region, i) => {
        const data = dataset.data;
        const getValue = dataset.getValue;
        const backgroundColor = dataset.backgroundColor;
        const borderColor = dataset.backgroundColor;

        // get the value of the region
        let value = data[region] ? getValue(data, region) : undefined;
        if(!((config.filterUndefined === true || ((config.filterUndefined === undefined && defaultConfig.filterUndefined))) && value === undefined)) {
            // data
            chartjs.data.datasets[i].data.push(value);

            // colors
            chartjs.data.datasets[i].backgroundColor.push(backgroundColor ?? config.style?.backgroundColor ?? defaultConfig.style?.backgroundColor);

            // emphasize colors
            if(selection?.region === region) {
                //chartjs.data.datasets[i].backgroundColor.push(config.style?.emphasizeBackgroundColorbackgroundColor ?? defaultConfig.style?.emphasizeBackgroundColor);
                chartjs.data.datasets[i].borderColor.push(config.style?.emphasizeBorderColor ?? defaultConfig.style?.emphasizeBorderColor);
                if(i === 0) {
                    chartjs.options.scales.y.ticks.color.push(config.style?.emphasizeTextColor ?? defaultConfig.style?.emphasizeTextColor);
                }
            } else {
                chartjs.data.datasets[i].borderColor.push(borderColor ?? config.style?.borderColor ?? defaultConfig.style?.borderColor);
                if(i === 0) {
                    if(value === undefined) {
                        chartjs.options.scales.y.ticks.color.push(config.style?.disabledColor ?? defaultConfig.style?.disabledColor);
                    } else {
                        chartjs.options.scales.y.ticks.color.push(config.style?.textColor ?? defaultConfig.style?.textColor);
                    }
                }
            }
        }
    }

    const processRegions = () => {

        // iterate over the regions and for every region get the region and category
        for (let region in geo) {
            // label
            chartjs.data.labels.push(geo[region].nameShort);
            
            for(let i = 0; i < datasets.length; i++) {
                processDataset(datasets[i], region, i);
                chartjs.data.datasets[i].label = datasets[i].label;
            }
        }

        if(config.sort || defaultConfig.sort) {
            sortData();
        }
    }

    const setOptions = () => {
        // merge options with the props
        chartjs.options = {
            ...chartjs.options,
            ...config.chartjs
        }

        chartjs.options.scales.x.ticks.callback = (value, index, ticks) => {
            return value && formatValue && formatValue(value);
        }

        /*chartjs.options.scales.y.ticks.callback = (value, index, ticks) => {
            return geo[chartjs.data.labels[value]].name;
        }*/

        
    }

    setOptions();
    processRegions();

    return (
        <>
            <Chart
                type={chartjs.type}
                data={chartjs.data}
                options={chartjs.options}
                style={{
                    ...config.elementStyle ?? defaultConfig.elementStyle,
                    height: (chartjs.data.labels.length * config.style.columnHeight) + "px"
                }} />
        </>
    )
}
export default RegionalStackedBarChart;