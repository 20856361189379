import { Message } from 'primereact/message';
import React from 'react';

/**
 * @param {object} props
 * @param {string} props.text
 * @param {string=} props.prefix
 */
export const ErrorMessage = ({ text, prefix }) => {
    const message = prefix ? `${prefix}: ${text}` : text;
    return (
        <div className='page__messages'>
            <Message severity='error' text={message} className='error-message' />
        </div>
    );
};
