import { Messages } from 'primereact/messages';
import React from 'react';
import { ButtonClose, ButtonDelete } from '../../../components/Buttons';
import { ModalDialog } from '../../../components/ModalDialog';
import { formatMemberName } from '../../../../utils/texts';

export const ConfirmRemoveMemberDialog = ({ isVisible, selectedMember, onHide, onClickDelete, msgs }) => {
    return (
        <ModalDialog
            visible={isVisible}
            header='Potvrďte odebrání člena ze zasedání'
            style={{ width: 400 }}
            footer={
                <>
                    <ButtonClose label='Zrušit' onClick={onHide} />
                    <ButtonDelete label='Odebrat' onClick={onClickDelete} />
                </>
            }
            onHide={onHide}
        >
            <Messages ref={msgs} className='flex-center' />
            {selectedMember && (
                <div className='dialog-text'>
                    <span className='dialog-text__warning'>
                        <span>
                            Opravdu chcete smazat {selectedMember.sex ? 'členku' : 'člena'}{' '}
                            <b>{formatMemberName(selectedMember.first_name, selectedMember.last_name)}</b>?
                        </span>
                    </span>
                </div>
            )}
        </ModalDialog>
    );
};
