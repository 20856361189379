import PopupBodyChart from "../../../../components/map/popup/PopupBodyChart";
import PopupFooter from "../../../../components/map/popup/PopupFooter";
import PopupHeader from "../../../../components/map/popup/PopupHeader";
import data from "../data";
import geo from "../geo";
import meta from "../meta";

/**
 * Outcome statistics.
 * 
 * @author Adam Janosik, Kristyna Zaklova
 */
const outcome23 = {
    label: "Rok 2023",
    data: data,
    categories: meta.vydajeKraju.values,
    getValue: meta.vydajeKraju.getValue,
    formatValue: meta.vydajeKraju.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle výdajů jednotlivých krajů (rok 2023)",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Výdaje jednotlivých krajů se v roce <span
                                                className="emp"><strong>2023</strong></span> pohybovaly od <span
                                                className="emp"><strong>13&nbsp;054&nbsp;583</strong> Kč</span> do <span
                                                className="emp"><strong>111&nbsp;511&nbsp;586</strong> Kč</span>.
                                                Největší výdaje měla <span className="emp"><strong>Praha</strong></span> a
                                                nejmenší <span className="emp"><strong>Karlovarský kraj</strong></span>.
                                            </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.vydajeKraju.values,
                                            getValue: meta.vydajeKraju.getValue,
                                            formatValue: meta.vydajeKraju.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroj:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů (rok 2023)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2023"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}


const outcome22 = {
    label: "Rok 2022",
    data: data,
    categories: meta.vydajeKraju22.values,
    getValue: meta.vydajeKraju22.getValue,
    formatValue: meta.vydajeKraju22.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle výdajů jednotlivých krajů (rok 2022)",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Výdaje jednotlivých krajů se v roce <span
                                                className="emp"><strong>2022</strong></span> pohybovaly od <span
                                                className="emp"><strong>10&nbsp;372&nbsp;168</strong> Kč</span> do <span
                                                className="emp"><strong>104&nbsp;822&nbsp;902</strong> Kč</span>.
                                                Největší výdaje měla <span className="emp"><strong>Praha</strong></span> a
                                                nejmenší <span className="emp"><strong>Karlovarský kraj</strong></span>.
                                            </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.vydajeKraju22.values,
                                            getValue: meta.vydajeKraju22.getValue,
                                            formatValue: meta.vydajeKraju22.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroj:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů (rok 2022)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2022"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const outcome21 = {
    label: "Rok 2021",
    data: data,
    categories: meta.vydajeKraju21.values,
    getValue: meta.vydajeKraju21.getValue,
    formatValue: meta.vydajeKraju21.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle výdajů jednotlivých krajů (rok 2021)",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Výdaje jednotlivých krajů se v roce <span
                                                    className="emp"><strong>2021</strong></span> pohybovaly od <span
                                                    className="emp"><strong>9&nbsp;465&nbsp;532</strong> Kč</span> do <span
                                                    className="emp"><strong>89&nbsp;649&nbsp;360</strong> Kč</span>.
                                                    Největší výdaje měla <span className="emp"><strong>Praha</strong></span> a
                                                    nejmenší <span className="emp"><strong>Karlovarský kraj</strong></span>.
                                                </p>    
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.vydajeKraju21.values,
                                            getValue: meta.vydajeKraju21.getValue,
                                            formatValue: meta.vydajeKraju21.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroj:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů (rok 2021)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2021"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const outcome20 = {
    label: "Rok 2020",
    data: data,
    categories: meta.vydajeKraju20.values,
    getValue: meta.vydajeKraju20.getValue,
    formatValue: meta.vydajeKraju20.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle výdajů jednotlivých krajů (rok 2020)",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Výdaje jednotlivých krajů se v roce <span
                                                    className="emp"><strong>2020</strong></span> pohybovaly od <span
                                                    className="emp"><strong>9&nbsp;050&nbsp;850</strong> Kč</span> do <span
                                                    className="emp"><strong>88&nbsp;034&nbsp;745</strong> Kč</span>.
                                                    Největší výdaje měla <span className="emp"><strong>Praha</strong></span> a
                                                    nejmenší <span className="emp"><strong>Karlovarský kraj</strong></span>.
                                                </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.vydajeKraju20.values,
                                            getValue: meta.vydajeKraju20.getValue,
                                            formatValue: meta.vydajeKraju20.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroj:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů (rok 2020)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2020"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}


const outcomeAvg = {
    label: "Průměr 2020-2023",
    data: data,
    categories: meta.vydajeKrajuAvg.values,
    getValue: meta.vydajeKrajuAvg.getValue,
    formatValue: meta.vydajeKrajuAvg.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle průměrných výdajů jednotlivých krajů v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Napříč lety <span className="emp"><strong>2020-2023</strong></span> v průměru nejvíce utrácela <span className="emp"><strong>Praha</strong></span>, nejméně pak <span className="emp"><strong>Karlovarský kraj</strong></span>. Typicky kraje s menší rozlohou a počtem obyvatel utrácejí méně než kraje s větší populací a územím.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.vydajeKrajuAvg.values,
                                            getValue: meta.vydajeKrajuAvg.getValue,
                                            formatValue: meta.vydajeKrajuAvg.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ (2023)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2023"
                                        },
                                        {
                                            label: "ČSÚ (2022)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2022"
                                        },
                                        {
                                            label: "ČSÚ (2021)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2021"
                                        },
                                        {
                                            label: "ČSÚ (2020)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2020"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}



const outcomeSum = {
    label: "Suma za období 2020-2023",
    data: data,
    categories: meta.vydajeKrajuSum.values,
    getValue: meta.vydajeKrajuSum.getValue,
    formatValue: meta.vydajeKrajuSum.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle vývoje výdajů jednotlivých krajů v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Suma výdajů jednotlivých krajů v letech <span className="emp"><strong>2020-2023</strong></span> značí součet všech výdajů krajů v daném období. Nejvíce utrácela <span className="emp"><strong>Praha</strong></span>, nejméně pak <span className="emp"><strong>Karlovarský kraj</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.vydajeKrajuSum.values,
                                            getValue: meta.vydajeKrajuSum.getValue,
                                            formatValue: meta.vydajeKrajuSum.formatValue
                                        }
                                    }
                                },
                                {
                                    title: "Vývoj výdajů krajů v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                                Praha výrazně převyšuje ostatní kraje v celkových výdajích a vzrůstajícím trendu.
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "line",
                                        config: {
                                            geo: geo,
                                            datasets: [
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.vydajeKraju20.values,
                                                    getValue: meta.vydajeKraju20.getValue,
                                                    formatValue: meta.vydajeKraju20.formatValue,
                                                    label: meta.vydajeKraju20.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.vydajeKraju21.values,
                                                    getValue: meta.vydajeKraju21.getValue,
                                                    formatValue: meta.vydajeKraju21.formatValue,
                                                    label: meta.vydajeKraju21.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.vydajeKraju22.values,
                                                    getValue: meta.vydajeKraju22.getValue,
                                                    formatValue: meta.vydajeKraju22.formatValue,
                                                    label: meta.vydajeKraju22.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.vydajeKraju.values,
                                                    getValue: meta.vydajeKraju.getValue,
                                                    formatValue: meta.vydajeKraju.formatValue,
                                                    label: meta.vydajeKraju.label
                                                },
                                            ]
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ (2023)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2023"
                                        },
                                        {
                                            label: "ČSÚ (2022)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2022"
                                        },
                                        {
                                            label: "ČSÚ (2021)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2021"
                                        },
                                        {
                                            label: "ČSÚ (2020)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2020"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const outcomeStats = {
    title: 'Celkové výdaje krajů',
    content: () => {
        return (
            <>
                <span className="emp"><strong>Výdaje krajů</strong></span> se dělí na dva typy, přičemž první uvedené pokrývají aktuální potřeby, zatímco druhé podporují budoucí rozvoj. <span className="emp">Běžné výdaje</span> zahrnují náklady spojené s&nbsp;každodenním provozem a&nbsp;poskytováním veřejných služeb, jako jsou platy zaměstnanců, náklady na zdravotnictví, školství, dopravu nebo sociální služby. <span className="emp">Kapitálové výdaje</span> se naopak vztahují k&nbsp;dlouhodobým investicím a&nbsp;projektům, jako jsou výstavba nové infrastruktury, renovace budov nebo investice do modernizace zařízení. 
            </>
        )
    },
    chart: {
        id: "mapa-vydaje",
        type: "regional-filtered-choropleth",
        config: {
            label: "Celkové výdaje krajů",
            geo: geo,
            datasets: [
                outcomeSum, outcomeAvg, outcome23, outcome22, outcome21, outcome20 
            ]

        }
    },
    footer: {
        left: {
            title: {
                label: "Zdroj:",
                icon: "pi pi-fw pi-globe"
            },
            links: [
                {
                    label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů",
                    value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt-parametry&z=T&f=TABULKA&katalog=33877&pvo=FIN01&sp=A&c=v3%7E8__RP2023&str=v58"
                }
            ]
        },
        right: {
            title: undefined,
            links: [
                meta.route
            ]
        },
        popup: {
            component: outcomeSum.map.defaults.polygon.popup
        }
    }
}
export default outcomeStats;