import { React, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useDimensions from '../../hooks/useDimensions';
import { useTranslation } from 'react-i18next';
import { Tooltip, TabView, TabPanel } from '../../components/PrimereactComponents';
import MembersComparison from './MembersComparison';
import AttendanceAnalysis from './AttendanceAnalysis';
import PartiesUnity from './PartiesUnity';
import VotingUnity from './VotingUnity';

function Analyses() {
    const location = useLocation();
    const searchParams = new URLSearchParams(window.location.search);
    const [activeTab] = useState(
        location.state || (searchParams.get('prvni_clen') || searchParams.get('druhy_clen') ? 1 : 0)
    );
    const { width } = useDimensions();
    const { t } = useTranslation(['analyses', 'translation']);

    const analyses = [
        {
            title: t('attendance_analysis', { ns: 'translation' }),
            subtitle: t('parties_attendance', { ns: 'translation' }),
            component: <AttendanceAnalysis />
        },
        {
            title: t('councilMember_comparison', { ns: 'translation' }),
            subtitle: t('comparison', { ns: 'translation' }),
            component: <MembersComparison />
        },
        {
            title: t('parties-voting-unity', { ns: 'translation' }),
            subtitle: t('voting_unity', { ns: 'translation' }),
            component: <PartiesUnity />
        },
        {
            title: t('voting-unity', { ns: 'translation' }),
            subtitle: t('voting_unity', { ns: 'translation' }),
            component: <VotingUnity />
        }
    ];

    return (
        <div className='analyses'>
            {/**
      <h1 className='analyses-title'>{t('Analyses', {ns: 'translation'})}
        <i
          className='custom-target-icon pi bi-info-circle parties__chart-icon'
          data-pr-tooltip={t('title-tooltip')}
          data-pr-position='bottom'
        ></i>
      </h1>
      */}
            <Tooltip target='.custom-target-icon' />
            <TabView scrollable={width <= 992} activeIndex={activeTab}>
                {analyses.map((analysis, index) => {
                    return (
                        <TabPanel key={index} header={analysis.title}>
                            <div className='card-content'>{analysis.component}</div>
                        </TabPanel>
                    );
                })}
            </TabView>
        </div>
    );
}

export default Analyses;
