import { classNames } from 'primereact/utils';
import React from 'react';

const ContainerType = {
    CARD: 'card',
    DIALOG: 'dialog'
};

/**
 * @param {object} props
 * @param {'card'|'dialog'=} props.type
 */
export const FormContainer = ({ children, type }) => {
    return (
        <div
            className={classNames('form-container', {
                'form-container--card': type === ContainerType.CARD,
                'form-container--dialog': type === ContainerType.DIALOG
            })}
        >
            {children}
        </div>
    );
};
