import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import React from 'react';
import { Controller } from 'react-hook-form';

/**
 * @param {object} params
 * @param {import('react-hook-form').UseFormRegister<any>} params.control
 * @param {string} params.name
 * @param {import('react-hook-form').RegisterOptions} params.rules
 * @param {string} params.placeholder
 * @param {import('primereact/password').PasswordProps} params.inputProps
 */
export const RHFPassword = ({ control, name, rules, placeholder, inputProps }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => (
                <Password
                    id={field.name}
                    {...field}
                    ref={undefined}
                    inputRef={field.ref}
                    className={classNames({ 'p-invalid': fieldState.invalid })}
                    placeholder={placeholder}
                    feedback={false}
                    toggleMask={true}
                    {...inputProps}
                />
            )}
        />
    );
};
