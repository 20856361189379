import { classNames } from 'primereact/utils';
import React from 'react';

const RowType = {
    HEADING: 'heading',
    SUBHEADING: 'subheading',
    BUTTON: 'button',
    FOOTER: 'footer',
    MESSAGE: 'message'
};

/**
 * @param {object} props
 * @param {'heading'|'subheading'|'button'|'footer'|'message'=} props.type
 */
export const FormRow = ({ children, type }) => {
    const renderChildren = (children, type) => {
        switch (type) {
            case RowType.HEADING:
                return <h2>{children}</h2>;
            case RowType.SUBHEADING:
                return <h3>{children}</h3>;
            case RowType.FOOTER:
                return <span>{children}</span>;
            default:
                return children;
        }
    };

    return (
        <div
            className={classNames('form-row', {
                'form-row--heading': type === RowType.HEADING || type === RowType.SUBHEADING,
                'form-row--button': type === RowType.BUTTON,
                'form-row--footer': type === RowType.FOOTER,
                'form-row--message': type === RowType.MESSAGE
            })}
        >
            {renderChildren(children, type)}
        </div>
    );
};
