import PopupBodyChart from "../../../../components/map/popup/PopupBodyChart";
import PopupFooter from "../../../../components/map/popup/PopupFooter";
import PopupHeader from "../../../../components/map/popup/PopupHeader";
import APP from "../../../config";
import data from "../data";
import geo from "../geo";
import meta from "../meta";

/**
 * Share of men statistics.
 * 
 * @author Kristyna Zaklova
 */
const genericPopup = {
    header: {
        component: PopupHeader,
        props: {
            data: data
        }
    },
    body: {
        component: PopupBodyChart,
        props: {
            data: data,
            stats: [
                {
                    title: "Srovnání dle podílu mužů v jednotlivých krajích v období 2020-2024 (s výjimkou Prahy)",
                    content: () => {
                        return (
                            <>
                                <p>Podíl mužů v jednotlivých krajích se pohybuje od <span className="emp"><strong>69%</strong></span> do <span className="emp"><strong>85%</strong></span>. Největší podíl mužů je v <span className="emp"><strong>Jihomoravském kraji</strong></span> a nejmenší v <span className="emp"><strong>Hlavním městě Praha</strong></span>.</p>
                            </>
                        )
                    },
                    chart: {
                        type: "bar",
                        config: {
                            geo: geo,
                            data: data,
                            categories: meta.podilMuzu.values,
                            getValue: meta.podilMuzu.getValue,
                            formatValue: meta.podilMuzu.formatValue
                        }
                    }
                }
            ]
        }
    },
    footer: {
        component: PopupFooter,
        props: {
            data: data,
            settings: {
                left: {
                    title: {
                        label: "Zdroj:",
                        icon: "pi pi-fw pi-globe"
                    },
                    links: [
                        {
                            label: "Datové sady",
                            value: `${APP.protocol}://kraje.${APP.domain}${APP.port && ':' + APP.port}/datasety`
                        }
                    ]
                },
                right: {
                    title: undefined,
                    links: [
                        meta.route
                    ]
                }
            }
        }
    },
    style: {
        width: '80vw',
        height: '100vh',
        maxWidth: '1200px',
    }
}

const menStats = {
    title: 'Zastoupení mužů',
    content: () => {
        return (
            <>
                Celkem ve <span className="emp"><strong>4</strong>&nbsp;krajích</span> jsou muži zastoupeni z&nbsp;více než <span className="emp"><strong>80&nbsp;%</strong></span>. 
                Tato skutečnost může být výsledkem dlouhodobých tradic a&nbsp;kulturních vzorců, které historicky formovaly politické struktury. 
                Ačkoli silná mužská přítomnost přináší mnoho zkušeností a&nbsp;perspektiv, je důležité si uvědomit význam vyváženého zastoupení, které zahrnuje různé pohledy.
            </>
        )
    },
    chart: {
        id: "mapa-podil-muzu",
        type: "regional-choropleth",
        config: {
            geo: geo,
            data: data,
            categories: meta.podilMuzu.values,
            getValue: meta.podilMuzu.getValue,
            formatValue: meta.podilMuzu.formatValue,
            map: {
                defaults: {
                    polygon: {
                        hoverStyle: {
                            fill: "yellow"
                        },
                        popup: {
                            header: {
                                component: PopupHeader,
                                props: {
                                    data: data
                                }
                            },
                            body: {
                                component: PopupBodyChart,
                                props: {
                                    data: data,
                                    stats: [
                                        {
                                            title: "Srovnání dle podílu mužů v jednotlivých krajích v období 2020-2024 (s výjimkou Prahy)",
                                            content: () => {
                                                return (
                                                    <>
                                                        <p>Podíl mužů v jednotlivých krajích se pohybuje od <span className="emp"><strong>69%</strong></span> do <span className="emp"><strong>85%</strong></span>. Největší podíl mužů je v <span className="emp"><strong>Jihomoravském kraji</strong></span> a nejmenší v <span className="emp"><strong>Hlavním městě Praha</strong></span>.</p>
                                                    </>
                                                )
                                            },
                                            chart: {
                                                type: "bar",
                                                config: {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.podilMuzu.values,
                                                    getValue: meta.podilMuzu.getValue,
                                                    formatValue: meta.podilMuzu.formatValue
                                                }
                                            }
                                        }
                                    ]
                                }
                            },
                            footer: {
                                component: PopupFooter,
                                props: {
                                    data: data,
                                    settings: {
                                        left: {
                                            title: {
                                                label: "Zdroje:",
                                                icon: "pi pi-fw pi-globe"
                                            },
                                            links: [
                                                {
                                                    ...meta.url,
                                                    label: "Web kraje",
                                                },
                                                {
                                                    ...meta.urlHlasovaciData,
                                                    label: "Hlasovací data kraje",
                                                }
                                            ]
                                        },
                                        right: {
                                            title: undefined,
                                            links: [
                                                meta.route
                                            ]
                                        }
                                    }
                                }
                            },
                            style: {
                                width: '80vw',
                                height: '100vh',
                                maxWidth: '1200px',
                            }
                        }
                    },
                    capital: {
                        style: {
                            display: "none"
                        }
                    }
                }
            },
        }
    },
    footer: {
        left: {
            title: {
                label: "Zdroj:",
                icon: "pi pi-fw pi-globe"
            },
            links: [
                {
                    label: "Datové sady",
                    value: `${APP.protocol}://kraje.${APP.domain}${APP.port && ':' + APP.port}/datasety`
                }
            ]
        },
        right: {
            title: undefined,
            links: [
                meta.route
            ]
        },
        popup: {
            component: genericPopup
        }
    }
}
export default menStats;