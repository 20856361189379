import { Column } from 'primereact/column';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { formatMemberName } from '../../../../utils/texts';
import { PaginatedDataTable } from '../../../components/PaginatedDataTable';
import { ButtonEdit } from '../../../components/Buttons';

export const TermTable = ({ terms, loading }) => {
    const { municipalityId } = useParams();

    const navigate = useNavigate();

    const leaderTemplate = (rowData) => {
        return rowData.leader ? formatMemberName(rowData.leader.first_name, rowData.leader.last_name) : '';
    };

    const coalitionTemplate = (rowData) => {
        return rowData.coalition ? rowData.coalition.map((entity) => entity.abbreviation).join(', ') : '';
    };

    const buttonsTemplate = (rowData) => {
        return (
            <ButtonEdit
                title='Upravit funkční období'
                rounded
                onClick={() => {
                    navigate(`/admin/municipality/${municipalityId}/funkcni-obdobi/${rowData.order}`);
                }}
            />
        );
    };

    return (
        <PaginatedDataTable
            value={terms}
            loading={loading}
            breakpoint='900px'
            emptyMessage='Nebyla nalezena žádná funkční období'
            sortField='order'
            sortOrder={1}
            onRowClick={(event) =>
                navigate(`/admin/municipality/${municipalityId}/funkcni-obdobi/${event.data.order}/zasedani`)
            }
            rowClassName={'table-row--clickable'}
            rowHover
        >
            <Column field='order' header='Pořadí' sortable />
            <Column field='year_from' header='Začátek' />
            <Column field='year_to' header='Konec' />
            <Column field='leader_title' header='Název funkce lídra' />
            <Column header='Lídr' body={leaderTemplate} />
            <Column header='Koalice' body={coalitionTemplate} />
            <Column body={buttonsTemplate} align='right' />
        </PaginatedDataTable>
    );
};
