import { Messages } from 'primereact/messages';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getFormErrorMessage, maxLengthMessage } from '../../../utils/errors';
import { FormContainer, FormInput, FormRow, Label } from '../../../components/form';
import { ButtonAdd } from '../../../components/Buttons';
import { RHFCalendar, RHFInputText } from '../../../components/rhf';
import { ModalDialog } from '../../../components/ModalDialog';
import { CLEN } from '../../../constants/field_lengths';

export const AliasAddDialog = forwardRef(({ isVisible, member, onHide, onSubmit, msgs }, ref) => {
    const [processing, setProcessing] = useState(false);

    useImperativeHandle(ref, () => ({
        reset: reset
    }));

    const formId = 'add-alias-dialog';

    const defaultValues = {
        first_name: member.first_name,
        last_name: '',
        date_from: null,
        date_to: null
    };

    const {
        control,
        formState: { errors, isSubmitting },
        handleSubmit,
        reset
    } = useForm({
        disabled: processing,
        defaultValues: defaultValues
    });

    useEffect(() => {
        setProcessing(isSubmitting);
    }, [isSubmitting]);

    return (
        <ModalDialog
            visible={isVisible}
            header='Přidat alias'
            style={{ width: 400 }}
            footer={<ButtonAdd label='Přidat' type='submit' form={formId} />}
            onHide={onHide}
        >
            <Messages ref={msgs} className='flex-center' />

            <form onSubmit={handleSubmit(onSubmit)} id={formId}>
                <FormContainer type='dialog'>
                    <FormRow>
                        <FormInput>
                            <Label htmlFor='first_name' text='Jméno' required />
                            <RHFInputText
                                control={control}
                                name='first_name'
                                rules={{
                                    required: 'Vyplňte toto pole',
                                    maxLength: {
                                        value: CLEN.JMENO,
                                        message: maxLengthMessage(CLEN.JMENO)
                                    }
                                }}
                                placeholder='Jméno'
                            />
                            {getFormErrorMessage(errors, 'first_name')}
                        </FormInput>
                    </FormRow>
                    <FormRow>
                        <FormInput>
                            <Label htmlFor='last_name' text='Příjmení' required />
                            <RHFInputText
                                control={control}
                                name='last_name'
                                rules={{
                                    required: 'Vyplňte toto pole',
                                    maxLength: {
                                        value: CLEN.PRIJMENI,
                                        message: maxLengthMessage(CLEN.PRIJMENI)
                                    }
                                }}
                                placeholder='Příjmení'
                            />
                            {getFormErrorMessage(errors, 'last_name')}
                        </FormInput>
                    </FormRow>
                    <FormRow>
                        <FormInput>
                            <Label htmlFor='date_from' text='Od' />
                            <RHFCalendar
                                control={control}
                                name='date_from'
                                inputProps={{
                                    dateFormat: 'd.m.yy'
                                }}
                            />
                            {getFormErrorMessage(errors, 'date_from')}
                        </FormInput>
                    </FormRow>
                    <FormRow>
                        <FormInput>
                            <Label htmlFor='date_to' text='Do' />
                            <RHFCalendar
                                control={control}
                                name='date_to'
                                inputProps={{
                                    dateFormat: 'd.m.yy'
                                }}
                            />
                            {getFormErrorMessage(errors, 'date_to')}
                        </FormInput>
                    </FormRow>
                </FormContainer>
            </form>
        </ModalDialog>
    );
});
