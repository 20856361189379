import React from "react";

import Widget from "../../widget/Widget";

import "./PopupBodyChart.css"

function PopupBodyChart({
    event,
    props = {
        data: {},
        stats: []
    }
}) {

    return (
        <>
            <div>
                {
                    props?.stats?.map((stat, index) => {
                        return (
                            <div key={index} className="popup-body-chart">
                                <h3>{stat.title}</h3>
                                {
                                    stat.content &&
                                    <div className="popup-body-chart-content">{stat.content()}</div>
                                }
                                {
                                    stat.chart &&
                                    <div className="popup-body-chart-chart">
                                        <Widget
                                            type={stat.chart.type}
                                            config = {{
                                                ...stat.chart.config,
                                                selection: event
                                            }}>
                                        </Widget>
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default PopupBodyChart;