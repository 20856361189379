import { Messages } from 'primereact/messages';
import { RadioButton } from 'primereact/radiobutton';
import { classNames } from 'primereact/utils';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ButtonAdd } from '../../../components/Buttons';
import { FormContainer, FormInput, FormRow, Label } from '../../../components/form';
import { getFormErrorMessage, maxLengthMessage } from '../../../utils/errors';
import { RHFInputText } from '../../../components/rhf';
import { ModalDialog } from '../../../components/ModalDialog';
import { UZIVATEL } from '../../../constants/field_lengths';

export const MemberAddDialog = forwardRef(({ isVisible, onHide, onSubmit, msgs }, ref) => {
    const [processing, setProcessing] = useState(false);

    useImperativeHandle(ref, () => ({
        reset: reset
    }));

    const formId = 'add-member-dialog';

    const defaultValues = {
        first_name: '',
        last_name: '',
        sex: null
    };

    const {
        control,
        formState: { errors, isSubmitting },
        handleSubmit,
        reset
    } = useForm({
        disabled: processing,
        defaultValues: defaultValues
    });

    useEffect(() => {
        setProcessing(isSubmitting);
    }, [isSubmitting]);

    return (
        <ModalDialog
            visible={isVisible}
            header='Přidat člena'
            style={{ width: 350 }}
            footer={<ButtonAdd label='Přidat' type='submit' form={formId} />}
            onHide={onHide}
        >
            <Messages ref={msgs} className='flex-center' />

            <form onSubmit={handleSubmit(onSubmit)} id={formId}>
                <FormContainer type='dialog'>
                    <FormRow>
                        <FormInput>
                            <Label htmlFor='first_name' text='Jméno' required />
                            <RHFInputText
                                control={control}
                                name='first_name'
                                rules={{
                                    required: 'Vyplňte toto pole',
                                    maxLength: {
                                        value: UZIVATEL.JMENO,
                                        message: maxLengthMessage(UZIVATEL.JMENO)
                                    }
                                }}
                                placeholder='Jméno'
                            />
                            {getFormErrorMessage(errors, 'first_name')}
                        </FormInput>
                    </FormRow>
                    <FormRow>
                        <FormInput>
                            <Label htmlFor='last_name' text='Příjmení' required />
                            <RHFInputText
                                control={control}
                                name='last_name'
                                rules={{
                                    required: 'Vyplňte toto pole',
                                    maxLength: {
                                        value: UZIVATEL.PRIJMENI,
                                        message: maxLengthMessage(UZIVATEL.PRIJMENI)
                                    }
                                }}
                                placeholder='Příjmení'
                            />
                            {getFormErrorMessage(errors, 'last_name')}
                        </FormInput>
                    </FormRow>
                    <FormRow>
                        <FormInput>
                            <Label htmlFor='sex' text='Pohlaví' required />
                            <Controller
                                name='sex'
                                control={control}
                                rules={{
                                    validate: (value) => value !== null || 'Zvolte pohlaví'
                                }}
                                render={({ field, fieldState }) => {
                                    const fieldRef = field.ref;
                                    field.ref = undefined;
                                    return (
                                        <div className='radio-group'>
                                            <div className='radio-group__item'>
                                                <RadioButton
                                                    inputId='M'
                                                    inputRef={fieldRef}
                                                    {...field}
                                                    className={classNames({ 'p-invalid': fieldState.invalid })}
                                                    value={false}
                                                    checked={field.value === false}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            e.preventDefault();
                                                            field.onChange(false);
                                                        } else if (e.key === ' ') {
                                                            field.onChange(false);
                                                        }
                                                    }}
                                                />
                                                <label htmlFor='M'>Muž</label>
                                            </div>
                                            <div className='radio-group__item'>
                                                <RadioButton
                                                    inputId='F'
                                                    {...field}
                                                    className={classNames({ 'p-invalid': fieldState.invalid })}
                                                    value={true}
                                                    checked={field.value === true}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            e.preventDefault();
                                                            field.onChange(true);
                                                        } else if (e.key === ' ') {
                                                            field.onChange(true);
                                                        }
                                                    }}
                                                />
                                                <label htmlFor='F'>Žena</label>
                                            </div>
                                        </div>
                                    );
                                }}
                            />
                            {getFormErrorMessage(errors, 'sex')}
                        </FormInput>
                    </FormRow>
                </FormContainer>
            </form>
        </ModalDialog>
    );
});
