import About from './pages/About';
import Home from './pages/Home';
import Analyses from './pages/Analyses/Analyses';
import Votings from './pages/Votings/Votings';
import Zasedani from './pages/Meetings/Meetings';
import App, { AppLoader } from './App';
import ZasedaniDetail from './pages/Meetings/MeetingDetail';
import PartyDetail from './pages/CouncilComposition/PartyDetail';
import MunicipalityHelmet from './pages/MunicipalityHelmet';
import NotFound from './pages/NotFound';
import VotingDetail from './pages/Votings/VotingDetail';
import MemberDetail from './pages/CouncilComposition/MemberDetail';
import Organy from './pages/Organy';
import Parties from './pages/CouncilComposition/Parties.js';
import Members from './pages/CouncilComposition/Members.js';
import MembersComparison from './pages/Analyses/MembersComparison.js';
import { Outlet } from 'react-router-dom';
import {
    MunicipalityHelmetLoader,
    MemberLoader,
    HomeLoader,
    VotingsLoader,
    VotingDetailLoader,
    CouncilCompositionLoader,
    MeetingsLoader,
    MeetingDetailLoader,
    PartyLoader,
    Home2Loader,
    StatsLoader,
    DatasetLoader,
    PartnersLoader,
    AboutLoader
} from './Loaders.js';
import AttendanceAnalysis from './pages/Analyses/AttendanceAnalysis.js';
import PartiesUnity from './pages/Analyses/PartiesUnity.js';
import VotingUnity from './pages/Analyses/VotingUnity.js';
import stats from './config/stats/stats.js';
import AppLayout from './layouts/AppLayout.jsx';
import Page from './pages/Page.js';

const routes = [
    {
        path: '/',
        element: <AppLayout />,
        errorElement: <NotFound />,
        children: [
            {
                index: true,
                element: <Page/>,
                loader: HomeLoader,
                errorElement: <NotFound />
            },
            {
                path: ':municipalitaId',
                element: <MunicipalityHelmet />,
                loader: MunicipalityHelmetLoader,
                errorElement: <NotFound />,
                children: [
                    {
                        index: true,
                        element: <Organy />,
                        //loader: async ({params}) => {return},
                        errorElement: <NotFound />
                    },
                    {
                        path: ':bodyType',
                        element: <Outlet />,
                        //loader: async ({params}) => {return},
                        errorElement: <NotFound />,
                        children: [
                            {
                                path: ':zastupitelstvoId',
                                element: <App />,
                                loader: AppLoader,
                                errorElement: <NotFound />,
                                children: [
                                    {
                                        index: true,
                                        element: <Home />,
                                        loader: Home2Loader,
                                        errorElement: <NotFound />
                                    },
                                    {
                                        path: 'hlasovani',
                                        element: <Outlet />,
                                        errorElement: <NotFound />,
                                        children: [
                                            {
                                                index: true,
                                                element: <Votings />,
                                                loader: VotingsLoader,
                                                errorElement: <NotFound />
                                            },
                                            {
                                                path: ':hlasovaniId',
                                                element: <VotingDetail />,
                                                loader: VotingDetailLoader,
                                                errorElement: <NotFound />
                                            }
                                        ]
                                    },
                                    {
                                        path: 'subjekty',
                                        element: <Outlet />,
                                        errorElement: <NotFound />,
                                        children: [
                                            {
                                                index: true,
                                                element: <Parties />,
                                                loader: CouncilCompositionLoader,
                                                errorElement: <NotFound />
                                            },
                                            {
                                                path: ':subjektId',
                                                element: <PartyDetail />,
                                                loader: PartyLoader,
                                                errorElement: <NotFound />
                                            }
                                        ]
                                    },
                                    {
                                        path: 'zastupitele',
                                        element: <Outlet />,
                                        errorElement: <NotFound />,
                                        children: [
                                            {
                                                index: true,
                                                element: <Members />,
                                                loader: CouncilCompositionLoader,
                                                errorElement: <NotFound />
                                            },
                                            {
                                                path: ':zastupitelId',
                                                element: <MemberDetail />,
                                                loader: MemberLoader,
                                                errorElement: <NotFound />
                                            }
                                        ]
                                    },
                                    {
                                        path: 'zasedani',
                                        element: <Outlet />,
                                        errorElement: <NotFound />,
                                        children: [
                                            {
                                                index: true,
                                                element: <Zasedani />,
                                                loader: MeetingsLoader,
                                                errorElement: <NotFound />
                                            },
                                            {
                                                path: ':zasedaniId',
                                                element: <ZasedaniDetail />,
                                                loader: MeetingDetailLoader,
                                                errorElement: <NotFound />
                                            }
                                        ]
                                    },
                                    {
                                        path: 'analyzy',
                                        element: <Outlet />,
                                        errorElement: <NotFound />,
                                        children: [
                                            {
                                                index: true,
                                                element: <Analyses />,
                                                errorElement: <NotFound />
                                            },
                                            {
                                                path: 'dochazka',
                                                element: <AttendanceAnalysis />,
                                                errorElement: <NotFound />
                                            },
                                            {
                                                path: 'porovnani_zastupitelu',
                                                element: <MembersComparison />,
                                                errorElement: <NotFound />
                                            },
                                            {
                                                path: 'nejednotnost',
                                                element: <PartiesUnity />,
                                                errorElement: <NotFound />
                                            },
                                            {
                                                path: 'koheze',
                                                element: <VotingUnity />,
                                                errorElement: <NotFound />
                                            }
                                        ]
                                    },
                                    {
                                        path: '*',
                                        element: <NotFound customError={404} />
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                path: 'datasety',
                element: <Page />,
                loader: DatasetLoader,
                errorElement: <NotFound />
            },
            {
                path: 'spoluprace',
                element: <Page />,
                loader: PartnersLoader,
                errorElement: <NotFound />
            },
            {
                path: 'o-projektu',
                element: <Page />,
                loader: AboutLoader,
                errorElement: <NotFound />
            },
            {
                path: '*',
                element: <NotFound customError={404} />
            }
        ]
    }
];

// TODO: This is a hardcoded solution. It should be set externally.
const rootRoute = routes.find((route) => route.path === '/');
stats !== undefined &&
    rootRoute.children.push({
        path: 'analyzy',
        element: <Outlet />,
        errorElement: <NotFound />,
        children: [
            {
                index: true,
                element: <Page />,
                loader: StatsLoader,
                errorElement: <NotFound />
            },
            {
                path: 'detail',
                element: <Page />,
                errorElement: <NotFound />
            }
        ]
    });

export default routes;
