import React from 'react';
import { Link, useParams, useRouteError, isRouteErrorResponse } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isAxiosError } from 'axios';

function NotFound({ customError }) {
    const { municipalitaId, bodyType, zastupitelstvoId } = useParams();
    const to =
        municipalitaId !== undefined && bodyType !== undefined && zastupitelstvoId !== undefined
            ? `/${municipalitaId}/${bodyType}/${zastupitelstvoId}`
            : '/';

    const { t } = useTranslation();
    const error = useRouteError();

    var msg = ' ';
    var state;

    if (isRouteErrorResponse(error)) {
        // React Router Error
        if (error.status === 401) {
            state = 400;
            msg = 'Požadavek nemůže být vyřízen.';
        }

        if (error.status === 401) {
            state = 401;
            msg = 'Neautorizovaný přístup.';
        }

        if (error.status === 404) {
            state = 404;
            msg = t('page-not-found');
        }

        if (error.status === 500) {
            state = 500;
            msg = 'Při zpracování požadavku došlo ke blíže nespecifikované chybě.';
        }

        if (error.status === 503) {
            state = 503;
            msg = 'Služba momentálně není k dispozici.';
        }
    } else if (isAxiosError(error)) {
        // Axios Error
        if (error.code === 'ERR_NETWORK') {
            state = 500;
            msg = 'Při zpracování požadavku došlo ke blíže nespecifikované chybě.';
        }
        if (error.code === 'ERR_BAD_REQUEST' || error.code === 'ERR_BAD_RESPONSE') {
            state = 404;
            msg = t('page-not-found');
        }
    } else if (customError) {
        if (customError === 404) {
            state = 404;
            msg = t('page-not-found');
        }
    }

    return (
        <>
            <div className='not-found'>
                <span className='content'>
                    <i className='pi bi-emoji-frown icon'></i>
                    {/*title*/}
                    <span className='title'>{state}</span>
                    <div className='text'>
                        {msg}

                        {state === 404 ? (
                            <>
                                <br />
                                {t('you-can-return')}{' '}
                                <Link className='link' to={to} key='home'>
                                    {t('homepage')}
                                </Link>
                                .
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                </span>
            </div>
        </>
    );
}

export default NotFound;
