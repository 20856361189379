import React, { useEffect, useState } from 'react';
import { ButtonClose, ButtonEdit } from '../../../components/Buttons';
import { ModalDialog } from '../../../components/ModalDialog';
import { MemberOrderTable } from './MemberOrderTable';
import { Messages } from 'primereact/messages';
import { PrimeIcons } from 'primereact/api';

const orderSorted = (members) => {
    return members ? members.toSorted((a, b) => a.order - b.order) : [];
};

export const MemberOrderDialog = ({ isVisible, members, loading, onHide, onConfirm, processing, msgs }) => {
    const [orderedMembers, setOrderedMembers] = useState(orderSorted(members));

    useEffect(() => {
        setOrderedMembers(orderSorted(members));
    }, [members]);

    const onRowReorder = (e) => {
        setOrderedMembers(e.value);
    };

    return (
        <ModalDialog
            visible={isVisible}
            header='Nastavit pořadí členů'
            footer={
                <>
                    <ButtonClose label='Zavřít' onClick={onHide} />
                    <ButtonEdit
                        label='Nastavit zvolené řazení'
                        icon={PrimeIcons.SORT_NUMERIC_DOWN}
                        onClick={() => onConfirm(orderedMembers)}
                        disabled={loading || processing}
                        loading={loading || processing}
                    />
                </>
            }
            onHide={onHide}
        >
            <Messages ref={msgs} className='flex-center' />
            <MemberOrderTable
                members={orderedMembers}
                loading={loading}
                disabled={processing}
                onRowReorder={onRowReorder}
            />
        </ModalDialog>
    );
};
