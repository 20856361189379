import { Messages } from 'primereact/messages';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorMessage } from '../../components/ErrorMessage';
import LoadingPage from '../../../components/LoadingPage';
import { getErrorMessage } from '../../../utils/errors';
import { showErrorMessage, showSuccessMessage } from '../../utils/messages';
import { AliasTable } from './components/AliasTable';
import { MembershipTable } from './components/MembershipTable';
import { MemberEditForm } from './components/MemberEditForm';
import authAxios from '../../lib/authAxios';
import { formatMemberName } from '../../../utils/texts';
import { LineDivider } from '../../components/LineDivider';
import { MemberBreadCrumb } from './components/MemberBreadCrumb';

export default function MemberEdit() {
    const { municipalityId, memberId } = useParams();
    const bodyType = 'zastupitelstvo';

    const msgs = useRef(null);

    const [member, setMember] = useState(null);
    const [loading, setLoading] = useState(true);
    const [initError, setInitError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await authAxios.get(`admin/${municipalityId}/${bodyType}/member/${memberId}`);
                setMember(response.data);
            } catch (error) {
                const errorMessage = getErrorMessage(error);
                setInitError(errorMessage ?? 'Nepodařilo se načíst zvoleného člena/členku');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [municipalityId, memberId]);

    const onSubmit = async (data) => {
        const requestData = {
            first_name: data.first_name,
            last_name: data.last_name,
            sex: data.sex
        };
        try {
            await authAxios.put(`admin/${municipalityId}/${bodyType}/member/${memberId}`, requestData);
            showSuccessMessage(msgs, requestData.sex ? 'Členka byla úspěšně upravena' : 'Člen byl úspěšně upraven');
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se upravit člena/členku');
        }
    };

    return loading ? (
        <LoadingPage />
    ) : initError ? (
        <>
            <MemberBreadCrumb />
            <h1>Upravit člena/členku</h1>

            <ErrorMessage text={initError} />
        </>
    ) : (
        <>
            <MemberBreadCrumb
                extraItems={[{ label: 'Upravit', url: `/admin/municipality/${municipalityId}/clenove/${memberId}` }]}
            />
            <h1>
                Upravit {member.sex ? 'členku' : 'člena'} {formatMemberName(member.first_name, member.last_name)}
            </h1>

            <Messages ref={msgs} className='page__messages' />

            <MemberEditForm member={member} onSubmit={onSubmit} />
            <LineDivider />
            <MembershipTable />
            <LineDivider />
            <AliasTable member={member} />
        </>
    );
}
