export const datasetsDescriptionWidget = {
    type: "custom",
    config: {
        style: {
            textAlign: "justify",
            maxWidth: "1200px"
        },
        content: (
            <>
                <p>
                    Zvolení zastupitelé vykonávají velmi důležitou činnost. Rozhodují o&nbsp;tom, jakým směrem se bude dané město, kraj nebo stát ubírat.
                    Rozhodují o&nbsp;tom, jaké projekty budou realizovány, jaké služby budou poskytovány občanům nebo jakým způsobem se bude hospodařit a&nbsp;nakládat s&nbsp;veřejnými zdroji a&nbsp;majetkem.
                    Činnost zastupitelů si každý celek řeší vlastním způsobem. Používají různé nástroje a&nbsp;systémy pro zaznamenávání hlasování, které nicméně produkují data v&nbsp;odlišných podobách a&nbsp;formátech.
                    To výrazně zesložiťuje další zpracování, vyhodnocování a&nbsp;zejména chápání těchto dat.
                    Jedním z&nbsp;cílů tohoto projektu je standardizovat formát, ve kterém budou jednotlivé záznamy z&nbsp;hlasování zastupitelstev reprezentovány, poskytovat je v&nbsp;jednotné podobě a&nbsp;umožnit tak občanům lépe pracovat s&nbsp;těmito daty.
                </p>
            </>
        )
    }
}

export const datasetsImageWidget = {
    type: "image",
    config: {
        external: false,
        src: "/img/process.svg",
        style: {
            maxWidth: "800px"
        }
    }
}

export const datasetsProcessWidget = {
    type: "custom",
    config: {
        style: {
            textAlign: "justify",
            maxWidth: "1200px"
        },
        content: (
            <>
                <p>
                    V rámci publikace <a href="https://doi.org/10.1007/978-3-031-60221-4_38" target="_blank">Towards Transparent Governance: Unifying City Councils Decision-Making Data Processing and Visualization</a> jsme představili jednotný přístup ke zpracování a&nbsp;ukládání dat z&nbsp;rozhodování zastupitelstva.
                    Článek nastiňuje <a href="https://github.com/zastupko/data-model" target="_blank" rel="noreferrer">obecný datový model</a> určený pro široké použití napříč obcemi a představuje jádro tohoto systému, tedy část pracující s&nbsp;jednou entitou (v tomto případě municpalitou), s&nbsp;reálnými hlasováními Zastupitelstva města Brna.
                </p>
                <p>
                    Zveřejňovaná data jsou v různorodých formátech a&nbsp;typicky neobsahují všechny potřebné informace pro navazující analýzy. 
                    Je tedy nutné nastudovat, jaká zdrojová data jsou k dispozici, jakým způsobem je možné jejich strojové zpracování a&nbsp;zda lze dohledat chybějící informace (typicky např. informace o&nbsp;příslušnostech zastupitelů ke konkrétním politickým subjektům). 
                    Formát zdrojových dat se může lišit i&nbsp;mezi jednotlivými zasedáními, proto je třeba důkladná analýza. 
                    Následuje proces transformace dat, jehož výstupem je datová sada v&nbsp;generickém datovém modelu. 
                    Tato datová sada je následně importována do našeho systému pro vizualizace z&nbsp;hlasování zastupitelstev. 
                    A&nbsp;jelikož souzníme s myšlenkou otevřených dat, datové sady si nenecháváme pro sebe a&nbsp;umožňujeme jejich využití dalšími subjekty.
                </p>
            </>
        )
    }
}

export const datasetsSourcesSection = (licencesContent) => {
    return {
        id: "datasety-zdroje",
        heading: {
            label: "Zdroje dat"
        },
        content: {
            type: "widget-group",
            config: {
                style: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "2rem"
                },
                widgets: [
                    {
                        type: "custom",
                        config: {
                            style: {
                                textAlign: "justify",
                                maxWidth: "1200px"
                            },
                            content: licencesContent
                        }
                    }
                ]
            }
        }
    }
}


export const datasetsDescriptionSection = {
    id: "datasety-popis",
    heading: {
        label: "Význam datových sad"
    },
    content: {
        type: "widget-group",
        config: {
            style: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "2rem"
            },
            widgets: [
                datasetsDescriptionWidget,
                datasetsImageWidget,
            ]
        }
    }
}

export const datasetsProcessSection = {
    id: "datasety-proces",
    heading: {
        label: "Proces zpracování dat"
    },
    content: {
        type: "widget-group",
        config: {
            style: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "2rem"
            },
            widgets: [
                datasetsProcessWidget
            ]
        }
    }
}


export const datasets = (response, data) => {
    return {
        id: "datasety",
        sections: [
            {
                id: "datasety-seznam",
                noAnchor: true,
                content: {
                    type: "dataset-list",
                    config: {
                        datasets: data
                    }
                }
            },
            datasetsDescriptionSection,
            datasetsProcessSection,
            datasetsSourcesSection(data.licencesContent)
        ]
    }
}
export default datasets;