import config_brno from './brno/config';
import config_kraje from './kraje/config';
import config_lab from './lab/config';
import config_mesta from './mesta/config';
import config_most from './most/config';
import config_zastupko from './zastupko/config';

const config = (subdomain) => {

    let subdomainConfig = {};
    switch(subdomain) {
        case "":
            subdomainConfig = config_zastupko;
        case "brno":
            subdomainConfig = config_brno;
        case "kraje":
            subdomainConfig = config_kraje;
        case "lab":
            subdomainConfig = config_lab;
        case "mesta":
            subdomainConfig = config_mesta;
        case "most":
            subdomainConfig = config_most;
    }

    return {
        logo: "/loga/title/zastupko/small.svg",
        ...subdomainConfig
    };
}
export default config;