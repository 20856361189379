import React, { useState } from "react";
import { Link, useLoaderData, useParams } from "react-router-dom";
import { Dropdown, Card, Toolbar, Message, Tooltip } from "../../components/PrimereactComponents";
import { getBodyNameGenitive } from '../../utils/texts';
import { useTranslation } from "react-i18next";

function Zasedani() {
  const [selectedYear, setSelectedYear] = useState(undefined);
  const [displayAll, setDisplayAll] = useState(true);

  const { bodyType } = useParams(); 

  const { t } = useTranslation(['translation', 'meetings']);

  const response = useLoaderData();
  const [sessionsData] = useState(response[0].data);
  const municipality = response[1].data;
  const options = response[2].data;

  const onOptionChange = (e) => {
    if(e.value === undefined){
      setDisplayAll(true);
      setSelectedYear(undefined);
    } else{
      setDisplayAll(false);
      setSelectedYear(e.value);
    }
  };

  if (
    sessionsData !== undefined &&
    municipality !== undefined &&
    options !== undefined
  ) {

    sessionsData.map(
      (session) => (session.dochazka = Math.round((session.ucast / session.celkem) * 100))
    );

  const leftContents = (
    <>
    <span className="p-float-label">
    <Dropdown
      showClear
      value={selectedYear}
      options={options}
      onChange={onOptionChange}
      optionLabel="name"
      inputId="yearSelection"
    />
    <label htmlFor="yearSelection">{t('select-year-meeting')}</label>
    </span>    
    </>
  )

	const organ = municipality.organy[bodyType];

  let cnt = 0;
  if(!displayAll){
    cnt = sessionsData.map((session) => (session.datum.includes(selectedYear.code) ? 1 : 0)).reduce((partialSum, a) => partialSum + a, 0);
  }

  const cardTemplate = (session) => {
    if (!displayAll && !session.datum.includes(selectedYear.code)) {
      return null;
    }

    return (
      <Link className="item-card" key={"zasedani" + session.cislo_zasedani} to={`${parseInt(session.id)}`}>
        <Card className="hoverable-card" header={<h3>{t('meeting-no')} {session.cislo_zasedani}</h3>}>
          <div className="card-content">
            <p>
              <i className="pi bi-calendar font-semibold text-icon__left text__primary"></i>
              <span className="text__primary">{session.datum}</span>
            </p>
            <p>
              <i className="pi bi-check2-square font-semibold text-icon__left text__primary"></i>
              <span className="text__primary">{session.pocet_platnych_hlasovani}</span>&nbsp;{t('valid-votings')}
            </p>
            <p>
              <i className="pi pi-users font-semibold text-icon__left text__primary"></i>
              <span className="text__primary">{Math.round((session.ucast / session.celkem) * 100)}%</span> {t('attendanceOfMembers')}
            </p>
          </div>
        </Card>
      </Link>
    );
  };

    return (
      <div className="meetings">
        {/**
          <h1>{t('Meetings')}
          <i
          className='custom-target-icon pi bi-info-circle parties__chart-icon'
          data-pr-tooltip={t('in-term', {ns: 'meetings', num: sessionsData.length, where: organ.misto, type: getBodyNameGenitive(bodyType)})}
          data-pr-position='bottom'
          ></i>
          </h1>
          <Tooltip target='.custom-target-icon'/>
      */}
        <Toolbar left={leftContents} /> 

        {!displayAll ? 
        <div className="message-wrapper">
        <Message severity="info" text={
          t('selected-year', {ns: "meetings", count: cnt})}/>
          </div>
          : ''}
        <div className="meetings-list">
          {sessionsData.length === 0 ? <Message severity="info" text="Nebylo nalezeno žádné zasedání."/> : 
          sessionsData.map((session) =>
            displayAll ?
              cardTemplate(session)
            :
            session.datum.includes(selectedYear.code) ? (
              cardTemplate(session)
            ) : <></>,
          )}
        </div>
      </div>
    );
  }
}

export default Zasedani;
