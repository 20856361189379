export const kraje = {
    jihocesky: {
        id: 1,
        text_id: "jihocesky",
        typ_nazev: "Kraj",
        nazev: "Jihočeský kraj",
        nazev_genitiv: "Jihočeského Kraje",
        nazev_kratky: "Jihočeský",
        logo: "flask/municipality/jihocesky/logo",
        barva: "#2bc231",
        deploy: {
            local: {
                subdomain: "kraje",
                path: "jihocesky"
            }
        },
        url: "https://www.kraj-jihocesky.cz/",
        email: null,
        partner: false,
        uplnyDataset: true,
        organy: {
            zastupitelstvo: {
                nazev: "Zastupitelstvo Jihočeského kraje",
                nazev_genitiv: "Zastupitelstva Jihočeského kraje",
                misto: " ",
                pocet_zastupitelu: 55,
                nazev_aplikace: "Vizualizace hlasování Zastupitelstva Jihočeského kraje",
                popis_aplikace: "Přehledné vizualizace Zastupitelstva Jihočeského kraje pro širokou veřejnost"
            }
        }
    },
    jihomoravsky: {
        id: 7,
        text_id: "jihomoravsky",
        typ_nazev: "Kraj",
        nazev: "Jihomoravský kraj",
        nazev_genitiv: "Jihomoravského Kraje",
        nazev_kratky: "Jihomoravský",
        logo: "flask/municipality/jihomoravsky/logo",
        barva: "#2bc231",
        deploy: {
            local: {
                subdomain: "kraje",
                path: "jihomoravsky"
            }
        },
        url: "https://www.jmk.cz/",
        uplnyDataset: true,
        email: null,
        partner: false,
        organy: {
            zastupitelstvo: {
                nazev: "Zastupitelstvo Jihomoravského kraje",
                nazev_genitiv: "Zastupitelstva Jihomoravského kraje",
                misto: "",
                pocet_zastupitelu: 65,
                nazev_aplikace: "Vizualizace hlasování Zastupitelstva Jihomoravského kraje",
                popis_aplikace: "Přehledné vizualizace Zastupitelstva Jihomoravského kraje pro širokou veřejnost"
            }
        }
    },
    karlovarsky: {
        id: 10,
        text_id: "karlovarsky",
        typ_nazev: "Kraj",
        nazev: "Karlovarský kraj",
        nazev_genitiv: "Karlovarského Kraje",
        nazev_kratky: "Karlovarský",
        logo: "flask/municipality/karlovarsky/logo",
        barva: "#FF0000",
        deploy: {
            local: {
                subdomain: "kraje",
                path: "karlovarsky"
            }
        },
        url: "https://www.kr-karlovarsky.cz/",
        uplnyDataset: false,
        email: null,
        partner: false,
        organy: {
            zastupitelstvo: {
                nazev: "Zastupitelstvo Karlovarského kraje",
                nazev_genitiv: "Zastupitelstva Karlovarského kraje",
                misto: "",
                pocet_zastupitelu: 45,
                nazev_aplikace: "Vizualizace hlasování Zastupitelstva Karlovarského kraje",
                popis_aplikace: "Přehledné vizualizace Zastupitelstva Karlovarského kraje pro širokou veřejnost"
            }
        }
    },
    vysocina: {
        id: 6,
        text_id: "vysocina",
        typ_nazev: "Kraj",
        nazev: "Kraj Vysočina",
        nazev_genitiv: "Kraje Vysočina",
        nazev_kratky: "Vysočina",
        logo: "flask/municipality/vysocina/logo",
        barva: "#2bc231",
        deploy: {
            local: {
                subdomain: "kraje",
                path: "vysocina"
            }
        },
        url: "https://www.kr-vysocina.cz/",
        uplnyDataset: true,
        email: null,
        partner: false,
        organy: {
            zastupitelstvo: {
                nazev: "Zastupitelstvo kraje Vysočina",
                nazev_genitiv: "Zastupitelstva kraje Vysočina",
                misto: "",
                pocet_zastupitelu: 45,
                nazev_aplikace: "Vizualizace hlasování Zastupitelstva kraje Vysočina",
                popis_aplikace: "Přehledné vizualizace Zastupitelstva kraje Vysočina pro širokou veřejnost"
            }
        }
    },
    kralovehradecky: {
        id: 2,
        text_id: "kralovehradecky",
        typ_nazev: "Kraj",
        nazev: "Královehradecký kraj",
        nazev_genitiv: "Královehradeckého kraje",
        nazev_kratky: "Královehradecký",
        logo: "flask/municipality/kralovehradecky/logo",
        barva: "#2bc231",
        deploy: {
            local: {
                subdomain: "kraje",
                path: "kralovehradecky"
            }
        },
        url: "https://www.khk.cz/",
        uplnyDataset: true,
        email: null,
        partner: false,
        organy: {
            zastupitelstvo: {
                nazev: "Zastupitelstvo Královehradeckého kraje",
                nazev_genitiv: "Zastupitelstva Královehradeckého kraje",
                misto: "",
                pocet_zastupitelu: 45,
                nazev_aplikace: "Vizualizace hlasování Zastupitelstva Královehradeckého kraje",
                popis_aplikace: "Přehledné vizualizace Zastupitelstva Královehradeckého kraje pro širokou veřejnost"
            }
        }
    },
    liberecky: {
        id: 4,
        text_id: "liberecky",
        typ_nazev: "Kraj",
        nazev: "Liberecký kraj",
        nazev_genitiv: "Libereckého Kraje",
        nazev_kratky: "Liberecký",
        logo: "flask/municipality/liberecky/logo",
        barva: "#FF0000",
        deploy: {
            local: {
                subdomain: "kraje",
                path: "liberecky"
            }
        },
        url: "https://www.kraj-lbc.cz/",
        uplnyDataset: false,
        email: null,
        partner: false,
        organy: {
            zastupitelstvo: {
                nazev: "Zastupitelstvo Libereckého kraje",
                nazev_genitiv: "Zastupitelstva Libereckého kraje",
                misto: "",
                pocet_zastupitelu: 45,
                nazev_aplikace: "Vizualizace hlasování Zastupitelstva Libereckého kraje",
                popis_aplikace: "Přehledné vizualizace Zastupitelstva Libereckého kraje pro širokou veřejnost"
            }
        }
    },
    moravskoslezsky: {
        id: 9,
        text_id: "moravskoslezsky",
        typ_nazev: "Kraj",
        nazev: "Moravskoslezský kraj",
        nazev_genitiv: "Moravskoslezského Kraje",
        nazev_kratky: "Moravskoslezský",
        logo: "flask/municipality/moravskoslezsky/logo",
        barva: "#2bc231",
        deploy: {
            local: {
                subdomain: "kraje",
                path: "moravskoslezsky"
            }
        },
        url: "https://www.msk.cz/",
        uplnyDataset: true,
        email: null,
        partner: false,
        organy: {
            zastupitelstvo: {
                nazev: "Zastupitelstvo Moravskoslezského kraje",
                nazev_genitiv: "Zastupitelstva Moravskoslezského kraje",
                misto: "",
                pocet_zastupitelu: 65,
                nazev_aplikace: "Vizualizace hlasování Zastupitelstva Moravskoslezského kraje",
                popis_aplikace: "Přehledné vizualizace Zastupitelstva Moravskoslezského kraje pro širokou veřejnost"
            }
        }
    },
    olomoucky: {
        id: 8,
        text_id: "olomoucky",
        typ_nazev: "Kraj",
        nazev: "Olomoucký kraj",
        nazev_genitiv: "Olomouckého Kraje",
        nazev_kratky: "Olomoucký",
        logo: "flask/municipality/olomoucky/logo",
        barva: "#2bc231",
        deploy: {
            local: {
                subdomain: "kraje",
                path: "olomoucky"
            }
        },
        url: "https://www.olkraj.cz/",
        uplnyDataset: true,
        email: null,
        partner: false,
        organy: {
            zastupitelstvo: {
                nazev: "Zastupitelstvo Olomouckého kraje",
                nazev_genitiv: "Zastupitelstva Olomouckého kraje",
                misto: "",
                pocet_zastupitelu: 55,
                nazev_aplikace: "Vizualizace hlasování Zastupitelstva Olomouckého kraje",
                popis_aplikace: "Přehledné vizualizace Zastupitelstva Olomouckého kraje pro širokou veřejnost"
            }
        }
    },
    pardubicky: {
        id: 5,
        text_id: "pardubicky",
        typ_nazev: "Kraj",
        nazev: "Pardubický Kraj",
        nazev_genitiv: "Pardubického Kraje",
        nazev_kratky: "Pardubický",
        logo: "flask/municipality/pardubicky/logo",
        barva: "#2bc231",
        deploy: {
            local: {
                subdomain: "kraje",
                path: "pardubicky"
            }
        },
        url: "https://www.pardubickykraj.cz/",
        uplnyDataset: true,
        email: null,
        partner: false,
        organy: {
            zastupitelstvo: {
                nazev: "Zastupitelstvo Pardubického Kraje",
                nazev_genitiv: "Zastupitelstva Pardubického Kraje",
                misto: "",
                pocet_zastupitelu: 45,
                nazev_aplikace: "Vizualizace hlasování Zastupitelstva Pardubického kraje",
                popis_aplikace: "Přehledné vizualizace Zastupitelstva Pardubického kraje pro širokou veřejnost"
            }
        }
    },
    plzensky: {
        id: 3,
        text_id: "plzensky",
        typ_nazev: "Kraj",
        nazev: "Plzeňský kraj",
        nazev_genitiv: "Plzeňského Kraje",
        nazev_kratky: "Plzeňský",
        logo: "flask/municipality/plzensky/logo",
        barva: "#2bc231",
        deploy: {
            local: {
                subdomain: "kraje",
                path: "plzensky"
            }
        },
        url: "https://www.plzensky-kraj.cz/",
        uplnyDataset: true,
        email: null,
        partner: false,
        organy: {
            zastupitelstvo: {
                nazev: "Zastupitelstvo Plzeňského Kraje",
                nazev_genitiv: "Zastupitelstva Plzeňského Kraje",
                misto: "",
                pocet_zastupitelu: 45,
                nazev_aplikace: "Vizualizace hlasování Zastupitelstva Plzeňského kraje",
                popis_aplikace: "Přehledné vizualizace Zastupitelstva Plzeňského kraje pro širokou veřejnost"
            }
        }
    },
    stredocesky: {
        id: 12,
        text_id: "stredocesky",
        typ_nazev: "Kraj",
        nazev: "Středočeský kraj",
        nazev_genitiv: "Středočeského Kraje",
        nazev_kratky: "Středočeský",
        logo: "flask/municipality/stredocesky/logo",
        barva: "#3a1818",
        deploy: {
            local: {
                subdomain: "kraje",
                path: "stredocesky"
            }
        },
        url: "https://kr-stredocesky.cz/",
        uplnyDataset: false,
        email: null,
        partner: false,
        organy: {
            zastupitelstvo: {
                nazev: "Zastupitelstvo Středočeského kraje",
                nazev_genitiv: "Zastupitelstva Středočeského kraje",
                misto: "",
                pocet_zastupitelu: 65,
                nazev_aplikace: "Vizualizace hlasování Zastupitelstva Středočeského kraje",
                popis_aplikace: "Přehledné vizualizace Zastupitelstva Středočeského kraje pro širokou veřejnost"
            }
        }
    },
    ustecky: {
        id: 11,
        text_id: "ustecky",
        typ_nazev: "Kraj",
        nazev: "Ústecký kraj",
        nazev_genitiv: "Ústeckého Kraje",
        nazev_kratky: "Ústecký",
        logo: "flask/municipality/ustecky/logo",
        barva: "#3a1818",
        deploy: {
            local: {
                subdomain: "kraje",
                path: "ustecky"
            }
        },
        url: "https://www.kr-ustecky.cz/",
        uplnyDataset: false,
        email: null,
        partner: false,
        organy: {
            zastupitelstvo: {
                nazev: "Zastupitelstvo Ústeckého kraje",
                nazev_genitiv: "Zastupitelstva Ústeckého kraje",
                misto: "",
                pocet_zastupitelu: 55,
                nazev_aplikace: "Vizualizace hlasování Zastupitelstva Ústeckého kraje",
                popis_aplikace: "Přehledné vizualizace Zastupitelstva Ústeckého kraje pro širokou veřejnost"
            }
        }
    },
    zlinsky: {
        id: 13,
        text_id: "zlinsky",
        typ_nazev: "Kraj",
        nazev: "Zlínský kraj",
        nazev_genitiv: "Zlínského Kraje",
        nazev_kratky: "Zlínský",
        logo: "flask/municipality/zlinsky/logo",
        barva: "#3a1818",
        deploy: {
            local: {
                subdomain: "kraje",
                path: "zlinsky"
            }
        },
        url: "https://zlinskykraj.cz/",
        uplnyDataset: false,
        email: null,
        partner: false,
        organy: {
            zastupitelstvo: {
                nazev: "Zastupitelstvo Zlínského kraje",
                nazev_genitiv: "Zastupitelstva Zlínského kraje",
                misto: "",
                pocet_zastupitelu: 45,
                nazev_aplikace: "Vizualizace hlasování Zastupitelstva Zlínského kraje",
                popis_aplikace: "Přehledné vizualizace Zastupitelstva Zlínského kraje pro širokou veřejnost"
            }
        }
    }
}

export const krajeList = [
    kraje.jihocesky,
    kraje.jihomoravsky,
    kraje.karlovarsky,
    kraje.vysocina,
    kraje.kralovehradecky,
    kraje.liberecky,
    kraje.moravskoslezsky,
    kraje.olomoucky,
    kraje.pardubicky,
    kraje.plzensky,
    kraje.stredocesky,
    kraje.ustecky,
    kraje.zlinsky
]