import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, FormInput, FormRow, Label } from '../../../components/form';
import { RHFPassword } from '../../../components/rhf';
import { ButtonEdit } from '../../../components/Buttons';
import { getFormErrorMessage } from '../../../utils/errors';
import { PrimeIcons } from 'primereact/api';
import { MIN_PASSWORD_LENGTH } from '../../../constants/constants';

export const ChangePasswordForm = ({ onSubmit, disabled }) => {
    const defaultValues = {
        password: '',
        new_password: '',
        new_password_confirm: ''
    };

    const {
        control,
        formState: { errors, isSubmitting, isSubmitSuccessful },
        handleSubmit,
        reset
    } = useForm({
        disabled: disabled,
        defaultValues: defaultValues
    });

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset();
        }
    }, [isSubmitSuccessful, reset]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer type='card'>
                <FormRow type='subheading'>Změnit heslo</FormRow>
                <FormRow>
                    <FormInput>
                        <Label htmlFor='password' text='Současné heslo' required />
                        <RHFPassword
                            control={control}
                            name='password'
                            rules={{
                                required: 'Zadejte heslo'
                            }}
                            placeholder='Současné heslo'
                        />
                        {getFormErrorMessage(errors, 'password')}
                    </FormInput>
                </FormRow>
                <FormRow>
                    <FormInput>
                        <Label htmlFor='new_password' text='Nové heslo' required />
                        <RHFPassword
                            control={control}
                            name='new_password'
                            rules={{
                                required: 'Zadejte heslo',
                                minLength: {
                                    value: MIN_PASSWORD_LENGTH,
                                    message: `Heslo musí mít alespoň ${MIN_PASSWORD_LENGTH} znaků`
                                }
                            }}
                            placeholder='Nové heslo'
                        />
                        {getFormErrorMessage(errors, 'new_password')}
                    </FormInput>
                </FormRow>
                <FormRow>
                    <FormInput>
                        <Label htmlFor='new_password_confirm' text='Zadejte heslo znovu' required />
                        <RHFPassword
                            control={control}
                            name='new_password_confirm'
                            rules={{
                                required: 'Zadejte heslo',
                                validate: (value, formValues) =>
                                    value === formValues.new_password || 'Hesla se musí shodovat'
                            }}
                            placeholder='Heslo'
                        />
                        {getFormErrorMessage(errors, 'new_password_confirm')}
                    </FormInput>
                </FormRow>
                <FormRow type='button'>
                    <ButtonEdit
                        type='submit'
                        icon={PrimeIcons.KEY}
                        label='Změnit heslo'
                        disabled={disabled}
                        loading={isSubmitting}
                    />
                </FormRow>
            </FormContainer>
        </form>
    );
};
