import React from 'react';
import { DataTable, Column } from '../../../../components/PrimereactComponents';
import { Link, useParams } from 'react-router-dom';
import { BaseChip } from '../../../components/Chips';

export const StaticTermMemberTable = ({ members }) => {
    const { municipalityId } = useParams();

    const MemberLink = ({ id, children }) => {
        return <Link to={`/admin/municipality/${municipalityId}/clenove/${id}`}>{children}</Link>;
    };

    const idTemplate = (rowData) => {
        return <MemberLink id={rowData.id}>{rowData.id}</MemberLink>;
    };

    const firstNameTemplate = (rowData) => {
        return <MemberLink id={rowData.id}>{rowData.first_name}</MemberLink>;
    };

    const lastNameTemplate = (rowData) => {
        return <MemberLink id={rowData.id}>{rowData.last_name}</MemberLink>;
    };

    const formatMembershipDuration = (dateFrom, dateTo) => {
        if (dateFrom && dateTo) return ` (${dateFrom} - ${dateTo})`;
        else if (dateFrom) return ` (od ${dateFrom})`;
        else if (dateTo) return ` (do ${dateTo})`;
        else return '';
    };

    const membershipTemplate = (rowData) => {
        return (
            <div className='membership-chips'>
                {rowData.memberships.map((membership) => (
                    <BaseChip
                        key={membership.id}
                        label={`${membership.political_entity.abbreviation} ${formatMembershipDuration(
                            membership.date_from,
                            membership.date_to
                        )}`}
                    />
                ))}
            </div>
        );
    };

    return (
        <DataTable
            value={members}
            removableSort
            sortField='last_name'
            sortOrder={1}
            breakpoint='900px'
            emptyMessage='K funkčnímu období nepřísluší žádní členové. Členy je možné vložit při zadávání zasedání.'
        >
            <Column field='id' header='ID' body={idTemplate} sortable />
            <Column field='first_name' header='Jméno' body={firstNameTemplate} sortable />
            <Column field='last_name' header='Příjmení' body={lastNameTemplate} sortable />
            <Column header='Členství' body={membershipTemplate} />
        </DataTable>
    );
};
