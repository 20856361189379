import APP from "../config";
import partners_default from "./default/partners";

const getPartners = (response) => {
    switch(APP.subdomain) {
        default:
            return partners_default(response);
    }
}

const partners = (response) => getPartners(response);
export default partners;