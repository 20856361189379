import { Messages } from 'primereact/messages';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonAdd, ButtonEdit } from '../../../components/Buttons';
import { FormContainer, FormInput, FormRow, Label } from '../../../components/form';
import { ModalDialog } from '../../../components/ModalDialog';
import { RHFInputText, RHFSketchPicker } from '../../../components/rhf';
import { DEFAULT_COLOR } from '../../../constants/constants';
import { POLITICKY_SUBJEKT } from '../../../constants/field_lengths';
import { getFormErrorMessage, maxLengthMessage } from '../../../utils/errors';

export const PoliticalEntityDialog = forwardRef(
    ({ isVisible, onHide, onSubmitAdd, onSubmitEdit, editPoliticalEntity, msgs }, ref) => {
        const [processing, setProcessing] = useState(false);

        useImperativeHandle(ref, () => ({
            reset: reset
        }));

        const formId = 'political-entity-dialog';

        const edit = !!editPoliticalEntity;

        const onSubmit = edit ? onSubmitEdit : onSubmitAdd;

        const defaultValues = useMemo(
            () => ({
                abbreviation: '',
                name: '',
                color: DEFAULT_COLOR
            }),
            []
        );

        const {
            control,
            formState: { errors, isSubmitting },
            handleSubmit,
            reset
        } = useForm({
            disabled: processing,
            defaultValues: defaultValues
        });

        useEffect(() => {
            setProcessing(isSubmitting);
        }, [isSubmitting]);

        useEffect(() => {
            if (editPoliticalEntity) {
                reset({
                    abbreviation: editPoliticalEntity.abbreviation,
                    name: editPoliticalEntity.name,
                    color: editPoliticalEntity.color
                });
            } else {
                reset(defaultValues);
            }
        }, [editPoliticalEntity, reset, defaultValues]);

        return (
            <ModalDialog
                visible={isVisible}
                header={edit ? 'Upravit politický subjekt' : 'Přidat politický subjekt'}
                style={{ width: 350 }}
                footer={
                    edit ? (
                        <ButtonEdit label='Upravit' type='submit' form={formId} />
                    ) : (
                        <ButtonAdd label='Přidat' type='submit' form={formId} />
                    )
                }
                onHide={onHide}
            >
                <Messages ref={msgs} className='flex-center' />

                <form onSubmit={handleSubmit(onSubmit)} id={formId}>
                    <FormContainer type='dialog'>
                        <FormRow>
                            <FormInput>
                                <Label htmlFor='name' text='Název' />
                                <RHFInputText
                                    control={control}
                                    name='name'
                                    rules={{
                                        maxLength: {
                                            value: POLITICKY_SUBJEKT.NAZEV,
                                            message: maxLengthMessage(POLITICKY_SUBJEKT.NAZEV)
                                        }
                                    }}
                                />
                            </FormInput>
                        </FormRow>
                        <FormRow>
                            <FormInput>
                                <Label htmlFor='abbreviation' text='Zkratka' required />
                                <RHFInputText
                                    control={control}
                                    name='abbreviation'
                                    rules={{
                                        required: 'Zadejte zkratku politického subjektu',
                                        maxLength: {
                                            value: POLITICKY_SUBJEKT.ZKRATKA,
                                            message: maxLengthMessage(POLITICKY_SUBJEKT.ZKRATKA)
                                        }
                                    }}
                                />
                                {getFormErrorMessage(errors, 'abbreviation')}
                            </FormInput>
                        </FormRow>
                        <FormRow>
                            <FormInput>
                                <Label htmlFor='color' text='Barva' required />
                                <RHFSketchPicker
                                    control={control}
                                    name='color'
                                    rules={{
                                        required: 'Vyberte barvu'
                                    }}
                                />
                                {getFormErrorMessage(errors, 'color')}
                            </FormInput>
                        </FormRow>
                    </FormContainer>
                </form>
            </ModalDialog>
        );
    }
);
