import { Messages } from 'primereact/messages';
import React, { useEffect, useRef, useState } from 'react';
import { getErrorMessage } from '../../../utils/errors';
import authAxios from '../../lib/authAxios';
import { showErrorMessage } from '../../utils/messages';
import { UserTable } from './components/UserTable';

export default function Users() {
    const [users, setUsers] = useState(null);
    const [loading, setLoading] = useState(true);

    const msgs = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await authAxios.get('admin/user');
                setUsers(response.data);
            } catch (error) {
                const errorMessage = getErrorMessage(error);
                showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se načíst uživatele');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <h1>Uživatelé</h1>

            <Messages ref={msgs} className='page__messages' />

            <UserTable users={users} loading={loading} />
        </>
    );
}
