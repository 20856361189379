import React, { useState } from 'react';
import { Link, useLoaderData, useParams } from 'react-router-dom';
import { redirectErrorPage } from '../../utils/errors';
import { TabView, TabPanel, Fieldset, Tooltip, Card } from '../../components/PrimereactComponents';
import useDimensions from '../../hooks/useDimensions';
import ChartComponent from '../../components/ChartComponent';
import { useTranslation } from 'react-i18next';
import { formatMemberName } from '../../utils/texts';
import { AliasTooltip } from '../../components/AliasTooltip';

function ZasedaniDetail(props) {
    const [activeTab, setActiveTab] = useState(undefined);
    const { width } = useDimensions();

    const { t } = useTranslation();

    const { zastupitelstvoId, zasedaniId } = useParams();

    const response = useLoaderData();
    const sessionData = response[0].data;
    const attendanceData = response[1].data;

    if (sessionData !== undefined && attendanceData !== undefined) {
        if (
            JSON.stringify(sessionData) === '{"error":"neexistujici ID"}' ||
            JSON.stringify(attendanceData) === '{"error":"neexistujici ID"}'
        ) {
            redirectErrorPage(props.municipalitaId, zastupitelstvoId);
        } else {
            const videoLen = sessionData.video_zaznam.length;
            const options = ['přítomnost', 'částečná přítomnost', 'nepřítomnost'];

            let datasetsDataSummaryAttendance = attendanceData['prehled'].map((item, index) => ({
                type: 'bar',
                label: item.strana_zkratka,
                backgroundColor: item.strana_barva,
                data: item.hodnoty
            }));

            let summaryAttendanceData = {
                labels: [
                    'přítomnost (' + attendanceData['sumarizace']['přítomnost'] + ')',
                    ['částečná', 'přítomnost (' + attendanceData['sumarizace']['částečná přítomnost'] + ')'],
                    'nepřítomnost (' + attendanceData['sumarizace']['nepřítomnost'] + ')'
                ],
                datasets: datasetsDataSummaryAttendance
            };

            //let maxX = Math.max(attendanceData['sumarizace']['přítomnost'], attendanceData['sumarizace']['částečná přítomnost'], attendanceData['sumarizace']['nepřítomnost']);

            /** 
                function updateStyles(id, removeClass, addClass) {
                    const elemToHide = document.getElementById(id);
                    elemToHide.classList.remove(removeClass);
                    elemToHide.classList.add(addClass);
                }*/

            const stackedOptions = {
                indexAxis: width <= 576 ? 'x' : 'y',
                maintainAspectRatio: false,
                aspectRatio: 0.8,
                plugins: {
                    tooltips: {
                        mode: 'index',
                        intersect: false
                    }
                },
                scales: {
                    x: {
                        stacked: true
                        //max: maxX,
                    },
                    y: {
                        stacked: true,
                        position: 'left'
                    }
                },
                onHover: function (evt, item) {
                    if (item[0] !== undefined) {
                        evt.native.target.style.cursor = 'pointer';
                    } else {
                        evt.native.target.style.cursor = 'default';
                    }
                },
                onClick: function (evt, item) {
                    if (item[0] !== undefined) {
                        setActiveTab(item[0].index);
                    }
                }
            };

            return (
                <div className='meeting-detail'>
                    <h1>
                        {t('meeting-no')} {sessionData.cislo_zasedani}
                    </h1>
                    <div className='subtitle'>
                        {sessionData.video_zaznam.map((record, i) => (
                            <a
                                key='link'
                                href={sessionData.video_zaznam[0].url}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='text__primary'
                            >
                                {t('meeting-material')}
                            </a>
                        ))}
                    </div>

                    <div className='meeting-grid'>
                        <Card className='meeting-info__card' title={t('basic-info')} footer={
                                    <Link
                                        to={`../../hlasovani?pohled=zasedani&zasedani=${zasedaniId}&pouze_platna=true`}
                                    >
                                        {t('votings-overview')} <i className='pi pi-angle-right'></i>
                                    </Link>
                        }>
                            <div className='grid'>
                                <div key='datum'>
                                    <i className='pi bi-calendar text-icon__left text__primary'></i>
                                    <span>{sessionData.datum}</span>
                                </div>
                                <div key='sumarizace'>
                                    <span key='celkem' className='text__primary'>
                                        {sessionData.pocet_hlasovani.platna}
                                    </span>{' '}
                                    {t('valid-votings')} (
                                    <Link
                                        to={`../../hlasovani?pohled=zasedani&zasedani=${zasedaniId}&pouze_platna=true&vysledek_hlasovani=1`}
                                    >
                                        <span key='prijato' className='text__success'>
                                            <i className='bi bi-check-square text-icon__left'></i>
                                            {sessionData.pocet_hlasovani.prijata}
                                        </span>
                                    </Link>{' '}
                                    {t('and')}
                                    <Link
                                        to={`../../hlasovani?pohled=zasedani&zasedani=${zasedaniId}&pouze_platna=true&vysledek_hlasovani=2`}
                                    >
                                        <span key='neprijato' className='text__danger'>
                                            &ensp;<i className='bi bi-x-square text-icon__left'></i>
                                            {sessionData.pocet_hlasovani.neprijata}
                                        </span>
                                    </Link>
                                    )
                                </div>
                                <div key='ucast'>
                                    <i className='pi pi-users text-icon__left text__primary'></i>
                                    <span className='text__primary'>
                                        {Math.round((sessionData.ucast / sessionData.celkem) * 100)}%{' '}
                                    </span>
                                    {t('meeting-attendance', { cnt1: sessionData.ucast, cnt2: sessionData.celkem })}
                                </div>

                            </div>
                        </Card>
                        <Card
                            title={
                                <span>
                                    {t('councilMember_attendance')}
                                    <Tooltip target='.custom-target-icon' />
                                    <i
                                        className='text-icon__right custom-target-icon pi bi-info-circle p-text-secondary p-overlay-badge'
                                        data-pr-tooltip={t('tooltip-chart')}
                                        data-pr-position='right'
                                        data-pr-at='right+5 top'
                                        data-pr-my='left center-2'
                                    ></i>
                                    {/*<Button className="custom-table-button" alt="Zobrazit tabulku" onClick={(e) => op.current.toggle(e)}><i className="bi bi-table" style={{verticalAlign: 'middle', fontSize: '1rem'}}></i></Button>*/}
                                </span>
                            }
                            className='meeting-attendance__card'
                        >
                            {/*<OverlayPanel ref={op} showCloseIcon>
                                   <DataTable responsiveLayout={false} value={summaryAttendanceData.datasets}>
                                        <Column field="label" header="strana" />
                                        <Column header="přít." body={(rowData) => rowData.data[0]} />
                                        <Column header="částečná přítomnost" body={(rowData) => rowData.data[1]} />
                                        <Column header="nepřít." body={(rowData) => rowData.data[2]} />
                                    </DataTable>
                                    </OverlayPanel>
                                    */}

                            <div className='grid'>
                                <div className='item-chart'>
                                    <ChartComponent
                                        type='bar'
                                        data={summaryAttendanceData}
                                        options={stackedOptions}
                                        height={width <= 576 ? '400px' : '300px'}
                                        width='100%'
                                    />
                                </div>
                                <div className='item-list'>
                                    <TabView
                                        scrollable={width < 576}
                                        activeIndex={activeTab}
                                        onTabChange={(e) => setActiveTab(e.index)}
                                    >
                                        {attendanceData['detail'].map((elem, idx) => (
                                            <TabPanel key={idx} header={options[idx]}>
                                                {elem.map((party, i) => (
                                                    <>
                                                        <Fieldset
                                                            collapsed={true}
                                                            legend={
                                                                <>
                                                                    <Link
                                                                        className='representatives-list-title'
                                                                        style={{ color: party.strana_barva }}
                                                                        to={`../../subjekty/${party.strana_id}`}
                                                                        key={party.strana_id}
                                                                    >
                                                                        {party.strana_zkratka}
                                                                    </Link>{' '}
                                                                    –⁠{' '}
                                                                    <span
                                                                        className='text__semibold'
                                                                        style={{ color: party.strana_barva }}
                                                                    >
                                                                        {party.clenove.length}
                                                                    </span>{' '}
                                                                    {t('councilMember', {
                                                                        count: party.clenove.length
                                                                    })}
                                                                </>
                                                            }
                                                            toggleable
                                                        >
                                                            <ul className='representatives-list__grid'>
                                                                {party.clenove.map((member) => (
                                                                    <li
                                                                        key={member.clen_id}
                                                                        className='representatives-list-cell bullet__primary'
                                                                    >
                                                                        <Link
                                                                            to={`../../zastupitele/${member.clen_id}`}
                                                                            key={member.clen_id}
                                                                            className='text__black'
                                                                        >
                                                                            {formatMemberName(
                                                                                member.clen_jmeno,
                                                                                member.clen_prijmeni
                                                                            )}
                                                                        </Link>
                                                                        {member.aliasy && (
                                                                            <AliasTooltip
                                                                                id={`member-${member.clen_id}-aliases`}
                                                                                aliases={member.aliasy}
                                                                            />
                                                                        )}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </Fieldset>
                                                    </>
                                                ))}
                                            </TabPanel>
                                        ))}
                                    </TabView>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            );
        }
    }
}

export default ZasedaniDetail;
