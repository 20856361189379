import PopupBodyChart from "../../../../components/map/popup/PopupBodyChart";
import PopupFooter from "../../../../components/map/popup/PopupFooter";
import PopupHeader from "../../../../components/map/popup/PopupHeader";
import APP from "../../../config";
import data from "../data";
import geo from "../geo";
import meta from "../meta";

/**
 * Share of people in subjects statistics.
 * 
 * @author Kristyna Zaklova
 */


const getAmountOfMandatesInEachRegionForASubject = (data, subjects) => {
    let count = 0;

    for (const subjectName of subjects) {
        Object.values(data).forEach(region => count += region?.subjektyWiki[subjectName]?.pocetZastupitelu ? region?.subjektyWiki[subjectName]?.pocetZastupitelu : 0);
    }

    return `(${count} mandátů)`;
}


const peopleSubjectsStats = {
    title: 'Počet mandátů největších subjektů napříč kraji',
    content: () => {
        return (
            <>
                Tato analýza napříč kraji ukazuje, které politické strany mají největší vliv v&nbsp;regionální politice. 
                V&nbsp;mnoha krajích <span className="emp">dominují tradiční strany</span> s&nbsp;dlouhodobým zázemím, které často získávají největší počet mandátů. 
                Tyto strany mají díky svému vlivu možnost sestavovat koalice a&nbsp;obsazovat klíčové pozice v&nbsp;krajských radách. 
                Některé z&nbsp;těchto subjektů vstoupily již do minulých voleb v&nbsp;koalici s&nbsp;dalšími stranami, v&nbsp;tomto případě byl počet mandátů očištěn tak, aby odpovídal pouze vybrané politické straně.
                <br></br><br></br>
                Nejvíce mandátů napříč celou republikou má politická strana <span className="emp"><strong>ANO 2011</strong></span> s&nbsp;celkem <span className="emp"><strong>183</strong></span> krajskými zastupiteli a&nbsp;zastupitelkami.
            </>
        )
    },
    chart: {
        id: "mapa-pocet-zastupitelu-po-krajich",
        type: "regional-filtered-choropleth",
        config: {
            geo: geo,
            datasets: [
                {
                    label: `ANO 2011 ${getAmountOfMandatesInEachRegionForASubject(data, ["ANO 2011", "ANO", "ANO+nez\u00e1visl\u00ed"])}`,
                    data: data,
                    categories: createParametrizableCategories(["#919eee","#6577e7","#3951e0"],[10, 15]),
                    getValue: (data, region) => getValue(data,region,["ANO 2011", "ANO", "ANO+nez\u00e1visl\u00ed"]),
                    formatValue: meta.pocetSubjektuKraj.formatValue
                },
                {
                    label: `ODS ${getAmountOfMandatesInEachRegionForASubject(data, ["ODS"])}`,
                    data: data,
                    categories: createParametrizableCategories(["#1e86fb","#046ce1","#0354af"],[8, 15]),
                    getValue: (data, region) => getValue(data,region,["ODS"]),
                    formatValue: meta.pocetSubjektuKraj.formatValue
                },
                {
                    label: `Piráti ${getAmountOfMandatesInEachRegionForASubject(data, ["Piráti"])}`,
                    data: data,
                    categories: createParametrizableCategories(["#8c8c8c","#404040","#0d0d0d"],[7,10]),
                    getValue: (data, region) => getValue(data,region,["Piráti"]),
                    formatValue: meta.pocetSubjektuKraj.formatValue
                },
                {
                    label: `KDU-ČSL ${getAmountOfMandatesInEachRegionForASubject(data, ["KDU-ČSL"])}`,
                    data: data,
                    categories: createParametrizableCategories(["#ffe180","#ffc91a","#e6af00"],[5,10]),
                    getValue: (data, region) => getValue(data,region,["KDU-ČSL"]),
                    formatValue: meta.pocetSubjektuKraj.formatValue
                },
                {
                    label: `STAN ${getAmountOfMandatesInEachRegionForASubject(data, ["STAN"])}`,
                    data: data,
                    categories: createParametrizableCategories(["#ae059a","#7c036e","#4D0244"],[5,15]),
                    getValue: (data, region) => getValue(data,region,["STAN"]),
                    formatValue: meta.pocetSubjektuKraj.formatValue
                },
                {
                    label: `SPD ${getAmountOfMandatesInEachRegionForASubject(data, ["SPD"])}`,
                    data: data,
                    categories: createParametrizableCategories(["#e60013","#b3000f"],[4]),
                    getValue: (data, region) => getValue(data,region,["SPD"]),
                    formatValue: meta.pocetSubjektuKraj.formatValue
                },
                {
                    label: `SOCDEM ${getAmountOfMandatesInEachRegionForASubject(data, ["ČSSD","SOCDEM"])}`,
                    data: data,
                    categories: createParametrizableCategories(["#ff8080","#ff5f60"],[5]),
                    getValue: (data, region) => getValue(data,region,["ČSSD","SOCDEM"]),
                    formatValue: meta.pocetSubjektuKraj.formatValue
                },
                {
                    label: `TOP 09 ${getAmountOfMandatesInEachRegionForASubject(data, ["TOP 09"])}`,
                    data: data,
                    categories: createParametrizableCategories(["#4c59cd","#141947"],[3]),
                    getValue: (data, region) => getValue(data,region,["TOP 09"]),
                    formatValue: meta.pocetSubjektuKraj.formatValue
                },
                {
                    label: `KSČM ${getAmountOfMandatesInEachRegionForASubject(data, ["KSČM"])}`,
                    data: data,
                    categories: createParametrizableCategories(["#e60000","#840000"],[2]),
                    getValue: (data, region) => getValue(data,region,["KSČM"]),
                    formatValue: meta.pocetSubjektuKraj.formatValue
                },
                {
                    label: `Zelení ${getAmountOfMandatesInEachRegionForASubject(data, ["Zelení"])}`,
                    data: data,
                    categories: createParametrizableCategories(["#1aff72","#00AD43"],[2]),
                    getValue: (data, region) => getValue(data,region,["Zelení"]),
                    formatValue: meta.pocetSubjektuKraj.formatValue
                },
                {
                    label: `SNK ED ${getAmountOfMandatesInEachRegionForASubject(data, ["SNK ED"])}`,
                    data: data,
                    categories: createParametrizableCategories(["#FDCA0B","#b18c01"],[1]),
                    getValue: (data, region) => getValue(data,region,["SNK ED"]),
                    formatValue: meta.pocetSubjektuKraj.formatValue
                }
            ]
        }
    },
    footer: {
        left: {
            title: {
                label: "Zdroj:",
                icon: "pi pi-fw pi-globe"
            },
            links: [
                {
                    label: "Datové sady",
                    value: `${APP.protocol}://kraje.${APP.domain}${APP.port && ':' + APP.port}/datasety`
                },
                {
                    label: "Wikipedia",
                    value: "https://cs.wikipedia.org/wiki/"
                },
            ]
        }
    }
}
export default peopleSubjectsStats;


function getValue(data, region, subjects) {
    for (const subjectName of subjects) {
        
        const subject = data[region]?.subjektyWiki[subjectName];
        
        
        if (subject?.pocetZastupitelu !== undefined) {
            return subject.pocetZastupitelu;
        }
    }
    
    
    return undefined;
}


// Function to create customizable categories
function createParametrizableCategories(colors, edgeValue) {
    let categories = {}
    if (colors.length - 1 !== edgeValue.length){
        throw new Error("colors is not one value longer than edgeValue");
    }
    let descr = ""

    for (let i = 0; i < colors.length; i++){
        if (i === 0){
            descr = "<" + String(edgeValue[i])

            categories[i] = {
                name: descr,
                description: descr,
                color: colors[i],
                equals: (value) => value < edgeValue[i]
            }

        } else if (i === (colors.length - 1)){
            descr = ">" + String(edgeValue[i-1])

            categories[i] = {
                name: descr,
                description: descr,
                color: colors[i],
                equals: (value) => value >= edgeValue[i-1]
            }
        } else {
            descr = edgeValue[i-1] + "-" + edgeValue[i]

            categories[i] = {
                name: descr,
                description: descr,
                color: colors[i],
                equals: (value) => value >= edgeValue[i-1] && value < edgeValue[i]   
            }
        }
    }


    return categories;
}