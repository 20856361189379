import { formatMemberName } from '../../utils/texts';

/**
 * @param {?Date} date
 * @returns {?string} Formatted date string for API requests
 */
export const formatDate = (date) => {
    if (!date) return null;
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

/**
 * @param {?import('@internationalized/date').Time} time
 * @returns {?string} Formatted time string for API requests
 */
export const formatTime = (time) => {
    return time ? time.toString() : null;
};

/**
 * @param {string?} firstName
 * @param {string?} lastName
 * @param {string} login
 * @returns {string}
 */
export const formatUserName = (firstName, lastName, login) => {
    const name = formatMemberName(firstName, lastName);
    return name ? `${login} (${name})` : login;
};

/**
 * @param {object} politicalEntity
 * @returns {string}
 */
export const formatPoliticalEntityName = (politicalEntity) => {
    if (politicalEntity.name) {
        return `${politicalEntity.name} (${politicalEntity.abbreviation})`;
    } else {
        return politicalEntity.abbreviation;
    }
};
