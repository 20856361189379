import data from "../data";
import geo from "../geo";
import meta from "../meta";

import PopupBodyBasicStats from "../../../../components/map/popup/PopupBodyBasicStats";
import PopupFooter from "../../../../components/map/popup/PopupFooter";
import PopupHeader from "../../../../components/map/popup/PopupHeader";

/**
 * Common statistics for the regions.
 * 
 * @author Jiri Hynek, Adam Janosik, Kristyna Zaklova
 */
const popup = {
    header: {
        component: PopupHeader,
        props: {
            data: data
        }
    },
    body: {
        component: PopupBodyBasicStats,
        props: {
            data: data,
            settings: {
                description: meta.description,
                img: meta.CoA,
                values: [
                    {
                        meta: meta.sidlo,
                        rank: false,
                        meter: false
                    },
                    {
                        meta: meta.pocetObyvatel,
                        rank: true,
                        meter: true
                    },
                    {
                        meta: meta.rozloha,
                        rank: true,
                        meter: true
                    },
                    {
                        meta: meta.nejvyssiBodNazev,
                        rank: false,
                        meter: false
                    },
                    {
                        meta: meta.nejvyssiBodVyska,
                        rank: true,
                        meter: true
                    },
                    {
                        meta: meta.pocetOkresu,
                        rank: true,
                        meter: true
                    },
                    {
                        meta: meta.pocetObci,
                        rank: true,
                        meter: true
                    },
                    {
                        meta: meta.pocetMest,
                        rank: true,
                        meter: true
                    },
                    {
                        meta: meta.pocetMestysu,
                        rank: true,
                        meter: true
                    },
                    {
                        meta: meta.pocetORP,
                        rank: true,
                        meter: true
                    },
                    ,
                    {
                        meta: meta.pocetPOU,
                        rank: true,
                        meter: true
                    }
                ]
            }
        }
    },
    footer: {
        component: PopupFooter,
        props: {
            data: data,
            settings: {
                left: {
                    title: {
                        label: "Zdroje:",
                        icon: "pi pi-fw pi-globe"
                    },
                    links: [
                        meta.wiki,
                        {
                            ...meta.CoALicense,
                            label: "Wikimedia",
                        },
                        {
                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ rozloha",
                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt-vyhledavani&vyhltext=rozloha+kraj&bkvt=cm96bG9oYSBrcmFq&filtr=G~F_M~F_Z~F_R~F_P~_S~_U~301_null_&katalog=all&pvo=RSO50"
                        },
                        {
                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ hustota obyvatel",
                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=RSO34&z=T&f=TABULKA&filtr=G~F_M~F_Z~F_R~F_P~_S~_U~301_null_&katalog=all&evo=v555_!_VUZEMI97-100H_1"
                        }
                    ]
                },
                right: {
                    title: undefined,
                    links: [
                        meta.route
                    ]
                }
            }
        }
    },
    style: {
        width: '80vw',
        height: '100vh',
        maxWidth: '1200px',
    }
}

const commonStats = {
    title: 'Geografické údaje',
    content: () => {
        return (
            <>
                Česká republika je rozdělena celkem na <span className="emp"><strong>14</strong>&nbsp;krajů</span>, které tvoří základní jednotky územní samosprávy. Každý kraj má vlastní krajské město a&nbsp;krajský úřad, který spravuje záležitosti regionu, jako jsou školství, zdravotnictví, doprava a&nbsp;sociální služby. Největším a&nbsp;nejlidnatějším krajem je <span className="emp">Hlavní město Praha</span>, které má zvláštní statut a&nbsp;plní zároveň funkci obce a&nbsp;kraje. <span className="emp">Pro detaily o&nbsp;kraji klikněte v&nbsp;mapce na daný kraj.</span>
            </>
        )
    },
    chart: {
        id: "mapa-dostupnost",
        type: "regional-map",
        config: {
            id: "mapa-dostupnost",
            geo: geo,
            defaults: {
                polygon: {
                    style: {
                        fill: "lightblue",
                    },
                    hoverStyle: {
                        fill: "yellow"
                    },
                    popup: popup
                },
                capital: {
                    style: {
                        display: "block",
                        fill: "blue"
                    }
                }
            }
        }
    },
    footer: {
        left: {
            title: {
                label: "Zdroje:",
                icon: "pi pi-fw pi-globe"
            },
            links: [
                {
                    label: "Wikipedia",
                    value: "https://cs.wikipedia.org/wiki/"
                },
                {
                    label: "Wikimedia",
                    value: "https://commons.wikimedia.org/wiki/Main_Page"
                },
                {
                    label: "ČSÚ –⁠⁠⁠⁠⁠⁠ rozloha",
                    value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt-vyhledavani&vyhltext=rozloha+kraj&bkvt=cm96bG9oYSBrcmFq&filtr=G~F_M~F_Z~F_R~F_P~_S~_U~301_null_&katalog=all&pvo=RSO50"
                },
                {
                    label: "ČSÚ –⁠⁠⁠⁠⁠⁠ hustota obyvatel",
                    value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=RSO34&z=T&f=TABULKA&filtr=G~F_M~F_Z~F_R~F_P~_S~_U~301_null_&katalog=all&evo=v555_!_VUZEMI97-100H_1"
                }
            ]
        },
        right: {
            title: undefined,
            links: [
                meta.route
            ]
        }
    }
}
export default commonStats;