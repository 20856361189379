import { DochazkaMoznost } from './enums';

export const MAX_TERM_LENGTH = 4;

export const DEFAULT_COLOR = '#FF0000';

export const MIN_PASSWORD_LENGTH = 8;

export const ATTENDANCE_OPTIONS = [
    { label: 'přítomnost', value: DochazkaMoznost.PRITOMNOST },
    { label: 'částečná přítomnost', value: DochazkaMoznost.CASTECNA_PRITOMNOST },
    { label: 'nepřítomnost', value: DochazkaMoznost.NEPRITOMNOST }
];
