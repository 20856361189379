import React from "react";

import "./PopupHeader.css"

function PopupHeader({
    event,
    props = {
        data: {}
    }
}) {

    return (
        <>
            {event && props?.data && props.data[event.region] ? props.data[event.region].nazev : "Detaily"}
        </>
    )
}

export default PopupHeader;