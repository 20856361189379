import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import LoadingPage from '../../components/LoadingPage';
import { useTranslation } from 'react-i18next';
import useDimensions from '../../hooks/useDimensions';
import PaginatedDataTable from '../../components/PaginatedDataTable';
import {Column, Tooltip, TabView, TabPanel, Card} from "../../components/PrimereactComponents";
import { redirectErrorPage } from '../../utils/errors';

function VotingUnity(props) {
  const [data, setData] = useState(undefined);
  const { municipalitaId, bodyType, zastupitelstvoId } = useParams();
  const [selectedRow, setSelectedRow] = useState(null);
  const { width } = useDimensions();

  const {t} = useTranslation(["translation", "analyses"]);
    
  useEffect(() => {
      const fetchData = async () => {
        try {
          const dataResponse = await axios.get(`${municipalitaId}/${bodyType}/${zastupitelstvoId}/votes?pohled=shoda`
        );
          setData(dataResponse.data);
        } catch (error) {
            redirectErrorPage(error);
        }
      };
    
        fetchData();
  }, []);
  
  if(data !== undefined) {
    const onTableOptionChange = (e) => {
        setSelectedRow(e.value);
        const baseUrl = process.env.REACT_APP_BASE_URL;
        let url = baseUrl + `${municipalitaId}/${bodyType}/${zastupitelstvoId}/hlasovani/${e.value.id_hlasovani}`;
        window.open(url, '_blank');
    }

      const resultBodyTemplate = (rowData) => {
        if (rowData.vysledek === "přijato") {
          return (
            <span>
              <i data-pr-tooltip={t("přijato")} className={"custom-target-icon4 bi bi-check-square polls-table__icon-accepted"}></i>&nbsp;
              <span>{width < 992 && rowData.predmet_hlasovani.length > 45? rowData.predmet_hlasovani.substring(0, 42) + "..." : rowData.predmet_hlasovani}</span>
              <Tooltip target=".custom-target-icon4"/>
            </span>
          );
        }
        return (
            <span>
            <i data-pr-tooltip={t("nepřijato")} className={"custom-target-icon5 bi bi-x-square polls-table__icon-rejected"}></i>&nbsp;
            <span>{width < 992 && rowData.predmet_hlasovani.length > 45? rowData.predmet_hlasovani.substring(0, 42) + "..." : rowData.predmet_hlasovani}</span>
            <Tooltip target=".custom-target-icon5"/>
          </span>
        );
      };
    
    const votesBodyTemplate = (rowData) => {
        return (
          <div>
            <div className="votes-table__vote">
              <i data-pr-tooltip={t("yes")} className={"custom-target-icon1 bi bi-check-square"}></i>&nbsp;{rowData.ano}
              <Tooltip target='.custom-target-icon1'/>
            </div>
            <div className="votes-table__vote">
              <i data-pr-tooltip={t("no")} className={"custom-target-icon2 bi bi-x-square"}></i>&nbsp;{rowData.ne}
              <Tooltip target='.custom-target-icon2'/>
            </div>
            <div className="votes-table__vote">
              <i data-pr-tooltip={t("abstain")} className={"custom-target-icon3 bi bi-dash-square"}></i>&nbsp;{rowData.zdrzel_se}
              <Tooltip target='.custom-target-icon3'/>
            </div>
          </div>
        );
      }

    return (
      <>
        <Card>
            <h2>
               {t("voting-unity")}&nbsp;
               <i
                className='custom-target-icon6 pi bi-info-circle parties__chart-icon'
                data-pr-tooltip={t('voting-unity-tooltip', {ns: "analyses"})}
                data-pr-position='bottom'
                ></i>
            </h2>
            <Tooltip target='.custom-target-icon6'/>
          
          <TabView scrollable={width <= 992}>
            <TabPanel header={t("largest-agreement", {ns: "analyses"})}>
            <PaginatedDataTable data={data} initialSortField="ai" initialSortOrder={-1} selection={selectedRow} onSelectionChange={onTableOptionChange}>
            <Column field="cislo_hlasovani" header={t("voting-number")}></Column>
            <Column
              field="predmet_hlasovani"
              header={t("voting-description")}
              body={resultBodyTemplate}
              className="w80 lh4"
            ></Column>
            <Column
                className="w10"
                field="ai"
                header={t("voting-agreement")}
            ></Column>
            <Column
                className="w10"
                body={votesBodyTemplate}
                header={t("voting-results")}
            ></Column>
          </PaginatedDataTable>
            </TabPanel>

            <TabPanel header={t("lowest-agreement", {ns: "analyses"})}>
            <PaginatedDataTable data={data} initialSortField="ai" initialSortOrder={1} selection={selectedRow} onSelectionChange={onTableOptionChange}>
            <Column field="cislo_hlasovani" header={t("voting-number")}></Column>
            <Column
              field="predmet_hlasovani"
              header={t("voting-description")}
              body={resultBodyTemplate}
              className="w80 lh-4"
            ></Column>
            <Column
                field="ai"
                header={t("voting-agreement")}
                className="w10"
            ></Column>
            <Column
                className="w10"
                body={votesBodyTemplate}
                header={t("voting-results")}
            ></Column>
          </PaginatedDataTable>
            </TabPanel>
          </TabView>
          
        </Card>
      </>
    );} 
    else{
      return (<LoadingPage/>)
    }
}


export default VotingUnity;