import PopupBodyChart from "../../../../components/map/popup/PopupBodyChart";
import PopupFooter from "../../../../components/map/popup/PopupFooter";
import PopupHeader from "../../../../components/map/popup/PopupHeader";
import data from "../data";
import geo from "../geo";
import meta from "../meta";

/**
 * outcome per citizen statistics.
 * 
 * @author Adam Janosik, Kristyna Zaklova
 */

const outcomePerCitizen23 = {
    label: "Rok 2023",
    data: data,
    categories: meta.vydajeNaObyvatele23.values,
    getValue: meta.vydajeNaObyvatele23.getValue,
    formatValue: meta.vydajeNaObyvatele23.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle výdajů na obyvatele jednotlivých krajů (rok 2023)",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Výdaje na obyvatele jednotlivých krajů se v roce <span
                                                className="emp"><strong>2023</strong></span> pohybovaly od <span
                                                className="emp"><strong>32,518</strong> Kč</span> do <span
                                                className="emp"><strong>80,529</strong> Kč</span>.
                                                Nejvyšší poměr zaznamenala <span className="emp"><strong>Praha</strong></span> a
                                                nejnižší <span className="emp"><strong>Moravskoslezský kraj</strong></span>.
                                            </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.vydajeNaObyvatele23.values,
                                            getValue: meta.vydajeNaObyvatele23.getValue,
                                            formatValue: meta.vydajeNaObyvatele23.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů (rok 2023)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2023"
                                        },
                                        {
                                            ...meta.csuPeople,
                                            label: "ČSÚ - obyvatelstvo"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}


const outcomePerCitizen22 = {
    label: "Rok 2022",
    data: data,
    categories: meta.vydajeNaObyvatele22.values,
    getValue: meta.vydajeNaObyvatele22.getValue,
    formatValue: meta.vydajeNaObyvatele22.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle výdajů na obyvatele jednotlivých krajů (rok 2022)",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Výdaje na obyvatele jednotlivých krajů se v roce <span
                                                className="emp"><strong>2022</strong></span> pohybovaly od <span
                                                className="emp"><strong>28,829</strong> Kč</span> do <span
                                                className="emp"><strong>77,228</strong> Kč</span>.
                                                Nejvyšší poměr zaznamenala <span className="emp"><strong>Praha</strong></span> a
                                                nejnižší <span className="emp"><strong>Jihomoravský kraj</strong></span>.
                                            </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.vydajeNaObyvatele22.values,
                                            getValue: meta.vydajeNaObyvatele22.getValue,
                                            formatValue: meta.vydajeNaObyvatele22.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů (rok 2022)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2022"
                                        },
                                        {
                                            ...meta.csuPeople,
                                            label: "ČSÚ - obyvatelstvo"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const outcomePerCitizen21 = {
    label: "Rok 2021",
    data: data,
    categories: meta.vydajeNaObyvatele21.values,
    getValue: meta.vydajeNaObyvatele21.getValue,
    formatValue: meta.vydajeNaObyvatele21.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle výdajů na obyvatele jednotlivých krajů (rok 2021)",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Výdaje na obyvatele jednotlivých krajů se v roce <span
                                                className="emp"><strong>2021</strong></span> pohybovaly od <span
                                                className="emp"><strong>26,969</strong> Kč</span> do <span
                                                className="emp"><strong>70,291</strong> Kč</span>.
                                                Nejvyšší poměr zaznamenala <span className="emp"><strong>Praha</strong></span> a
                                                nejnižší <span className="emp"><strong>Jihomoravský kraj</strong></span>.
                                            </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.vydajeNaObyvatele21.values,
                                            getValue: meta.vydajeNaObyvatele21.getValue,
                                            formatValue: meta.vydajeNaObyvatele21.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů (rok 2021)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2021"
                                        },
                                        {
                                            ...meta.csuPeople,
                                            label: "ČSÚ - obyvatelstvo"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const outcomePerCitizen20 = {
    label: "Rok 2020",
    data: data,
    categories: meta.vydajeNaObyvatele20.values,
    getValue: meta.vydajeNaObyvatele20.getValue,
    formatValue: meta.vydajeNaObyvatele20.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle výdajů na obyvatele jednotlivých krajů (rok 2020)",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Výdaje na obyvatele jednotlivých krajů se v roce <span
                                                className="emp"><strong>2020</strong></span> pohybovaly od <span
                                                className="emp"><strong>25,669</strong> Kč</span> do <span
                                                className="emp"><strong>65,939</strong> Kč</span>.
                                                Nejvyšší poměr zaznamenala <span className="emp"><strong>Praha</strong></span> a
                                                nejnižší <span className="emp"><strong>Moravskoslezský kraj</strong></span>.
                                            </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.vydajeNaObyvatele20.values,
                                            getValue: meta.vydajeNaObyvatele20.getValue,
                                            formatValue: meta.vydajeNaObyvatele20.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů (rok 2020)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2020"
                                        },
                                        {
                                            ...meta.csuPeople,
                                            label: "ČSÚ - obyvatelstvo"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const outcomePerCitizenSum = {
    label: "Suma za období 2020-2023",
    data: data,
    categories: meta.vydajeNaObyvateleSum.values,
    getValue: meta.vydajeNaObyvateleSum.getValue,
    formatValue: meta.vydajeNaObyvateleSum.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle sumy výdajů na obyvatele jednotlivých krajů v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Suma výdajů jednotlivých krajů na obyvatele v letech <span className="emp"><strong>2020-2023</strong></span> značí součet všech výdajů na obyvatele krajů v daném období. Nejvyšší výdaje na obyvatele zaznamenala <span className="emp"><strong>Praha</strong></span>, nejnižší pak <span className="emp"><strong>Moravskoslezský kraj</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.vydajeNaObyvateleSum.values,
                                            getValue: meta.vydajeNaObyvateleSum.getValue,
                                            formatValue: meta.vydajeNaObyvateleSum.formatValue
                                        }
                                    }
                                },
                                {
                                    title: "Vývoj výdajů krajů na obyvatele v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                                
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "line",
                                        config: {
                                            geo: geo,
                                            datasets: [
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.vydajeNaObyvatele20.values,
                                                    getValue: meta.vydajeNaObyvatele20.getValue,
                                                    formatValue: meta.vydajeNaObyvatele20.formatValue,
                                                    label: meta.vydajeNaObyvatele20.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.vydajeNaObyvatele21.values,
                                                    getValue: meta.vydajeNaObyvatele21.getValue,
                                                    formatValue: meta.vydajeNaObyvatele21.formatValue,
                                                    label: meta.vydajeNaObyvatele21.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.vydajeNaObyvatele22.values,
                                                    getValue: meta.vydajeNaObyvatele22.getValue,
                                                    formatValue: meta.vydajeNaObyvatele22.formatValue,
                                                    label: meta.vydajeNaObyvatele22.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.vydajeNaObyvatele23.values,
                                                    getValue: meta.vydajeNaObyvatele23.getValue,
                                                    formatValue: meta.vydajeNaObyvatele23.formatValue,
                                                    label: meta.vydajeNaObyvatele23.label
                                                }
                                            ]
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ (2023)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2023"
                                        },
                                        {
                                            label: "ČSÚ (2022)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2022"
                                        },
                                        {
                                            label: "ČSÚ (2021)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2021"
                                        },
                                        {
                                            label: "ČSÚ (2020)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2020"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}






const outcomePerCitizenAvg = {
    label: "Průměr 2020-2023",
    data: data,
    categories: meta.vydajeNaObyvateleAvg.values,
    getValue: meta.vydajeNaObyvateleAvg.getValue,
    formatValue: meta.vydajeNaObyvateleAvg.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle průměrných výdajů na obyvatele jednotlivých krajů v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                            <p>Napříč lety <span className="emp"><strong>2020-2023</strong></span> v průměru nejvíce financí na obyvatele využila <span className="emp"><strong>Praha</strong></span>, nejméně pak <span className="emp"><strong>Moravskoslezský kraj</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.vydajeNaObyvateleAvg.values,
                                            getValue: meta.vydajeNaObyvateleAvg.getValue,
                                            formatValue: meta.vydajeNaObyvateleAvg.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ - hospodaření (2023)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2023"
                                        },
                                        {
                                            label: "ČSÚ - hospodaření (2022)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2022"
                                        },
                                        {
                                            label: "ČSÚ - hospodaření (2021)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2021"
                                        },
                                        {
                                            label: "ČSÚ - hospodaření (2020)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2020"
                                        },
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ obyvatelstvo",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt-parametry&z=T&f=TABULKA&katalog=30845&sp=A&skupId=3809&pvo=DEMZU01a&u=v81__VUZEMI__100__3018&evo=v450_%21_IK-DEM-R-2030-2000-sestup_1&str=v191"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const genericPopup = {
    header: {
        component: PopupHeader,
        props: {
            data: data
        }
    },
    body: {
        component: PopupBodyChart,
        props: {
            data: data,
            stats: [
                {
                    title: "Srovnání dle průměrných výdajů na obyvatele jednotlivých krajů v období 2020-2023",
                    content: () => {
                        return (
                            <>
                            </>
                        )
                    },
                    chart: {
                        type: "bar",
                        config: {
                            geo: geo,
                            data: data,
                            categories: meta.vydajeNaObyvateleAvg.values,
                            getValue: meta.vydajeNaObyvateleAvg.getValue,
                            formatValue: meta.vydajeNaObyvateleAvg.formatValue
                        }
                    }
                },
                {
                    title: "Vývoj výdajů krajů na obyvatele v období 2020-2023",
                    content: () => {
                        return (
                            <>
                                
                            </>
                        )
                    },
                    chart: {
                        type: "line",
                        config: {
                            geo: geo,
                            datasets: [
                                {
                                    geo: geo,
                                    data: data,
                                    categories: meta.vydajeNaObyvatele20.values,
                                    getValue: meta.vydajeNaObyvatele20.getValue,
                                    formatValue: meta.vydajeNaObyvatele20.formatValue,
                                    label: meta.vydajeNaObyvatele20.label
                                },
                                {
                                    geo: geo,
                                    data: data,
                                    categories: meta.vydajeNaObyvatele21.values,
                                    getValue: meta.vydajeNaObyvatele21.getValue,
                                    formatValue: meta.vydajeNaObyvatele21.formatValue,
                                    label: meta.vydajeNaObyvatele21.label
                                },
                                {
                                    geo: geo,
                                    data: data,
                                    categories: meta.vydajeNaObyvatele22.values,
                                    getValue: meta.vydajeNaObyvatele22.getValue,
                                    formatValue: meta.vydajeNaObyvatele22.formatValue,
                                    label: meta.vydajeNaObyvatele22.label
                                },
                                {
                                    geo: geo,
                                    data: data,
                                    categories: meta.vydajeNaObyvatele23.values,
                                    getValue: meta.vydajeNaObyvatele23.getValue,
                                    formatValue: meta.vydajeNaObyvatele23.formatValue,
                                    label: meta.vydajeNaObyvatele23.label
                                }
                            ]
                        }
                    }
                }
            ]
        }
    },
    footer: {
        component: PopupFooter,
        props: {
            data: data,
            settings: {
                left: {
                    title: {
                        label: "Zdroje:",
                        icon: "pi pi-fw pi-globe"
                    },
                    links: [
                        {
                            label: "ČSÚ - hospodaření (2023)",
                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2023"
                        },
                        {
                            label: "ČSÚ - hospodaření (2022)",
                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2022"
                        },
                        {
                            label: "ČSÚ - hospodaření (2021)",
                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2021"
                        },
                        {
                            label: "ČSÚ - hospodaření (2020)",
                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2020"
                        },
                        {
                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ obyvatelstvo",
                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt-parametry&z=T&f=TABULKA&katalog=30845&sp=A&skupId=3809&pvo=DEMZU01a&u=v81__VUZEMI__100__3018&evo=v450_%21_IK-DEM-R-2030-2000-sestup_1&str=v191"
                        }
                    ]
                },
                right: {
                    title: undefined,
                    links: [
                        meta.route
                    ]
                }
            }
        }
    },
    style: {
        width: '80vw',
        height: '100vh',
        maxWidth: '1200px',
    }
}

const outcomePerCitizen = {
    title: 'Výdaje kraje na obyvatele',
    content: () => {
        return (
            <>
                Výdaje kraje na obyvatele představují průměrnou částku, kterou kraj vynakládá na jednotlivého obyvatele v&nbsp;daném regionu. 
                Tento ukazatel poskytuje přehled o&nbsp;tom, kolik prostředků je <span className="emp">investováno</span> do veřejných služeb a&nbsp;infrastruktury <span className="emp">na jednu osobu</span>. 
                Pomáhá hodnotit efektivitu a&nbsp;úroveň poskytovaných služeb v&nbsp;různých krajích a umožňuje srovnání mezi regiony různých velikostí a&nbsp;ekonomických podmínek. 
                Vyšší výdaje na obyvatele mohou indikovat větší investice do kvalitních služeb a rozvoje, zatímco nižší výdaje mohou signalizovat potřebu dalších investic nebo efektivnějšího využívání prostředků.
                Stejně jako u&nbsp;příjmů i&nbsp;u&nbsp;výdajů si <span className="emp">Praha</span> drží významný náskok před ostatními kraji.
            </>
        )
    },
    chart: {
        id: "mapa-vydaje-na-obyvatele",
        type: "regional-filtered-choropleth",
        config: {
            label: "Celkové výdaje na obyvatele krajů",
            geo: geo,
            datasets: [
                outcomePerCitizenSum,outcomePerCitizenAvg, outcomePerCitizen23, outcomePerCitizen22, outcomePerCitizen21, outcomePerCitizen20
            ]

        }
    },
    footer: {
        left: {
            title: {
                label: "Zdroj:",
                icon: "pi pi-fw pi-globe"
            },
            links: [
                {
                    label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů",
                    value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt-parametry&z=T&f=TABULKA&katalog=33877&pvo=FIN01&sp=A&c=v3%7E8__RP2023&str=v58"
                },
                {
                    label: "ČSÚ –⁠⁠⁠⁠⁠⁠ obyvatelstvo",
                    value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt-parametry&z=T&f=TABULKA&katalog=30845&sp=A&skupId=3809&pvo=DEMZU01a&u=v81__VUZEMI__100__3018&evo=v450_%21_IK-DEM-R-2030-2000-sestup_1&str=v191"
                }
            ]
        },
        right: {
            title: undefined,
            links: [
                meta.route
            ]
        },
        popup: {
            component: genericPopup
        }
    }
}
export default outcomePerCitizen;