import React from 'react';
import { logoUrl } from '../../utils/logoUrl';

export const MunicipalityLogo = ({ municipality }) => {
    const name = municipality.nazev;
    const color = municipality.barva;

    return municipality.logo ? (
        <img className='mainmenu__image' src={logoUrl(municipality.text_id)} alt={name} />
    ) : (
        <MunicipalityName name={name} color={color} />
    );
};

export const MunicipalityName = ({ name, color }) => {
    return (
        <span className='mainmenu__municipality-name' style={{ color }}>
            {name}
        </span>
    );
};
