import PopupBodyChart from "../../../../components/map/popup/PopupBodyChart";
import PopupFooter from "../../../../components/map/popup/PopupFooter";
import PopupHeader from "../../../../components/map/popup/PopupHeader";
import data from "../data";
import geo from "../geo";
import meta from "../meta";

/**
 * rewards statistics.
 * 
 * @author Petr John
 */
const platUvolneny = {
    label: "Uvolněný zastupitel (2024)",
    data: data,
    categories: meta.platUvolneny.values,
    getValue: meta.platUvolneny.getValue,
    formatValue: meta.platUvolneny.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle odměn uvolněných zastupitelů jednotlivých krajů (rok 2024)",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Odměna uvolněných zastupitelů krajů se v roce <span className="emp"><strong>2024</strong></span> pohybovala od <span className="emp"><strong>104&nbsp;356&nbsp;Kč</strong></span> do <span className="emp"><strong>114&nbsp;791&nbsp;Kč</strong></span>. Největší odměna zastupitelů byla v <span className="emp"><strong>Praze</strong></span> a nejmenší v <span className="emp"><strong>krajích s počtem obyvatel do 500 000</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.platUvolneny.values,
                                            getValue: meta.platUvolneny.getValue,
                                            formatValue: meta.platUvolneny.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "MVČR - Výše odměn 2024",
                                            value: "https://www.mvcr.cz/odk2/soubor/tabulka-a-koeficenty-pdf.aspx"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const platUvolneny23 = {
    label: "Uvolněný zastupitel (2023)",
    data: data,
    categories: meta.platUvolneny23.values,
    getValue: meta.platUvolneny23.getValue,
    formatValue: meta.platUvolneny23.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle odměn uvolněných zastupitelů jednotlivých krajů (rok 2023)",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Odměna uvolněných zastupitelů krajů se v roce <span className="emp"><strong>2023</strong></span> pohybovala od <span className="emp"><strong>96&nbsp;401&nbsp;Kč</strong></span> do <span className="emp"><strong>106&nbsp;041&nbsp;Kč</strong></span>. Největší odměna zastupitelů byla v <span className="emp"><strong>Praze</strong></span> a nejmenší v <span className="emp"><strong>krajích s počtem obyvatel do 500 000</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.platUvolneny23.values,
                                            getValue: meta.platUvolneny23.getValue,
                                            formatValue: meta.platUvolneny23.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "MVČR - Výše odměn 2023",
                                            value: "https://www.mvcr.cz/odk2/soubor/metodicke-doporuceni-k-aktualnim-zmenam-v-oblasti-odmenovani-clenu-zastupitelstev-usc-k-1-lednu-2023.aspx"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const platNeuvolneny23 = {
    label: "Neuvolněný zastupitel (2023)",
    data: data,
    categories: meta.platNeuvolneny23.values,
    getValue: meta.platNeuvolneny23.getValue,
    formatValue: meta.platNeuvolneny23.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle odměn uvolněných zastupitelů jednotlivých krajů (rok 2023)",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Odměna neuvolněných zastupitelů krajů se v roce <span className="emp"><strong>2023</strong></span> pohybovala od <span className="emp"><strong>8&nbsp;506&nbsp;Kč</strong></span> do <span className="emp"><strong>9&nbsp;357&nbsp;Kč</strong></span>. Největší odměna zastupitelů byla v <span className="emp"><strong>Praze</strong></span> a nejmenší v <span className="emp"><strong>krajích s počtem obyvatel do 500 000</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.platNeuvolneny23.values,
                                            getValue: meta.platNeuvolneny23.getValue,
                                            formatValue: meta.platNeuvolneny23.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "MVČR - Výše odměn 2023",
                                            value: "https://www.mvcr.cz/odk2/soubor/metodicke-doporuceni-k-aktualnim-zmenam-v-oblasti-odmenovani-clenu-zastupitelstev-usc-k-1-lednu-2023.aspx"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const platNeuvolneny = {
    label: "Neuvolněný zastupitel (2024)",
    data: data,
    categories: meta.platNeuvolneny.values,
    getValue: meta.platNeuvolneny.getValue,
    formatValue: meta.platNeuvolneny.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle odměn uvolněných zastupitelů jednotlivých krajů (rok 2024)",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Odměna neuvolněných zastupitelů krajů se v roce <span className="emp"><strong>2024</strong></span> pohybovala od <span className="emp"><strong>9&nbsp;211&nbsp;Kč</strong></span> do <span className="emp"><strong>10&nbsp;140&nbsp;Kč</strong></span>. Největší odměna zastupitelů byla v <span className="emp"><strong>Praze</strong></span> a nejmenší v <span className="emp"><strong>krajích s počtem obyvatel do 500 000</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.platNeuvolneny.values,
                                            getValue: meta.platNeuvolneny.getValue,
                                            formatValue: meta.platNeuvolneny.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "MVČR - Výše odměn 2024",
                                            value: "https://www.mvcr.cz/odk2/soubor/tabulka-a-koeficenty-pdf.aspx"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const salaryMemberStats = {
    title: 'Odměna zastupitelů',
    widget: {
        type: "column-group",
        config: {
            widgets: [
                {
                    type: "custom",
                    config: {
                        content: (
                            <p>
                                Členové zastupitelstev jsou odměňováni na základě počtu obyvatel a dělí se na&nbsp;<span className="emp"><strong>uvolněné</strong></span> a&nbsp;<span className="emp"><strong>neuvolněné</strong></span>, přičemž každá skupina je odměňována jiným způsobem. <span className="emp">Uvolnění zastupitelé</span> vykonávají svou funkci jako <span className="emp">hlavní pracovní činnost</span> a&nbsp;dostávají za&nbsp;ni pravidelný plat, zatímco <span className="emp">neuvolnění zastupitelé</span> svou&nbsp;funkci vykonávají <span className="emp">vedle jiného zaměstnání</span> a&nbsp;jejich odměna je nižší a&nbsp;často zahrnuje jen&nbsp;náhrady nákladů. 
                                Uvedené odměny zahrnují řadové členy zastupitelstva, kteří nevykonávají žádnou další funkci jako člen rady, náměstek apod.  
                            </p>
                        )
                    }
                },
                {
                    type: "regional-filtered-choropleth",
                    config: {
                        id: "mapa-odmenaZastupitelu",
                        label: "Odměna zastupitelů",
                        geo: geo,
                        datasets: [
                            platUvolneny, platNeuvolneny, platUvolneny23, platNeuvolneny23
                        ]
                    }
                }
            ]
        }
    },
    footer: {
        left: {
            title: {
                label: "Zdroj:",
                icon: "pi pi-fw pi-globe"
            },
            links: [
                {
                    label: "MVČR - Výše odměn 2023",
                    value: "https://www.mvcr.cz/odk2/soubor/metodicke-doporuceni-k-aktualnim-zmenam-v-oblasti-odmenovani-clenu-zastupitelstev-usc-k-1-lednu-2023.aspx"
                },
                {
                    label: "MVČR - Výše odměn 2024",
                    value: "https://www.mvcr.cz/odk2/soubor/tabulka-a-koeficenty-pdf.aspx"
                }
            ]
        },
        right: {
            title: undefined,
            links: [
                meta.route
            ]
        }
    }
}
export default salaryMemberStats;