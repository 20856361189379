import { Messages } from 'primereact/messages';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingPage from '../../../components/LoadingPage';
import { getErrorMessage } from '../../../utils/errors';
import { ErrorMessage } from '../../components/ErrorMessage';
import authAxios from '../../lib/authAxios';
import { showErrorMessage } from '../../utils/messages';
import { RequestNewMunicipalityInfoForm } from './components/RequestNewMunicipalityInfoForm';

export default function RequestNewMunicipalityResolve() {
    const { requestId } = useParams();

    const navigate = useNavigate();

    const msgs = useRef(null);

    const [loading, setLoading] = useState(true);
    const [initError, setInitError] = useState(null);
    const [request, setRequest] = useState(null);

    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await authAxios.get(`admin/request/add-municipality/${requestId}`);
                setRequest(response.data);
            } catch (error) {
                const errorMessage = getErrorMessage(error);
                setInitError(errorMessage ?? 'Nepodařilo se načíst žádost na přidání municipality');
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [requestId]);

    const handleResolveRequest = async (approved) => {
        setProcessing(true);
        const requestData = {
            approved: approved
        };
        try {
            await authAxios.patch(`admin/request/add-municipality/${request.id}`, requestData);
            navigate('/admin/pozadavky/');
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se vyřídit žádost na přidání municipality');
        } finally {
            setProcessing(false);
        }
    };

    return loading ? (
        <LoadingPage />
    ) : (
        <>
            <h1>Vyřídit žádost na přidání nové municipality</h1>

            {initError ? (
                <ErrorMessage text={initError} />
            ) : (
                <>
                    <Messages ref={msgs} className='page__messages' />

                    <RequestNewMunicipalityInfoForm
                        request={request}
                        onResolve={handleResolveRequest}
                        processing={processing}
                    />
                </>
            )}
        </>
    );
}
