import axios from 'axios';
import { Messages } from 'primereact/messages';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingPage from '../../../components/LoadingPage';
import { getErrorMessage } from '../../../utils/errors';
import { ErrorMessage } from '../../components/ErrorMessage';
import authAxios from '../../lib/authAxios';
import { formatTime } from '../../utils/format';
import { showErrorMessage, showSuccessMessage } from '../../utils/messages';
import { scrollTop } from '../../utils/scroll';
import { VoteForm } from './components/VoteForm';
import { VoteBreadCrumb } from './components/VoteBreadCrumb';

export default function VoteEdit() {
    const { municipalityId, termId, meetingId, voteId } = useParams();
    const bodyType = 'zastupitelstvo';

    const msgs = useRef(null);

    const [loading, setLoading] = useState(true);
    const [initError, setInitError] = useState(null);
    const [vote, setVote] = useState(null);
    const [members, setMembers] = useState([]);
    const [resultOptions, setResultOptions] = useState([]);
    const [voteOptions, setVoteOptions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [responseVote, responseVoteOptions, responseVoteResultOptions] = await Promise.all([
                    authAxios.get(`admin/${municipalityId}/${bodyType}/vote/${voteId}`),
                    axios.get(`FE/${municipalityId}/${bodyType}/dropdownVoteOptions`),
                    axios.get(`FE/${municipalityId}/${bodyType}/dropdownSessionResults`)
                ]);
                setVote(responseVote.data);
                setMembers(responseVote.data.members);
                setVoteOptions(responseVoteOptions.data);
                setResultOptions(responseVoteResultOptions.data);
            } catch (error) {
                const errorMessage = getErrorMessage(error);
                setInitError(errorMessage ?? 'Nepodařilo se načíst údaje o hlasování');
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [municipalityId, voteId]);

    const onSubmit = async (data) => {
        const requestData = {
            number: data.number,
            time: formatTime(data.time),
            result: data.result,
            item: data.item,
            valid: data.valid,
            procedural: data.procedural,
            secret: data.secret,
            minutes_url: data.minutes_url,
            member_votes: Object.entries(data.members).map(([memberId, data]) => ({
                member_id: memberId,
                option: data.option,
                in_person: !!data.in_person // can be undefined if member vote was not present in API response
            }))
        };
        if (data.secret) {
            requestData.sum_yes = data.sum_yes ?? 0;
            requestData.sum_no = data.sum_no ?? 0;
            requestData.sum_abstained = data.sum_abstained ?? 0;
            requestData.sum_did_not_vote = data.sum_did_not_vote ?? 0;
            requestData.sum_absent = data.sum_absent ?? 0;
            requestData.sum_excused = data.sum_excused ?? 0;
        }
        try {
            await authAxios.put(`admin/${municipalityId}/${bodyType}/vote/${voteId}`, requestData);
            showSuccessMessage(msgs, 'Hlasování bylo úspěšně upraveno');
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se upravit hlasování', { replace: true });
        } finally {
            scrollTop();
        }
    };

    return loading ? (
        <LoadingPage />
    ) : (
        <>
            <VoteBreadCrumb
                extraItems={[
                    {
                        label: 'Upravit',
                        url: `/admin/municipality/${municipalityId}/funkcni-obdobi/${termId}/zasedani/${meetingId}/hlasovani/${voteId}`
                    }
                ]}
            />

            {initError ? (
                <>
                    <h1>Upravit hlasování</h1>
                    <ErrorMessage prefix='Nelze použít formulář' error={initError} />
                </>
            ) : (
                <>
                    <h1>Upravit hlasování č. {vote.number}</h1>

                    <Messages ref={msgs} className='page__messages' />
                    <VoteForm
                        members={members}
                        resultOptions={resultOptions}
                        voteOptions={voteOptions}
                        vote={vote}
                        onSubmit={onSubmit}
                    />
                </>
            )}
        </>
    );
}
