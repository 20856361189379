import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Column } from '../../../../components/PrimereactComponents';
import { ButtonDelete, ButtonEdit } from '../../../components/Buttons';
import { Permission, UserRole } from '../../../constants/enums';
import { hasPermission, hasRole } from '../../../utils/auth';
import { PaginatedDataTable } from '../../../components/PaginatedDataTable';
import { PrimeIcons } from 'primereact/api';
import { BaseChip } from '../../../components/Chips';

export const MunicipalityTable = ({
    municipalities,
    loading,
    user,
    onRowClick,
    onClickDelete,
    onClickAddRequest,
    disabled
}) => {
    const { municipalityId } = useParams();

    const navigate = useNavigate();

    const highlightRow = (rowData) => {
        return {
            'table-row--primary': municipalityId && rowData.text_id === municipalityId,
            'table-row--clickable': hasPermission(user, Permission.MANAGE_MUNICIPALITY, rowData.text_id),
            'table-row--bold': hasPermission(user, Permission.MANAGE_MUNICIPALITY, rowData.text_id)
        };
    };

    const partnerTemplate = (rowData) => {
        return rowData.partner ? <i className={`${PrimeIcons.CHECK} color-success`} /> : '';
    };

    const canManageTemplate = (rowData) => {
        return hasPermission(user, Permission.MANAGE_MUNICIPALITY, rowData.text_id) ? (
            <BaseChip label='Ano' severity='success' />
        ) : (
            <BaseChip
                label='Ne'
                hoverable
                title='Požádat o právo spravovat municipalitu'
                onClick={() => onClickAddRequest(rowData)}
            />
        );
    };

    const buttonsTemplate = (rowData) => {
        return (
            <div className='table-buttons'>
                {hasPermission(user, Permission.MANAGE_MUNICIPALITY, rowData.text_id) && (
                    <ButtonEdit
                        rounded
                        title='Editovat municipalitu'
                        disabled={disabled}
                        onClick={() => navigate(`/admin/municipality/${rowData.text_id}/edit`)}
                    />
                )}
                {hasRole(user, UserRole.ADMIN) && (
                    <ButtonDelete
                        rounded
                        title='Smazat municipalitu'
                        disabled={disabled}
                        onClick={() => onClickDelete(rowData)}
                    />
                )}
            </div>
        );
    };

    return (
        <PaginatedDataTable
            value={municipalities}
            loading={loading}
            removableSort
            initialRows={25}
            breakpoint='900px'
            emptyMessage='Nebyly nalezeny žádné municipality'
            onRowClick={onRowClick}
            rowHover
            rowClassName={highlightRow}
        >
            <Column field='name' header='Název' sortable />
            <Column field='type_name' header='Typ' />
            <Column field='text_id' header='Textové ID' sortable />
            <Column field='email' header='Kontaktní email' />
            <Column header='Partner' body={partnerTemplate} align='center' />
            {!hasRole(user, UserRole.ADMIN) && <Column header='Správce' body={canManageTemplate} align='center' />}
            <Column body={buttonsTemplate} align='right' />
        </PaginatedDataTable>
    );
};
