import { ColorPicker } from 'primereact/colorpicker';
import { Column } from 'primereact/column';
import React from 'react';
import { ButtonDelete, ButtonEdit } from '../../../components/Buttons';
import { PaginatedDataTable } from '../../../components/PaginatedDataTable';

export const PoliticalEntityTable = ({ politicalEntities, loading, onClickEdit, onClickDelete, disabled }) => {
    const colorTemplate = (rowData) => {
        return (
            <div className='color-display'>
                <span>{rowData.color.toUpperCase()}</span>
                <ColorPicker value={rowData.color} disabled />
            </div>
        );
    };

    const buttonsTemplate = (rowData) => {
        return (
            <div className='table-buttons'>
                <ButtonEdit
                    title='Upravit politický subjekt'
                    rounded
                    disabled={disabled}
                    onClick={() => onClickEdit(rowData)}
                />
                <ButtonDelete
                    title='Smazat politický subjekt'
                    rounded
                    disabled={disabled}
                    onClick={() => onClickDelete(rowData)}
                />
            </div>
        );
    };

    return (
        <PaginatedDataTable
            value={politicalEntities}
            loading={loading}
            removableSort
            breakpoint='900px'
            emptyMessage='Municipalita nemá definovány žádné politické subjekty'
        >
            <Column field='id' header='ID' sortable />
            <Column field='abbreviation' header='Zkratka' sortable />
            <Column field='name' header='Název' sortable />
            <Column header='Barva' body={colorTemplate} />
            <Column body={buttonsTemplate} align='right' />
        </PaginatedDataTable>
    );
};
