import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonClose, ButtonDetail, ButtonNo } from '../../../components/Buttons';
import { RequestInfoForm } from './RequestInfoForm';
import { Messages } from 'primereact/messages';
import { ModalDialog } from '../../../components/ModalDialog';

export const ResolveRequestAddDialog = ({ isVisible, processing, request, onHide, onReject, msgs }) => {
    const navigate = useNavigate();

    return (
        <ModalDialog
            visible={isVisible}
            header='Vyřídit žádost na přidání nové municipality'
            style={{ width: 600 }}
            footer={
                <>
                    <ButtonClose label='Zrušit' onClick={onHide} />
                    <ButtonDetail
                        label='Detail'
                        onClick={() => navigate(`/admin/nova-municipalita/${request.id}`)}
                        tooltip='Zobrazit detailní informace o nové municipalitě a případně schválit požadavek'
                    />
                    <ButtonNo label='Zamítnout' onClick={onReject} disabled={processing} loading={processing} />
                </>
            }
            onHide={onHide}
        >
            <Messages ref={msgs} className='flex-center' />
            {request && <RequestInfoForm request={request} />}
        </ModalDialog>
    );
};
