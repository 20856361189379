import { PrimeIcons } from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import React from 'react';

export const IconTooltip = ({ children, warning }) => {
    const refIcon = React.useRef(null);

    const icon = warning ? PrimeIcons.EXCLAMATION_TRIANGLE : PrimeIcons.INFO_CIRCLE;

    return (
        <>
            <i
                className={classNames(`icon-tooltip ${icon}`, {
                    'icon-tooltip--warning': warning
                })}
                ref={refIcon}
            />
            <Tooltip target={refIcon}>{children}</Tooltip>
        </>
    );
};
