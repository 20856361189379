import { Badge, Tooltip } from './PrimereactComponents';
import { formatMemberName } from '../utils/texts';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const AliasTooltip = ({ id, aliases }) => {
    const { t } = useTranslation();

    const formatAliasInterval = (dateFrom, dateTo) => {
        if (dateFrom && dateTo) return ` (${dateFrom} - ${dateTo})`;
        else if (dateFrom) return ` (${t('interval-from')} ${dateFrom})`;
        else if (dateTo) return ` (${t('interval-to')} ${dateTo})`;
        else return '';
    };

    return (
        <>
            <Badge id={id} value={aliases.length} className='aliases-badge' />
            <Tooltip target={`#${id}`}>
                <div className='aliases-container'>
                    {aliases.map((a) => (
                        <span key={a.id}>
                            {formatMemberName(a.jmeno, a.prijmeni)}
                            {formatAliasInterval(a.datum_od, a.datum_do)}
                        </span>
                    ))}
                </div>
            </Tooltip>
        </>
    );
};
