export const VysledekHlasovaniMoznost = {
    PRIJATO: 1,
    NEPRIJATO: 2
};

export const HlasovaciMoznost = {
    ANO: 1,
    NE: 2,
    ZDRZEL_SE: 3,
    NEHLASOVAL: 4,
    NEPRITOMEN: 5,
    TAJNA: 6,
    OMLUVEN: 7
};

export const DochazkaMoznost = {
    PRITOMNOST: 1,
    CASTECNA_PRITOMNOST: 2,
    NEPRITOMNOST: 3
};

export const UserRole = {
    USER: 1,
    ADMIN: 2
};

export const Permission = {
    MANAGE_MUNICIPALITY: 'manage_municipality'
};

export const ErrorCode = {
    TOKEN_EXPIRED: 40101,
    TOKEN_INVALID: 40102
};
