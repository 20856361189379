import React from "react";

import "./LinksFooter.css"

function LinksFooter({
    region,
    data={},
    left=undefined,
    right=undefined
}) {

    const simplifyUrl = (url) => {
        return url?.replace("https://", "").replace("www.","").replace("/", "")
    }
    
    /**
     * Help function which generates links
     * 
     * @param {*} config 
     * @returns 
     */
    const generateLinks = (config) => {
        return (
            // left side
            config && (
                <>
                    {
                        // title
                        config.title &&
                        <div className="links-footer-item">
                            {
                                // icon
                                config.title.icon &&
                                <i className={ "pi " + config.title.icon }></i>
                            }
                            {
                                // label
                                config.title.label
                            }
                        </div>     
                    }
                    {
                        // links
                        config.links.map((link, index) => {
                            return (
                                <div className="links-footer-item" key={index}>
                                    {
                                        // link
                                        (link.value || link.onClick || (region && data && data[region] && data[region][link.id])) &&
                                        <span className="links-footer-item-a"
                                            onClick={() => {
                                                if(link.onClick) {
                                                    link.onClick()
                                                } else {
                                                    window.open(link.value ? link.value : data[region][link.id])
                                                }
                                            }
                                            }>
                                            {
                                                // icon
                                                link.icon &&
                                                <i className={ "pi " + link.icon }></i>
                                            }
                                            {
                                                // link label
                                                link.label ?? simplifyUrl(data[region][link.id])
                                            }
                                            <i className="pi pi-angle-right emp"></i>
                                        </span>
                                    }
                                </div>
                            )
                        })
                    }
                </>
            )
        )
    }

    return (
        <div className="links-footer">
            <div className="links-footer-left">
                {
                    // left side
                    left && (
                        generateLinks(left)
                    )
                }
            </div>
            <div className="links-footer-right">
                {
                    // right side
                    right && (
                        generateLinks(right)
                    )
                }
            </div>
        </div>
    )
}

export default LinksFooter;