import React from "react";

import "./Heading.css"

function Heading({
    level = 2,
    title = "/loga/logo_fit.png",
    arrowAnchor = "",
    style = {},
}) {

    const getHeading = () => {
        return React.createElement(`h${level}`, {}, title);
    }

    return (
        <>
            <div className="heading" style={style}>
                {
                    getHeading()
                }
                {
                    arrowAnchor &&
                    <a className="heading-arrow" href={`#${arrowAnchor}`}>
                        <i className="pi pi-angle-down"></i>
                    </a>
                }
            </div>
        </>
    )
}

export default Heading;