import { aboutDescriptionIntroWidget, aboutImageWidget } from "../../../about/about/about";

export const aboutSection = {
    id: "kdo-jsme",
    heading: {
        label: "Kdo jsme?",
    },
    content: {
        type: "widget-group",
        config: {
            style: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "2rem"
            },
            widgets: [
                aboutDescriptionIntroWidget,
                aboutImageWidget,
                {
                    type: "detail-ref",
                    config: {
                        url: "/o-projektu",
                    }
                }
            ]
        }
    }
}
