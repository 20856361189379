import APP from "../../../config";
import data from "../data";
import geo from "../geo";
import meta from "../meta";

/**
 * Transparency statistics.
 * 
 * @author Jiri Hynek
 */
const transparencyStats = {
    title: 'Transparentnost zasedání',
    content: () => {
        return (
            <>
                Kraje přistupují různým způsobem ke zveřejňování záznámů jednotlivých hlasování. Od strojově zpracovatelných serializovaných dat s podrobnými záznamy o hlasování, přes PDF dokumenty až po video záznamy, které jsou již obtížně zpracovatelné. Náš výzkumý tým byl schopen zpracovat celkem <span className="emp"><strong>9</strong>&nbsp;z&nbsp;14&nbsp;krajských zastupitelstev</span> (včetně Prahy).
            </>
        )
    },
    chart: {
        id: "mapa-transparentnost",
        type: "regional-choropleth",
        config: {
            geo: geo,
            data: data,
            categories: meta.transparentnost.values,
            getValue: meta.transparentnost.getValue,
            formatValue: meta.transparentnost.formatValue
        }
    },
    footer: {
        left: {
            title: {
                label: "Zdroj:",
                icon: "pi pi-fw pi-globe"
            },
            links: [
                {
                    label: "Datové sady",
                    value: `${APP.protocol}://kraje.${APP.domain}${APP.port && ':' + APP.port}/datasety`
                }
            ]
        },
        right: {
            title: undefined,
            links: [
                meta.route
            ]
        }
    }
}
export default transparencyStats;