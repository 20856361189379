import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { InputText } from '../../../../components/PrimereactComponents';
import { ButtonAdd, ButtonClose } from '../../../components/Buttons';
import { FormContainer, FormInput, FormRow, Label } from '../../../components/form';
import { LineDivider } from '../../../components/LineDivider';
import { Messages } from 'primereact/messages';
import { RHFInputTextarea } from '../../../components/rhf';
import { ModalDialog } from '../../../components/ModalDialog';
import { ZADOST_MUNICIPALITA_SPRAVA } from '../../../constants/field_lengths';
import { maxLengthMessage } from '../../../utils/errors';

export const AddRequestDialog = forwardRef(({ isVisible, municipality, onHide, onSubmit, msgs }, ref) => {
    const [processing, setProcessing] = useState(false);

    useImperativeHandle(ref, () => ({
        reset: reset
    }));

    const defaultValues = {
        text: ''
    };

    const {
        control,
        formState: { isSubmitting },
        handleSubmit,
        reset
    } = useForm({
        disabled: processing,
        defaultValues: defaultValues
    });

    useEffect(() => {
        setProcessing(isSubmitting);
    }, [isSubmitting]);

    useEffect(() => {
        if (!isVisible) {
            msgs?.current?.clear();
        }
    }, [isVisible, msgs]);

    const formId = 'add-permission-dialog-form';

    return (
        <ModalDialog
            visible={isVisible}
            header='Požádat o právo spravovat municipalitu'
            style={{ width: 600 }}
            footer={
                <>
                    <ButtonClose label='Zrušit' onClick={onHide} />
                    <ButtonAdd label='Požádat' type='submit' form={formId} />
                </>
            }
            onHide={onHide}
        >
            {municipality && (
                <form onSubmit={handleSubmit(onSubmit)} id={formId}>
                    <FormContainer type='dialog'>
                        <FormRow type='message'>
                            <Messages ref={msgs} />
                        </FormRow>
                        <FormRow>
                            <FormInput>
                                <Label htmlFor='municipalityName' text='Název municipality' />
                                <InputText id='municipalityName' disabled defaultValue={municipality.name} />
                            </FormInput>
                            <FormInput>
                                <Label htmlFor='municipalityTextId' text='ID municipality' />
                                <InputText id='municipalityTextId' disabled defaultValue={municipality.text_id} />
                            </FormInput>
                        </FormRow>
                        <LineDivider />
                        <FormRow>
                            <FormInput>
                                <Label htmlFor='text' text='Text žádosti' />
                                <RHFInputTextarea
                                    control={control}
                                    name='text'
                                    rules={{
                                        maxLength: {
                                            value: ZADOST_MUNICIPALITA_SPRAVA.TEXT,
                                            message: maxLengthMessage(ZADOST_MUNICIPALITA_SPRAVA.TEXT)
                                        }
                                    }}
                                    placeholder='Případné odůvodnění žádosti o správu municipality'
                                />
                            </FormInput>
                        </FormRow>
                    </FormContainer>
                </form>
            )}
        </ModalDialog>
    );
});
