import React, { useState } from 'react';
import ChartComponent from './ChartComponent';
import { Dropdown } from "./PrimereactComponents";
import { useTranslation } from 'react-i18next';

function UnityLineChart({data, options}) {
    const [selectedParty, setSelectedParty] = useState(null);
    const [filteredData, setFilteredData] = useState(undefined);

    const {t} = useTranslation();

    const handlePartyChange = (e) => {
        if(e.value === undefined){
            setSelectedParty(null);
            setFilteredData(undefined);
        } else{
          
        const selectedPartyName = e.value.name;
        setSelectedParty(e.value);
        
        const filteredDatasets = data.datasets.filter(dataset => {
          return dataset.label === selectedPartyName;
        });
    
        setFilteredData({ ...data, datasets: filteredDatasets });
    }
      };
    
      let without_null = data.datasets[0].data.filter(x => !!x)
      let min = Math.min(...without_null);
      let max = Math.max(...data.datasets[0].data);
      for (let obj of data.datasets) {
        var i = obj.data.filter(i => !!i)
        let objMin = Math.min(...i);
        let objMax = Math.max(...i);
        if (objMin < min) {
            min = objMin;
        }
         if (objMax > max) {
            max = objMax;
        }
        //let sum = obj.data.reduce((a, b) => a + b, 0);
        //let avg = sum / obj.data.length;
    }
    
    
        var hover_timeout;
      const chartOptions = {
        clip: false,
        maintainAspectRatio: false,
        aspectRatio: 1,
        plugins: {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            legend:{
                onHover: function (evt, item, legend) {
                    evt.native.target.style.cursor = 'pointer';
                    legend.chart.data.datasets.forEach((i, id) => {
                        if (id !== item.datasetIndex) {
                            i.borderColor += '30';
                            i.backgroundColor += '30';
                        } else{
                            i.borderWidth = 2;
                        }
                    });
                    clearTimeout(hover_timeout);
                        hover_timeout = setTimeout(function () {
                        legend.chart.update();
                    }, 100)
                },
                onLeave: function (evt, item, legend) {
                    legend.chart.data.datasets.forEach((i, id) => {
                        if (id !== item.datasetIndex) {
                            i.borderColor = i.borderColor.slice(0, -2);
                            i.backgroundColor = i.backgroundColor.slice(0, -2);
                        } else{
                            i.borderWidth = 1;
                        }
                    });
                    clearTimeout(hover_timeout);
                        hover_timeout = setTimeout(function () {
                        legend.chart.update();
                    }, 100)
                },
            },
        },
        elements: {
          line:{
            borderWidth:  1,
          },
          point:{
            borderWidth: 0,
          }
        },
        scales: {
            y: {
                ticks: {
                    callback: (value, index, values) => {
                      return value + " %";
                  },
                },
                min:0,
                
                max: Math.ceil(max),
                //min: (min > 99 ? 99 : min),
            },
        },
        onHover: function(evt, item) {
            if (item[0] !== undefined) {
                evt.native.target.style.cursor = "pointer";
            } else {
                evt.native.target.style.cursor = "default";
            }
        },
    };

    return (
            <div className="unity-line-chart">
                <ChartComponent type='line' height="450px" yTitle="Nejednotnost" xTitle="Zasedání" data={filteredData ? filteredData : data} options={chartOptions}/>
            
                <div className="selection-wrapper"> 
                    <span className="p-float-label">
                    <Dropdown showClear inputId="partySelection" value={selectedParty} options={options} onChange={handlePartyChange} optionLabel="name"/>
                    <label htmlFor="partySelection">{t("select-party")}</label>
                    </span>
                </div></div>
    );
}
export default UnityLineChart;