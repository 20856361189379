import { classNames } from 'primereact/utils';
import React from 'react';

const FormInputWidth = {
    LARGE: 'lg',
    SMALL: 'sm'
};

const FormInputType = {
    CHECKBOX: 'checkbox'
};

/**
 * @param {object} props
 * @param {boolean=} props.readonly
 * @param {'lg'|'sm'=} props.width
 * @param {'checkbox'=} props.type
 */
export const FormInput = ({ children, readonly, width, type }) => {
    return (
        <div
            className={classNames('form-input', {
                'form-input--readonly': readonly,
                'form-input--long': width === FormInputWidth.LARGE,
                'form-input--short': width === FormInputWidth.SMALL,
                'form-input--checkbox': type === FormInputType.CHECKBOX
            })}
        >
            {children}
        </div>
    );
};
