import axios from 'axios';
import { PrimeIcons } from 'primereact/api';
import React, { useEffect, useRef, useState } from 'react';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Menu, Menubar, Sidebar } from '../../components/PrimereactComponents';
import { UserRole } from '../constants/enums';
import { useUserPermissions } from '../hooks/useUserPermissions';
import { hasRole } from '../utils/auth';
import { BaseButton } from './Buttons';
import { LineDivider } from './LineDivider';
import { MunicipalityLogo } from './MunicipalityLogo';

export const AdminNavMenu = () => {
    const { municipalityId, termId, meetingId } = useParams();

    const navigate = useNavigate();

    const signOut = useSignOut();

    const [municipality, setMunicipality] = useState(null);
    const [isMenuSiderbarVisible, setIsMenuSidebarVisible] = useState(false);

    const { user } = useUserPermissions();

    const userMenuRef = useRef(null);

    useEffect(() => {
        const fetchMunicipality = async () => {
            try {
                const response = await axios.get(`municipality/${municipalityId}`);
                setMunicipality(response.data);
            } catch (error) {
                navigate('/chyba');
            }
        };
        if (municipalityId) fetchMunicipality();
        else setMunicipality(null);
    }, [municipalityId, navigate]);

    const showMenuSidebar = () => {
        setIsMenuSidebarVisible(true);
    };

    const hideMenuSidebar = () => {
        setIsMenuSidebarVisible(false);
    };

    const template = (item, options) => {
        return (
            <NavLink
                to={item.url}
                className={({ isActive }) =>
                    isActive ? `menu__link-active menu-item ${options.className}` : `${options.className} menu-item`
                }
                onClick={hideMenuSidebar}
                end
            >
                <span className={`${item.icon} menu-item__icon`} />
                <span>{item.label}</span>
            </NavLink>
        );
    };

    const navItems = [
        { label: 'Municipality', icon: PrimeIcons.BUILDING, url: '/admin/municipality/', template: template }
    ];

    if (hasRole(user, UserRole.ADMIN)) {
        const navItemsAdmin = [
            {
                label: 'Uživatelé',
                icon: 'bi bi-person',
                url: '/admin/uzivatele',
                template: template
            },
            {
                label: 'Požadavky',
                icon: 'bi bi-patch-question',
                url: '/admin/pozadavky',
                template: template
            }
        ];
        navItemsAdmin.forEach((item) => navItems.push(item));
    }

    if (municipalityId) {
        const navItemsMunicipality = [
            {
                template: () => <LineDivider layout='vertical' />
            },
            {
                label: 'Subjekty',
                icon: 'bi bi-person-workspace',
                url: `/admin/municipality/${municipalityId}/subjekty`,
                template: template
            },
            {
                label: 'Členové',
                icon: 'bi bi-people-fill',
                url: `/admin/municipality/${municipalityId}/clenove`,
                template: template
            },
            {
                label: 'Funkční období',
                icon: 'bi bi-calendar-range',
                url: `/admin/municipality/${municipalityId}/funkcni-obdobi`,
                template: template
            }
        ];
        navItemsMunicipality.forEach((item) => navItems.push(item));
    }

    if (termId) {
        const navItemsTerm = [
            {
                label: 'Zasedání',
                icon: 'bi bi-calendar-event',
                url: `/admin/municipality/${municipalityId}/funkcni-obdobi/${termId}/zasedani`,
                template: template
            }
        ];
        navItemsTerm.forEach((item) => navItems.push(item));
    }

    if (meetingId) {
        const navItemsMeeting = [
            {
                label: 'Hlasování',
                icon: 'bi bi-check2-square',
                url: `/admin/municipality/${municipalityId}/funkcni-obdobi/${termId}/zasedani/${meetingId}/hlasovani`,
                template: template
            }
        ];
        navItemsMeeting.forEach((item) => navItems.push(item));
    }

    const userTemplate = (item, options) => {
        return (
            <NavLink
                to={item.url}
                className={({ isActive }) => (isActive ? `menu__link-active ${options.className}` : options.className)}
                onClick={options.onClick}
                end
            >
                <span className={`${options.iconClassName} ${item.icon}`} />
                <span className={options.labelClassName}>{item.label}</span>
            </NavLink>
        );
    };

    const userMenuItems = [
        {
            label: `Přihlášen [${user?.login}]`,
            items: [
                {
                    label: 'Profil',
                    icon: PrimeIcons.USER,
                    url: '/admin/profil',
                    template: userTemplate
                }
            ]
        },
        { separator: true },
        {
            label: 'Odhlásit',
            icon: PrimeIcons.SIGN_OUT,
            command: () => {
                signOut();
                navigate('/admin/login');
            }
        }
    ];

    const userMenu = (
        <>
            <BaseButton
                icon={PrimeIcons.USER}
                rounded
                outlined
                onClick={(event) => userMenuRef.current.toggle(event)}
            />
            <Menu model={userMenuItems} popup ref={userMenuRef} baseZIndex={2001} />
        </>
    );

    const municipalityLogo = municipality ? <MunicipalityLogo municipality={municipality} /> : null;

    const start = (
        <>
            {municipalityLogo}
            <button
                onClick={showMenuSidebar}
                type='button'
                className='p-sidebar-icon p-link hamburger-icon menu-item'
                aria-label='Open'
            >
                <i className={PrimeIcons.BARS} />
            </button>
        </>
    );

    return (
        <div className='mainmenu mainmenu--admin'>
            <Menubar model={navItems} start={start} end={userMenu} />

            <Sidebar visible={isMenuSiderbarVisible} position='left' onHide={hideMenuSidebar}>
                {municipalityLogo}
                <Menu
                    className='sidebar-menu'
                    model={navItems.map((item) => (item.url ? item : { separator: true }))}
                />
            </Sidebar>
        </div>
    );
};
