import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useMemo } from 'react';
import { capitalizeFirstLetter } from '../../../../utils/texts';
import { RHFCheckbox, RHFRadioButton } from '../../../components/rhf';
import { classNames } from 'primereact/utils';
import { HlasovaciMoznost } from '../../../constants/enums';

export const MemberVoteTable = ({ members, voteOptions, control, onSetVoteOption, onVoteOptionChanged }) => {
    const voteOptionAbbreviations = useMemo(
        () =>
            voteOptions.reduce((acc, option) => {
                let abbreviation = option.name[0].toUpperCase();
                if (acc.find((a) => a === abbreviation)) {
                    abbreviation += option.name.slice(1, 3);
                }
                acc.push(abbreviation);
                return acc;
            }, []),
        [voteOptions]
    );

    const orderedMembers = useMemo(() => {
        return members.toSorted((a, b) => a.order - b.order);
    }, [members]);

    const voteOptionTitles = useMemo(() => {
        const map = new Map();
        voteOptions.forEach((option) => {
            map.set(option.code, option.name);
        });
        return map;
    }, [voteOptions]);

    const voteOptionTemplate = (rowData, code) => {
        return (
            <RHFRadioButton
                control={control}
                name={`members[${rowData.id}].option`}
                key={`members[${rowData.id}].option`}
                rules={{ required: true }}
                inputProps={{
                    value: code,
                    title: voteOptionTitles.get(code)
                }}
                onChange={(e, field) => {
                    field.onChange(e);
                    onVoteOptionChanged();
                }}
            />
        );
    };

    const inPersonTemplate = (rowData) => {
        return (
            <RHFCheckbox
                control={control}
                name={`members[${rowData.id}].in_person`}
                key={`members[${rowData.id}].in_person`}
            />
        );
    };

    return (
        <DataTable
            value={orderedMembers}
            removableSort
            sortMode='multiple'
            stripedRows
            breakpoint='1px'
            size='small'
            emptyMessage='Žádní členové nebyli nalezeni'
            className='member-vote-table'
        >
            <Column field='first_name' header='Jméno' bodyClassName='break-word' sortable />
            <Column field='last_name' header='Příjmení' bodyClassName='break-word' sortable />
            <Column field='political_entity.abbreviation' header='Subjekt' sortable />
            {voteOptions.map((option, idx, arr) => (
                <Column
                    header={
                        <span
                            title={`${capitalizeFirstLetter(option.name)}.${
                                option.code !== HlasovaciMoznost.NEPRITOMEN && option.code !== HlasovaciMoznost.OMLUVEN
                                    ? ' Kliknutím nastavíte hlasovací možnost pro všechny členy (ponechá hlasovací možnost nepřítomen a omluven).'
                                    : ''
                            }`}
                            className={classNames({
                                'clickable-text':
                                    option.code !== HlasovaciMoznost.NEPRITOMEN &&
                                    option.code !== HlasovaciMoznost.OMLUVEN
                            })}
                            onClick={
                                option.code !== HlasovaciMoznost.NEPRITOMEN && option.code !== HlasovaciMoznost.OMLUVEN
                                    ? () => onSetVoteOption(option.code)
                                    : undefined
                            }
                        >
                            <span className='header-text--long'>{capitalizeFirstLetter(option.name)}</span>
                            <span className='header-text--short'>{voteOptionAbbreviations[idx]}</span>
                        </span>
                    }
                    field={`option-${option.code}`}
                    key={`option-${option.code}`}
                    body={(rowData) => voteOptionTemplate(rowData, option.code)}
                    align='center'
                    className={classNames({
                        'table-cell-border-left': idx === 0,
                        'table-cell-border-right': idx === arr.length - 1
                    })}
                />
            ))}
            <Column
                field='in_person'
                header={
                    <>
                        <span className='header-text--long'>Prezenčně</span>
                        <span className='header-text--short'>Prez.</span>
                    </>
                }
                headerClassName='break-word'
                body={inPersonTemplate}
                align='center'
            />
        </DataTable>
    );
};
