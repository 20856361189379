import React from 'react';
import { ButtonClose, ButtonDelete } from '../../../components/Buttons';
import { ModalDialog } from '../../../components/ModalDialog';

export function ConfirmDeleteVoteDialog({ visible, onHide, handleDeleteVote, vote }) {
    return (
        <ModalDialog
            visible={visible}
            header='Potvrďte smazání'
            footer={
                <>
                    <ButtonClose label='Ne' onClick={onHide} />
                    <ButtonDelete label='Ano' onClick={handleDeleteVote} />
                </>
            }
            onHide={onHide}
        >
            {vote && (
                <div className='dialog-text'>
                    <span>
                        Opravdu chcete smazat hlasování č. <b>{vote.number}</b> (ID {vote.id})?
                    </span>
                </div>
            )}
        </ModalDialog>
    );
}
