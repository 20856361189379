export const CLEN = {
    JMENO: 50,
    PRIJMENI: 50
};

export const HLASOVANI = {
    URL_PROTOKOL: 150,
    CISLO: 10,
    PREDMET: 5000
};

export const POLITICKY_SUBJEKT = {
    ZKRATKA: 70,
    NAZEV: 150,
    BARVA: 10
};

export const ZASEDANI = {
    CISLO: 20
};

export const ZASEDANI_ZAZNAM = {
    URL: 500
};

export const ZASTUPITELSTVO = {
    LEADER_FUNKCE: 100
};

export const MUNICIPALITA = {
    TEXT_ID: 50,
    NAZEV: 150,
    NAZEV_GENITIV: 150,
    LOGO: 150,
    BARVA: 10,
    URL: 150,
    EMAIL: 100,
    CASOVA_ZONA: 64
};

export const ORGAN = {
    NAZEV: 150,
    NAZEV_GENITIV: 150,
    MISTO: 150,
    NAZEV_APLIKACE: 150,
    POPIS_APLIKACE: 200
};

export const UZIVATEL = {
    JMENO: 50,
    PRIJMENI: 50,
    LOGIN: 30
};

export const ZADOST_MUNICIPALITA_SPRAVA = {
    TEXT: 1000
};

export const ZADOST_MUNICIPALITA_NOVA = {
    TEXT: 1000
};
