import React from 'react';
import { useForm } from 'react-hook-form';
import { ButtonEdit } from '../../../components/Buttons';
import { FormContainer, FormInput, FormRow, Label } from '../../../components/form';
import { RHFInputText } from '../../../components/rhf';
import { UZIVATEL } from '../../../constants/field_lengths';
import { getFormErrorMessage, maxLengthMessage } from '../../../utils/errors';

export const ProfileNameForm = ({ user, onSubmit, disabled }) => {
    const defaultValues = {
        first_name: user.first_name ?? '',
        last_name: user.last_name ?? ''
    };

    const {
        control,
        formState: { errors, isSubmitting },
        handleSubmit
    } = useForm({
        disabled: disabled,
        defaultValues: defaultValues
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer type='card'>
                <FormRow type='subheading'>Upravit jméno</FormRow>
                <FormRow>
                    <FormInput>
                        <Label htmlFor='first_name' text='Jméno' />
                        <RHFInputText
                            control={control}
                            name='first_name'
                            rules={{
                                maxLength: {
                                    value: UZIVATEL.JMENO,
                                    message: maxLengthMessage(UZIVATEL.JMENO)
                                }
                            }}
                            placeholder='Jméno'
                        />
                        {getFormErrorMessage(errors, 'first_name')}
                    </FormInput>
                </FormRow>
                <FormRow>
                    <FormInput>
                        <Label htmlFor='last_name' text='Příjmení' />
                        <RHFInputText
                            control={control}
                            name='last_name'
                            rules={{
                                maxLength: {
                                    value: UZIVATEL.PRIJMENI,
                                    message: maxLengthMessage(UZIVATEL.PRIJMENI)
                                }
                            }}
                            placeholder='Příjmení'
                        />
                        {getFormErrorMessage(errors, 'last_name')}
                    </FormInput>
                </FormRow>
                <FormRow type='button'>
                    <ButtonEdit type='submit' label='Upravit' disabled={disabled} loading={isSubmitting} />
                </FormRow>
            </FormContainer>
        </form>
    );
};
