import APP from "../../config";
import { datasetsDescriptionSection, datasetsProcessSection, datasetsSourcesSection } from "../default/datasets";


export const datasets = (response, data) => {
    return {
        id: "datasety",
        sections: [
            {
                id: "datasety-typy",
                noAnchor: true,
                content: {
                    type: "custom",
                    config: {
                        style: {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            fontSize: "1.5rem",
                            marginTop: "2rem"
                        },
                        content: (
                            <>
                                <div style={{padding: "2rem" }}>
                                    <strong>
                                        <a href={`${APP.protocol}://kraje.${APP.domain}${APP.port ? (':' + APP.port) : ""}/datasety`}>
                                            <i className="pi pi-arrow-right" style={{margin: ".5rem"}}></i>
                                            <span>Kraje</span>
                                        </a>
                                    </strong>
                                </div>
                                <div>
                                    <strong>
                                        <a href={`${APP.protocol}://mesta.${APP.domain}${APP.port ? (':' + APP.port) : ""}/datasety`}>
                                            <i className="pi pi-arrow-right" style={{margin: ".5rem"}}></i>
                                            <span>Města</span>
                                        </a>
                                    </strong>
                                </div>
                            </>
                        )
                    }
                }
            },
            datasetsDescriptionSection,
            datasetsProcessSection,
            datasetsSourcesSection(data)
        ]
    }
}
export default datasets;