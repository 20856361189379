import { UserRole } from '../constants/enums';

export const hasRole = (user, role) => {
    return user?.role === role;
};

export const hasPermission = (user, key, id) => {
    if (user?.role === UserRole.ADMIN) {
        return true;
    }

    const allowedIds = user?.permissions?.[key];
    if (Array.isArray(allowedIds)) {
        return allowedIds.includes(id);
    }

    return false;
};
