import axios from 'axios';
import { Messages } from 'primereact/messages';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingPage from '../../../components/LoadingPage';
import { getErrorMessage } from '../../../utils/errors';
import { ErrorMessage } from '../../components/ErrorMessage';
import { MunicipalityForm } from '../../components/MunicipalityForm';
import authAxios from '../../lib/authAxios';
import { showErrorMessage, showSuccessMessage } from '../../utils/messages';
import { scrollTop } from '../../utils/scroll';

export default function MunicipalityAdd() {
    const navigate = useNavigate();

    const msgs = useRef(null);

    const [loading, setLoading] = useState(true);
    const [initError, setInitError] = useState(null);
    const [municipalityTypeOptions, setMunicipalityTypeOptions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('admin/municipality-type');
                setMunicipalityTypeOptions(
                    response.data.map((typMunicipality) => ({
                        label: typMunicipality.name,
                        value: typMunicipality.id
                    }))
                );
            } catch (error) {
                const errorMessage = getErrorMessage(error);
                setInitError(errorMessage ?? 'Nepodařilo se načíst typy municipalit');
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const onSubmit = async (data) => {
        const requestData = {
            text_id: data.text_id,
            municipality_type: data.municipality_type,
            name: data.name,
            name_genitive: data.name_genitive,
            color: data.color,
            url: data.url,
            email: data.email ? data.email : null,
            partner: data.partner,
            zastupitelstvo_application_name: data.zastupitelstvo_application_name,
            zastupitelstvo_name: data.zastupitelstvo_name,
            zastupitelstvo_name_genitive: data.zastupitelstvo_name_genitive,
            zastupitelstvo_place: data.zastupitelstvo_place,
            zastupitelstvo_member_count: data.zastupitelstvo_member_count,
            zastupitelstvo_application_description: data.zastupitelstvo_application_description
        };
        try {
            const response = await authAxios.post('admin/municipality', requestData);
            showSuccessMessage(msgs, 'Municipalita byla úspěšně vytvořena');

            if (data.logo) {
                try {
                    const formData = new FormData();
                    formData.append('logo', data.logo);
                    await authAxios.post(`admin/municipality/${response.data.text_id}/logo`, formData);
                    navigate(`/admin/municipality/${response.data.text_id}/subjekty/`);
                } catch (error) {
                    const errorMessage = getErrorMessage(error);
                    const baseErrorMessage = 'Nepodařilo se uložit logo municipality';
                    showErrorMessage(msgs, errorMessage ? `${baseErrorMessage}: ${errorMessage}` : baseErrorMessage);
                    scrollTop();
                }
            } else {
                navigate(`/admin/municipality/${response.data.text_id}/subjekty/`);
            }
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se přidat municipalitu', { replace: true });
            scrollTop();
        }
    };

    return loading ? (
        <LoadingPage />
    ) : (
        <>
            <h1>Přidat Municipalitu</h1>

            {initError ? (
                <ErrorMessage prefix='Nelze použít formulář' error={initError} />
            ) : (
                <>
                    <Messages ref={msgs} className='page__messages' />

                    <MunicipalityForm municipalityTypeOptions={municipalityTypeOptions} onSubmit={onSubmit} />
                </>
            )}
        </>
    );
}
