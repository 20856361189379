import { Column } from 'primereact/column';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonEdit } from '../../../components/Buttons';
import { PaginatedDataTable } from '../../../components/PaginatedDataTable';
import { compareAsc, compareDesc } from 'date-fns';
import { parseFormattedDate } from '../../../utils/date';

export const MeetingTable = ({ meetings, loading }) => {
    const { municipalityId, termId } = useParams();

    const navigate = useNavigate();

    const buttonsTemplate = (rowData) => {
        return (
            <ButtonEdit
                title='Upravit zasedání'
                rounded
                onClick={() => {
                    navigate(`/admin/municipality/${municipalityId}/funkcni-obdobi/${termId}/zasedani/${rowData.id}`);
                }}
            />
        );
    };

    const sortDate = (e) => {
        return e.data.sort((a, b) => {
            const dateA = parseFormattedDate(a.date);
            const dateB = parseFormattedDate(b.date);
            return e.order === 1 ? compareAsc(dateA, dateB) : compareDesc(dateA, dateB);
        });
    };

    return (
        <PaginatedDataTable
            value={meetings}
            loading={loading}
            breakpoint='500px'
            emptyMessage='Pro zvolené funkční období nebyla nalezena žádná zasedání'
            sortField='date'
            sortOrder={-1}
            onRowClick={(event) =>
                navigate(
                    `/admin/municipality/${municipalityId}/funkcni-obdobi/${termId}/zasedani/${event.data.id}/hlasovani`
                )
            }
            rowClassName={'table-row--clickable'}
            rowHover
        >
            <Column field='number' header='Číslo' sortable />
            <Column field='date' header='Datum' sortable sortFunction={sortDate} />
            <Column field='attendance' header='Účast' />
            <Column body={buttonsTemplate} align='right' />
        </PaginatedDataTable>
    );
};
