import React from "react";

import MunicipalityCard from "./MunicipalityCard";

import "./MunicipalityCardGrid.css"

function MunicipalityCardGrid({
    items = []
}) {

    return (
        <>
            <div className='municipality-card-grid'>
                {
                    items.map((municipality, key) => (
                        <MunicipalityCard key={key} municipality={municipality} enableLabel={false}></MunicipalityCard>
                    ))
                }
            </div>
        </>
    )
}

export default MunicipalityCardGrid;