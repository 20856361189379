import { Time } from '@internationalized/date';
import { TimeInput } from '@nextui-org/date-input';
import { PrimeIcons } from 'primereact/api';
import { classNames } from 'primereact/utils';
import React from 'react';
import { Controller } from 'react-hook-form';

/**
 * @param {object} params
 * @param {import('react-hook-form').UseFormRegister<any>} params.control
 * @param {string} params.name
 * @param {import('react-hook-form').RegisterOptions} params.rules
 * @param {import('@nextui-org/date-input').TimeInputProps} params.inputProps
 */
export const RHFTimeInput = ({ control, name, rules, inputProps }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => (
                <TimeInput
                    id={field.name}
                    {...field}
                    {...inputProps}
                    isDisabled={field.disabled}
                    className={classNames('p-component p-inputtext time-input', {
                        'p-invalid': fieldState.invalid,
                        'p-disabled': field.disabled
                    })}
                    hourCycle={24}
                    granularity='second'
                    endContent={
                        field.value && (
                            <i
                                className={`${PrimeIcons.TIMES} time-input__clear`}
                                onClick={() => field.onChange(null)}
                            />
                        )
                    }
                    // Prefill input segments with zeroes on valid input, so the user is not forced to fill out all segments.
                    // Also prevents a situation where the input without seconds (or any other segment) filled out has a null value,
                    // which could lead into issues if the input is optional.
                    onKeyUp={(e) => {
                        // nothing to do when the input has a valid value
                        if (field.value) {
                            return;
                        }

                        const container = e.target.parentElement;
                        if (!container) {
                            return;
                        }

                        // input segments
                        const hourElement = container.querySelector('[data-type="hour"]');
                        const minuteElement = container.querySelector('[data-type="minute"]');
                        const secondElement = container.querySelector('[data-type="second"]');
                        if (!hourElement || !minuteElement || !secondElement) {
                            return;
                        }

                        // No input yet, e.g. initial tab into the field
                        if (
                            hourElement.ariaValueText === 'Empty' &&
                            minuteElement.ariaValueText === 'Empty' &&
                            secondElement.ariaValueText === 'Empty'
                        ) {
                            return;
                        }

                        const hours = parseInt(hourElement.ariaValueNow);
                        const minutes = parseInt(minuteElement.ariaValueNow);
                        const seconds = parseInt(secondElement.ariaValueNow);
                        if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
                            return;
                        }

                        field.onChange(new Time(hours, minutes, seconds));
                    }}
                />
            )}
        />
    );
};
