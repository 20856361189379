import React from "react";

import Widget from "./Widget";

import "./WidgetGroup.css"

function WidgetGroup({
    widgets = [],
    style = {}
}) {

    return (
        <>
            <div className="widget-group" style={style}>
            {
                widgets && widgets.map((widget, index) => {
                    return (
                        <Widget
                            key={index}
                            type={widget.type}
                            config={widget.config}
                        />
                    )
                })
            }
            </div>
        </>
    )
}
export default WidgetGroup;