import { partneriListWidget, partnersDescriptionWidget } from "../../../partners/default/partners";

export const partnersSection = {
    id: "spoluprace",
    heading: {
        label: "Zapojte své zastupitelstvo",
    },
    content: {
        type: "widget-group",
        config: {
            style: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "2rem"
            },
            widgets: [
                partnersDescriptionWidget,
                partneriListWidget,
                {
                    type: "detail-ref",
                    config: {
                        url: "/spoluprace",
                    }
                }
            ]
        }
    }
}
