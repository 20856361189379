export const aboutDescriptionIntroWidget = {
    type: "custom",
    config: {
        style: {
            textAlign: "justify",
            maxWidth: "1200px"
        },
        content: (
            <>
                <p>
                    Jsme malá <a href="https://dexter.fit.vutbr.cz/" target="_blank" rel="noreferrer">skupina výzkumníků</a> zaměřující se na inovace v&nbsp;oblasti zpracování dat, chytrých měst a&nbsp;internetu věcí (IoT). 
                    Naším hlavním cílem je propojit technologické inovace s&nbsp;každodenním životem lidí, aby se svět kolem nás stal chytřejším, efektivnějším a&nbsp;udržitelnějším. 
                    Skrze naše projekty se zaměřujeme na praktické využití moderních technologií, které mají skutečný dopad na kvalitu života ve městech. 
                    Naše práce zahrnuje nejen pokročilé analýzy a&nbsp;vizualizace dat, ale také jejich aplikaci v&nbsp;reálném světě. 
                    Snažíme se porozumět složitým výzvám, kterým čelí městské prostředí, a&nbsp;nacházet řešení, která jsou funkční a&nbsp;přínosná pro širokou veřejnost. Věříme, že budoucnost měst je v&nbsp;chytrých řešeních, která respektují potřeby obyvatel.
                </p>
            </>
        )
    }
}

export const aboutDescriptionWidget = {
    type: "custom",
    config: {
        style: {
            textAlign: "justify",
            maxWidth: "1200px"
        },
        content: (
            <>
                <p>
                    Tento projekt vznikl díky nadšení a&nbsp;velkému odhodlání části našeho výzkumného týmu, která ve svém volném čase pracovala na těchto analýzách s&nbsp;cílem zlepšit situaci v&nbsp;oblasti vládních dat a&nbsp;informovanosti široké veřejnosti. Konkrétně se jedná o <a href="https://www.fit.vut.cz/person/zaklova/" target="_blank " rel="noreferrer">Kristýnu Zaklovou</a>, <a href="https://www.fit.vut.cz/person/hynek/" target="_blank" rel="noreferrer">Jirku Hynka</a> a&nbsp;<a href="https://www.fit.vut.cz/person/ijohn/" target="_blank" rel="noreferrer">Petra Johna</a>. 
                    Naši iniciativu podpořili také studenti FIT VUT v&nbsp;Brně v&nbsp;rámci svých závěrečných prací, konkrétně – Adam Janošík, Ondra Krejčí, Pavel Osinek a&nbsp;Lukáš Etzler.
                </p>
            </>
        )
    }
}

export const aboutImageWidget = {
    type: "image",
    config: {
        external: false,
        src: "/loga/logo_fit.png",
        url: "https://www.fit.vut.cz",
        style: {
            maxWidth: "400px"
        }
    }
}

export const about = (response) => {
    return {
        id: "o-projektu",
        sections: [
            {
                id: "tym",
                noAnchor: true,
                content: {
                    type: "widget-group",
                    config: {
                        style: {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "2rem"
                        },
                        widgets: [
                            aboutDescriptionIntroWidget,
                            aboutImageWidget,
                            aboutDescriptionWidget
                        ]
                    }
                }
            }
        ]
    }
}
export default about;