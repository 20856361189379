import React, { useState } from "react";
import { Dropdown } from "./PrimereactComponents";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import ChartComponent from './ChartComponent';
import { redirectErrorPage } from "../utils/errors";
import { useTranslation } from "react-i18next";

function SummaryVotesGraph(props) {
  const [partiesStats, setPartiesStats] = useState(undefined);
  const [options, setOptions] = useState(undefined);
  const [selectedVoteOption, setSelectedVoteOption] = useState(undefined);
  const [error, setError] = useState(null);
  
  const { municipalitaId, bodyType, zastupitelstvoId } = useParams();

  const {t} = useTranslation();

  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
        try {
            const [partiesDataResponse, voteOptionsResponse] =
                await Promise.all([
                    axios.get(`${municipalitaId}/${bodyType}/${zastupitelstvoId}/partiesStats`),
                    axios.get(`FE/${municipalitaId}/${bodyType}/dropdownVoteOptions`)
                ]);

            setPartiesStats(partiesDataResponse.data);
            setOptions(voteOptionsResponse.data);
            setSelectedVoteOption(voteOptionsResponse.data[0]);
        } catch (error) {
            setError(error);
            redirectErrorPage(error);
        }
    };

    fetchData();
}, []);

  function onOptionChange(e){
    setSelectedVoteOption(e.value);
  }


  if (partiesStats !== undefined ) {
    function dataSummaryVotes(option) {
      let datasets = partiesStats.map((party) => ({
        label: party.strana_zkratka,
        backgroundColor: party.strana_barva,
        data: [party.celkove_hlasy[option]]
      }));
      let data = {
        labels: [""],
        datasets: datasets,
      };
      return data;
    }

    let voteDataAno = dataSummaryVotes ('ano');
            let voteDataNe = dataSummaryVotes ('ne');
            let voteDataZdrzelSe = dataSummaryVotes ('zdržel se');
            let voteDataNehlasoval = dataSummaryVotes ('nehlasoval');
            let voteDataNeprit = dataSummaryVotes ('nepřít.');

            function selectedData(){
                switch (selectedVoteOption.code) {
                    case 1:
                        return voteDataAno;
                    case 2:
                        return voteDataNe;
                    case 3:
                        return voteDataZdrzelSe;
                    case 4:
                        return voteDataNehlasoval;
                    case 5:
                        return voteDataNeprit;
                    default:
                        break;
                }
            }
            let voteData = selectedData();

    const chartOptions = {
      indexAxis: "y",
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            generateLabels: (chart) => {
                const datasets = chart.data.datasets;
                return datasets.map((data, i) => ({
                    text: `${data.label} (${data.data[0]})`,
                    fillStyle: data.backgroundColor,
                    strokeStyle: 'white',
                    datasetIndex: 0,
                    index: i,
                }))
            }
        },
        onHover: function(evt, item, legend) {
          const tooltip = legend.chart.tooltip;
          if (tooltip.getActiveElements().length > 0) {
            tooltip.setActiveElements([], { x: 0, y: 0 });
          }
        
           tooltip.setActiveElements([{ datasetIndex: item.index, index: 0 }], { x: 0, y: 0 })
                    
            evt.native.target.style.cursor = "pointer";
            legend.chart.data.datasets.forEach((i, id) => {
                if(id !== item.index){i.backgroundColor+= '30'}
            });
              legend.chart.update();
        },
        onLeave: function(evt, item, legend){
            legend.chart.data.datasets.forEach((i, id) => {
                if(id !== item.index){i.backgroundColor = i.backgroundColor.slice(0, -2)}
            });
              legend.chart.update();
        },
        },
      },
      onHover: function (evt, item) {
        if (item[0] !== undefined) {
          evt.native.target.style.cursor = "pointer";
        } else {
          evt.native.target.style.cursor = "default";
        }
      },
      onClick: function (evt, item) {
        if (item[0] !== undefined) {
          let actItem = voteData.datasets[item[0].datasetIndex].label;
          let partyId = partiesStats.find(
            (obj) => obj.strana_zkratka === actItem,
          ).strana_id;
          let voteOptionId = selectedVoteOption.code;

          // REDIRECT
          navigate(
            `/${municipalitaId}/${bodyType}/${zastupitelstvoId}/hlasovani?pohled=subjekt&subjekt=` +
              partyId +
              `&hlasovaci_moznost=` +
              voteOptionId +
              `&pouze_platna=true`,
          );

          /*
          const element = document.getElementsByClassName("p-datatable")[0];
          if(element != null){
            element.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "center",
            });
          }
          */
        }
      },
    };

    if(partiesStats !== undefined && options !== undefined && selectedVoteOption !== undefined){
    return (
        <div className="summary-votes">
            <div className="dropdown">{t("voting-option")}: &nbsp;
            <span className="summary-votes-graph__dropdown">
            <Dropdown
              value={selectedVoteOption}
              options={options}
              onChange={onOptionChange}
              optionLabel="name"
            />
          </span>
        </div>
        <ChartComponent type="bar" data={voteData} options={chartOptions} height="250px" width='100%'/>
      </div>
    );} else if(error){
      redirectErrorPage(error);
    } else{
      return null;
    }
  }
};

export default SummaryVotesGraph;
