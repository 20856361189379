import React from 'react';
import { RHFDropdown } from '../../../components/rhf';
import { getFormArrayErrorMessage } from '../../../utils/errors';
import { ButtonDelete } from '../../../components/Buttons';
import {} from '../../../../components/PrimereactComponents';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ATTENDANCE_OPTIONS } from '../../../constants/constants';

export const MeetingEditSelectedMembersTable = ({
    control,
    errors,
    fields,
    politicalEntities,
    loading,
    disabled,
    onRemoveMember
}) => {
    const politicalEntityTemplate = (rowData) => {
        return (
            <div className='flex-column'>
                <RHFDropdown
                    name={`members[${rowData.index}].political_entity`}
                    key={`${rowData.item.id}-political_entity`}
                    control={control}
                    rules={{ required: 'Zvolte politický subjekt' }}
                    placeholder='Zvolte politický subjekt'
                    inputProps={{
                        options: politicalEntities,
                        optionValue: 'id',
                        optionLabel: 'abbreviation',
                        emptyMessage: 'Nejsou zadány žádné politické subjekty',
                        showClear: false
                    }}
                />
                {getFormArrayErrorMessage(errors, 'members', rowData.index, 'political_entity')}
            </div>
        );
    };

    const attendanceTemplate = (rowData) => {
        return (
            <div className='flex-column'>
                <RHFDropdown
                    name={`members[${rowData.index}].attendance`}
                    key={`${rowData.item.id}-attendance`}
                    control={control}
                    rules={{
                        required: 'Zvolte přítomnost na zasedání'
                    }}
                    placeholder='Zvolte přítomnost na zasedání'
                    inputProps={{
                        options: ATTENDANCE_OPTIONS,
                        showClear: false
                    }}
                />
                {getFormArrayErrorMessage(errors, 'members', rowData.index, 'attendance')}
            </div>
        );
    };

    const buttonsColumn = (rowData) => {
        return (
            <ButtonDelete
                type='button'
                tooltip='Odebrat člena'
                rounded
                onClick={() => onRemoveMember(rowData.item, rowData.index)}
                disabled={disabled}
            />
        );
    };

    return (
        <DataTable
            value={fields.map((item, index) => ({
                item,
                index
            }))}
            loading={loading}
            removableSort
            breakpoint='500px'
            emptyMessage='Nejsou zvoleni žádní členové'
        >
            <Column field='item.member_id' header='ID' sortable />
            <Column field='item.first_name' header='Jméno' sortable />
            <Column field='item.last_name' header='Příjmení' sortable />
            <Column header='Politický subjekt' body={politicalEntityTemplate} />
            <Column header='Přítomnost' body={attendanceTemplate} />
            <Column body={buttonsColumn} align='right' />
        </DataTable>
    );
};
