import PopupBodyChart from "../../../../components/map/popup/PopupBodyChart";
import PopupFooter from "../../../../components/map/popup/PopupFooter";
import PopupHeader from "../../../../components/map/popup/PopupHeader";
import APP from "../../../config";
import data from "../data";
import geo from "../geo";
import meta from "../meta";

/**
 * Share of votings ratio statistic.
 * 
 * @author Adam Janosik, Kristyna Zaklova
 */
const popup = {
    header: {
        component: PopupHeader,
        props: {
            data: data
        }
    },
    body: {
        component: PopupBodyChart,
        props: {
            data: data,
            stats: [
                {
                    title: "Srovnání dle poměru přijatých a nepřijatých hlasování za volební období 2020-2024",
                    content: () => {
                        return (
                            <>
                                <p>Za uplynulé volební období se poměr přijatých a&nbsp;nepřijatých hlasování zastupitelstev v&nbsp;jednotlivých krajích pohyboval od <span className="emp"><strong>93,4&nbsp;%</strong></span> do <span className="emp"><strong>99&nbsp;%</strong></span>. Nejvyšší poměr byl v&nbsp;<span className="emp"><strong>Karlovarském kraji</strong></span> a&nbsp;nejmenší v&nbsp;<span className="emp"><strong>Plzeňském kraji</strong></span>.</p>
                            </>
                        )
                    },
                    chart: {
                        type: "bar",
                        config: {
                            geo: geo,
                            data: data,
                            categories: meta.hlasovaniPomer.values,
                            getValue: meta.hlasovaniPomer.getValue,
                            formatValue: meta.hlasovaniPomer.formatValue
                        }
                    }
                }
            ]
        }
    },
    footer: {
        component: PopupFooter,
        props: {
            data: data,
            settings: {
                left: {
                    title: {
                        label: "Zdroj:",
                        icon: "pi pi-fw pi-globe"
                    },
                    links: [
                        {
                            label: "Datové sady",
                            value: `${APP.protocol}://kraje.${APP.domain}${APP.port && ':' + APP.port}/datasety`
                        }
                    ]
                },
                right: {
                    title: undefined,
                    links: [
                        meta.route
                    ]
                }
            }
        }
    },
    style: {
        width: '80vw',
        height: '100vh',
        maxWidth: '1200px',
    }
}

const votingsRatio = {
    title: 'Poměr přijatých a nepřijatých hlasování',
    content: () => {
        return (
            <>
                Lze pozorovat, že počet přijatých hlasování typicky významně převyšuje počet nepřijatých návrhů. 
                V <span className="emp">Karlovarském kraji</span> bylo za celé funkční období <span className="emp">zamítnuto pouze <strong>8</strong> návrhů</span>, což implikuje, že v tomto kraji je největší poměr přijatých hlasování ku všem hlasováním.            </>
        )
    },
    chart: {
        id: "mapa-pomer-prijatych-neprijatych-hlasovani",
        type: "regional-choropleth",
        config: {
            geo: geo,
            data: data,
            categories: meta.hlasovaniPomer.values,
            getValue: meta.hlasovaniPomer.getValue,
            formatValue: meta.hlasovaniPomer.formatValue,
            map: {
                defaults: {
                    polygon: {
                        hoverStyle: {
                            fill: "yellow"
                        },
                        popup: popup
                    },
                    capital: {
                        style: {
                            display: "none"
                        }
                    }
                }
            },
        }
    },
    footer: {
        left: {
            title: {
                label: "Zdroj:",
                icon: "pi pi-fw pi-globe"
            },
            links: [
                {
                    label: "Datové sady",
                    value: `${APP.protocol}://kraje.${APP.domain}${APP.port && ':' + APP.port}/datasety`
                }
            ]
        },
        right: {
            title: undefined,
            links: [
                meta.route
            ]
        },
        popup: {
            component: popup
        }
    }
}
export default votingsRatio;