import React from "react";

import { Chart } from 'primereact/chart';

import "./RegionalLineChart.css"

const chartColors = [
    "#A0C4FF", // Soft Blue
    "#BDB2FF", // Light Purple
    "#FFADAD", // Pale Red
    "#FFB4A2", // Soft Peach
    "#CAFFBF", // Pale Green
    "#9BF6FF", // Light Cyan
    "#FFC6FF", // Light Pink
    "#D0F4DE", // Pastel Green
    "#99C1B9", // Subtle Teal
    "#D4A5A5", // Soft Rose
    "#C49BBB", // Muted Lavender
    "#97B1A6", // Muted Sea Green
    "#ADA7C9", // Soft Lilac
    "#E5989B"  // Soft Coral
];

const defaultConfig = {
    sort: true,
    filterUndefined: false,
    elementStyle: {
        width: "100%",
        height: "100%",
        position: "relative",
    },
    style: {
        backgroundColor: "lightgray",
        borderColor: "lightgray",
        textColor: "black",
        disabledColor: "lightgray",
        emphasizeBackgroundColor: "yellow",
        emphasizeBorderColor: "yellow",
        emphasizeTextColor: "#b2b300",
        columnHeight: 30,
    },
    chartjs: undefined
}

const datasetTemplate = {
    axis: 'y',
    data: [],
    backgroundColor: [],
    borderColor: [],
    borderWidth: 2,
    barPercentage: 0.9,
    //barThickness: 20,
}

const chartjsStructure = {
    type: 'line',
    data: {
        labels: [],
        datasets: []
    },
    options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom'
            },
        }
    }
}

function RegionalLineChart({
    geo = {},
    datasets = [],
    config = defaultConfig,
    selection = undefined
}) {

    // initialize the chartjs structure
    const chartjs = JSON.parse(JSON.stringify(chartjsStructure));
    let i = 0;
    for(let region in geo) {
        chartjs.data.datasets.push(JSON.parse(JSON.stringify(datasetTemplate)));
        chartjs.data.datasets[i].label = geo[region].nameShort;
        if(selection) {
            if(selection.region === region) {
                chartjs.data.datasets[i].borderColor = "yellow";
                chartjs.data.datasets[i].backgroundColor = "yellow";
            } else {
                chartjs.data.datasets[i].borderColor = "lightgray";
                chartjs.data.datasets[i].backgroundColor = "lightgray";
            }
        } else {
            chartjs.data.datasets[i].borderColor = chartColors[i];
            chartjs.data.datasets[i].backgroundColor = chartColors[i];
        }
        i++;
    }

    const processDatasets = () => {

        for(let dataset in datasets) {
            chartjs.data.labels.push(datasets[dataset].label);

            let i = 0;
            for(let region in geo) {
                chartjs.data.datasets[i].data.push(datasets[dataset].getValue(datasets[dataset].data, region));
                i++;
            }
        }
    }

    processDatasets();

    return (
        <>
            <Chart
                type={chartjs.type}
                data={chartjs.data}
                options={chartjs.options}
                style={{
                    ...config.elementStyle ?? defaultConfig.elementStyle,
                    height: "500px"
                }} />
        </>
    )
}
export default RegionalLineChart;