import { Messages } from 'primereact/messages';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { showErrorMessage } from '../../../utils/messages';
import { getErrorMessage } from '../../../../utils/errors';
import authAxios from '../../../lib/authAxios';
import { Column } from '../../../../components/PrimereactComponents';
import { PaginatedDataTable } from '../../../components/PaginatedDataTable';
import { formatPoliticalEntityName } from '../../../utils/format';
import { BaseChip } from '../../../components/Chips';

export const MembershipTable = () => {
    const { municipalityId, memberId } = useParams();
    const bodyType = 'zastupitelstvo';

    const [memberships, setMemberships] = useState(null);
    const [loading, setLoading] = useState(true);

    const msgs = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await authAxios.get(
                    `admin/${municipalityId}/${bodyType}/member/${memberId}/membership`
                );
                setMemberships(response.data);
            } catch (error) {
                const errorMessage = getErrorMessage(error);
                showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se načíst členství');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [memberId, municipalityId]);

    const termTemplate = (rowData) => {
        return `${rowData.term_order}.`;
    };

    const dateFromTemplate = (rowData) => {
        if (rowData.date_from) {
            return rowData.date_from;
        }

        return (
            <BaseChip label='bez omezení' severity='success' outlined title='Členství od začátku funkčního období' />
        );
    };

    const dateToTemplate = (rowData) => {
        if (rowData.date_to) {
            return rowData.date_to;
        }

        return <BaseChip label='bez omezení' severity='success' outlined title='Členství do konce funkčního období' />;
    };

    const politicalEntityTemplate = (rowData) => {
        return formatPoliticalEntityName(rowData.political_entity);
    };

    return (
        <>
            <h2>Členství</h2>

            <Messages ref={msgs} className='page__messages' />

            <PaginatedDataTable
                value={memberships}
                loading={loading}
                initialRows={5}
                removableSort
                breakpoint='900px'
                emptyMessage='Pro člena nejsou definována žádná členství'
            >
                <Column header='Funkční období' body={termTemplate} sortField='term_order' sortable />
                <Column header='Začátek' body={dateFromTemplate} />
                <Column header='Konec' body={dateToTemplate} />
                <Column header='Politický subjekt' body={politicalEntityTemplate} />
            </PaginatedDataTable>
        </>
    );
};
