import { NavLink, useParams } from 'react-router-dom';
import React from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import { PrimeIcons } from 'primereact/api';

/**
 * @param {object} params
 * @param {{label: string, url?: string}[]} params.extraItems
 */
export const MunicipalityBreadCrumb = ({ extraItems }) => {
    const { municipalityId } = useParams();

    const template = (item, options) => {
        return item.url ? (
            <NavLink
                to={item.url}
                className={({ isActive }) =>
                    isActive ? `menu__link-active menu-item ${options.className}` : `${options.className} menu-item`
                }
                end
                title={item.title}
            >
                <span className={`${item.icon} menu-item__icon`} />
                <span>{item.label}</span>
            </NavLink>
        ) : (
            <>
                <span className={`${item.icon} menu-item__icon`} />
                <span>{item.label}</span>
            </>
        );
    };

    const items = [];

    if (municipalityId) {
        items.push({ label: municipalityId, url: `/admin/municipality/${municipalityId}`, template: template });
    }

    if (extraItems) {
        extraItems.forEach((item) => items.push({ ...item, template: template }));
    }

    return (
        <BreadCrumb
            model={items}
            home={{
                icon: PrimeIcons.BUILDING,
                title: 'Municipality',
                url: '/admin/municipality',
                template: template
            }}
        />
    );
};
