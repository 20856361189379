import { Messages } from 'primereact/messages';
import React from 'react';
import { ButtonClose, ButtonNo, ButtonYes } from '../../../components/Buttons';
import { RequestInfoForm } from './RequestInfoForm';
import { ModalDialog } from '../../../components/ModalDialog';

export const ResolvePermissionRequestDialog = ({
    isVisible,
    processing,
    request,
    onHide,
    onApprove,
    onReject,
    msgs
}) => {
    return (
        <ModalDialog
            visible={isVisible}
            header='Vyřídit žádost o správu municipality'
            style={{ width: 600 }}
            footer={
                <>
                    <ButtonClose label='Zrušit' onClick={onHide} />
                    <ButtonYes label='Schválit' onClick={onApprove} disabled={processing} loading={processing} />
                    <ButtonNo label='Zamítnout' onClick={onReject} disabled={processing} loading={processing} />
                </>
            }
            onHide={onHide}
        >
            <Messages ref={msgs} className='flex-center' />
            {request && <RequestInfoForm request={request} />}
        </ModalDialog>
    );
};
