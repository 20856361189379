import { Messages } from 'primereact/messages';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingPage from '../../../components/LoadingPage';
import { getErrorMessage } from '../../../utils/errors';
import { ErrorMessage } from '../../components/ErrorMessage';
import authAxios from '../../lib/authAxios';
import { showErrorMessage, showSuccessMessage } from '../../utils/messages';
import { TermAddForm } from './components/TermAddForm';
import { scrollTop } from '../../utils/scroll';
import { TermBreadCrumb } from './components/TermBreadCrumb';

export default function TermAdd() {
    const { municipalityId } = useParams();
    const bodyType = 'zastupitelstvo';

    const navigate = useNavigate();

    const msgs = useRef(null);

    const [loading, setLoading] = useState(true);
    const [initError, setInitError] = useState(null);
    const [members, setMembers] = useState([]);
    const [politicalEntities, setPoliticalEntities] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [politicalEntitiesResponse, membersResponse] = await Promise.all([
                    authAxios.get(`admin/${municipalityId}/${bodyType}/political-entity`),
                    authAxios.get(`admin/${municipalityId}/${bodyType}/member`)
                ]);
                setPoliticalEntities(politicalEntitiesResponse.data);
                setMembers(membersResponse.data);
            } catch (error) {
                const errorMessage = getErrorMessage(error);
                setInitError(errorMessage ?? 'Nepodařilo se načíst data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [municipalityId]);

    const onSubmit = async (data) => {
        const requestData = {
            year_from: data.year_from.getFullYear(),
            year_to: data.year_to.getFullYear(),
            order: data.order,
            coalition: data.coalition,
            leader_title: data.leader_title,
            leader: data.leader,
            members: data.members.map((member) => ({
                id: member.member_id,
                political_entity: member.political_entity
            }))
        };
        try {
            const response = await authAxios.post(`admin/${municipalityId}/${bodyType}/term`, requestData);
            showSuccessMessage(msgs, `Funkční období č. ${response.data.order} bylo úspěšně přidáno`, {
                replace: true
            });
            navigate(`/admin/municipality/${municipalityId}/funkcni-obdobi/${response.data.order}/zasedani`);
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se přidat funkční období', { replace: true });
            scrollTop();
        }
    };

    return loading ? (
        <LoadingPage />
    ) : (
        <>
            <TermBreadCrumb
                extraItems={[
                    {
                        label: 'Přidat',
                        url: `/admin/municipality/${municipalityId}/funkcni-obdobi/new`
                    }
                ]}
            />
            <h1>Přidat funkční období</h1>

            {initError ? (
                <ErrorMessage prefix='Nelze použít formulář' text={initError} />
            ) : (
                <>
                    <Messages ref={msgs} className='page__messages' />

                    <TermAddForm members={members} politicalEntities={politicalEntities} onSubmit={onSubmit} />
                </>
            )}
        </>
    );
}
