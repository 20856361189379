import React from "react";
import LinksFooter from "../../widget/LinksFooter";

import "./PopupFooter.css"

function PopupFooter({
    event,
    props = {
        data: {},
        settings: {}
    }
}) {

    return (
        <div className="popup-footer">
            <LinksFooter
                region={event?.region}
                data={props.data}
                left={props.settings.left}
                right={props.settings.right}
            ></LinksFooter>
        </div>
    )
}

export default PopupFooter;