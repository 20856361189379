/**
 * Definition of data related to the regions of the Czech Republic.
 *  
 * 
 * @author Jiri Hynek
 * 
 * @note Validováno 2024-08-27 Adam Janosik
 */

import APP from "../../config";
import generatedData from "./generated/data.json";

const data = {
    A: {
        nazev: "Hlavní město Praha",
        popis: "Praha je hlavním městem České republiky a zároveň samostatným krajem.",
        sidlo: "Praha",
        rz: "A",
        rozloha: 496,
        pocetObyvatel: 1379837, 
        pocetObyvatel23: 1384732,
        pocetObyvatel22: 1357326,
        pocetObyvatel21: 1275406,
        pocetObyvatel20: 1335084,
        hustotaZalidneni: 2790,
        nejvyssiBodNazev: "Teleček",
        nejvyssiBodVyska: 399,
        pocetOkresu: 1,
        pocetORP: 1,
        pocetPOU: 1,
        pocetObci: 1,
        pocetMest: 1,
        pocetMestysu: 0,
        route: `${APP.protocol}://praha.${APP.domain}${APP.port && ':' + APP.port}`,
        url: "https://praha.eu/",
        urlHlasovaciData: "https://data.gov.cz/datov%C3%A1-sada?iri=https%3A%2F%2Fdata.gov.cz%2Fzdroj%2Fdatov%C3%A9-sady%2F00064581%2Faaf8f062015cc627d5f247d6bd6cf9ac",
        urlDataset: "https://praha.zastupko.cz/flask/praha/zastupitelstvo/9/dataset",
        uplnyDataset: true,
        posledniAktualizace: "2024-08-29",
        CoA: "https://upload.wikimedia.org/wikipedia/commons/d/d3/Coat_of_arms_of_Prague.svg",
        CoALicense: "https://commons.wikimedia.org/wiki/File:Coat_of_arms_of_Prague.svg",
        wiki: "https://cs.wikipedia.org/wiki/Praha",
        transparentnost: "serialized",
        dostupnost: true,
        celkovyPocetZastupitelu: 65,
        pocetZastupitelu: 65,
        pocetZen: 20,
        pocetMuzu: 45,
        pocetSubjektu: 7,
        pocetZasedani: 24,
        pocetHlasovani: 1131,
        pocetHlasovaniPrijata: 1131,
        pocetHlasovaniNeprijata: 0,
        lidrJmeno: "Bohuslav",
        lidrPrijmeni: "Svoboda",
        lidrPohlavi: 0,
        lidrSubjekt: "SPOLU pro Prahu",
        dataset: "https://praha.zastupko.cz/flask/zastupitelstvo/6/dataset",
        prumernaHrubaMzda: 56372,
        prumernaHrubaMzda23: 50318,
        prumernaHrubaMzda22: 46999,
        prumernaHrubaMzda21: 44742,
        prumernaHrubaMzda20: 42573,
        nezamestnanost: 2.1,
        nezamestnanost22: 1.6,
        nezamestnanost21: 2.3,
        nezamestnanost20: 2.1,
        prijem: 140780639,
        vydaje: 111511586,
        prijem22: 120335910,
        vydaje22: 104822902,
        prijem21: 105636412,
        vydaje21: 89649360,
        prijem20: 96941215,
        vydaje20: 88034745,
        trestneCiny23: 41070,
        trestneCiny22: 40704,
        trestneCiny21: 35136,
        trestneCiny20: 38059,
        trestneCinyDetail23: [35765,	2815,	190,	850,	169,	27],
        trestneCinyDetail22: [35056,	3040,	255,	984,	153,	24],
        trestneCinyDetail21: [29798,	2813,	190,	1021,	125,	20],
        trestneCinyDetail20: [30872,	4567,	229,	998,	89,	17],
        csuSource: "https://csu.gov.cz/pha/domov?pocet=10&start=0&razeni=-datumVydani",
        csuPeople: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=DEMZU01a&z=T&f=TABULKA&skupId=3809&katalog=30845&pvo=DEMZU01a&evo=v450_!_IK-DEM-R-2030-2000-sestup_1&u=v81__VUZEMI__100__3018",
        csuSalary: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=MZD01-C&z=T&f=TABULKA&skupId=855&katalog=30852&pvo=MZD01-C&evo=v208_!_MZD-R-ABS-od2011_1&u=v159__VUZEMI__100__3018",
        subjekty: {
            "SPOLU pro Prahu": {
                color: "#212529",
                pocetZastupitelu: 18
            },
            "ANO 2011": {
                color: "#02A238",
                pocetZastupitelu: 14
            },
            "Pir\u00e1ti": {
                color: "#353535",
                pocetZastupitelu: 12
            },
            "PRAHA SOBĚ": {
                color: "#fee000",
                pocetZastupitelu: 11
            },
            "STAN": {
                color: "#ce0f68",
                pocetZastupitelu: 5
            },
            "SPD": {
                color: "#c30010",
                pocetZastupitelu: 3
            },
            "BEZPP": {
                color: "#742D2B",
                pocetZastupitelu: 2
            },
        },
        subjektyWiki: {
            "ANO": {
                pocetZastupitelu: 14
            },
            "ODS": {
                pocetZastupitelu: 9
            },
            "Piráti": {
                pocetZastupitelu: 13
            },
            "KDU-ČSL": {
                pocetZastupitelu: 2
            },
            "STAN": {
                pocetZastupitelu: 5
            },
            "SPD": {
                pocetZastupitelu: 2
            },
            "SOCDEM": {
                pocetZastupitelu: undefined
            },
            "TOP 09": {
                pocetZastupitelu: 7
            },
            "Zelení": {
                pocetZastupitelu: undefined
            },
            "KSČM": {
                pocetZastupitelu: undefined
            },
            "SNK ED": {
                pocetZastupitelu: undefined
            },
        },
    },
    C: {
        nazev: "Jihočeský kraj",
        popis: "Jihočeský kraj se nachází na jihu České republiky a je známý svými krásnými rybníky a historickými městy.",
        sidlo: "České Budějovice",
        rz: "C",
        rozloha: 10058, 
        pocetObyvatel: 652232,
        pocetObyvatel23: 654505,
        pocetObyvatel22: 652303,
        pocetObyvatel21: 637047,
        pocetObyvatel20: 643551,
        hustotaZalidneni: 65, 
        nejvyssiBodNazev: "Plechý",
        nejvyssiBodVyska: 1378,
        pocetOkresu: 7,
        pocetORP: 17,
        pocetPOU: 37,
        pocetObci: 623,
        pocetMest: 56,
        pocetMestysu: 23,
        route: "jihocesky",
        url: "https://www.kraj-jihocesky.cz",
        urlHlasovaciData: "https://kraj-jihocesky.cz/ku_usneseni/",
        urlDataset: "https://kraje.zastupko.cz/flask/jihocesky/zastupitelstvo/6/dataset",
        uplnyDataset: true,
        posledniAktualizace: "2024-08-29",
        CoA: "https://upload.wikimedia.org/wikipedia/commons/5/53/South_Bohemian_Region_CoA_CZ.svg",
        CoALicense: "https://commons.wikimedia.org/wiki/File:South_Bohemian_Region_CoA_CZ.svg",
        wiki: "https://cs.wikipedia.org/wiki/Jihočeský_kraj",
        transparentnost: "HTML",
        dostupnost: true,
        pocetZastupitelu: generatedData.C.pocetZastupitelu,
        celkovyPocetZastupitelu: generatedData.C.celkovyPocetZastupitelu,
        pocetZen: generatedData.C.pocetZen,
        pocetMuzu: generatedData.C.pocetMuzu,
        pocetSubjektu: generatedData.C.pocetSubjektu,
        pocetZasedani: generatedData.C.pocetZasedani,
        pocetHlasovani: generatedData.C.pocetHlasovani,
        pocetHlasovaniPrijata: generatedData.C.pocetHlasovaniPrijata,
        pocetHlasovaniNeprijata: generatedData.C.pocetHlasovaniNeprijata,
        lidrJmeno: generatedData.C.lidrJmeno,
        lidrPrijmeni: generatedData.C.lidrPrijmeni,
        lidrPohlavi: generatedData.C.lidrPohlavi,
        lidrSubjekt: generatedData.C.lidrSubjekt,
        prumernaHrubaMzda: 39767,
        prumernaHrubaMzda23: 37839,
        prumernaHrubaMzda22: 34982,
        prumernaHrubaMzda21: 33559,
        prumernaHrubaMzda20: 31436,
        nezamestnanost: 1.7,
        nezamestnanost22: 1.4,
        nezamestnanost21: 1.8,
        nezamestnanost20: 1.8,
        prijem: 26264748,
        vydaje: 24022845,
        prijem22: 23568302,
        vydaje22: 22369652,
        prijem21: 21845248,
        vydaje21: 21153269,
        prijem20: 19763991,
        vydaje20: 19762718,
        trestneCiny23: 8917,
        trestneCiny22: 8916,
        trestneCiny21: 7484,
        trestneCiny20: 7947,
        trestneCinyDetail23: [6518,	761,	65,	142,	63,	5],
        trestneCinyDetail22: [6511,	919,	57,	160,	35,	3],
        trestneCinyDetail21: [5090,	870,	43,	127,	49,	8],
        trestneCinyDetail20: [5301,	1089,	51,	162,	34,	6],
        csuSource: "https://csu.gov.cz/jhc/domov?pocet=10&start=0&razeni=-datumVydani",
        csuPeople: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=DEMZU01a&z=T&f=TABULKA&skupId=3809&katalog=30845&pvo=DEMZU01a&evo=v450_!_IK-DEM-R-2030-2000-sestup_1&u=v81__VUZEMI__100__3034",
        csuSalary: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=MZD01-C&z=T&f=TABULKA&skupId=855&katalog=30852&pvo=MZD01-C&evo=v208_!_MZD-R-ABS-od2011_1&u=v159__VUZEMI__100__3034",
        subjekty: generatedData.C.subjekty,
        subjektyWiki: {
            "ANO": {
                pocetZastupitelu: 8
            },
            "ODS": {
                pocetZastupitelu: 12
            },
            "Piráti": {
                pocetZastupitelu: 9
            },
            "KDU-ČSL": {
                pocetZastupitelu: 4
            },
            "STAN": {
                pocetZastupitelu: 5
            },
            "SOCDEM": {
                pocetZastupitelu: 6
            },
            "TOP 09": {
                pocetZastupitelu: 3
            },
            "SPD": {
                pocetZastupitelu: 4
            },
            "Zelení": {
                pocetZastupitelu: undefined
            },
            "KSČM": {
                pocetZastupitelu: undefined
            },
            "SNK ED": {
                pocetZastupitelu: undefined
            },
        },
    },
    B: {
        nazev: "Jihomoravský kraj",
        popis: "Jihomoravský kraj leží na jihovýchodě České republiky a je známý svými vinicemi a kulturními památkami.",
        sidlo: "Brno",
        rz: "B",
        rozloha: 7188,
        pocetObyvatel: 1222028,
        pocetObyvatel23: 1226749,
        pocetObyvatel22: 1217200,
        pocetObyvatel21: 1184568,
        pocetObyvatel20: 1195327,
        hustotaZalidneni: 171,
        nejvyssiBodNazev: "Durda",
        nejvyssiBodVyska: 836,
        pocetOkresu: 7,
        pocetORP: 21,
        pocetPOU: 34,
        pocetObci: 673,
        pocetMest: 50,
        pocetMestysu: 39,
        route: "jihomoravsky",
        url: "https://www.jmk.cz",
        urlHlasovaciData: "https://jmk.brandcloud.pro/en/folder/111960/112358",
        urlDataset: "https://kraje.zastupko.cz/flask/jihomoravsky/zastupitelstvo/6/dataset",
        uplnyDataset: true,
        posledniAktualizace: "2024-08-29",
        CoA: "https://upload.wikimedia.org/wikipedia/commons/b/b9/South_Moravian_Region_CoA_CZ.svg",
        CoALicense: "https://commons.wikimedia.org/wiki/File:South_Moravian_Region_CoA_CZ.svg",
        wiki: "https://cs.wikipedia.org/wiki/Jihomoravsk%C3%BD_kraj",
        transparentnost: "HTML", //poslali HTML, na stránkách PDF hlasovacích protokolů 
        dostupnost: true,
        pocetZastupitelu: generatedData.B.pocetZastupitelu,
        celkovyPocetZastupitelu: generatedData.B.celkovyPocetZastupitelu,
        pocetZen: generatedData.B.pocetZen,
        pocetMuzu: generatedData.B.pocetMuzu,
        pocetSubjektu: generatedData.B.pocetSubjektu,
        pocetZasedani: generatedData.B.pocetZasedani,
        pocetHlasovani: generatedData.B.pocetHlasovani,
        pocetHlasovaniPrijata: generatedData.B.pocetHlasovaniPrijata,
        pocetHlasovaniNeprijata: generatedData.B.pocetHlasovaniNeprijata,
        lidrJmeno: generatedData.B.lidrJmeno,
        lidrPrijmeni: generatedData.B.lidrPrijmeni,
        lidrPohlavi: generatedData.B.lidrPohlavi,
        lidrSubjekt: generatedData.B.lidrSubjekt,
        prumernaHrubaMzda: 43037,
        prumernaHrubaMzda23: 40131,
        prumernaHrubaMzda22: 37143,
        prumernaHrubaMzda21: 35695,
        prumernaHrubaMzda20: 33728,
        nezamestnanost: 2.5,
        nezamestnanost22: 1.8,
        nezamestnanost21: 2.5,
        nezamestnanost20: 2.3,
        prijem: 42847233,
        vydaje: 41975854,
        prijem22: 35319861,
        vydaje22: 35090638,
        prijem21: 33194141,
        vydaje21: 31946205,
        prijem20: 31182509,
        vydaje20: 31593195,
        trestneCiny23: 17970,
        trestneCiny22: 18579,
        trestneCiny21: 15544,
        trestneCiny20: 16985,
        trestneCinyDetail23: [14572,	1258,	186,	330,	64,	15],
        trestneCinyDetail22: [15152,	1217,	166,	433,	98,	10],
        trestneCinyDetail21: [12215,	1211,	174,	310,	72,	15],
        trestneCinyDetail20: [12703,	1970,	135,	512,	61,	14],
        csuSource: "https://csu.gov.cz/jhm/domov?pocet=10&start=0&razeni=-datumVydani",
        csuPeople: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=DEMZU01a&z=T&f=TABULKA&skupId=3809&katalog=30845&pvo=DEMZU01a&evo=v450_!_IK-DEM-R-2030-2000-sestup_1&u=v81__VUZEMI__100__3115",
        csuSalary: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=MZD01-C&z=T&f=TABULKA&skupId=855&katalog=30852&pvo=MZD01-C&evo=v208_!_MZD-R-ABS-od2011_1&u=v159__VUZEMI__100__3115",
        subjekty: generatedData.B.subjekty,
        subjektyWiki: {
            "ANO": {
                pocetZastupitelu: 15
            },
            "ODS": {
                pocetZastupitelu: 9
            },
            "Piráti": {
                pocetZastupitelu: 10
            },
            "KDU-ČSL": {
                pocetZastupitelu: 11
            },
            "STAN": {
                pocetZastupitelu: 6
            },
            "SOCDEM": {
                pocetZastupitelu: 4
            },
            "TOP 09": {
                pocetZastupitelu: 2
            },
            "SPD": {
                pocetZastupitelu: 4
            },
            "Zelení": {
                pocetZastupitelu: 1
            },
            "KSČM": {
                pocetZastupitelu: undefined
            },
            "SNK ED": {
                pocetZastupitelu: undefined
            },
        },
    },
    K: {
        nazev: "Karlovarský kraj",
        popis: "Karlovarský kraj je známý svými lázněmi a nachází se na západě České republiky.",
        sidlo: "Karlovy Vary",
        rz: "K",
        rozloha: 3310,
        pocetObyvatel: 292856,
        pocetObyvatel23: 295077,
        pocetObyvatel22: 293595,
        pocetObyvatel21: 283210,
        pocetObyvatel20: 293311,
        hustotaZalidneni: 89,
        nejvyssiBodNazev: "Klínovec",
        nejvyssiBodVyska: 1244,
        pocetOkresu: 3,
        pocetORP: 7,
        pocetPOU: 15,
        pocetObci: 134,
        pocetMest: 38,
        pocetMestysu: 1,
        route: "karlovarsky",
        url: "https://www.kr-karlovarsky.cz",
        urlHlasovaciData: "https://www.kr-karlovarsky.cz/karlovarsky-kraj/zastupitelstvo",
        urlDataset: "https://kraje.zastupko.cz/flask/karlovarsky/zastupitelstvo/6/dataset",
        uplnyDataset: false,
        posledniAktualizace: "2024-08-29",
        datasetPoznamka: "Obsahuje podmnožinu zasedaní",
        CoA: "https://upload.wikimedia.org/wikipedia/commons/6/67/Karlovy_Vary_Region_CoA_CZ.svg",
        CoALicense: "https://commons.wikimedia.org/wiki/File:Karlovy_Vary_Region_CoA_CZ.svg",
        wiki: "https://cs.wikipedia.org/wiki/Karlovarský_kraj",
        transparentnost: "HTML",
        dostupnost: false,
        pocetZastupitelu: generatedData.K.pocetZastupitelu,
        celkovyPocetZastupitelu: generatedData.K.celkovyPocetZastupitelu,
        pocetZen: generatedData.K.pocetZen,
        pocetMuzu: generatedData.K.pocetMuzu,
        pocetSubjektu: generatedData.K.pocetSubjektu,
        pocetZasedani: generatedData.K.pocetZasedani,
        pocetHlasovani: generatedData.K.pocetHlasovani,
        pocetHlasovaniPrijata: generatedData.K.pocetHlasovaniPrijata,
        pocetHlasovaniNeprijata: generatedData.K.pocetHlasovaniNeprijata,
        lidrJmeno: generatedData.K.lidrJmeno,
        lidrPrijmeni: generatedData.K.lidrPrijmeni,
        lidrPohlavi: generatedData.K.lidrPohlavi,
        lidrSubjekt: generatedData.K.lidrSubjekt,
        prumernaHrubaMzda: 37089,
        prumernaHrubaMzda23: 35565,
        prumernaHrubaMzda22: 32910,
        prumernaHrubaMzda21: 31844,
        prumernaHrubaMzda20: 29983,
        nezamestnanost: 4.3,
        nezamestnanost22: 4.0,
        nezamestnanost21: 5.7,
        nezamestnanost20: 4.7,
        prijem: 12350066,
        vydaje: 13054583,
        prijem22: 11021354,
        vydaje22: 10372168,
        prijem21: 9869446,
        vydaje21: 9465532,
        prijem20: 8807422,
        vydaje20: 9050850,
        trestneCiny23: 5041,
        trestneCiny22: 4939,
        trestneCiny21: 3754,
        trestneCiny20: 4422,
        trestneCinyDetail23: [3875,	352,	40,	133,	33,	6],
        trestneCinyDetail22: [3648,	567,	47,	101,	26,	10],
        trestneCinyDetail21: [2771,	310,	41,	72,	26,	5],
        trestneCinyDetail20: [3222,	400,	46,	101,	23,	6],
        csuSource: "https://csu.gov.cz/kvk/domov?pocet=10&start=0&razeni=-datumVydani",
        csuPeople: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=DEMZU01a&z=T&f=TABULKA&skupId=3809&katalog=30845&pvo=DEMZU01a&evo=v450_!_IK-DEM-R-2030-2000-sestup_1&u=v81__VUZEMI__100__3051",
        csuSalary: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=MZD01-C&z=T&f=TABULKA&skupId=855&katalog=30852&pvo=MZD01-C&evo=v208_!_MZD-R-ABS-od2011_1&u=v159__VUZEMI__100__3051",
        subjekty: generatedData.K.subjekty,
        subjektyWiki: {
            "ANO": {
                pocetZastupitelu: 13
            },
            "ODS": {
                pocetZastupitelu: 3
            },
            "Piráti": {
                pocetZastupitelu: 6
            },
            "KDU-ČSL": {
                pocetZastupitelu: 1
            },
            "STAN": {
                pocetZastupitelu: 7
            },
            "SOCDEM": {
                pocetZastupitelu: undefined
            },
            "TOP 09": {
                pocetZastupitelu: 1
            },
            "SPD": {
                pocetZastupitelu: 4
            },
            "Zelení": {
                pocetZastupitelu: undefined
            },
            "KSČM": {
                pocetZastupitelu: undefined
            },
            "SNK ED": {
                pocetZastupitelu: undefined
            },
        },
    },
    H: {
        nazev: "Královéhradecký kraj",
        popis: "Královéhradecký kraj leží na severovýchodě České republiky a je známý svými horami a kulturními památkami.",
        sidlo: "Hradec Králové",
        rz: "H",
        rozloha: 4759,
        pocetObyvatel: 554436,
        pocetObyvatel23: 556949,
        pocetObyvatel22: 555267,
        pocetObyvatel21: 542583,
        pocetObyvatel20: 550803,
        hustotaZalidneni: 117,
        nejvyssiBodNazev: "Sněžka",
        nejvyssiBodVyska: 1603,
        pocetOkresu: 5,
        pocetORP: 15,
        pocetPOU: 35,
        pocetObci: 448,
        pocetMest: 48,
        pocetMestysu: 13,
        url: "https://www.khk.cz/",
        urlHlasovaciData: "https://www.khk.cz/scripts/detail.php?pgid=1674&conn=9607&pg=1",
        urlDataset: "https://kraje.zastupko.cz/flask/kralovehradecky/zastupitelstvo/6/dataset",
        uplnyDataset: true,
        posledniAktualizace: "2024-08-29",
        route: "kralovehradecky",
        CoA: "https://upload.wikimedia.org/wikipedia/commons/d/d3/Hradec_Kralove_Region_CoA_CZ.svg",
        CoALicense: "https://commons.wikimedia.org/wiki/File:Hradec_Kralove_Region_CoA_CZ.svg",
        wiki: "https://cs.wikipedia.org/wiki/Královéhradecký_kraj",
        transparentnost: "HTML",
        dostupnost: true,
        pocetZastupitelu: generatedData.H.pocetZastupitelu,
        celkovyPocetZastupitelu: generatedData.H.celkovyPocetZastupitelu,
        pocetZen: generatedData.H.pocetZen,
        pocetMuzu: generatedData.H.pocetMuzu,
        pocetSubjektu: generatedData.H.pocetSubjektu,
        pocetZasedani: generatedData.H.pocetZasedani,
        pocetHlasovani: generatedData.H.pocetHlasovani,
        pocetHlasovaniPrijata: generatedData.H.pocetHlasovaniPrijata,
        pocetHlasovaniNeprijata: generatedData.H.pocetHlasovaniNeprijata,
        lidrJmeno: generatedData.H.lidrJmeno,
        lidrPrijmeni: generatedData.H.lidrPrijmeni,
        lidrPohlavi: generatedData.H.lidrPohlavi,
        lidrSubjekt: generatedData.H.lidrSubjekt,
        prumernaHrubaMzda: 39647,
        prumernaHrubaMzda23: 38183,
        prumernaHrubaMzda22: 35536,
        prumernaHrubaMzda21: 34503,
        prumernaHrubaMzda20: 32573,
        nezamestnanost: 3.2,
        nezamestnanost22: 2.7,
        nezamestnanost21: 2.3,
        nezamestnanost20: 2.6,
        prijem: 22268887,
        vydaje: 21323446,
        prijem22: 19719123,
        vydaje22: 19279612,
        prijem21: 18581685,
        vydaje21: 18054468,
        prijem20: 16720456,
        vydaje20: 17824368,
        trestneCiny23: 6817,
        trestneCiny22: 6659,
        trestneCiny21: 5268,
        trestneCiny20: 5794,
        trestneCinyDetail23: [5161,	579,	38,	107,	40,	7],
        trestneCinyDetail22: [4955,	565,	26,	142,	53,	9],
        trestneCinyDetail21: [3709,	499,	38,	70,	36,	3],
        trestneCinyDetail20: [3888,	779,	36,	92,	46,	4],
        csuSource: "https://csu.gov.cz/hkk/domov?pocet=10&start=0&razeni=-datumVydani",
        csuPeople: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=DEMZU01a&z=T&f=TABULKA&skupId=3809&katalog=30845&pvo=DEMZU01a&evo=v450_!_IK-DEM-R-2030-2000-sestup_1&u=v81__VUZEMI__100__3085",
        csuSalary: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=MZD01-C&z=T&f=TABULKA&skupId=855&katalog=30852&pvo=MZD01-C&evo=v208_!_MZD-R-ABS-od2011_1&u=v159__VUZEMI__100__3085",
        subjekty: generatedData.H.subjekty,
        subjektyWiki: {
            "ANO": {
                pocetZastupitelu: 12
            },
            "ODS": {
                pocetZastupitelu: 6
            },
            "Piráti": {
                pocetZastupitelu: 7
            },
            "KDU-ČSL": {
                pocetZastupitelu: 4
            },
            "STAN": {
                pocetZastupitelu: 3
            },
            "SOCDEM": {
                pocetZastupitelu: 3
            },
            "TOP 09": {
                pocetZastupitelu: 1
            },
            "SPD": {
                pocetZastupitelu: 2
            },
            "Zelení": {
                pocetZastupitelu: 1
            },
            "KSČM": {
                pocetZastupitelu: undefined
            },
            "SNK ED": {
                pocetZastupitelu: undefined
            },
        },
    },
    L: {
        nazev: "Liberecký kraj",
        popis: "Liberecký kraj leží na severu České republiky a je známý svými horami a krásnými přírodními scenériemi.",
        sidlo: "Liberec",
        rz: "L",
        rozloha: 3163,
        pocetObyvatel: 448625,
        pocetObyvatel23: 450728,
        pocetObyvatel22: 449177,
        pocetObyvatel21: 437570,
        pocetObyvatel20: 442476,
        hustotaZalidneni: 143,
        nejvyssiBodNazev: "Kotel",
        nejvyssiBodVyska: 1435,
        pocetOkresu: 4,
        pocetORP: 10,
        pocetPOU: 21,
        pocetObci: 215,
        pocetMest: 39,
        pocetMestysu: 4,
        route: "liberecky",
        url: "https://www.kraj-lbc.cz",
        urlHlasovaciData: "https://www.kraj-lbc.cz/samosprava/zastupitelstvo",
        urlDataset: "https://kraje.zastupko.cz/flask/liberecky/zastupitelstvo/6/dataset",
        uplnyDataset: false,
        posledniAktualizace: "2024-08-29",
        CoA: "https://upload.wikimedia.org/wikipedia/commons/4/4a/Liberec_Region_CoA_CZ.svg",
        CoALicense: "https://commons.wikimedia.org/wiki/File:Liberec_Region_CoA_CZ.svg",
        wiki: "https://cs.wikipedia.org/wiki/Libereck%C3%BD_kraj",
        transparentnost: "scan",
        dostupnost: false,
        pocetZastupitelu: generatedData.L.pocetZastupitelu,
        celkovyPocetZastupitelu: generatedData.L.celkovyPocetZastupitelu,
        pocetZen: generatedData.L.pocetZen,
        pocetMuzu: generatedData.L.pocetMuzu,
        pocetSubjektu: generatedData.L.pocetSubjektu,
        pocetZasedani: 45,
        pocetHlasovani: generatedData.L.pocetHlasovani,
        pocetHlasovaniPrijata: generatedData.L.pocetHlasovaniPrijata,
        pocetHlasovaniNeprijata: generatedData.L.pocetHlasovaniNeprijata,
        lidrJmeno: generatedData.L.lidrJmeno,
        lidrPrijmeni: generatedData.L.lidrPrijmeni,
        lidrPohlavi: generatedData.L.lidrPohlavi,
        lidrSubjekt: generatedData.L.lidrSubjekt,
        prumernaHrubaMzda: 39210,
        prumernaHrubaMzda23: 37244,
        prumernaHrubaMzda22: 34461,
        prumernaHrubaMzda21: 33194,
        prumernaHrubaMzda20: 31822,
        nezamestnanost: 3.3,
        nezamestnanost22: 2.0,
        nezamestnanost21: 2.6,
        nezamestnanost20: 2.9,
        prijem: 16843438,
        vydaje: 15918191,
        prijem22: 15598068,
        vydaje22: 13909999,
        prijem21: 13563346,
        vydaje21: 13046271,
        prijem20: 12324293,
        vydaje20: 12312915,
        trestneCiny23: 7705,
        trestneCiny22: 8399,
        trestneCiny21: 6586,
        trestneCiny20: 7290,
        trestneCinyDetail23: [6048,	549,	69,	149,	60,	9],
        trestneCinyDetail22: [6551,	687,	67,	183,	56,	2],
        trestneCinyDetail21: [4766,	673,	70,	111,	61,	5],
        trestneCinyDetail20: [5121,	857,	50,	105,	41,	6],
        csuSource: "https://csu.gov.cz/lbk/domov?pocet=10&start=0&pouzeVydane=true&kraje=3077&razeni=-datumVydani",
        csuPeople: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=DEMZU01a&z=T&f=TABULKA&skupId=3809&katalog=30845&pvo=DEMZU01a&evo=v450_!_IK-DEM-R-2030-2000-sestup_1&u=v81__VUZEMI__100__3077",
        csuSalary: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=MZD01-C&z=T&f=TABULKA&skupId=855&katalog=30852&pvo=MZD01-C&evo=v208_!_MZD-R-ABS-od2011_1&u=v159__VUZEMI__100__3077",
        subjekty: generatedData.L.subjekty,
        subjektyWiki: {
            "ANO": {
                pocetZastupitelu: 10
            },
            "ODS": {
                pocetZastupitelu: 4
            },
            "Piráti": {
                pocetZastupitelu: 5
            },
            "KDU-ČSL": {
                pocetZastupitelu: undefined
            },
            "STAN": {
                pocetZastupitelu: undefined
            },
            "SOCDEM": {
                pocetZastupitelu: undefined
            },
            "TOP 09": {
                pocetZastupitelu: undefined
            },
            "SPD": {
                pocetZastupitelu: 3
            },
            "Zelení": {
                pocetZastupitelu: undefined
            },
            "KSČM": {
                pocetZastupitelu: undefined
            },
            "SNK ED": {
                pocetZastupitelu: undefined
            },
        },
    },
    T: {
        nazev: "Moravskoslezský kraj",
        popis: "Moravskoslezský kraj leží na severovýchodě České republiky a je známý svým průmyslem a horami.",
        sidlo: "Ostrava",
        rz: "T",
        rozloha: 5431,
        pocetObyvatel: 1184637,
        pocetObyvatel23: 1189204,
        pocetObyvatel22: 1189674,
        pocetObyvatel21: 1177989,
        pocetObyvatel20: 1192834,
        hustotaZalidneni: 219,
        nejvyssiBodNazev: "Praděd",
        nejvyssiBodVyska: 1491,
        pocetOkresu: 6,
        pocetORP: 22,
        pocetPOU: 30,
        pocetObci: 300,
        pocetMest: 42,
        pocetMestysu: 4,
        route: "moravskoslezsky",
        url: "https://www.msk.cz",
        urlHlasovaciData: "https://www.msk.cz/cs/kraj/zastupitelstvo/vystupy-z-jednani-zastupitelstva-_-volebni-obdobi-2020_2024-883/",
        urlDataset: "https://kraje.zastupko.cz/flask/moravskoslezsky/zastupitelstvo/6/dataset",
        uplnyDataset: true,
        posledniAktualizace: "2024-08-29",
        CoA: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Moravian-Silesian_Region_CoA_CZ.svg",
        CoALicense: "https://commons.wikimedia.org/wiki/File:Moravian-Silesian_Region_CoA_CZ.svg",
        wiki: "https://cs.wikipedia.org/wiki/Moravskoslezsk%C3%BD_kraj",
        transparentnost: "HTML",
        dostupnost: true,
        pocetZastupitelu: generatedData.T.pocetZastupitelu,
        celkovyPocetZastupitelu: generatedData.T.celkovyPocetZastupitelu,
        pocetZen: generatedData.T.pocetZen,
        pocetMuzu: generatedData.T.pocetMuzu,
        pocetSubjektu: generatedData.T.pocetSubjektu,
        pocetZasedani: generatedData.T.pocetZasedani,
        pocetHlasovani: generatedData.T.pocetHlasovani,
        pocetHlasovaniPrijata: generatedData.T.pocetHlasovaniPrijata,
        pocetHlasovaniNeprijata: generatedData.T.pocetHlasovaniNeprijata,
        lidrJmeno: generatedData.T.lidrJmeno,
        lidrPrijmeni: generatedData.T.lidrPrijmeni,
        lidrPohlavi: generatedData.T.lidrPohlavi,
        lidrSubjekt: generatedData.T.lidrSubjekt,
        prumernaHrubaMzda: 39227,
        prumernaHrubaMzda23: 37279,
        prumernaHrubaMzda22: 34487,
        prumernaHrubaMzda21: 33417,
        prumernaHrubaMzda20: 31282,
        nezamestnanost: 3.9,
        nezamestnanost22: 4.0,
        nezamestnanost21: 4.6,
        nezamestnanost20: 3.6,
        prijem: 40360020,
        vydaje: 38670172,
        prijem22: 35673706,
        vydaje22: 34683916,
        prijem21: 33744101,
        vydaje21: 32443105,
        prijem20: 30200325,
        vydaje20: 30618690,
        trestneCiny23: 21564,
        trestneCiny22: 21884,
        trestneCiny21: 17865,
        trestneCiny20: 19635,
        trestneCinyDetail23: [17864,	1236,	264,	395,	97,	22],
        trestneCinyDetail22: [18026,	1219,	261,	470,	109,	15],
        trestneCinyDetail21: [14079,	1118,	203,	415,	59,	6],
        trestneCinyDetail20: [14703,	2007,	206,	436,	64,	13],
        csuSource: "https://csu.gov.cz/msk/domov?pocet=10&start=0&razeni=-datumVydani",
        csuPeople: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=DEMZU01a&z=T&f=TABULKA&skupId=3809&katalog=30845&pvo=DEMZU01a&evo=v450_!_IK-DEM-R-2030-2000-sestup_1&u=v81__VUZEMI__100__3140",
        csuSalary: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=MZD01-C&z=T&f=TABULKA&skupId=855&katalog=30852&pvo=MZD01-C&evo=v208_!_MZD-R-ABS-od2011_1&u=v159__VUZEMI__100__3140",
        subjekty: generatedData.T.subjekty,
        subjektyWiki: {
            "ANO": {
                pocetZastupitelu: 19
            },
            "ODS": {
                pocetZastupitelu: 9
            },
            "Piráti": {
                pocetZastupitelu: 9
            },
            "KDU-ČSL": {
                pocetZastupitelu: 7
            },
            "STAN": {
                pocetZastupitelu: undefined
            },
            "SOCDEM": {
                pocetZastupitelu: 5
            },
            "TOP 09": {
                pocetZastupitelu: 1
            },
            "SPD": {
                pocetZastupitelu: undefined
            },
            "Zelení": {
                pocetZastupitelu: undefined
            },
            "KSČM": {
                pocetZastupitelu: 4
            },
            "SNK ED": {
                pocetZastupitelu: undefined
            },
        },
    },
    M: {
        nazev: "Olomoucký kraj",
        popis: "Olomoucký kraj se nachází ve střední části Moravy a je známý svým kulturním a historickým dědictvím.",
        sidlo: "Olomouc",
        rz: "M",
        rozloha: 5272,
        pocetObyvatel: 631048,
        pocetObyvatel23: 632864,
        pocetObyvatel22: 631802,
        pocetObyvatel21: 622930,
        pocetObyvatel20: 630522,
        hustotaZalidneni: 120,
        nejvyssiBodNazev: "Praděd",
        nejvyssiBodVyska: 1491,
        pocetOkresu: 5,
        pocetORP: 13,
        pocetPOU: 21,
        pocetObci: 402,
        pocetMest: 31,
        pocetMestysu: 12,
        route: "olomoucky",
        url: "https://www.olkraj.cz",
        urlHlasovaciData: "https://www.olkraj.cz/zapisy-zok-cl-4995.html",
        urlDataset: "https://kraje.zastupko.cz/flask/olomoucky/zastupitelstvo/6/dataset",
        uplnyDataset: true,
        posledniAktualizace: "2024-08-29",
        CoA: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Olomouc_Region_CoA_CZ.svg",
        CoALicense: "https://commons.wikimedia.org/wiki/File:Olomouc_Region_CoA_CZ.svg",
        wiki: "https://cs.wikipedia.org/wiki/Olomouck%C3%BD_kraj",
        transparentnost: "PDF",
        dostupnost: true,
        pocetZastupitelu: generatedData.M.pocetZastupitelu,
        celkovyPocetZastupitelu: generatedData.M.celkovyPocetZastupitelu,
        pocetZen: generatedData.M.pocetZen,
        pocetMuzu: generatedData.M.pocetMuzu,
        pocetSubjektu: generatedData.M.pocetSubjektu,
        pocetZasedani: generatedData.M.pocetZasedani,
        pocetHlasovani: generatedData.M.pocetHlasovani,
        pocetHlasovaniPrijata: generatedData.M.pocetHlasovaniPrijata,
        pocetHlasovaniNeprijata: generatedData.M.pocetHlasovaniNeprijata,
        lidrJmeno: generatedData.M.lidrJmeno,
        lidrPrijmeni: generatedData.M.lidrPrijmeni,
        lidrPohlavi: generatedData.M.lidrPohlavi,
        lidrSubjekt: generatedData.M.lidrSubjekt,
        prumernaHrubaMzda: 38609,
        prumernaHrubaMzda23: 36740,
        prumernaHrubaMzda22: 34346,
        prumernaHrubaMzda21: 33262,
        prumernaHrubaMzda20: 31321,
        nezamestnanost: 2.8,
        nezamestnanost22: 3.4,
        nezamestnanost21: 2.6,
        nezamestnanost20: 3.1,
        prijem: 24278135,
        vydaje: 22422196,
        prijem22: 21714823,
        vydaje22: 20606111,
        prijem21: 20862390,
        vydaje21: 19757512,
        prijem20: 18728678,
        vydaje20: 19120498,
        trestneCiny23: 9224,
        trestneCiny22: 9114,
        trestneCiny21: 7802,
        trestneCiny20: 8369,
        trestneCinyDetail23: [6694,	948,	79,	120,	33,	5],
        trestneCinyDetail22: [6566,	904,	69,	92,	36,	13],
        trestneCinyDetail21: [5374,	793,	51,	129,	39,	5],
        trestneCinyDetail20: [5581,	1072,	65,	113,	23,	9],
        csuSource: "https://csu.gov.cz/olk/domov?pocet=10&start=0&razeni=-datumVydani",
        csuPeople: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=DEMZU01a&z=T&f=TABULKA&skupId=3809&katalog=30845&pvo=DEMZU01a&evo=v450_!_IK-DEM-R-2030-2000-sestup_1&u=v81__VUZEMI__100__3123",
        csuSalary: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=MZD01-C&z=T&f=TABULKA&skupId=855&katalog=30852&pvo=MZD01-C&evo=v208_!_MZD-R-ABS-od2011_1&u=v159__VUZEMI__100__3123",
        subjekty: generatedData.M.subjekty,
        subjektyWiki: {
            "ANO": {
                pocetZastupitelu: 18
            },
            "ODS": {
                pocetZastupitelu: 7
            },
            "Piráti": {
                pocetZastupitelu: 8
            },
            "KDU-ČSL": {
                pocetZastupitelu: 6
            },
            "STAN": {
                pocetZastupitelu: 5
            },
            "SOCDEM": {
                pocetZastupitelu: undefined
            },
            "TOP 09": {
                pocetZastupitelu: 2
            },
            "SPD": {
                pocetZastupitelu: 5
            },
            "Zelení": {
                pocetZastupitelu: 2
            },
            "KSČM": {
                pocetZastupitelu: undefined
            },
            "SNK ED": {
                pocetZastupitelu: undefined
            },
        },
    },
    E: {
        nazev: "Pardubický kraj",
        popis: "Pardubický kraj leží ve východních Čechách a je známý svým průmyslem a kulturními památkami.",
        sidlo: "Pardubice",
        rz: "E",
        rozloha: 4519,
        pocetObyvatel: 528453,
        pocetObyvatel23: 530560,
        pocetObyvatel22: 528761,
        pocetObyvatel21: 514518,
        pocetObyvatel20: 522856,
        hustotaZalidneni: 117,
        nejvyssiBodNazev: "Králický Sněžník",
        nejvyssiBodVyska: 1424,
        pocetOkresu: 4,
        pocetORP: 15,
        pocetPOU: 26,
        pocetObci: 451,
        pocetMest: 38,
        pocetMestysu: 12,
        route: "pardubicky",
        url: "https://www.pardubickykraj.cz",
        urlHlasovaciData: "https://www.pardubickykraj.cz/jednani-zpk",
        urlDataset: "https://kraje.zastupko.cz/flask/pardubicky/zastupitelstvo/6/dataset",
        uplnyDataset: true,
        posledniAktualizace: "2024-08-29",
        CoA: "https://upload.wikimedia.org/wikipedia/commons/4/47/Pardubice_Region_CoA_CZ.svg",
        CoALicense: "https://commons.wikimedia.org/wiki/File:Pardubice_Region_CoA_CZ.svg",
        wiki: "https://cs.wikipedia.org/wiki/Pardubický_kraj",
        transparentnost: "PDF",
        dostupnost: true,
        pocetZastupitelu: generatedData.E.pocetZastupitelu,
        celkovyPocetZastupitelu: generatedData.E.celkovyPocetZastupitelu,
        pocetZen: generatedData.E.pocetZen,
        pocetMuzu: generatedData.E.pocetMuzu,
        pocetSubjektu: generatedData.E.pocetSubjektu,
        pocetZasedani: generatedData.E.pocetZasedani,
        pocetHlasovani: generatedData.E.pocetHlasovani,
        pocetHlasovaniPrijata: generatedData.E.pocetHlasovaniPrijata,
        pocetHlasovaniNeprijata: generatedData.E.pocetHlasovaniNeprijata,
        lidrJmeno: generatedData.E.lidrJmeno,
        lidrPrijmeni: generatedData.E.lidrPrijmeni,
        lidrPohlavi: generatedData.E.lidrPohlavi,
        lidrSubjekt: generatedData.E.lidrSubjekt,
        prumernaHrubaMzda: 38438,
        prumernaHrubaMzda23: 36711,
        prumernaHrubaMzda22: 34091,
        prumernaHrubaMzda21: 33076,
        prumernaHrubaMzda20: 31240,
        nezamestnanost: 1.9,
        nezamestnanost22: 1.8,
        nezamestnanost21: 2.3,
        nezamestnanost20: 1.6,
        prijem: 21018448,
        vydaje: 20187050,
        prijem22: 18687507,
        vydaje22: 18695229,
        prijem21: 16960372,
        vydaje21: 16777200,
        prijem20: 15053479,
        vydaje20: 16114137,
        trestneCiny23: 4985,
        trestneCiny22: 5230,
        trestneCiny21: 4420,
        trestneCiny20: 4778,
        trestneCinyDetail23: [3586,	339,	35,	54,	33,	5],
        trestneCinyDetail22: [3797,	339,	34,	84,	33,	10],
        trestneCinyDetail21: [3066,	390,	24,	56,	25,	1],
        trestneCinyDetail20: [3113,	583,	23,	58,	34,	5],
        unemploymentSource: "https://vdb.czso.cz/vdbvo2/faces/index.jsf?page=vystup-objekt&z=T&f=TABULKA&katalog=30853&pvo=ZAM06&str=v95",
        csuSource: "https://csu.gov.cz/pak/domov?pocet=10&start=0&razeni=-datumVydani",
        csuPeople: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=DEMZU01a&z=T&f=TABULKA&skupId=3809&katalog=30845&pvo=DEMZU01a&evo=v450_!_IK-DEM-R-2030-2000-sestup_1&u=v81__VUZEMI__100__3093",
        csuSalary: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=MZD01-C&z=T&f=TABULKA&skupId=855&katalog=30852&pvo=MZD01-C&evo=v208_!_MZD-R-ABS-od2011_1&u=v159__VUZEMI__100__3093",
        subjekty: generatedData.E.subjekty,
        subjektyWiki: {
            "ANO": {
                pocetZastupitelu: 11
            },
            "ODS": {
                pocetZastupitelu: 6
            },
            "Piráti": {
                pocetZastupitelu: 7
            },
            "KDU-ČSL": {
                pocetZastupitelu: 4
            },
            "STAN": {
                pocetZastupitelu: 4
            },
            "SOCDEM": {
                pocetZastupitelu: 5
            },
            "TOP 09": {
                pocetZastupitelu: 2
            },
            "SPD": {
                pocetZastupitelu: undefined
            },
            "Zelení": {
                pocetZastupitelu: undefined
            },
            "KSČM": {
                pocetZastupitelu: undefined
            },
            "SNK ED": {
                pocetZastupitelu: 2
            },
        },
    },
    P: {
        nazev: "Plzeňský kraj",
        popis: "Plzeňský kraj leží na západě České republiky a je známý svým pivem a historickými památkami.",
        sidlo: "Plzeň",
        rz: "P",
        rozloha: 7649,
        pocetObyvatel: 609175,
        pocetObyvatel23: 613374,
        pocetObyvatel22: 605388,
        pocetObyvatel21: 578707,
        pocetObyvatel20: 591041,
        hustotaZalidneni: 80,
        nejvyssiBodNazev: "Velká Mokrůvka",
        nejvyssiBodVyska: 1370,
        pocetOkresu: 7,
        pocetORP: 15,
        pocetPOU: 35,
        pocetObci: 501,
        pocetMest: 57,
        pocetMestysu: 12,
        route: "plzensky",
        url: "https://www.plzensky-kraj.cz",
        urlHlasovaciData: "https://opendata.plzensky-kraj.cz/zpk/",
        urlDataset: "https://kraje.zastupko.cz/flask/plzensky/zastupitelstvo/6/dataset",
        uplnyDataset: true,
        posledniAktualizace: "2024-08-29",
        CoA: "https://upload.wikimedia.org/wikipedia/commons/6/60/Plzen_Region_CoA_CZ.svg",
        CoALicense: "https://commons.wikimedia.org/wiki/File:Plzen_Region_CoA_CZ.svg",
        wiki: "https://cs.wikipedia.org/wiki/Plze%C5%88sk%C3%BD_kraj",
        transparentnost: "serialized",
        dostupnost: true,
        pocetZastupitelu: generatedData.P.pocetZastupitelu,
        celkovyPocetZastupitelu: generatedData.P.celkovyPocetZastupitelu,
        pocetZen: generatedData.P.pocetZen,
        pocetMuzu: generatedData.P.pocetMuzu,
        pocetSubjektu: generatedData.P.pocetSubjektu,
        pocetZasedani: generatedData.P.pocetZasedani,
        pocetHlasovani: generatedData.P.pocetHlasovani,
        pocetHlasovaniPrijata: generatedData.P.pocetHlasovaniPrijata,
        pocetHlasovaniNeprijata: generatedData.P.pocetHlasovaniNeprijata,
        lidrJmeno: generatedData.P.lidrJmeno,
        lidrPrijmeni: generatedData.P.lidrPrijmeni,
        lidrPohlavi: generatedData.P.lidrPohlavi,
        lidrSubjekt: generatedData.P.lidrSubjekt,
        prumernaHrubaMzda: 41273,
        prumernaHrubaMzda23: 39030,
        prumernaHrubaMzda22: 36272,
        prumernaHrubaMzda21: 35331,
        prumernaHrubaMzda20: 33528,
        nezamestnanost: 2.1,
        nezamestnanost22: 2.1,
        nezamestnanost21: 3.1,
        nezamestnanost20: 2.2,
        prijem: 25836354,
        vydaje: 23005689,
        prijem22: 20150156,
        vydaje22: 20383658,
        prijem21: 19844420,
        vydaje21: 19514438,
        prijem20: 17026766,
        vydaje20: 17209101,
        trestneCiny23: 9562,
        trestneCiny22: 10207,
        trestneCiny21: 8224,
        trestneCiny20: 8996,
        trestneCinyDetail23: [7426,	651,	79,	248,	43,	13],
        trestneCinyDetail22: [7889,	866,	69,	228,	34,	6],
        trestneCinyDetail21: [6038,	726,	66,	213,	37,	6],
        trestneCinyDetail20: [6672,	823,	70,	284,	41,	10],
        csuSource: "https://csu.gov.cz/plk/domov?pocet=10&start=0",
        csuPeople: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=DEMZU01a&z=T&f=TABULKA&skupId=3809&katalog=30845&pvo=DEMZU01a&evo=v450_!_IK-DEM-R-2030-2000-sestup_1&u=v81__VUZEMI__100__3042",
        csuSalary: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=MZD01-C&z=T&f=TABULKA&skupId=855&katalog=30852&pvo=MZD01-C&evo=v208_!_MZD-R-ABS-od2011_1&u=v159__VUZEMI__100__3042",
        subjekty: generatedData.P.subjekty,
        subjektyWiki: {
            "ANO": {
                pocetZastupitelu: 12
            },
            "ODS": {
                pocetZastupitelu: 9
            },
            "Piráti": {
                pocetZastupitelu: 6
            },
            "KDU-ČSL": {
                pocetZastupitelu: undefined
            },
            "STAN": {
                pocetZastupitelu: 4
            },
            "SOCDEM": {
                pocetZastupitelu: 1
            },
            "TOP 09": {
                pocetZastupitelu: 2
            },
            "SPD": {
                pocetZastupitelu: 1
            },
            "Zelení": {
                pocetZastupitelu: 1
            },
            "KSČM": {
                pocetZastupitelu: 1
            },
            "SNK ED": {
                pocetZastupitelu: undefined
            },
        },
    },
    S: {
        nazev: "Středočeský kraj",
        popis: "Středočeský kraj obklopuje hlavní město Prahu a je známý svým průmyslem a historickými památkami.",
        sidlo: "Praha",
        rz: "S",
        rozloha: 10929,
        pocetObyvatel: 1452966,
        pocetObyvatel23: 1455940,
        pocetObyvatel22: 1439391,
        pocetObyvatel21: 1386824,
        pocetObyvatel20: 1397997,
        hustotaZalidneni: 133,
        nejvyssiBodNazev: "Tok",
        nejvyssiBodVyska: 865,
        pocetOkresu: 12,
        pocetORP: 26,
        pocetPOU: 55,
        pocetObci: 1144,
        pocetMest: 86,
        pocetMestysu: 50,
        route: "stredocesky",
        url: "https://kr-stredocesky.cz",
        urlHlasovaciData: "https://kr-stredocesky.cz/web/urad/usneseni-zastupitelstva",
        urlDataset: "https://kraje.zastupko.cz/flask/stredocesky/zastupitelstvo/6/dataset",
        uplnyDataset: false,
        posledniAktualizace: "2024-08-29",
        CoA: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Central_Bohemian_Region_CoA_CZ.svg",
        CoALicense: "https://commons.wikimedia.org/wiki/File:Central_Bohemian_Region_CoA_CZ.svg",
        wiki: "https://cs.wikipedia.org/wiki/St%C5%99edo%C4%8Desk%C3%BD_kraj",
        transparentnost: "sums",
        dostupnost: false,
        pocetZastupitelu: generatedData.S.pocetZastupitelu,
        celkovyPocetZastupitelu: generatedData.S.celkovyPocetZastupitelu,
        pocetZen: generatedData.S.pocetZen,
        pocetMuzu: generatedData.S.pocetMuzu,
        pocetSubjektu: generatedData.S.pocetSubjektu,
        pocetZasedani: 35, //chybi zapisy uplne k 28/8/24 https://kr-stredocesky.cz/web/urad/usneseni-zastupitelstva, fixnuto 6/9/24
        pocetHlasovani: generatedData.S.pocetHlasovani,
        pocetHlasovaniPrijata: generatedData.S.pocetHlasovaniPrijata,
        pocetHlasovaniNeprijata: generatedData.S.pocetHlasovaniNeprijata,
        lidrJmeno: generatedData.S.lidrJmeno,
        lidrPrijmeni: generatedData.S.lidrPrijmeni,
        lidrPohlavi: generatedData.S.lidrPohlavi,
        lidrSubjekt: generatedData.S.lidrSubjekt,
        prumernaHrubaMzda: 43554,
        prumernaHrubaMzda23: 41904,
        prumernaHrubaMzda22: 38883,
        prumernaHrubaMzda21: 37123,
        prumernaHrubaMzda20: 35464,
        nezamestnanost: 1.7,
        nezamestnanost22: 1.2,
        nezamestnanost21: 2.5,
        nezamestnanost20: 1.9,
        prijem: 51128199,
        vydaje: 49786513,
        prijem22: 45633534,
        vydaje22: 44372443,
        prijem21: 42024311,
        vydaje21: 41570701,
        prijem20: 37226463,
        vydaje20: 38297731,
        trestneCiny23: 20594,
        trestneCiny22: 19775,
        trestneCiny21: 17625,
        trestneCiny20: 18091,
        trestneCinyDetail23: [16158,	1186,	105,	621,	97,	18],
        trestneCinyDetail22: [15703,	1049,	108,	873,	99,	19],
        trestneCinyDetail21: [13737,	1050,	110,	822,	90,	12],
        trestneCinyDetail20: [13468,	1510,	99,	754,	61,	18],
        csuSource: "https://csu.gov.cz/stc/domov?pocet=10&start=0&razeni=-datumVydani",
        csuPeople: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=DEMZU01a&z=T&f=TABULKA&skupId=3809&katalog=30845&pvo=DEMZU01a&evo=v450_!_IK-DEM-R-2030-2000-sestup_1&u=v81__VUZEMI__100__3026",
        csuSalary: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=MZD01-C&z=T&f=TABULKA&skupId=855&katalog=30852&pvo=MZD01-C&evo=v208_!_MZD-R-ABS-od2011_1&u=v159__VUZEMI__100__3026",
        subjekty: generatedData.S.subjekty,
        subjektyWiki: {
            "ANO": {
                pocetZastupitelu: 15
            },
            "ODS": {
                pocetZastupitelu: 16
            },
            "Piráti": {
                pocetZastupitelu: 12
            },
            "KDU-ČSL": {
                pocetZastupitelu: undefined
            },
            "STAN": {
                pocetZastupitelu: 18
            },
            "SOCDEM": {
                pocetZastupitelu: undefined
            },
            "TOP 09": {
                pocetZastupitelu: 3
            },
            "SPD": {
                pocetZastupitelu: undefined
            },
            "Zelení": {
                pocetZastupitelu: 1
            },
            "KSČM": {
                pocetZastupitelu: undefined
            },
            "SNK ED": {
                pocetZastupitelu: undefined
            },
        },
    },
    U: {
        nazev: "Ústecký kraj",
        popis: "Ústecký kraj leží na severozápadě České republiky a je známý svým průmyslem a přírodními krásami.",
        sidlo: "Ústí nad Labem",
        rz: "U",
        rozloha: 5339,
        pocetObyvatel: 808410,
        pocetObyvatel23: 811169,
        pocetObyvatel22: 812337,
        pocetObyvatel21: 798898,
        pocetObyvatel20: 817004,
        hustotaZalidneni: 152,
        nejvyssiBodNazev: "Klínovec",
        nejvyssiBodVyska: 1244,
        pocetOkresu: 7,
        pocetORP: 16,
        pocetPOU: 30,
        pocetObci: 354,
        pocetMest: 59,
        pocetMestysu: 11,
        route: "ustecky",
        url: "https://www.kr-ustecky.cz",
        urlHlasovaciData: "https://www.kr-ustecky.cz/zastupitelstvo/d-846945/p1=272786",
        urlDataset: "https://kraje.zastupko.cz/flask/ustecky/zastupitelstvo/6/dataset",
        uplnyDataset: false,
        posledniAktualizace: "2024-08-29",
        CoA: "https://upload.wikimedia.org/wikipedia/commons/9/92/Usti_nad_Labem_Region_CoA_CZ.svg",
        CoALicense: "https://commons.wikimedia.org/wiki/File:Usti_nad_Labem_Region_CoA_CZ.svg",
        wiki: "https://cs.wikipedia.org/wiki/%C3%9Asteck%C3%BD_kraj",
        transparentnost: "sums", //pdf sumy
        dostupnost: false,
        pocetZastupitelu: generatedData.U.pocetZastupitelu,
        celkovyPocetZastupitelu: generatedData.U.celkovyPocetZastupitelu,
        pocetZen: generatedData.U.pocetZen,
        pocetMuzu: generatedData.U.pocetMuzu,
        pocetSubjektu: generatedData.U.pocetSubjektu,
        pocetZasedani: 29,
        pocetHlasovani: generatedData.U.pocetHlasovani,
        pocetHlasovaniPrijata: generatedData.U.pocetHlasovaniPrijata,
        pocetHlasovaniNeprijata: generatedData.U.pocetHlasovaniNeprijata,
        lidrJmeno: generatedData.U.lidrJmeno,
        lidrPrijmeni: generatedData.U.lidrPrijmeni,
        lidrPohlavi: generatedData.U.lidrPohlavi,
        lidrSubjekt: generatedData.U.lidrSubjekt,
        prumernaHrubaMzda: 40737,
        prumernaHrubaMzda23: 38474,
        prumernaHrubaMzda22: 35491,
        prumernaHrubaMzda21: 34184,
        prumernaHrubaMzda20: 32556,
        nezamestnanost: 4.0,
        nezamestnanost22: 3.0,
        nezamestnanost21: 3.7,
        nezamestnanost20: 3.7,
        prijem: 29987349,
        vydaje: 29974562,
        prijem22: 26925234,
        vydaje22: 26856698,
        prijem21: 25196003,
        vydaje21: 24768248,
        prijem20: 22727737,
        vydaje20: 23663840,
        trestneCiny23: 16061,
        trestneCiny22: 16365,
        trestneCiny21: 14333,
        trestneCiny20: 14872,
        trestneCinyDetail23: [12430,	1166,	225,	302,	127,	13],
        trestneCinyDetail22: [12512,	1300,	192,	356,	93,	17],
        trestneCinyDetail21: [10562,	1113,	159,	295,	109,	9],
        trestneCinyDetail20: [10648,	1565,	172,	270,	81,	13],
        csuSource: "https://csu.gov.cz/ulk/domov?pocet=10&start=0&razeni=-datumVydani",
        csuPeople: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=DEMZU01a&z=T&f=TABULKA&skupId=3809&katalog=30845&pvo=DEMZU01a&evo=v450_!_IK-DEM-R-2030-2000-sestup_1&u=v81__VUZEMI__100__3069",
        csuSalary: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=MZD01-C&z=T&f=TABULKA&skupId=855&katalog=30852&pvo=MZD01-C&evo=v208_!_MZD-R-ABS-od2011_1&u=v159__VUZEMI__100__3069",
        subjekty: generatedData.U.subjekty,
        subjektyWiki: {
            "ANO": {
                pocetZastupitelu: 17
            },
            "ODS": {
                pocetZastupitelu: 8
            },
            "Piráti": {
                pocetZastupitelu: 6
            },
            "KDU-ČSL": {
                pocetZastupitelu: 1
            },
            "STAN": {
                pocetZastupitelu: 4
            },
            "SOCDEM": {
                pocetZastupitelu: 1
            },
            "TOP 09": {
                pocetZastupitelu: 2
            },
            "SPD": {
                pocetZastupitelu: 4
            },
            "Zelení": {
                pocetZastupitelu: undefined
            },
            "KSČM": {
                pocetZastupitelu: 4
            },
            "SNK ED": {
                pocetZastupitelu: undefined
            },
        },
    },
    J: {
        nazev: "Kraj Vysočina",
        popis: "Vysočina se nachází v centrální části České republiky a je známá svými krásnými přírodními scenériemi a historickými památkami.",
        sidlo: "Jihlava",
        rz: "J",
        rozloha: 6796,
        pocetObyvatel: 515894,
        pocetObyvatel23: 517960,
        pocetObyvatel22: 514777,
        pocetObyvatel21: 504025,
        pocetObyvatel20: 508852,
        hustotaZalidneni: 76,
        nejvyssiBodNazev: "Javořice",
        nejvyssiBodVyska: 837,
        pocetOkresu: 5,
        pocetORP: 15,
        pocetPOU: 26,
        pocetObci: 704,
        pocetMest: 34,
        pocetMestysu: 43,
        route: "vysocina",
        url: "https://www.kr-vysocina.cz",
        urlHlasovaciData: "https://samosprava.kr-vysocina.cz/zastupitelstvo/",
        urlDataset: "https://kraje.zastupko.cz/flask/vysocina/zastupitelstvo/6/dataset",
        uplnyDataset: true,
        posledniAktualizace: "2024-08-29",
        CoA: "https://upload.wikimedia.org/wikipedia/commons/7/7f/CZE_Kraj_Wysoczyzna_COA.svg",
        CoALicense: "https://commons.wikimedia.org/wiki/File:CZE_Kraj_Wysoczyzna_COA.svg",
        wiki: "https://cs.wikipedia.org/wiki/Kraj_Vyso%C4%8Dina",
        transparentnost: "HTML",
        dostupnost: true,
        pocetZastupitelu: generatedData.J.pocetZastupitelu,
        celkovyPocetZastupitelu: generatedData.J.celkovyPocetZastupitelu,
        pocetZen: generatedData.J.pocetZen,
        pocetMuzu: generatedData.J.pocetMuzu,
        pocetSubjektu: generatedData.J.pocetSubjektu,
        pocetZasedani: generatedData.J.pocetZasedani,
        pocetHlasovani: generatedData.J.pocetHlasovani,
        pocetHlasovaniPrijata: generatedData.J.pocetHlasovaniPrijata,
        pocetHlasovaniNeprijata: generatedData.J.pocetHlasovaniNeprijata,
        lidrJmeno: generatedData.J.lidrJmeno,
        lidrPrijmeni: generatedData.J.lidrPrijmeni,
        lidrPohlavi: generatedData.J.lidrPohlavi,
        lidrSubjekt: generatedData.J.lidrSubjekt,
        prumernaHrubaMzda: 39688,
        prumernaHrubaMzda23: 37746,
        prumernaHrubaMzda22: 34942,
        prumernaHrubaMzda21: 34037,
        prumernaHrubaMzda20: 31856,
        nezamestnanost: 1.5,
        nezamestnanost22: 1.6,
        nezamestnanost21: 2.1,
        nezamestnanost20: 2.2,
        prijem: 22447924,
        vydaje: 20584778,
        prijem22: 19766164,
        vydaje22: 20796696,
        prijem21: 18585605,
        vydaje21: 18057734,
        prijem20: 17071792,
        vydaje20: 16834889,
        trestneCiny23: 5605,
        trestneCiny22: 5240,
        trestneCiny21: 4374,
        trestneCiny20: 4835,
        trestneCinyDetail23: [4258,	434,	21,	64,	23,	5],
        trestneCinyDetail22: [3950,	409,	29,	91,	27,	5],
        trestneCinyDetail21: [3045,	425,	27,	87,	19,	6],
        trestneCinyDetail20: [3264,	624,	30,	101,	25,	5],
        csuSource: "https://csu.gov.cz/vys/domov?pocet=10&start=0&razeni=-datumVydani",
        csuPeople: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=DEMZU01a&z=T&f=TABULKA&skupId=3809&katalog=30845&pvo=DEMZU01a&evo=v450_!_IK-DEM-R-2030-2000-sestup_1&u=v81__VUZEMI__100__3107",
        csuSalary: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=MZD01-C&z=T&f=TABULKA&skupId=855&katalog=30852&pvo=MZD01-C&evo=v208_!_MZD-R-ABS-od2011_1&u=v159__VUZEMI__100__3107",
        subjekty: generatedData.J.subjekty,
        subjektyWiki: {
            "ANO": {
                pocetZastupitelu: 10
            },
            "ODS": {
                pocetZastupitelu: 5
            },
            "Piráti": {
                pocetZastupitelu: 7
            },
            "KDU-ČSL": {
                pocetZastupitelu: 6
            },
            "STAN": {
                pocetZastupitelu: 4
            },
            "SOCDEM": {
                pocetZastupitelu: 6
            },
            "TOP 09": {
                pocetZastupitelu: undefined
            },
            "SPD": {
                pocetZastupitelu: undefined
            },
            "Zelení": {
                pocetZastupitelu: undefined
            },
            "KSČM": {
                pocetZastupitelu: 3
            },
            "SNK ED": {
                pocetZastupitelu: 2
            },
        },
    },
    Z: {
        nazev: "Zlínský kraj",
        popis: "Zlínský kraj se nachází na východě České republiky a je známý svým průmyslem a kulturními památkami.",
        sidlo: "Zlín",
        rz: "Z",
        rozloha: 3963,
        pocetObyvatel: 578935,
        pocetObyvatel23: 580744,
        pocetObyvatel22: 580531,
        pocetObyvatel21: 572432,
        pocetObyvatel20: 580119,
        hustotaZalidneni: 147,
        nejvyssiBodNazev: "Čertův mlýn",
        nejvyssiBodVyska: 1206,
        pocetOkresu: 4,
        pocetORP: 13,
        pocetPOU: 25,
        pocetObci: 307,
        pocetMest: 30,
        pocetMestysu: 6,
        route: "zlinsky",
        url: "https://zlinskykraj.cz",
        urlHlasovaciData: "https://zlinskykraj.cz/zastupitelstvo",
        urlDataset: "https://kraje.zastupko.cz/flask/zlinsky/zastupitelstvo/6/dataset",
        uplnyDataset: false,
        posledniAktualizace: "2024-08-29",
        CoA: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Zlin_Region_CoA_CZ.svg",
        CoALicense: "https://commons.wikimedia.org/wiki/File:Zlin_Region_CoA_CZ.svg",
        wiki: "https://cs.wikipedia.org/wiki/Zl%C3%ADnsk%C3%BD_kraj",
        transparentnost: "video",
        dostupnost: false,
        pocetZastupitelu: generatedData.Z.pocetZastupitelu,
        celkovyPocetZastupitelu: generatedData.Z.celkovyPocetZastupitelu,
        pocetZen: generatedData.Z.pocetZen,
        pocetMuzu: generatedData.Z.pocetMuzu,
        pocetSubjektu: generatedData.Z.pocetSubjektu,
        pocetZasedani: 24,
        pocetHlasovani: generatedData.Z.pocetHlasovani,
        pocetHlasovaniPrijata: generatedData.Z.pocetHlasovaniPrijata,
        pocetHlasovaniNeprijata: generatedData.Z.pocetHlasovaniNeprijata,
        lidrJmeno: generatedData.Z.lidrJmeno,
        lidrPrijmeni: generatedData.Z.lidrPrijmeni,
        lidrPohlavi: generatedData.Z.lidrPohlavi,
        lidrSubjekt: generatedData.Z.lidrSubjekt,
        prumernaHrubaMzda: 38415,
        prumernaHrubaMzda23: 36903,
        prumernaHrubaMzda22: 34264,
        prumernaHrubaMzda21: 33014,
        prumernaHrubaMzda20: 30540,
        nezamestnanost: 2.3,
        nezamestnanost22: 2.0,
        nezamestnanost21: 1.8,
        nezamestnanost20: 1.9,
        prijem: 21173317,
        vydaje: 19892559,
        prijem22: 19609119,
        vydaje22: 18313625,
        prijem21: 17035119,
        vydaje21: 16832058,
        prijem20: 15501365,
        vydaje20: 14965723,
        trestneCiny23: 6302,
        trestneCiny22: 5980,
        trestneCiny21: 4818,
        trestneCiny20: 5452,
        trestneCinyDetail23: [4618,	491,	43,	73,	35,	9],
        trestneCinyDetail22: [4211,	556,	42,	76,	28,	7],
        trestneCinyDetail21: [3099,	519,	34,	56,	26,	4],
        trestneCinyDetail20: [3425,	682,	36,	74,	16,	4],
        csuSource: "https://csu.gov.cz/zlk/domov?pocet=10&start=0&razeni=-datumVydani",
        csuPeople: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=DEMZU01a&z=T&f=TABULKA&skupId=3809&katalog=30845&pvo=DEMZU01a&evo=v450_!_IK-DEM-R-2030-2000-sestup_1&u=v81__VUZEMI__100__3131",
        csuSalary: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=MZD01-C&z=T&f=TABULKA&skupId=855&katalog=30852&pvo=MZD01-C&evo=v208_!_MZD-R-ABS-od2011_1&u=v159__VUZEMI__100__3131",
        subjekty: generatedData.Z.subjekty,
        subjektyWiki: {
            "ANO": {
                pocetZastupitelu: 9
            },
            "ODS": {
                pocetZastupitelu: 5
            },
            "Piráti": {
                pocetZastupitelu: 6
            },
            "KDU-ČSL": {
                pocetZastupitelu: 9
            },
            "STAN": {
                pocetZastupitelu: 6
            },
            "SOCDEM": {
                pocetZastupitelu: 4
            },
            "TOP 09": {
                pocetZastupitelu: undefined
            },
            "SPD": {
                pocetZastupitelu: 3
            },
            "Zelení": {
                pocetZastupitelu: undefined
            },
            "KSČM": {
                pocetZastupitelu: undefined
            },
            "SNK ED": {
                pocetZastupitelu: undefined
            },
        },
    }
}
export default data;
