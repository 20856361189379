import React from 'react';
import { Column, DataTable } from '../../../../components/PrimereactComponents';

export const MemberOrderTable = ({ members, loading, disabled, onRowReorder }) => {
    const sexTemplate = (rowData) => {
        const icon = rowData.sex ? 'bi bi-gender-female' : 'bi bi-gender-male';
        const title = rowData ? 'Žena' : 'Muž';
        return <i className={icon} title={title} />;
    };

    return (
        <DataTable
            value={members}
            loading={loading}
            reorderableRows
            onRowReorder={onRowReorder}
            size='small'
            breakpoint='500px'
            emptyMessage='Municipalita nemá definovány žádné členy'
        >
            <Column rowReorder={!disabled} style={{ width: '2em' }} />
            <Column field='id' header='ID' />
            <Column field='order' header='Pořadí' />
            <Column field='first_name' header='Jméno' />
            <Column field='last_name' header='Příjmení' />
            <Column field='sex' header='Pohlaví' body={sexTemplate} align='center' />
        </DataTable>
    );
};
