import React from "react";

import RegionalMap from "./RegionalMap";
import RegionalMapLegend from "./RegionalMapLegend";

import "./RegionalChoropleth.css"

/**
 * @author Jiri Hynek
 * 
 * A component which represents a regional choropleth map.
 * 
 * It wraps the RegionalMap and RegionalMapLegend components.
 */
function RegionalChoropleth({
    id = undefined,
    geo = {},
    data = {}, // the structure of the region values { regionId: value }
    categories = {},
    getValue = (data, region) => data[region],
    formatValue = (value, categories, region) => value,
    map = {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
    config = {
        filteredColor: "#f0f0f0"
    }
}) {

    let [selectedCategory, setSelectedCategory] = React.useState(undefined);

    let [disabledCategories, setDisabledCategories] = React.useState([]);

    /**
     * 
     * @param {*} category 
     */
    const isDisabledCategory = (category) => {
        for(let i = 0; i < disabledCategories.length; i++) {
            if(categories[disabledCategories[i]] === category) {
                return true;
            }
        };

        return false;
    }

    /**
     * Helper function to process the values and set the color of the regions
     * 
     * @returns 
     */
    const processRegions = () => {
        // initialize map.regions if not initialized
        if(map.regions === undefined) {
            map.regions = {};
        }

        // iterate over the regions and for every region get the region and category
        for (let region in data) {
            // get the value of the region
            let value = data[region] ? getValue(data, region) : undefined;

            // initialize map region if not initialized
            if(map.regions[region] === undefined) {
                map.regions[region] = {
                    polygon: {
                        value: value,
                        formatValue: (value) => {
                            const result = formatValue ? formatValue(value, categories, data, region) : value;
                            return result;
                        },
                        style: {}
                    }
                }
            }

            // if selected category is set, make every region de-emphasize by default
            if(selectedCategory) {
                map.regions[region].polygon.style.fill = config.filteredColor;
            }

            //if(value !== undefined) {
                // first, try to find the category for the value using the category equals function
                let category = Object.values(categories).find(category => category.equals && category.equals(value));
                if(category === undefined) {
                    // if there category is not found, find the category for the value using name of the category
                    category = categories[value];
                }

                const categoryIsDisabled = isDisabledCategory(category);

                // set the color of the region
                if(category !== undefined && (!selectedCategory || categories[selectedCategory] === category)
                    && !categoryIsDisabled) {
                    
                    // set the color of the region
                    map.regions[region].polygon.style = {
                        ...map.regions[region].polygon.style,
                        fill: category.color,
                    }
                }

                if(categoryIsDisabled) {
                    map.regions[region].polygon.style.fill = config.filteredColor;
                }
            //}
        }

        // set default hover formatValue function if not set
        if(map.defaults === undefined) {
            map.defaults = {};
        }
        if(map.defaults.polygon === undefined) {
            map.defaults.polygon = {};
        }

        if(map.defaults.polygon.formatValue === undefined) {
            map.defaults.polygon.formatValue = (value) => {
                const result = formatValue ? formatValue(value, categories) : value;
                return result;
            };
        }
    }

    // process the regions
    processRegions();

    return (
        <>
            <div className="regional-choropleth">
                <RegionalMap id={id} geo={geo} regions={map.regions} defaults={map.defaults}/>
                <RegionalMapLegend
                    id={id}
                    categories={categories}
                    onMouseEnter={(categoryId) => setSelectedCategory(categoryId)}
                    onMouseLeave={() => setSelectedCategory(undefined)}
                    onClick={(categories) => setDisabledCategories(categories)}
                    filteredColor={config.filteredColor}>
                </RegionalMapLegend>
            </div>
        </>
    )
}

export default RegionalChoropleth;