import React from "react";

import { Dialog } from "primereact/dialog";

import RegionalChoropleth from "../map/RegionalChoropleth.js";
import RegionalFilteredChoropleth from "../map/RegionalFilteredChoropleth.js";
import Widget from "./Widget.js";
import LinksFooter from "./LinksFooter.js";

import "./StatsSection.css";
import RegionalMap from "../map/RegionalMap.js";

function StatsSection({
    title= "",
    content = undefined,
    chart = undefined,
    widget = undefined,
    footer = undefined,
}) {

    const [ popup, setPopup ] = React.useState({
        visible: false,
        header: undefined,
        body: undefined,
        footer: undefined
    })

    const openPopup = () => {

        const config = footer.popup.component;

        if(config &&config.header && config.header.component &&
            config.body && config.body.component && 
            config.footer && config.footer.component) {

            setPopup({
                visible: true,
                header: React.createElement(config.header.component, { props: config.header.props}),
                body: React.createElement(config.body.component, { props: config.body.props}),
                footer: React.createElement(config.footer.component, { props: config.footer.props}),
            });
        }
    }

    return (
        <>
            <div className="stats-section">
                <h3>{title}</h3>
                
                {
                    content && <p className="stats-section-par">{content()}</p>
                }
                {
                    chart && chart.type === "regional-choropleth"
                    && chart.config
                    && <div className="stats-map">
                            <RegionalChoropleth
                                id={chart.id}
                                geo={chart.config.geo}
                                data={chart.config.data}
                                categories={chart.config.categories}
                                getValue={chart.config.getValue}
                                formatValue={chart.config.formatValue}
                                map={chart.config.map}/>
                        </div>

                }
                {
                    chart && chart.type === "regional-filtered-choropleth"
                    && chart.config
                    && <div className="stats-map">
                            <RegionalFilteredChoropleth
                                id={chart.id}
                                geo={chart.config.geo}
                                datasets={chart.config.datasets}
                                map={chart.config.map}/>
                        </div>

                }
                {
                    chart && chart.type === "regional-map"
                    && chart.config
                    && <div className="stats-map">
                            <RegionalMap
                                id={chart.id}
                                geo={chart.config.geo}
                                defaults={chart.config.defaults}
                                regions={chart.config.regions}/>
                        </div>

                }
                {
                    widget &&
                    <div className="stats-map">
                        <Widget
                            type={widget.type}
                            config={widget.config}/>
                    </div>
                }
                {
                    footer &&
                        <div className="stats-section-footer">
                            {
                                footer.popup &&
                                <>
                                    <LinksFooter
                                        left={footer.left}
                                        right={{
                                            title: footer.right.title,
                                            links: [
                                                ...footer.right.links,
                                                {
                                                    label: footer.popup.label ?? "Více podrobností",
                                                    icon: footer.popup.label ?? "pi-fw pi-chart-bar",
                                                    onClick: () => openPopup(),
                                                }
                                            ]
                                        }}>
                                    </LinksFooter>
                                    {
                                        <Dialog
                                            header={popup.header} footer={popup.footer} visible={popup.visible} draggable={false} position="bottom"
                                            breakpoints={{ '576px': '100vw' }}
                                            onHide={() => {if (!popup.visible) return; setPopup({
                                                visible: false,
                                                header: undefined,
                                                body: undefined,
                                                footer: undefined,
                                                props: undefined
                                            }); }}
                                            style={footer.popup.component.style}>
                                            {popup.body}
                                        </Dialog>
                                    }
                                </>
                            }
                            {                                
                                !footer.popup &&
                                    <LinksFooter
                                        left={footer.left}
                                        right={footer.right}>
                                    </LinksFooter>
                            }
                        </div>
                }
            </div>
        </>
    )
}

export default StatsSection;