import React from 'react';
import { Chip } from '../../components/PrimereactComponents';
import { classNames } from 'primereact/utils';

const ChipSeverity = {
    PRIMARY: 'primary',
    SUCCESS: 'success',
    DANGER: 'danger',
    WARNING: 'warning'
};

/**
 * @param {import('primereact/chip').ChipProps & {severity?: 'primary'|'success'|'danger'|'warning', outlined?: boolean, hoverable?: boolean}} props
 */
export const BaseChip = ({ severity, outlined, hoverable, ...chipProps }) => {
    const baseClass = chipProps.className || '';
    return (
        <Chip
            {...chipProps}
            className={classNames(baseClass, {
                'p-chip-primary': severity === ChipSeverity.PRIMARY,
                'p-chip-success': severity === ChipSeverity.SUCCESS,
                'p-chip-danger': severity === ChipSeverity.DANGER,
                'p-chip-warning': severity === ChipSeverity.WARNING,
                'p-chip-outlined': outlined,
                'p-chip-hover': hoverable
            })}
        />
    );
};
