import PopupBodyChart from "../../../../components/map/popup/PopupBodyChart";
import PopupFooter from "../../../../components/map/popup/PopupFooter";
import PopupHeader from "../../../../components/map/popup/PopupHeader";
import APP from "../../../config";
import data from "../data";
import geo from "../geo";
import meta from "../meta";
/**
 * Share of votings statistics.
 * 
 * @author Adam Janosik, Kristyna Zaklova
 */


/**
 * approved votings statistics
 */
const approvedVotings = {
    label: "- z toho přijatých",
    data: data,
    categories: meta.pocetHlasovaniPrijata.values,
    getValue: meta.pocetHlasovaniPrijata.getValue,
    formatValue: meta.pocetHlasovaniPrijata.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle počtu přijatých hlasování za volební období 2020-2024 (s výjimkou Prahy)",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Za uplynulé volební období se počet přijatých hlasování zastupitelstev v jednotlivých krajích pohyboval od <span className="emp"><strong>475</strong></span> do <span className="emp"><strong>1712</strong></span>. Nejvíce přijatých hlasování bylo v <span className="emp"><strong>Moravskoslezském kraji</strong></span> a&nbsp;naopak nejméně v&nbsp;<span className="emp"><strong>Pardubickém kraji</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.pocetHlasovaniPrijata.values,
                                            getValue: meta.pocetHlasovaniPrijata.getValue,
                                            formatValue: meta.pocetHlasovaniPrijata.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroj:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "Datové sady",
                                            value: `${APP.protocol}://kraje.${APP.domain}${APP.port && ':' + APP.port}/datasety`
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

/**
 * number of declined votings
 */
const declinedVotings = {
    label: "- z toho nepřijatých",
    data: data,
    categories: meta.pocetHlasovaniNeprijata.values,
    getValue: meta.pocetHlasovaniNeprijata.getValue,
    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle počtu nepřijatých hlasování za volební období 2020-2024 (s výjimkou Prahy)",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Za uplynulé volební období se počet nepřijatých hlasování zastupitelstev v jednotlivých krajích pohyboval od <span className="emp"><strong>8</strong></span> do <span className="emp"><strong>79</strong></span>. Nejvíce nepřijatých hlasování bylo v <span className="emp"><strong>Plzeňském kraji</strong></span> a&nbsp;naopak nejméně v&nbsp;<span className="emp"><strong>Karlovarském kraji</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.pocetHlasovaniNeprijata.values,
                                            getValue: meta.pocetHlasovaniNeprijata.getValue,
                                            formatValue: meta.pocetHlasovaniNeprijata.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroj:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "Datové sady",
                                            value: `${APP.protocol}://kraje.${APP.domain}${APP.port && ':' + APP.port}/datasety`
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}


/**
 * total approved votings
 */
const totalVotings = {
    label: "Počet všech hlasování",
    data: data,
    categories: meta.pocetHlasovani.values,
    getValue: meta.pocetHlasovani.getValue,
    formatValue: meta.pocetHlasovani.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle počtu všech hlasování za volební období 2020-2024 (s výjimkou Prahy)",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Za uplynulé volební období se počet hlasování zastupitelstev v jednotlivých krajích pohyboval od <span className="emp"><strong>497</strong></span> do <span className="emp"><strong>1764</strong></span>. Nejvíce hlasování proběhlo v <span className="emp"><strong>Moravskoslezském kraji</strong></span> a&nbsp;naopak nejméně v&nbsp;<span className="emp"><strong>Pardubickém kraji</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "stacked-bar",
                                        config: {
                                            geo: geo,
                                            datasets: [
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniPrijata.values,
                                                    getValue: meta.pocetHlasovaniPrijata.getValue,
                                                    formatValue: meta.pocetHlasovaniPrijata.formatValue,
                                                    backgroundColor: "#bdf5bd",
                                                    label: meta.pocetHlasovaniPrijata.label
                                                },
                                                {
                                                    data: data,
                                                    categories: meta.pocetHlasovaniNeprijata.values,
                                                    getValue: meta.pocetHlasovaniNeprijata.getValue,
                                                    formatValue: meta.pocetHlasovaniNeprijata.formatValue,
                                                    backgroundColor: "#f5bdc2",
                                                    label: meta.pocetHlasovaniNeprijata.label
                                                }
                                            ],
                                            formatValue: meta.pocetHlasovaniPrijata.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroj:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "Datové sady",
                                            value: `${APP.protocol}://kraje.${APP.domain}${APP.port && ':' + APP.port}/datasety`
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}



const votingsStats = {
    title: 'Hlasování zastupitelstev',
    content: () => {
        return (
            <>
                Na každém zasedání se často koná několik hlasování, v&nbsp;závislosti na počtu projednávaných bodů. 
                <span className="emp"> Počet hlasování</span> za celé funkční období se pohybuje v&nbsp;řádu <span className="emp"><strong>stovek</strong></span>, přičemž nejvíce se hlasovalo v&nbsp;<span className="emp">kraji Moravskoslezském</span>.
                Drtivá většina hlasování bývá přijata, konkrétní počty přijatých a&nbsp;nepřijatých hlasování lze vidět po nastavení filtru nebo po kliknutí do mapy.
            </>
        )
    },
    chart: {
        id: "mapa-pocet-hlasovani",
        type: "regional-filtered-choropleth",
        config: {
            label: "Celkový počet hlasování",
            geo: geo,
            datasets: [
                totalVotings, approvedVotings, declinedVotings
            ]

        }
    },
    footer: {
        left: {
            title: {
                label: "Zdroj:",
                icon: "pi pi-fw pi-globe"
            },
            links: [
                {
                    label: "Datové sady",
                    value: `${APP.protocol}://kraje.${APP.domain}${APP.port && ':' + APP.port}/datasety`
                }
            ]
        },
        right: {
            title: undefined,
            links: [
                meta.route
            ]
        },
        popup: {
            component: totalVotings.map.defaults.polygon.popup
        }
    }
}
export default votingsStats;


