import PopupBodyChart from "../../../../components/map/popup/PopupBodyChart";
import PopupFooter from "../../../../components/map/popup/PopupFooter";
import PopupHeader from "../../../../components/map/popup/PopupHeader";
import APP from "../../../config";
import config from "../../../config/zastupko/config";
import data from "../data";
import geo from "../geo";
import meta from "../meta";

/**
 * Share of women statistics.
 * 
 * @author Jiri Hynek
 */

const genericPopup = {
    header: {
        component: PopupHeader,
        props: {
            data: data
        }
    },
    body: {
        component: PopupBodyChart,
        props: {
            data: data,
            stats: [
                {
                    title: "Srovnání dle podílu žen v jednotlivých krajích v období 2020-2024 (s výjimkou Prahy)",
                    content: () => {
                        return (
                            <>
                                <p>Podíl žen v jednotlivých krajích se pohybuje od <span className="emp"><strong>15%</strong></span> do <span className="emp"><strong>31%</strong></span>. Největší podíl žen je v <span className="emp"><strong>Hlavním městě Praha</strong></span> a nejmenší v <span className="emp"><strong>Jihomoravském kraji</strong></span>.</p>
                            </>
                        )
                    },
                    chart: {
                        type: "bar",
                        config: {
                            geo: geo,
                            data: data,
                            categories: meta.podilZen.values,
                            getValue: meta.podilZen.getValue,
                            formatValue: meta.podilZen.formatValue
                        }
                    }
                }
            ]
        }
    },
    footer: {
        component: PopupFooter,
        props: {
            data: data,
            settings: {
                left: {
                    title: {
                        label: "Zdroj:",
                        icon: "pi pi-fw pi-globe"
                    },
                    links: [
                        {
                            label: "Datové sady",
                            value: `${APP.protocol}://kraje.${APP.domain}${APP.port && ':' + APP.port}/datasety`
                        }
                    ]
                },
                right: {
                    title: undefined,
                    links: [
                        meta.route
                    ]
                }
            }
        }
    },
    style: {
        width: '80vw',
        height: '100vh',
        maxWidth: '1200px',
    }
}

const womenStats = {
    title: 'Zastoupení žen',
    widget: {
        type: "column-group",
        config: {
            widgets: [
                {
                    type: "custom",
                    config: {
                        content: (
                            <p>
                                Právě ve <span className="emp"><strong>2</strong>&nbsp;krajích</span> byl zaznamenán podíl žen v&nbsp;zastupitelstvech překračující <span className="emp"><strong>30&nbsp;%</strong></span>. 
                                Tento pozitivní vývoj naznačuje, že v&nbsp;těchto regionech existuje podpora pro genderovou rovnost a&nbsp;inkluzi v&nbsp;politických rozhodovacích procesech. 
                                Vyšší zastoupení žen přináší rozmanité perspektivy, které mohou obohatit politickou debatu a&nbsp;přispět k&nbsp;vytváření politik, jež lépe odpovídají potřebám celé populace.
                            </p>
                        )
                    }
                },
                {
                    type: "regional-choropleth",
                    config: {
                        id: "mapa-podil-zen",
                        geo: geo,
                        data: data,
                        categories: meta.podilZen.values,
                        getValue: meta.podilZen.getValue,
                        formatValue: meta.podilZen.formatValue,
                        map: {
                            defaults: {
                                polygon: {
                                    hoverStyle: {
                                        fill: "yellow"
                                    },
                                    popup: {
                                        header: {
                                            component: PopupHeader,
                                            props: {
                                                data: data
                                            }
                                        },
                                        body: {
                                            component: PopupBodyChart,
                                            props: {
                                                data: data,
                                                stats: [
                                                    {
                                                        title: "Srovnání dle podílu žen v jednotlivých krajích v období 2020-2024 (s výjimkou Prahy)",
                                                        content: () => {
                                                            return (
                                                                <>
                                                                    <p>Podíl žen v jednotlivých krajích se pohybuje od <span className="emp"><strong>15%</strong></span> do <span className="emp"><strong>31%</strong></span>. Největší podíl žen je v <span className="emp"><strong>Hlavním městě Praha</strong></span> a nejmenší v <span className="emp"><strong>Jihomoravském kraji</strong></span>.</p>
                                                                </>
                                                            )
                                                        },
                                                        chart: {
                                                            type: "bar",
                                                            config: {
                                                                geo: geo,
                                                                data: data,
                                                                categories: meta.podilZen.values,
                                                                getValue: meta.podilZen.getValue,
                                                                formatValue: meta.podilZen.formatValue
                                                            }
                                                        }
                                                    }
                                                ]
                                            }
                                        },
                                        footer: {
                                            component: PopupFooter,
                                            props: {
                                                data: data,
                                                settings: {
                                                    left: {
                                                        title: {
                                                            label: "Zdroje:",
                                                            icon: "pi pi-fw pi-globe"
                                                        },
                                                        links: [
                                                            {
                                                                ...meta.url,
                                                                label: "Web kraje",
                                                            },
                                                            {
                                                                ...meta.urlHlasovaciData,
                                                                label: "Hlasovací data kraje",
                                                            }
                                                        ]
                                                    },
                                                    right: {
                                                        title: undefined,
                                                        links: [
                                                            meta.route
                                                        ]
                                                    }
                                                }
                                            }
                                        },
                                        style: {
                                            width: '80vw',
                                            height: '100vh',
                                            maxWidth: '1200px',
                                        }
                                    }
                                },
                                capital: {
                                    style: {
                                        display: "none"
                                    }
                                }
                            }
                        },
                    }
                }
            ]
        }
    },
    footer: {
        left: {
            title: {
                label: "Zdroj:",
                icon: "pi pi-fw pi-globe"
            },
            links: [
                {
                    label: "Datové sady",
                    value: `${APP.protocol}://kraje.${APP.domain}${APP.port && ':' + APP.port}/datasety`
                }
            ]
        },
        right: {
            title: undefined,
            links: [
                meta.route
            ]
        },
        popup: {
            component: genericPopup
        }
    }
}
export default womenStats;