import React from 'react';
import { Card } from '../PrimereactComponents';
import APP from '../../config/config';

import "./MunicipalityCard.css"

const getApp = () => {
    return APP;
}

const getLocalLogo = (municipality) => {
    return `https://${municipality.deploy.local.subdomain}.${getApp().apiDomain}/${municipality.logo}`;
}

const getLogo = (municipality) => {
    if(municipality.logo) {
        return municipality.deploy.local
            ? getLocalLogo(municipality)
            : `${municipality.deploy.external.url}/${municipality.logo}`;
    } else {
        return "loga/zastupko-simple.svg";
    }
}

const getPortSuffix = () => {
    return getApp().port ? `:${getApp().port}` : ''
}

const getLocalUrl = (municipality) => {
    if(municipality.deploy.local.subdomain === getApp().subdomain) {
        // same subdomain
        return municipality.deploy.local.path;
    } else {
        // different subdomain
        return `${getApp().protocol}://${municipality.deploy.local.subdomain}.${getApp().domain}${getPortSuffix()}/${municipality.deploy.local.path}`;
    }
}

const getUrl = (municipality) => {
    return municipality.deploy.local
            ? getLocalUrl(municipality)
            : municipality.url
}

function MunicipalityCard({ municipality, enableLabel }) {
    console.log(municipality);
    return (
        <a
            key={municipality.name}
            className='municipality-card-wrapper'
            href={ getUrl(municipality) }
            target={ municipality.external ? '_blank' : undefined }>
            <Card className={"municipality-card hoverable-card" + (municipality.uplnyDataset === false ? " municipality-card-incomplete" : "")}>
                <div className='municipality-card-content'>
                    <img 
                        className='municipality-card-logo'
                        src={getLogo(municipality)} alt='logo'
                        style={!municipality.logo ? { padding: "1rem" } : {}}/>
                </div>
                {
                    enableLabel || !municipality.logo &&
                    <h2 className='municipality-card-text' style={{ color: municipality.barva }}>
                        {municipality.nazev}
                    </h2>
                }
            </Card>
        </a>
    );
}

export default MunicipalityCard;
