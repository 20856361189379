export const getBodyNameGenitive = (body) => {
    if (body === 'zastupitelstvo') return 'zastupitelstva';
    if (body === 'rada') return 'rady';

    return undefined;
};

export const formatMemberName = (firstName, lastName) => {
    return `${firstName ?? ''} ${lastName ?? ''}`.trim();
};

export const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};
