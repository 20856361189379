import React from "react";

import { Toast } from 'primereact/toast';

import Heading from "./Heading";
import Widget from "./Widget";

import "./Section.css"

function Section({
    id = undefined,
    heading = undefined,
    content = undefined,
    style = {},
    noAnchor = false
}) {

    const toast = React.useRef(null);

    return (
        <>
            <section className='section' id={id} style={style}>
                {
                    !noAnchor && <i className="pi pi-link section-bookmark" onClick={() => {
                        navigator.clipboard.writeText(document.location.href.match(/(^[^#]*)/)[0] + "#" + id).then(() => {
                            toast.current.show({severity:'success', summary: 'Uloženo', detail:'Odkaz zkopírován do paměti', life: 3000});
                        }).catch(() => {
                            toast.current.show({severity:'error', summary: 'Chyba', detail:'Odkaz nebylo možné zkopírovat do paměti', life: 3000});
                        });
                    }}/>
                }
                {
                    // section heading
                    heading &&
                    <Heading
                        level={heading.level}
                        title={heading.label}
                        arrowAnchor={`${id}-in`}
                        style={heading.style}>
                    </Heading>
                }
                {
                    // section content
                    content &&
                    <div className={`section-content`} id={`${id}-in`}>
                        <Widget
                            type={content.type}
                            config={content.config}>
                        </Widget>
                    </div>
                }
            </section>
            <Toast ref={toast} position="center" />
        </>
    )
}

export default Section;
