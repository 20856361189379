import { Column } from 'primereact/column';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonDelete, ButtonEdit } from '../../../components/Buttons';
import { PaginatedDataTable } from '../../../components/PaginatedDataTable';

export const VoteTable = ({ votes, loading, onClickDelete, disabled }) => {
    const { municipalityId, termId, meetingId } = useParams();

    const navigate = useNavigate();

    const buttonsTemplate = (rowData) => {
        return (
            <div className='table-buttons'>
                <ButtonEdit
                    rounded
                    title='Editovat hlasování'
                    disabled={disabled}
                    onClick={() =>
                        navigate(
                            `/admin/municipality/${municipalityId}/funkcni-obdobi/${termId}/zasedani/${meetingId}/hlasovani/${rowData.id}`
                        )
                    }
                />
                <ButtonDelete
                    rounded
                    title='Smazat hlasování'
                    disabled={disabled}
                    onClick={() => onClickDelete(rowData)}
                />
            </div>
        );
    };

    return (
        <PaginatedDataTable
            value={votes}
            loading={loading}
            removableSort
            breakpoint='500px'
            emptyMessage='Zasedání neobsahuje žádná hlasování'
        >
            <Column field='number' header='Číslo' sortable />
            <Column field='time' header='Čas' sortable />
            <Column field='item' header='Předmět' sortable />
            <Column body={buttonsTemplate} align='right' />
        </PaginatedDataTable>
    );
};
