import { dev } from "../../municipality/dev";
import { partneriList } from "../../municipality/partneri";

export const partnersDescriptionWidget = {
    type: "custom",
    config: {
        style: {
            textAlign: "justify",
            maxWidth: "1200px"
        },
        content: (
            <>
                <p>
                    Informační systém vznikl jako diplomová práce na Vysokém učení technickém v Brně ve spolupráci s městem Brnem a týmem provozující datový portál města Brna <span style={{ fontSize: "larger" }}><a href="https://data.brno.cz" target="_blank" rel="noreferrer">data.brno.cz</a></span>,
                    který se snaží zavádět nové trendy v oblasti zpracování a vizualizace otevřených dat v ČR.
                    Dalším partnerem projektu je město Most, které na svém datovém portálu <span style={{ fontSize: "larger" }}><a href="https://opendata.mesto-most.cz" target="_blank" rel="noreferrer">opendata.mesto-most.cz</a></span> podobně jako Brno uveřejňuje datové sady, včetně jejich vizualizací. 
                    Podobné aktivity měst jsou velmi dobrým krokem pro porozumění interních procesů ve městech.
                    Díky datům a jejich správnému chápání je možné výrazně přispět zlepšení fungování měst v různých oblastech (doprava, životní prostředí, dostupnost služeb, komunikace s obyvateli atd.).
                </p>
            </>
        )
    }
}

export const partneriListWidget = {
    type: "municipality-card-grid",
    config: {
        items: partneriList
    }
}

export const joinUsWidget = {
    type: "custom",
    config: {
        style: {
            textAlign: "justify",
            maxWidth: "1200px"
        },
        content: (
            <>
                <p>
                    Pokud Vaše město nebo kraj sdílí naši vizi transparentnější státní správy a má zájem se zapojit do našeho projektu, rádi s vámi navážeme spolupráci. 
                    Společně můžeme analyzovat a zpracovávat data z jednání krajských zastupitelstev, čímž přispějeme k větší otevřenosti a informovanosti veřejnosti. 
                    Naše výzkumná skupina nabízí odborné zázemí a podporu pro města a kraje, které chtějí aktivně přispět ke zlepšení dostupnosti a kvality veřejných informací.
                    Jsme schopni implementovat nástroje pro transformaci poskytnutých zdrojových dat a nově testujeme i administrační rozhraní pro správu datových sad (obrázek níže).
                    Pokud máte zájem o spolupráci, neváhejte nás <span style={{ fontSize: "larger"}}><a href="mailto:zastupko@zastupko.cz">kontaktovat</a></span>.
                </p>
            </>
        )
    }
}

export const developmentDescriptionWidget = {
    type: "custom",
    config: {
        style: {
            textAlign: "justify",
            maxWidth: "1200px"
        },
        content: (
            <>
                <p>
                    Todo
                </p>
            </>
        )
    }
}

export const adminImageWidget = {
    type: "image",
    config: {
        external: false,
        src: "/img/admin.png",
        style: {
            maxWidth: "1200px"
        }
    }
}

export const developmentItemsWidget = {
    type: "municipality-card-grid",
    config: {
        items: [
            dev.decin,
            dev.hk,
            dev.karvina
        ]
    }
}

export const partners = (response) => {
    return {
        id: "spoluprace",
        sections: [
            {
                id: "partneri",
                noAnchor: true,
                content: {
                    type: "widget-group",
                    config: {
                        style: {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "2rem"
                        },
                        widgets: [
                            partnersDescriptionWidget,
                            partneriListWidget,
                        ]
                    }
                }
            },
            {
                id: "zapojeni",
                heading: {
                    label: "Jak se zapojit do projektu?"
                },
                content: {
                    type: "widget-group",
                    config: {
                        style: {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "2rem"
                        },
                        widgets: [
                            joinUsWidget,
                            adminImageWidget
                        ]
                    }
                }
            }/*,
            {
                id: "vyvoj",
                heading: {
                    label: "Ve vývoji"
                },
                content: {
                    type: "widget-group",
                    config: {
                        style: {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "2rem"
                        },
                        widgets: [
                            developmentDescriptionWidget,
                            developmentItemsWidget
                        ]
                    }
                }
            }*/
        ]
    }
}
export default partners;