import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Messages } from 'primereact/messages';
import { getErrorMessage } from '../../../utils/errors';
import { useParams } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import authAxios from '../../lib/authAxios';
import { showErrorMessage, showSuccessMessage } from '../../utils/messages';
import { MemberTable } from './components/MemberTable';
import { ButtonAdd, ButtonEdit } from '../../components/Buttons';
import { MemberAddDialog } from './components/MemberAddDialog';
import { formatMemberName } from '../../../utils/texts';
import { ConfirmDeleteMemberDialog } from '../User/components/ConfirmDeleteMemberDialog';
import { MemberBreadCrumb } from './components/MemberBreadCrumb';
import { MemberOrderDialog } from './components/MemberOrderDialog';
import { PrimeIcons } from 'primereact/api';

export default function Members() {
    const { municipalityId } = useParams();
    const bodyType = 'zastupitelstvo';

    const [members, setMembers] = useState(null);
    const [loading, setLoading] = useState(true);

    const [showAddDialog, setShowAddDialog] = useState(false);
    const [processingAdd, setProcessingAdd] = useState(false);
    const refAddDialogMsgs = useRef(null);
    const refAddDialogForm = useRef(null);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedMember, setSelectedMember] = useState(null);
    const [processingDelete, setProcessingDelete] = useState(false);
    const refDeleteDialogMsgs = useRef(null);

    const [isVisibleOrderDialog, setIsVisibleOrderDialog] = useState(false);
    const [processingOrder, setProcessingOrder] = useState(false);
    const refOrderDialogMsgs = useRef(null);

    const msgs = useRef(null);

    const loadMembers = useCallback(async () => {
        try {
            const response = await authAxios.get(`admin/${municipalityId}/${bodyType}/member`);
            setMembers(response.data);
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se načíst členy');
        } finally {
            setLoading(false);
        }
    }, [municipalityId]);

    useEffect(() => {
        loadMembers();
    }, [loadMembers]);

    const hideAddDialog = () => {
        setShowAddDialog(false);
    };

    const handleAdd = async (data) => {
        setProcessingAdd(true);
        const requestData = {
            first_name: data.first_name,
            last_name: data.last_name,
            sex: data.sex
        };
        try {
            await authAxios.post(`admin/${municipalityId}/${bodyType}/member`, requestData);
            hideAddDialog();
            refAddDialogForm?.current?.reset();
            const message = requestData.sex ? 'Nová členka byla úspěšně přidána' : 'Nový člen byl úspěšně přidán';
            showSuccessMessage(msgs, message);
            loadMembers();
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(refAddDialogMsgs, errorMessage ?? 'Nepodařilo se přidat nového člena/členku', {
                replace: true
            });
        } finally {
            setProcessingAdd(false);
        }
    };

    const confirmDelete = (rowData) => {
        setSelectedMember(rowData);
        setShowDeleteDialog(true);
    };

    const hideDeleteDialog = () => {
        setShowDeleteDialog(false);
        setSelectedMember(null);
    };

    const handleDelete = async () => {
        const member = selectedMember;

        if (!member) {
            hideDeleteDialog();
            return;
        }

        setProcessingDelete(true);
        try {
            await authAxios.delete(`admin/${municipalityId}/${bodyType}/member/${member.id}`);
            setMembers(members.filter((e) => e.id !== member.id));
            hideDeleteDialog();
            const name = formatMemberName(member.first_name, member.last_name);
            showSuccessMessage(
                msgs,
                member.sex ? `Členka ${name} byla úspěšně smazána` : `Člen ${name} byl úspěšně smazán`
            );
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(
                refDeleteDialogMsgs,
                errorMessage ?? `Nepodařilo se smazat ${member.sex ? 'členku' : 'člena'}`,
                {
                    replace: true
                }
            );
        } finally {
            setProcessingDelete(false);
        }
    };

    const showOrderDialog = () => {
        setIsVisibleOrderDialog(true);
    };

    const hideOrderDialog = () => {
        setIsVisibleOrderDialog(false);
    };

    const handleOrderMembers = async (members) => {
        setProcessingOrder(true);
        const requestData = {
            order: members.map((member) => member.id)
        };
        try {
            await authAxios.post(`admin/${municipalityId}/${bodyType}/member/order`, requestData);
            hideOrderDialog();
            loadMembers();
            showSuccessMessage(msgs, 'Pořadí členů bylo úspěšně nastaveno');
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(refOrderDialogMsgs, errorMessage ?? 'Nepodařilo se nastavit pořadí členů', {
                replace: true
            });
        } finally {
            setProcessingOrder(false);
        }
    };

    return (
        <>
            <MemberBreadCrumb />
            <h1>Členové</h1>

            <div className='page__buttons'>
                <ButtonAdd
                    label='Přidat člena'
                    disabled={processingAdd || processingDelete || processingOrder}
                    onClick={() => setShowAddDialog(true)}
                />
                <ButtonEdit
                    label='Změnit pořadí'
                    icon={PrimeIcons.SORT_NUMERIC_DOWN}
                    disabled={processingAdd || processingDelete || processingOrder}
                    onClick={showOrderDialog}
                />
                {(processingAdd || processingDelete || processingOrder) && <ProgressSpinner />}
            </div>

            <Messages ref={msgs} className='page__messages' />

            <MemberTable
                members={members}
                loading={loading}
                onClickDelete={confirmDelete}
                disabled={processingAdd || processingDelete || processingOrder}
            />

            <ConfirmDeleteMemberDialog
                member={selectedMember}
                isVisible={showDeleteDialog}
                onHide={hideDeleteDialog}
                onClickDelete={handleDelete}
                msgs={refDeleteDialogMsgs}
            />

            <MemberAddDialog
                isVisible={showAddDialog}
                onHide={hideAddDialog}
                onSubmit={handleAdd}
                msgs={refAddDialogMsgs}
                ref={refAddDialogForm}
            />

            <MemberOrderDialog
                isVisible={isVisibleOrderDialog}
                members={members}
                loading={loading}
                onHide={hideOrderDialog}
                onConfirm={handleOrderMembers}
                processing={processingOrder}
                msgs={refOrderDialogMsgs}
            />
        </>
    );
}
