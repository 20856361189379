import React from 'react';
import { UserRole } from '../constants/enums';
import { BaseChip } from './Chips';

export const UserRoleChip = ({ role }) => {
    if (role === UserRole.ADMIN) {
        return <BaseChip label='Administrátor' severity='success' />;
    }
    if (role === UserRole.USER) {
        return <BaseChip label='Uživatel' severity='primary' />;
    }
    return <BaseChip label='-' />;
};
