import { Messages } from 'primereact/messages';
import React, { useEffect, useRef, useState } from 'react';
import LoadingPage from '../../../components/LoadingPage';
import { Card } from '../../../components/PrimereactComponents';
import { getErrorMessage } from '../../../utils/errors';
import { ErrorMessage } from '../../components/ErrorMessage';
import { LineDivider } from '../../components/LineDivider';
import authAxios from '../../lib/authAxios';
import { showErrorMessage, showSuccessMessage } from '../../utils/messages';
import { ProfileNameForm } from './components/ProfileNameForm';
import { AttributeRow } from '../../components/AttributeRow';
import { ChangePasswordForm } from './components/ChangePasswordForm';
import { scrollTop } from '../../utils/scroll';
import { UserRoleChip } from '../../components/UserRoleChip';

export default function Profile() {
    const msgs = useRef(null);

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [initError, setInitError] = useState(null);

    const [processingEditName, setProcessingEditName] = useState(false);

    const [processingChangePassword, setProcessingChangePassword] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await authAxios.get('admin/profile');
                setUser(response.data);
            } catch (error) {
                const errorMessage = getErrorMessage(error);
                setInitError(errorMessage ?? 'Nepodařilo se načíst uživatelské údaje');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const onSubmitEditName = async (data) => {
        setProcessingEditName(true);
        const requestData = {
            first_name: data.first_name,
            last_name: data.last_name
        };
        try {
            const params = new URLSearchParams({ type: 'name' });
            await authAxios.patch(`admin/profile?${params.toString()}`, requestData);
            showSuccessMessage(msgs, 'Jméno bylo úspěšně upraveno', { replace: true });
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se upravit údaje', { replace: true });
        } finally {
            setProcessingEditName(false);
            scrollTop();
        }
    };

    const onSubmitChangePassword = async (data) => {
        setProcessingChangePassword(true);
        const requestData = {
            old_password: data.password,
            new_password: data.new_password
        };
        try {
            const params = new URLSearchParams({ type: 'password' });
            await authAxios.patch(`admin/profile?${params.toString()}`, requestData);
            showSuccessMessage(msgs, 'Heslo bylo úspěšně změněno', { replace: true });
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se změnit heslo', { replace: true });
        } finally {
            setProcessingChangePassword(false);
            scrollTop();
        }
    };

    return loading ? (
        <LoadingPage />
    ) : (
        <>
            <h1>Upravit profil</h1>

            {initError ? (
                <ErrorMessage text={initError} />
            ) : (
                <>
                    <Card className='admin-card'>
                        <Messages ref={msgs} className='page__messages' />

                        <div className='dialog-text'>
                            <AttributeRow label='Login'>{user.login}</AttributeRow>
                            <AttributeRow label='Role'>
                                <UserRoleChip role={user.role} />
                            </AttributeRow>
                        </div>

                        <LineDivider />

                        <ProfileNameForm
                            user={user}
                            onSubmit={onSubmitEditName}
                            disabled={processingEditName || processingChangePassword}
                        />

                        <LineDivider />

                        <ChangePasswordForm
                            onSubmit={onSubmitChangePassword}
                            disabled={processingEditName || processingChangePassword}
                        />
                    </Card>
                </>
            )}
        </>
    );
}
