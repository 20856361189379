import React from "react";

import ColumnGroup from "./ColumnGroup";
import CustomWidget from "./CustomWidget";
import DatasetList from "./DatasetList";
import DetailRef from "./DetailRef";
import Header from "./Header";
import Heading from "./Heading";
import Image from "./Image";
import LinksFooter from "./LinksFooter";
import MunicipalityCardGrid from "./MunicipalityCardGrid";
import RegionalBarChart from "../map/RegionalBarChart";
import RegionalFilteredChoropleth from "../map/RegionalFilteredChoropleth";
import RegionalChoropleth from "../map/RegionalChoropleth";
import RegionalMap from "../map/RegionalMap";
import RegionalStackedBarChart from "../map/RegionalStackedBarChart";
import Section from "./Section";
import StatsGrid from "./StatsGrid";
import WidgetGroup from "./WidgetGroup";

import "./Widget.css"
import RegionalLineChart from "../map/RegionalLineChart";

function Widget({
    type = "",
    config = undefined
}) {

    return (
        <>
            {
                type === "custom" &&
                    <CustomWidget
                        content={config.content}
                        style = {config.style}
                    />
            }
            {
                type === "column-group" &&
                    <ColumnGroup
                        widgets={config.widgets}
                        style = {config.style}
                    />
            }
            {
                type === "dataset-list" &&
                <DatasetList
                    datasets={config.datasets}
                />
            }
            {
                type === "detail-ref" &&
                <DetailRef
                    url={config.url}
                    label={config.label}/>
            }
            {
                type === "links-footer" &&
                    <LinksFooter
                        region={config.region}
                        data={config.data}
                        left={config.left}
                        right={config.right}
                    />
            }
            {
                type === "header" &&
                    <Header
                        id={config.level}
                        sublabel={config.sublabel}
                        logoLarge = {config.logoLarge}
                        logoSmall = {config.logoSmall}
                        style = {config.style}
                    />
            }
            {
                type === "heading" &&
                    <Heading
                        level={config.level}
                        title = {config.title}
                        arrowAnchor = {config.arrowAnchor}
                        style = {config.style}
                    />
            }
            {
                type === "image" &&
                    <Image
                        external={config.external}
                        src = {config.src}
                        alt = {config.alt}
                        style = {config.style}
                        url = {config.url}
                    />
            }
            {
                type === "line" &&
                    <RegionalLineChart
                        geo = {config.geo}
                        datasets = {config.datasets}
                        selection = {config.selection}
                    />
            }
            {
                type === "municipality-card-grid" &&
                    <MunicipalityCardGrid
                        items={config.items}
                    />
            }
            {
                type === "bar" &&
                    <RegionalBarChart
                        geo = {config.geo}
                        data = {config.data}
                        categories = {config.categories}
                        getValue = {config.getValue}
                        formatValue = {config.formatValue}
                        config = {config.config}
                        selection = {config.selection}
                    />
            }
            {
                type === "regional-filtered-choropleth" &&
                <RegionalFilteredChoropleth
                    id={config.id}
                    geo={config.geo}
                    datasets={config.datasets}
                    map={config.map}/>
            }
            {
                type === "regional-choropleth" &&
                    <RegionalChoropleth
                        id={config.id}
                        geo={config.geo}
                        data={config.data}
                        categories={config.categories}
                        getValue={config.getValue}
                        formatValue={config.formatValue}
                        map={config.map}
                    />
            }
            {
                type === "stacked-bar" &&
                    <RegionalStackedBarChart
                        geo = {config.geo}
                        datasets = {config.datasets}
                        getValue = {config.getValue}
                        selection = {config.selection}
                    />
            }
            {
                type === "regional-map" &&
                <RegionalMap
                    id={config.id}
                    geo={config.geo}
                    defaults={config.defaults}
                    regions={config.regions}/>
            }
            {
                type === "section" &&
                    <Section
                        id={config.id}
                        heading={config.heading}
                        content={config.content}
                        style={config.style}
                    />
            }
            {
                type === "stats-grid" &&
                    <StatsGrid
                        stats={config.stats}
                    />
            }
            {
                type === "widget-group" &&
                    <WidgetGroup
                        widgets={config.widgets}
                        style={config.style}
                    />
            }
        </>
    )
}

export default Widget;