import React, {useState, useEffect, useRef} from 'react';
import useDimensions from '../hooks/useDimensions';
import { Link, useNavigate } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import LoadingPage from './LoadingPage';
import { useTranslation } from 'react-i18next';

const calculateRowsMax = (width) => width < 720 ? 8 : 5;

function SvgChart({ basicData }) {
    const { height, width } = useDimensions();
    const { t } = useTranslation();
    const navigate = useNavigate();


    const [rowsMax, setRowsMax] = useState(calculateRowsMax(window.innerWidth));

    useEffect(() => {
        const handleResize = () => setRowsMax(calculateRowsMax(window.innerWidth));

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const generateSvg = () => {
        const itemCount = basicData.nejnovejsi_zasedani.mandaty.map(item => item.pocet_mandatu).reduce((partyCount, sum) => sum + partyCount, 0);
        const rows = itemCount > rowsMax ? rowsMax : itemCount;
        const radius = 15;
        const gap = 5;
        const offset = radius * 2 + gap;
        const columns = Math.round(itemCount / rowsMax);

        // first dot starts with a gap, and then it needs 1 radius of space, so it does not clip
        let x = gap + radius;
        let y = gap + radius;

        const width = gap + offset * columns;
        const height = gap + offset * rows;

        let content = `<svg viewbox="0 0 ${width} ${height}" height="${height}" xmlns="http://www.w3.org/2000/svg">`;

        let idx = 0;
        let colors = [];
        let party_id = [];

        let idxs = basicData.nejnovejsi_zasedani.mandaty.map((party) => {
            party_id.push(party.strana_id);
            idx = idx + party.pocet_mandatu;
            colors.push(party.strana_barva);
            // idx, na kterem se meni barva
            return idx;
        });

        let color = '';
        let partyId = 0;

        for (let i = 0; i < itemCount; i++) {
            if (i === 0) {
                color = colors[0];
                content += `<g>`;
            }
            if (idxs.includes(i)) {
                idx = idxs.indexOf(i);
                content += `</g><g>`;
                color = colors[idx + 1];
                partyId++;
            }
            if (i > 0 && i % rowsMax === 0) {
                x = x + offset;
            }

            content += `<ellipse class="party-${party_id[partyId]}" ry="${radius}" rx="${radius}" cy="${y}" cx="${x}" fill="${color}"/>`;

            y = (y + offset) % (rowsMax * offset);
        }
        content = content + '</svg>';
        return content.toString('base64');
    };

    const svgData = generateSvg();

    const [hoveredParty, setHoveredParty] = useState(null);
    const svgRef = useRef(null);

    const handleMouseEnter = (partyId) => {
        setHoveredParty(partyId);
    };

    const handleMouseLeave = () => {
        setHoveredParty(null);
    };

    const handleEllipseClick = (partyId) => {
        navigate(`subjekty/${partyId}`);
    };
    
    const attachEventListeners = (svgElement) => {
        if (svgElement) {
            const ellipses = svgElement.querySelectorAll('ellipse[class^="party-"]');

            const handleMouseEnterWrapper = (partyId) => () => handleMouseEnter(partyId);
            const handleMouseLeaveWrapper = () => handleMouseLeave();
            const handleEllipseClickWrapper = (partyId) => () => handleEllipseClick(partyId);

            ellipses.forEach((ellipse) => {
                const partyId = ellipse.getAttribute('class').split('-')[1];
                ellipse.addEventListener('mouseenter', handleMouseEnterWrapper(partyId));
                ellipse.addEventListener('mouseleave', handleMouseLeaveWrapper);
                ellipse.addEventListener('click', handleEllipseClickWrapper(partyId));

                // Store event listeners to remove them later
                ellipse._eventListeners = {
                    mouseenter: handleMouseEnterWrapper(partyId),
                    mouseleave: handleMouseLeaveWrapper,
                    click: handleEllipseClickWrapper(partyId),
                };
            });

            return () => {
                ellipses.forEach((ellipse) => {
                    const { mouseenter, mouseleave, click } = ellipse._eventListeners;
                    ellipse.removeEventListener('mouseenter', mouseenter);
                    ellipse.removeEventListener('mouseleave', mouseleave);
                    ellipse.removeEventListener('click', click);
                });
            };
        }
    };

    return (
        <div className='grid'>
            <div className='item-svg'>
                <SVG innerRef={svgRef} loader={<LoadingPage />} src={svgData} onLoad={attachEventListeners(svgRef.current)} />
            </div>
            <div className='item-list'>
                {basicData.nejnovejsi_zasedani.mandaty.map((party) => (
                    <div key={party.strana_id}>
                        <Link
                            style={{ color: hoveredParty? (hoveredParty == party.strana_id ? party.strana_barva : party.strana_barva+"4D" ) : party.strana_barva }}
                            to={`subjekty/${party.strana_id}`}
                            key={party.strana_id}
                        >
                            <b>{party.strana_zkratka}</b>
                        </Link>{' '}
                        <span
                        className={`party-${party.strana_id} ${hoveredParty? (hoveredParty == party.strana_id ? "" : "text__secondary" ) : ""}`}
                        >
                            <span style={{ color: hoveredParty? (hoveredParty == party.strana_id ? party.strana_barva : party.strana_barva+"4D" ) : party.strana_barva, fontWeight: 600 }}>{party.pocet_mandatu}</span>
                            &nbsp;{t('mandate', { count: party.pocet_mandatu })}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
}
export default SvgChart;
