import commonStats from "../kraje/stats/common";
import transparencyStats from "../kraje/stats/transparency";
import APP from "../../config";
import saldoStats from "../kraje/stats/saldo";
import subjectsStats from "../kraje/stats/subjects";
import { mainDescription, mainDescriptionBrief } from "../kraje/stats";

const stats = (response) => {
    return {
        id: "statistiky",
        sections: [
            {
                id: "o-statistikach",
                noAnchor: true,
                content: {
                    type: "custom",
                    config: {
                        style: {
                            textAlign: "justify",
                            maxWidth: "1200px",
                            margin: "0 auto"
                        },
                        content: (
                            <>
                                <p>
                                Samotná data bývají obtížně čitelná a jejich smysl často zůstává skrytý, pokud nejsou vhodně prezentována. Vizualizace dat umožňuje převést složité soubory čísel a záznámů do grafických forem, které jsou snadněji pochopitelné a interpretovatelné. Díky tomu mohou lidé rychle identifikovat vzory, trendy a vztahy mezi hodnotami, což jim pomůže lépe se orientovat v dané problematice a rozhodovat na základě faktů. V rámci projektu jsme vytvořili několik vizualizací, které vám pomohou lépe pochopit fungování zastupitelstev a zastupitelů v jednotlivých krajích a městech.
                                </p>
                            </>
                        )
                    }
                }
            },
            {
                id: "porovnani",
                heading: {
                    label: "Porovnávejte jednotlivé kraje",
                },
                content: {
                    type: "widget-group",
                    config: {
                        widgets: [
                            {
                                type: "custom",
                                config: {
                                    style: {
                                        textAlign: "justify",
                                        maxWidth: "1200px",
                                        margin: "0 auto"
                                    },
                                    content: (
                                        <>
                                            {mainDescriptionBrief} 
                                            <p>
                                                Veškeré analýzy naleznete na <a href={`${APP.protocol}://kraje.${APP.domain}${APP.port ? ':' + APP.port : ''}/analyzy`}>samostatné stránce</a>.
                                            </p>
                                        </>
                                    )
                                }
                            },
                            {
                                type: "stats-grid",
                                config: {
                                    stats: [
                                        saldoStats,
                                        subjectsStats
                                    ]
                                }
                            },
                            {
                                type: "detail-ref",
                                config: {
                                    url: `${APP.protocol}://kraje.${APP.domain}${APP.port ? ':' + APP.port : ''}/analyzy`,
                                    label: "Více analýz"
                                }
                            }
                        ]
                    }
                }
            },
            {
                id: "detaily",
                heading: {
                    label: "Analyzujte detaily jednotlivých zastupitelstev",
                },
                content: {
                    type: "widget-group",
                    config: {
                        widgets: [
                            {
                                type: "custom",
                                config: {
                                    style: {
                                        textAlign: "justify",
                                        maxWidth: "1200px",
                                        margin: "0 auto"
                                    },
                                    content: (
                                        <>
                                            <p>
                                                Příkladem zastupitelstva je <a href={`${APP.protocol}://brno.${APP.domain}${APP.port ? ':' + APP.port : ''}/brno/zastupitelstvo/9/`}>Zastupitelstvo města Brna</a> nebo <a href={`${APP.protocol}://kraje.${APP.domain}${APP.port ? ':' + APP.port : ''}/jihomoravsky/zastupitelstvo/6/`}>Zastupitelstvo Jihomoravského kraje</a>. V detailu každého zastupitelstva jsou k dispozici analýzy jednotlivých politických subjektů, zastupitelů, zasedání a hlasování. Dále jsou sledovány:
                                            </p>
                                            <ul style={{ fontSize: "1.2rem", lineHeight: 2 }}>
                                                <li><a href={`${APP.protocol}://brno.${APP.domain}${APP.port ? ':' + APP.port : ''}/brno/zastupitelstvo/9/analyzy/dochazka`}>docházka zastupitelů</a>,</li>
                                                <li><a href={`${APP.protocol}://brno.${APP.domain}${APP.port ? ':' + APP.port : ''}/brno/zastupitelstvo/9/analyzy/porovnani_zastupitelu`}>shoda zastupitelů</a>,</li>
                                                <li><a href={`${APP.protocol}://brno.${APP.domain}${APP.port ? ':' + APP.port : ''}/brno/zastupitelstvo/9/analyzy/nejednotnost`}>nejednotnost stran</a>,</li>
                                                <li><a href={`${APP.protocol}://brno.${APP.domain}${APP.port ? ':' + APP.port : ''}/brno/zastupitelstvo/9/analyzy/koheze`}>koheze hlasování</a>.</li>
                                            </ul>
                                            <p>
                                                Seznam městských a krajských zastupitelstev je k dispozici na <a href={`${APP.protocol}://${APP.domain}${APP.port ? ':' + APP.port : ''}`}>tilulní straně</a>.
                                            </p>
                                        </>
                                    )
                                }
                            }
                        ]
                    }
                }
            }
        ]
    }
}

export default stats;