import { datasetsDescriptionWidget, datasetsImageWidget } from "../../../datasets/default/datasets";

export const datasetsSection = {
    id: "datasety",
    heading: {
        label: "Proč jsou důležitá otevřená data?"
    },
    content: {
        type: "widget-group",
        config: {
            style: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "2rem"
            },
            widgets: [
                datasetsDescriptionWidget,
                datasetsImageWidget,
                {
                    type: "detail-ref",
                    config: {
                        url: "/datasety"
                    }
                }
            ]
        }
    },
}