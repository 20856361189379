import React, { useEffect } from 'react';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import { Navigate, Outlet, ScrollRestoration, useNavigate } from 'react-router-dom';
import '../styles/AdminApp.css';
import { AdminFooter } from './AdminFooter';
import { AdminNavMenu } from './AdminNavMenu';

export default function AdminApp() {
    const isAuthenticated = useIsAuthenticated();

    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/admin/login', { replace: true });
        }
    }, [isAuthenticated, navigate]);

    return isAuthenticated ? (
        <>
            <AdminNavMenu />
            <div>
                <div id='body' className='body--admin'>
                    <ScrollRestoration />
                    <Outlet />
                </div>
                <AdminFooter />
            </div>
        </>
    ) : (
        <Navigate to='/admin/login' replace />
    );
}
