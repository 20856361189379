import React, { useState } from 'react';
import { Link, useParams, useLoaderData } from 'react-router-dom';
import { Card } from '../../components/PrimereactComponents';
import { useTranslation } from 'react-i18next';

function Parties() {
    const { t } = useTranslation();

    const { bodyType, zastupitelstvoId } = useParams();

    const response = useLoaderData();

    const basicData = response[0].data;
    const partiesData = response[1].data;
    const partiesStats = response[2].data;
    const municipality = response[3].data;
    const options = response[4].data;
    const memberOptions = response[5].data;

    const cardTemplate = (party) => {
        const stats = partiesStats.find((p) => p.strana_zkratka === party.strana_zkratka);
        const isInKoalice = basicData.koalice.find(
            (koaliceParty) => koaliceParty.strana_zkratka === party.strana_zkratka
        );
        return (
            <Link className='item-card' key={'strana' + party.strana_zkratka} to={`${parseInt(party.strana_id)}`}>
                <Card
                    className='hoverable-card'
                    header={<h3 style={{ color: party.strana_barva }}>{party.strana_zkratka}</h3>}
                >
                    <div className='card-content'>
                        <p>
                            <span className='text__primary text__bold'>{party.pocet_aktivnich}</span>&nbsp;mandátů
                        </p>
                        { stats.celkova_ucast ? <p>
                            <span className='text__primary text__bold'>{stats.celkova_ucast}</span>&nbsp;% celková účast
                        </p> : <></> }
                        <p>
                            <span className='text__primary text__bold'>
                                {party.pocet_aktivnich === 0 ? 'neaktivní' : isInKoalice ? 'koaliční' : 'opoziční'}
                            </span>
                            &nbsp;subjekt
                        </p>
                    </div>
                </Card>
            </Link>
        );
    };

    if (partiesData !== undefined && zastupitelstvoId !== undefined && municipality !== undefined) {
        return (
            <div className='parties'>
                {partiesData.map((party) => cardTemplate(party))}
            </div>
        );
    }
}

export default Parties;
