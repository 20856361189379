import { Messages } from 'primereact/messages';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getErrorMessage } from '../../../utils/errors';
import authAxios from '../../lib/authAxios';
import { showErrorMessage, showSuccessMessage } from '../../utils/messages';
import { ButtonAdd } from '../../components/Buttons';
import { PoliticalEntityTable } from './components/PoliticalEntityTable';
import { PoliticalEntityDialog } from './components/PoliticalEntityDialog';
import { ConfirmDeletePoliticalEntityDialog } from './components/ConfirmDeletePoliticalEntityDialog';
import { MunicipalityBreadCrumb } from '../../components/MunicipalityBreadCrumb';

export default function PoliticalEntities() {
    const { municipalityId } = useParams();
    const bodyType = 'zastupitelstvo';

    const [politicalEntities, setPoliticalEntities] = useState(null);
    const [loading, setLoading] = useState(true);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedPoliticalEntity, setSelectedPoliticalEntity] = useState(null);
    const [processingDelete, setProcessingDelete] = useState(false);
    const refDialogDeleteMsgs = useRef(null);

    const [showPoliticalEntityDialog, setShowPoliticalEntityDialog] = useState(false);
    const [processingAdd, setProcessingAdd] = useState(false);
    const [processingEdit, setProcessingEdit] = useState(false);
    const refPoliticalEntityDialogMsgs = useRef(null);
    const refPoliticalEntityDialogForm = useRef(null);

    const msgs = useRef(null);

    const fetchData = useCallback(async () => {
        try {
            const response = await authAxios.get(`admin/${municipalityId}/${bodyType}/political-entity`);
            setPoliticalEntities(response.data);
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se načíst politické subjekty', { replace: true });
        } finally {
            setLoading(false);
        }
    }, [municipalityId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const confirmDelete = (rowData) => {
        setSelectedPoliticalEntity(rowData);
        setShowDeleteDialog(true);
    };

    const hideDeleteDialog = () => {
        setShowDeleteDialog(false);
        setSelectedPoliticalEntity(null);
    };

    const handleDelete = async (data) => {
        if (!selectedPoliticalEntity) {
            hideDeleteDialog();
            return;
        }

        setProcessingDelete(true);
        const requestData = {
            password: data.password
        };
        try {
            await authAxios.post(
                `admin/${municipalityId}/${bodyType}/political-entity/${selectedPoliticalEntity.id}/delete`,
                requestData
            );
            setPoliticalEntities(politicalEntities.filter((e) => e.id !== selectedPoliticalEntity.id));
            hideDeleteDialog();
            showSuccessMessage(msgs, `Politický subjekt ${selectedPoliticalEntity.abbreviation} byl úspěšně smazán`);
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(refDialogDeleteMsgs, errorMessage ?? 'Nepodařilo se smazat politický subjekt', {
                replace: true
            });
        } finally {
            setProcessingDelete(false);
        }
    };

    const hidePoliticalEntityDialog = () => {
        setShowPoliticalEntityDialog(false);
        setSelectedPoliticalEntity(null);
    };

    const handleAdd = async (data) => {
        setProcessingAdd(true);
        const requestData = {
            name: data.name,
            abbreviation: data.abbreviation,
            color: data.color
        };
        try {
            await authAxios.post(`admin/${municipalityId}/${bodyType}/political-entity`, requestData);
            hidePoliticalEntityDialog();
            refPoliticalEntityDialogForm?.current?.reset();
            showSuccessMessage(msgs, `Politický subjekt ${data.name || data.abbreviation} byl úspěšně přidán`);
            fetchData();
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(refPoliticalEntityDialogMsgs, errorMessage ?? 'Nepodařilo se přidat politický subjekt', {
                replace: true
            });
        } finally {
            setProcessingAdd(false);
        }
    };

    const onClickEdit = (rowData) => {
        setSelectedPoliticalEntity(rowData);
        setShowPoliticalEntityDialog(true);
    };

    const handleEdit = async (data) => {
        if (!selectedPoliticalEntity) {
            hidePoliticalEntityDialog();
            return;
        }

        setProcessingEdit(true);
        const requestData = { abbreviation: data.abbreviation, name: data.name, color: data.color };
        try {
            await authAxios.put(
                `admin/${municipalityId}/${bodyType}/political-entity/${selectedPoliticalEntity.id}`,
                requestData
            );

            const index = politicalEntities.findIndex((e) => e.id === selectedPoliticalEntity.id);
            if (index !== -1) {
                const newRows = [...politicalEntities];
                newRows[index] = {
                    ...newRows[index],
                    abbreviation: data.abbreviation,
                    name: data.name,
                    color: data.color
                };
                setPoliticalEntities(newRows);
            }

            hidePoliticalEntityDialog();
            showSuccessMessage(msgs, 'Politický subjekt byl úspěšně upraven');
            fetchData();
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(refPoliticalEntityDialogMsgs, errorMessage ?? 'Nepodařilo se upravit politický subjekt', {
                replace: true
            });
        } finally {
            setProcessingEdit(false);
        }
    };

    return (
        <>
            <MunicipalityBreadCrumb
                extraItems={[
                    {
                        label: 'Politické subjekty',
                        url: `/admin/municipality/${municipalityId}/subjekty`
                    }
                ]}
            />
            <h1>Politické subjekty</h1>

            <div className='page__buttons'>
                <ButtonAdd
                    label='Přidat subjekt'
                    disabled={processingDelete || processingEdit || processingAdd}
                    onClick={() => setShowPoliticalEntityDialog(true)}
                />
                {(processingDelete || processingEdit || processingAdd) && <ProgressSpinner />}
            </div>

            <Messages ref={msgs} className='page__messages' />

            <PoliticalEntityTable
                politicalEntities={politicalEntities}
                loading={loading}
                onClickEdit={onClickEdit}
                onClickDelete={confirmDelete}
                disabled={processingDelete || processingEdit || processingAdd}
            />

            <ConfirmDeletePoliticalEntityDialog
                politicalEntity={selectedPoliticalEntity}
                isVisible={showDeleteDialog}
                onHide={hideDeleteDialog}
                onSubmit={handleDelete}
                msgs={refDialogDeleteMsgs}
            />

            <PoliticalEntityDialog
                isVisible={showPoliticalEntityDialog}
                onHide={hidePoliticalEntityDialog}
                onSubmitAdd={handleAdd}
                onSubmitEdit={handleEdit}
                editPoliticalEntity={selectedPoliticalEntity}
                msgs={refPoliticalEntityDialogMsgs}
                ref={refPoliticalEntityDialogForm}
            />
        </>
    );
}
