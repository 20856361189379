import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { hasPermission, hasRole } from '../utils/auth';
import LoadingPage from '../../components/LoadingPage';
import { Permission } from '../constants/enums';
import { useUserPermissions } from '../hooks/useUserPermissions';

export const ProtectedRoute = ({ element, role, permission }) => {
    const { municipalityId } = useParams();

    const { user, loading } = useUserPermissions();

    const isAllowed = (user, role, permission) => {
        if (role) {
            return hasRole(user, role);
        }
        if (permission === Permission.MANAGE_MUNICIPALITY) {
            return !municipalityId || hasPermission(user, permission, municipalityId);
        }
        return false;
    };

    if (loading) {
        return <LoadingPage />;
    }

    const allowed = isAllowed(user, role, permission);

    return allowed ? element : <Navigate to='/admin/login' replace />;
};
