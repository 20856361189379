import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react';
import { getFormArrayErrorMessage } from '../../../utils/errors';
import { ButtonDelete, ButtonRevert } from '../../../components/Buttons';
import { RHFDropdown } from '../../../components/rhf';
import { classNames } from 'primereact/utils';
import { ATTENDANCE_OPTIONS } from '../../../constants/constants';
import { order, orderNull } from '../../../utils/sort';
import { IconTooltip } from '../../../components/IconTooltip';

export const MeetingAddMemberTable = ({
    control,
    errors,
    fields,
    getValues,
    politicalEntities,
    loading,
    disabled,
    onRemoveMember,
    onRevertRemoveMember
}) => {
    const politicalEntityTemplate = (rowData) => {
        return (
            <div className='flex-column'>
                <RHFDropdown
                    name={`members[${rowData.index}].political_entity`}
                    key={`${rowData.item.id}-political_entity`}
                    control={control}
                    rules={{
                        required: {
                            value: !rowData.item.delete,
                            message: 'Zvolte politický subjekt'
                        }
                    }}
                    placeholder='Zvolte politický subjekt'
                    inputProps={{
                        options: politicalEntities,
                        optionValue: 'id',
                        optionLabel: 'abbreviation',
                        emptyMessage: 'Nejsou zadány žádné politické subjekty',
                        showClear: false
                    }}
                />
                {getFormArrayErrorMessage(errors, 'members', rowData.index, 'political_entity')}
            </div>
        );
    };

    const attendanceTemplate = (rowData) => {
        return (
            <div className='flex-column'>
                <RHFDropdown
                    name={`members[${rowData.index}].attendance`}
                    key={`${rowData.item.id}-attendance`}
                    control={control}
                    rules={{
                        required: {
                            value: !rowData.item.delete,
                            message: 'Zvolte přítomnost na zasedání'
                        }
                    }}
                    placeholder='Zvolte přítomnost na zasedání'
                    inputProps={{
                        options: ATTENDANCE_OPTIONS,
                        showClear: false
                    }}
                />
                {getFormArrayErrorMessage(errors, 'members', rowData.index, 'attendance')}
            </div>
        );
    };

    const buttonsColumn = (rowData) => {
        return !rowData.item.delete ? (
            <ButtonDelete
                type='button'
                tooltip='Odebrat člena'
                rounded
                onClick={() => onRemoveMember(rowData.item, rowData.index)}
                disabled={disabled}
            />
        ) : (
            <ButtonRevert
                type='button'
                tooltip='Zrušit odebrání'
                rounded
                onClick={() => onRevertRemoveMember(rowData.item, rowData.index)}
                disabled={disabled}
            />
        );
    };

    const highlightCell = (rowData) => {
        return classNames({
            'table-cell--warning': rowData.item.delete,
            'table-cell--success': rowData.item.add
        });
    };

    const politicalEntitySort = (e) => {
        return e.data.toSorted((a, b) => {
            const aPoliticalEntityId = getValues(`members[${a.index}].political_entity`);
            const bPoliticalEntityId = getValues(`members[${b.index}].political_entity`);

            // one of the inputs is empty
            if (!aPoliticalEntityId || !bPoliticalEntityId) {
                return orderNull(aPoliticalEntityId, bPoliticalEntityId);
            }

            const aAbbreviation = politicalEntities.find((entity) => entity.id === aPoliticalEntityId).abbreviation;
            const bAbbreviation = politicalEntities.find((entity) => entity.id === bPoliticalEntityId).abbreviation;

            return order(aAbbreviation, bAbbreviation) * e.order;
        });
    };

    return (
        <DataTable
            value={fields.map((item, index) => ({
                item,
                index
            }))}
            loading={loading}
            removableSort
            sortField='item.last_name'
            sortOrder={1}
            breakpoint='500px'
            emptyMessage='Zvolte datum pro načtení členů zasedání'
        >
            <Column field='item.member_id' header='ID' bodyClassName={highlightCell} sortable />
            <Column field='item.first_name' header='Jméno' sortable />
            <Column field='item.last_name' header='Příjmení' sortable />
            <Column
                field='__.political_entity'
                header='Politický subjekt'
                body={politicalEntityTemplate}
                sortable
                sortFunction={politicalEntitySort}
            />
            <Column
                header={
                    <>
                        Přítomnost
                        <IconTooltip>
                            Přítomnost se automaticky vypočítává podle přidaných hlasování. Změna hodnoty je vhodná
                            pouze pro zasedání bez hlasování.
                        </IconTooltip>
                    </>
                }
                body={attendanceTemplate}
            />
            <Column body={buttonsColumn} align='right' />
        </DataTable>
    );
};
