import React, { useState } from "react";

import { Dialog } from 'primereact/dialog';
import { Tooltip } from "primereact/tooltip";

import PopupBodyBasicStats from "./popup/PopupBodyBasicStats";
import PopupHeader from "./popup/PopupHeader";
import PopupFooter from "./popup/PopupFooter";

import './RegionalMap.css';

/**
 * @author Jiri Hynek
 * 
 * A simple components which provides an SVG choroptleth of given regions.
 * It renders the SVG directly. It is not a good approach, but quick.
 * 
 * TODO: Convert it to D3 rendering GeoJSON in order to make the code more compact.
 * 
 * @param {*} param 
 * @returns 
 */
function RegionalMap({
    /**
     * TODO: convert it to TS!
     */
    id = undefined,
    geo = {},
    defaults = {
        polygon: {
            value: undefined,
            formatValue: (value) => value,
            // general SVG CSS styles
            style: {
                fill: "lightgray",
                stroke: "white"
                // ... all SVG CSS styles can be used
            },
            hoverStyle: {
                fill: "yellow",
            },
            callbacks: {
                onMouseEnter: undefined,
                onMouseLeave: undefined,
                onMouseMove: undefined,
                onClick: undefined,
                // it needs to be extended if further event types are required
            },
            popup: {
                header: {
                    component: PopupHeader,
                    props: {}
                },
                body: {
                    component: PopupBodyBasicStats,
                    props: {}
                },
                footer: {
                    component: PopupFooter,
                    props: {}
                },
                style: {
                    width: '80vw',
                    height: '90vh',
                    maxWidth: '1600px',
                }
            },
            hover: {
                style: {}
            }
        },
        capital: {
            value: undefined,
            formatValue: (value) => value,
            style: {
                fill: "darkgray",
                stroke: "black",
            },
            hoverStyle: {
                fill: "yellow",
            },
            callbacks: {
                onMouseEnter: undefined,
                onMouseLeave: undefined,
                onMouseMove: undefined,
                onClick: undefined,
                // it needs to be extended if further event types are required
            },
            hover: {
                style: {}
            }
        }
    },
    regions = {
        // settings can be overriden for every region using license plate codes
        // e.g. regions.U.capital.style.fill: "blue"
    },
    }) {

    /**
     * These styles are able to override input styles
     */
    const [ hover, setHover ] = useState({
        target: undefined,
        style: {}
    });

    const [ popup, setPopup ] = useState({
        visible: false,
        header: undefined,
        body: undefined,
        footer: undefined,
        props: undefined
    })

    const [ hoverPos, setHoverPos ] = useState({ x: 0, y: 0 });

    const formatValue = (region, className) => {
        if(regions && regions[region] && regions[region][className] && regions[region][className].formatValue) {
            return regions[region][className].formatValue ? regions[region][className].formatValue(regions[region][className].value) : regions[region][className].value;
        } else if(defaults[className] && defaults[className].formatValue) {
            return defaults[className].formatValue ? defaults[className].formatValue(defaults[className].value) : defaults[className].value;
        }
        return undefined;
    }

    /**
     * Help function which processes a given event and returns
     * selected element, region id, and class name ('polygon' / 'capital')
     * 
     * @param {*} e 
     * @returns 
     */
    const getEventTarget = (e) => {
        if(e && e.target && e.target.parentElement) {
            return {
                element: e.target,
                region: e.target.parentElement.id,
                className: e.target.className?.baseVal
            }
        }
        return undefined;
    }

    /**
     * Mouse enter callback.
     * 
     * @param {*} e 
     */
    const onMouseEnter = (e) => {
        const target = getEventTarget(e);
        if(target && target.element && target.region && target.className) {
            
            const region = regions[target.region];
            const regionalProps = region ? region[target.className] : undefined;
            const defaultProps = defaults[target.className];

            // callbacks
            if(regionalProps?.callbacks?.onMouseEnter) {
                // regional callback
                regionalProps.callbacks.onMouseEnter(target);
            } else if(defaultProps?.callbacks?.onClick) {
                // default callback
                defaultProps.callbacks.onMouseEnter(target);
            }

            // hover style
            const hoverStyle = {
                [target.region]: {
                    [target.className]: {
                        style: {
                            ...defaultProps?.hoverStyle, // merge it with default hover styles if defined
                            ...regionalProps?.hoverStyle
                        }
                    }
                }
            }

            setHover({
                target: target,
                style: hoverStyle,
                value: formatValue(target.region, target.className)
            });
        }
    }

    /**
     * Mouse leave callback.
     * 
     * @param {*} e 
     */
    const onMouseLeave = (e) => {
        const target = getEventTarget(e);
        if(target && target.element && target.region && target.className) {
            const region = regions[target.region];
            const regionalProps = region ? region[target.className] : undefined;
            const defaultProps = defaults[target.className];

            if(regionalProps?.callbacks?.onClick) {
                // regional callback
                regionalProps.callbacks.onMouseLeave(target);
            } else if(defaultProps?.callbacks?.onClick) {
                // default callback
                defaultProps.callbacks.onMouseLeave(target);
            }

            setHover({
                target: undefined,
                style: {},
                value: undefined
            });
        }
    }

    const onMouseMove = (e) => {
        if(hover.target !== undefined) {
            const region = regions[hover.target.region];
            const regionalProps = region ? region[hover.target.className] : undefined;
            const defaultProps = defaults[hover.target.className];

            if(regionalProps?.callbacks?.onClick) {
                // regional callback
                regionalProps.callbacks.onMouseMove(hover.target);
            } else if(defaultProps?.callbacks?.onClick) {
                // default callback
                defaultProps.callbacks.onMouseMove(hover.target);
            }

            // update hover position
            setHoverPos({ x: e.clientX, y: e.clientY });
        }
    }

    /**
     * Mouse click event.
     * 
     * @param {*} e 
     */
    const onClick = (e) => {
        const target = getEventTarget(e);
        if(target && target.element && target.region && target.className) {    
            
            const region = regions[target.region];
            const regionalProps = region ? region[target.className] : undefined;
            const defaultProps = defaults[target.className];

            if(regionalProps?.callbacks?.onClick) {
                // regional callback
                regionalProps.callbacks.onClick(target);
            } else if(defaultProps?.callbacks?.onClick) {
                // default callback
                defaultProps.callbacks.onClick(target);
            } else {
                // popup
                const header = regionalProps?.popup?.header ?? defaultProps?.popup?.header;
                const body = regionalProps?.popup?.body ?? defaultProps?.popup?.body;
                const footer = regionalProps?.popup?.footer ?? defaultProps?.popup?.footer;

                if(header && header.component && body && body.component && footer && footer.component) {
                    setPopup({
                        visible: true,
                        header: React.createElement(header.component, { event: target, props: header.props}),
                        body: React.createElement(body.component, { event: target, props: body.props}),
                        footer: React.createElement(footer.component, { event: target, props: footer.props}),
                        props: target
                    });
                }
            }
        }
    }

    return (
        <>
            <div className="regional-map">
                {
                    // popup
                    hover.value !== undefined && 
                    <div
                        className="map-hover-popup"
                        style={{
                            left: hoverPos.x + 10,
                            top: hoverPos.y + 10,
                            ...defaults?.hover?.style,
                            ...(
                                (regions[hover.target.region] && regions[hover.target.region][hover.target.className])
                                ? regions[hover.target.region][hover.target.className]?.hover?.style
                                : {}
                            ),
                        }}>
                        {
                            hover.value
                        }
                    </div>
                }
                <svg baseProfile="tiny" fill="#6f9c76" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round"
                    strokeWidth=".5" version="1.2" viewBox="0 0 1000 570" width="100%" id="regional-map"
                    xmlns="http://www.w3.org/2000/svg">
                    {
                        Object.keys(geo).map((region, index) => {
                            return (
                                <g key={index} id={region} className="polygon" name={geo[region].name}>
                                    {
                                        geo[region].polygon && 
                                        <path
                                            d={geo[region].polygon.d}
                                            className="polygon"
                                            style={{ 
                                                ...defaults?.polygon?.style,
                                                ...regions[region]?.polygon?.style,
                                                ...hover.style[region]?.polygon?.style
                                            }}
                                            onMouseEnter={onMouseEnter}
                                            onMouseLeave={onMouseLeave}
                                            onClick={onClick}
                                            onMouseMove={onMouseMove}>
                                        </path>
                                    }
                                    {
                                        geo[region].capital && 
                                        <circle 
                                            className="capital"
                                            cx={geo[region].capital.x} cy={geo[region].capital.y} r="4"
                                            style={{
                                                ...defaults?.capital?.style,
                                                ...regions[region]?.capital?.style,
                                                ...hover.style[region]?.capital?.style
                                            }}
                                            onMouseEnter={onMouseEnter}
                                            onMouseLeave={onMouseLeave}
                                            onClick={onClick}
                                            onMouseMove={onMouseMove}>
                                        </circle>
                                    }
                                </g>
                            )
                        })
                    }
                </svg>
                {
                    popup.visible && popup.props &&
                    <Dialog
                        header={popup.header} footer={popup.footer} visible={popup.visible} draggable={false} position="bottom"
                        breakpoints={{ '576px': '100vw' }}
                        onHide={() => {if (!popup.visible) return; setPopup({
                            visible: false,
                            header: undefined,
                            body: undefined,
                            footer: undefined,
                            props: undefined
                        }); }}
                        style={(regions && regions[popup.props.region] && regions[popup.props.region][popup.props.className]
                            && regions[popup.props.region][popup.props.className].popup?.style)
                            ? regions[popup.props.region][popup.props.className].popup?.style
                            : defaults[popup.props.className]?.popup?.style
                        }>
                        {popup.body}
                    </Dialog>
                }
                {
                    defaults.polygon.popup &&
                    <>
                        <div id={`regional-map-info-tooltip-${id}`} className="regional-map-click-marker"><i className="pi pi-info-circle"></i></div>
                        <Tooltip target={`#regional-map-info-tooltip-${id}`} content={"Klikněte na kraj pro detaily"} />
                    </>
                }
            </div>
        </>
    )
}

export default RegionalMap;