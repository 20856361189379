import { classNames } from 'primereact/utils';
import React from 'react';
import { IconTooltip } from '../IconTooltip';

export const Label = ({ htmlFor, text, required, warning, info }) => {
    return (
        <label htmlFor={htmlFor} className={classNames('label', { 'label--required': required })}>
            {text}
            {required && '*'}
            {info && <IconTooltip>{info}</IconTooltip>}
            {warning && <IconTooltip warning>{warning}</IconTooltip>}
        </label>
    );
};
