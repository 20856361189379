import { addLocale, locale } from 'primereact/api';

export const initPrimeReact = () => {
    addLocale('cs', {
        firstDayOfWeek: 1,
        monthNames: [
            'Leden',
            'Únor',
            'Březen',
            'Duben',
            'Květen',
            'Červen',
            'Červenec',
            'Srpen',
            'Září',
            'Říjen',
            'Listopad',
            'Prosinec'
        ],
        dayNamesMin: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So']
    });

    locale('cs');
};
