import axios from 'axios';
import { PrimeIcons } from 'primereact/api';
import { Messages } from 'primereact/messages';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card } from '../../components/PrimereactComponents';
import { getErrorMessage } from '../../utils/errors';
import { FormContainer, FormInput, FormRow, Label } from '../components/form';
import { LineDivider } from '../components/LineDivider';
import { LoginNavMenu } from '../components/LoginNavMenu';
import { RHFInputText, RHFPassword } from '../components/rhf';
import { UZIVATEL } from '../constants/field_lengths';
import { getFormErrorMessage, maxLengthMessage } from '../utils/errors';
import { showErrorMessage } from '../utils/messages';
import { MIN_PASSWORD_LENGTH } from '../constants/constants';

export default function Register() {
    const msgs = useRef(null);

    const navigate = useNavigate();

    const [processing, setProcessing] = useState(false);

    const {
        control,
        formState: { errors },
        handleSubmit
    } = useForm({
        disabled: processing,
        defaultValues: {
            login: '',
            password: '',
            passwordConfirm: '',
            firstName: '',
            lastName: ''
        }
    });

    const onSubmit = async (data) => {
        setProcessing(true);
        const requestData = {
            login: data.login,
            password: data.password,
            first_name: data.firstName || null,
            last_name: data.lastName || null
        };
        try {
            await axios.post('register', requestData);
            navigate('/admin/login');
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(msgs, errorMessage ?? 'Nepodařilo se vytvořit nový účet.', { replace: true });
        } finally {
            setProcessing(false);
        }
    };

    return (
        <>
            <LoginNavMenu />

            <div id='body' className='body--admin'>
                <Messages ref={msgs} className='page__messages' />

                <Card className='admin-card'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormContainer type='card'>
                            <FormRow type='heading'>Registrace</FormRow>
                            <FormRow>
                                <FormInput>
                                    <Label htmlFor='login' text='Login' required />
                                    <RHFInputText
                                        control={control}
                                        name='login'
                                        rules={{
                                            required: 'Zadejte login',
                                            maxLength: {
                                                value: UZIVATEL.LOGIN,
                                                message: maxLengthMessage(UZIVATEL.LOGIN)
                                            }
                                        }}
                                        placeholder='Login'
                                    />
                                    {getFormErrorMessage(errors, 'login')}
                                </FormInput>
                            </FormRow>
                            <FormRow>
                                <FormInput>
                                    <Label htmlFor='password' text='Heslo' required />
                                    <RHFPassword
                                        control={control}
                                        name='password'
                                        rules={{
                                            required: 'Zadejte heslo',
                                            minLength: {
                                                value: MIN_PASSWORD_LENGTH,
                                                message: `Heslo musí mít alespoň ${MIN_PASSWORD_LENGTH} znaků`
                                            }
                                        }}
                                        placeholder='Heslo'
                                    />
                                    {getFormErrorMessage(errors, 'password')}
                                </FormInput>
                            </FormRow>
                            <FormRow>
                                <FormInput>
                                    <Label htmlFor='passwordConfirm' text='Zadejte heslo znovu' required />
                                    <RHFPassword
                                        control={control}
                                        name='passwordConfirm'
                                        rules={{
                                            required: 'Zadejte heslo',
                                            validate: (value, formValues) =>
                                                value === formValues.password || 'Hesla se musí shodovat'
                                        }}
                                        placeholder='Heslo'
                                    />
                                    {getFormErrorMessage(errors, 'passwordConfirm')}
                                </FormInput>
                            </FormRow>
                            <LineDivider />
                            <FormRow>
                                <FormInput>
                                    <Label htmlFor='firstName' text='Jméno' />
                                    <RHFInputText
                                        control={control}
                                        name='firstName'
                                        rules={{
                                            maxLength: {
                                                value: UZIVATEL.JMENO,
                                                message: maxLengthMessage(UZIVATEL.JMENO)
                                            }
                                        }}
                                        placeholder='Jméno'
                                    />
                                    {getFormErrorMessage(errors, 'firstName')}
                                </FormInput>
                            </FormRow>
                            <FormRow>
                                <FormInput>
                                    <Label htmlFor='lastName' text='Příjmení' />
                                    <RHFInputText
                                        control={control}
                                        name='lastName'
                                        rules={{
                                            maxLength: {
                                                value: UZIVATEL.PRIJMENI,
                                                message: maxLengthMessage(UZIVATEL.PRIJMENI)
                                            }
                                        }}
                                        placeholder='Příjmení'
                                    />
                                    {getFormErrorMessage(errors, 'lastName')}
                                </FormInput>
                            </FormRow>
                            <FormRow type='button'>
                                <Button
                                    type='submit'
                                    label='Registrovat'
                                    icon={PrimeIcons.USER_PLUS}
                                    disabled={processing}
                                    loading={processing}
                                />
                            </FormRow>
                            <FormRow type='footer'>
                                Již máte účet? <Link to='/admin/login'>Přihlašte se</Link>.
                            </FormRow>
                        </FormContainer>
                    </form>
                </Card>
            </div>
        </>
    );
}
