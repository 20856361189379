import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import NotFound from '../pages/NotFound';
import AdminApp from './components/AdminApp';
import { AuthProviderOutlet } from './components/AuthProviderOutlet';
import { ProtectedRoute } from './components/ProtectedRoute';
import { Permission, UserRole } from './constants/enums';
import { initPrimeReact } from './lib/localization';
import Login from './pages/Login';
import MeetingAdd from './pages/Meeting/MeetingAdd';
import MeetingEdit from './pages/Meeting/MeetingEdit';
import Meetings from './pages/Meeting/Meetings';
import MemberEdit from './pages/Member/MemberEdit';
import Members from './pages/Member/Members';
import Municipalities from './pages/Municipality/Municipalities';
import MunicipalityAdd from './pages/Municipality/MunicipalityAdd';
import MunicipalityEdit from './pages/Municipality/MunicipalityEdit';
import MunicipalityRequests from './pages/MunicipalityRequest/MunicipalityRequests';
import RequestNewMunicipalityResolve from './pages/MunicipalityRequest/RequestNewMunicipalityResolve';
import PoliticalEntities from './pages/PoliticalEntity/PoliticalEntities';
import Profile from './pages/Profile/Profile';
import Register from './pages/Register';
import RequestNewMunicipalityAdd from './pages/RequestNewMunicipalityAdd';
import TermAdd from './pages/Term/TermAdd';
import TermEdit from './pages/Term/TermEdit';
import Terms from './pages/Term/Terms';
import Users from './pages/User/Users';
import VoteAdd from './pages/Vote/VoteAdd';
import VoteEdit from './pages/Vote/VoteEdit';
import Votes from './pages/Vote/Votes';

export default function initApp(routes) {
    const adminRoutes = [
        {
            path: 'admin',
            element: <AuthProviderOutlet />,
            children: [
                {
                    index: true,
                    element: <Navigate to='/admin/municipality' replace />
                },
                {
                    path: 'login',
                    element: <Login />
                },
                {
                    path: 'register',
                    element: <Register />
                },
                {
                    element: <AdminApp />,
                    children: [
                        {
                            path: 'uzivatele',
                            element: <ProtectedRoute element={<Users />} role={UserRole.ADMIN} />
                        },
                        {
                            path: 'pozadavky',
                            element: <ProtectedRoute element={<MunicipalityRequests />} role={UserRole.ADMIN} />
                        },
                        {
                            path: 'profil',
                            element: <Profile />
                        },
                        {
                            path: 'nova-municipalita',
                            element: <Outlet />,
                            children: [
                                {
                                    index: true,
                                    element: <RequestNewMunicipalityAdd />
                                },
                                {
                                    path: ':requestId',
                                    element: (
                                        <ProtectedRoute
                                            element={<RequestNewMunicipalityResolve />}
                                            role={UserRole.ADMIN}
                                        />
                                    )
                                }
                            ]
                        },
                        {
                            path: 'municipality',
                            element: <Outlet />,
                            children: [
                                {
                                    index: true,
                                    element: <Municipalities />
                                },
                                {
                                    path: 'new',
                                    element: <MunicipalityAdd />
                                },
                                {
                                    path: ':municipalityId',
                                    element: (
                                        <ProtectedRoute
                                            element={<Outlet />}
                                            permission={Permission.MANAGE_MUNICIPALITY}
                                        />
                                    ),
                                    children: [
                                        {
                                            index: true,
                                            element: <Municipalities />
                                        },
                                        {
                                            path: 'edit',
                                            element: <MunicipalityEdit />
                                        },
                                        {
                                            path: 'subjekty',
                                            element: <PoliticalEntities />
                                        },
                                        {
                                            path: 'funkcni-obdobi',
                                            element: <Outlet />,
                                            children: [
                                                {
                                                    index: true,
                                                    element: <Terms />
                                                },
                                                {
                                                    path: 'new',
                                                    element: <TermAdd />
                                                },
                                                {
                                                    path: ':termId',
                                                    element: <Outlet />,
                                                    children: [
                                                        {
                                                            index: true,
                                                            element: <TermEdit />
                                                        },
                                                        {
                                                            path: 'zasedani',
                                                            element: <Outlet />,
                                                            children: [
                                                                {
                                                                    index: true,
                                                                    element: <Meetings />
                                                                },
                                                                {
                                                                    path: 'new',
                                                                    element: <MeetingAdd />
                                                                },
                                                                {
                                                                    path: ':meetingId',
                                                                    element: <Outlet />,
                                                                    children: [
                                                                        {
                                                                            index: true,
                                                                            element: <MeetingEdit />
                                                                        },
                                                                        {
                                                                            path: 'hlasovani',
                                                                            element: <Outlet />,
                                                                            children: [
                                                                                {
                                                                                    index: true,
                                                                                    element: <Votes />
                                                                                },
                                                                                {
                                                                                    path: 'new',
                                                                                    element: <VoteAdd />
                                                                                },
                                                                                {
                                                                                    path: ':voteId',
                                                                                    element: <VoteEdit />
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            path: 'clenove',
                                            element: <Outlet />,
                                            children: [
                                                {
                                                    index: true,
                                                    element: <Members />
                                                },
                                                {
                                                    path: ':memberId',
                                                    element: <MemberEdit />
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    path: '*',
                    element: <NotFound />
                }
            ]
        }
    ];

    routes.push(adminRoutes[0]);

    initPrimeReact();
}
