import { useState, useEffect } from 'react';

function windowSize() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    return { width, height };
}

export default function useDimensions() {
    const [dimensions, setDimensions] = useState(windowSize());

    useEffect(() => {
        function handleChange() {
            setDimensions(windowSize());
        }

        window.addEventListener('resize', handleChange);
        return () => window.removeEventListener('resize', handleChange);
    }, []);

    return dimensions;
}