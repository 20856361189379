import React from 'react';
import { DataTable, Column, Dropdown } from '../../../../components/PrimereactComponents';
import { ButtonDelete, ButtonEdit } from '../../../components/Buttons';
import { formatPoliticalEntityName } from '../../../utils/format';
import { Link, useParams } from 'react-router-dom';
import { ATTENDANCE_OPTIONS } from '../../../constants/constants';
import { IconTooltip } from '../../../components/IconTooltip';

export const MeetingEditMemberTable = ({
    members,
    disabled,
    onClickChangePoliticalEntity,
    onClickRemoveMember,
    onAttendanceChange
}) => {
    const { municipalityId } = useParams();

    const MemberLink = ({ id, children }) => {
        return <Link to={`/admin/municipality/${municipalityId}/clenove/${id}`}>{children}</Link>;
    };

    const idTemplate = (rowData) => {
        return <MemberLink id={rowData.id}>{rowData.id}</MemberLink>;
    };

    const firstNameTemplate = (rowData) => {
        return <MemberLink id={rowData.id}>{rowData.first_name}</MemberLink>;
    };

    const lastNameTemplate = (rowData) => {
        return <MemberLink id={rowData.id}>{rowData.last_name}</MemberLink>;
    };

    const politicalEntityTemplate = (rowData) => {
        return formatPoliticalEntityName(rowData.political_entity);
    };

    const attendanceTemplate = (rowData) => {
        return (
            <Dropdown
                value={rowData.attendance}
                key={`${rowData.id}-attendance`}
                options={ATTENDANCE_OPTIONS}
                onChange={(e) => onAttendanceChange(e, rowData.id)}
                disabled={disabled}
            />
        );
    };

    const buttonsTemplate = (rowData) => {
        return (
            <div className='table-buttons'>
                <ButtonEdit
                    rounded
                    title='Změnit politický subjekt'
                    icon='bi bi-person-workspace'
                    onClick={() => onClickChangePoliticalEntity(rowData)}
                    disabled={disabled}
                />
                <ButtonDelete
                    rounded
                    title='Odebrat člena'
                    onClick={() => onClickRemoveMember(rowData)}
                    disabled={disabled}
                />
            </div>
        );
    };

    return (
        <DataTable
            value={members}
            removableSort
            sortMode='multiple'
            multiSortMeta={[{ field: 'last_name', order: 1 }]}
            breakpoint='900px'
        >
            <Column field='id' header='ID' body={idTemplate} sortable />
            <Column field='first_name' header='Jméno' body={firstNameTemplate} sortable />
            <Column field='last_name' header='Příjmení' body={lastNameTemplate} sortable />
            <Column
                header='Politický subjekt'
                body={politicalEntityTemplate}
                sortField='political_entity.abbreviation'
                sortable
            />
            <Column
                header={
                    <>
                        Přítomnost
                        <IconTooltip>
                            Přítomnost se automaticky vypočítává podle přidaných hlasování. Změna hodnoty slouží pouze
                            pro případnou korekci již zcela vyplněného zasedání.
                        </IconTooltip>
                    </>
                }
                body={attendanceTemplate}
            />
            <Column body={buttonsTemplate} align='right' />
        </DataTable>
    );
};
