import { Messages } from 'primereact/messages';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, FormInput, FormRow } from '../../../components/form';
import { LineDivider } from '../../../components/LineDivider';
import { getFormErrorMessage } from '../../../utils/errors';
import { ButtonClose, ButtonDelete } from '../../../components/Buttons';
import { PrimeIcons } from 'primereact/api';
import { RHFPassword } from '../../../components/rhf';
import { ModalDialog } from '../../../components/ModalDialog';

export const ConfirmDeletePoliticalEntityDialog = ({ politicalEntity, isVisible, onHide, onSubmit, msgs }) => {
    const [processing, setProcessing] = useState(false);

    const defaultValues = {
        password: ''
    };

    const {
        control,
        formState: { errors, isSubmitting, isSubmitSuccessful },
        handleSubmit,
        reset
    } = useForm({
        disabled: processing,
        defaultValues: defaultValues
    });

    useEffect(() => {
        setProcessing(isSubmitting);
    }, [isSubmitting]);

    useEffect(() => {
        // Is always true for async onSubmit, clears the password after every submit
        if (isSubmitSuccessful) {
            reset();
        }
    }, [isSubmitSuccessful, reset]);

    useEffect(() => {
        if (!isVisible) {
            reset();
        }
    }, [isVisible, reset]);

    return (
        <ModalDialog
            visible={isVisible}
            header='Potvrďte smazání'
            style={{ width: 400 }}
            footer={
                <>
                    <ButtonClose label='Ne' onClick={onHide} />
                    <ButtonDelete label='Smazat' onClick={handleSubmit(onSubmit)} />
                </>
            }
            onHide={onHide}
        >
            <Messages ref={msgs} className='flex-center' />
            {politicalEntity && (
                <>
                    <div className='dialog-text'>
                        <span className='dialog-text__warning'>
                            <i className={PrimeIcons.EXCLAMATION_TRIANGLE} />
                            <span>
                                Opravdu chcete smazat politický subjekt{' '}
                                <b>{politicalEntity.name || politicalEntity.abbreviation}</b>?
                            </span>
                        </span>
                    </div>
                    <LineDivider />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormContainer type='dialog'>
                            <FormRow>
                                <FormInput>
                                    <RHFPassword
                                        control={control}
                                        name='password'
                                        rules={{ required: 'Zadejte heslo' }}
                                        placeholder='Potvrďte zadáním hesla'
                                    />
                                    {getFormErrorMessage(errors, 'password')}
                                </FormInput>
                            </FormRow>
                        </FormContainer>
                    </form>
                </>
            )}
        </ModalDialog>
    );
};
