import React from "react";

import "./DatasetList.css"

const simplifyUrl = (url) => {
    const l = document.createElement("a");
    l.href = url;
    return l.hostname;
}

function DatasetList({
    datasets=undefined,
}) {

    return (
        <>
            <div className="dataset-list">
                <table>
                    <thead>
                        <tr>
                            <th>Datová sada</th>
                            <th>Zdroj</th>
                            <th>Poslední aktualizace</th>
                            <th>Poznámka</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        datasets && datasets.data && datasets.getLabel && datasets.getUrl && datasets.getSource &&
                        Object.values(datasets.data).map((data, index) => {
                            return (
                                <tr key={index}>
                                    <th className="dataset-list-label">
                                        <a href={datasets.getUrl(data)}>
                                            <i className="pi pi-download"></i>
                                            <span>{datasets.getLabel(data)}</span>
                                        </a>
                                    </th>
                                    <td className="dataset-list-source">
                                        <a href={datasets.getSource(data)}>
                                            <i className="pi pi-"></i>
                                            <span>{simplifyUrl(datasets.getSource(data))}</span>
                                        </a>
                                    </td>
                                    <td className="dataset-list-update">
                                        {
                                            datasets.lastUpdate &&
                                            <span>{datasets.lastUpdate(data)}</span>
                                        }
                                    </td>
                                    <td className="dataset-list-note">
                                        {
                                            datasets.isComplete && !datasets.isComplete(data) &&
                                            <span>
                                                Data nejsou kompletní.
                                            </span>
                                        }
                                        {
                                            datasets.getComment && datasets.getComment(data) &&
                                            <span>
                                                <i className="pi pi-info-circle" title={datasets.getComment(data)}></i>
                                            </span>
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default DatasetList;