import React from 'react';
import { MunicipalityBreadCrumb } from '../../../components/MunicipalityBreadCrumb';
import { useParams } from 'react-router-dom';

/**
 * @param {object} params
 * @param {{label: string, url?: string}[]} params.extraItems
 */
export const TermBreadCrumb = ({ extraItems }) => {
    const { municipalityId } = useParams();

    const items = [];

    if (municipalityId) {
        items.push({
            label: 'Funkční období',
            url: `/admin/municipality/${municipalityId}/funkcni-obdobi`
        });
    }

    if (extraItems) {
        items.push(...extraItems);
    }

    return <MunicipalityBreadCrumb extraItems={items} />;
};
