import { MultiSelect } from 'primereact/multiselect';
import { Controller } from 'react-hook-form';

/**
 * @param {object} params
 * @param {import('react-hook-form').UseFormRegister<any>} params.control
 * @param {string} params.name
 * @param {import('react-hook-form').RegisterOptions} params.rules
 * @param {string} params.placeholder
 * @param {import('primereact/multiselect').MultiSelectProps} params.inputProps
 */
export const RHFMultiSelect = ({ control, name, rules, placeholder, inputProps }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
                <MultiSelect
                    id={field.name}
                    {...field}
                    placeholder={placeholder}
                    display='chip'
                    showClear={true}
                    {...inputProps}
                />
            )}
        />
    );
};
