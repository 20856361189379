import React from "react";

import "./StatsGrid.css";
import StatsSection from "./StatsSection.js";

function StatsGrid({
    stats
}) {

    return (
        <>
            <div className="stats-grid">
                {
                    stats && stats.map((stat, index) => {
                        if(stat) {
                            return (
                                <StatsSection key={index}
                                    title={stat.title}
                                    content={stat.content}
                                    chart={stat.chart}
                                    widget={stat.widget}
                                    footer={stat.footer}>
                                </StatsSection>
                            )
                        }
                    })
                }
            </div>
        </>
    )
}

export default StatsGrid;