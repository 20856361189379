import React, { useState, useRef } from 'react';
import { Link, useParams, useLoaderData } from 'react-router-dom';
import {
    Chip,
    Tooltip,
    Card,
    TabView,
    TabPanel,
    Fieldset,
    Button,
    OverlayPanel,
    DataTable,
    Column
} from '../../components/PrimereactComponents';
import { redirectErrorPage } from '../../utils/errors';
import ChartComponent from '../../components/ChartComponent';
import useDimensions from '../../hooks/useDimensions';
import { useTranslation } from 'react-i18next';
import { formatMemberName } from '../../utils/texts';
import { AliasTooltip } from '../../components/AliasTooltip';

const VotingDetail = () => {
    const [activeTab, setActiveTab] = useState(undefined);
    const { width } = useDimensions();

    const { zastupitelstvoId, municipalitaId } = useParams();

    const { t } = useTranslation();

    const op_att = useRef();

    const voteData = useLoaderData();
    const voteOptions = { ano: 1, ne: 2, 'zdržel se': 3, nehlasoval: 4, 'nepřít.': 5, omluven: 6, tajná: 7 }

    if (voteData !== undefined) {
        if (JSON.stringify(voteData) === '{"error":"neexistujici ID"}') {
            redirectErrorPage(municipalitaId, zastupitelstvoId);
        } else {
            var style = getComputedStyle(document.body);
            const colors = [
                style.getPropertyValue('--clr-1'),
                style.getPropertyValue('--clr-2'),
                style.getPropertyValue('--clr-3'),
                style.getPropertyValue('--clr-4'),
                style.getPropertyValue('--clr-5'),
                style.getPropertyValue('--clr-6')
            ];
            const voteColors = {
                ano: colors[0],
                ne: colors[1],
                'zdržel se': colors[2],
                nehlasoval: colors[3],
                'nepřít.': colors[4],
                tajná: colors[5],
                omluven: colors[6]
            };
            let summaryVotesLabels = Object.keys(voteData.sumarizace);
            let summaryVotesValues = Object.values(voteData.sumarizace);

            let datasetsDataSummaryVotes = summaryVotesValues.map((item, index) => ({
                type: 'bar',
                label: summaryVotesLabels[index],
                backgroundColor: voteColors[summaryVotesLabels[index]],
                data: [item]
            }));

            datasetsDataSummaryVotes.sort((a, b) => {
                return Object.keys(voteOptions).indexOf(a.label) - Object.keys(voteOptions).indexOf(b.label);
            });

            let summaryVotesData = {
                labels: [''],
                datasets: datasetsDataSummaryVotes
            };

            const handleBarClick = (evt, item) => {
                if (item[0] !== undefined) {
                    let actItem = summaryVotesData.datasets[item[0].datasetIndex].label;
                    let option_id = summaryVotesLabels.indexOf(actItem);
                    setActiveTab(option_id);
                }
            };

            const handleBarHover = (evt, item) => {
                if (item[0] !== undefined) {
                    evt.native.target.style.cursor = 'pointer';
                } else {
                    evt.native.target.style.cursor = 'default';
                }
            };

            const stackedOptions = {
                indexAxis: 'y',
                maintainAspectRatio: false,
                aspectRatio: 0.8,
                plugins: {
                    tooltips: {
                        mode: 'index',
                        intersect: false
                    },
                    legend: {
                        onHover: function (evt, item, legend) {
                            const tooltip = legend.chart.tooltip;
                            if (tooltip.getActiveElements().length > 0) {
                                tooltip.setActiveElements([], { x: 0, y: 0 });
                            }

                            tooltip.setActiveElements([{ datasetIndex: item.datasetIndex, index: 0 }], { x: 0, y: 0 });

                            evt.native.target.style.cursor = 'pointer';
                            legend.chart.data.datasets.forEach((i, id) => {
                                if (id !== item.datasetIndex) {
                                    i.backgroundColor += '30';
                                }
                            });
                            legend.chart.update();
                        },
                        onLeave: function (evt, item, legend) {
                            legend.chart.data.datasets.forEach((i, id) => {
                                if (id !== item.datasetIndex) {
                                    i.backgroundColor = i.backgroundColor.slice(0, -2);
                                }
                            });
                            legend.chart.update();
                        }
                    }
                },
                scales: {
                    x: {
                        stacked: true
                    },
                    y: {
                        stacked: true,
                        position: 'right'
                    }
                },
                onHover: handleBarHover,
                onClick: handleBarClick
            };

            return (
                <div className='voting-detail'>
                    <h1 className='voting-detail-title'>
                        <span className='text__semibold'>
                            {t('meeting-no')}&nbsp;{voteData.cislo_zasedani} –&nbsp;
                        </span>
                        {t('voting-no')}&nbsp;{voteData.cislo_hlasovani}
                        {voteData.validni_hlasovani === 0 ? (
                            voteData.tajne_hlasovani === 1 ?
                                <Chip label={t('invalid-secret-voting')} className='bg__secondary text__white' /> :
                                <Chip label={t('invalid-voting')} className='bg__secondary text__white' />
                        ) : (
                            ''
                        )}
                        {voteData.validni_hlasovani === 1 && voteData.tajne_hlasovani === 1 ? (
                            <Chip label={t('secret-voting')} className='bg__secondary text__white' />
                        ) : (
                            ''
                        )}
                        {voteData.proceduralni_hlasovani === 1 ? (
                            <Chip label={t('procedural-voting')} className='bg__primary text__white' />
                        ) : (
                            ''
                        )}
                    </h1>
                    <div className='subtitle'>
                        <h3 className='text__center text__balance text__normal text__primary'>
                            {voteData.predmet_hlasovani}
                        </h3>
                    </div>

                    <div className='voting-detail-grid'>
                        <Card className='info-card' title={t('basic-info')}>
                            <div className='grid'>
                                <div key='datum'>
                                    <i className='pi bi-calendar info-icon text-icon__left text__primary'></i>
                                    <span>{voteData.datum_cas}</span>
                                </div>
                                <div key='vysledek'>
                                    <i
                                        className={
                                            'bi text-icon__left ' +
                                            (voteData.vysledek === 'přijato' ? 'bi-check-square' : 'bi-x-square')
                                        }
                                    ></i>
                                    <span
                                        className={voteData.vysledek === 'přijato' ? 'text__success' : 'text__danger'}
                                    >
                                        {t(voteData.vysledek)}
                                    </span>
                                </div>
                                {voteData.soubor !== '' ? (
                                    <div key='protokol'>
                                        <a
                                            href={voteData.soubor}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='voting-detail__item-protocol-primary'
                                        >
                                            <i className='pi pi-file voting-detail__item-icon text__primary text-icon__left'></i>
                                            <span>{t('voting-protocol')}</span>
                                        </a>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {voteData.proceduralni_hlasovani === 1 ? (
                                    <div>
                                        <i className='pi bi-gear-fill text__primary text-icon__left'></i>
                                        <span>{t('procedural-voting')}</span>
                                        <Tooltip target='.custom-target-icon' />
                                        <i
                                            className='custom-target-icon pi bi-info-circle text-icon__right text__secondary'
                                            data-pr-tooltip={t('tooltip-voting-attendance')}
                                            data-pr-position='bottom'
                                        ></i>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                <div key='hlasovani'>
                                    <i className='pi pi-users text-icon__left text__primary'></i>
                                    <span className='text__primary'> {voteData.pritomno} </span> {t('members-present')}
                                    <Tooltip target='.custom-target-icon2' />
                                    <i
                                        className='custom-target-icon2 pi bi-info-circle text-icon__right text__secondary'
                                        data-pr-tooltip='Hlasujících prezenčně nebo distančně (přes virtuálního klienta)'
                                        data-pr-position='bottom'
                                    ></i>
                                </div>
                            </div>
                        </Card>

                        <Card
                            className='votings-summary-card'
                            title={
                                <span className='card-title-w-btn'>
                                    <span>
                                        {t('votings-summary')}
                                        <i
                                            className='custom-target-icon3 pi bi-info-circle text__secondary text-icon__right'
                                            data-pr-tooltip={t('tooltip-chart')}
                                            data-pr-position='right'
                                            data-pr-at='right+5 top'
                                            data-pr-my='left center-2'
                                        ></i>
                                    </span>
                                    <Button
                                        className='table-view-icon'
                                        icon='bi bi-table'
                                        onClick={(e) => op_att.current.toggle(e)}
                                        alt='Zobrazit tabulku'
                                        aria-label='Detail'
                                    />
                                </span>
                            }
                        >
                            <Tooltip target='.custom-target-icon3' />
                            <OverlayPanel ref={op_att}>
                                <DataTable responsiveLayout='false' value={summaryVotesData.datasets}>
                                    <Column field='label' header={t('voting-option')} />
                                    <Column field='data' header={t('count')} />
                                </DataTable>
                            </OverlayPanel>
                            <div className='grid'>
                                <div className='item-chart'>
                                    <ChartComponent
                                        type='bar'
                                        data={summaryVotesData}
                                        options={stackedOptions}
                                        height='300px'
                                        width='100%'
                                    />
                                </div>
                                <div className='item-list'>
                                    <TabView
                                        scrollable={width < 576}
                                        activeIndex={activeTab}
                                        onTabChange={(e) => setActiveTab(e.index)}
                                    >
                                        {Object.keys(voteData.sumarizace_po_stranach).map((option) => (
                                            <TabPanel key={option} header={option}>
                                                {voteData.sumarizace_po_stranach[option].map((party, i) => (
                                                    <>
                                                        <Fieldset
                                                            collapsed={true}
                                                            legend={
                                                                <>
                                                                    <Link
                                                                        className='representatives-list-title'
                                                                        style={{ color: party.strana_barva }}
                                                                        to={`../../subjekty/${party.strana_id}`}
                                                                        key={party.strana_id}
                                                                    >
                                                                        {party.strana_zkratka}
                                                                    </Link>{' '}
                                                                    –{' '}
                                                                    <span
                                                                        className='text__semibold'
                                                                        style={{ color: party.strana_barva }}
                                                                    >
                                                                        {party.pocet}
                                                                    </span>{' '}
                                                                    {t('vote', { count: party.pocet })}
                                                                </>
                                                            }
                                                            toggleable
                                                        >
                                                            <ul className='representatives-list__grid'>
                                                                {party.lide.map((member) => (
                                                                    <li
                                                                        key={member.id}
                                                                        className='representatives-list-cell bullet__primary'
                                                                    >
                                                                        <Link
                                                                            to={`../../zastupitele/${member.id}`}
                                                                            key={member.id}
                                                                            className='text__black'
                                                                        >
                                                                            {formatMemberName(
                                                                                member.jmeno,
                                                                                member.prijmeni
                                                                            )}
                                                                        </Link>

                                                                        {member.aliasy && (
                                                                            <AliasTooltip
                                                                                id={`member-${member.id}-aliases`}
                                                                                aliases={member.aliasy}
                                                                            />
                                                                        )}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </Fieldset>
                                                    </>
                                                ))}
                                            </TabPanel>
                                        ))}
                                    </TabView>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            );
        }
    }
};

export default VotingDetail;
