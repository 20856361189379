import { InputTextarea } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';
import React from 'react';
import { Controller } from 'react-hook-form';

/**
 * @param {object} params
 * @param {import('react-hook-form').UseFormRegister<any>} params.control
 * @param {string} params.name
 * @param {import('react-hook-form').RegisterOptions} params.rules
 * @param {string} params.placeholder
 * @param {import('primereact/inputtextarea').InputTextareaProps} params.inputProps
 */
export const RHFInputTextarea = ({ control, name, rules, placeholder, inputProps }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => (
                <InputTextarea
                    id={field.name}
                    {...field}
                    className={classNames({ 'p-invalid': fieldState.invalid })}
                    placeholder={placeholder}
                    autoResize={true}
                    {...inputProps}
                />
            )}
        />
    );
};
