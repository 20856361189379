import PopupBodyChart from "../../../../components/map/popup/PopupBodyChart";
import PopupFooter from "../../../../components/map/popup/PopupFooter";
import PopupHeader from "../../../../components/map/popup/PopupHeader";
import APP from "../../../config";
import data from "../data";
import geo from "../geo";
import meta from "../meta";

/**
 * Share of people statistics.
 * 
 * @author Kristyna Zaklova
 */
const genericPopup = {
    header: {
        component: PopupHeader,
        props: {
            data: data
        }
    },
    body: {
        component: PopupBodyChart,
        props: {
            data: data,
            stats: [
                {
                    title: "Počet členů zastupitelstev",
                    content: () => {
                        return (
                            <>
                                <p>Počet členů zastupitelstva se v jednotlivých krajích pohybuje od <span className="emp"><strong>45</strong></span> do <span className="emp"><strong>65</strong></span>, a to dle definice v Zákoně č. 129/2000 Sb. (Zákon o krajích) v závislosti na počtu obyvatel kraje.</p>
                            </>
                        )
                    },
                    chart: {
                        type: "bar",
                        config: {
                            geo: geo,
                            data: data,
                            categories: meta.pocetZastupitelu.values,
                            getValue: meta.pocetZastupitelu.getValue,
                            formatValue: meta.pocetZastupitelu.formatValue
                        }
                    }
                }
            ]
        }
    },
    footer: {
        component: PopupFooter,
        props: {
            data: data,
            settings: {
                left: {
                    title: {
                        label: "Zdroj:",
                        icon: "pi pi-fw pi-globe"
                    },
                    links: [
                        {
                            label: "Datové sady",
                            value: `${APP.protocol}://kraje.${APP.domain}${APP.port && ':' + APP.port}/datasety`
                        }
                    ]
                },
                right: {
                    title: undefined,
                    links: [
                        meta.route
                    ]
                }
            }
        }
    },
    style: {
        width: '80vw',
        height: '100vh',
        maxWidth: '1200px',
    }
}

const peopleStats = {
    title: 'Členové zastupitelstev',
    content: () => {
        return (
            <>
                Krajská zastupitelstva v&nbsp;České republice se skládají z&nbsp;volených zástupců, jejichž počet se liší podle velikosti a&nbsp;počtu obyvatel jednotlivých krajů. 
                Počet členů zastupitelstva je stanoven zákonem a&nbsp;pohybuje se od <span className="emp"><strong>45</strong></span> do <span className="emp"><strong>65</strong> zastupitelů</span>. 
                Tento počet je navržen tak, aby bylo zajištěno, že každý kraj má dostatečné zastoupení pro efektivní rozhodování o&nbsp;klíčových záležitostech regionálního rozvoje, přičemž je zachována přiměřená reprezentace obyvatel.
            </>
        )
    },
    chart: {
        id: "mapa-clenove-zastupitelstev",
        type: "regional-choropleth",
        config: {
            geo: geo,
            data: data,
            categories: meta.pocetZastupitelu.values,
            getValue: meta.pocetZastupitelu.getValue,
            formatValue: meta.pocetZastupitelu.formatValue,
            map: {
                defaults: {
                    polygon: {
                        hoverStyle: {
                            fill: "yellow"
                        },
                        popup: {
                            header: {
                                component: PopupHeader,
                                props: {
                                    data: data
                                }
                            },
                            body: {
                                component: PopupBodyChart,
                                props: {
                                    data: data,
                                    stats: [
                                        {
                                            title: "Srovnání dle počtu členů zastupitelstev ve volebním období 2020-2024 (s výjimkou Prahy)",
                                            content: () => {
                                                return (
                                                    <>
                                                        <p>Počet členů zastupitelstva se v jednotlivých krajích pohybuje od <span className="emp"><strong>45</strong></span> do <span className="emp"><strong>65</strong></span>, a to dle definice v Zákoně č. 129/2000 Sb. (Zákon o krajích) v závislosti na počtu obyvatel kraje.</p>
                                                    </>
                                                )
                                            },
                                            chart: {
                                                type: "bar",
                                                config: {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.pocetZastupitelu.values,
                                                    getValue: meta.pocetZastupitelu.getValue,
                                                    formatValue: meta.pocetZastupitelu.formatValue
                                                }
                                            }
                                        }
                                    ]
                                }
                            },
                            footer: {
                                component: PopupFooter,
                                props: {
                                    data: data,
                                    settings: {
                                        left: {
                                            title: {
                                                label: "Zdroje:",
                                                icon: "pi pi-fw pi-globe"
                                            },
                                            links: [
                                                {
                                                    ...meta.url,
                                                    label: "Web kraje",
                                                },
                                                {
                                                    ...meta.urlHlasovaciData,
                                                    label: "Hlasovací data kraje",
                                                }
                                            ]
                                        },
                                        right: {
                                            title: undefined,
                                            links: [
                                                meta.route
                                            ]
                                        }
                                    }
                                }
                            },
                            style: {
                                width: '80vw',
                                height: '100vh',
                                maxWidth: '1200px',
                            }
                        }
                    },
                    capital: {
                        style: {
                            display: "none"
                        }
                    }
                }
            }
        }
    },
    footer: {
        left: {
            title: {
                label: "Zdroj:",
                icon: "pi pi-fw pi-globe"
            },
            links: [
                {
                    label: "Datové sady",
                    value: `${APP.protocol}://kraje.${APP.domain}${APP.port && ':' + APP.port}/datasety`
                }
            ]
        },
        right: {
            title: undefined,
            links: [
                meta.route
            ]
        },
        popup: {
            component: genericPopup
        }
    }
}
export default peopleStats;