import home_brno from "./brno/home";
import home_dev from "./dev/home";
import home_default from "./default/home";
import home_kraje from "./kraje/home";
import home_mesta from "./mesta/home";
import home_most from "./most/home";
import home_zastupko from "./zastupko/home";
import home_praha from "./praha/home";
import APP from "../config";

const getHome = (response) => {
    switch(APP.subdomain) {
        case "":
            return home_zastupko(response);
        case "brno":
            return home_brno(response);
        case "dev":
            return home_dev(response);
        case "kraje":
            return home_kraje(response);
        case "mesta":
            return home_mesta(response);
        case "most":
            return home_most(response);
        case "praha":
            return home_praha(response);
        default:
            return home_default(response);
    }
}

const home = (response) => getHome(response);
export default home;