import { mesta } from "./mesta"

export const partneri = {
    brno: mesta.brno,
    most: mesta.most
}

export const partneriList = [
    partneri.brno,
    partneri.most
]