import commonStats from "./stats/common";
import transparencyStats from "./stats/transparency";
import womenStats from "./stats/women";
import menStats from "./stats/men";
import peopleStats from "./stats/people";
import subjectsStats from "./stats/subjects";
import peopleSubjectsStats from "./stats/peopleSubjects";
import sittingsStats from "./stats/sittings";
import votingsStats from "./stats/votings"
import votingsPerSittings from "./stats/votingsPerSitting";
import votingsRatio from "./stats/votingsRatio";
import salaryStats from "./stats/salary";
import unemploymentStats from "./stats/unemployment";
import incomeStats from "./stats/income";
import outcomeStats from "./stats/outcome";
import saldoStats from "./stats/saldo";
import incomePerCitizen from "./stats/incomePerCitizen";
import outcomePerCitizen from "./stats/outcomePerCitizen";
import crimePerCitizen from "./stats/crimesPerCitizen";
import crimes from "./stats/crimes";
import APP from "../../config";
import populationStats from "./stats/population";
import leaderGenderStats from "./stats/leaderGender";
import salaryMemberStats from "./stats/salaryMember";

export const mainDescriptionBrief = (
    <p>
        Krajská zastupitelstva v České republice hrají klíčovou roli v&nbsp;řízení a&nbsp;rozvoji jednotlivých regionů.
        Jsou zodpovědná za široké spektrum oblastí, od školství a&nbsp;zdravotnictví až po dopravu a&nbsp;životní prostředí.
        Rozhodnutí, která zastupitelstva přijímají, mají přímý dopad na kvalitu života obyvatel.
        Proto je důležité analyzovat a&nbsp;porozumět tomu, jak jsou tato rozhodnutí přijímána, kdo je činí a&nbsp;jaké faktory je ovlivňují.
        Právě za tímto účelem vznikly následující analýzy funkčního období <span style={{ color: "var(--primary-color)", fontSize: "larger" }}><strong>2020–2024</strong></span>.
    </p>
)

export const mainDescription = (
    <>
        <p>
            V&nbsp;pátek <span style={{ color: "#ff1a42", fontSize: "larger" }}><strong>20. září</strong></span> a&nbsp;v&nbsp;sobotu <span style={{ color: "#ff1a42", fontSize: "larger" }}><strong>21. září 2024</strong>
            </span> se uskuteční <span style={{ color: "#ff1a42", fontSize: "larger" }}><strong>volby</strong> do zastupitelstev krajů</span>, nebuďte lhostejní a&nbsp;přijďte volit!
        </p>
        {mainDescriptionBrief}
        <p>
            Pro úplnost bylo do analýz zahrnuto i&nbsp;<span style={{ color: "var(--primary-color)", fontSize: "larger" }}>hlavní město <strong>Praha</strong></span>, nicméně do Zastupitelstva hlavního města Prahy probíhají volby v&nbsp;rámci voleb do zastupitelstev obcí a jeho funkční období trvá v letech <span style={{ color: "var(--primary-color)", fontSize: "larger" }}><strong>2022–2026</strong></span>.
        </p>
        <p>
            Analyzování dat z činnosti krajských zastupitelstev umožňuje odhalit trendy a vzorce, které poskytují hlubší vhled do politického procesu.
            Publikování otevřených dat ve strojově čitelných formátech, jako jsou CSV nebo JSON, je klíčové pro jejich další využití a&nbsp;zpracování.
            Kvalitní data podporují inovace, zvyšují transparentnost a&nbsp;přispívají k lepší informovanosti občanů, což posiluje důvěru ve veřejné instituce.
            Ne všechna zdrojová data byla však natolik kvalitní, abychom mohli zaručit 100% správnost níže uvedených informací, více informací o&nbsp;kvalitě dat viz v&nbsp;sekci <a href={`${APP.protocol}://kraje.${APP.domain}${APP.port && ':' + APP.port}/datasety`}>Datasety</a>.
        </p>
    </>
)

/**
 * Definition of statistics which should be displayed in the Stats page
 * 
 * @author Jiri Hynek, Kristyna Zaklova
 */
const stats = (repsonse) => {
    return {
        id: "home",
        sections: [
            {
                id: "intro",
                noAnchor: true,
                content: {
                    type: "widget-group",
                    config: {
                        style: {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "2rem"
                        },
                        widgets: [
                            {
                                type: "custom",
                                config: {
                                    style: {
                                        textAlign: "justify",
                                        maxWidth: "1200px"
                                    },
                                    content: mainDescription
                                }
                            }
                            , {
                                type: "stats-grid",
                                config: {
                                    stats: [
                                        commonStats,
                                        transparencyStats,
                                        peopleStats,
                                        salaryMemberStats
                                    ]
                                }
                            }
                        ]
                    }
                }
            },

            {
                id: "politicke-subjekty",
                heading: {
                    label: "Politické subjekty",
                },
                content: {
                    type: "widget-group",
                    config: {
                        style: {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "2rem"
                        },
                        widgets: [
                            {
                                type: "custom",
                                config: {
                                    style: {
                                        textAlign: "justify",
                                        maxWidth: "1200px"
                                    },
                                    content: (
                                        <>
                                            <p>
                                                <span style={{ color: "var(--primary-color)", fontSize: "larger" }}><strong>Politické subjekty</strong></span> hrají v&nbsp;krajských zastupitelstvech klíčovou roli, protože zastupují různé politické a&nbsp;ideologické proudy v&nbsp;regionálním řízení. 
                                                Politický subjekt je termín zahrnující jak <span style={{ color: "var(--primary-color)", fontSize: "larger" }}>samostatné politické strany</span>, tak <span style={{ color: "var(--primary-color)", fontSize: "larger" }}>koalice stran</span>, které se zformovaly již před volbami. 
                                                Tyto subjekty mohou mít různou ideologickou orientaci a spoluprací či soupeřením ovlivňují podobu politiky v kraji. 
                                                V&nbsp;praxi se tedy politický subjekt může skládat buď z jedné strany, nebo z několika spojených stran, které se dohodly na spolupráci již v&nbsp;rámci kandidatury ve volbách, aby získaly širší podporu voličů a&nbsp;větší zastoupení v&nbsp;krajském zastupitelstvu.
                                            </p>
                                            <p>    
                                                Zastupitelé mohou během funkčního období <span style={{ color: "var(--primary-color)", fontSize: "larger" }}>měnit příslušnost</span> k subjektu. 
                                                Nicméně ne u&nbsp;všech zastupitelstev je možné tyto změny analyzovat –⁠⁠⁠⁠⁠⁠ buď nejsou data o&nbsp;jednotlivých hlasováních k&nbsp;dispozici vůbec, nebo tato data neobsahují informace o&nbsp;příslušnosti zastupitelů k&nbsp;politickým subjektům.
                                                U&nbsp;subjektů, které jsou tvořeny koalicemi politických stran, je v některých případech rovněž obtížněji dohledatelná "domovská" politická strana konkrétních zastupitelů.
                                            </p>
                                        </>
                                    )
                                }
                            }
                            ,{ 
                                type: "stats-grid",
                                config: {
                                    stats: [
                                        subjectsStats, 
                                        peopleSubjectsStats
                                    ]
                                }
                            }
                        ]
                    }
                }
            },
            {
                id: "cinnost-zastupitelstev",
                heading: {
                    label: "Činnost zastupitelstev",
                },
                content: {
                    type: "widget-group",
                    config: {
                        style: {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "2rem"
                        },
                        widgets: [
                            {
                                type: "custom",
                                config: {
                                    style: {
                                        textAlign: "justify",
                                        maxWidth: "1200px"
                                    },
                                    content: (
                                        <>
                                            <p>
                                                Činnost krajských zastupitelstev je klíčová pro správu a&nbsp;rozvoj jednotlivých regionů. 
                                                <span style={{ color: "var(--primary-color)", fontSize: "larger" }}><strong> Transparentnost</strong></span> jejich rozhodování závisí na tom, jakým způsobem a&nbsp;v&nbsp;jakém rozsahu zveřejňují tyto informace.
                                                Různorodost dat, která zastupitelstva poskytují, může zahrnovat zápisy ze zasedání, výsledky hlasování, rozpočtové dokumenty a další důležité podklady.
                                                Tato data jsou zásadní pro veřejnou kontrolu a&nbsp;zajištění informovanosti občanů.
                                            </p>
                                            <p>
                                                <span style={{ color: "var(--primary-color)", fontSize: "larger" }}><strong>Zasedání</strong> zastupitelstev</span> se konají pravidelně, většinou několikrát ročně, a&nbsp;jsou veřejná, což umožňuje občanům sledovat rozhodovací procesy v&nbsp;reálném čase.
                                                Na těchto zasedáních se projednávají klíčové otázky, které ovlivňují život v&nbsp;kraji, od schvalování rozpočtu po přijímání dlouhodobých strategických plánů.
                                                Transparentnost těchto jednání je podpořena veřejnou přístupností a&nbsp;možností nahlédnout do rozhodnutí zastupitelů.
                                                Ne vždy jsou však tyto informace na webových stránkách krajů a&nbsp;pokud ano, nemusejí být ve strojově zpracovatelném formátu.
                                            </p>
                                            <p>
                                                <span style={{ color: "var(--primary-color)", fontSize: "larger" }}><strong>Hlasování</strong> zastupitelů a&nbsp;zastupitelek</span> probíhá veřejně - s&nbsp;výjimkou tajných hlasování - a&nbsp;to s&nbsp;možností hlasovat pro, proti nebo se zdržet hlasování.
                                                Dále mohou členové zastupitelstva nehlasovat, být nepřítomni (ev. omluveni).
                                                Výsledky hlasování jsou následně zveřejněny, aby byla zajištěna odpovědnost zastupitelů vůči voličům.
                                                Tímto způsobem se zajišťuje, že občané mají přehled o&nbsp;tom, jak jsou zastupitelé aktivní a&nbsp;jak hájí jejich zájmy.
                                                Ačkoliv velká část krajů zveřejňuje výsledky na úrovni jmenovitých hlasování, není to jejich zákonná povinnost.
                                                Nicméně transparentnost v&nbsp;těchto procesech je nezbytná pro budování důvěry veřejnosti ve veřejnou správu a&nbsp;pro efektivní fungování demokracie na regionální úrovni.
                                            </p>
                                            <p>
                                                <span className="emp" style={{ color: "#ff1a42", fontSize: "larger" }}>Pozor:</span> u&nbsp;<span style={{ color: "var(--primary-color)", fontSize: "larger" }}>hlavního města <strong>Prahy</strong></span> je třeba brát v&nbsp;potaz, že jeho zastupitelstvo bylo ustaveno v&nbsp;rámci voleb do zastupitelstev obcí a&nbsp;jeho funkční období trvá v&nbsp;letech 2022-2026. 
                                            </p>
                                        </>
                                    )
                                }
                            },
                            {
                                type: "stats-grid",
                                config: {
                                    stats: [
                                        sittingsStats,
                                        votingsStats,
                                        votingsRatio,
                                        votingsPerSittings
                                    ]
                                }
                            }
                        ]
                    }
                }
            },
            {
                id: "demograficke-analyzy",
                heading: {
                    label: "Demografické analýzy",
                },
                content: {
                    type: "widget-group",
                    config: {
                        style: {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "2rem"
                        },
                        widgets: [
                            {
                                type: "custom",
                                config: {
                                    style: {
                                        textAlign: "justify",
                                        maxWidth: "1200px"
                                    },
                                    content: (
                                        <>
                                            <p>
                                                Demografické analýzy zastupitelstev jsou klíčové pro pochopení, jak jsou různé skupiny obyvatelstva zastoupeny v rozhodovacích procesech.
                                                Zastoupení <span style={{ color: "#0096c7", fontSize: "larger" }}><strong>mužů</strong></span> a <span className="emp" style={{ color: "#ff1a42", fontSize: "larger" }}><strong>žen</strong></span> v politice není jen otázkou rovnosti, ale také kvality demokracie.
                                                Vyváženější zastoupení pohlaví může přinést rozmanitější pohledy a zkušenosti, což vede k lepším a inkluzivnějším rozhodnutím. Rovnoměrná účast mužů a žen přispívá k tomu, aby byly zohledněny různé potřeby a priority celé společnosti, což je zásadní pro spravedlivý a udržitelný rozvoj.
                                            </p>
                                            <p>
                                                I přesto, že se počet žen v politice postupně zvyšuje, z dat vyplývá, že se stále jedná spíše o doménu můžu.
                                                Následující statistiky zahrnují <span style={{ color: "var(--primary-color)", fontSize: "larger" }}>celé funkční období <strong>2020–⁠⁠⁠⁠⁠⁠2024</strong></span>.
                                                Pokud tedy v některém zastupitelstvu došlo ke změnám v jeho složení, figuruje ve výpočtech celkový počet zastupitelů a zastupitelek za celé období, nikoliv počet členů typický pro konkrétní zasedání.
                                            </p>
                                            <p>
                                                Zajímavé by bylo rovněž zahrnout analýzu věkových kategorií všech členů a členek a jejich nejvyššího dosaženého vzdělaní.
                                                Taková data však bohužel nejsou u všech osob snadno dohledatelná a u některých nejsou k dispozici vůbec.
                                            </p>
                                        </>
                                    )
                                }
                            }
                            , {
                                type: "stats-grid",
                                config: {
                                    stats: [
                                        leaderGenderStats,
                                        menStats,
                                        womenStats
                                    ]
                                }
                            }
                        ]
                    }
                }
            },
            {
                id: "statisticke-udaje",
                heading: {
                    label: "Statistické údaje z ČSÚ",
                },
                content: {
                    type: "widget-group",
                    config: {
                        style: {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "2rem"
                        },
                        widgets: [
                            {
                                type: "custom",
                                config: {
                                    style: {
                                        textAlign: "justify",
                                        maxWidth: "1200px"
                                    },
                                    content: (
                                        <>
                                            <p>
                                            Zdrojová data v&nbsp;této sekci a&nbsp;v&nbsp;úvodní statistice "Geografické údaje" pocházejí od <span style={{ color: "var(--primary-color)", fontSize: "larger" }}><strong><a href="https://csu.gov.cz/domov" target="_blank">Českého statistického úřadu</a></strong></span>, který se věnuje komplexnímu sběru dat z&nbsp;různých oblastí společenského a&nbsp;ekonomického života v&nbsp;České republice. 
                                                ČSÚ organizuje a&nbsp;provádí pravidelná statistická šetření, jako jsou sčítání lidu, průzkumy pracovního trhu nebo ekonomické analýzy. 
                                                Kromě dotazníkových šetření sbírá data také prostřednictvím administrativních zdrojů, spolupracuje s&nbsp;úřady a institucemi na všech úrovních. 
                                                Po zpracování dat zajišťuje jejich analýzu a&nbsp;zveřejnění ve formě statistických zpráv, ročenek a&nbsp;databází. 
                                                Tyto údaje jsou klíčové pro tvorbu veřejných politik, ekonomická rozhodnutí a&nbsp;akademický výzkum, a&nbsp;jsou dostupné široké veřejnosti jako otevřená data.
                                            </p>
                                            <p>   
                                                Data převzatá z ČSÚ byla využita v souladu s podmínkami <span style={{ color: "var(--primary-color)", fontSize: "larger" }}><a href="https://csu.gov.cz/podminky_pro_vyuzivani_a_dalsi_zverejnovani_statistickych_udaju_csu" target="_blank">licence <strong>ČSÚ</strong></a></span>.
                                                Reference na konkrétní zdroje dat jsou k&nbsp;dispozici vždy v&nbsp;zápatí modálního okna, které se otevře kliknutím do příslušné mapy. 
                                                Jednotlivé statistiky zahrnují pouze období <span style={{ color: "var(--primary-color)", fontSize: "larger" }}><strong>2020–2023</strong></span>, neboť data za aktuální kalendářní rok ještě nejsou u většiny statistik k&nbsp;dispozici.
                                            </p>
                                            <p>
                                                Následující statistiky se zaměřují na <span style={{ color: "var(--primary-color)", fontSize: "larger" }}><strong>hospodaření krajů</strong></span>. 
                                                To zahrnuje správu jejich finančních prostředků, které jsou využívány k&nbsp;zajištění veřejných služeb a&nbsp;rozvoje regionu. 
                                                Kraje spravují své příjmy, které získávají z&nbsp;daňových výnosů, dotací a&nbsp;dalších zdrojů, a&nbsp;tyto prostředky následně rozdělují na výdaje podle priorit, jako jsou infrastruktura, zdravotnictví, školství či sociální péče. 
                                                Efektivní hospodaření krajů je klíčové pro zajištění dlouhodobé stability a&nbsp;růstu regionů, přičemž je důležité, aby bylo transparentní a&nbsp;odpovědné vůči občanům.
                                            </p>
                                        </>
                                    )
                                }
                            }
                            , {
                                type: "stats-grid",
                                config: {
                                    stats: [
                                        populationStats,
                                        incomeStats,
                                        outcomeStats,
                                        saldoStats, 
                                        incomePerCitizen,
                                        outcomePerCitizen
                                    ]
                                }
                            },
                            {
                                type: "custom",
                                config: {
                                    style: {
                                        textAlign: "justify",
                                        maxWidth: "1200px"
                                    },
                                    content: (
                                        <>
                                            <p>   
                                                <span style={{ color: "var(--primary-color)", fontSize: "larger" }}><strong>Kvalita života</strong></span> v&nbsp;krajích České republiky se odvíjí od mnoha faktorů.
                                                Následující statistiky poskytují ucelený obraz o&nbsp;regionálních rozdílech a&nbsp;pomáhají zhodnotit úroveň kvality života v&nbsp;jednotlivých krajích. 
                                                Průměrná hrubá mzda, nezaměstnanost a počet trestných činů jsou důležité ukazatele, které ovlivňují celkový životní standard obyvatel. 
                                                Vyšší mzdy a&nbsp;nižší nezaměstnanost přispívají k&nbsp;lepším podmínkám pro jednotlivce i&nbsp;rodiny, zatímco nízká míra kriminality podporuje bezpečné prostředí.
                                            </p>
                                        </>
                                    )
                                }
                            }, 
                            {
                                type: "stats-grid",
                                config: {
                                    stats: [
                                        salaryStats, 
                                        unemploymentStats,
                                        crimes,
                                        crimePerCitizen
                                    ]
                                }
                            }
                        ]
                    }
                }
            }
        ]
    }
}
export default stats;