import { Calendar } from 'primereact/calendar';
import { classNames } from 'primereact/utils';
import React from 'react';
import { Controller } from 'react-hook-form';
import { parse } from 'date-fns';

/**
 * @param {object} params
 * @param {import('react-hook-form').UseFormRegister<any>} params.control
 * @param {string} params.name
 * @param {import('react-hook-form').RegisterOptions} params.rules
 * @param {string} params.placeholder
 * @param {import('primereact/calendar').CalendarProps} params.inputProps
 * @param {(e: import('primereact/calendar').CalendarChangeParams, field: import('react-hook-form').ControllerRenderProps) => void} params.onChange
 */
export const RHFCalendar = ({ control, name, rules, placeholder, inputProps, onChange }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => (
                <Calendar
                    id={field.name}
                    {...field}
                    ref={undefined}
                    inputRef={field.ref}
                    className={classNames({ 'p-invalid': fieldState.invalid })}
                    placeholder={placeholder}
                    showIcon
                    {...inputProps}
                    {...(onChange && { onChange: (e) => onChange(e, field) })}
                />
            )}
        />
    );
};

/**
 * @param {object} params
 * @param {import('react-hook-form').UseFormRegister<any>} params.control
 * @param {string} params.name
 * @param {import('react-hook-form').RegisterOptions} params.rules
 * @param {string} params.placeholder
 * @param {import('primereact/calendar').CalendarProps} params.inputProps
 * @param {(date: Date?) => void} params.afterChange
 */
export const RHFDateCalendar = ({ control, name, rules, placeholder, inputProps, afterChange }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => (
                <Calendar
                    id={field.name}
                    {...field}
                    ref={undefined}
                    inputRef={field.ref}
                    className={classNames({ 'p-invalid': fieldState.invalid })}
                    placeholder={placeholder}
                    showIcon
                    {...inputProps}
                    dateFormat='d.m.yy'
                    onInput={(e) => {
                        if (field.value) {
                            return;
                        }

                        let value = e.target.value;
                        if (!value) {
                            return;
                        }

                        value = value.replaceAll(/\s/g, '');
                        const date = parse(value, 'd.M.y', new Date());

                        if (isNaN(date) || date.getFullYear() < 1000) {
                            return;
                        }

                        field.onChange(date);
                        if (afterChange) {
                            afterChange(date);
                        }
                    }}
                    {...(afterChange && {
                        onChange: (e) => {
                            field.onChange(e);
                            afterChange(e.value);
                        }
                    })}
                />
            )}
        />
    );
};

/**
 * @param {object} params
 * @param {import('react-hook-form').UseFormRegister<any>} params.control
 * @param {string} params.name
 * @param {import('react-hook-form').RegisterOptions} params.rules
 * @param {string} params.placeholder
 * @param {import('primereact/calendar').CalendarProps} params.inputProps
 */
export const RHFYearCalendar = ({ control, name, rules, placeholder, inputProps }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => (
                <Calendar
                    id={field.name}
                    {...field}
                    ref={undefined}
                    inputRef={field.ref}
                    className={classNames({ 'p-invalid': fieldState.invalid })}
                    placeholder={placeholder}
                    showIcon
                    showOnFocus={false}
                    {...inputProps}
                    view='year'
                    dateFormat='yy'
                    // parse year from input text which the input doest not do by default for years
                    // FIXME: parseDateTime causes a warning in console, probably due to a bug in PrimeReact,
                    // either ignore, try switching to onInput or upgrade to a newer version of PrimeReact
                    parseDateTime={(text) => {
                        const year = parseInt(text);
                        if (isNaN(year) || year < 100) {
                            return null;
                        }
                        const date = new Date(year, 0);
                        return date;
                    }}
                />
            )}
        />
    );
};
