import { Checkbox } from 'primereact/checkbox';
import React from 'react';
import { Controller } from 'react-hook-form';

/**
 * @param {object} params
 * @param {import('react-hook-form').UseFormRegister<any>} params.control
 * @param {string} params.name
 * @param {boolean} params.disabled
 * @param {import('primereact/checkbox').CheckboxProps} params.inputProps
 * @param {(e: import('primereact/checkbox').CheckboxChangeParams, field: import('react-hook-form').ControllerRenderProps) => void} params.onChange
 */
export const RHFCheckbox = ({ control, name, disabled, inputProps, onChange }) => {
    return (
        <Controller
            name={name}
            control={control}
            disabled={disabled}
            render={({ field }) => (
                <Checkbox
                    inputId={field.name}
                    checked={field.value}
                    inputRef={field.ref}
                    disabled={field.disabled}
                    onChange={(e) => field.onChange(e.checked)}
                    {...inputProps}
                    {...(onChange && { onChange: (e) => onChange(e, field) })}
                />
            )}
        />
    );
};
