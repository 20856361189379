import PopupBodyChart from "../../../../components/map/popup/PopupBodyChart";
import PopupFooter from "../../../../components/map/popup/PopupFooter";
import PopupHeader from "../../../../components/map/popup/PopupHeader";
import data from "../data";
import geo from "../geo";
import meta from "../meta";

/**
 * Income statistics.
 * 
 * @author Adam Janosik, Kristyna Zaklova
 */
const income23 = {
    label: "Rok 2023",
    data: data,
    categories: meta.prijmyKraju.values,
    getValue: meta.prijmyKraju.getValue,
    formatValue: meta.prijmyKraju.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle příjmů jednotlivých krajů (rok 2023)",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Příjmy jednotlivých krajů se v roce <span
                                                    className="emp"><strong>2023</strong></span> pohybovaly od <span
                                                    className="emp"><strong>12&nbsp;350&nbsp;066</strong> Kč</span> do <span
                                                    className="emp"><strong>140&nbsp;780&nbsp;639</strong> Kč</span>.
                                                    Největší příjem měla <span className="emp"><strong>Praha</strong></span> a
                                                    nejmenší <span className="emp"><strong>Karlovarský kraj</strong></span>.
                                                </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.prijmyKraju.values,
                                            getValue: meta.prijmyKraju.getValue,
                                            formatValue: meta.prijmyKraju.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroj:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů (rok 2023)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2023"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}


const income22 = {
    label: "Rok 2022",
    data: data,
    categories: meta.prijmyKraju22.values,
    getValue: meta.prijmyKraju22.getValue,
    formatValue: meta.prijmyKraju22.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle příjmů jednotlivých krajů (rok 2022)",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Příjmy jednotlivých krajů se v roce <span
                                                    className="emp"><strong>2022</strong></span> pohybovaly od <span
                                                    className="emp"><strong>11&nbsp;021&nbsp;354</strong> Kč</span> do <span
                                                    className="emp"><strong>120&nbsp;335&nbsp;910</strong> Kč</span>.
                                                    Největší příjem měla <span className="emp"><strong>Praha</strong></span> a
                                                    nejmenší <span className="emp"><strong>Karlovarský kraj</strong></span>.
                                                </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.prijmyKraju22.values,
                                            getValue: meta.prijmyKraju22.getValue,
                                            formatValue: meta.prijmyKraju22.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroj:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů (rok 2022)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2022"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const income21 = {
    label: "Rok 2021",
    data: data,
    categories: meta.prijmyKraju21.values,
    getValue: meta.prijmyKraju21.getValue,
    formatValue: meta.prijmyKraju21.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle příjmů jednotlivých krajů (rok 2021)",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Příjmy jednotlivých krajů se v roce <span
                                                    className="emp"><strong>2021</strong></span> pohybovaly od <span
                                                    className="emp"><strong>9&nbsp;869&nbsp;446</strong> Kč</span> do <span
                                                    className="emp"><strong>105&nbsp;636&nbsp;412</strong> Kč</span>.
                                                    Největší příjem měla <span className="emp"><strong>Praha</strong></span> a
                                                    nejmenší <span className="emp"><strong>Karlovarský kraj</strong></span>.
                                                </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.prijmyKraju21.values,
                                            getValue: meta.prijmyKraju21.getValue,
                                            formatValue: meta.prijmyKraju21.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroj:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů (rok 2021)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2021"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}

const income20 = {
    label: "Rok 2020",
    data: data,
    categories: meta.prijmyKraju20.values,
    getValue: meta.prijmyKraju20.getValue,
    formatValue: meta.prijmyKraju20.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle příjmů jednotlivých krajů (rok 2020)",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Příjmy jednotlivých krajů se v roce <span
                                                    className="emp"><strong>2020</strong></span> pohybovaly od <span
                                                    className="emp"><strong>8&nbsp;807&nbsp;422</strong> Kč</span> do <span
                                                    className="emp"><strong>96&nbsp;941&nbsp;215</strong> Kč</span>.
                                                    Největší příjem měla <span className="emp"><strong>Praha</strong></span> a
                                                    nejmenší <span className="emp"><strong>Karlovarský kraj</strong></span>.
                                                </p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.prijmyKraju20.values,
                                            getValue: meta.prijmyKraju20.getValue,
                                            formatValue: meta.prijmyKraju20.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroj:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů (rok 2020)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2020"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}


const incomeAvg = {
    label: "Průměr 2020-2023",
    data: data,
    categories: meta.prijmyKrajuAvg.values,
    getValue: meta.prijmyKrajuAvg.getValue,
    formatValue: meta.prijmyKrajuAvg.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle průměrných příjmů jednotlivých krajů v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Napříč lety <span className="emp"><strong>2020-2023</strong></span> v průměru nejvíce financí získala <span className="emp"><strong>Praha</strong></span>, nejméně pak <span className="emp"><strong>Karlovarský kraj</strong></span>. Typicky kraje s menší rozlohou a počtem obyvatel získávají méně než kraje s větší populací a územím.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.prijmyKrajuAvg.values,
                                            getValue: meta.prijmyKrajuAvg.getValue,
                                            formatValue: meta.prijmyKrajuAvg.formatValue
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ (2023)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2023"
                                        },
                                        {
                                            label: "ČSÚ (2022)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2022"
                                        },
                                        {
                                            label: "ČSÚ (2021)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2021"
                                        },
                                        {
                                            label: "ČSÚ (2020)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2020"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}


const incomeSum = {
    label: "Suma za období 2020-2023",
    data: data,
    categories: meta.prijmyKrajuSum.values,
    getValue: meta.prijmyKrajuSum.getValue,
    formatValue: meta.prijmyKrajuSum.formatValue,
    map: {
        defaults: {
            polygon: {
                hoverStyle: {
                    fill: "yellow"
                },
                popup: {
                    header: {
                        component: PopupHeader,
                        props: {
                            data: data
                        }
                    },
                    body: {
                        component: PopupBodyChart,
                        props: {
                            data: data,
                            stats: [
                                {
                                    title: "Srovnání dle vývoje příjmu jednotlivých krajů v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                                <p>Suma příjmů jednotlivých krajů v letech <span className="emp"><strong>2020-2023</strong></span> značí součet všech příjmů krajů v daném období. Nejvíce financí získala <span className="emp"><strong>Praha</strong></span>, nejméně pak <span className="emp"><strong>Karlovarský kraj</strong></span>.</p>
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "bar",
                                        config: {
                                            geo: geo,
                                            data: data,
                                            categories: meta.prijmyKrajuSum.values,
                                            getValue: meta.prijmyKrajuSum.getValue,
                                            formatValue: meta.prijmyKrajuSum.formatValue
                                        }
                                    }
                                },
                                {
                                    title: "Vývoj příjmů krajů v období 2020-2023",
                                    content: () => {
                                        return (
                                            <>
                                                Praha výrazně převyšuje ostatní kraje v celkových příjmech a vzrůstajícím trendu.
                                            </>
                                        )
                                    },
                                    chart: {
                                        type: "line",
                                        config: {
                                            geo: geo,
                                            datasets: [
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.prijmyKraju20.values,
                                                    getValue: meta.prijmyKraju20.getValue,
                                                    formatValue: meta.prijmyKraju20.formatValue,
                                                    label: meta.prijmyKraju20.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.prijmyKraju21.values,
                                                    getValue: meta.prijmyKraju21.getValue,
                                                    formatValue: meta.prijmyKraju21.formatValue,
                                                    label: meta.prijmyKraju21.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.prijmyKraju22.values,
                                                    getValue: meta.prijmyKraju22.getValue,
                                                    formatValue: meta.prijmyKraju22.formatValue,
                                                    label: meta.prijmyKraju22.label
                                                },
                                                {
                                                    geo: geo,
                                                    data: data,
                                                    categories: meta.prijmyKraju.values,
                                                    getValue: meta.prijmyKraju.getValue,
                                                    formatValue: meta.prijmyKraju.formatValue,
                                                    label: meta.prijmyKraju.label
                                                },
                                            ]
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    footer: {
                        component: PopupFooter,
                        props: {
                            data: data,
                            settings: {
                                left: {
                                    title: {
                                        label: "Zdroje:",
                                        icon: "pi pi-fw pi-globe"
                                    },
                                    links: [
                                        {
                                            label: "ČSÚ (2023)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2023"
                                        },
                                        {
                                            label: "ČSÚ (2022)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2022"
                                        },
                                        {
                                            label: "ČSÚ (2021)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2021"
                                        },
                                        {
                                            label: "ČSÚ (2020)",
                                            value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt&pvo=FIN01&z=T&f=TABULKA&katalog=33877&str=v58&c=v3~8__RP2020"
                                        }
                                    ]
                                },
                                right: {
                                    title: undefined,
                                    links: [
                                        meta.route
                                    ]
                                }
                            }
                        }
                    },
                    style: {
                        width: '80vw',
                        height: '100vh',
                        maxWidth: '1200px',
                    }
                }
            },
            capital: {
                style: {
                    display: "none"
                }
            }
        }
    },
}


const incomeStats = {
    title: 'Celkové příjmy krajů',
    content: () => {
        return (
            <>
                <span className="emp"><strong>Příjmy krajů</strong></span> se skládají z&nbsp;několika hlavních zdrojů. <span className="emp">Daňové příjmy</span> tvoří významnou část rozpočtu a&nbsp;zahrnují podíly z&nbsp;daní, jako jsou daň z&nbsp;příjmů nebo DPH. <span className="emp">Nedaňové příjmy</span> zahrnují například poplatky za služby poskytované krajem nebo výnosy z&nbsp;majetku. <span className="emp">Kapitálové příjmy</span> se týkají prodeje majetku kraje nebo zisků z&nbsp;investic. 
                A <span className="emp">přijaté transféry</span> jsou finanční prostředky přidělené státem nebo Evropskou unií, často ve formě dotací určených na konkrétní projekty či činnosti. 
                Tyto zdroje společně tvoří <span className="emp"><strong>rozpočet</strong></span>, z&nbsp;něhož kraje financují své služby a&nbsp;rozvojové projekty.
            </>
        )
    },
    chart: {
        id: "mapa-prijem",
        type: "regional-filtered-choropleth",
        config: {
            label: "Celkový příjem krajů",
            geo: geo,
            datasets: [
                incomeSum, incomeAvg, income23, income22, income21, income20
            ]

        }
    },
    footer: {
        left: {
            title: {
                label: "Zdroj:",
                icon: "pi pi-fw pi-globe"
            },
            links: [
                {
                    label: "ČSÚ –⁠⁠⁠⁠⁠⁠ příjmy a výdaje krajů",
                    value: "https://vdb.czso.cz/vdbvo2/faces/cs/index.jsf?page=vystup-objekt-parametry&z=T&f=TABULKA&katalog=33877&pvo=FIN01&sp=A&c=v3%7E8__RP2023&str=v58"
                }
            ]
        },
        right: {
            title: undefined,
            links: [
                meta.route
            ]
        },
        popup: {
            component: incomeSum.map.defaults.polygon.popup
        }
    }
}
export default incomeStats;