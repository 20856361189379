import React from 'react';
import { Card } from './PrimereactComponents';
import { logoUrl } from '../utils/logoUrl';

export const PoliticalBodyCard = ({ municipality, bodyType, onClick }) => {
    return (
        <div className='municipality-card-wrapper'>
            <Card onClick={onClick} className='municipality-card hoverable-card'>
                <div className='municipality-card-content'>
                    <img className='municipality-card-logo' src={logoUrl(municipality.text_id)} alt='logo' />
                </div>
                <h2 className='municipality-card-text' style={{ color: municipality.barva }}>
                    {municipality.organy[bodyType].nazev}
                </h2>
            </Card>
        </div>
    );
};
