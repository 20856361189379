import React, { useState, useRef } from 'react';
import { useParams, useNavigate, Link, useLoaderData } from 'react-router-dom';
import { Chip, Card, Tooltip, Button, DataTable, OverlayPanel, Column } from '../../components/PrimereactComponents';
import ChartComponent from '../../components/ChartComponent';
import { useTranslation } from 'react-i18next';
import PaginatedDataTable from '../../components/PaginatedDataTable';
import { AliasTooltip } from '../../components/AliasTooltip';

function MemberDetail() {
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedAttendance, setSelectedAttendance] = useState(null);

    const { municipalitaId, bodyType, zastupitelstvoId } = useParams();

    const { t } = useTranslation();

    const navigate = useNavigate();

    const op = useRef(null);
    const op_att = useRef(null);

    const memberData = useLoaderData().data;
    const hasLatestMeeting = !!memberData.posledni_aktivita.length;

    const summaryVotesLabelsCorrection = (arr) => {
        var idx = 0;
        if (arr.includes('zdržel se')) {
            idx = arr.indexOf('zdržel se');
            arr[idx] = 'zdržela se';
        }
        if (arr.includes('nehlasoval')) {
            idx = arr.indexOf('nehlasoval');
            arr[idx] = 'nehlasovala';
        }
        if (arr.includes('omluven')) {
            idx = arr.indexOf('omluven');
            arr[idx] = 'omluvena';
        }
        return arr;
    };

    const onTableOptionChange = (e) => {
        setSelectedRow(e.value);
        const baseUrl = process.env.REACT_APP_BASE_URL;
        let url = baseUrl + `${municipalitaId}/${bodyType}/${zastupitelstvoId}/hlasovani/${e.value.hlasovani_id}`;
        window.open(url, '_blank');
    };

    var style = getComputedStyle(document.body);
    const colors = [
        style.getPropertyValue('--clr-1'),
        style.getPropertyValue('--clr-2'),
        style.getPropertyValue('--clr-3'),
        style.getPropertyValue('--clr-4'),
        style.getPropertyValue('--clr-5'),
        style.getPropertyValue('--clr-6'),
        style.getPropertyValue('--clr-7')
    ];
    const voteColors = {
        ano: colors[0],
        ne: colors[1],
        'zdržel se': colors[2],
        nehlasoval: colors[3],
        'nepřít.': colors[4],
        tajná: colors[5],
        omluven: colors[6]
    };
    const attendanceColors = {
        'přítomnost': colors[0],
        'částečná přítomnost': colors[2],
        'nepřítomnost': colors[1]
    }

    const member = t('member', { context: memberData.pohlavi });
    const voteOptions = memberData.pohlavi
        ? { ano: 1, ne: 2, 'zdržela se': 3, nehlasovala: 4, 'nepřít.': 5, omluvena: 6, tajná: 7 }
        : { ano: 1, ne: 2, 'zdržel se': 3, nehlasoval: 4, 'nepřít.': 5, omluven: 6, tajná: 7 };
    let summaryVotesLabels = memberData.pohlavi
        ? summaryVotesLabelsCorrection(Object.keys(memberData.hlasovani.sumarizace))
        : Object.keys(memberData.hlasovani.sumarizace);
    let summaryVotesValues = Object.values(memberData.hlasovani.sumarizace);

    let summaryAttendanceLabels = Object.keys(memberData.dochazka.sumarizace);
    let summaryAttendanceValues = Object.values(memberData.dochazka.sumarizace);

    const datasetsDataSummaryVotes = Object.keys(memberData.hlasovani.sumarizace).map((item, index) => ({
        type: 'bar',
        label: summaryVotesLabels[index],
        backgroundColor: voteColors[item],
        data: [summaryVotesValues[index]]
    }));

    datasetsDataSummaryVotes.sort((a, b) => {
        return Object.keys(voteOptions).indexOf(a.label) - Object.keys(voteOptions).indexOf(b.label);
    });

    let summaryVotesData = {
        labels: [''],
        datasets: datasetsDataSummaryVotes
    };

    const datasetsDataSummaryAttendance = Object.keys(memberData.dochazka.sumarizace).map((item, index) => {
        return {
            type: 'bar',
            label: item,
            backgroundColor: attendanceColors[item],
            data: [summaryAttendanceValues[index]]
        };
    })

    let summaryAttendanceData = {
        labels: [''],
        datasets: datasetsDataSummaryAttendance
    };

    var hover_timeout;
    const stackedOptionsVotes = {
        indexAxis: 'y',
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            legend: {
                labels: {
                    generateLabels: (chart) => {
                        const datasets = chart.data.datasets;
                        return datasets.map((data, i) => ({
                            text: `${data.label} (${data.data[0]})`,
                            fillStyle: data.backgroundColor,
                            strokeStyle: 'white',
                            datasetIndex: 0,
                            index: i
                        }));
                    }
                },
                onHover: function (evt, item, legend) {
                    const tooltip = legend.chart.tooltip;
                    if (tooltip.getActiveElements().length > 0) {
                        tooltip.setActiveElements([], { x: 0, y: 0 });
                    }
                    tooltip.setActiveElements([{ datasetIndex: item.index, index: 0 }], { x: 0, y: 0 });

                    evt.native.target.style.cursor = 'pointer';
                    legend.chart.data.datasets.forEach((i, id) => {
                        if (id !== item.index && i.backgroundColor.length === 7) {
                            i.backgroundColor += '30';
                        }
                    });
                    clearTimeout(hover_timeout);
                    hover_timeout = setTimeout(function () {
                        legend.chart.update();
                    }, 60);
                },
                onLeave: function (evt, item, legend) {
                    const tooltip = legend.chart.tooltip;
                    tooltip.setActiveElements([], { x: 0, y: 0 });
                    legend.chart.data.datasets.forEach((i, id) => {
                        if (id !== item.index && i.backgroundColor.length > 7) {
                            i.backgroundColor = i.backgroundColor.slice(0, -2);
                        }
                    });
                    legend.chart.update();
                }
            }
        },
        scales: {
            x: {
                stacked: true
            },
            y: {
                stacked: true,
                position: 'right'
            }
        },
        onHover: function (evt, item) {
            if (item[0] !== undefined) {
                evt.native.target.style.cursor = 'pointer';
            } else {
                evt.native.target.style.cursor = 'default';
            }
        },
        onClick: function (evt, item) {
            if (item[0] !== undefined) {
                let voteOpt = summaryVotesData.datasets[item[0].datasetIndex].label;

                // REDIRECT
                navigate(
                    `/${municipalitaId}/${bodyType}/${zastupitelstvoId}/hlasovani?pohled=clen&clen=` +
                        memberData.id +
                        `&hlasovaci_moznost=` +
                        voteOptions[voteOpt] +
                        `&pouze_platna=true`
                );
            }
        }
    };

    const stackedOptionsAttendance = {
        indexAxis: 'y',
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            legend: {
                labels: {
                    generateLabels: (chart) => {
                        const datasets = chart.data.datasets;
                        return datasets.map((data, i) => ({
                            text: `${data.label} (${data.data[0]})`,
                            fillStyle: data.backgroundColor,
                            strokeStyle: 'white',
                            datasetIndex: 0,
                            index: i
                        }));
                    }
                },
                onHover: function (evt, item, legend) {
                    evt.native.target.style.cursor = 'pointer';
                    const tooltip = legend.chart.tooltip;
                    if (tooltip.getActiveElements().length > 0) {
                        tooltip.setActiveElements([], { x: 0, y: 0 });
                    }
                    tooltip.setActiveElements([{ datasetIndex: item.index, index: 0 }], { x: 0, y: 0 });
                    legend.chart.data.datasets.forEach((i, id) => {
                        if (id !== item.index && i.backgroundColor.length === 7) {
                            i.backgroundColor += '30';
                        }
                    });
                    clearTimeout(hover_timeout);
                    hover_timeout = setTimeout(function () {
                        legend.chart.update();
                    }, 60);
                },
                onLeave: function (evt, item, legend) {
                    const tooltip = legend.chart.tooltip;
                    tooltip.setActiveElements([], { x: 0, y: 0 });
                    legend.chart.data.datasets.forEach((i, id) => {
                        if (id !== item.index && i.backgroundColor.length > 7) {
                            i.backgroundColor = i.backgroundColor.slice(0, -2);
                        }
                    });
                    legend.chart.update();
                }
            }
        },
        scales: {
            x: {
                stacked: true
            },
            y: {
                stacked: true,
                position: 'right'
            }
        },
        onHover: function (evt, item) {
            if (item[0] !== undefined) {
                evt.native.target.style.cursor = 'pointer';
            } else {
                evt.native.target.style.cursor = 'default';
            }
        },
        onClick: function (evt, item) {
            if (item[0] !== undefined) {
                const currentLabel = summaryAttendanceLabels[item[0].datasetIndex];
                setSelectedAttendance(currentLabel !== selectedAttendance ? currentLabel : null);
            }
        }
    };

    let total = memberData.hlasovani.aktivni_platna;
    if (memberData.hlasovani.sumarizace.hasOwnProperty('nepřít.')) {
        total += memberData.hlasovani.sumarizace['nepřít.'];
    }
    if (memberData.hlasovani.sumarizace.hasOwnProperty('omluven')) {
        total += memberData.hlasovani.sumarizace['omluven'];
    }

    const ucast = Math.round((memberData.hlasovani.aktivni_platna / total) * 100);

    const formatMembershipDuration = (dateFrom, dateTo) => {
        if (dateFrom && dateTo) return `${dateFrom} - ${dateTo}`;
        else if (dateFrom) return `${t('interval-from')} ${dateFrom}`;
        else if (dateTo) return `${t('interval-to')} ${dateTo}`;
        else return '';
    };

    return (
        <>
            <div className='representative'>
                <h1 className='representative-title'>
                    {memberData.jmeno}
                    {memberData.aliasy && <AliasTooltip id='aliases-tooltip' aliases={memberData.aliasy} />}
                    {memberData.aktivni_clen === 0 ? (
                        <Chip label={t('inactive') + ' ' + member} className='bg__secondary text__white' />
                    ) : (
                        <Chip
                            label={t('active') + ' ' + member}
                            className='text__white'
                            style={{ backgroundColor: memberData.politicke_strany[0].strana_barva }}
                        />
                    )}
                </h1>

                <div className='representative-grid'>
                    <Card
                        className='info-card'
                        title={t('basic-info')}
                        footer={
                            hasLatestMeeting ? (
                                <Link to={`../../analyzy?prvni_clen=${memberData.id}`} state={1}>
                                    {t('councilMember_comparison')}
                                    <i className='pi pi-angle-right'></i>
                                </Link>
                            ) : (
                                <></>
                            )
                        }
                    >
                        <div className='grid'>
                            <div>
                                {memberData.dochazka.mandat === memberData.dochazka.celkem_zasedani ? (
                                    <>
                                        <i className='pi pi-book text-icon__left text__primary'></i>
                                        {t('mandate')} <span className='text__primary'>{t('whole')} </span>
                                        {t('term')}
                                    </>
                                ) : (
                                    <>
                                        <i className='pi pi-book text-icon__left text__primary'></i>
                                        {t('mandate_out_of', {
                                            cnt1: memberData.dochazka.mandat,
                                            cnt2: memberData.dochazka.celkem_zasedani
                                        })}
                                    </>
                                )}
                            </div>
                            {memberData.dochazka.celkem_zasedani > 0 && (
                                <>
                                    <div>
                                        <span className='text__primary'>{ucast || 0}%</span> {t('voting-attendance')}{' '}
                                        <Tooltip target='.custom-target-icon' />
                                        <i
                                            className='custom-target-icon pi bi-info-circle p-text-secondary p-overlay-badge'
                                            data-pr-tooltip={t('tooltip-attendance', {
                                                cnt1: memberData.hlasovani.aktivni_platna.toLocaleString('cz'),
                                                cnt2: total.toLocaleString('cz')
                                            })}
                                            data-pr-position='bottom'
                                        ></i>
                                    </div>
                                    <div>
                                        <i className='pi pi bi-stack text__primary text-icon__left'></i>
                                        <span className='text__primary'>
                                            {memberData.hlasovani.aktivni_platna.toLocaleString('cz')}
                                        </span>{' '}
                                        {t('valid-votings')}
                                    </div>
                                </>
                            )}
                        </div>
                    </Card>

                    <Card
                        className='parties-card'
                        title={t('political-party', { count: memberData.politicke_strany.length })}
                    >
                        <ul className='list__unstyled'>
                            {memberData.politicke_strany.map((party, i) => {
                                const membershipDuration = formatMembershipDuration(party.datum_od, party.datum_do);
                                return (
                                    <Link to={`../../subjekty/${party.id}`}>
                                        <li className={i !== 0 ? 'bullet__secondary' : 'bullet__primary'}>
                                            <span
                                                style={i === 0 ? { color: party.strana_barva } : {}}
                                                className={i === 0 ? 'text__semibold' : 'text__secondary'}
                                            >
                                                {party.zkratka}
                                            </span>
                                            {i === 0 && memberData.aktivni_clen === 1 ? (
                                                <span className=''> ({t('now')})</span>
                                            ) : (
                                                <>
                                                    {' '}
                                                    <i
                                                        className='text__secondary custom-target-icon pi bi-info-circle'
                                                        data-pr-tooltip={membershipDuration}
                                                        data-pr-position='bottom'
                                                    ></i>
                                                    <Tooltip target='.custom-target-icon' />
                                                </>
                                            )}
                                        </li>
                                    </Link>
                                );
                            })}
                        </ul>
                    </Card>
                    {hasLatestMeeting ? (
                        <Card
                            title={
                                <span className='card-title-w-btn'>
                                    {t('member-attendance')}
                                    <Button
                                        className='table-view-icon'
                                        icon='bi bi-table'
                                        onClick={(e) => op_att.current.toggle(e)}
                                        alt='Zobrazit tabulku'
                                        aria-label='Detail'
                                    />
                                </span>
                            }
                            className='attendance-card'
                        >
                            <OverlayPanel ref={op_att}>
                                <DataTable responsiveLayout='false' value={summaryAttendanceData.datasets}>
                                    <Column field='label' header={t('attendance')} />
                                    <Column field='data' header={t('count')} />
                                </DataTable>
                            </OverlayPanel>
                            <ChartComponent
                                type='bar'
                                data={summaryAttendanceData}
                                options={stackedOptionsAttendance}
                                height='220px'
                                width='100%'
                            />
                        </Card>
                    ) : (
                        <></>
                    )}
                    {hasLatestMeeting ? (
                        <Card
                            title={
                                <span className='card-title-w-btn'>
                                    {t('votings-summary')}
                                    <Button
                                        className='table-view-icon'
                                        icon='bi bi-table'
                                        onClick={(e) => op.current.toggle(e)}
                                        alt='Zobrazit tabulku'
                                        aria-label='Detail'
                                    />
                                </span>
                            }
                            className='votings-card'
                        >
                            <OverlayPanel ref={op}>
                                <DataTable responsiveLayout='false' value={summaryVotesData.datasets}>
                                    <Column field='label' header={t('option')} />
                                    <Column field='data' header={t('count')} />
                                </DataTable>
                            </OverlayPanel>
                            <ChartComponent
                                type='bar'
                                data={summaryVotesData}
                                options={stackedOptionsVotes}
                                height='220px'
                                width='100%'
                            />
                        </Card>
                    ) : (
                        <></>
                    )}

                    {selectedAttendance ? (
                        <Card
                            title={
                                <span>
                                    {t('member-attendance')} - {selectedAttendance}
                                </span>
                            }
                            style={{ gridArea: '3/1/3/9' }}
                        >
                            <ul className='list__unstyled grid'>
                                {memberData.zasedani[selectedAttendance].map((item) => (
                                    <li className='bullet__primary representatives-list-cell'>
                                        <Link
                                            to={`/${municipalitaId}/${bodyType}/${zastupitelstvoId}/zasedani/${item.zmb}`}
                                        >
                                            <span>{t('meeting-no')}&nbsp;</span>
                                            <span>{item.zmb}</span>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </Card>
                    ) : (
                        <></>
                    )}

                    {hasLatestMeeting ? (
                        <Card
                            title={<span>{t('latest-activity')}</span>}
                            style={selectedAttendance ? { gridArea: '4/1/4/9' } : {}}
                            className='activity-card'
                        >
                            <PaginatedDataTable
                                data={memberData.posledni_aktivita}
                                selection={selectedRow}
                                onSelectionChange={onTableOptionChange}
                            >
                                <Column className='w10' field='zasedani' header={t('meeting')} />
                                <Column className='w10' field='hlasovaci_moznost' header={t('Voting-option')} />
                                <Column className='w80 lh4' field='predmet' header={t('voting-description')} />
                            </PaginatedDataTable>
                        </Card>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </>
    );
}

export default MemberDetail;
