import React from "react";

import { Dropdown } from 'primereact/dropdown';

import RegionalChoropleth from "./RegionalChoropleth";

import "./RegionalFilteredChoropleth.css"

function RegionalFilteredChoropleth({
    id = undefined,
    geo = undefined,
    datasets = [], // list of datasets
    map = undefined,
    config = undefined
}) {

    let [selectedDataset, setSelectedDataset] = React.useState(datasets[0]);

    return (
        <>
            <div className="regional-filtered-choropleth">
                <Dropdown value={selectedDataset} onChange={(e) => setSelectedDataset(e.value)} options={datasets} optionLabel="label" 
                    placeholder="Vyberte položku" />
                <RegionalChoropleth
                    id={id}
                    geo={geo}
                    data={selectedDataset.data}
                    categories={selectedDataset.categories}
                    getValue={selectedDataset.getValue}
                    formatValue={selectedDataset.formatValue}
                    map={selectedDataset.map}
                    config={selectedDataset.config}/>


{/*
id={stat.head.chart.id}
                                                    geo={stat.head.chart.config.geo}
                                                    data={stat.head.chart.config.data}
                                                    categories={stat.head.chart.config.categories}
                                                    getValue={stat.head.chart.config.getValue}
                                                    formatValue={stat.head.chart.config.formatValue}
                                                    map={stat.head.chart.config.map}/>*/}
            </div>
        </>
    )
}

export default RegionalFilteredChoropleth;