import React from 'react';
import { MunicipalityBreadCrumb } from '../../../components/MunicipalityBreadCrumb';
import { useParams } from 'react-router-dom';

/**
 * @param {object} params
 * @param {{label: string, url?: string}[]} params.extraItems
 */
export const VoteBreadCrumb = ({ extraItems }) => {
    const { municipalityId, termId, meetingId } = useParams();

    const items = [];
    if (termId) {
        items.push({
            label: 'Funkční období',
            url: `/admin/municipality/${municipalityId}/funkcni-obdobi`
        });
        items.push({
            label: termId
        });
        items.push({
            label: 'Zasedání',
            url: `/admin/municipality/${municipalityId}/funkcni-obdobi/${termId}/zasedani`
        });
    }

    if (meetingId) {
        items.push({
            label: meetingId
        });
        items.push({
            label: 'Hlasování',
            url: `/admin/municipality/${municipalityId}/funkcni-obdobi/${termId}/zasedani/${meetingId}/hlasovani`
        });
    }

    if (extraItems) {
        items.push(...extraItems);
    }

    return <MunicipalityBreadCrumb extraItems={items} />;
};
